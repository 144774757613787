import { observable } from "mobx";


export class TemplateTestComparisonInstructions
{
    @observable leftExpression: string | null;
    @observable rightUnitBaseId: string | null;
    @observable rightUnitTestTypeId: string | null;
    @observable rightExpression: string | null;

    constructor(json: any)
    {
        this.leftExpression = json.leftExpression;
        this.rightUnitBaseId = json.rightUnitBaseId;
        this.rightUnitTestTypeId = json.rightUnitTestTypeId;
        this.rightExpression = json.rightExpression;
    }

    isEmpty(): boolean
    {
        return (this.leftExpression === null || this.leftExpression === '')
            && (this.rightExpression === null || this.rightExpression === '')
            && (this.rightUnitTestTypeId === null || this.rightUnitTestTypeId === '')
            && (this.rightExpression === null || this.rightExpression === '');
    }
}

export interface TemplateUpsertProperties
{
    freeText: null | string,
    unitBaseId: string | null,
    testTypeId: string | null,
    comparisonId: null | string,
    comparisonInstructions: any,
    value: any[],
    optional: boolean,
    datacron: TemplateDatacronTest | null;
    setIndex: null | number
}

export class TemplateDatacronSecondary
{
    @observable statType: number;
    @observable minValue: number | null = null;

    constructor(json: any)
    {
        this.statType = json.statType;
        if (json.minValue !== null && json.minValue !== undefined)
        {
            this.minValue = json.minValue;
        }
    }
}

export class TemplateDatacronTest
{
    @observable testName: string = '';
    @observable affix: string[] = [];
    @observable secondary: TemplateDatacronSecondary[] = [];
    @observable count: number | null = null;

    constructor(json: any)
    {
        if (json.testName) this.testName = json.testName;
        if (json.affix) this.affix = json.affix;

        if (json.count !== null && json.count !== undefined)
        {
            this.count = json.count;
        }
        if (json.secondary)
        {
            this.secondary = json.secondary.map((s: any) => new TemplateDatacronSecondary(s));
        }
    }
}

export class TemplateTest
{
    @observable testId: number;
    @observable description: string;
    @observable unitBaseId: string | null;
    @observable testTypeId: string | null;
    @observable comparisonId: string | null;
    @observable comparisonInstructions: TemplateTestComparisonInstructions | null;
    @observable value: string[] = [];
    @observable datacron: TemplateDatacronTest | null = null;

    @observable optional: boolean;

    constructor(json: any)
    {
        this.testId = json.testId;
        this.description = json.description;
        this.unitBaseId = json.unitBaseId;
        this.testTypeId = json.testTypeId;
        this.comparisonId = json.comparisonId;
        if (json.comparisonInstructions)
        {
            this.comparisonInstructions = new TemplateTestComparisonInstructions(json.comparisonInstructions);
        }
        else
        {
            this.comparisonInstructions = null;
        }
        if (json.datacron)
        {
            this.datacron = new TemplateDatacronTest(json.datacron);
        }
        json.value.forEach((v: string) => this.value.push(v));
        this.optional = json.optional;
    }

    static getProperties(tt: TemplateTest): TemplateUpsertProperties
    {
        return {
            freeText: null,
            unitBaseId: tt.unitBaseId,
            testTypeId: tt.testTypeId,
            comparisonId: tt.comparisonId,
            comparisonInstructions: tt.comparisonInstructions === null ? null : {
                leftExpression: tt.comparisonInstructions.leftExpression,
                rightUnitBaseId: tt.comparisonInstructions.rightUnitBaseId,
                rightUnitTestTypeId: tt.comparisonInstructions.rightUnitTestTypeId,
                rightExpression: tt.comparisonInstructions.rightExpression
            },
            datacron: tt.datacron === null || tt.datacron === undefined ? null : {
                testName: tt.datacron.testName,
                affix: tt.datacron.affix.slice(0),
                secondary: tt.datacron.secondary.map(s => new TemplateDatacronSecondary(s)),
                count: tt.datacron.count
            },
            value: tt.value,
            optional: tt.optional,
            setIndex: null
        }
    }
}

export class TemplateCategory
{
    @observable key: string;
    @observable description: string;
    @observable optional: boolean;
    @observable isAlternate: boolean;
    @observable isAlternateOf: null | string = null;
    @observable test: TemplateTest[] | null = null;
    @observable testCount: number;
    @observable alternate: TemplateCategory[] | null = null;

    constructor(json: any)
    {
        this.key = json.key;
        this.description = json.description;
        this.optional = json.optional;
        this.isAlternate = json.isAlternate;
        this.isAlternateOf = json.isAlternateOf;
        this.testCount = json.testCount;
        if (json.test !== undefined)
        {
            this.test = [];
            this.testCount = json.test.length;
            json.test.forEach((t: any) => this.test!.push(new TemplateTest(t)));
        }
        if (json.alternate)
        {
            this.alternate = json.alternate.map((j: any) => new TemplateCategory(j))
        }
    }

}
export class Template
{
    @observable category: TemplateCategory[] = [];
    @observable id: string;
    @observable guildId: string;
    @observable key: string;
    @observable description: string | null;
    @observable footer: string | null;
    @observable link: string | null;
    @observable chained: boolean;
    @observable published: boolean;
    @observable editable: boolean;
    @observable shareAlliance: boolean;

    constructor(json: any)
    {
        this.id = json.id;
        this.guildId = json.guildId;
        this.key = json.key;
        this.description = json.description;
        this.footer = json.footer;
        this.link = json.link;
        this.chained = json.chained;
        this.published = json.published;
        this.editable = json.editable;
        this.shareAlliance = json.shareAlliance;

        if (json.category)
        {
            this.category = [];
            json.category.forEach((c: any) => this.category.push(new TemplateCategory(c)));
        }
    }

    public fromJson(json: any)
    {
        this.id = json.id;
        this.guildId = json.guildId;
        this.key = json.key;
        this.description = json.description;
        this.footer = json.footer;
        this.link = json.link;
        this.chained = json.chained;
        this.published = json.published;
        this.editable = json.editable;
        this.shareAlliance = json.shareAlliance;

        if (json.category)
        {
            this.category = [];
            json.category.forEach((c: any) => this.category.push(new TemplateCategory(c)));
        }
    }
}

export class TemplateGroup
{
    @observable name: string;
    @observable template: Template[] = [];

    constructor(json: any)
    {
        this.name = json.name;
        if (json.template)
        {
            json.template.forEach((t: any) => this.template.push(new Template(t)));
        }
    }
}
