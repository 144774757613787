import React from "react";
import { Button, Modal, Form, List, Spin, Typography, Input } from "antd";
import { observable, runInAction, action } from "mobx";
import { observer } from "mobx-react";
import { SelectorLayout } from "../../layouts/SelectorLayout";
import IPageProps from "../IPageProps";
import PlayerData from "../../model/PlayerData";
import BaseAPI from "../../service/BaseAPI";
import { generateGoogleLink } from "./LoginPage";
import i18next from "i18next";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import styles from "./styles/Login.module.scss"
import confirmationDialog from "../../ui/confirmationDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserBountyHunter} from "@fortawesome/pro-duotone-svg-icons";


interface CollectionCreateFormProps
{
    visible: boolean;
    onCreate: (suportRequest: string) => void;
    onCancel: () => void;
}


const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
    visible,
    onCreate,
    onCancel,
}) =>
{
    const [form] = Form.useForm();
    return (
        <Modal
            visible={visible}
            title="Link new Game Account"
            okText="Link"
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() =>
            {
                form
                    .validateFields()
                    .then(values =>
                    {
                        form.resetFields();
                        onCreate(values.allyCode);
                    })
                    .catch(info =>
                    {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item
                    name="allyCode"
                    label="Ally Code or Discord Tag"
                    rules={[{ required: true, message: 'Please input an ally code or discord tag' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

interface ConnectionIdDlgProps
{
    visible: boolean;
    onCancel: () => void;
}

const ConnectionIdDlg: React.FC<ConnectionIdDlgProps> = (props: ConnectionIdDlgProps) =>
{
    const [form] = Form.useForm();
    return (
        <Modal
            visible={props.visible}
            title="Contact Hotsauce or Login with Google"
            okText="Close"
            cancelButtonProps={{ disabled: true, style: { display: 'none' } }}
            onCancel={props.onCancel}
            onOk={props.onCancel}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <h2>If you use Google Play, some accounts are directly compatible. Try the button below.<br/>If you are on iOS or the button doesn't work, you need to contact Hotsauce#4756 on discord to form a game connection with another method</h2>
                <a href={generateGoogleLink()}>
                    <Button type="primary" value="large" block>{i18next.t("common:login.login_with_google")}</Button>
                </a>
            </Form>
        </Modal>
    );
}

@observer
class AccountSelectionPage extends React.Component<IPageProps> {
    @observable showConnectionIdDlg: boolean = false;
    @observable connectionID: string = "";
    @observable connectionPlayer: PlayerData | null = null;
    @observable createDlgVisible: boolean = false;

    componentDidMount()
    {
        if (this.props.user.players == null) {
            BaseAPI.discordGetPlayers(this.props.user).then(playerAccounts => {
                if(playerAccounts.length === 1 && !this.props.user.isSupportStaff) {

                    const player = playerAccounts[0];

                    if(player) {
                        this.selectAllyCode(player);
                    }
                }
            });
        }
    }

    private async onSupportRequest(supportRequest: string)
    {
        supportRequest = supportRequest.trim();
        let modal = Modal.info({ maskClosable: false, okButtonProps: { disabled: true, style: { display: 'none' } }, title: "Please wait, logging in", content: <Spin size="large" /> });
        try
        {
            await BaseAPI.discordSupportChoosePlayer(this.props.user, this.props.gameData, supportRequest);
            runInAction(() => {
                this.createDlgVisible = false;
            });
        }
        catch (err: any)
        {
            modal.update({ content: "Error: " + err.errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
            return;
        }
        modal.destroy();
        this.props.history.push('/');
    }

    private async selectAllyCode(item: PlayerData): Promise<void>
    {
        //if (!item.hasConnection)
        if (false)
        {
            /*if (item.hasGoogle)
            {
                window.location.assign(generateGoogleLink());
                return;
            }*/
            runInAction(() =>
            {
                this.connectionPlayer = item;
                this.showConnectionIdDlg = true;
            })
            return;
        }
        let modal = Modal.info({ maskClosable: false, okButtonProps: { disabled: true, style: { display: 'none' } }, title: "Please wait, logging in", content: <Spin size="large" /> });
        const rememberMe = localStorage.getItem("rememberMe") === "1";

        try
        {
            await BaseAPI.discordChoosePlayer(this.props.user, this.props.gameData, item, rememberMe ? this.props.cookies : undefined);
        }
        catch (response: any)
        {
            modal.update({ content: "Error: " + response.errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
            return;
        }
        modal.destroy();
        this.props.history.push('/');
    }

    private async onUnlink(player: PlayerData): Promise<void>
    {
        let confirm = await confirmationDialog("Unlink account", "Are you sure you want to unlink " + player.name);
        if (!confirm)
            return;
        
        let modal = Modal.info({ maskClosable: false, okButtonProps: { disabled: true, style: { display: 'none' } }, title: "Please wait", content: <Spin size="large" /> });

        try
        {
            await BaseAPI.unlinkSharedAccount(this.props.user, player.allyCode);
            await BaseAPI.discordGetPlayers(this.props.user);
            modal.destroy();
        }
        catch (response: any)
        {
            modal.update({ content: "Error: " + response.errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
            return;
        }
    }

    @action
    private onConnectionIdCancel(): void
    {
        this.showConnectionIdDlg = false;
    }

    private renderSupportSection(): React.ReactNode
    {
        if (!this.props.user.isSupportStaff)
            return null;
        
        return <div className={styles.list} >
            <Button onClick={action(() => this.createDlgVisible = true)}>Support User Login</Button>
            <CollectionCreateForm
                        visible={this.createDlgVisible}
                        onCreate={(supportId: string) =>
                        {
                            this.onSupportRequest(supportId);
                        }}
                        onCancel={action(() =>
                        {
                            this.createDlgVisible = false;
                        })}
                    />
        </div>
    }

    render()
    {
        if (this.props.user.players == null)
        {
            return <LoadingSpinner size={"large"} spinning={true} text={"Loading accounts.."} />
        }

        return (
            <SelectorLayout>
                <List
                    itemLayout="horizontal"
                    className={styles.list}
                    dataSource={this.props.user.players}
                    rowKey={(item) => item.allyCode}
                    renderItem={item => (
                        <List.Item onClick={() => this.selectAllyCode(item)}>
                            <List.Item.Meta
                                className={styles.item}
                                avatar={<FontAwesomeIcon icon={faUserBountyHunter}  className={styles.avatar}/>}
                                title={item.name}
                                description={<div>
                                            <Typography>{"Ally Code: " + item.allyCode}</Typography>
                                            {!item.hasConnection && <><br/><Typography>No Game Connection</Typography></>}
                                            {item.readOnly && <Typography>Read Only</Typography>}
                                            {item.shared && <Button onClick={(e) => { e.stopPropagation(); this.onUnlink(item); }}>Unlink Account</Button>}
                                            </div>}
                            />
                        </List.Item>
                    )}
                />
                <ConnectionIdDlg visible={this.showConnectionIdDlg}
                    onCancel={() => this.onConnectionIdCancel()}
                />
                {this.renderSupportSection()}
{/*
                <div style={{ paddingTop: '20px', textAlign: 'center', }}>
                    <Button
                        type="primary"

                        onClick={action(() =>
                        {
                            this.createDlgVisible = true;
                        })}
                    >
                        Link new Game Account
                    </Button>
                    <CollectionCreateForm
                        visible={this.createDlgVisible}
                        onCreate={(allyCode: string) =>
                        {
                            this.onCreate(allyCode);
                        }}
                        onCancel={action(() =>
                        {
                            this.createDlgVisible = false;
                        })}
                    />
                </div>
                    */}
            </SelectorLayout>
        );
    }

};


export default AccountSelectionPage;
