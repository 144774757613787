import { observable } from "mobx";

class PlayerCharacterSummaryData
{
    @observable totalScore: number = 0;
    @observable gearScore: number = 0;
    @observable modScore: number = 0;
    @observable glCount: number = 0;
    @observable characterGP80: number = 0;
    @observable characterGP65: number = 0;
    @observable ultimateGLCount: number = 0;
    @observable star7Count: number = 0;
    @observable speed10: number = 0;
    @observable speed15: number = 0;
    @observable speed20: number = 0;
    @observable speed25: number = 0;
    @observable mod6Dot: number = 0;
    @observable g13Count: number = 0;
    @observable g12Count: number = 0;
    @observable g11Count: number = 0;
    @observable twOmiCount: number = 0;
    @observable tbOmiCount: number = 0;
    @observable gacOmiCount: number = 0;
    @observable cqOmiCount: number = 0;
    @observable omiCount: number = 0;
    @observable zetaCount: number = 0;
    @observable relic0Count: number = 0;
    @observable relic1Count: number = 0;
    @observable relic2Count: number = 0;
    @observable relic3Count: number = 0;
    @observable relic4Count: number = 0;
    @observable relic5Count: number = 0;
    @observable relic6Count: number = 0;
    @observable relic7Count: number = 0;
    @observable relic8Count: number = 0;
    @observable relic9Count: number = 0;
    @observable skillRating: number = 0;

    constructor(json: any)
    {
        if (json != null)
        {
            this.totalScore = json.totalScore;
            this.gearScore = json.gearScore;
            this.modScore = json.modScore;
            this.glCount = json.glCount;
            this.characterGP80 = json.characterGP80;
            this.characterGP65 = json.characterGP65;
            this.ultimateGLCount = json.ultimateGLCount;
            this.star7Count = json.star7Count;
            this.speed10 = json.speed10;
            this.speed15 = json.speed15;
            this.speed20 = json.speed20;
            this.speed25 = json.speed25;
            this.mod6Dot = json.mod6Dot;
            this.g13Count = json.g13Count;
            this.g12Count = json.g12Count;
            this.g11Count = json.g11Count;
            this.omiCount = json.omiCount;
            this.cqOmiCount = json.cqOmiCount;
            this.twOmiCount = json.twOmiCount;
            this.tbOmiCount = json.tbOmiCount;
            this.gacOmiCount = json.gacOmiCount;
            this.zetaCount = json.zetaCount;
            this.relic0Count = json.relic0Count;
            this.relic1Count = json.relic1Count;
            this.relic2Count = json.relic2Count;
            this.relic3Count = json.relic3Count;
            this.relic4Count = json.relic4Count;
            this.relic5Count = json.relic5Count;
            this.relic6Count = json.relic6Count;
            this.relic7Count = json.relic7Count;
            this.relic8Count = json.relic8Count;
            this.relic9Count = json.relic9Count;
            this.skillRating = json.skillRating;
        }
    }
}

export default PlayerCharacterSummaryData;