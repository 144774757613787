export class UnitCategory
{
    key: string;
    display: string;

    constructor(json: any)
    {
        this.key = json.key;
        this.display = json.display;
    }
}

export class Zeta implements Upgrade
{
    key: string;
    display: string;
    leader: boolean;

    constructor(json: any)
    {
        this.key = json.key;
        this.display = json.display;
        this.leader = json.leader;
    }
}

export interface Upgrade
{
    key: string;
    display: string;
    leader: boolean;
}

export interface IBasicUnitData
{
    baseId: string;
    gearLevel: number;
    level: number;
    rarity?: number;
    stars?: number;
    relicLevel: number;
    ultimate: boolean;
    omiCount: number;
    zetaCount: number;
    zetaLead: boolean;
}

export interface IUnitAbbreviationProperties
{
    baseId: string;
    abbreviation: string;
    preferred: boolean;
}

export interface IUnitAbbreviation extends IUnitAbbreviationProperties
{
    dbId: number;
    name: string;
}

export interface IUnitAbbreviationRecord
{
    dbId: number | null;
    unit: IUnitAbbreviationProperties;
    void: boolean | null;
}

export class UnitData
{
    baseId: string;
    name: string;
    baseImage: string;
    combatType: number;
    alignment: number;
    galacticLegend: boolean;
    fleetCommander: boolean;
    hasZetaLead: boolean;
    hasOmicronLead: boolean;
    affiliation: UnitCategory[] | null = null;
    profession: UnitCategory[] | null = null;
    role: UnitCategory[] | null = null;
    species: UnitCategory[] | null = null;
    other: UnitCategory[] | null = null;
    zeta: Zeta[] | null = null;
    omicron: Upgrade[] | null = null;
    abbreviations: string[] | null = null;
    shipBaseId: string | null = null;

    constructor(json: any)
    {
        this.baseId = json.baseId;
        this.name = json.name;
        this.baseImage = json.baseImage;
        this.combatType = json.combatType;
        this.alignment = json.alignment;
        this.galacticLegend = json.galacticLegend;
        this.fleetCommander = json.fleetCommander;
        this.hasZetaLead = json.hasZetaLead;
        this.hasOmicronLead = json.hasOmicronLead;
        this.omicron = json.omicron;

        if (json.affiliation != null)
        {
            this.affiliation = json.affiliation.map((affiliation: any) => new UnitCategory(affiliation));
        }
        if (json.profession != null)
        {
            this.profession = json.profession.map((affiliation: any) => new UnitCategory(affiliation));
        }
        if (json.role != null)
        {
            this.role = json.role.map((affiliation: any) => new UnitCategory(affiliation));
        }
        if (json.species != null)
        {
            this.species = json.species.map((affiliation: any) => new UnitCategory(affiliation));
        }
        if (json.other != null)
        {
            this.other = json.other.map((affiliation: any) => new UnitCategory(affiliation));
        }
        if (json.zeta != null)
        {
            this.zeta = json.zeta.map((zeta: any) => new Zeta(zeta));
        }
        if (json.abbreviations)
        {
            this.abbreviations = json.abbreviations;
        }
        if (json.shipBaseId)
        {
            this.shipBaseId = json.shipBaseId;
        }
    }

    static unitByBaseId(baseId: string, units: UnitData[]): UnitData
    {
        return units.find(u => u.baseId === baseId)!;
    }
}



export default UnitData;
