import GameData from "../../../model/GameData";
import { RosterUnit, TerritoryBattleDefinition } from "../../../model/TerritoryBattleGameData";
import { CombatNodeStrategy, RoundStrategy, TbGuildData, TbGuildPlayer, TbInstanceData, TbRoundInstance, ZoneStrategy } from "./model";

export class PlayerRoundCalculations
{
    expectedPoints: Map<string, number> = new Map();
    totalExpectedPoints: number;

    combatValidationWarnings: Map<string, string[]>;
    combatValidationErrors: Map<string, string[]>;

    constructor(strategy: RoundStrategy, player: TbGuildPlayer, tbDef: TerritoryBattleDefinition, gameData: GameData)
    {
        this.totalExpectedPoints = 0;

        this.combatValidationWarnings = new Map();
        this.combatValidationErrors = new Map();

        const ps = strategy.getPlayerStrategy(player.allyCode);
        ps.combatNodeStrategies.forEach(cns =>
        {
            const szd = tbDef.getStrikeZone(cns.combatNodeId);

            this.combatValidationWarnings.set(cns.combatNodeId, []);
            this.combatValidationErrors.set(cns.combatNodeId, []);

            if (cns.expectedWaves !== undefined && szd !== undefined)
            {
                const missionPoints = cns.getExpectedPoints(szd);
                this.expectedPoints.set(cns.combatNodeId, missionPoints);
                this.totalExpectedPoints = this.totalExpectedPoints + missionPoints;
            }

            if (cns.isEmpty() === false && szd !== undefined && szd.campaign !== null)
            {
                const unitList = cns.getUnitList(gameData);
                const rosterUnits: RosterUnit[] = [];
                const missingUnitsWarnings: string[] = [];

                unitList.forEach(u =>
                {
                    const rosterUnit = player.roster.find(ru => ru.baseId === u.baseId);
                    if (rosterUnit === undefined)
                    {
                        missingUnitsWarnings.push(u.name + ' is not unlocked yet.');
                    } else
                    {
                        rosterUnits.push(rosterUnit);
                    }
                });
                this.combatValidationErrors.set(cns.combatNodeId, szd.campaign.getSquadErrors(unitList, gameData));
                const warnings = missingUnitsWarnings.concat(szd.campaign.getSquadWarnings(rosterUnits, gameData));
                this.combatValidationWarnings.set(cns.combatNodeId, warnings);
            }

        });
    }
}

export interface FavoriteSquad
{
    count: number;
    leadUnit: string | undefined;
    units: string;
}

export class RoundCombatFavorites
{
    squads: FavoriteSquad[] = [];

    addSquad(cns: CombatNodeStrategy)
    {
        if (cns.leaderUnit !== undefined || cns.units.length > 0)
        {
            const existingFav = this.squads.find(f => RoundCombatFavorites.same(cns, f));
            if (existingFav === undefined)
            {
                this.squads.push({
                    count: 1,
                    leadUnit: cns.leaderUnit,
                    units: RoundCombatFavorites.getUnitsKey(cns)
                });
            } else
            {
                existingFav.count = existingFav.count + 1;
            }
        }
    }

    static getUnitsKey(cns: CombatNodeStrategy): string
    {
        return cns.units.slice().sort((u1, u2) => u1.localeCompare(u2)).join(',');
    }

    static getUnitsListFromKey(units: string): string[]
    {
        return units.split(',');
    }

    static same(cns: CombatNodeStrategy, squadFav: FavoriteSquad): boolean
    {
        return cns.leaderUnit === squadFav.leadUnit && squadFav.units === RoundCombatFavorites.getUnitsKey(cns);
    }

    sortSquads()
    {
        this.squads.sort((s1, s2) => s2.count - s1.count);
    }
}

export class PlatoonRequirement
{
    unitBaseId: string;
    relic: number;

    constructor(unitBaseId: string, relic: number)
    {
        this.unitBaseId = unitBaseId;
        this.relic = relic;
    }

    keyValue(): string
    {
        return this.unitBaseId + "#" + this.relic;
    }

    static fromKeyValue(key: string): PlatoonRequirement
    {
        const keyParts = key.split('#');
        return new PlatoonRequirement(keyParts[0], Number(keyParts[1]));
    }
}


export class RoundCalculations
{
    playerRoundCalculations: Map<number, PlayerRoundCalculations>;

    guildMemberCount: number;
    guildGp: number;
    totalGpNeeded: number = 0;
    totalGpNeededSandbag: number = 0;
    totalPlatoonBonus: number = 0;
    totalPlatoonSandbagBonus: number = 0;
    missionGpRequired: number;
    sandbagMissionGpRequired: number;

    totalMissionPointsAvailable: number = 0;
    totalMissionPointsSandbagAvailable: number = 0;

    totalMissionPointsAvailablePerZone: Map<string, number> = new Map();

    expectedSandbagAmount: number;
    previousSandbagAmount: number;

    totalGpNeededPerZone: Map<string, number> = new Map();

    maxGpPerZone: Map<string, number> = new Map();

    totalSandbaggableGp: number = 0;

    usingActualPreviousRoundData = false;

    previousRoundInstance: TbRoundInstance | undefined;
    thisRoundInstance: TbRoundInstance | undefined;

    expectedMissionPointsNonSandbagZones: number;
    expectedMissionPointsSandbagZones: number;

    deploymentNeededNonSandbagZones: number;
    deploymentAvailableForSandbagZones: number;

    sandbagAmountCarryOver: number;

    platoonBonusByZone: Map<string, number> = new Map();


    getPreviousRoundInstance(currentRound: number, tbInstanceData: TbInstanceData): TbRoundInstance | undefined
    {
        const previousRound = (tbInstanceData.activeRound === undefined || tbInstanceData.activeRound >= currentRound) ?
            currentRound - 1 : tbInstanceData.activeRound;
        return tbInstanceData.getRound(previousRound);
    }

    getPreviousSandbagAmount(strategy: RoundStrategy, previousRc: RoundCalculations | null,
        previousRoundInstance: TbRoundInstance | undefined, tbInstanceData: TbInstanceData):
        { previousSandbagAmount: number, usingActualPreviousRoundData: boolean }
    {
        const retVal = {
            previousSandbagAmount: 0,
            usingActualPreviousRoundData: false
        };
        retVal.previousSandbagAmount = previousRc === null ? 0 : previousRc.expectedSandbagAmount;

        // dont use previous round data if it is the current round. allow users to plan off mission expectations
        if (previousRoundInstance !== undefined && tbInstanceData.activeRound !== undefined)
        {
            let actualSandbagAmount = 0;
            strategy.zoneStrategies.forEach(zs => 
            {
                actualSandbagAmount += previousRoundInstance!.getScoreByZone(zs.zoneId);
            });

            if (tbInstanceData.activeRound >= strategy.round || actualSandbagAmount > retVal.previousSandbagAmount)
            {
                retVal.usingActualPreviousRoundData = true;
                retVal.previousSandbagAmount = actualSandbagAmount;
            }
        }
        return retVal;
    }

    constructor(strategy: RoundStrategy, previousStrategy: RoundStrategy | undefined, guildData: TbGuildData, tbDef: TerritoryBattleDefinition,
        gameData: GameData, previousRc: RoundCalculations | null, tbInstanceData: TbInstanceData)
    {
        this.thisRoundInstance = tbInstanceData.getRound(strategy.round);
        // set previous round to current round if this round has not occured yet
        this.previousRoundInstance = this.getPreviousRoundInstance(strategy.round, tbInstanceData);

        // get the sandbag amounts
        const sandbagAmount = this.getPreviousSandbagAmount(strategy, previousRc, this.previousRoundInstance, tbInstanceData);
        this.usingActualPreviousRoundData = sandbagAmount.usingActualPreviousRoundData;
        this.previousSandbagAmount = sandbagAmount.previousSandbagAmount;


        this.guildMemberCount = guildData.players.length;
        this.guildGp = guildData.guild.guildGalacticPower;

        this.extractTotalGpNeeded(strategy, tbDef);
        this.extractTotalPlatoonBonus(strategy, tbDef, previousStrategy, this.previousRoundInstance);

        // if the double sandbag a zone, carry over the sandbag amount
        const sandbagAmountUsed = this.totalGpNeeded > this.previousSandbagAmount ? this.previousSandbagAmount :
            this.totalGpNeeded;
        this.sandbagAmountCarryOver = this.previousSandbagAmount - sandbagAmountUsed;

        this.missionGpRequired = this.totalGpNeeded - (this.totalPlatoonBonus + this.guildGp + this.previousSandbagAmount);

        this.sandbagMissionGpRequired = this.totalGpNeededSandbag - (this.totalPlatoonSandbagBonus + this.guildGp + this.previousSandbagAmount);

        this.extractTotalMissionPointsPossible(strategy, tbDef);

        this.playerRoundCalculations = new Map();

        this.expectedMissionPointsNonSandbagZones = (strategy.getMissionGpEstimate(false) || 0);

        this.deploymentNeededNonSandbagZones = this.totalGpNeeded - this.totalPlatoonBonus - this.expectedMissionPointsNonSandbagZones -
            this.previousSandbagAmount;
        this.deploymentNeededNonSandbagZones = this.deploymentNeededNonSandbagZones > 0 ? this.deploymentNeededNonSandbagZones : 0;

        this.expectedMissionPointsSandbagZones = (strategy.getMissionGpEstimate(true) || 0) - this.expectedMissionPointsNonSandbagZones;

        this.deploymentAvailableForSandbagZones = this.guildGp > this.deploymentNeededNonSandbagZones ?
            this.guildGp - this.deploymentNeededNonSandbagZones : 0;

        this.expectedSandbagAmount = this.deploymentAvailableForSandbagZones + this.expectedMissionPointsSandbagZones +
            (this.totalPlatoonSandbagBonus - this.totalPlatoonBonus) + this.sandbagAmountCarryOver;

        // can't sandbag past one star in each zone even if you have enough deploy gp to do so
        this.expectedSandbagAmount = this.expectedSandbagAmount > this.totalSandbaggableGp ? this.totalSandbaggableGp : this.expectedSandbagAmount;
    }

    extractTotalGpNeeded(strategy: RoundStrategy, tbDef: TerritoryBattleDefinition)
    {
        this.totalGpNeeded = 0;
        this.totalGpNeededSandbag = 0;

        strategy.zoneStrategies.forEach(z =>
        {
            const conflictZone = tbDef.getZoneGameDefinition(z.zoneId);
            const vrp = conflictZone?.victoryPointRewards.slice().sort((vrp1, vrp2) => vrp1.galacticScoreRequirement - vrp2.galacticScoreRequirement);
            if (vrp && z.starTarget !== undefined && z.starTarget !== 0 && vrp.length >= z.starTarget)
            {
                const zoneGpNeeded = vrp[z.starTarget - 1].galacticScoreRequirement;
                this.totalGpNeeded = this.totalGpNeeded + zoneGpNeeded;
                this.totalGpNeededSandbag = this.totalGpNeededSandbag + zoneGpNeeded;
                this.totalGpNeededPerZone.set(z.zoneId, zoneGpNeeded);
                this.maxGpPerZone.set(z.zoneId, zoneGpNeeded);
            }
            if (vrp && z.starTarget !== undefined && z.starTarget === 0 && vrp.length >= z.starTarget)
            {
                const zoneGpNeeded = vrp[0].galacticScoreRequirement - 1;
                this.totalSandbaggableGp = this.totalSandbaggableGp + zoneGpNeeded;
                this.totalGpNeededSandbag = this.totalGpNeededSandbag + zoneGpNeeded;
                this.maxGpPerZone.set(z.zoneId, zoneGpNeeded);
            }
        });
    }


    extractTotalPlatoonBonus(strategy: RoundStrategy, tbDef: TerritoryBattleDefinition, previousStrategy: RoundStrategy | undefined,
        previousRoundInstance: TbRoundInstance | undefined)
    {
        this.totalPlatoonBonus = 0;
        this.totalPlatoonSandbagBonus = 0;

        strategy.zoneStrategies.forEach(z =>
        {
            const reconZone = tbDef.getReconZone(z.zoneId);

            let platoonBonusZone = 0;
            if (reconZone)
            {
                reconZone.platoonDefinition.filter(pd => z.targetCompletePlatoons.includes(pd.id)).forEach(pd =>
                {
                    let platoonPreviouslyFilled = false;
                    if (previousRoundInstance)
                    {
                        const reconStatus = previousRoundInstance.tbData.getReconZoneStatus(reconZone.zoneDefinition.zoneId);
                        if (reconStatus.getPlatoonStatus(ZoneStrategy.mapPlatoonId(pd.id)).isPlatoonFilled())
                        {
                            platoonPreviouslyFilled = true;
                        }
                    } else if (previousStrategy && previousStrategy.platoonFilled(z.zoneId, pd.id))
                    {
                        // only use plan when there is no historical data
                        platoonPreviouslyFilled = true;
                    }
                    // dont add platoon bonus this phase because it should already be included in sandbag amount
                    this.totalPlatoonSandbagBonus = platoonPreviouslyFilled ? this.totalPlatoonSandbagBonus :
                        this.totalPlatoonSandbagBonus + pd.reward.value;

                    if (z.isSandbag() === false)
                    {
                        // we can always add the platoon bonus to next phase
                        this.totalPlatoonBonus = this.totalPlatoonBonus + pd.reward.value;
                    }
                    platoonBonusZone += pd.reward.value;
                })
            }
            this.platoonBonusByZone.set(z.zoneId, platoonBonusZone);
        });
        console.log('round: ' + strategy.round);
        console.log('totalPlatoonBonus: ' + this.totalPlatoonBonus);
        console.log('totalPlatoonSandbagBonus: ' + this.totalPlatoonSandbagBonus);
    }

    extractTotalMissionPointsPossible(strategy: RoundStrategy, tbDef: TerritoryBattleDefinition)
    {
        this.totalMissionPointsAvailable = 0;
        this.totalMissionPointsSandbagAvailable = 0;

        strategy.zoneStrategies.forEach(zs =>
        {
            const strikeZones = tbDef.getStrikeZones(zs.zoneId);


            let missionPointsZone = 0;

            if (strikeZones)
            {

                strikeZones.forEach(z =>
                {
                    this.totalMissionPointsSandbagAvailable = this.totalMissionPointsSandbagAvailable + (z.getTotalPossibleMissionPoints() * this.guildMemberCount);

                    if (zs.isSandbag() === false)
                    {
                        this.totalMissionPointsAvailable = this.totalMissionPointsAvailable + (z.getTotalPossibleMissionPoints() * this.guildMemberCount);
                    }
                    missionPointsZone += z.getTotalPossibleMissionPoints() * this.guildMemberCount;
                });

            }
            this.totalMissionPointsAvailablePerZone.set(zs.zoneId, missionPointsZone);
        });
    }
}

export class PlayerCalulations
{
    validUnitsForMission: Map<string, string[]> = new Map();

    constructor(player: TbGuildPlayer, tbDef: TerritoryBattleDefinition, validUnitsForMission: Map<string, string[]>)
    {
        tbDef.strikeZoneDefinition.filter(szd => szd.campaign !== null).forEach(szd =>
        {
            const zoneId = szd.zoneDefinitionData.zoneId;

            const validUnits = validUnitsForMission.get(szd.zoneDefinitionData.zoneId)!;
            this.validUnitsForMission.set(zoneId, validUnits.filter(unitBaseId =>
            {
                const playerUnit = player.roster?.find(ru => ru.baseId === unitBaseId);
                return playerUnit !== undefined && szd.campaign!.unitMeetsCriteria(playerUnit);
            }));
        });

        tbDef.covertZoneDefinition.filter(szd => szd.campaign !== null).forEach(szd =>
        {
            const zoneId = szd.zoneDefinition.zoneId;

            const validUnits = validUnitsForMission.get(szd.zoneDefinition.zoneId)!;
            this.validUnitsForMission.set(zoneId, validUnits.filter(unitBaseId =>
            {
                const playerUnit = player.roster?.find(ru => ru.baseId === unitBaseId);
                return playerUnit !== undefined && szd.campaign!.unitMeetsCriteria(playerUnit);
            }));
        });
    }
}

export class TbCalculations
{
    validUnitsForMission: Map<string, string[]> = new Map();
    playerCalulations: Map<number, PlayerCalulations> = new Map();
    roundCalculations: Map<number, RoundCalculations> = new Map();

    gameData: GameData;

    constructor(gameData: GameData, guildData: TbGuildData, tbDef: TerritoryBattleDefinition)
    {
        this.gameData = gameData;
        tbDef.strikeZoneDefinition.filter(szd => szd.campaign !== null).forEach(szd =>
        {
            this.validUnitsForMission.set(szd.zoneDefinitionData.zoneId, gameData.units!.filter(u => szd.campaign!.meetsCriteria(u)).map(u => u.baseId));
        });

        tbDef.covertZoneDefinition.filter(szd => szd.campaign !== null).forEach(szd =>
        {
            this.validUnitsForMission.set(szd.zoneDefinition.zoneId, gameData.units!.filter(u => szd.campaign!.meetsCriteria(u)).map(u => u.baseId));
        });


        guildData.players.forEach(player =>
        {
            this.playerCalulations.set(player.allyCode, new PlayerCalulations(player, tbDef, this.validUnitsForMission));
        });
    }

    calculateRounds(guildData: TbGuildData, tbDef: TerritoryBattleDefinition,
        roundStrategies: Map<number, RoundStrategy>, tbInstanceData: TbInstanceData)
    {
        var startTime = performance.now()

        this.roundCalculations = new Map();

        let previousRc: RoundCalculations | null = null;

        Array.from(roundStrategies.keys()).forEach(round =>
        {
            const rc = new RoundCalculations(roundStrategies.get(round)!, roundStrategies.get(round - 1), guildData, tbDef,
                this.gameData, previousRc, tbInstanceData);
            this.roundCalculations.set(round, rc);
            previousRc = rc;
        });
        var endTime = performance.now()

        console.log(`TbCalculations.calculateRounds ${endTime - startTime} milliseconds`)

    }
}
