import { LoadoutDefinition, LoadoutDefinitionCalculator, LoadoutDefinitionTarget } from "../../../../model/LoadoutDefinition";
import { isModable, UnitStats } from '../PlaygroundData';
import { PlaygroundPlayerSettings } from './../PlaygroundData';
import { ModData } from './../../../../model/ModData';
import { UnitData } from './../../../../model/UnitData';
import StatIds from "../../../../model/StatIds";
import { ModCalculator } from "../../../../utils/mod-calculator";
import { StatCalculatorUnitOutput } from "./ModStats";
import { AvailableModAssignments } from "./ModAssignment";

export const WORKING_AUTOMATION_INPUT = "working";
export const CONFIRMED_AUTOMATION_INPUT = "confirmed";
export const CONFIRMED_AUTOMATION_UNIT_INPUT = "confirmedunit";

/*
* Data objects for input and output of mod automation workers
*/

export class ModAutomationSettings
{
    constructor(json: any)
    {
        if (json.dontBreakSets !== undefined && json.dontBreakSets !== null) this.dontBreakSets = json.dontBreakSets;
        if (json.searchAfterFoundDepth !== undefined && json.searchAfterFoundDepth !== null) this.searchAfterFoundDepth = json.searchAfterFoundDepth;
        if (json.minimizeSpeedOnRequired !== undefined && json.minimizeSpeedOnRequired !== null) this.minimizeSpeedOnRequired = json.minimizeSpeedOnRequired;
        if (json.simulateAsSix !== undefined && json.simulateAsSix !== null) this.simulateAsSix = json.simulateAsSix;
        if (json.simulateAsSixMinSpeed !== undefined && json.simulateAsSixMinSpeed !== null) this.simulateAsSixMinSpeed = json.simulateAsSixMinSpeed;
        if (json.promptSettings !== undefined && json.promptSettings !== null) this.promptSettings = json.promptSettings;
        if (json.minimizeSpeed !== undefined && json.minimizeSpeed !== null) this.minimizeSpeed = json.minimizeSpeed;
        if (json.speedSmoothingFactor !== undefined && json.speedSmoothingFactor !== null) this.speedSmoothingFactor = json.speedSmoothingFactor;
        if (json.autoReorder !== undefined && json.autoReorder !== null) this.autoReorder = json.autoReorder;
        if (json.deriveRequirements !== undefined && json.deriveRequirements !== null) this.deriveRequirements = json.deriveRequirements;
        if (json.attemptDesiredTargets !== undefined && json.attemptDesiredTargets !== null) this.attemptDesiredTargets = json.attemptDesiredTargets;
        if (json.limitAutomation !== undefined && json.limitAutomation !== null) this.limitAutomation = json.limitAutomation;
        if (json.stopAtAutoReorder !== undefined && json.stopAtAutoReorder !== null) this.stopAtAutoReorder = json.stopAtAutoReorder;
        if (json.desiredAsRequiredAboveUnit !== undefined && json.desiredAsRequiredAboveUnit !== null) this.desiredAsRequiredAboveUnit = json.desiredAsRequiredAboveUnit;
        if (json.useExistingSet !== undefined && json.useExistingSet !== null) this.useExistingSet = json.useExistingSet;
        if (json.favorSpeedArrows !== undefined && json.favorSpeedArrows !== null) this.favorSpeedArrows = json.favorSpeedArrows;
        if (json.favorWeight !== undefined && json.favorWeight !== null) this.favorWeight = json.favorWeight;
        if (json.uncappedReorder !== undefined && json.uncappedReorder !== null) this.uncappedReorder = json.uncappedReorder;
        if (json.exitAfterFailure !== undefined && json.exitAfterFailure !== null) this.exitAfterFailure = json.exitAfterFailure;
        if (json.maxReorderPerUnit !== undefined && json.maxReorderPerUnit !== null) this.maxReorderPerUnit = json.maxReorderPerUnit;
        if (json.ignoreNonLockerMods !== undefined && json.ignoreNonLockerMods !== null) this.ignoreNonLockerMods = json.ignoreNonLockerMods;
        if (json.fillLockerUnit !== undefined && json.fillLockerUnit !== null) this.fillLockerUnit = json.fillLockerUnit;
        if (json.autoFillLockerUnits !== undefined && json.autoFillLockerUnits !== null) this.autoFillLockerUnits = json.autoFillLockerUnits;
        if (json.dontPromptLocker !== undefined && json.dontPromptLocker !== null) this.dontPromptLocker = json.dontPromptLocker;
    }

    static newInstance(): ModAutomationSettings
    {
        return new ModAutomationSettings({});
    }

    dontBreakSets: boolean = false;
    searchAfterFoundDepth: number | undefined = 200000;
    minimizeSpeedOnRequired: boolean = false;
    simulateAsSixMinSpeed: number = 0;
    speedSmoothingFactor: number = 5;
    simulateAsSix: boolean = false;
    promptSettings: boolean = true;
    minimizeSpeed: boolean = false;
    autoReorder: boolean = false;
    deriveRequirements: boolean = true;
    attemptDesiredTargets: boolean = false;
    limitAutomation: boolean = true;
    stopAtAutoReorder: string | undefined = undefined;
    desiredAsRequiredAboveUnit: string | undefined = undefined;
    useExistingSet: boolean = false;
    favorSpeedArrows: boolean = false;
    useBeta: boolean = false;
    favorWeight: number = 20;
    uncappedReorder: boolean = false;
    maxReorderPerUnit: number = 300;
    exitAfterFailure: boolean = false;

    dontPromptLocker: boolean = false;

    ignoreNonLockerMods: boolean = true;
    fillLockerUnit: boolean = true;
    autoFillLockerUnits: boolean = true;
}

export class RelatedUnit
{
    unitBaseId: string;
    baseStats: UnitStats;
    gearStats: UnitStats;
    mods: ModData[];
    level: number;
    gearLevel: number;
    target: LoadoutDefinitionTarget;

    constructor(unitBaseId: string, baseStats: UnitStats, gearStats: UnitStats, mods: ModData[], level: number, gearLevel: number, target: LoadoutDefinitionTarget)
    {
        this.unitBaseId = unitBaseId;
        this.baseStats = baseStats;
        this.gearStats = gearStats;
        this.mods = mods;
        this.level = level;
        this.gearLevel = gearLevel;
        this.target = target;
    }
}

export class ModAutomationCalculatorInput
{
    source: string;
    units: ModAutomationCalculatorUnitInput[];
    relatedUnits: RelatedUnit[];// Eventually if we add automation that factors in other unit targets, we can use this
    availableMods: ModData[];
    slicedMods: ModData[];
    settings: ModAutomationSettings;
    stopAutoReorder: number | undefined;
    desiredAsRequiredAboveUnit: number | undefined;

    lockerUnits: string[];

    loadoutDefinition: LoadoutDefinition;

    constructor(source: string, units: ModAutomationCalculatorUnitInput[], availableMods: ModData[], stopIndex: number | undefined,
        desiredAsRequiredAboveUnit: number | undefined,
        settings: ModAutomationSettings, relatedUnits: RelatedUnit[], loadoutDefinition: LoadoutDefinition, lockerUnits: string[])
    {
        this.source = source;
        this.units = units.slice(0);
        this.availableMods = availableMods;
        this.slicedMods = availableMods.map(mod => mod.slicedMod === null || mod.rarity !== 5 ||
            (mod.primary.statId !== StatIds.Speed &&
                ModCalculator.getSecondaryBonus(mod, StatIds.Speed) < settings.simulateAsSixMinSpeed * ModCalculator.MULTIPLIER_FACTOR) ? mod : mod.slicedMod);
        this.settings = settings;
        this.relatedUnits = relatedUnits;
        this.loadoutDefinition = JSON.parse(JSON.stringify(loadoutDefinition));
        this.lockerUnits = lockerUnits;

        this.stopAutoReorder = stopIndex;
        this.desiredAsRequiredAboveUnit = desiredAsRequiredAboveUnit;

        if (settings.deriveRequirements)
        {
            this.loadoutDefinition.targets = this.loadoutDefinition.targets.slice(0);

            let derivedTargets = LoadoutDefinitionCalculator.deriveHardRanges(loadoutDefinition.targets);
            let dt = derivedTargets.rangeResults;
            Array.from(dt.keys()).forEach(unitId =>
            {
                let existingTarget = this.loadoutDefinition.targets.find(t => t.unitBaseId === unitId);
                if (existingTarget === undefined)
                {
                    existingTarget = LoadoutDefinitionTarget.newInstance(unitId);
                    this.loadoutDefinition.targets.push(existingTarget);
                } else
                {
                    existingTarget = new LoadoutDefinitionTarget(existingTarget);

                    this.loadoutDefinition.targets = this.loadoutDefinition.targets.filter(t => t.unitBaseId !== unitId);
                    this.loadoutDefinition.targets.push(existingTarget);
                }
                existingTarget.optimizeTargets = existingTarget.optimizeTargets === undefined ? [] : existingTarget.optimizeTargets!;
                existingTarget.optimizeTargets = existingTarget.optimizeTargets.concat(dt.get(unitId)!);
            });
        }
    }

    // Create input for automating a single unit
    static getModAutomationCalculatorInput(source: string, pps: PlaygroundPlayerSettings, unitData: UnitData,
        loadoutDefinition: LoadoutDefinition,
        defaultTargets: Map<string, LoadoutDefinitionTarget>, settings: ModAutomationSettings, lockerUnits: string[]): ModAutomationCalculatorInput
    {
        let ps = pps.unitSettings.get(unitData.baseId)!.previousSets.length === 0 ? [pps.unitSettings.get(unitData.baseId)!.visibleConfirmedMods.map(ma => ma.mod.id)] :
            pps.unitSettings.get(unitData.baseId)!.previousSets.map(ps => ps.map(ma => ma));

        let units: ModAutomationCalculatorUnitInput[] = [new ModAutomationCalculatorUnitInput(pps, unitData, loadoutDefinition, defaultTargets,
            ps)];

        let relatedUnits = ModAutomationCalculatorInput.getRelatedUnitsData(loadoutDefinition, defaultTargets, units, pps);

        let relatedUnitsModIds: string[] = [];
        relatedUnits.forEach(ru => relatedUnitsModIds.concat(ru.mods.map(m => m.id)));

        let allMods = Array.from(pps.mods.values()).filter(m => relatedUnitsModIds.indexOf(m.id) === -1).filter(m => m.rarity >= 5);

        // only grab mods that are on equal or lower priority units
        let availableMods: ModData[] = pps.availableModAssignments.getUnlockedMods(
            allMods,
            source === WORKING_AUTOMATION_INPUT,
            unitData,
            pps.priorityOrder,
            pps.includedUnits).map(modId => pps.mods.get(modId)!);

        availableMods = ModAutomationCalculatorInput.removeNonLockerMods(settings, availableMods, units, pps.includedUnits, pps.availableModAssignments, lockerUnits);


        let stopReorderIndex: number | undefined = settings.autoReorder === false ? undefined : ModAutomationCalculatorInput.getUnitIndex(settings.stopAtAutoReorder, units, pps.priorityOrder);
        let stopDesiredIndex: number | undefined = ModAutomationCalculatorInput.getUnitIndex(settings.desiredAsRequiredAboveUnit, units, pps.priorityOrder);

        return new ModAutomationCalculatorInput(source, units, availableMods, stopReorderIndex, stopDesiredIndex, settings, relatedUnits, loadoutDefinition, lockerUnits);
    }

    static removeNonLockerMods(settings: ModAutomationSettings, mods: ModData[], units: ModAutomationCalculatorUnitInput[], includedUnits: string[],
        ama: AvailableModAssignments, lockerUnits: string[]): ModData[]
    {
        return settings.ignoreNonLockerMods === false ? mods :
            mods.filter(mod =>
            {
                const currentUnit = ama.assignedMods.get(mod.id)?.currentAssignedCharacter.baseId;
                return currentUnit === undefined || includedUnits.includes(currentUnit) === false ||
                    lockerUnits.includes(currentUnit) || units.map(u => u.unitBaseId).includes(currentUnit);
            });
    }

    static getUnitIndex(unitId: string | undefined, units: ModAutomationCalculatorUnitInput[], priorityOrder: string[]): number | undefined
    {

        let retVal: number | undefined = undefined;
        if (unitId !== undefined && unitId !== "")
        {
            let iIndex = 0;
            units.forEach(unit =>
            {
                if (priorityOrder.indexOf(unitId) >= priorityOrder.indexOf(unit.unitBaseId))
                {
                    iIndex = iIndex + 1;
                }
            });
            retVal = iIndex;
        }

        return retVal;
    }


    // Create input for modding all possible characters
    static getModAutomationCalculatorInputContinue(pps: PlaygroundPlayerSettings, startUnitBaseId: string,
        loadoutDefinition: LoadoutDefinition,
        defaultTargets: Map<string, LoadoutDefinitionTarget>, settings: ModAutomationSettings, lockerUnits: string[]): ModAutomationCalculatorInput
    {

        let startIndex = pps.priorityOrder.indexOf(startUnitBaseId);
        let unitData = pps.unitSettings.get(startUnitBaseId)!.gameUnit;

        let units: ModAutomationCalculatorUnitInput[] = pps.priorityOrder.filter((baseId, index) =>
        {
            return index >= startIndex && isModable(baseId, pps.unitSettings) && pps.unitSettings.get(baseId)!.locked === false &&
                pps.includedUnits.indexOf(baseId) !== -1
        }
        ).map(baseId =>
        {
            let ps = pps.unitSettings.get(baseId)!.previousSets.length === 0 ? [pps.unitSettings.get(baseId)!.visibleConfirmedMods.map(ma => ma.mod.id)] :
                pps.unitSettings.get(baseId)!.previousSets;

            return new ModAutomationCalculatorUnitInput(pps, pps.unitSettings.get(baseId)!.gameUnit, loadoutDefinition, defaultTargets,
                ps);
        });

        let relatedUnits = ModAutomationCalculatorInput.getRelatedUnitsData(loadoutDefinition, defaultTargets, units, pps);

        let relatedUnitsModIds: string[] = [];
        relatedUnits.forEach(ru => relatedUnitsModIds.concat(ru.mods.map(m => m.id)));

        let allMods = Array.from(pps.mods.values()).filter(m => relatedUnitsModIds.indexOf(m.id) === -1).filter(m => m.rarity >= 5);

        let availableMods: ModData[] = pps.availableModAssignments.getUnlockedMods(allMods, false, unitData, pps.priorityOrder, pps.includedUnits).map(modId => pps.mods.get(modId)!);

        availableMods = ModAutomationCalculatorInput.removeNonLockerMods(settings, availableMods, units, pps.includedUnits, pps.availableModAssignments, lockerUnits);


        let stopReorderIndex: number | undefined = settings.autoReorder === false ? undefined : ModAutomationCalculatorInput.getUnitIndex(settings.stopAtAutoReorder, units, pps.priorityOrder);
        let stopDesiredIndex: number | undefined = ModAutomationCalculatorInput.getUnitIndex(settings.desiredAsRequiredAboveUnit, units, pps.priorityOrder);

        return new ModAutomationCalculatorInput(CONFIRMED_AUTOMATION_INPUT, units, availableMods, stopReorderIndex,
            stopDesiredIndex, settings, relatedUnits, loadoutDefinition, lockerUnits);
    }

    // Create input for modding all possible characters
    static getModAutomationCalculatorInputList(pps: PlaygroundPlayerSettings, startIndex: number,
        loadoutDefinition: LoadoutDefinition,
        defaultTargets: Map<string, LoadoutDefinitionTarget>, settings: ModAutomationSettings, lockerUnits: string[],
        targetList: string[] | null = null): ModAutomationCalculatorInput
    {
        let units: ModAutomationCalculatorUnitInput[] = pps.priorityOrder.filter((baseId, index) =>
        {
            return index >= startIndex && isModable(baseId, pps.unitSettings) && pps.unitSettings.get(baseId)!.locked === false &&
                pps.includedUnits.indexOf(baseId) !== -1
        }
        ).filter(baseId => targetList === null || targetList.includes(baseId)).map(baseId =>
        {
            let ps = pps.unitSettings.get(baseId)!.previousSets.length === 0 ? [pps.unitSettings.get(baseId)!.visibleConfirmedMods.map(ma => ma.mod.id)] :
                pps.unitSettings.get(baseId)!.previousSets;

            return new ModAutomationCalculatorUnitInput(pps, pps.unitSettings.get(baseId)!.gameUnit, loadoutDefinition, defaultTargets,
                ps);
        });

        let relatedUnits = ModAutomationCalculatorInput.getRelatedUnitsData(loadoutDefinition, defaultTargets, units, pps);

        let relatedUnitsModIds: string[] = [];
        relatedUnits.forEach(ru => relatedUnitsModIds.concat(ru.mods.map(m => m.id)));

        let allMods = Array.from(pps.mods.values()).filter(m => relatedUnitsModIds.indexOf(m.id) === -1).filter(m => m.rarity >= 5);

        let availableMods: ModData[] = pps.availableModAssignments.getUnlockedMods(allMods, false).map(modId => pps.mods.get(modId)!);
        availableMods = ModAutomationCalculatorInput.removeNonLockerMods(settings, availableMods, units, pps.includedUnits, pps.availableModAssignments, lockerUnits);



        let stopReorderIndex: number | undefined = settings.autoReorder === false ? undefined : ModAutomationCalculatorInput.getUnitIndex(settings.stopAtAutoReorder, units, pps.priorityOrder);
        let stopDesiredIndex: number | undefined = ModAutomationCalculatorInput.getUnitIndex(settings.desiredAsRequiredAboveUnit, units, pps.priorityOrder);

        return new ModAutomationCalculatorInput(CONFIRMED_AUTOMATION_INPUT, units, availableMods, stopReorderIndex,
            stopDesiredIndex, settings, relatedUnits, loadoutDefinition, lockerUnits);
    }

    static getRelatedUnitsData(loadoutDefinition: LoadoutDefinition, defaultTargets: Map<string, LoadoutDefinitionTarget>, units: ModAutomationCalculatorUnitInput[],
        pps: PlaygroundPlayerSettings): RelatedUnit[]
    {
        return ModAutomationCalculatorInput.getRelatedUnits(units).filter(unitId => pps.unitSettings.has(unitId)).map(unitId =>
        {
            let unitSettings = pps.unitSettings.get(unitId)!;
            let unitData = unitSettings.baseStats;
            let gearStats = unitSettings.gearStats;
            let mods = unitSettings.visibleConfirmedMods.map(m => m.mod);
            let level = unitSettings.playerCharacterData.level;
            let gearLevel = unitSettings.playerCharacterData.gearLevel;
            let ldTarget = loadoutDefinition.targets.find(t => t.unitBaseId === unitId);
            let target = ldTarget === undefined ? defaultTargets.get(unitId)! : ldTarget;

            return new RelatedUnit(unitId, unitData, gearStats, mods, level, gearLevel, target);
        });
    }

    static getRelatedUnits(units: ModAutomationCalculatorUnitInput[]): string[]
    {
        let retVal: string[] = [];
        let includedUnits: string[] = units.map(u => u.unitBaseId);
        units.forEach(inputUnit =>
        {
            let inputRelatedUnits = inputUnit.target.getAllRelatedUnits();
            inputRelatedUnits.forEach(iru =>
            {
                if (retVal.indexOf(iru) === -1 && includedUnits.indexOf(iru) === -1)
                {
                    retVal.push(iru);
                }
            });
        });
        return retVal;
    }


}

export class ModAutomationCalculatorUnitInput
{
    unitBaseId: string;
    baseStats: UnitStats;
    gearStats: UnitStats;
    target: LoadoutDefinitionTarget;
    level: number;
    gearLevel: number;
    previousSets: string[][];
    currentModIds: string[];

    constructor(pps: PlaygroundPlayerSettings, unitData: UnitData, loadoutDefinition: LoadoutDefinition,
        defaultTargets: Map<string, LoadoutDefinitionTarget>, previousSets: string[][])
    {
        let unitBaseId = unitData.baseId;
        let unitSettings = pps.unitSettings.get(unitData.baseId)!;
        let target = loadoutDefinition.targets.find(t => t.unitBaseId === unitBaseId);
        target = target === undefined ? defaultTargets.get(unitBaseId)! : target;

        this.unitBaseId = unitData.baseId;
        this.baseStats = unitSettings.baseStats;
        this.target = target;
        this.gearLevel = unitSettings.playerCharacterData.gearLevel;
        this.level = unitSettings.playerCharacterData.level;
        this.gearStats = unitSettings.gearStats;
        this.previousSets = previousSets;
        this.currentModIds = unitSettings.visibleConfirmedMods.map(mac => mac.mod.id);
    }
}

export enum CalculationResultSummaryType
{
    Reorder = 1,
    ExitEarly,
    RemoveStatTarget,
    RemoveStatBonus,
    RemoveSetRestriction,
    RemovePrimaryRestriction
}

export interface CalculationResultSummary
{
    unitName: string;
    types: CalculationResultSummaryType[];
    descriptions: string[];
}

export interface ModAutomationCalculatorOutput
{
    units: ModAutomationCalculatorUnitOutput[];
    priorityOrder: string[];
    source: string;
    automationRunId: number;

}


export function getCalculationResultsSummary(modAutomationCalculatorInput: ModAutomationCalculatorInput,
    calculatorOutput: ModAutomationCalculatorOutput, unitsData: UnitData[]): CalculationResultSummary[]
{
    let inputPriorityOrder = modAutomationCalculatorInput!.units.map(u => u.unitBaseId);

    let retVal: CalculationResultSummary[] = [];

    calculatorOutput.units.forEach(unitResult =>
    {
        if (unitResult === undefined)
        {
            console.log("undefined unit result: ");
            console.log(calculatorOutput);
        }
        let unitData = unitsData.find(ud => ud.baseId === unitResult.unitBaseId);

        let startingPriorityOrder = inputPriorityOrder.indexOf(unitResult.unitBaseId) + 1;
        let endingPriorityOrder = calculatorOutput.priorityOrder.indexOf(unitResult.unitBaseId) + 1;
        let inputUnit = modAutomationCalculatorInput!.units.find(u => u.unitBaseId === unitResult.unitBaseId)!;

        let types: CalculationResultSummaryType[] = [];

        let descriptions: string[] = [];

        if (endingPriorityOrder < startingPriorityOrder)
        {
            descriptions.push("Moved priority from " + startingPriorityOrder + " to " + endingPriorityOrder);
            types.push(CalculationResultSummaryType.Reorder);
        }
        if (unitResult.completedAutomation === false)
        {
            descriptions.push("Exited automation early");
            types.push(CalculationResultSummaryType.ExitEarly);
        }
        if (inputUnit.target.getEffectiveOptimizeTargets() !== undefined && unitResult.optimizeTargets === false)
        {
            descriptions.push("Removed stat targets, unable to find set");
            types.push(CalculationResultSummaryType.RemoveStatTarget);
        }
        if (inputUnit.target.setBonusRestrictions !== undefined && unitResult.setBonusRestrictions === false)
        {
            descriptions.push("Removed set restrictions, unable to find set");
            types.push(CalculationResultSummaryType.RemoveSetRestriction);
        }
        if (inputUnit.target.primaryBonusRestrictions !== undefined && unitResult.primaryBonusRestrictions === false)
        {
            descriptions.push("Removed primary restrictions, unable to find set");
            types.push(CalculationResultSummaryType.RemovePrimaryRestriction);
        }

        if (descriptions.length > 0)
        {
            retVal.push({
                unitName: unitData!.name,
                descriptions: descriptions,
                types: types
            });
        }
    });

    return retVal;
}

export interface ModAutomationCalculatorUnitOutput
{
    unitBaseId: string;
    modIds: string[] | null;
    previousSets: string[][];
    completedAutomation: boolean;
    percentCompleted: number;
    priorityOrder: number;
    originalPriorityOrder: number;
    optimizeTargets: boolean,
    setBonusRestrictions: boolean,
    primaryBonusRestrictions: boolean
    statCalculatorOutput: StatCalculatorUnitOutput;

    automationCount: number;
    automationTimeTotal: number;

    statAutomationCount: number;
    statAutomationTimeTotal: number;

    maxAutomationTime: number;

    numberOfAutomationFailures: number;

}
