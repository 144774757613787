import { ZoneStrategy } from "../pages/TB/TBStrategy/model";
import GameData from "./GameData";
import UnitData, { IBasicUnitData } from "./UnitData";
import tbMissions from "../model/tb_missions.json";

export interface RosterUnit
{
    baseId: string;
    gear: {
        level: number;
    }
    stars: number;
    relicLevel: number;
    omiCount: number;
    zetaCount: number;
    zetaLead: boolean;
    level: number;
    ultimate: boolean;
}

export function mapGuildRoserUnitToBasicUnitData(gpru: RosterUnit): IBasicUnitData
{
    return {
        baseId: gpru.baseId,
        gearLevel: gpru.gear.level,
        level: gpru.level,
        rarity: gpru.stars,
        relicLevel: gpru.relicLevel,
        ultimate: gpru.ultimate,
        omiCount: gpru.omiCount,
        zetaCount: gpru.zetaCount,
        zetaLead: gpru.zetaLead
    }
}

export class ConflictVictoryRewardType
{
    type: number;
    value: number;
    rewardId: string;

    constructor(json: any)
    {
        this.type = json.type;
        this.value = json.value;
        this.rewardId = json.rewardId;
    }

    isStarReward(): boolean
    {
        return this.type === 2;
    }
}

export class ConflictVictoryPointRewards
{
    victoryPointReward: number
    galacticScoreRequirement: number;
    reward: ConflictVictoryRewardType | null = null;

    constructor(json: any)
    {
        this.victoryPointReward = json.victoryPointReward;
        this.galacticScoreRequirement = json.galacticScoreRequirement;
        if (json.reward)
        {
            this.reward = new ConflictVictoryRewardType(json.reward);
        }
    }
}

export class ZoneDefinitionData
{
    zoneId: string;
    prefabName: string;
    nameKey: string;
    descriptionKey: string;
    linkedConflictId: string;
    maxAttemptsAllowed: number;
    maxUnitCountPerPlayer: number;

    unlockRequirement: UnlockRequirement | null = null;

    // grantedAbilities": []

    constructor(json: any)
    {
        this.zoneId = json.zoneId;
        this.prefabName = json.prefabName;
        this.nameKey = json.nameKey;
        this.descriptionKey = json.descriptionKey;
        this.linkedConflictId = json.linkedConflictId;
        this.maxAttemptsAllowed = json.maxAttemptsAllowed;
        this.maxUnitCountPerPlayer = json.maxUnitCountPerPlayer;
        if (json.unlockRequirement)
        {
            this.unlockRequirement = new UnlockRequirement(json.unlockRequirement);
        }
    }
}

export class ConflictZoneDefinition
{
    combatType: number;
    victoryPointRewards: ConflictVictoryPointRewards[] = [];
    zoneDefinition: ZoneDefinitionData;

    static PHASE_TEXT = 'phase';

    constructor(json: any)
    {
        this.combatType = json.combatType;
        this.zoneDefinition = new ZoneDefinitionData(json.zoneDefinition);

        if (json.victoryPointRewards != null)
        {
            json.victoryPointRewards.forEach((vpr: any) => this.victoryPointRewards.push(new ConflictVictoryPointRewards(vpr)));
        }
    }

    static getAlignment(id: string): string
    {
        return ConflictZoneDefinition.getAlignmentById(ConflictZoneDefinition.getArea(id));
    }


    static getRelicLevelForZone(conflictZoneId: string): number
    {
        const phase = ConflictZoneDefinition.getPhase(conflictZoneId);

        switch (phase)
        {
            case 1:
                return 5;
            case 2:
                return 6;
            case 3:
                return 7;
            case 4:
                return 8;
            case 5:
                return 9;
            case 6:
                return 9;
        }
        return 0;
    }

    static getPhase(id: string): number
    {
        const parts = id.split('_');
        if (parts.length >= 3)
        {
            const lastCharacter = parts[2].charAt(parts[2].length - 1);
            const retVal = Number(lastCharacter);
            return isNaN(retVal) ? 1000 : retVal;
        }
        return 1000;
    }

    static getAlignmentById(id: number): string
    {
        switch (id)
        {
            case ZoneStrategy.LIGHT:
                return 'Light';
            case ZoneStrategy.DARK:
                return 'Dark';
            case ZoneStrategy.MIXED:
                return 'Mixed';
            case ZoneStrategy.BONUS:
                return 'Bonus';
            default:
                return 'Unknown';
        }
    }

    static getArea(id: string): number
    {
        const lastCharacter = id.charAt(id.length - 1);
        const retVal = Number(lastCharacter);
        return isNaN(retVal) ? 1000 : retVal;
    }

    getGpNeededForStar(starCount: number): number
    {
        let retVal = 0;
        const vrp = this.victoryPointRewards.slice().sort((vrp1, vrp2) => vrp1.galacticScoreRequirement - vrp2.galacticScoreRequirement);
        // should always be a number, but checking because of bug reported by @JebediahJSmith#4778 
        if (isNaN(starCount) === false && vrp && starCount > 0 && vrp.length >= starCount)
        {
            retVal = vrp[starCount - 1].galacticScoreRequirement;
        }
        return retVal;
    }

    getMaxSandbagAmount(): number
    {
        return this.victoryPointRewards.length > 0 ? this.victoryPointRewards[0].galacticScoreRequirement : 0;
    }

    getZoneId(): string
    {
        return this.zoneDefinition.zoneId;
    }

    getPhase(): number | undefined
    {
        const zoneIdParts = this.getZoneId().split('_');
        const phasePart = zoneIdParts.find(part => part.indexOf(ConflictZoneDefinition.PHASE_TEXT) !== -1);
        if (phasePart !== undefined)
        {
            const phaseNumberText = phasePart.replaceAll(ConflictZoneDefinition.PHASE_TEXT, '');
            const phaseNumber = Number(phaseNumberText);
            return isNaN(phaseNumber) ? undefined : phaseNumber;
        }
        return undefined;
    }
}

export class CampaignElementIdentifier
{
    campaignId: string;
    campaignMapId: string;
    campaignNodeId: string;
    campaignNodeDifficulty: number;
    campaignMissionId: string;

    constructor(json: any)
    {
        this.campaignId = json.campaignId;
        this.campaignMapId = json.campaignMapId;
        this.campaignNodeId = json.campaignNodeId;
        this.campaignNodeDifficulty = json.campaignNodeDifficulty;
        this.campaignMissionId = json.campaignMissionId;

    }
}

export class RewardRow
{
    key: string;
    value: string;

    constructor(json: any)
    {
        this.value = json.value;
        this.key = json.key;
    }

    getGpReward(): number
    {
        const parts = this.value.split(":");
        if (parts.length > 1)
        {
            const value = Number(parts[1]);
            if (isNaN(value) === false)
            {
                return value;
            }
        }
        return 0;
    }
}

export class Reward
{
    key: string;
    row: RewardRow[] = [];

    constructor(json: any)
    {
        this.key = json.key;
        json.row.forEach((row: any) => this.row.push(new RewardRow(row)));
    }
}

export class CovertZoneDefinition
{
    zoneDefinition: ZoneDefinitionData;
    campaign: Campaign | null = null;


    constructor(json: any)
    {
        this.zoneDefinition = new ZoneDefinitionData(json.zoneDefinition);
        if (json.campaign)
        {
            this.campaign = new Campaign(json.campaign);
        }
    }


    public getTbMissionInfo(parentZoneId: string): TbMissionInfo
    {

        return new TbMissionInfo({
            zoneId: this.zoneDefinition.zoneId,
            parentZoneId: parentZoneId,
            bosses: this.campaign?.calcs.bossEnemies.map(enemy => enemy.baseEnemyItem.id),
            elites: this.campaign?.calcs.eliteEnemies.map(enemy => enemy.baseEnemyItem.id),
            categories: this.campaign?.entryCategoryAllowed?.categoryId.filter(mru =>
                CampaignRequirements.isCategoryAlignment(mru) === false),
            commanderCategories: this.campaign?.entryCategoryAllowed?.commanderCategoryId.filter(mru =>
                CampaignRequirements.isCategoryAlignment(mru) === false),
            mandatoryRosterUnits: this.campaign?.entryCategoryAllowed?.mandatoryRosterUnit.map(mru => mru.id),
            combatType: this.campaign?.combatType,
            specialMission: true
        });
    }

}

export class MandatoryRosterUnit
{
    id: string;
    slot: number;

    constructor(json: any)
    {
        this.id = json.id;
        this.slot = json.slot;
    }
}

export class CampaignRequirements
{

    categoryId: string[] = [];
    maximumAllowedUnitQuantity: number;
    matchType: number;
    minimumRequiredUnitQuantity: number;
    minimumUnitRarity: number;
    minimumOwnedUnitQuantity: number;
    minimumUnitLevel: number;
    minimumUnitTier: number;
    maximumReinforcement: number;
    commanderCategoryId: string[] = [];
    minimumReinforcement: number;
    minimumGalacticPower: number;
    mandatoryRosterUnit: MandatoryRosterUnit[] = [];
    excludeCategoryId: string[] = [];
    minimumRelicTier: number;
    minimumModRarity: number;
    minimumAbilityLevelAvg: number;
    minimumAbilityLevelAll: number;
    legendLimit: number;
    unitGuideMinimumRequiredUnitQuantity: number;
    bigUnitLimit: number;

    constructor(json: any)
    {
        if (json.categoryId)
        {
            this.categoryId = json.categoryId;
        }
        if (json.mandatoryRosterUnit)
        {
            this.mandatoryRosterUnit = json.mandatoryRosterUnit.map((mru: any) => new MandatoryRosterUnit(mru));
        }
        if (json.excludeCategoryId)
        {
            this.excludeCategoryId = json.excludeCategoryId;
        }
        if (json.commanderCategoryId)
        {
            this.commanderCategoryId = json.commanderCategoryId;
        }
        this.maximumAllowedUnitQuantity = json.maximumAllowedUnitQuantity;
        this.matchType = json.matchType;
        this.minimumRequiredUnitQuantity = json.minimumRequiredUnitQuantity;
        this.minimumUnitRarity = json.minimumUnitRarity;
        this.minimumOwnedUnitQuantity = json.minimumOwnedUnitQuantity;
        this.minimumUnitLevel = json.minimumUnitLevel;
        this.minimumUnitTier = json.minimumUnitTier;
        this.maximumReinforcement = json.maximumReinforcement;

        this.minimumReinforcement = json.minimumReinforcement;
        this.minimumGalacticPower = json.minimumGalacticPower;

        this.minimumRelicTier = json.minimumRelicTier;
        this.minimumModRarity = json.minimumModRarity;
        this.minimumAbilityLevelAvg = json.minimumAbilityLevelAvg;
        this.minimumAbilityLevelAll = json.minimumAbilityLevelAll;
        this.legendLimit = json.legendLimit;
        this.unitGuideMinimumRequiredUnitQuantity = json.unitGuideMinimumRequiredUnitQuantity;
        this.bigUnitLimit = json.bigUnitLimit;
    }

    static getAlignment(alignmentText: string): number
    {
        switch (alignmentText)
        {
            case "light":
                return 2;
            case "neutral":
                return 1;
            case "dark":
                return 3;
        }
        return -1;
    }

    static capitalize(word: string): string
    {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    static getCategoryDescription(category: string, units: UnitData[])
    {
        const parts = category.split('_');

        if (parts.length > 1)
        {
            const testType = parts[0];
            const testValue = parts[1];
            switch (testType)
            {
                case "selftag": {
                    const unit = UnitData.unitByBaseId(testValue.toUpperCase(), units);

                    return unit ? UnitData.unitByBaseId(testValue.toUpperCase(), units).name : testValue;
                }
                default: {
                    return CampaignRequirements.capitalize(testValue);
                    ;
                }
            }
        } else
        {
            return '?';
        }
    }

    static isCategoryAlignment(category: string): boolean
    {
        const parts = category.split('_');

        return parts[0] === 'alignment';
    }

    static isCategoryType(unitData: UnitData, category: string): boolean
    {
        const parts = category.split('_');

        if (parts.length > 1)
        {
            const testType = parts[0];
            const testValue = parts[1];
            switch (testType)
            {
                case "alignment": {
                    return unitData.alignment === CampaignRequirements.getAlignment(testValue);
                }
                case "profession": {
                    return unitData.profession !== null && unitData.profession.map(p => p.key).includes(category);
                }
                case "selftag": {
                    return unitData.baseId === testValue.toUpperCase();
                }
                case "species": {
                    return unitData.species !== null && unitData.species.map(p => p.key).includes(category);
                }
                case "affiliation": {
                    return unitData.affiliation !== null && unitData.affiliation.map(p => p.key).includes(category);
                }
                default: {
                    console.log("unknown cat type: " + testType);
                    return true;
                }
            }
        } else
        {
            console.log('Unknown criteria, only one part' + category);
            return true;
        }
    }

    meetsCriteria(unitData: UnitData)
    {
        const meetsCategory = this.categoryId.length === 0 || this.categoryId.find(cat => CampaignRequirements.isCategoryType(unitData, cat)) !== undefined;
        const isNotExlcuded = this.excludeCategoryId.find(cat => CampaignRequirements.isCategoryType(unitData, cat)) === undefined;

        return meetsCategory && isNotExlcuded;

    }

    getSquadWarnings(rosterUnits: RosterUnit[], gameData: GameData): ConcatArray<string>
    {
        let retVal: string[] = [];

        rosterUnits.forEach(u =>
        {
            if (this.unitMeetsCriteria(u) === false)
            {
                retVal.push(UnitData.unitByBaseId(u.baseId, gameData.units!).name + ' does not meet minimum requirements.');
            }
        });
        return retVal;
    }

    getSquadErrors(units: UnitData[], gameData: GameData): string[]
    {
        let retVal: string[] = [];

        const unitList = units.map(u => u.baseId);

        units.forEach(u =>
        {
            if (this.meetsCriteria(u) === false)
            {
                retVal.push(u.name + ' is not a valid unit for this mission.');
            }
        });

        this.mandatoryRosterUnit.forEach(mru =>
        {
            if (unitList.includes(mru.id) === false)
            {
                retVal.push('Missing required unit: ' + UnitData.unitByBaseId(mru.id, gameData.units!).name + '.');
            }
        });

        // this.commanderCategoryId.forEach(ccid =>
        // {
        //     const parts = ccid.split("_");
        //     if (parts.length > 1)
        //     {
        //         const unitId = parts[1].toUpperCase();
        //         if (unitList.includes(unitId) === false)
        //         {
        //             retVal.push('Missing required unit: ' + UnitData.unitByBaseId(unitId, gameData.units!).name + '.');
        //         }
        //     }
        // });

        if (this.maximumAllowedUnitQuantity < units.length)
        {
            retVal.push('Too many units selected.');
        }

        if (this.minimumRequiredUnitQuantity > units.length)
        {
            retVal.push('Not enough units selected.');
        }

        if (units.filter(u => u.galacticLegend).length > this.legendLimit)
        {
            retVal.push('Too many GLs selected.');
        }

        // this.bigUnitLimit = json.bigUnitLimit;
        // TODO

        return retVal;
    }

    unitMeetsCriteria(rosterUnit: RosterUnit): boolean
    {
        const meetsUnitRarity = this.minimumUnitRarity <= rosterUnit.stars;
        const meetsUnitLevel = this.minimumUnitLevel <= rosterUnit.level;
        const meetsUnitTeir = this.minimumUnitTier === 1 || this.minimumUnitTier <= rosterUnit.gear.level;
        const meetsRelicLevel = this.minimumRelicTier === 1 || this.minimumRelicTier - 2 <= rosterUnit.relicLevel;

        return meetsUnitRarity && meetsUnitLevel && meetsUnitTeir && meetsRelicLevel;
    }
}

export class BaseEnemy
{
    id: string;
    type: number;
    weight: number;
    minQuantity: number;
    maxQuantity: number;
    rarity: number;

    constructor(json: any)
    {
        this.id = json.id;
        this.type = json.type;
        this.weight = json.weight;
        this.minQuantity = json.minQuantity;
        this.maxQuantity = json.maxQuantity;
        this.rarity = json.rarity;
    }
}

export class EnemyUnitPreview
{

    baseEnemyItem: BaseEnemy;
    enemyLevel: number;
    enemyTier: number;
    threatLevel: number;
    thumbnailName: string;
    prefabName: string;
    displayedEnemy: boolean;
    unitClass: number;
    enemyForceAlignment: number;
    enemyRelicTier: number;
    zetaCount: number;
    isGalacticLegend: boolean;
    isUltimateUnlocked: boolean;
    omicronCount: number;
    calcs: {
        isElite: boolean;
        isBoss: boolean;
        description: string;
    };

    constructor(json: any)
    {
        this.enemyLevel = json.enemyLevel;
        this.enemyTier = json.enemyTier;
        this.threatLevel = json.threatLevel;
        this.thumbnailName = json.thumbnailName;
        this.prefabName = json.prefabName;
        this.displayedEnemy = json.displayedEnemy;
        this.unitClass = json.unitClass;
        this.enemyForceAlignment = json.enemyForceAlignment;
        this.enemyRelicTier = json.enemyRelicTier;
        this.zetaCount = json.zetaCount;
        this.isGalacticLegend = json.isGalacticLegend;
        this.isUltimateUnlocked = json.isUltimateUnlocked;
        this.omicronCount = json.omicronCount;
        this.baseEnemyItem = new BaseEnemy(json.baseEnemyItem)

        this.calcs = {
            isElite: this.isElite(),
            isBoss: this.isBoss(),
            description: EnemyUnitPreview.getDescription(this.baseEnemyItem.id)
        }
    }

    private isElite(): boolean
    {
        return this.baseEnemyItem.id.indexOf('_ELITE') !== -1;
    }

    private isBoss(): boolean
    {
        return this.baseEnemyItem.id.indexOf('BOSS') !== -1;
    }

    static getDescription(enemyId: string, unitDataList: UnitData[] | undefined = undefined): string
    {
        const unitId = enemyId.split(":")[0].replaceAll('PVE_', '')

        const unitData = unitDataList === undefined ? undefined : UnitData.unitByBaseId(unitId, unitDataList);

        return unitData === undefined ? unitId : unitData.name;
    }
}

export class Campaign
{
    id: string;
    nameKey: string;
    descKey: string;
    combatType: number;
    entryCategoryAllowed: CampaignRequirements | null = null;
    enemyUnitPreview: EnemyUnitPreview[] = [];

    calcs: {
        eliteEnemies: EnemyUnitPreview[],
        bossEnemies: EnemyUnitPreview[]
    }

    constructor(json: any)
    {
        this.id = json.id;
        this.nameKey = json.nameKey;
        this.descKey = json.descKey;
        this.combatType = json.combatType;
        if (json.entryCategoryAllowed)
        {
            this.entryCategoryAllowed = new CampaignRequirements(json.entryCategoryAllowed);
        }
        if (json.enemyUnitPreview)
        {
            this.enemyUnitPreview = json.enemyUnitPreview.map((eup: any) => new EnemyUnitPreview(eup));
        }
        this.calcs = {
            eliteEnemies: this.enemyUnitPreview.filter(eu => eu.calcs.isElite),
            bossEnemies: this.enemyUnitPreview.filter(eu => eu.calcs.isBoss)
        }
    }

    meetsCriteria(unitData: UnitData)
    {
        const meetsCombatType = this.combatType === unitData.combatType;
        return meetsCombatType && (this.entryCategoryAllowed === null || this.entryCategoryAllowed.meetsCriteria(unitData));
    }

    getSquadErrors(units: UnitData[], gameData: GameData): string[]
    {
        let retVal: string[] = [];

        retVal = this.entryCategoryAllowed ? retVal.concat(this.entryCategoryAllowed.getSquadErrors(units, gameData)) : retVal;

        return retVal;
    }

    unitMeetsCriteria(rosterUnit: RosterUnit): boolean
    {
        return this.entryCategoryAllowed === null || this.entryCategoryAllowed.unitMeetsCriteria(rosterUnit);
    }

    getSquadWarnings(rosterUnits: RosterUnit[], gameData: GameData): string[]
    {
        let retVal: string[] = [];

        retVal = this.entryCategoryAllowed ? retVal.concat(this.entryCategoryAllowed.getSquadWarnings(rosterUnits, gameData)) : retVal;

        return retVal;
    }


}

export class TbMissionInfo
{
    zoneId: string;
    parentZoneId: string;

    manualShortDescription?: string;
    specialMission: boolean;

    requiredUnits?: string[];
    requiredAlignment?: string[];

    bosses?: string[];
    elites?: string[];
    categories?: string[];
    commanderCategories?: string[];
    mandatoryRosterUnits?: string[];
    combatType?: number;

    constructor(json: any)
    {
        this.zoneId = json.zoneId;

        this.parentZoneId = json.parentZoneId;
        this.requiredUnits = json.requiredUnits;
        this.requiredAlignment = json.requiredAlignment;
        this.specialMission = json.specialMission;

        this.bosses = json.bosses;
        this.elites = json.elites;
        this.categories = json.categories;
        this.commanderCategories = json.commanderCategories;
        this.mandatoryRosterUnits = json.mandatoryRosterUnits;
        this.combatType = json.combatType;
        this.manualShortDescription = json.manualShortDescription;
    }

    createShortDescription(unitDataList: UnitData[]): string
    {
        let retVal = '';
        if (this.mandatoryRosterUnits && this.mandatoryRosterUnits.length > 0)
        {
            retVal = this.mandatoryRosterUnits.map(unitBaseId =>
            {
                const unitData = UnitData.unitByBaseId(unitBaseId, unitDataList);
                if (unitData === undefined)
                {
                    console.error('Unable to find unit: ' + unitBaseId);
                    return unitBaseId;
                }
                return unitData.name;
            }).join(', ');
        } else if (this.commanderCategories &&
            this.commanderCategories.length > 0)
        {
            retVal = this.commanderCategories.map(mru =>
                CampaignRequirements.getCategoryDescription(mru, unitDataList)).join(', ');
        } else if (this.categories &&
            this.categories.length > 0)
        {
            retVal = this.categories.map(mru =>
                CampaignRequirements.getCategoryDescription(mru, unitDataList)).join(', ');
        } else if (this.bosses && this.bosses.length > 0)
        {
            retVal = 'Enemy: ' + this.bosses.map(enemy => EnemyUnitPreview.getDescription(enemy, unitDataList)).join(', ');
        } else if (this.elites && this.elites.length > 0)
        {
            retVal = 'Enemy: ' + this.elites.map(enemy => EnemyUnitPreview.getDescription(enemy, unitDataList)).join(', ');
        }

        return retVal;
    }

    getShortDescription(unitDataList: UnitData[]): string
    {
        let retVal = '';
        if (this.manualShortDescription)
        {
            retVal = this.manualShortDescription;
        } else
        {
            retVal = this.createShortDescription(unitDataList);
        }
        return retVal;
    }

    getDescription(unitDataList: UnitData[])
    {
        const parts: string[] = [];

        if (this.specialMission) parts.push('SM');
        if (this.combatType === 2) parts.push(this.getMissionType());
        parts.push(this.getShortDescription(unitDataList));
        return parts.join(' - ')
    }

    getMissionType(): string
    {
        return this.combatType === 2 ? 'Fleet' : 'Ground';
    }

    getAlignment(): string
    {
        return ConflictZoneDefinition.getAlignment(this.parentZoneId);
    }

    getRelicRequirement(): number
    {
        return ConflictZoneDefinition.getRelicLevelForZone(this.parentZoneId);
    }
}

export class StrikeZoneDefinition
{
    maxTotalScore: number;
    campaignElementIdentifier: CampaignElementIdentifier | null = null;
    zoneDefinitionData: ZoneDefinitionData;
    encounterRewardTableId: string | undefined;
    reward: Reward | null = null;
    campaign: Campaign | null = null;

    constructor(json: any, jsonTables: any)
    {
        this.maxTotalScore = json.maxTotalScore;
        this.campaignElementIdentifier = new CampaignElementIdentifier(json.campaignElementIdentifier);
        this.zoneDefinitionData = new ZoneDefinitionData(json.zoneDefinition);
        this.encounterRewardTableId = json.encounterRewardTableId;

        if (this.encounterRewardTableId !== undefined)
        {
            let tableReward = jsonTables.find((reward: any) => reward.id === this.encounterRewardTableId);
            if (tableReward !== undefined)
            {
                this.reward = new Reward(tableReward);
            }
        }
        if (json.campaign)
        {
            this.campaign = new Campaign(json.campaign);
        }
    }

    public getTbMissionInfo(parentZoneId: string): TbMissionInfo
    {

        return new TbMissionInfo({
            zoneId: this.zoneDefinitionData.zoneId,
            parentZoneId: parentZoneId,
            bosses: this.campaign?.calcs.bossEnemies.map(enemy => enemy.baseEnemyItem.id),
            elites: this.campaign?.calcs.eliteEnemies.map(enemy => enemy.baseEnemyItem.id),
            categories: this.campaign?.entryCategoryAllowed?.categoryId.filter(mru =>
                CampaignRequirements.isCategoryAlignment(mru) === false),
            commanderCategories: this.campaign?.entryCategoryAllowed?.commanderCategoryId.filter(mru =>
                CampaignRequirements.isCategoryAlignment(mru) === false),
            mandatoryRosterUnits: this.campaign?.entryCategoryAllowed?.mandatoryRosterUnit.map(mru => mru.id),
            combatType: this.campaign?.combatType,
            specialMission: false
        });
    }

    public getDescription(unitDataList: UnitData[]): string
    {
        let retVal = this.zoneDefinitionData.zoneId;
        if (this.campaign)
        {
            const mrus = this.campaign.entryCategoryAllowed?.mandatoryRosterUnit;
            const commanderCategories = this.campaign.entryCategoryAllowed?.commanderCategoryId.filter(mru => CampaignRequirements.isCategoryAlignment(mru) === false);
            const categories = this.campaign.entryCategoryAllowed?.categoryId.filter(mru => CampaignRequirements.isCategoryAlignment(mru) === false);
            const bosses = this.campaign.calcs.bossEnemies;
            const elites = this.campaign.calcs.eliteEnemies;

            const combatType = this.campaign.combatType === 2 ? 'Fleet: ' : '';

            if (mrus && mrus.length > 0)
            {
                retVal = combatType + mrus.map(mru =>
                {
                    const unitData = UnitData.unitByBaseId(mru.id, unitDataList);
                    if (unitData === undefined)
                    {
                        console.error('Unable to find unit: ' + mru.id);
                        return mru.id;
                    }
                    return unitData.name;
                }).join(',');
            } else if (commanderCategories &&
                commanderCategories.length > 0)
            {
                retVal = combatType + commanderCategories.map(mru =>
                    CampaignRequirements.getCategoryDescription(mru, unitDataList)).join(',');
            } else if (categories &&
                categories.length > 0)
            {
                retVal = combatType + categories.map(mru =>
                    CampaignRequirements.getCategoryDescription(mru, unitDataList)).join(',');
            } else if (bosses.length > 0)
            {
                retVal = combatType + 'Enemy: ' + bosses.map(enemy => enemy.calcs.description).join(', ');
            } else if (elites.length > 0)
            {
                retVal = combatType + 'Enemy: ' + elites.map(enemy => enemy.calcs.description).join(', ');
            }
        }
        return retVal;
    }

    public getTotalPossibleMissionPoints(): number
    {
        let retVal = 0;

        if (this.reward != null && this.reward.row.length > 0)
        {
            let finalReward = this.reward.row[this.reward.row.length - 1];
            retVal = this.getGpReward(finalReward);
        }
        return retVal;
    }

    getGpReward(rewardRow: RewardRow): number
    {
        let retVal = 0;
        let amountString = rewardRow.value.replace('GALACTIC_SCORE:', '');
        let amountValue = Number(amountString);
        if (isNaN(amountValue))
        {
            throw new Error("Unable to parse mission reward scrore: " + amountString);
        } else
        {
            retVal = retVal + amountValue;

        }
        return retVal;
    }

}

export class PlatoonReward
{
    type: number;
    value: number;

    constructor(json: any)
    {
        this.type = json.type;
        this.value = json.value;
    }
}

export class PlatoonSquad
{
    id: string;
    nameKey: string;

    constructor(json: any)
    {
        this.id = json.id;
        this.nameKey = json.nameKey;
    }
}

export class PlatoonMember
{
    operation: string;
    row: number;
    slot: number;
    baseId: string;

    constructor(json: any)
    {
        this.operation = json.operation;
        this.row = json.row;
        this.slot = json.slot;
        this.baseId = json.baseId;
    }
}

export class PlatoonDefinition
{
    id: string;

    nameKey: string;
    reward: PlatoonReward;
    members: PlatoonMember[] = [];
    squad: PlatoonSquad[] = [];
    unitRarity: number;
    unitRelicTier: number;

    constructor(json: any)
    {
        this.id = json.id;
        this.nameKey = json.nameKey;
        this.reward = new PlatoonReward(json.reward);
        this.unitRarity = json.unitRarity;
        this.unitRelicTier = json.unitRelicTier;

        if (json.members)
        {
            this.members = json.members.map((m: any) => new PlatoonMember(m))
        }

        json.squad.forEach((squad: any) => this.squad.push(new PlatoonSquad(squad)));
    }
}

export class ReconZoneDefinition
{
    zoneDefinition: ZoneDefinitionData;
    abilityImage: string;
    abilityShortDescKey: string;
    abilityLongDescKey: string;
    goalDescKey: string;
    goalImage: string;
    rewardDescKey: string;
    subTitleKey: string;
    platoonDefinition: PlatoonDefinition[] = [];

    constructor(json: any)
    {
        this.zoneDefinition = new ZoneDefinitionData(json.zoneDefinition);
        this.abilityImage = json.abilityImage;
        this.abilityShortDescKey = json.abilityShortDescKey;
        this.abilityLongDescKey = json.abilityLongDescKey;
        this.goalDescKey = json.goalDescKey;
        this.goalImage = json.goalImage;
        this.rewardDescKey = json.rewardDescKey;
        this.subTitleKey = json.subTitleKey;

        json.platoonDefinition.forEach((platoon: any) => this.platoonDefinition.push(new PlatoonDefinition(platoon)));
    }
}


export class RequirementItem
{

    static STAR_ZONE = 61;
    static MISSION_COMPLETE = 116;

    type: number;
    id: string;
    value: 1;

    constructor(json: any)
    {
        this.type = json.type;
        this.id = json.id;
        this.value = json.value;
    }
}

export class UnlockRequirement
{
    evalType: number;
    id: string;
    descKey: string;
    requirementItem: RequirementItem[] = [];

    constructor(json: any)
    {
        this.evalType = json.evalType;
        this.id = json.id;
        this.descKey = json.descKey;

        if (json.requirementItem)
        {
            json.requirementItem.forEach((item: any) =>
            {
                this.requirementItem.push(new RequirementItem(item));
            });
        }
    }
}

export class TerritoryBattleDefinition
{
    static SHIP_COMBAT_TYPE = 2;

    id: string;
    nameKey: string;
    descriptionKey: string;
    prefabName: string;
    roundDuration: number;
    roundCount: number;

    conflictZoneDefinition: ConflictZoneDefinition[] = [];
    strikeZoneDefinition: StrikeZoneDefinition[] = [];
    reconZoneDefinition: ReconZoneDefinition[] = [];
    covertZoneDefinition: CovertZoneDefinition[] = [];

    constructor(json: any, jsonTables: any, units: UnitData[])
    {
        this.id = json.id;
        this.nameKey = json.nameKey;
        this.descriptionKey = json.descriptionKey;
        this.prefabName = json.prefabName;
        this.roundDuration = json.roundDuration;
        this.roundCount = json.roundCount;

        if (json.conflictZoneDefinition != null)
        {
            json.conflictZoneDefinition.forEach((zd: any) => this.conflictZoneDefinition.push(new ConflictZoneDefinition(zd)));
        }
        if (json.strikeZoneDefinition != null)
        {
            json.strikeZoneDefinition.forEach((zd: any) => this.strikeZoneDefinition.push(new StrikeZoneDefinition(zd, jsonTables)));
        }
        if (json.reconZoneDefinition != null)
        {
            json.reconZoneDefinition.forEach((zd: any) => this.reconZoneDefinition.push(new ReconZoneDefinition(zd)));
        }
        if (json.covertZoneDefinition != null)
        {
            json.covertZoneDefinition.forEach((cd: any) => this.covertZoneDefinition.push(new CovertZoneDefinition(cd)));
        }

    }

    getTbMissionInfo(): Map<string, TbMissionInfo>
    {
        const retVal: Map<string, TbMissionInfo> = new Map();

        const tbOverriders: {
            zoneId: string,
            shortDescription: string
        }[] = tbMissions;

        this.conflictZoneDefinition.forEach(czd =>
        {
            this.getStrikeZones(czd.zoneDefinition.zoneId).forEach(szd =>
            {
                const missionData = szd.getTbMissionInfo(czd.zoneDefinition.zoneId);
                const override = tbOverriders.find(o => o.zoneId === szd.zoneDefinitionData.zoneId);
                if (override)
                {
                    missionData.manualShortDescription = override.shortDescription;
                }
                retVal.set(szd.zoneDefinitionData.zoneId, missionData);
            });

            this.getCovertZones(czd.zoneDefinition.zoneId).forEach(szd =>
            {
                const missionData = szd.getTbMissionInfo(czd.zoneDefinition.zoneId);
                const override = tbOverriders.find(o => o.zoneId === szd.zoneDefinition.zoneId);
                if (override)
                {
                    missionData.manualShortDescription = override.shortDescription;
                }
                retVal.set(szd.zoneDefinition.zoneId, missionData);
            });
        });
        return retVal;
    }

    dumpCombatZones(units: UnitData[])
    {
        this.conflictZoneDefinition.forEach(czd =>
        {

            this.getStrikeZones(czd.zoneDefinition.zoneId).forEach(szd =>
            {
                console.log(czd.zoneDefinition.zoneId + '!' + szd.zoneDefinitionData.zoneId + '!' +
                    ZoneStrategy.getAlignment(czd.getZoneId()) + '!' + czd.getPhase() + '!' + szd.getDescription(units));
            })
        });

    }

    public getZoneGameDefinition(zoneId: string): ConflictZoneDefinition
    {
        let czd = this.conflictZoneDefinition.find(czd => czd.zoneDefinition.zoneId === zoneId);
        if (czd !== undefined)
        {
            return czd;
        }
        throw new Error("Unable to find combat zone definition: " + zoneId);
    }

    public getUnlockingMissions(zoneId: string): string[]
    {
        let retVal: string[] = [];

        const reconZones = this.getCovertZones(zoneId).map(cz => cz.zoneDefinition.zoneId);

        this.conflictZoneDefinition.forEach(czd =>
        {
            const unlockZones = czd.zoneDefinition.unlockRequirement?.requirementItem.filter(ri =>
            {
                return ri.type === RequirementItem.MISSION_COMPLETE && reconZones.includes(ri.id) &&
                    retVal.includes(czd.zoneDefinition.zoneId) === false;
            }).map(ri => czd.zoneDefinition.zoneId);
            retVal = unlockZones ? retVal.concat(unlockZones) : retVal;
        });
        return retVal;
    }

    public getZonesByPhase(phase: number): ConflictZoneDefinition[]
    {
        return this.conflictZoneDefinition.filter(czd => czd.getPhase() === phase);
    }

    // These are platoon nodes
    public getReconZone(conflictId: string): ReconZoneDefinition | undefined
    {
        return this.reconZoneDefinition.find(rzd => rzd.zoneDefinition.linkedConflictId === conflictId);
    }

    // This are combat mission nodes
    public getStrikeZones(conflictId: string): StrikeZoneDefinition[]
    {
        return this.strikeZoneDefinition.filter(szd => szd.zoneDefinitionData.linkedConflictId === conflictId);
    }

    public getStrikeZone(strikeZoneId: string): StrikeZoneDefinition | undefined
    {
        return this.strikeZoneDefinition.find(szd => szd.zoneDefinitionData.zoneId === strikeZoneId);
    }

    // These are special mission nodes
    public getCovertZones(conflictId: string): CovertZoneDefinition[]
    {
        return this.covertZoneDefinition.filter(czd => czd.zoneDefinition.linkedConflictId === conflictId);
    }

    public isZoneFleet(zoneId: string)
    {
        let zoneDefinition: ConflictZoneDefinition = this.getZoneGameDefinition(zoneId);
        return zoneDefinition.combatType === TerritoryBattleDefinition.SHIP_COMBAT_TYPE;
    }

    public getActiveZones(completedZones: string[], zoneMissionsUnlocked: string[]): ConflictZoneDefinition[]
    {
        return this.conflictZoneDefinition.filter(czd =>
        {
            const alreadyCompleted = completedZones.includes(czd.zoneDefinition.zoneId);
            const requirementNotMeet = czd.zoneDefinition.unlockRequirement?.requirementItem.find(ri =>
            {
                if (ri.type === RequirementItem.STAR_ZONE)
                {
                    return completedZones.includes(ri.id) === false;
                } else if (ri.type === RequirementItem.MISSION_COMPLETE)
                {
                    return zoneMissionsUnlocked.includes(czd.zoneDefinition.zoneId) === false;
                }
                return false;
            });

            return alreadyCompleted === false && requirementNotMeet === undefined;
        });
    }
}

export class TerritoryBattleGameData
{
    static TB_GAME_DATA_KEY = "tb_game_data_v2";
    static TB_GAME_RISE_DATA_KEY = "tb_game_data_rise_v3";

    loaded: boolean = false;
    definitions: TerritoryBattleDefinition[] = [];

    calcs = {
        definitionMap: new Map()
    };

    public fromJson(json: any, units: UnitData[])
    {
        this.loaded = true;

        json.definition.forEach((def: any) =>
        {
            this.addDefinition(def, json.tables, units);
            // const tbd = new TerritoryBattleDefinition(def, json.tables, units);
            // this.definitions.push(tbd);
            // this.calcs.definitionMap.set(tbd.id, tbd);
        });
    }

    public addDefinition(def: any, tables: any, units: UnitData[])
    {
        const tbd = new TerritoryBattleDefinition(def, tables, units);
        // replace existing definition if it exists (rise has manual campaing data saved in project)
        this.definitions = this.definitions.filter(tbd => tbd.id !== def.id);
        this.definitions.push(tbd);
        this.calcs.definitionMap.set(tbd.id, tbd);
    }

    getTerritoryBattleDefinition(definitionId: string): TerritoryBattleDefinition
    {
        let tbData: TerritoryBattleDefinition | undefined = this.definitions.find(definition => definitionId === definition.id);
        if (tbData !== undefined)
        {
            return tbData;
        }
        throw new Error("Unable to find territory battle game data: " + definitionId);
    }
}


export default TerritoryBattleGameData;
