import { ModData } from '../model/ModData';
import ModSets from '../model/ModSets';
import StatIds from '../model/StatIds';
import PlayerCharacterData from './../model/PlayerCharacterData';
import { ModLoadoutUnit } from './../model/ModLoadoutData';
import ModSlots from './../model/ModSlots';
import { IGuildData } from '../model/GuildData';
import { LoadoutDefinitionStatWeights, OptimizationPlanStatWeight } from '../model/LoadoutDefinition';
import { PerfectSets } from './perfect-stats';
// import { PERFECT_ACCURACY_SET, PERFECT_CRIT_AVOID_SET, PERFECT_CRIT_CHANCE_SET, PERFECT_CRIT_DAMAGE_SET, PERFECT_DEFENSE_SET, PERFECT_HEALTH_SET, PERFECT_OFFENSE_SET, PERFECT_POTENCY_SET, PERFECT_PROTECTION_SET, PERFECT_SPEED_SET, PERFECT_TENACITY_SET } from './perfect-stats';

export class ModBonuses
{
    speed: number = 0;
    speedPct: number = 0;
    health: number = 0;
    healthPct: number = 0;
    offense: number = 0;
    offensePct: number = 0;
    defense: number = 0;
    defensePct: number = 0;
    tenacity: number = 0;
    criticalDamage: number = 0;
    criticalChance: number = 0;
    potency: number = 0;
    accuracy: number = 0;
    protection: number = 0;
    protectionPct: number = 0;
    criticalAvoidance: number = 0;
}

export interface ModCounts
{
    setTypes: Map<number, number>,
    primaryArrow: Map<number, number>,
    primaryTriangle: Map<number, number>,
    primaryCircle: Map<number, number>,
    primaryCross: Map<number, number>
    totalCounts: number
}

export class UnitStats
{
    health: number = 0;
    protection: number = 0;
    speed: number = 0;
    critDamage: number = 0;
    potency: number = 0;
    tenacity: number = 0;
    healthSteal: number = 0;
    defensePen: number = 0;
    damage: number = 0;
    critChance: number = 0;
    armorPen: number = 0;
    accuracy: number = 0;
    defense: number = 0;
    specialDefense: number = 0;
    armor: number = 0;
    dodgeChance: number = 0;
    critAvoidance: number = 0;
    specialDamage: number = 0;
    specialCritChance: number = 0;
    resistancePen: number = 0;
    specialAccuracy: number = 0;
    resistance: number = 0;
    deflectionChance: number = 0;
    specialCritAvoidance: number = 0;
    effectiveHealthAndProtection: number = 0;
    effectiveHealth: number = 0;
    effectiveProtection: number = 0;
    rawStats: {
        stat: number,
        amount: number
    }[] = [];

    static getBaseStat(statId: number, baseStats: any[]): number
    {
        let baseStat = baseStats.find(bs => bs.stat === statId);
        return baseStat === undefined ? 0 : baseStat.amount;
    }



    static fromGuildRoster(roster: any): UnitStats
    {
        let retVal: UnitStats = new UnitStats();

        retVal.health = UnitStats.getBaseStat(1, roster.baseStats);
        retVal.protection = UnitStats.getBaseStat(28, roster.baseStats);
        retVal.speed = UnitStats.getBaseStat(5, roster.baseStats);
        retVal.critDamage = ModCalculator.critDamageBaseToReadable(UnitStats.getBaseStat(16, roster.baseStats));
        retVal.potency = ModCalculator.potencyBaseToReadable(UnitStats.getBaseStat(17, roster.baseStats));
        retVal.tenacity = ModCalculator.tenacityBaseToReadable(UnitStats.getBaseStat(18, roster.baseStats));
        retVal.damage = UnitStats.getBaseStat(6, roster.baseStats);
        retVal.critChance = ModCalculator.critChanceBaseToReadable(UnitStats.getBaseStat(14, roster.baseStats));
        retVal.accuracy = ModCalculator.accuracyBaseToReadable(UnitStats.getBaseStat(37, roster.baseStats));
        retVal.armor = ModCalculator.armorBaseToReadable(UnitStats.getBaseStat(8, roster.baseStats), roster.level);
        retVal.critAvoidance = ModCalculator.criticalAvoidanceBaseToReadable(UnitStats.getBaseStat(39, roster.baseStats));
        retVal.specialDamage = UnitStats.getBaseStat(7, roster.baseStats);
        retVal.specialCritChance = ModCalculator.critChanceBaseToReadable(UnitStats.getBaseStat(15, roster.baseStats));
        retVal.resistance = ModCalculator.armorBaseToReadable(UnitStats.getBaseStat(9, roster.baseStats), roster.level);

        retVal.defense = UnitStats.getBaseStat(8, roster.baseStats);

        retVal.specialDefense = UnitStats.getBaseStat(9, roster.baseStats);

        //retVal.healthSteal = UnitStats.getBaseStat(1, roster.baseStats);
        //retVal.defensePen = UnitStats.getBaseStat(1, roster.baseStats);
        //retVal.armorPen = UnitStats.getBaseStat(1, roster.baseStats);
        //retVal.dodgeChance = UnitStats.getBaseStat(1, roster.baseStats);
        //retVal.resistancePen = UnitStats.getBaseStat(1, roster.baseStats);
        //retVal.specialAccuracy = UnitStats.getBaseStat(1, roster.baseStats);
        //retVal.deflectionChance = UnitStats.getBaseStat(1, roster.baseStats);
        //retVal.specialCritAvoidance = UnitStats.getBaseStat(1, roster.baseStats);

        retVal.rawStats = roster.baseStats;

        return retVal;
    }

    getRawStatValue(stat: number)
    {
        return this.rawStats.find(x => x.stat === stat)?.amount || 0;
    }
}

interface LoadoutDefintionStatWeightField
{
    name: string,
    getValue: (ldsw: LoadoutDefinitionStatWeights) => number;
    setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => void;
    getMaxValue: () => number;
}

const LD_STAT_WEIGHT_FIELDS: LoadoutDefintionStatWeightField[] = [
    {
        name: "health", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.health },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.health = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.HEALTH },
    },
    {
        name: "protection", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.protection },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.protection = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.PROTECTION },
    },
    {
        name: "speed", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.speed },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.speed = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.SPEED },
    },
    {
        name: "criticalDamage", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.criticalDamage },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.criticalDamage = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.CRIT_DAMAGE },
    },
    {
        name: "potency", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.potency },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.potency = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.POTENCY },
    },
    {
        name: "tenacity", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.tenacity },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.tenacity = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.TENACITY },
    },
    {
        name: "physicalDamage", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.physicalDamage },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.physicalDamage = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.PHYSCIAL_DAMAGE },
    },
    {
        name: "specialDamage", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.specialDamage },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.specialDamage = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.SPECIAL_DAMAGE },
    },
    {
        name: "criticalChance", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.criticalChance },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.criticalChance = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.CRIT_CHANCE },
    },
    {
        name: "armor", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.armor },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.armor = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.ARMOR },
    },
    {
        name: "resistance", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.resistance },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.resistance = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.RESISTANCE },
    },
    {
        name: "accuracy", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.accuracy },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.accuracy = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.ACCURACY },
    },
    {
        name: "criticalAvoidance", getValue: (ldsw: LoadoutDefinitionStatWeights) => { return ldsw.criticalAvoidance },
        setValue: (ldsw: LoadoutDefinitionStatWeights, value: number) => { ldsw.criticalAvoidance = value },
        getMaxValue: () => { return OptimizationPlanStatWeight.CRIT_AVOID },
    },

];

export class ModCompletedSet
{
    partialCount: number = 0;
    fullCount: number = 0;
    id: ModSets = ModSets.Health;
}

class SetInfo
{
    id: number = 0;
    required: number = 0;
    partial: number = 0;
    full: number = 0;
}

export interface StatSliceInfo
{
    id: number;
    bonus: number;
}

export interface IModData
{
    slot: ModSlots;
    set: ModSets;
    level: number;
}

// Description of defense:  https://gaming-fans.com/2017/12/swgoh-advanced-armor-function-defense/
export class ModCalculator
{

    private static MAX_MOD_LEVEL = 15;
    public static MULTIPLIER_FACTOR = 100000000;
    public static PERCENT_MULTIPLIER_FACTOR = 1000000;
    private static PRECISION_ROUND = 5; // used to account for odd javascript precision rounding
    private static STAT_SCALING_FACTOR = 10000;  // used to convert from unscaledDecimalValue to statValueDecimal

    // These are target values, replace existing stat
    private static SET_STATS: SetInfo[] = [
        { id: ModSets.Speed, partial: 5 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, full: 10 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, required: 4 },
        { id: ModSets.Offense, partial: 7.5 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, full: 15 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, required: 4 },
        { id: ModSets.CriticalDamage, partial: 15 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, full: 30 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, required: 4 },
        { id: ModSets.CriticalChance, partial: 4 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, full: 8 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, required: 2 },
        { id: ModSets.Health, partial: 5 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, full: 10 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, required: 2 },
        { id: ModSets.Defense, partial: 12.5 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, full: 25 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, required: 2 },
        { id: ModSets.Tenacity, partial: 10 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, full: 20 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, required: 2 },
        { id: ModSets.Potency, partial: 7.5 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, full: 15 * ModCalculator.PERCENT_MULTIPLIER_FACTOR, required: 2 }
    ];

    // These are target values, replace existing stat
    public static PRIMARY_STATS_SLICE: StatSliceInfo[] = [
        { id: StatIds.OffensePct, bonus: 8.5 * ModCalculator.PERCENT_MULTIPLIER_FACTOR },
        { id: StatIds.DefensePct, bonus: 20 * ModCalculator.PERCENT_MULTIPLIER_FACTOR },
        { id: StatIds.HealthPct, bonus: 16 * ModCalculator.PERCENT_MULTIPLIER_FACTOR },
        { id: StatIds.ProtectionPct, bonus: 24 * ModCalculator.PERCENT_MULTIPLIER_FACTOR },
        { id: StatIds.Speed, bonus: 32 * ModCalculator.MULTIPLIER_FACTOR },
        { id: StatIds.Accuracy, bonus: 30 * ModCalculator.PERCENT_MULTIPLIER_FACTOR },
        { id: StatIds.CriticalAvoidance, bonus: 35 * ModCalculator.PERCENT_MULTIPLIER_FACTOR },
        { id: StatIds.CriticalDamage, bonus: 42 * ModCalculator.PERCENT_MULTIPLIER_FACTOR },
        { id: StatIds.CriticalChance, bonus: 20 * ModCalculator.PERCENT_MULTIPLIER_FACTOR },
        { id: StatIds.Potency, bonus: 30 * ModCalculator.PERCENT_MULTIPLIER_FACTOR },
        { id: StatIds.Tenacity, bonus: 35 * ModCalculator.PERCENT_MULTIPLIER_FACTOR },
    ];

    // These are percent modifiers to existing stats
    private static SECONDARY_STATS_SLICE: StatSliceInfo[] = [
        { id: StatIds.CriticalChance, bonus: 1.04 },
        { id: StatIds.Defense, bonus: 1.63 },
        { id: StatIds.DefensePct, bonus: 2.34 },
        { id: StatIds.Health, bonus: 1.26 },
        { id: StatIds.HealthPct, bonus: 1.86 },
        { id: StatIds.Offense, bonus: 1.10 },
        { id: StatIds.OffensePct, bonus: 3.02 },
        { id: StatIds.Potency, bonus: 1.33 },
        { id: StatIds.Protection, bonus: 1.11 },
        { id: StatIds.ProtectionPct, bonus: 1.33 },
        { id: StatIds.Speed, bonus: 1.03 },
        { id: StatIds.Tenacity, bonus: 1.33 }
    ];

    // helper filter function
    public static getModsByBaseUnitId(mods: ModData[], baseUnitId: string)
    {
        return mods.filter(mod => mod.equippedCharacter != null && mod.equippedCharacter.baseId === baseUnitId);
    }

    public static isMissingSet(mods: ModData[]): boolean
    {
        let sets = ModCalculator.deriveCompletedSets(mods);
        let completedValue = 0;
        sets.forEach(set =>
        {
            let setInfo = ModCalculator.SET_STATS.find(si => si.id === set.id);
            if (setInfo !== undefined)
            {
                completedValue = completedValue + (setInfo.required * (set.fullCount + set.partialCount));
            }
        })
        return completedValue !== 6;
    }

    public static calculateLoadoutCost(modLoadoutUnits: ModLoadoutUnit[], currentMods: ModData[])
    {

        let modsRemoved: ModData[] = [];

        modLoadoutUnits.forEach(mlu =>
        {
            if (mlu.unit != null)
            {
                // pulled off others
                modsRemoved = modsRemoved.concat(mlu.mods.filter(mod => mlu.unit != null && mod.equippedCharacter != null && mod.equippedCharacter.id !== mlu.unit.id));

                let unitCurrentMods = ModCalculator.getModsByBaseUnitId(currentMods, mlu.unit.baseId);
                // pulled off this unit
                modsRemoved = modsRemoved.concat(unitCurrentMods.filter(unitCurrentMod =>
                {
                    let dontLeave: boolean = mlu.mods.find(mluFind => mluFind.id === unitCurrentMod.id) === undefined;
                    return dontLeave;
                }));
            }
        });

        const distinctThings = modsRemoved.filter(
            (thing, i, arr) => arr.findIndex(t => t.id === thing.id) === i
        );


        let retVal: number = 0;
        distinctThings.forEach(mod => mod.removeCost !== null ? retVal = retVal + mod.removeCost.quantity : null);
        return retVal;
    }

    public static calculateLoadoutUpdates(modLoadoutUnits: ModLoadoutUnit[], currentMods: ModData[]): ModLoadoutUnit[]
    {

        let retVal: ModLoadoutUnit[] = [];

        modLoadoutUnits.forEach(mlu =>
        {
            let updatedMods = mlu.mods.filter(mod => mod.equippedCharacter === null || mlu.unit === null || mod.equippedCharacter.id !== mlu.unit.id);

            if (updatedMods.length > 0)
            {
                let newMlu = this.deepClone<ModLoadoutUnit>(mlu);
                newMlu.modIds = updatedMods.map((m: ModData) => m.id);
                retVal.push(newMlu);
            }
        })
        return retVal;
    }


    public static getSecondaryBonus(mod: ModData, statId: StatIds): number
    {
        let retVal: number = 0;

        if (mod.secondaries)
        {
            mod.secondaries.forEach(secondary =>
            {
                if (secondary.statId === statId)
                {
                    retVal = secondary.statValueUnscaled;
                }
            });
        }


        return retVal;
    }

    public static slotIdToString(slotId: number): string
    {
        switch (slotId)
        {
            case ModSlots.Square:
                return "Square"
            case ModSlots.Diamond:
                return "Diamond"
            case ModSlots.Circle:
                return "Circle"

            case ModSlots.Arrow:
                return "Arrow"

            case ModSlots.Triangle:
                return "Triangle"

            case ModSlots.Cross:
                return "Cross"
            default:
                return "Unknown"
        }
    }

    public static setIdToString(setId: number): string
    {
        switch (setId)
        {
            case ModSets.Speed:
                return "Speed"
            case ModSets.Offense:
                return "Offense"
            case ModSets.CriticalDamage:
                return "Crit Damage"
            case ModSets.CriticalChance:
                return "Crit Chance"
            case ModSets.Health:
                return "Health"
            case ModSets.Defense:
                return "Defense"
            case ModSets.Potency:
                return "Potency"
            case ModSets.Tenacity:
                return "Tenactiy"
            default:
                return "Unknown"
        }
    }

    /**
     * Calculate unit stats when a set of mods is applied.  This function will derive unit base stats, so is more
     * convienient when performance is not a consideration (only need to calculate one mod set). 
     * 
     * @static
     * @param {PlayerCharacterData} unit - the unit to calculate stats for
     * @param {ModData[]} existingsMods - mods that are currently equipped on the unit.
     * @param {ModData[]} mods - target set of mods
     * @param {boolean} [ignoreLevel=false] - assumes all mods are level 15 (set bonuses differ on mods 15 and < 15).  May be useful when remodding.
     * @param {boolean} [convertToSixE=false] - calculates bonses as if all mods are upgraded to six e.  Note, this only affects primary and secondary stats, and does not perform any addition rolls that might occur.
     * @returns {UnitStats}
     * 
     * @memberOf ModCalculator
     */
    public static calculateModStats(unit: PlayerCharacterData, existingsMods: ModData[] | null, mods: ModData[], ignoreLevel: boolean = false, convertToSixE: boolean = false): UnitStats
    {
        let baseStats: UnitStats = unit.baseStats;
        return ModCalculator.calculateUpdatedStats(unit, baseStats, mods, ignoreLevel, convertToSixE);
    }

    public static deriveSpeed(unit: PlayerCharacterData, baseStats: UnitStats, mods: ModData[], completedSets: ModCompletedSet[], ignoreLevel: boolean = false, convertToSixE: boolean = false): number
    {
        let stats = ModCalculator.calculateUpdatedStats(unit, baseStats, mods, ignoreLevel, convertToSixE);
        return stats.speed;
    }

    public static useable(mod: ModData, playerUnit: PlayerCharacterData): boolean
    {
        return playerUnit.level >= 50 && (mod.rarity < 6 || playerUnit.gearLevel >= 12);
    }

    /**
     * Calculate updated unit stats when a set of mods is applied.  This function works in conjunction with deriveBaseStats.
     * It should be used in place of calculateModStats when performance is considered (aka optimization is occuring and many
     * sets are being calculation).
     * 
     * @static
     * @param {PlayerCharacterData} unit - the unit to calculate stats for
     * @param {UnitStats} baseStats - the units base stats with no mods eqiupped.  See deriveBaseStats for this calculation.
     * @param {ModData[]} mods - target set of mods
     * @param {boolean} [ignoreLevel=false] - assumes all mods are level 15 (set bonuses differ on mods 15 and < 15).  May be useful when remodding.
     * @param {boolean} [convertToSixE=false] - calculates bonses as if all mods are upgraded to six e.  Note, this only affects primary and secondary stats, and does not perform any addition rolls that might occur.
     * @returns {UnitStats} - stats after mods are applied to unit
     * 
     * @memberOf ModCalculator
     */
    public static calculateUpdatedStats(unit: PlayerCharacterData, baseStats: UnitStats, mods: ModData[], ignoreLevel: boolean = false, convertToSixE: boolean = false): UnitStats
    {

        let bonuses = ModCalculator.deriveModBonuses(mods, ignoreLevel, convertToSixE);
        let retVal = ModCalculator.applyBonuses(unit, baseStats, bonuses);
        ModCalculator.precisionRoundUnitStats(retVal);

        return retVal;
    }

    public static applyBonuses(unit: PlayerCharacterData, baseStats: UnitStats, bonuses: ModBonuses)
    {
        let retVal: UnitStats = new UnitStats();

        retVal.speed = (baseStats.speed) * (1 + bonuses.speedPct / (ModCalculator.MULTIPLIER_FACTOR)) +
            (bonuses.speed / ModCalculator.MULTIPLIER_FACTOR);

        retVal.critDamage = (baseStats.critDamage) + (bonuses.criticalDamage / (ModCalculator.PERCENT_MULTIPLIER_FACTOR));

        retVal.health = (baseStats.health) * (1 + bonuses.healthPct / (ModCalculator.MULTIPLIER_FACTOR)) +
            (bonuses.health / ModCalculator.MULTIPLIER_FACTOR);
        retVal.protection = (baseStats.protection) * (1 + bonuses.protectionPct / (ModCalculator.MULTIPLIER_FACTOR)) +
            (bonuses.protection / ModCalculator.MULTIPLIER_FACTOR);
        retVal.damage = (baseStats.damage) * (1 + bonuses.offensePct / (ModCalculator.MULTIPLIER_FACTOR)) +
            (bonuses.offense / ModCalculator.MULTIPLIER_FACTOR);
        retVal.specialDamage = (baseStats.specialDamage) * (1 + bonuses.offensePct / (ModCalculator.MULTIPLIER_FACTOR)) +
            (bonuses.offense / ModCalculator.MULTIPLIER_FACTOR);

        retVal.tenacity = (baseStats.tenacity) + (bonuses.tenacity / (ModCalculator.PERCENT_MULTIPLIER_FACTOR));
        retVal.potency = (baseStats.potency) + (bonuses.potency / (ModCalculator.PERCENT_MULTIPLIER_FACTOR));
        retVal.critChance = (baseStats.critChance) + (bonuses.criticalChance / (ModCalculator.PERCENT_MULTIPLIER_FACTOR));
        retVal.specialCritChance = (baseStats.specialCritChance) + (bonuses.criticalChance / (ModCalculator.PERCENT_MULTIPLIER_FACTOR));

        retVal.critAvoidance = (baseStats.critAvoidance) + (bonuses.criticalAvoidance / (ModCalculator.PERCENT_MULTIPLIER_FACTOR));
        retVal.accuracy = (baseStats.accuracy) + (bonuses.accuracy / (ModCalculator.PERCENT_MULTIPLIER_FACTOR));
        retVal.specialAccuracy = (baseStats.specialAccuracy) + (bonuses.accuracy / (ModCalculator.PERCENT_MULTIPLIER_FACTOR));
        retVal.specialCritAvoidance = (baseStats.specialCritAvoidance) + (bonuses.criticalAvoidance / (ModCalculator.PERCENT_MULTIPLIER_FACTOR));

        retVal.defense = (baseStats.defense) * (1 + bonuses.defensePct / (ModCalculator.MULTIPLIER_FACTOR)) +
            (bonuses.defense / ModCalculator.MULTIPLIER_FACTOR);
        retVal.specialDefense = (baseStats.specialDefense) * (1 + bonuses.defensePct / (ModCalculator.MULTIPLIER_FACTOR)) +
            (bonuses.defense / ModCalculator.MULTIPLIER_FACTOR);

        retVal.armor = ModCalculator.calculateArmor(retVal.defense, new ModBonuses(), unit);
        retVal.resistance = ModCalculator.calculateArmor(retVal.specialDefense, new ModBonuses(), unit);

        retVal.effectiveHealthAndProtection = (retVal.health + retVal.protection) / (1 - retVal.armor / 100);
        retVal.effectiveHealth = (retVal.health) / (1 - retVal.armor / 100);
        retVal.effectiveProtection = (retVal.protection) / (1 - retVal.armor / 100);


        return retVal;
    }

    public static getAllStatsLevel(mod: ModData): number
    {

        if (mod.rarity === 6 || mod.tier === 5)
        {
            return 1;
        } else if (mod.tier === 1)
        {
            return 12;
        } else if (mod.tier === 2)
        {
            return 9;
        } else if (mod.tier === 3)
        {
            return 6;
        } else if (mod.tier === 4)
        {
            return 3;
        }
        throw new Error("Unexpected mod tier or rarity: " + mod);
    }

    /**
     * Derive the stats of the unit if all the mods were removed.
     * 
     * @static
     * @param {PlayerCharacterData} unit - the unit to calculate stats for
     * @param {ModData[]} mods - mods that are currently equipped on the unit.
     * @returns {UnitStats}
     * 
     * @memberOf ModCalculator
     */
    public static deriveBaseStats(unit: PlayerCharacterData, mods: ModData[]): UnitStats
    {
        let retVal: UnitStats = new UnitStats();

        let currentModBonus = ModCalculator.deriveModBonuses(mods);

        retVal.speed = (unit.statsSpeed - (currentModBonus.speed / ModCalculator.MULTIPLIER_FACTOR)) /
            (1 + currentModBonus.speedPct / (ModCalculator.MULTIPLIER_FACTOR));
        retVal.critDamage = unit.statsCritDamage - currentModBonus.criticalDamage / ModCalculator.PERCENT_MULTIPLIER_FACTOR;
        retVal.health = (unit.statsHealth - (currentModBonus.health / ModCalculator.MULTIPLIER_FACTOR)) / (1 + currentModBonus.healthPct / (ModCalculator.MULTIPLIER_FACTOR));
        retVal.protection = (unit.statsProtection - (currentModBonus.protection / ModCalculator.MULTIPLIER_FACTOR)) / (1 + currentModBonus.protectionPct / ModCalculator.MULTIPLIER_FACTOR);
        retVal.damage = (unit.statsDamage - (currentModBonus.offense / ModCalculator.MULTIPLIER_FACTOR)) / (1 + currentModBonus.offensePct / (ModCalculator.MULTIPLIER_FACTOR));
        retVal.specialDamage = (unit.statsSpecialDamage - (currentModBonus.offense / ModCalculator.MULTIPLIER_FACTOR)) / (1 + currentModBonus.offensePct / (ModCalculator.MULTIPLIER_FACTOR));
        retVal.tenacity = unit.statsTenacity - currentModBonus.tenacity / ModCalculator.PERCENT_MULTIPLIER_FACTOR;
        retVal.potency = unit.statsPotency - currentModBonus.potency / ModCalculator.PERCENT_MULTIPLIER_FACTOR;
        retVal.critChance = (unit.statsCritChance) - (currentModBonus.criticalChance / (ModCalculator.PERCENT_MULTIPLIER_FACTOR));
        retVal.specialCritChance = (unit.statsSpecialCritChance) - (currentModBonus.criticalChance / (ModCalculator.PERCENT_MULTIPLIER_FACTOR));
        retVal.critAvoidance = unit.statsCritAvoidance - (currentModBonus.criticalAvoidance) / (ModCalculator.PERCENT_MULTIPLIER_FACTOR);
        retVal.specialCritAvoidance = unit.statsSpecialCritAvoidance - (currentModBonus.criticalAvoidance) / (ModCalculator.PERCENT_MULTIPLIER_FACTOR);

        retVal.accuracy = unit.statsAccuracy - (currentModBonus.accuracy) / (ModCalculator.PERCENT_MULTIPLIER_FACTOR);
        retVal.specialAccuracy = unit.statsSpecialAccuracy - (currentModBonus.accuracy) / (ModCalculator.PERCENT_MULTIPLIER_FACTOR);

        // calculate current defense - Defense = (Armor*(CharacterLevel*7.5))/(100-Armor)
        let defense = (unit.statsArmor * (unit.level * 7.5)) / (100 - unit.statsArmor);
        retVal.defense = (defense - currentModBonus.defense / ModCalculator.MULTIPLIER_FACTOR) / (1 + currentModBonus.defensePct / (ModCalculator.PERCENT_MULTIPLIER_FACTOR * 100));

        let resistance = (unit.statsResistance * (unit.level * 7.5)) / (100 - unit.statsResistance);
        retVal.specialDefense = (resistance - currentModBonus.defense / ModCalculator.MULTIPLIER_FACTOR) / (1 + currentModBonus.defensePct / (ModCalculator.PERCENT_MULTIPLIER_FACTOR * 100));

        retVal.armor = ModCalculator.calculateArmor(retVal.defense, new ModBonuses(), unit);
        retVal.resistance = ModCalculator.calculateArmor(retVal.specialDefense, new ModBonuses(), unit);
        retVal.rawStats = unit.baseStats.rawStats;

        // account for odd javascript rounding on multiplication
        ModCalculator.precisionRoundUnitStats(retVal);

        return retVal;
    }

    private static precisionRoundUnitStats(unitStats: UnitStats)
    {
        unitStats.speed = ModCalculator.precisionRound(unitStats.speed, ModCalculator.PRECISION_ROUND);
        unitStats.critDamage = ModCalculator.precisionRound(unitStats.critDamage, ModCalculator.PRECISION_ROUND);
        unitStats.health = ModCalculator.precisionRound(unitStats.health, ModCalculator.PRECISION_ROUND);
        unitStats.protection = ModCalculator.precisionRound(unitStats.protection, ModCalculator.PRECISION_ROUND);
        unitStats.damage = ModCalculator.precisionRound(unitStats.damage, ModCalculator.PRECISION_ROUND);
        unitStats.specialDamage = ModCalculator.precisionRound(unitStats.specialDamage, ModCalculator.PRECISION_ROUND);
        unitStats.tenacity = ModCalculator.precisionRound(unitStats.tenacity, ModCalculator.PRECISION_ROUND);
        unitStats.potency = ModCalculator.precisionRound(unitStats.potency, ModCalculator.PRECISION_ROUND);
        unitStats.critChance = ModCalculator.precisionRound(unitStats.critChance, ModCalculator.PRECISION_ROUND);
        unitStats.specialCritChance = ModCalculator.precisionRound(unitStats.specialCritChance, ModCalculator.PRECISION_ROUND);
        unitStats.critAvoidance = ModCalculator.precisionRound(unitStats.critAvoidance, ModCalculator.PRECISION_ROUND);
        unitStats.specialCritAvoidance = ModCalculator.precisionRound(unitStats.specialCritAvoidance, ModCalculator.PRECISION_ROUND);
        unitStats.defense = ModCalculator.precisionRound(unitStats.defense, ModCalculator.PRECISION_ROUND);
        unitStats.specialDefense = ModCalculator.precisionRound(unitStats.specialDefense, ModCalculator.PRECISION_ROUND);
        unitStats.armor = ModCalculator.precisionRound(unitStats.armor, ModCalculator.PRECISION_ROUND);
        unitStats.resistance = ModCalculator.precisionRound(unitStats.resistance, ModCalculator.PRECISION_ROUND);

        unitStats.effectiveHealthAndProtection = ModCalculator.precisionRound(unitStats.effectiveHealthAndProtection, ModCalculator.PRECISION_ROUND);
        unitStats.effectiveHealth = ModCalculator.precisionRound(unitStats.effectiveHealth, ModCalculator.PRECISION_ROUND);
        unitStats.effectiveProtection = ModCalculator.precisionRound(unitStats.effectiveProtection, ModCalculator.PRECISION_ROUND);
    }


    /**
     * Armor (and resistance is a complex equation related to unit level and defense).
     * 
     * @private
     * @static
     * @param {number} baseDefense - defense with no mods applied
     * @param {ModBonuses} bonuses - stat bonuses from mod set (defense and defense percent used)
     * @param {PlayerCharacterData} unit - in game unit, level used
     * @returns {number}
     * 
     * @memberOf ModCalculator
     */
    private static calculateArmor(baseDefense: number, bonuses: ModBonuses, unit: PlayerCharacterData): number
    {
        // Armor = (Defense*100)/(Defense+(CharacterLevel*7.5))
        let defensePercentMultiplier = bonuses.defensePct / ModCalculator.PERCENT_MULTIPLIER_FACTOR;
        let newDefense = (baseDefense + bonuses.defense) + (baseDefense * defensePercentMultiplier);
        return (newDefense * 100) / (newDefense + (unit.level * 7.5));
    }


    /**
     * Calculates total bonuses from a list of mods, includes set, primary and secondary bonuses.
     * 
     * @static
     * @param {ModData[]} mods - list of mods (this should never be more then 6, or include 2 mods of same slot).
     * @param {boolean} [ignoreLevel=false] - assumes all mods are level 15 (set bonuses differ on mods 15 and < 15).  May be useful when remodding.
     * @param {boolean} [convertToSixE=false] - calculates bonses as if all mods are upgraded to six e.  Note, this only affects primary and secondary stats, and does not perform any addition rolls that might occur.
     * @returns {ModBonuses}
     * 
     * @memberOf ModCalculator
     */
    public static deriveModBonuses(mods: ModData[], ignoreLevel: boolean = false, convertToSixE: boolean = false): ModBonuses
    {

        let completedSets = ModCalculator.deriveCompletedSets(mods);

        let setBonuses = ModCalculator.deriveSetBonuses(completedSets, ignoreLevel);
        let secondaryBonuses = ModCalculator.deriveSecondaryBonuses(mods, convertToSixE);
        let primaryBonuses = ModCalculator.derivePrimaryBonuses(mods, convertToSixE);

        return ModCalculator.sumBonuses([setBonuses, secondaryBonuses, primaryBonuses]);
    }

    private static deepClone<T>(target: T): T
    {
        if (target === null)
        {
            return target;
        }
        if (target instanceof Date)
        {
            return new Date(target.getTime()) as any;
        }
        if (target instanceof Array)
        {
            const cp = [] as any[];
            (target as any[]).forEach((v) => { cp.push(v); });
            return cp.map((n: any) => ModCalculator.deepClone<any>(n)) as any;
        }
        if (typeof target === 'object' && target !== {})
        {
            const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
            Object.keys(cp).forEach(k =>
            {
                cp[k] = ModCalculator.deepClone<any>(cp[k]);
            });
            return cp as T;
        }
        return target;
    }

    public static upgradeToRaritySix(mod: ModData): ModData
    {

        let retVal: ModData = ModCalculator.deepClone<ModData>(mod);

        if (retVal.rarity === 5)
        {
            retVal.rarity = 6;
            retVal.tier = 1;
            let primarySlice = ModCalculator.PRIMARY_STATS_SLICE.find(pss => pss.id === mod.primary.statId);
            if (primarySlice === null || primarySlice === undefined)
            {
                throw new Error("Unable to find slicing data for stat id:" + mod.primary.statId)
            }
            retVal.primary.statValueUnscaled = ModCalculator.precisionRound(primarySlice.bonus, 5);
            retVal.primary.statValueDecimal = ModCalculator.precisionRound(primarySlice.bonus / ModCalculator.STAT_SCALING_FACTOR, 5);

            retVal.secondaries.forEach(secondary =>
            {

                let secondarySlice = ModCalculator.SECONDARY_STATS_SLICE.find(ss => ss.id === secondary.statId);
                if (secondarySlice === null || secondarySlice === undefined)
                {
                    throw new Error("Unable to find slicing data for stat id:" + secondary.statId)
                }

                if (secondary.statId === StatIds.Speed)
                {
                    // speed rounding doesnt seem to play nice
                    secondary.statValueUnscaled = secondary.statValueUnscaled + 100000000;
                    secondary.statValueDecimal = secondary.statValueDecimal + 10000;
                } else
                {
                    secondary.statValueUnscaled = ModCalculator.precisionRound(secondary.statValueUnscaled * secondarySlice.bonus, 5);
                    secondary.statValueDecimal = ModCalculator.precisionRound(secondary.statValueDecimal * secondarySlice.bonus, 5);
                }

            })
        }
        return retVal;
    }

    public static sliceMod(mod: ModData)
    {
        if (mod.rarity === 5)
        {
            mod.rarity = 6;
            mod.tier = 1;
            let primarySlice = ModCalculator.PRIMARY_STATS_SLICE.find(pss => pss.id === mod.primary.statId);
            if (primarySlice === null || primarySlice === undefined)
            {
                throw new Error("Unable to find slicing data for stat id:" + mod.primary.statId)
            }
            mod.primary.statValueUnscaled = ModCalculator.precisionRound(primarySlice.bonus, 5);
            mod.primary.statValueDecimal = ModCalculator.precisionRound(primarySlice.bonus / ModCalculator.STAT_SCALING_FACTOR, 5);

            mod.secondaries.forEach(secondary =>
            {

                let secondarySlice = ModCalculator.SECONDARY_STATS_SLICE.find(ss => ss.id === secondary.statId);
                if (secondarySlice === null || secondarySlice === undefined)
                {
                    throw new Error("Unable to find slicing data for stat id:" + secondary.statId)
                }

                if (secondary.statId === StatIds.Speed)
                {
                    // speed rounding doesnt seem to play nice
                    secondary.statValueUnscaled = secondary.statValueUnscaled + 100000000;
                    secondary.statValueDecimal = secondary.statValueDecimal + 10000;
                } else
                {
                    secondary.statValueUnscaled = ModCalculator.precisionRound(secondary.statValueUnscaled * secondarySlice.bonus, 5);
                    secondary.statValueDecimal = ModCalculator.precisionRound(secondary.statValueDecimal * secondarySlice.bonus, 5);
                }

            })
        }
        return mod;
    }

    private static derivePrimaryBonuses(mods: ModData[], convertToSixE: boolean = false): ModBonuses
    {
        let retVal: ModBonuses = new ModBonuses();

        mods.forEach(mod =>
        {
            let statValue = mod.primary.statValueUnscaled;

            if (convertToSixE === true && mod.rarity === 5)
            {
                let primarySlice = ModCalculator.PRIMARY_STATS_SLICE.find(pss => pss.id === mod.primary.statId);
                if (primarySlice === null || primarySlice === undefined)
                {
                    throw new Error("Unable to find slicing data for stat id:" + mod.primary.statId)
                }
                statValue = primarySlice.bonus;
            }

            switch (mod.primary.statId)
            {
                case StatIds.Speed:
                    retVal.speed = retVal.speed + statValue;
                    break;
                case StatIds.Potency:
                    retVal.potency = retVal.potency + statValue;
                    break;
                case StatIds.Tenacity:
                    retVal.tenacity = retVal.tenacity + statValue;
                    break;
                case StatIds.OffensePct:
                    retVal.offensePct = retVal.offensePct + statValue;
                    break;
                case StatIds.DefensePct:
                    retVal.defensePct = retVal.defensePct + statValue;
                    break;
                case StatIds.CriticalChance:
                    retVal.criticalChance = retVal.criticalChance + statValue;
                    break;
                case StatIds.HealthPct:
                    retVal.healthPct = retVal.healthPct + statValue;
                    break;
                case StatIds.ProtectionPct:
                    retVal.protectionPct = retVal.protectionPct + statValue;
                    break;
                case StatIds.CriticalDamage:
                    retVal.criticalDamage = retVal.criticalDamage + statValue;
                    break;
                case StatIds.Accuracy:
                    retVal.accuracy = retVal.accuracy + statValue;
                    break;
                case StatIds.CriticalAvoidance:
                    retVal.criticalAvoidance = retVal.criticalAvoidance + statValue;
                    break;

                default:
                    throw new Error("Unexpected secondary statId: " + mod.primary.statId);
            }
        });
        return retVal;
    }

    private static deriveSecondaryBonuses(mods: ModData[], convertToSixE: boolean = false): ModBonuses
    {
        let retVal: ModBonuses = new ModBonuses();

        mods.forEach(mod =>
        {
            if (mod.secondaries)
            {
                mod.secondaries.forEach(secondary =>
                {

                    let statValue = secondary.statValueUnscaled;

                    if (convertToSixE === true && mod.rarity === 5)
                    {
                        let secondarySlice = ModCalculator.SECONDARY_STATS_SLICE.find(ss => ss.id === secondary.statId);
                        if (secondarySlice === null || secondarySlice === undefined)
                        {
                            throw new Error("Unable to find slicing data for stat id:" + secondary.statId)
                        }
                        statValue = secondarySlice.bonus * statValue;
                    }

                    switch (secondary.statId)
                    {
                        case StatIds.Health:
                            retVal.health = retVal.health + statValue;
                            break;
                        case StatIds.Speed:
                            retVal.speed = retVal.speed + statValue;
                            break;
                        case StatIds.Potency:
                            retVal.potency = retVal.potency + statValue;
                            break;
                        case StatIds.Tenacity:
                            retVal.tenacity = retVal.tenacity + statValue;
                            break;
                        case StatIds.Protection:
                            retVal.protection = retVal.protection + statValue;
                            break;
                        case StatIds.Offense:
                            retVal.offense = retVal.offense + statValue;
                            break;
                        case StatIds.Defense:
                            retVal.defense = retVal.defense + statValue;
                            break;
                        case StatIds.OffensePct:
                            retVal.offensePct = retVal.offensePct + statValue;
                            break;
                        case StatIds.DefensePct:
                            retVal.defensePct = retVal.defensePct + statValue;
                            break;
                        case StatIds.CriticalChance:
                            retVal.criticalChance = retVal.criticalChance + statValue;
                            break;
                        case StatIds.HealthPct:
                            retVal.healthPct = retVal.healthPct + statValue;
                            break;
                        case StatIds.ProtectionPct:
                            retVal.protectionPct = retVal.protectionPct + statValue;
                            break;
                        default:
                            throw new Error("Unexpected secondary statId: " + secondary.statId);
                    }
                });
            }
        })

        return retVal;
    }



    /**
     * Calculates a list of sets that are completed by the list of Mods.  This includes sets with partial (< level 15) and full bonuses (level 15).
     * This calculation might be useful in displaying completed sets for the list of mods, or calculating if the set is missing a set bonus.
     * 
     * @static
     * @param {ModData[]} mods
     * @returns {ModCompletedSet[]}
     * 
     * @memberOf ModCalculator
     */
    public static deriveCompletedSets(mods: IModData[]): ModCompletedSet[]
    {

        return ModCalculator.SET_STATS.reduce(function (result: ModCompletedSet[], set)
        {

            let modsOfSet = mods.filter(mod => mod.set === set.id);
            let level15Mods = modsOfSet.filter(mod => mod.level === ModCalculator.MAX_MOD_LEVEL);

            let fullCount = Math.floor(level15Mods.length / set.required);
            let partialCount = Math.floor(modsOfSet.length / set.required) - fullCount;

            if (fullCount > 0 || partialCount > 0)
            {

                let newItem: ModCompletedSet = {
                    partialCount: partialCount,
                    fullCount: fullCount,
                    id: set.id
                };

                result.push(newItem);
            }
            return result;
        }, []);

    }

    private static getSetBonusAmount(set: ModCompletedSet, bonus: SetInfo, ignoreLevel: boolean = false): number
    {
        return set.fullCount * bonus.full + set.partialCount * (ignoreLevel ? bonus.full : bonus.partial)
    }

    public static deriveSetBonuses(completedSets: ModCompletedSet[], ignoreLevel: boolean = false): ModBonuses
    {
        let retVal: ModBonuses = new ModBonuses();

        completedSets.forEach(set =>
        {
            let bonus = ModCalculator.SET_STATS.find(setBonus => setBonus.id === set.id);
            if (bonus === null || bonus === undefined)
            {
                throw new Error("unable to find set bonus for id: " + set.id);
            }
            let bonusAmount = ModCalculator.getSetBonusAmount(set, bonus, ignoreLevel);

            switch (set.id)
            {
                case ModSets.Speed:
                    retVal.speedPct = bonusAmount;
                    break;
                case ModSets.Offense:
                    retVal.offensePct = bonusAmount;
                    break;
                case ModSets.CriticalDamage:
                    retVal.criticalDamage = bonusAmount;
                    break;
                case ModSets.CriticalChance:
                    retVal.criticalChance = bonusAmount;
                    break;
                case ModSets.Health:
                    retVal.healthPct = bonusAmount;
                    break;
                case ModSets.Defense:
                    retVal.defensePct = bonusAmount;
                    break;
                case ModSets.Potency:
                    retVal.potency = bonusAmount;
                    break;
                case ModSets.Tenacity:
                    retVal.tenacity = bonusAmount;
                    break;
                default:
                    throw new Error("Unexpected set: " + set.id);
            }
        })

        return retVal;
    }

    // used to account for odd javascript precision rounding
    private static precisionRound(value: number, precision: number): number
    {
        var factor = Math.pow(10, precision);
        return Math.round(value * factor) / factor;
    }

    private static sumBonuses(bonuses: ModBonuses[]): ModBonuses
    {
        let retVal: ModBonuses = new ModBonuses();

        bonuses.forEach(bonus =>
        {
            retVal.speed = retVal.speed + bonus.speed;
            retVal.speedPct = retVal.speedPct + bonus.speedPct;
            retVal.health = retVal.health + bonus.health;
            retVal.healthPct = retVal.healthPct + bonus.healthPct;
            retVal.offense = retVal.offense + bonus.offense;
            retVal.offensePct = retVal.offensePct + bonus.offensePct;
            retVal.defense = retVal.defense + bonus.defense;
            retVal.defensePct = retVal.defensePct + bonus.defensePct;
            retVal.tenacity = retVal.tenacity + bonus.tenacity;
            retVal.criticalDamage = retVal.criticalDamage + bonus.criticalDamage;
            retVal.criticalChance = retVal.criticalChance + bonus.criticalChance;
            retVal.potency = retVal.potency + bonus.potency;
            retVal.accuracy = retVal.accuracy + bonus.accuracy;
            retVal.protection = retVal.protection + bonus.protection;
            retVal.protectionPct = retVal.protectionPct + bonus.protectionPct;
            retVal.criticalAvoidance = retVal.criticalAvoidance + bonus.criticalAvoidance;
        })

        return retVal;
    }

    private static createDummyPlayerCharacterData(): PlayerCharacterData
    {
        return new PlayerCharacterData({
            level: 85,
            gear: {

            },
            mods: {

            },
            power: {

            },
            stats: {

            }
        });
    }

    private static percentPerfect(base: number, current: number, perfect: number): number
    {
        return Math.round((current - base) / (perfect - base) * 100);
    }

    public static getSpeedPlusAmounts(guildData: IGuildData): Map<string, number[]>
    {
        let retVal: Map<string, number[]> = new Map();

        guildData.players.forEach(p =>
        {
            if (p.roster !== undefined)
            {
                p.roster.filter(r => r.gear.level >= 12).forEach(r =>
                {
                    if (retVal.has(r.baseId) === false)
                    {
                        retVal.set(r.baseId, []);
                    }

                    if (r.mods !== undefined)
                    {
                        let speedPlus = r.mods!.plusSpeed;
                        let baseSpeed = r.stats.speed;

                        if (r.mods.setTypes !== undefined)
                        {
                            if (r.mods.setTypes.find(st => st === ModSets.Speed) !== undefined)
                            {
                                speedPlus = speedPlus - (baseSpeed * .1);
                            }
                        }
                        retVal.get(r.baseId)!.push(speedPlus);
                    }
                });
            }
        });
        return retVal;
    }

    public static getGuildModTypeCounts(guildData: IGuildData): Map<string, ModCounts>
    {
        let retVal: Map<string, ModCounts> = new Map();
        guildData.players.forEach(p =>
        {
            if (p.roster !== undefined)
            {
                p.roster.filter(r => r.gear.level >= 12).forEach(r =>
                {
                    if (retVal.has(r.baseId) === false)
                    {
                        retVal.set(r.baseId, {
                            setTypes: new Map(),
                            primaryArrow: new Map(),
                            primaryTriangle: new Map(),
                            primaryCircle: new Map(),
                            primaryCross: new Map(),
                            totalCounts: 0
                        });
                    }
                    let unitCounts = retVal.get(r.baseId)!;
                    unitCounts.totalCounts = unitCounts.totalCounts + 1;
                    if (r.mods !== undefined)
                    {
                        if (r.mods.primaryArrow !== undefined)
                        {
                            let existingCount = unitCounts.primaryArrow.has(r.mods.primaryArrow) ? unitCounts.primaryArrow.get(r.mods.primaryArrow)! : 0;
                            unitCounts.primaryArrow.set(r.mods.primaryArrow, existingCount + 1);
                        }
                        if (r.mods.primaryTriangle !== undefined)
                        {
                            let existingCount = unitCounts.primaryTriangle.has(r.mods.primaryTriangle) ? unitCounts.primaryTriangle.get(r.mods.primaryTriangle)! : 0;
                            unitCounts.primaryTriangle.set(r.mods.primaryTriangle, existingCount + 1);
                        }
                        if (r.mods.primaryCircle !== undefined)
                        {
                            let existingCount = unitCounts.primaryCircle.has(r.mods.primaryCircle) ? unitCounts.primaryCircle.get(r.mods.primaryCircle)! : 0;
                            unitCounts.primaryCircle.set(r.mods.primaryCircle, existingCount + 1);
                        }
                        if (r.mods.primaryCross !== undefined)
                        {
                            let existingCount = unitCounts.primaryCross.has(r.mods.primaryCross) ? unitCounts.primaryCross.get(r.mods.primaryCross)! : 0;
                            unitCounts.primaryCross.set(r.mods.primaryCross, existingCount + 1);
                        }
                        if (r.mods.setTypes !== undefined)
                        {
                            r.mods.setTypes.forEach(st =>
                            {
                                let actualSet: ModSets = ModSets.Speed;
                                switch (st)
                                {
                                    case StatIds.HealthPct:
                                        actualSet = ModSets.Health;
                                        break;
                                    case StatIds.CriticalChance:
                                        actualSet = ModSets.CriticalChance;
                                        break;
                                    case 57:
                                        actualSet = ModSets.Speed;
                                        break;
                                    case StatIds.OffensePct:
                                        actualSet = ModSets.Offense;
                                        break;
                                    case StatIds.CriticalDamage:
                                        actualSet = ModSets.CriticalDamage;
                                        break;
                                    case StatIds.DefensePct:
                                        actualSet = ModSets.Defense;
                                        break;
                                    case StatIds.Potency:
                                        actualSet = ModSets.Potency;
                                        break;
                                    case StatIds.Tenacity:
                                        actualSet = ModSets.Tenacity;
                                        break;
                                }
                                let existingCount = unitCounts.setTypes.has(actualSet) ? unitCounts.setTypes.get(actualSet)! : 0;
                                unitCounts.setTypes.set(actualSet, existingCount + 1);
                            });
                        }
                    }
                });
            }
        });
        return retVal;
    }

    public static calculateGuildAverages(guildData: IGuildData): Map<string, LoadoutDefinitionStatWeights[]>
    {
        let weights: Map<string, LoadoutDefinitionStatWeights[]> = new Map();

        let dumyPlayerCharacter = ModCalculator.createDummyPlayerCharacterData();

        let ps = new PerfectSets();

        guildData.players.forEach(p =>
        {
            if (p.roster !== undefined)
            {
                p.roster.filter(r => r.gear.level >= 12).forEach(r =>
                {
                    if (r.baseId === 'GRIEVOUS')
                    {
                        console.log("debug grevious");
                    }
                    let baseStats = UnitStats.fromGuildRoster(r);

                    let perfectSpeedStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.PERFECT_SPEED_SET);
                    let perfectOffenseStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.PERFECT_OFFENSE_SET);
                    let perfectHealthStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.PERFECT_HEALTH_SET);
                    let perfectTenacityStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.PERFECT_TENACITY_SET);
                    let perfectPotencyStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.PERFECT_POTENCY_SET);
                    let perfectCritDamageStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.PERFECT_CRIT_DAMAGE_SET);
                    let perfectCritChanceStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.PERFECT_CRIT_CHANCE_SET);
                    let perfectDefenseStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.PERFECT_DEFENSE_SET);
                    let perfectProtectionStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.PERFECT_PROTECTION_SET);
                    let perfectCritAvoidStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.PERFECT_CRIT_AVOID_SET);
                    let perfectAccuracyStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.PERFECT_ACCURACY_SET);

                    let minOffenseStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.MIN_OFFENSE_SET);
                    let minDefenseStats = ModCalculator.calculateUpdatedStats(dumyPlayerCharacter, baseStats, ps.MIN_DEFENSE_SET);

                    let minDamage = minOffenseStats.damage - baseStats.damage;
                    let minSpecialDamage = minOffenseStats.specialDamage - baseStats.specialDamage;
                    let minArmor = minDefenseStats.armor - baseStats.armor;
                    let minResisance = minDefenseStats.resistance - baseStats.resistance;

                    if (weights.has(r.baseId) === false)
                    {
                        weights.set(r.baseId, []);
                    }

                    let unitWeights = new LoadoutDefinitionStatWeights({
                        health: ModCalculator.percentPerfect(baseStats.health, r.stats.health, perfectHealthStats.health),
                        protection: ModCalculator.percentPerfect(baseStats.protection, r.stats.protection, perfectProtectionStats.protection),
                        speed: ModCalculator.percentPerfect(baseStats.speed, r.stats.speed, perfectSpeedStats.speed),
                        criticalDamage: ModCalculator.percentPerfect(baseStats.critDamage, r.stats.critDamage, perfectCritDamageStats.critDamage),
                        potency: ModCalculator.percentPerfect(baseStats.potency, r.stats.potency, perfectPotencyStats.potency),
                        tenacity: ModCalculator.percentPerfect(baseStats.tenacity, r.stats.tenacity, perfectTenacityStats.tenacity),
                        physicalDamage: ModCalculator.percentPerfect(baseStats.damage + minDamage, r.stats.damage, perfectOffenseStats.damage),
                        specialDamage: ModCalculator.percentPerfect(baseStats.specialDamage + minSpecialDamage, r.stats.specialDamage, perfectOffenseStats.specialDamage),
                        criticalChance: ModCalculator.percentPerfect(baseStats.critChance, r.stats.critChance, perfectCritChanceStats.critChance),
                        armor: ModCalculator.percentPerfect(baseStats.armor + minArmor, r.stats.armor, perfectDefenseStats.armor),
                        resistance: ModCalculator.percentPerfect(baseStats.resistance + minResisance, r.stats.resistance, perfectDefenseStats.resistance),
                        accuracy: ModCalculator.percentPerfect(baseStats.accuracy, r.stats.accuracy, perfectAccuracyStats.accuracy),
                        criticalAvoidance: ModCalculator.percentPerfect(baseStats.critAvoidance, r.stats.critAvoidance, perfectCritAvoidStats.critAvoidance)
                    });

                    let largestWeight: LoadoutDefintionStatWeightField | undefined = undefined;
                    // find which field has the highest percent
                    LD_STAT_WEIGHT_FIELDS.forEach(swf =>
                    {
                        let statWeightValue = swf.getValue(unitWeights);
                        if (largestWeight === undefined || statWeightValue > largestWeight.getValue(unitWeights))
                        {
                            largestWeight = swf;
                        }
                    });

                    // normalize all fields to this max percent (aka so one field will be 100% and all will be relative to that field)
                    let maxPercentValue = largestWeight!.getValue(unitWeights);

                    let normalizedWeights = LoadoutDefinitionStatWeights.zero();

                    LD_STAT_WEIGHT_FIELDS.forEach(swf =>
                    {
                        let normalizedValue = Math.round(swf.getValue(unitWeights) / maxPercentValue * 100);
                        let minValue = normalizedValue < 5 ? 0 : normalizedValue;

                        swf.setValue(normalizedWeights, minValue);
                    });

                    // normalized weights are the "percentage of max each stat gets"

                    let scoreStatWeight = LoadoutDefinitionStatWeights.zero();
                    largestWeight!.setValue(scoreStatWeight, largestWeight!.getMaxValue());

                    let maxAllStats = new UnitStats();

                    maxAllStats.health = perfectHealthStats.health;
                    maxAllStats.protection = perfectProtectionStats.protection;
                    maxAllStats.speed = perfectSpeedStats.speed;
                    maxAllStats.critDamage = perfectCritDamageStats.critDamage;
                    maxAllStats.potency = perfectPotencyStats.potency;
                    maxAllStats.tenacity = perfectTenacityStats.tenacity;
                    maxAllStats.defense = perfectDefenseStats.defense;
                    maxAllStats.damage = perfectOffenseStats.damage;
                    maxAllStats.specialDamage = perfectOffenseStats.specialDamage;
                    maxAllStats.critChance = perfectCritChanceStats.critChance;
                    maxAllStats.armor = perfectDefenseStats.armor;
                    maxAllStats.resistance = perfectDefenseStats.resistance;
                    maxAllStats.accuracy = perfectAccuracyStats.accuracy;
                    maxAllStats.critAvoidance = perfectCritAvoidStats.critAvoidance;

                    let biggestScore = scoreStatWeight.getScore(maxAllStats, baseStats, r.level);

                    let finalPushValue = LoadoutDefinitionStatWeights.zero();

                    LD_STAT_WEIGHT_FIELDS.forEach(swf =>
                    {
                        let finalScoreSw = LoadoutDefinitionStatWeights.zero();
                        swf.setValue(finalScoreSw, swf.getMaxValue());

                        let thisBiggestScore = finalScoreSw.getScore(maxAllStats, baseStats, r.level);

                        let thisWeight = swf.getValue(normalizedWeights) / 100;

                        let optimizationWeight = biggestScore / thisBiggestScore * thisWeight * swf.getMaxValue();
                        swf.setValue(finalPushValue, optimizationWeight);
                    });

                    let unadjustedWeights = LoadoutDefinitionStatWeights.zero();

                    LD_STAT_WEIGHT_FIELDS.forEach(swf =>
                    {
                        let thisWeight = swf.getValue(normalizedWeights) / 100;
                        swf.setValue(unadjustedWeights, swf.getMaxValue() * thisWeight);
                    });


                    ModCalculator.enforceWeightMaximums(finalPushValue);
                    if (Math.round(maxPercentValue) !== 0)
                    {
                        weights.get(r.baseId)!.push(finalPushValue);
                    }
                });
            }
        });
        return weights;
    }

    private static enforceWeightMaximums(ldsw: LoadoutDefinitionStatWeights)
    {
        let reduceFactory = 1;

        LD_STAT_WEIGHT_FIELDS.forEach(swf =>
        {
            let score = swf.getValue(ldsw);
            let maxScore = swf.getMaxValue();
            if (score > maxScore && (maxScore / score) < reduceFactory)
            {
                reduceFactory = maxScore / score;
            }
        });

        if (reduceFactory !== 1)
        {
            LD_STAT_WEIGHT_FIELDS.forEach(swf =>
            {
                let currentValue = swf.getValue(ldsw);
                swf.setValue(ldsw, currentValue * reduceFactory);
            });
        }
    }


    public static critDamageBaseToReadable(val: number): number
    {
        return val * 100;
    }
    public static potencyBaseToReadable(val: number): number
    {
        return val * 100;
    }
    public static tenacityBaseToReadable(val: number): number
    {
        return val * 100;
    }
    public static critChanceBaseToReadable(val: number): number
    {
        return (val / 2400 + 0.1) * 100;
    }
    public static armorBaseToReadable(val: number, level: number): number
    {
        var level_effect = (level * 7.5);
        return (val / (level_effect + val)) * 100;
    }
    public static accuracyBaseToReadable(val: number): number
    {
        return (val / 1200) * 100;
    }

    public static criticalAvoidanceBaseToReadable(val: number): number
    {
        return (val / 2400) * 100;
    }

}
