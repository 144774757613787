import { observable, toJS, action } from "mobx";
import ModSets from "./ModSets";
import StatIds from "./StatIds";

export type FilterOperation = "Any" | "Equal" | "Not Equal" | "Greater Than" | "Less Than" | "Rolls Greater Than" | "Rolls Less Than" | "Pct of Max" 
    | "Pct of Max by Tier" | "Less Than Pct of Max" | "Less Than Pct of Max by Tier" | "Average Per Roll Greater Than" | "Average Per Roll Less Than";

export type ExtraSecondaryStats = "Reroll Count" | "Level" | "Any Non Speed" | "Flat Non Speed" | "Non Flat" | "Speed Remainder" | "Non Flat Count";

class ModSingleFilterData
{
    id: string = "";
    @observable name: string = "";
    @observable slots: number[] = [];
    @observable sets: ModSets[] = [];
    @observable primaries: StatIds[] = [];
    @observable secondaries: StatIds[] = [];
    @observable secondariesNotPresent: StatIds[] = [];
    @observable tiers: number[] = [];
    @observable levels: number[] = [];
    @observable rarities: number[] = [];
    @observable secondary1Stat: StatIds | ExtraSecondaryStats | null = null;
    @observable secondary1Operation: FilterOperation = "Any";
    @observable secondary1Value: number = 0;
    @observable secondary2Stat: StatIds | ExtraSecondaryStats | null = null;
    @observable secondary2Operation: FilterOperation = "Any";
    @observable secondary2Value: number = 0;
    @observable secondary3Stat: StatIds | ExtraSecondaryStats | null = null;
    @observable secondary3Operation: FilterOperation = "Any";
    @observable secondary3Value: number = 0;
    @observable secondary4Stat: StatIds | ExtraSecondaryStats | null = null;
    @observable secondary4Operation: FilterOperation = "Any";
    @observable secondary4Value: number = 0;
    @observable inLoadout: boolean | null = null;
    @observable onlySlicable: boolean = false;
    @observable equipped: "None" | "Only" | null = null;
    @observable exposed: "Full" | "Not" | null = null;

    @action
    public fromJSON(json: any): void
    {
        if (!this.id)
            this.id = json.id;
        
        this.name = json.name;
        this.slots = json.slots;
        this.sets = json.sets;
        this.primaries = json.primaries;
        this.secondaries = json.secondaries;
        this.tiers = json.tiers;
        this.rarities = json.rarities;
        this.levels = json.levels;
        this.secondary1Stat = json.secondary1Stat;
        this.secondary1Operation = json.secondary1Operation;
        this.secondary1Value = json.secondary1Value;
        this.secondary2Stat = json.secondary2Stat;
        this.secondary2Operation = json.secondary2Operation;
        this.secondary2Value = json.secondary2Value;
        this.secondary3Stat = json.secondary3Stat;
        this.secondary3Operation = json.secondary3Operation;
        this.secondary3Value = json.secondary3Value;
        this.secondary4Stat = json.secondary4Stat;
        this.secondary4Operation = json.secondary4Operation;
        this.secondary4Value = json.secondary4Value;
        this.secondariesNotPresent = json.secondariesNotPresent ? json.secondariesNotPresent : [];
        this.inLoadout = json.inLoadout === undefined ? null : json.inLoadout;
        this.onlySlicable = json.onlySlicable === undefined ? false : json.onlySlicable;
        this.equipped = json.equipped === undefined ? null : json.equipped;
        this.exposed = json.exposed === undefined ? null : json.exposed;
    }

    public toJSON()
    {
        return toJS(this);
    }
}

export default ModSingleFilterData;
