import React from 'react';
import {readableNumber} from '../../utils/readableTime';
import {Col, Row, Tooltip, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChartArea,
    faGem,
    faHelmetBattle,
    faQrcode,
    faRunning,
    faScrollOld,
    faStars,
    faSwordsLaser,
    faTachometerFast,
    faTachometerSlow,
    faUserChart,
    faUsers,
    faRabbitFast,
    faInfoCircle,
    faClock,
    faCampground,
    faHistory,
    faEnvelopeOpenDollar,
    faChartPie,
    faChartPieAlt,
    faUserPlus,
    faUserEdit,
    faUserAlien,
    faTicketAlt,
    faClipboardPrescription,
    faUniversalAccess,
    faStarfighter,
    faPersonSign, faTicket, faCalendarEdit, faCalendarPlus, faSitemap, faUsersClass, faCubesStacked,
} from "@fortawesome/pro-duotone-svg-icons";
import {faGalacticRepublic, faModx} from "@fortawesome/free-brands-svg-icons";

import styles from './styles/TableTitle.module.scss';
import UnitAvatar from "../swgoh/Character/UnitAvatar";
import UnitData from "../../model/UnitData";
const { Text } = Typography;

interface ITableTitleProps {
    averages?: boolean;
    combatType?: boolean;
    guildName?: string;
    name?: string;
    avatar?: UnitData | undefined;
    members?: number;
    gaLeague?: number;
    gaLeagueRank?: string;
    galacticLegends?: number;
    galacticLegendsUltimate?: number;
    gearScore?: number;
    modScore?: number;
    totalScore?: number;
    zetas?: number;
    omicrons4?: number;
    omicrons3?: number;
    omicrons2?: number;
    omicrons1?: number;
    omicrons?: number;
    omicronsTW?: number;
    omicronsTB?: number;
    omicronsGAC?: number;
    omicronsCQ?: number;
    gpPer15speedMods?: number;
    gpPer20speedMods?: number;
    mods6?: number;
    gear11?: number;
    gear12?: number;
    gear13?: number;
    relic5Plus?: number | null;
    relic5?: number;
    relic6?: number;
    relic7?: number;
    relic8?: number;
    relic9?: number;
    rarity7?: number;
    rarity6?: number;
    rarity5?: number;
    avgSpeedMods?: number;
    avgSpeed?: number;
    zeta6?: number;
    zeta5?: number;
    zeta4?: number;
    zeta3?: number;
    zeta2?: number;
    zeta1?: number;
    gp?: number;
    guildGP?: number;
    guildGPChar?: number;
    guildGPShip?: number;
    gpChar65?: number;
    gpChar80?: number;
    gpChar?: number;
    gpShip?: number;
    speedMods?: any;
    gaSeasonPoints?: number;
    gaLifetime?: number;
    gaOffensiveWins?: number;
    gaUndersizedWins?: number;
    gaDefensiveWins?: number;
    gaBanners?: number;
    gaFullClears?: number;
    gaZonesCleared?: number;
    gacSkillRating?: number | null;
    arenaSquad?: number;
    arenaShip?: number;
    grandArena?: number;
    allyCode?: string;
    guildRank?: number | string;
    credits?: number | null;
    shipCredits?: number | null;
    crystals?: number | null;
    nextPayoutUTC?: any | number;
    lastActivity?: any | number;
    compare?: boolean;
    compareUnits?: boolean;
    createdBy?: string;
    lastEditedBy?: string;
    dateCreated?: string;
    dateEdited?: string;
    assignedMembers?: number;
    assignedSquads?: number;
    unassignedCharacters?: number;
    duplicatedUnitsPerPlayer?: number;
    isTW?: boolean;
    description?: string;
    datacrons?: number;
    templateName?: string;
    templateCategory?: string[];
    avgGuildTicketsdays?: number;
    avgGuildTickets7days?: number;
    completedSquads?: string;
    platoonType?: string;
    platoonZone?: string;
    availablePlatoonUnits?: string;
    platoonFilters?: IFiltersProps;
    updated?: string;
}

interface IFiltersProps {
    gp?: number;
    relicLevel?: number | null;
    gearLevel?: number | null;
    starLevel?: number;
}

export default class TableTitle extends React.PureComponent<ITableTitleProps> {
    render() {
        return (
            <Row className={`${styles['table-title']} ${this.props.compare ? `${styles.compare} compareColumn` : ''}`}>
                {(this.props.name || this.props.avatar) && (
                    <Row className={styles.header}>
                        <Col span={24} className={`${styles.title} ${this.props.compare ? `compareTitle` : ''}`}>
                            {this.props.avatar && <UnitAvatar className={styles.img} playerUnit={undefined} displayName={false} unitData={this.props.avatar} size={"medium"} onClick={() => null} />}
                            {this.props.name && <Text className={'titleRow'}><strong>{this.props.name}</strong></Text>}
                        </Col>
                    </Row>
                )}

                {this.props.guildName && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faCampground} size={'1x'} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Guild</span>
                                <span className={'titleStrong'}>[ <strong>{this.props.guildName}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.allyCode && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faQrcode}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Ally Code </span>
                                <span className={'titleStrong'}>[ <strong>{readableNumber(parseInt(this.props.allyCode), '-')}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.guildGP && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faUserChart}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>GP </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gp'}>{readableNumber(this.props.guildGP)}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.templateCategory && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faSitemap} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Category </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gp'}>{this.props.templateCategory}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.templateName && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faScrollOld} />
                            <Text className={'titleRow'}>
                                <span className={'titleStrong'}><strong>{this.props.templateName}</strong></span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.guildGPChar && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartPieAlt}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>GP Chars </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gpchar'}>{readableNumber(this.props.guildGPChar)}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.guildGPShip && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartPie}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>GP Ships </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gpship'}>{readableNumber(this.props.guildGPShip)}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.members || (this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faUsers}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Members </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'members'}>{this.props.members}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.completedSquads && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faUsers}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Completed Squads </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'completedSquads'}>{this.props.completedSquads}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.gp && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faStars}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GP </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'avggp'}>{readableNumber(this.props.gp)}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {((this.props.gaLeague !== undefined && this.props.gaLeague >= 1) || (this.props.averages && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faSwordsLaser}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>No of Kybers: </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'galeague'}>{this.props.gaLeague}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(this.props.gaLeagueRank !== undefined) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faSwordsLaser}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>GAC League: </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gaLeagueRank'}>{this.props.gaLeagueRank}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.datacrons || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faCubesStacked}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Datacrons </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'datacrons'}>{this.props.datacrons ? this.props.datacrons : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.zetas || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"zeta"}
                                    title={"Zeta"}
                                    src="https://api.hotutils.com/images/zeta/zeta_glow.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Zetas </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'zetas'}>{this.props.zetas ? this.props.zetas : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.omicronsTB || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"omicron"}
                                    title={"Omicron"}
                                    src="https://api.hotutils.com/images/omicron/omicron_white.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Omicrons TB</span>
                                <span className={'titleStrong'}>[ <strong data-compare={'omicrons'}>{this.props.omicronsTB ? this.props.omicronsTB : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.omicronsTW || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"omicron"}
                                    title={"Omicron"}
                                    src="https://api.hotutils.com/images/omicron/omicron_white.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Omicrons TW</span>
                                <span className={'titleStrong'}>[ <strong data-compare={'omicrons'}>{this.props.omicronsTW ? this.props.omicronsTW : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.omicronsGAC || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"omicron"}
                                    title={"Omicron"}
                                    src="https://api.hotutils.com/images/omicron/omicron_white.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Omicrons GAC</span>
                                <span className={'titleStrong'}>[ <strong data-compare={'omicrons'}>{this.props.omicronsGAC ? this.props.omicronsGAC : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.omicronsCQ || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"omicron"}
                                    title={"Omicron"}
                                    src="https://api.hotutils.com/images/omicron/omicron_white.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Omicrons CQ</span>
                                <span className={'titleStrong'}>[ <strong data-compare={'omicrons'}>{this.props.omicronsCQ ? this.props.omicronsCQ : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.omicrons || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"omicron"}
                                    title={"Omicron"}
                                    src="https://api.hotutils.com/images/omicron/omicron_white.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Omicrons </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'omicrons'}>{this.props.omicrons ? this.props.omicrons : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.omicrons1 || (this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"omicron"}
                                    title={"Omicron"}
                                    src="https://api.hotutils.com/images/omicron/omicron_white.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Omicrons x1</span>
                                <span className={'titleStrong'}>[ <strong data-compare={'omicrons1'}>{this.props.omicrons1}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.omicrons2 || (this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"omicron"}
                                    title={"Omicron"}
                                    src="https://api.hotutils.com/images/omicron/omicron_white.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Omicrons x2</span>
                                <span className={'titleStrong'}>[ <strong data-compare={'omicrons2'}>{this.props.omicrons2}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.omicrons3 || (this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"omicron"}
                                    title={"Omicron"}
                                    src="https://api.hotutils.com/images/omicron/omicron_white.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Omicrons x3</span>
                                <span className={'titleStrong'}>[ <strong data-compare={'omicrons3'}>{this.props.omicrons3}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.omicrons4 || (this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"omicron"}
                                    title={"Omicron"}
                                    src="https://api.hotutils.com/images/omicron/omicron_white.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Omicrons x4</span>
                                <span className={'titleStrong'}>[ <strong data-compare={'omicrons4'}>{this.props.omicrons4}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.credits && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"credits"}
                                    title={"credits"}
                                    src="https://api.hotutils.com/images/currency/currency_credits.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Credits </span>
                                <span className={'titleStrong'}>[ <strong>{this.props.credits.toLocaleString()}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.shipCredits && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"ship-credits"}
                                    title={"Ship Credits"}
                                    src="https://api.hotutils.com/images/currency/currency_ship.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Ship Credits </span>
                                <span className={'titleStrong'}>[ <strong>{this.props.shipCredits.toLocaleString()}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.crystals && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"crystals"}
                                    title={"Crystals"}
                                    src="https://api.hotutils.com/images/currency/currency_crystals.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Crystals </span>
                                <span className={'titleStrong'}>[ <strong>{this.props.crystals.toLocaleString()}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.totalScore && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faTachometerFast}/>
                            <Text className={'titleRow'}>
                                <Tooltip title="Total Quality Score = Mod Quality + Gear Quality">
                                    <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Total Quality Score
                                    </span>
                                    <span className={'titleStrong'}>[ <strong data-compare={'totalscore'}>{readableNumber(this.props.totalScore)}</strong> ]</span>
                                </Tooltip>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.modScore && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faTachometerSlow}/>
                            <Text className={'titleRow'}>
                                <Tooltip title="((# of 15-19 Speed * 0.8) + (# of 20-24 Speed) + (# of 25+ Speed * 1.6)) / (squadGP / 100,000)">
                                    <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Mod Score </span>
                                    <span className={'titleStrong'}>[ <strong  data-compare={'modscore'}>{readableNumber(this.props.modScore)}</strong> ]</span>
                                </Tooltip>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.gearScore && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faTachometerSlow}/>
                            <Text className={'titleRow'}>

                                <Tooltip title="Gear Quality it's the sum of the number of G12 + G13 + Relic Score) / (Total GP / 100000), Relic score: 1 + (0.2 bonus per relic tier) (ex: r0 = 1, r1 = 1.2, r2 = 1.4, ..., r8 = 2.8, r9 = 3.2">
                                    <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Gear Score </span>
                                    <span className={'titleStrong'}>[ <strong data-compare={'gearscore'}>{readableNumber(this.props.gearScore)}</strong> ]</span>
                                </Tooltip>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.galacticLegends || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faGalacticRepublic}/>
                            <Text className={'titleRow'}>
                                <Tooltip title="Total number of Galactic Legends">
                                    <span className={'titleName'}>{this.props.averages ? 'Total ' : ''}Galactic Legends </span>
                                    <span className={'titleStrong'}>[ <strong  data-compare={'gl'}>{readableNumber(this.props.galacticLegends ? this.props.galacticLegends : 0)}</strong> ]</span>
                                </Tooltip>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.galacticLegendsUltimate || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faGalacticRepublic}/>
                            <Text className={'titleRow'}>
                                <Tooltip title="Total number of Galactic Legends Ultimate">
                                    <span className={'titleName'}>{this.props.averages ? 'Total ' : ''}Galactic Legends with ultimate</span>
                                    <span className={'titleStrong'}>[ <strong  data-compare={'gl'}>{readableNumber(this.props.galacticLegendsUltimate ? this.props.galacticLegendsUltimate : 0)}</strong> ]</span>
                                </Tooltip>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.gpChar65 && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faStars}/>
                            <Text className={'titleRow'}>
                                <Tooltip title="The GP of top 65 characters">
                                    <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GP Top 65 </span>
                                    <span className={'titleStrong'}>[ <strong data-compare={'gptop65'}>{readableNumber(this.props.gpChar65)}</strong> ]</span>
                                </Tooltip>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.gpChar80 && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faStars}/>
                            <Text className={'titleRow'}>
                                <Tooltip title="The GP of top 80 characters">
                                    <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GP Top 80 </span>
                                    <span className={'titleStrong'}>[ <strong data-compare={'gptop80'}>{readableNumber(this.props.gpChar80)}</strong> ]</span>
                                </Tooltip>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.gpChar && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faStars}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GP Chars </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gpchars'}>{readableNumber(this.props.gpChar)}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.gpShip && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faStars}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GP Ships </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gpships'}>{readableNumber(this.props.gpShip)}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.arenaSquad && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartArea}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Squad Arena Rank </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'arenaSquad'}>#{this.props.arenaSquad}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.arenaShip && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartArea}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Fleet Arena Rank </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'arenaShip'}>#{this.props.arenaShip}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.grandArena || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartArea}/>
                            <Text className={'titleRow'}>
                                <Tooltip title="The Grand Arena Rank">
                                    <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GA Rank </span>
                                    <span className={'titleStrong'}>[ <strong data-compare={'garank'}>#{this.props.grandArena}</strong> ]</span>
                                </Tooltip>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.rarity7 || (this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faGem}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Rarity 7 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'rarity7'}>{this.props.rarity7}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.rarity6 || (this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faGem}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Rarity 6 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'rarity6'}>{this.props.rarity6}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.rarity5 || (this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faGem}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Rarity 5 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'rarity5'}>{this.props.rarity5}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.avgSpeed && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faRabbitFast} />
                            <Text className={'titleRow'}>
                                <Tooltip title="Avg speed of the characters">
                                    <span className={'titleName'}>Avg speed </span>
                                    <span className={'titleStrong'}>[ <strong data-compare={'avgspeed'}>{this.props.avgSpeed}</strong> ]</span>
                                </Tooltip>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.avgSpeedMods && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faRabbitFast} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Avg Speed Mods </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'avgspeedmods'}>{this.props.avgSpeedMods}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.zeta6 || (this.props.compareUnits && (this.props.compare && !this.props.combatType))) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"crystals"}
                                    title={"Crystals"}
                                    src="https://api.hotutils.com/images/zeta/zeta_glow.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Zeta x6 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'zeta6'}>{this.props.zeta6}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.zeta5 || (this.props.compareUnits && (this.props.compare && !this.props.combatType))) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"crystals"}
                                    title={"Crystals"}
                                    src="https://api.hotutils.com/images/zeta/zeta_glow.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Zeta x5 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'zeta5'}>{this.props.zeta5}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.zeta4 || (this.props.compareUnits && (this.props.compare && !this.props.combatType))) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"crystals"}
                                    title={"Crystals"}
                                    src="https://api.hotutils.com/images/zeta/zeta_glow.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Zeta x4 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'zeta4'}>{this.props.zeta4}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.zeta3 || (this.props.compareUnits && (this.props.compare && !this.props.combatType))) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"crystals"}
                                    title={"Crystals"}
                                    src="https://api.hotutils.com/images/zeta/zeta_glow.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Zeta x3 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'zeta3'}>{this.props.zeta3}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.zeta2 || (this.props.compareUnits && (this.props.compare && !this.props.combatType))) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"crystals"}
                                    title={"Crystals"}
                                    src="https://api.hotutils.com/images/zeta/zeta_glow.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Zeta x2 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'zeta2'}>{this.props.zeta2}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.zeta1 || (this.props.compareUnits && (this.props.compare && !this.props.combatType))) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <div className={styles.img}>
                                <img
                                    width={24}
                                    alt={"crystals"}
                                    title={"Crystals"}
                                    src="https://api.hotutils.com/images/zeta/zeta_glow.png"
                                />
                            </div>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Zeta x1 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'zeta1'}>{this.props.zeta1}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gear13 || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faHelmetBattle}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Gear 13 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gear13'}>{this.props.gear13 ? this.props.gear13 : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gear12 || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faHelmetBattle}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Gear 12 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gear12'}>{this.props.gear12 ? this.props.gear12 : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gear11 || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faHelmetBattle}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Gear 11 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gear11'}>{this.props.gear11 ? this.props.gear11 : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.relic9 || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faScrollOld}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Relic 9 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'relic9'}>{this.props.relic9 ? this.props.relic9 : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.relic8 || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faScrollOld}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Relic 8 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'relic8'}>{this.props.relic8 ? this.props.relic8 : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.relic7 || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faScrollOld}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Relic 7 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'relic7'}>{this.props.relic7 ? this.props.relic7 : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.relic6 || (this.props.compare && !this.props.combatType)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faScrollOld}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}Relic 6 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'relic6'}>{this.props.relic6 ? this.props.relic6 : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                  {(!!this.props.relic5 || (this.props.compare && (this.props.compareUnits && !this.props.combatType))) && (
                      <Col className={styles.item}>
                          <div className={styles.wrapper}>
                              <FontAwesomeIcon icon={faScrollOld}/>
                              <Text className={'titleRow'}>
                                <span className={'titleName'}>  {this.props.averages ? 'Avg ' : ''}Relic 5 </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'relic5'}>{this.props.relic5 ? this.props.relic5 : 0}</strong> ]</span>
                              </Text>
                          </div>
                      </Col>
                  )}

                  {(!!this.props.relic5Plus || (!this.props.compareUnits && this.props.compare)) && (
                      <Col className={styles.item}>
                          <div className={styles.wrapper}>
                              <FontAwesomeIcon icon={faScrollOld}/>
                              <Text className={'titleRow'}>
                                <span className={'titleName'}>  {this.props.averages ? 'Avg ' : ''}Relic 5+ </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'relic5Plus'}>{this.props.relic5Plus ? this.props.relic5Plus : 0}</strong> ]</span>
                              </Text>
                          </div>
                      </Col>
                  )}

                {(!!this.props.mods6 || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faModx}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}6&#176; Mods </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'mods6'}>{this.props.mods6}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gpPer15speedMods || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faRunning}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GP / 15+ Spd </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gpPer15speedMods'}>{this.props.gpPer15speedMods ? this.props.gpPer15speedMods.toLocaleString() : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gpPer20speedMods || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faRunning}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GP / 20+ Spd </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gpPer20speedMods'}>{this.props.gpPer20speedMods ? this.props.gpPer20speedMods.toLocaleString() : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.speedMods || (!this.props.compareUnits && this.props.compare)) && (
                    <>
                        <Col className={styles.item}>
                            <div className={styles.wrapper}>
                                <FontAwesomeIcon icon={faRabbitFast}/>
                                <Text className={'titleRow'}>
                                    <Tooltip title="Total 10+ speed mods you have equipped">
                                        <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}10+ Spd Mods </span>
                                        <span className={'titleStrong'}>[ <strong data-compare={'speedMods10'}>{this.props.speedMods[0] ? this.props.speedMods[0] : 0}</strong> ]</span>
                                    </Tooltip>
                                </Text>
                            </div>
                        </Col>
                        <Col className={styles.item}>
                            <div className={styles.wrapper}>
                                <FontAwesomeIcon icon={faRabbitFast}/>
                                <Text className={'titleRow'}>
                                    <Tooltip title="Total 15+ speed mods you have equipped">
                                        <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}15+ Spd Mods </span>
                                        <span className={'titleStrong'}>[ <strong data-compare={'speedMods15'}>{this.props.speedMods[1] ? this.props.speedMods[1] : 0}</strong> ]</span>
                                    </Tooltip>
                                </Text>
                            </div>
                        </Col>
                        <Col className={styles.item}>
                            <div className={styles.wrapper}>
                                <FontAwesomeIcon icon={faRabbitFast}/>
                                <Text className={'titleRow'}>
                                    <Tooltip title="Total 20+ speed mods you have equipped">
                                        <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}20+ Spd Mods </span>
                                        <span className={'titleStrong'}>[ <strong data-compare={'speedMods20'}>{this.props.speedMods[2] ? this.props.speedMods[2] : 0}</strong> ]</span>
                                    </Tooltip>
                                </Text>
                            </div>
                        </Col>
                        <Col className={styles.item}>
                            <div className={styles.wrapper}>
                                <FontAwesomeIcon icon={faRabbitFast}/>
                                <Text className={'titleRow'}>
                                    <Tooltip title="Total 25+ speed mods you have equipped">
                                        <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}25+ Spd Mods </span>
                                        <span className={'titleStrong'}>[ <strong data-compare={'speedMods25'}>{this.props.speedMods[3] ? this.props.speedMods[3] : 0}</strong> ]</span>
                                    </Tooltip>
                                </Text>
                            </div>
                        </Col>
                    </>
                )}

                {(!!this.props.gaSeasonPoints || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartArea}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GA Season Pts </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gaSeasonPoints'}>{this.props.gaSeasonPoints ? this.props.gaSeasonPoints : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gaLifetime || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartArea}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GA Lifetime </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'galifetime'}>{this.props.gaLifetime ? this.props.gaLifetime.toLocaleString() : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gaOffensiveWins || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartArea}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GA Offensive Wins </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gaOffensiveWins'}>{this.props.gaOffensiveWins ? this.props.gaOffensiveWins.toLocaleString() : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gaUndersizedWins || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartArea}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GA Undersized Wins </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gaUndersizedWins'}>{this.props.gaUndersizedWins ? this.props.gaUndersizedWins.toLocaleString() : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gaDefensiveWins || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartArea}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GA Defensive Wins </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gaDefensiveWins'}>{this.props.gaDefensiveWins ? this.props.gaDefensiveWins.toLocaleString() : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gaBanners || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartArea}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GA Banners </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gaBanners'}>{this.props.gaBanners ? this.props.gaBanners.toLocaleString() : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gaFullClears || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartArea}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GA Full Clears </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gaFullClears'}>{this.props.gaFullClears ? this.props.gaFullClears.toLocaleString() : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gaZonesCleared || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartArea}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GA Zones Cleared </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gaZonesCleared'}>{this.props.gaZonesCleared ? this.props.gaZonesCleared.toLocaleString() : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {(!!this.props.gacSkillRating || (!this.props.compareUnits && this.props.compare)) && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faChartArea}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Avg ' : ''}GA Skill Rating </span>
                                <span className={'titleStrong'}>[ <strong data-compare={'gacSkillRating'}>{this.props.gacSkillRating ? this.props.gacSkillRating.toLocaleString() : 0}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.guildRank && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>{this.props.averages ? 'Officers count' : 'Guild Rank'} </span>
                                <span className={'titleStrong'}>[ <strong data-compare={this.props.averages && 'officers'}>{this.props.guildRank.toLocaleString()}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.lastActivity !== undefined && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faHistory} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Last Activity </span>
                                <span className={'titleStrong'}>[ <strong>{this.props.lastActivity}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.description && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Description: </span>
                                <span className={'titleStrong'}> <strong>{this.props.description}</strong> </span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.assignedMembers && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faUserAlien} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Assigned Members </span>
                                <span className={'titleStrong'}>[ <strong>{this.props.assignedMembers}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.assignedSquads && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faUsersClass} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Assigned Squads </span>
                                <span className={'titleStrong'}>[ <strong>{this.props.assignedSquads}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.unassignedCharacters && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={this.props.isTW ? faUsersClass : faUserAlien} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Unassigned {this.props.isTW ? 'Squads' : 'Units'} </span>
                                <span className={`titleStrong ${styles.danger}`}>[ <strong>{this.props.unassignedCharacters}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.duplicatedUnitsPerPlayer && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faSwordsLaser} style={{ color: "red" }} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Duplicated Units</span>
                                <span className={`titleStrong ${styles.danger}`}>[ <strong>{this.props.duplicatedUnitsPerPlayer}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.platoonType && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={this.props.platoonType.toLowerCase() === 'ship' ? faStarfighter : faPersonSign} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Platoon type </span>
                                <span className={'titleStrong'}>[ <strong>{this.props.platoonType}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.platoonZone && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faUserPlus} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Platoon zone </span>
                                <span className={'titleStrong'}>[ <strong>{this.props.platoonZone}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.platoonFilters && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faClipboardPrescription} />
                            <p className={styles.filters}>
                                {this.props.platoonFilters.gp && this.props.platoonFilters.gp > 0 ? <span className={styles.filter}>GP: {this.props.platoonFilters.gp}+</span> : null}
                                {this.props.platoonFilters.starLevel && this.props.platoonFilters.starLevel > 0 && <span className={styles.filter}>Rarity: {this.props.platoonFilters.starLevel}{this.props.platoonFilters.starLevel < 7 ? '+' : ''}</span>}
                                {this.props.platoonFilters.gearLevel && this.props.platoonFilters.gearLevel > 0 ? <span className={styles.filter}>Gear: {this.props.platoonFilters.gearLevel}{this.props.platoonFilters.gearLevel < 13 ? '+' : ''}</span> : null}
                                {(this.props.platoonFilters.relicLevel !== null && this.props.platoonFilters.relicLevel !== undefined && this.props.platoonFilters.gearLevel === 13) ? <span className={styles.filter}>Relic: {this.props.platoonFilters.relicLevel}{this.props.platoonFilters.relicLevel < 8 ? '+' : ''}</span> : null}
                                {this.props.platoonFilters.gp === undefined  && this.props.platoonFilters.starLevel === undefined && this.props.platoonFilters.gearLevel === undefined && this.props.platoonFilters.relicLevel === undefined && 'No filters selected'}
                            </p>
                        </div>
                    </Col>
                )}

                {this.props.availablePlatoonUnits && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faUniversalAccess} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Available unassigned / Assigned units </span>
                                <span className={'titleStrong'}>[ <strong>{this.props.availablePlatoonUnits}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.createdBy && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faUserPlus} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Created by </span>
                                <span className={'titleStrong'}>[ <strong>{this.props.createdBy}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.dateCreated && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={this.props.dateEdited ? faCalendarEdit : faCalendarPlus} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Time: </span>
                                <span className={'titleStrong'}><strong>{this.props.dateEdited ?? this.props.dateCreated}</strong></span>
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.lastEditedBy && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faUserEdit} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Edited by </span>
                                <span className={'titleStrong'}>[ <strong>{this.props.lastEditedBy}</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.nextPayoutUTC !== undefined && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faEnvelopeOpenDollar} />
                            <Text className={'titleRow'}>
                                <span className={'titleName'}>Arena Payout </span>
                                <span className={'titleStrong'}>[ <strong>{this.props.nextPayoutUTC} UTC</strong> ]</span>
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.avgGuildTicketsdays !== undefined && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faTicketAlt} />
                            <Text>
                                Today's Avg Guild Tickets [ <strong>{readableNumber(this.props.avgGuildTicketsdays)}</strong> ]
                            </Text>
                        </div>
                    </Col>
                )}

                {this.props.avgGuildTickets7days !== undefined && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faTicket} />
                            <Text>
                                7 Day's Avg Guild Tickets [ <strong>{readableNumber(this.props.avgGuildTickets7days)}</strong> ]
                            </Text>
                        </div>
                    </Col>
                )}

                {!!this.props.updated && (
                    <Col className={styles.item}>
                        <div className={styles.wrapper}>
                            <FontAwesomeIcon icon={faClock} />
                            <Text className={'titleRow'}>
                                <Tooltip title="Last time the data was updated">
                                    <span className={'titleName'}>Updated: </span>
                                    <span className={'titleStrong'}>[ <strong>{this.props.updated}</strong> ]</span>
                                </Tooltip>
                            </Text>
                        </div>
                    </Col>
                )}
            </Row>
        );
    }
}