import React from "react";
import {observer} from "mobx-react";
import IPageProps from "../../IPageProps";
import {action} from "mobx";
import {Modal} from "antd";
import styles from "../../../styles/components/Modal.module.scss";

interface IInstructionsModalModalProps extends IPageProps{
    showModal: boolean;
    onClose: () => void;
}

@observer
export class InstructionsModal extends React.PureComponent<IInstructionsModalModalProps> {

    @action private cancel() {
        return "";
    }

    render() {
        return (
            <Modal
                title={`How to register your guild members on HotUtils`}
                visible={this.props.showModal}
                onCancel={this.props.onClose}
                footer={null}
            >
                <div className={styles.modal}>
                    <div className={styles.row}>
                        <div className={styles.title}>In order to register your members to HotBot you need to:</div>
                        <div className={styles.txt}>
                            - Use the command  <span>register ALLYCODE @DISCORDTAG</span> with the bot to register them manually.
                            <br /><br />
                            Example: <em><strong>register 286433799 @svirve#2740</strong></em>
                        </div>

                    </div>
                </div>
            </Modal>
        );
    }
}