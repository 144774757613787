import styled from 'styled-components';
import { Button } from 'antd';

export const BlockButton = styled(Button)`

`;

export const GoogleButton = styled(BlockButton)`
    background-color: #DB4437 !important;
    border-color: #DB4437 !important;
    
    &:hover {
        background-color: #E36C62 !important;
        border-color: #DB4437 !important;
    }
`;
