import React from "react";
import { Spin } from "antd";
import { observer } from "mobx-react";
import IPageProps from "../IPageProps";
import BaseAPI from "../../service/BaseAPI";
import { observable, action } from "mobx";

@observer
class GoogleCallback extends React.Component<IPageProps, any> {
    @observable error: any = null;
    
    componentDidMount()
    {
        let params = new URLSearchParams(window.location.hash.substring(1))
        let accessToken = params.get("access_token") || undefined;
        let accessCode = "";
        if (window.location.search.length > 100)
        {
            params = new URLSearchParams(window.location.search.substring(1));
            accessCode = params.get("code") || "";
        }
        const rememberMe = localStorage.getItem("rememberMe") === "1";

        BaseAPI.googleLogin(this.props.user, this.props.gameData, accessCode, "/googlecallback", rememberMe ? this.props.cookies : undefined, accessToken)
            .then(() => this.props.history.push('/')).catch(action((error) => {
                this.error = error;
            }));
    }

    render()
    {
        if (this.error)
        {
            return <div>{this.error.errorMessage}</div>;
        }
        return (
            <>
                <div style={{
                    textAlign: 'center',
                    borderRadius: '4px',
                    marginBottom: '20px',
                    padding: '30px 50px',
                    margin: 'auto',
                }} >
                    <Spin />
                </div>
            </>
        );
    }
}

export default GoogleCallback;
