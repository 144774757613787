import React from "react";
import IPageProps from "../../IPageProps";
import {observer} from "mobx-react";
import {Alert, Button, Input, Radio, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/pro-duotone-svg-icons";
import {action, computed, observable, runInAction, toJS} from "mobx";
import {ITerritoryWarsArray, ITerritoryWarsData, squadsTW, zonesTW} from "../../../model/TWPlanning";
import {IGuildPlayer} from "../../../model/GuildData";
import styles from "./styles/Templates.module.scss";
import {allSquadCategories, IGuildSquadTemplate, IGuildSquadUnits} from "../../../model/Squads";
import layout from "../../../styles/layout/structure.module.scss";
import Column from "antd/lib/table/Column";
import GroupUnitAvatar from "../../../components/swgoh/Character/UnitAvatarGroup";
import table from "../../../styles/components/Table.module.scss";
import {sorting} from "../../../utils/sorting";
import {GuildSquadTemplate} from "../../Squads/GuildSquads/GuildSquadTemplate";
import {AddSquadModal} from "../../Squads/GuildSquads/Modal/AddSquadModal";
import UnitAvatarUndefined from "../../../components/swgoh/Character/UnitAvatarUndefined";
import {GuildPoolSquadTemplate} from "../../Squads/GuildSquads/GuildPoolSquadTemplate";
import {AddPoolSquadModal} from "../../Squads/GuildSquads/Modal/AddPoolSquadModal";

interface ITWAssignSquadsProps extends IPageProps {
    template: ITerritoryWarsData;
    category: typeof ITerritoryWarsArray;
    guildSquadTemplates: IGuildSquadTemplate[];
    updateSquadTemplate: (templateId: number, name: string, category: string[], units: IGuildSquadUnits[], poolUnits: string[] | undefined, combatType: number, datacronAffixes: string[], datacronRequired: boolean) => void;
    members: IGuildPlayer[];
    guildName: string;
    saveAssignedSquads: (squadsTW: squadsTW[]) => void;
    hideHeader: (showHeader: boolean) => void;
    isAdmin: boolean;
    zoneTW: zonesTW;
    squadsTW: squadsTW[];
}

@observer
export default class TWAssignSquads extends React.PureComponent<ITWAssignSquadsProps> {
    @observable unassignedCharacters: number = 0;
    @observable guildSquadTemplate: IGuildSquadTemplate | undefined = undefined;
    @observable selectedFilter: string = this.props.zoneTW.combatType;
    @observable filterCards: string = 'TW - Defense';
    @observable private filter = "";
    @observable showModal: boolean = false;
    @observable showPoolSquadModal: boolean = false;

    constructor(props: ITWAssignSquadsProps) {
        super(props);

        this.filterSquads = this.filterSquads.bind(this);
    }

    @computed
    private get charsInUse(): Set<string> {
        let ret = new Set<string>();
        for (let z of this.props.template.squads) {
                z.units.forEach((c) => ret.add(c.characterId));
        }
        return ret;
    }

    @action.bound
    handleFilter(event: React.ChangeEvent<HTMLInputElement>) {
        this.filter = event.target.value;
    }

    filterSquads(record: IGuildSquadTemplate) {
        if(record){
            return record.name.toLowerCase().includes(this.filter.toLowerCase());
        }
    }

    private getContentFiltered(type: string) {
        if (!this.props.guildSquadTemplates) {
            return false;
        }

        const squadType = this.props.zoneTW.combatType === "Character" ? 1 : 2;
        const squadSize = this.props.zoneTW.combatType === "Character" ? 5 : 8;

        let categories: string[] = [];


        const filteredGuildSquads = this.props.guildSquadTemplates.filter(x => {
            if(x.combatType === 0){
                let isCombatTypeOne = true;

               for (let u of x.units) {
                    const unitData = this.props.gameData.getUnit(u.characterId);

                    if (unitData?.combatType !== squadType) {
                        return isCombatTypeOne = false;
                    }
                }
               return isCombatTypeOne && x.units.length <= squadSize
            } else {
                return x.combatType === squadType && x.units.length <= squadSize
            }
        });

        for (let i = 0; i < filteredGuildSquads.length; i++) {
            const template = filteredGuildSquads[i];

            template.category.map(x => {
                return categories.push(x);
            });
        }

        let uniqueCategories = [...new Set(categories)];
        return uniqueCategories.includes(type) || type === 'All';
    }

    private showGuildSquads() {
        const squadType = this.props.zoneTW.combatType === "Character" ? 1 : 2;
        const squadSize = this.props.zoneTW.combatType === "Character" ? 5 : 8;

        let squads = this.props.guildSquadTemplates.filter(x => {

            if(x.combatType === 0){
                let isCombatTypeOne = true;

                for (let u of x.units) {
                    const unitData = this.props.gameData.getUnit(u.characterId);

                    if (unitData?.combatType !== squadType) {
                        return isCombatTypeOne = false;
                    }
                }

                return isCombatTypeOne && x.units.length <= squadSize && x.createdBy.guildName === this.props.user.currentPlayer!.guildName;

            } else {
               return  x.combatType === squadType && x.units.length <= squadSize && x.createdBy.guildName === this.props.user.currentPlayer!.guildName
            }

        });

        if (this.filterCards === 'All') {
            return squads.filter(this.filterSquads);
        } else {
            return squads.filter(x => x.category.includes(this.filterCards)).filter(this.filterSquads);
        }
    }

    private renderAddSquadModal(viewedTemplate: IGuildSquadTemplate) {
        if (!this.showModal && !this.showPoolSquadModal) {
            return null;
        }

        if(this.showPoolSquadModal) {
            return <AddPoolSquadModal
                {...this.props}
                showModal={this.showPoolSquadModal}
                onClose={() => runInAction(() => {
                    this.showPoolSquadModal = false;
                })}
                onSave={(title, category, selectedCharacters, poolCharactersId, combatType, isDuplicate, datacronAffixes, datacronRequired) => {
                    runInAction(() => {
                        this.showPoolSquadModal = false;
                        this.guildSquadTemplate = {
                            ...viewedTemplate,
                            name: title,
                            category: category,
                            units: selectedCharacters,
                            poolUnits: poolCharactersId,
                            combatType: combatType
                        }
                    })
                    this.props.updateSquadTemplate(viewedTemplate!.id, title, category, selectedCharacters, poolCharactersId, combatType, datacronAffixes, datacronRequired);
                }}
                onDelete={undefined}
                template={viewedTemplate}
            />
        }

        return (
            <AddSquadModal
                {...this.props}
                showModal={this.showModal}
                onClose={() => runInAction(() => {
                    this.showModal = false;
                })}
                onSave={(name, category, selectedCharacters, combatType, duplicate, datacronAffixes, datacronRequired) => {
                    runInAction(() => {
                        this.showModal = false;
                        this.guildSquadTemplate = {
                            ...viewedTemplate,
                            name: name,
                            category: category,
                            units: selectedCharacters,
                            combatType: combatType
                        }
                    })
                    this.props.updateSquadTemplate(viewedTemplate!.id, name, category, selectedCharacters, undefined, combatType, datacronAffixes, datacronRequired);
                }}
                onDelete={undefined}
                template={viewedTemplate}
            />
        );
    }

    render() {
        if (this.props.guildSquadTemplates.length === 0) {
            return <div className={styles.row}>
                <Alert
                    message="Warning"
                    description="There are no valid squads in Guild Squads!"
                    type="warning"
                    showIcon
                    closable
                />
            </div>;
        }

        if (this.guildSquadTemplate) {
            const viewedTemplate = this.guildSquadTemplate;
            let usedPUThisZone = new Array<number>();
            let squadsUsedThisZone: squadsTW[] = [];

            for (let i = 0; i < this.props.squadsTW.length; i++) {
                const squadTW = this.props.squadsTW[i];

                if(this.props.zoneTW.id === squadTW.zoneId){
                    squadsUsedThisZone.push(squadTW);

                    if(squadTW.squadTypeId === viewedTemplate.id){
                        usedPUThisZone.push(squadTW.allyCode);
                    }
                }
            }
            usedPUThisZone = Array.from(new Set(usedPUThisZone));
            const squadsPerZoneAvailable = this.props.template.squadsPerZone - squadsUsedThisZone.length + usedPUThisZone.length;

            if (viewedTemplate) {
                if (viewedTemplate.type === 'pool') {
                    return (
                        <>
                            <GuildPoolSquadTemplate
                                {...this.props}
                                isAdmin={this.props.isAdmin}
                                template={toJS(viewedTemplate)}
                                onBack={() => runInAction(() => {
                                    runInAction(() => this.guildSquadTemplate = undefined);
                                    this.props.hideHeader(false);
                                })}
                                onEditSettings={() => runInAction(() => this.showPoolSquadModal = true)}
                                createdBy={viewedTemplate.createdBy.playerName}
                                editedBy={viewedTemplate.editedBy.playerName}
                                createdTime={viewedTemplate.createdBy.createdUTC}
                                editedTime={viewedTemplate.editedBy.createdUTC}
                                zoneTW={this.props.zoneTW}
                                squadsTW={toJS(this.props.squadsTW)}
                                saveAssignedSquads={squadsTW => {
                                    this.props.saveAssignedSquads(squadsTW);
                                    runInAction(() => this.guildSquadTemplate = undefined);
                                    this.props.hideHeader(false);
                                }}
                                selectedPlayersAllyCode={usedPUThisZone}
                                squadsPerZoneAvailable={squadsPerZoneAvailable}
                                squadsPerZone={this.props.template.squadsPerZone}
                                squadsUsedThisZone={toJS(squadsUsedThisZone)}
                                excludedPlayers={this.props.template.excludedPlayers}
                                preferredPlayers={this.props.template.preferredPlayers}
                                templateTW={this.props.template}
                            />
                            {this.renderAddSquadModal(viewedTemplate)}
                        </>
                    );
                }
                return (
                    <>
                        <GuildSquadTemplate
                            {...this.props}
                            isAdmin={this.props.isAdmin}
                            template={toJS(viewedTemplate)}
                            onBack={() => {
                                runInAction(() => this.guildSquadTemplate = undefined);
                                this.props.hideHeader(false);
                            }}
                            onEditSettings={() => runInAction(() => this.showModal = true)}
                            createdBy={viewedTemplate.createdBy.playerName}
                            editedBy={viewedTemplate.editedBy.playerName}
                            createdTime={viewedTemplate.createdBy.createdUTC}
                            editedTime={viewedTemplate.editedBy.createdUTC}
                            zoneTW={this.props.zoneTW}
                            squadsTW={toJS(this.props.squadsTW)}
                            saveAssignedSquads={squadsTW => {
                                this.props.saveAssignedSquads(squadsTW);
                                runInAction(() => this.guildSquadTemplate = undefined);
                                    this.props.hideHeader(false);
                            }}
                            selectedPlayersAllyCode={usedPUThisZone}
                            squadsPerZoneAvailable={squadsPerZoneAvailable}
                            squadsPerZone={this.props.template.squadsPerZone}
                            squadsUsedThisZone={toJS(squadsUsedThisZone)}
                            excludedPlayers={this.props.template.excludedPlayers}
                            preferredPlayers={this.props.template.preferredPlayers}
                            templateTW={this.props.template}
                        />
                        {this.renderAddSquadModal(viewedTemplate)}
                    </>
                );
            }
        }

        return (
            <div className={styles.body}>
                <h1 className={styles.title}>
                    Assign {this.props.zoneTW.combatType === "Character" ? 'Squads' : 'Fleets'} to Zone #{this.props.zoneTW.id}
                </h1>
                <div className={`${styles.container}`}>
                    <section className={layout.main}>
                        <div className={styles.filter}>
                            <Radio.Group
                                defaultValue={'TW - Defense'}
                                buttonStyle={"solid"}
                                optionType="button"
                            >
                                {allSquadCategories.map(i => <Radio.Button
                                    value={i}
                                    key={i}
                                    onClick={(e) => runInAction(() => this.filterCards = i)}
                                    disabled={!this.getContentFiltered(i)}
                                >
                                    {i}
                                </Radio.Button>)}
                            </Radio.Group>
                        </div>
                        <div className={styles.row}>
                            <Input
                                className={styles.search}
                                placeholder={`Search for a squad`}
                                allowClear
                                defaultValue={this.filter}
                                onChange={this.handleFilter}
                                autoFocus={true}
                            />
                        </div>
                        <div className={styles.squads}>
                            <Table
                                dataSource={this.showGuildSquads()}
                                style={{minWidth: '100%'}}
                                bordered={false}
                                size={'small'}
                                className={`${table.table} ${table['custom-minHeight']} ${table.guildSquad} table-squad`}
                                rowKey={record => record.id}
                                pagination={false}
                            >
                                <Column
                                    align={"center"}
                                    title="Title"
                                    dataIndex={"name"}
                                    defaultSortOrder={"ascend"}
                                    sorter={(a: IGuildSquadTemplate, b: IGuildSquadTemplate) => a.name.localeCompare(b.name)}
                                />
                                <Column
                                    align={"center"}
                                    title="Created"
                                    render={(record: IGuildSquadTemplate) => record.createdBy.playerName}
                                    sorter={(a: IGuildSquadTemplate, b: IGuildSquadTemplate) => a.createdBy.playerName.localeCompare(b.createdBy.playerName)}
                                />
                                <Column
                                    align={"center"}
                                    title="Category"
                                    render={(record: IGuildSquadTemplate) => record.category}
                                    sorter={(a: IGuildSquadTemplate, b: IGuildSquadTemplate) => a.category[0].localeCompare(b.category[0])}
                                />
                                <Column
                                    align={"center"}
                                    title="Type"
                                    render={(record: IGuildSquadTemplate) => {
                                        if(record.type){
                                            return record.type === "pool" ? 'Pool' : 'Substitution';
                                        } else {
                                            return "Substitution"
                                        }
                                    }}
                                    sorter={(a: IGuildSquadTemplate, b: IGuildSquadTemplate) => a.category[0].localeCompare(b.category[0])}
                                />
                                <Column
                                    align={"center"}
                                    title="Description"
                                    render={(record: IGuildSquadTemplate) => record.description}
                                    sorter={(a: IGuildSquadTemplate, b: IGuildSquadTemplate) => sorting(a.description, b.description)}
                                />
                                <Column
                                    align={"center"}
                                    title="Units"
                                    width={400}
                                    render={(record: IGuildSquadTemplate) => {
                                        return <div className={styles.units}>
                                        {record.units.map((squadUnit, i) => {
                                                const unitData = this.props.gameData.units?.find(x => x.baseId === squadUnit.characterId);

                                                if (squadUnit.poolUnit) {
                                                    return <UnitAvatarUndefined
                                                        name={"Pool Unit"}
                                                        baseImage={"blank-character"}
                                                        combatType={record.combatType}
                                                        alignment={3}
                                                        stars={7}
                                                        key={squadUnit.id}
                                                        gear={squadUnit.requirements?.filters.gear}
                                                        relic={squadUnit.requirements?.filters.relic}
                                                        showName={false}
                                                        className={styles.unit}
                                                    />
                                                }

                                                if (!unitData) {
                                                    return null;
                                                }

                                                const subs = squadUnit.requirements?.subs || [];
                                                const subsCharacters = subs.map(y => y.characterId);
                                                const subsUnitData = this.props.gameData.units?.filter(x => subsCharacters.includes(x.baseId)) ?? [];

                                                return (
                                                    <GroupUnitAvatar
                                                        key={squadUnit.id}
                                                        className={styles.unit}
                                                        unitData={unitData}
                                                        zeta={squadUnit.requirements?.hasZeta}
                                                        omi={squadUnit.requirements?.hasOmicron}
                                                        subsPriority={squadUnit.requirements?.subsPriority}
                                                        stars={7}
                                                        gear={squadUnit.requirements?.filters.gear}
                                                        relic={squadUnit.requirements?.filters.relic}
                                                        ultimate={unitData.galacticLegend === true}
                                                        subsUnit={subsUnitData}
                                                        subs={subs}
                                                        showName={false}
                                                    />
                                                );
                                            }
                                        )}
                                        </div>
                                    }}
                                />
                                <Column
                                    align={"center"}
                                    title="Pool units"
                                    width={350}
                                    render={(record: IGuildSquadTemplate) => {
                                        if(record.type === "pool") {
                                            return record.poolUnits && record.poolUnits.map((poolUnit, index) => {
                                                const poolUnitData = this.props.gameData.units?.find(x => x.baseId === poolUnit);
                                                if (!poolUnitData) {
                                                    return null;
                                                }
                                                return <GroupUnitAvatar
                                                    key={index}
                                                    className={styles.unit}
                                                    unitData={poolUnitData}
                                                    zeta={undefined}
                                                    omi={undefined}
                                                    subsPriority={undefined}
                                                    stars={7}
                                                    gear={1}
                                                    relic={0}
                                                    ultimate={false}
                                                    subsUnit={undefined}
                                                    subs={undefined}
                                                    showName={false}
                                                />
                                            });
                                        }

                                        return;
                                    }}
                                />
                                <Column
                                    align={"center"}
                                    title="View"
                                    render={(record: IGuildSquadTemplate) => {
                                        return <Button
                                            type={"primary"}
                                            className={`${styles.btn}`}
                                            title={'View this Squad template'}
                                            onClick={() => runInAction(() => {
                                                const template = this.props.guildSquadTemplates.find(x => x.id === record.id);

                                                this.guildSquadTemplate = template;
                                                this.props.hideHeader(true);
                                            })}
                                        >
                                            <FontAwesomeIcon icon={faEye} className={styles.icon}/> View
                                        </Button>
                                    }}
                                />
                            </Table>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
