
import { observable } from "mobx";

export class CompareUnit
{
    @observable discordDBId: number;
    @observable unitBaseId: string;
    @observable group: string;
    @observable groupOrder: number;
    @observable combatType: number;
    @observable alignment: number;
    @observable fleetCommander: boolean;
    @observable galacticLegend: boolean;
    @observable hasLeaderAbility: boolean;
    @observable name: string;
    @observable thumbnailName: string;
    @observable dbId: number;

    constructor(json: any)
    {
        this.discordDBId = json.discordDBId;
        this.unitBaseId = json.unitBaseId;
        this.group = json.group;
        this.groupOrder = json.groupOrder;
        this.combatType = json.combatType;
        this.alignment = json.alignment;
        this.fleetCommander = json.fleetCommander;
        this.galacticLegend = json.galacticLegend;
        this.hasLeaderAbility = json.hasLeaderAbility;
        this.name = json.name;
        this.thumbnailName = json.thumbnailName;
        this.dbId = json.dbId;
    }
}

class CompareGroup
{
    @observable id: string;
    @observable name: string;
    @observable unit: CompareUnit[] = [];

    constructor(json: any)
    {
        this.id = json.id;
        this.name = json.name;

        if (json.unit)
        {
            json.unit.forEach((unit: any) => this.unit.push(new CompareUnit(unit)));
        }
    }
}

export default CompareGroup;
