import { Modal, ModalFuncProps } from "antd";
import i18next from "i18next";
import React from "react";

function confirmationDialog(title: string, text: string | React.ReactNode, otherProps?: ModalFuncProps): Promise<boolean>
{
    return new Promise((resolve) => {
        let dlg = Modal.confirm({
            ...otherProps,
            okText: i18next.t("common:ui.confirmation_yes_button"), 
            cancelText: i18next.t("common:ui.confirmation_no_button"), 
            title: title, 
            content: <div>{text}</div>, 
            onCancel: () => {
                dlg.destroy();
                resolve(false);
            }, onOk: () => {
                dlg.destroy();
                resolve(true);
            }
        });
    });
}

export default confirmationDialog;
