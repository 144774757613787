import { observable } from "mobx";

export class TemplateTestOptions {
    @observable id: string;
    @observable name: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
    }
}
export class TemplateTestComparison {
    @observable isRange: boolean;
    @observable isAgainstUnit: boolean;
    @observable id: string;
    @observable name: string;

    constructor(json: any) {
        this.isRange = json.isRange;
        this.isAgainstUnit = json.isAgainstUnit;
        this.id = json.id;
        this.name = json.name;
    }
}

export class TemplateTestFilterDefinition {

    public static TRIANGLE_FILTER = "STAT_TRIANGLE";
    public static CROSS_FILTER = "STAT_CROSS";
    public static CIRCLE_FILTER = "STAT_CIRCLE";
    public static ARROW_FILTER = "STAT_ARROW";

    @observable id: string;
    @observable isZetaSelection: boolean;
    @observable comparison: TemplateTestComparison[] | null;

    @observable options: TemplateTestOptions[] | null;

    constructor(json: any) {
        this.id = json.id;
        this.isZetaSelection = json.isZetaSelection;
        if (json.comparison !== null) {
            this.comparison = [];
            json.comparison.forEach((c: any) => this.comparison!.push(new TemplateTestComparison(c)));
        } else {
            this.comparison = null;
        }
        if (json.options !== null) {
            this.options = [];
            json.options.forEach((o: any) => this.options!.push(new TemplateTestOptions(o)));
        } else {
            this.options = null;
        }
    }
}

export class TemplateTestFilter {
    @observable description: string;
    @observable requiresCharacter: boolean;
    @observable requiresShip: boolean;
    @observable requiresGalacticLegend: boolean;
    @observable requiresCapitalShip: boolean;
    @observable requiresZetas: boolean;
    @observable requiresZetaLead: boolean;
    @observable notForCapitalShip: boolean;
    @observable definitionId: string;
    @observable id: string;
    @observable name: string;

    @observable definition: TemplateTestFilterDefinition;

    constructor(json: any, definitions: TemplateTestFilterDefinition[]) {
        this.description = json.description;
        this.requiresCharacter = json.requiresCharacter;
        this.requiresShip = json.requiresShip;
        this.requiresGalacticLegend = json.requiresGalacticLegend;
        this.requiresCapitalShip = json.requiresCapitalShip;
        this.requiresZetas = json.requiresZetas;
        this.requiresZetaLead = json.requiresZetaLead;
        this.notForCapitalShip = json.notForCapitalShip;
        this.definitionId = json.definitionId;
        this.id = json.id;
        this.name = json.name;

        let d = definitions.find(d => d.id === this.definitionId);
        if (d === undefined) {
            throw new Error("Unable to find template test definition: " + this.definitionId);
        } else {
            this.definition = d;
        }
    }
}
