import { observable, runInAction, when } from "mobx";
import UserData from "../model/UserData";
import BaseAPI, { HTTPError } from "./BaseAPI";

export class APITask
{
    private static ERROR_TOLERANCE_COUNT = 3;

    private taskId: string;
    private pollTime: number;
    private timeoutId: number | null;
    private user: UserData;
    private getAllData: boolean;
    private errorCount: number = 0;
    private updateCallback: (response: any) => void;
    private doneCallback: (response: any) => void;
    @observable running: boolean = true;
    @observable responseMessage: string | null = null;
    @observable errorMessage: string | null = null;
    @observable responseCode: number = 1;
    @observable cancelled: boolean = false;
    @observable currProgress: number = 0;
    @observable maxProgress: number = 0;
    @observable elapsedTime: number = 0;
    @observable finalResponse: any = null;

    public get estimatedTimeLeftMs(): number
    {
        if (this.maxProgress === 0 || this.elapsedTime === 0)
            return 0;

        return (this.maxProgress - this.currProgress) * (this.elapsedTime / this.currProgress);
    }

    public get estimatedTimeLeftDisplayString(): string
    {
        let est = Math.trunc(this.estimatedTimeLeftMs / 1000);
        if (est < 120)
            return est.toLocaleString() + " seconds left";
        else
            return Math.trunc(est / 60).toLocaleString() + " minutes left";
    }

    public get cleanResponseMessage(): string
    {
        if (!this.responseMessage)
            return "";
        return this.responseMessage.replace(/(\(\d+%\) )?(.*)?( \[\d+\/\d+\]$)/, "$2");
    }

    constructor(taskId: string, user: UserData, getAllData: boolean, pollTime: number, updateCallback: (response: any) => void, doneCallback: (response: any) => void)
    {
        this.taskId = taskId;
        this.pollTime = pollTime;
        this.user = user;
        this.getAllData = getAllData;
        this.updateCallback = updateCallback;
        this.doneCallback = doneCallback;
        this.timeoutId = window.setTimeout(() => this.update(true), this.pollTime);
    }

    public restartPolling(): void
    {
        if (this.timeoutId)
        {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
        this.update(true);
    }

    public async update(schedulePoll?: boolean): Promise<any>
    {
        let response: any;
        try
        {
            response = await BaseAPI.postToApi("mods/task/get", {
                sessionId: this.user.sessionId,
                taskId: this.taskId,
                requestCancel: false,
                getAllData: this.getAllData
            });
            this.errorCount = 0;
        }
        catch (err: any)
        {
            if (!(err instanceof HTTPError))
            {
                // non http errors are fatal...
                this.errorCount = APITask.ERROR_TOLERANCE_COUNT + 999;
            }
            this.errorCount++;
            console.error("API task encountered error: " + this.errorCount + "/" + APITask.ERROR_TOLERANCE_COUNT, err);
            if (this.errorCount >= APITask.ERROR_TOLERANCE_COUNT)
            {
                console.error("Error tolerance reached, exiting API task");
                response = err;
                // and stop asking
                schedulePoll = false;
                if (this.timeoutId)
                {
                    clearTimeout(this.timeoutId);
                    this.timeoutId = null;
                }
            } else
            {
                console.error("Continuing polling until error tolerance reached");
                this.timeoutId = window.setTimeout(() => this.update(true), this.pollTime);
                // does not appear like return value is used, but can be used in update callback or done callback
                return response;
            }
        }

        runInAction(() =>
        {
            this.running = response.running;
            this.responseMessage = response.responseMessage;
            this.errorMessage = response.errorMessage;
            this.responseCode = response.responseCode;
            if (response.progress)
            {
                this.currProgress = response.progress.index;
                this.maxProgress = response.progress.count;
                this.elapsedTime = response.progress.elapsedMs;
            }
            if (!response.running)
                this.finalResponse = response;
        });

        if (response.running)
            this.updateCallback(response);
        else
            this.doneCallback(response);

        if (schedulePoll)
        {
            if (response.running)
                this.timeoutId = window.setTimeout(() => this.update(true), this.pollTime);
            else if (this.timeoutId)
            {
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
            }
        }

        return response;
    }

    public async cancel(): Promise<any>
    {
        if (this.timeoutId)
        {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
        let response = await BaseAPI.postToApi("mods/task/get", {
            sessionId: this.user.sessionId,
            taskId: this.taskId,
            requestCancel: true,
            getAllData: this.getAllData
        });

        runInAction(() =>
        {
            this.running = response.running;
            this.responseMessage = response.responseMessage;
            this.errorMessage = response.errorMessage;
            this.responseCode = response.responseCode;
            if (response.progress)
            {
                this.currProgress = response.progress.index;
                this.maxProgress = response.progress.count;
                this.elapsedTime = response.progress.elapsedMs;
            }
            this.cancelled = true;
            if (!response.running)
                this.finalResponse = response;
        });

        if (this.running)
        {
            let updateFunc = async () => 
            {
                response = await this.update();
                if (this.running)
                {
                    window.setTimeout(updateFunc, 200);
                }
            };
            window.setTimeout(updateFunc, 200);
            await when(() => this.running);
        }

        return response;
    }
}
