import {
    createGlobalStyle
} from 'styled-components';

interface ThemeType {
    readonly body: string;
    readonly text: string;
}

export const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`
  body {
    background-color: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text} !important;
    //transition: all 0.50s linear;
  }
  /** General Borders **/
  table,
  .table-squad,
  .ant-input-number-input,
  .ant-input-number,
  .ant-input-affix-wrapper,
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color: ${({theme}) => theme.border} !important;
  }
  .ant-card-bordered{
    border-color: ${({theme}) => theme.border};
  }
  .ant-table-content{
    overflow-x: auto;
    overflow-y: hidden;
  }
  .Table_table__3rF0w thead tr th{
    border-right-color: ${({theme}) => theme.border} !important;
  }
  .ant-modal-header,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-card-head{
    border-bottom-color: ${({theme}) => theme.border};
  }
  .ant-card-actions,
  .ant-modal-footer{
    border-top-color: ${({theme}) => theme.border};
  }
  .ant-dropdown-menu-item-divider, 
  .ant-dropdown-menu-submenu-title-divider{
    background-color: ${({theme}) => theme.border};
  }

  .antCard-header {
    .ant-card-head {
        background-color: ${({theme}) => theme.cardHeader};
    }
  }
  /** Header **/

  .ant-layout-header {
    background-color: ${({theme}) => theme.header};
  }
  
  /** General Typography **/
  h1.ant-typography, .ant-typography h1,
  h2.ant-typography, .ant-typography h2,
  h3.ant-typography, .ant-typography h3,
  h4.ant-typography, .ant-typography h4,
  h5.ant-typography, .ant-typography h5,
  .ant-divider, .ant-divider-horizontal.ant-divider-with-text,
  .ant-progress-text,
  .ant-checkbox-wrapper, .ant-tabs {
    color: ${({theme}) => theme.text};
  }

  .ant-typography,
  h1, h2, h3, h4, h5,
  .ant-form-item-label > label,
  .ant-list-item-action > li,
  .ant-form-item-explain, .ant-form-item-extra {
    color: ${({theme}) => theme.text};
  }
  
  /** Breadcrumbs **/
  .ant-breadcrumb.breadcrumb{
    background-color: ${({theme}) => theme.bgBreadcrumb};    
    
      .ant-breadcrumb-link .link {
        color: ${({theme}) => theme.primaryButtonColor};
      }
  }
  // .ant-breadcrumb span:last-child {
  //   color: ${({theme}) => theme.text};
  // }
  
  /** Menu **/
  .ant-menu-submenu-popup {
    > .ant-menu {
      background-color: ${({theme}) => theme.body};    
    }
  }
  
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left,
  .ant-menu-sub.ant-menu-inline {
    background-color: ${({theme}) => theme.body};
    border-right-color: ${({theme}) => theme.transparent};
    
    .ant-menu-item {
      color: ${({theme}) => theme.text};
      
      a {
        color: ${({theme}) => theme.text};
      }
    }
    
    .ant-menu-item-selected {
      background-color: ${({theme}) => theme.itemSelected} !important;
      a {
        color: ${({theme}) => theme.activeColor};
      }
    }
  }
  
  .ant-menu-submenu-title {
    color: ${({theme}) => theme.text};
    
    .ant-menu-submenu-arrow {
      color: ${({theme}) => theme.text};
    }
  }

  /** Card **/
  .ant-card {
    background-color: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};

    

    .ant-card-extra,
    .ant-card-head {
      color: ${({theme}) => theme.text};
    }
      .ant-card-actions{
        background-color: ${({theme}) => theme.body};

        & > li:not(:last-child){
          border-right-color: ${({theme}) => theme.border};
        }
        & > li .ant-btn{
          border-color: ${({theme}) => theme.transparent};
        }
      }

    .ant-card-meta-title {
      color: ${({theme}) => theme.text};
    }
  }
  
  .ant-card-active {
    background-color: ${({theme}) => theme.cardBackground} !important;
    border-color: ${({theme}) => theme.cardBorder} !important;
  }
  
  .ant-alert {
    //background-color: ${({theme}) => theme.alertBackground};
    border: ${({theme}) => theme.alertBorder };
    box-shadow: ${({theme}) => theme.alertShadow };
    
    .ant-alert-message {
      //color: ${({theme}) => theme.text};
    }
  }
  
  /** Modal **/
  .ant-modal {
    padding-bottom: 0;
  }
  
  .highlighted{
    background-color: ${({theme}) => theme.modalRowHighlighted};
  }
  
  .ant-modal, .ant-modal-header,
  .ant-modal-content {
    background-color: ${({theme}) => theme.modalBackground};
    color: ${({theme}) => theme.text};

    .ant-modal-confirm-body, 
    .ant-modal-confirm-content, 
    .ant-modal-title,
    .ant-modal-confirm-title {
      color: ${({theme}) => theme.text};
      
      .ant-alert {
        background-color: ${({theme}) => theme.alertBackground};
        border-color: ${({theme}) => theme.modalBackground};
      }
      
      .ant-alert-message {
        color: ${({theme}) => theme.text };
      }
    }
  }
  
  /** Radio Button **/
  .ant-radio-button-wrapper {
    color: ${({theme}) => theme.text };
    background-color: ${({theme}) => theme.body };
    border-color: ${({theme}) => theme.border };
    
    &:hover {
      color: ${({theme}) => theme.primaryButtonHover };
    }
    
    &.ant-radio-button-wrapper-disabled {
      background-color: ${ ({theme}) => theme.primaryButtonDisabledColor };
      border-color: ${ ({theme}) => theme.primaryButtonDisabledBorder };
      color: ${ ({theme}) => theme.primaryButtonDisabledText };
    }
  }

  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]) {
    &.ant-radio-button-wrapper:first-child,
    &.ant-radio-button-wrapper:last-child {
      background-color: ${({theme}) => theme.primaryButtonColor};
      
      &:hover {
        background-color: ${({theme}) => theme.primaryButtonHover };
      }
    }
  }
  
  /** Inputs **/
  input, textarea {
    background-color: ${({theme}) => theme.inputBackground };
    border-color: ${({theme}) => theme.inputBorder };
    color: ${({theme}) => theme.inputText };
  }
  
  .ant-input,
  .ant-input-affix-wrapper {
    background-color: ${({theme}) => theme.inputBackground };
    color: ${({theme}) => theme.inputText };

    > input.ant-input {
      color: ${({theme}) => theme.inputText };
    }
  }

  .ant-input-number {
    background-color: ${({theme}) => theme.inputBackground };
    color: ${({theme}) => theme.inputText };

    .ant-input-number-handler-wrap {
      background-color: ${({theme}) => theme.inputBackground };
      
      .anticon {
        color: ${({theme}) => theme.inputText };
      }
    }
  }

  .ant-input-group {
    color: ${({theme}) => theme.inputText };
  }

  /** Checkbox **/
  .ant-checkbox {
    color: ${({theme}) => theme.text };
    .ant-checkbox-inner {
      background-color: ${({theme}) => theme.checkboxBackground };
    }
  }
  .ant-checkbox-disabled {
      background-color: ${({theme}) => theme.checkboxDisableBackground };
    + span {
      color: ${({theme}) => theme.text };
    }
  }

  .ant-radio, .ant-radio-wrapper {
    color: ${({theme}) => theme.text };
  }
  
  /** Select **/
  .ant-select {
    background-color: ${({theme}) => theme.body };

    .ant-select-arrow {
      color: ${({theme}) => theme.text };
    }
  }

  .ant-select-multiple .ant-select-selection-item {
    background-color: ${({theme}) => theme.body };
  };
  
  /** Select Dropdown **/
  .ant-select-dropdown {
    background-color: ${({theme}) => theme.body };
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-item-option:not(.ant-select-item-option-disabled),
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: ${({theme}) => theme.text };
    background-color: ${({theme}) => theme.body };
    
    &:hover {
      background-color: ${({theme}) => theme.tableHover };
    }
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input[disabled] {
    background-color: ${ ({theme}) => theme.inputDisabled };
    border-color: ${ ({theme}) => theme.inputDisabledBorder };
    color: ${ ({theme}) => theme.inputDisabledText };
  }


  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${({theme}) => theme.itemSelected};
  }

  .ant-layout {
    background-color: ${({theme}) => theme.backdrop} !important;
    color: ${({theme}) => theme.text} !important;
  }

  .ant-layout-content {
    background-color: ${({theme}) => theme.body} !important;
    color: ${({theme}) => theme.text};
  }

  table {
    border-color: ${({theme}) => theme.paginatorBorder};
  }
  
  .ant-table {
    background-color: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};

    .ant-table-cell-fix-left, .ant-table-cell-fix-right,
    .ant-table-thead > tr > th {
      background-color: ${({theme}) => theme.body};
      color: ${({theme}) => theme.text};
    }

    .ant-table-tbody > tr.ant-table-row-selected > td{
        background-color: ${({theme}) => theme.tableHover};
    }
    .ant-table-body tr.ant-table-row:hover,
    .ant-table-tbody tr.ant-table-row:hover {
      > td {
        background-color: ${({theme}) => theme.tableHover};
      }
    }
    
    .ant-empty-description {
      color: ${({theme}) => theme.text};
    }

    td.ant-table-column-sort {
      background-color: ${({theme}) => theme.itemSelected};
      color: ${({theme}) => theme.text};
    }

    .ant-table-thead th.ant-table-column-has-sorters {
      
      &:hover {
        background-color: ${({theme}) => theme.tableHover };

        .ant-table-filter-trigger-container {
          background-color: ${({theme}) => theme.transparent };
        }
      }
    }

    tr.ant-table-expanded-row > td {
      background-color: ${({theme}) => theme.body };
    }

    .ant-table-row-expand-icon {
      background-color: ${({theme}) => theme.body };
    }
  }
  
  /** Buttons **/

  .ant-btn {
    color: ${({theme}) => theme.buttonText };
    background-color: ${({theme}) => theme.transparent};
    border-color: ${({theme}) => theme.buttonBorder};

    &:focus,
    &:active{
      background-color: ${({theme}) => theme.transparent};
    }
    &:hover {
      background-color: ${({theme}) => theme.transparent};
      color: ${({theme}) => theme.buttonHover };
    }
    
    &.ant-btn-primary:not([disabled]) {
      background-color: ${({theme}) => theme.primaryButtonColor };
      border-color: ${({theme}) => theme.primaryButtonColor };
      color: ${({theme}) => theme.primaryButtonText };

      &:hover {
        background-color: ${({theme}) => theme.primaryButtonHover};
        color: ${({theme}) => theme.primaryButtonText };
      }
    }
    
    &.ant-btn-primary[disabled] {
      background-color: ${({theme}) => theme.primaryButtonDisabledColor };
      border-color: ${({theme}) => theme.primaryButtonDisabledBorder };
      color: ${({theme}) => theme.primaryButtonDisabledText };
    }

    &.ant-btn-txt {
      color: ${({theme}) => theme.txtButtonText };
      background-color: ${({theme}) => theme.txtButtonColor };
      border-color: ${({theme}) => theme.txtButtonColor };

      &:hover {
        color: ${({theme}) => theme.txtButtonHover };
        background-color: ${({theme}) => theme.txtButtonColor };
        border-color: ${({theme}) => theme.txtButtonHover };
      }
    }

    &.ant-btn-primary:not([disabled]).ant-btn-dangerous{
      color: ${({theme}) => theme.dangerButtonDefaultText };
      background-color: ${({theme}) => theme.dangerButtonText };
      border-color: ${({theme}) => theme.dangerButtonColor };

      &:hover {
        color: ${({theme}) => theme.dangerButtonHoverText };
        background-color: ${({theme}) => theme.dangerButtonHover };
        border-color: ${({theme}) => theme.dangerButtonHover };
      }
    }
      
    &.ant-btn-dangerous.ant-btn-default {
      color: ${({theme}) => theme.dangerButtonText };
      background-color: ${({theme}) => theme.dangerButtonColor };
      border-color: ${({theme}) => theme.dangerButtonText };

      &:hover {
        color: ${({theme}) => theme.dangerButtonHoverText };
        background-color: ${({theme}) => theme.dangerButtonHover };
        border-color: ${({theme}) => theme.dangerButtonHover };
      }
    }
      
    &.ant-btn-dangerous {
      color: ${({theme}) => theme.dangerButtonText };
      background-color: ${({theme}) => theme.dangerButtonColor };
      border-color: ${({theme}) => theme.dangerButtonColor };

      &:hover {
        color: ${({theme}) => theme.dangerButtonHoverText };
        background-color: ${({theme}) => theme.dangerButtonHover };
        border-color: ${({theme}) => theme.dangerButtonHover };
      }
    }
      
    &.ant-btn-link {
      color: ${({theme}) => theme.primaryButtonText };

      &:hover {
        color: ${({theme}) => theme.primaryButtonColor };
      }
    }
    
    &.ant-btn[disabled] {
      background-color: ${({theme}) => theme.buttonDisableColor };
      border-color: ${({theme}) => theme.buttonDisableBorder };
      color: ${({theme}) => theme.buttonDisableText };
      
      > .anticon {
        color: ${({theme}) => theme.primaryButtonDisabledColor };
      }
    }
  }


  /** Pagination **/
  .ant-pagination {
    .ant-pagination-item-link,
    .ant-pagination-item {
      background-color: ${({theme}) => theme.paginatorColor};
      border-color: ${({theme}) => theme.paginatorBorder};
      color: ${({theme}) => theme.text};

      a {
        color: ${({theme}) => theme.text};
      }

      &.ant-pagination-item-active {
        border-color: ${({theme}) => theme.activeColor};

        a {
          color: ${({theme}) => theme.activeColor};
        }
      }
    }
    
    .ant-pagination-item-container .ant-pagination-item-ellipsis {
      color: ${({theme}) => theme.text};
    }
  }

  .ant-pagination-disabled {
    .ant-pagination-item-link {
      background-color: ${({theme}) => theme.paginatorColor};
      border-color: ${({theme}) => theme.paginatorBorder};
      color: ${({theme}) => theme.paginatorBorder};
    }
  }
  
  /** tree-list **/
  .ant-tree {
    .ant-tree-list {
      background-color: ${({theme}) => theme.body };
      color: ${({theme}) => theme.text };
    }

    .ant-tree-node-content-wrapper:hover {
      background-color: ${({theme}) => theme.tableHover };
    }
  }

  .ant-tree-show-line .ant-tree-switcher {
    background-color: ${({theme}) => theme.body };
  }
  
  /** icon color **/
  
  .anticon {
    color: ${({theme}) => theme.text};
  }
  
  /** toggle-switch **/

  .ant-switch {
    color: ${({theme}) => theme.text};
    background-color: ${({theme}) => theme.buttonToggle};

    &.ant-switch-checked {
      background-color: ${({theme}) => theme.primaryButtonColor}
    }
  }
  
  .ant-layout-sider-children {
    background-color: ${({theme}) => theme.body};
  }

  /** lists **/

  .ant-list {
    .ant-list-item,
    .ant-list-item-meta-title,
    .ant-list-item-meta-description,
    .ant-list-item-meta-content {
      color: ${({theme}) => theme.text};
    }
  }

  .ant-tag {
    color: ${({theme}) => theme.inputText };

    &.ant-tag-checkable-checked {
      background-color: ${({theme}) => theme.itemSelected};
      border-color: ${({theme}) => theme.activeColor};
      color: ${({theme}) => theme.activeColor };
    }
  }
  
  .ant-collapse {
    background-color: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};

    .panel-header {
      background-color: ${({theme}) => theme.cardBackground};
    }
    .ant-collapse-item {
      .ant-collapse-header {
        color: ${({theme}) => theme.text};
      }
      .ant-collapse-content {
        color: ${({theme}) => theme.text};
        background-color: ${({theme}) => theme.body};
      }
    }
  }

  .BasicLayout {
    background-color: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
  }
  
  .FloatingToolbar {
    background-color: ${({theme}) => theme.body};
  }
  
  // rolls //
  .roll-1 {
    color: ${({theme}) => theme.text} !important;
  }
  
  .roll-2 {
    color: ${({theme}) => theme.red100} !important;
  }
  
  .roll-3 {
    color: ${({theme}) => theme.red200} !important;
  }
  
  .roll-4 {
    color: ${({theme}) => theme.red300} !important;
  }
  
  .roll-5 {
    color: ${({theme}) => theme.red400} !important;
  }

  .mod-reroll-count {
    background-color: ${({theme}) => theme.body};
  }
  
  // table background? //
  .twDefeatedRow {
    background-color: ${({theme}) => theme.red400};
    &:hover > td {
      background-color: ${({theme}) => theme.red400} !important;
    }
  }
  
  .mod-selected {
    background-color: ${({theme}) => theme.modsSelected};
  }

  /** Breadcrumb **/
  .ant-breadcrumb-separator { 
    color: ${({theme}) => theme.text};
  }
  
  /** Dropdown Menu **/
  .ant-dropdown-menu {
    background-color: ${({theme}) => theme.body };

    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
      color: ${({theme}) => theme.text};
      
      a {
        color: ${({theme}) => theme.text};
      }
      
      &:hover {
        background-color: ${({theme}) => theme.tableHover };
      }
    }
  }

  /** Highlight Table Row **/

  .highlight-row {
    background-color: ${({theme}) => theme.highlightRow };
    
    .ant-table-cell-fix-left{
      background-color: ${({theme}) => theme.highlightRow };
    }
  }
  
  
  /** Playground **/
  .toolbarrow{
      background-color: ${({theme}) => theme.toolbarColor };
  }
  
  /** TB Planning **/
  .battle-zone-title {
      background-color: ${({theme}) => theme.titleBattleZones };
  }
  .tb-card-border {
      border: 1px solid ${({theme}) => theme.borderBattleZones };
  }
  .tb-card-bottom-border {
      border-bottom: 1px solid ${({theme}) => theme.borderBattleZones } !important;
  }
  .tb-card-left-border {
      border-left: 1px solid ${({theme}) => theme.borderBattleZones } !important;
  }
  
`;

