import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';
import UserData from './model/UserData';
import GameData from './model/GameData';

let user: UserData = new UserData();
let gameData: GameData = new GameData();

const render = (Component: any) => {
  return ReactDOM.render(
    <CookiesProvider>
        <Component user={user} gameData={gameData}/>
    </CookiesProvider>,
  document.getElementById('root')
)
};

render(App);

if ((module as any).hot) {
  (module as any).hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
