import React from "react";
import IPageProps from "../../../IPageProps";
import {observer} from "mobx-react";
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownLeftAndUpRightToCenter,
    faLayerPlus,
    faMapMarkerEdit, faPeopleArrowsLeftRight,
    faTrashAlt,
    faUpRightAndDownLeftFromCenter,
} from "@fortawesome/pro-duotone-svg-icons";
import {observable, runInAction} from "mobx";
import {ICombatType, IDuplicateUnits, ITerritoryWarsArray, ITerritoryWarsData, ITWZonesInGame, squadsTW, zonesTW} from "../../../../model/TWPlanning";
import {IGuildPlayer} from "../../../../model/GuildData";
import styles from "../styles/Templates.module.scss";
import TWCardRow from "./TWCardRow";
import {TWSquadZoneModal} from "../Modal/TWSquadZoneModal";
import TWCardCompactRow from "./TWCardCompactRow";

interface ITWZonesProps extends IPageProps {
    template: ITerritoryWarsData;
    category: typeof ITerritoryWarsArray;
    members: IGuildPlayer[];
    guildName: string;
    isAdmin: boolean;
    onDelete: (zoneId: number, squadId: string) => void;
    onSaveSquad: (squadTW: squadsTW) => void;
    onSaveSquads: (squadLeader: string, zoneId: number, newZoneId: number) => void;
    onMoveSquads: (zoneId: number, newZoneId: number, switchUnits: boolean, moveUnits: boolean) => void;
    assignSquads: (zone: zonesTW) => void;
    showZoneModal: () => void;
    showUnitNames: boolean;
    isCompactMode: boolean;
    showAllSquads: boolean;
    showRemainingSquads: boolean;
    zoneTW: (zone: zonesTW) => void;
    filledSquadsInTW: ITWZonesInGame[];
    sortOrder: number;
    duplicateUnits: IDuplicateUnits[];
    filterPlayers: string;
}


@observer
export default class TWZones extends React.PureComponent<ITWZonesProps> {
    @observable showModal: boolean = false;
    @observable squad: squadsTW | undefined = undefined;
    @observable zone: zonesTW | undefined = undefined;
    @observable isPlayerMode: boolean = false;
    @observable zonesHidden: number[] = [];
    @observable zoneId: number = 1;
    @observable squadLeader: string | undefined = undefined;
    @observable switchUnits: boolean = false;

    private filterPlayers(zone: zonesTW) {
        let playerSquadsZones : number[] = [];
        const playerSquads = this.props.template.squads.filter(x => x.playerName.toLowerCase().includes(this.props.filterPlayers.toLocaleLowerCase()));

        for(let s of playerSquads){
            playerSquadsZones.push(s.zoneId);
        }


        if(this.props.filterPlayers.length > 0){
            return !playerSquadsZones.includes(zone.id);
        }

        return false;
    }
    private renderSquadModal() {
        if (!this.showModal || (!this.squad && this.squadLeader === "") || !this.zone) {
            return null;
        }

        return (
            <TWSquadZoneModal
                {...this.props}
                showModal={this.showModal}
                onClose={() => runInAction(() => this.showModal = false)}
                zone={this.zone}
                zoneId = {this.zoneId}
                squadTW={this.squad}
                switchUnits={this.switchUnits}
                squadLeader={this.squadLeader}
                onSave={(squadTW, squadLeader, switchUnits, moveUnits, zoneId, newZoneId) => {
                    if(squadTW){
                        this.props.onSaveSquad(squadTW);
                    }
                    if(squadLeader){
                        this.props.onSaveSquads(squadLeader, zoneId, newZoneId);
                    }
                    if(switchUnits){
                        this.props.onMoveSquads(zoneId, newZoneId, switchUnits, moveUnits);
                    }
                    runInAction(() => {
                        this.showModal = false;
                        this.zoneId = newZoneId;
                    });
                }}
            />
        );
    }

    private showZones(zone: zonesTW) {
        if(!this.props.showAllSquads){
            return false;
        } else {
            let mySquadsZones : number[] = [];
            const mySquads = this.props.template.squads.filter(x => x.allyCode === Number(this.props.user.currentPlayer?.allyCode));

            for(let s of mySquads){
                mySquadsZones.push(s.zoneId);
            }

            return !mySquadsZones.includes(zone.id);
        }
    }

    render() {
        return (
            <div className={`${styles.twZones} ${this.props.showAllSquads ? styles.auto : ''}`}>
                {this.props.template.zones.slice().sort((a, b) => a.id - b.id).map(zone => {
                    let squads: squadsTW[] = [];

                    for (let i = 0; i < this.props.template.squads.length; i++) {
                        const squad = this.props.template.squads[i];

                        if (squad.zoneId === zone.id) {
                            squads.push(squad);
                        }
                    }

                    return (
                        <div className={`${styles['zone-card']} ${((this.showZones(zone) || this.filterPlayers(zone)) ? styles.hide  : '')}`} key={zone.id}>
                            <div className={`${styles['card-header']} tb-card-border`}>
                                <Button
                                    type={"text"}
                                    className={`${this.zonesHidden.includes(zone.id) ? styles.active : ''} ${styles.hideBtn}`}
                                    onClick={() => runInAction(() => {
                                        if(this.zonesHidden.includes(zone.id)){
                                            const index = this.zonesHidden.indexOf(zone.id);
                                            this.zonesHidden.splice(index, 1);
                                        } else {
                                            this.zonesHidden.push(zone.id);
                                        }
                                    })}
                                >
                                        <FontAwesomeIcon icon={!this.zonesHidden.includes(zone.id) ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} className={styles.icon}/>
                                </Button>
                                <div className={styles['zone-id']}>
                                    <Button
                                        type={"text"}
                                        onClick={() => {
                                            runInAction(() => {
                                                this.zone = zone;
                                                this.switchUnits = true;
                                                this.showModal = true;
                                            })
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPeopleArrowsLeftRight} className={styles.icon}/>
                                    </Button>
                                    {zone.name}
                                    <Button
                                        type={"text"}
                                        onClick={() => {
                                            this.props.zoneTW(zone);
                                            this.props.showZoneModal();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faMapMarkerEdit} className={styles.icon}/>
                                    </Button>
                                </div>
                                <div className={`${styles['zone-assigned']} ${squads.length === this.props.template.squadsPerZone ? styles.fullZone : ''} ${this.props.template.squadsPerZone < squads.length ? styles.excludedPlayer : ''}`}>
                                    [ {squads.length} / {this.props.template.squadsPerZone} ]
                                </div>
                                <div className={styles['zone-actions']}>
                                    {this.props.isAdmin && <Button
                                        title={`Assign Squads`}
                                        type={'default'}
                                        onClick={() => {
                                            this.props.assignSquads(zone);
                                        }}
                                        // disabled={squads.length > this.props.template.squadsPerZone}
                                    >
                                        <FontAwesomeIcon icon={faLayerPlus} className={styles.icon}/>
                                        Assign {zone.combatType === ICombatType.Character ? "Squads" : "Fleets"}
                                    </Button>}
                                    {this.props.isAdmin &&
                                    <Button
                                        style={{paddingRight: '10px', paddingLeft: '10px', marginLeft: '5px'}}
                                        type={'text'}
                                        danger={true}
                                        title={`Clear all`}
                                        disabled={squads.length === 0}
                                        onClick={() => {
                                            this.props.onDelete(zone.id, 'all');
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} className={styles.icon}/>
                                        Clear
                                    </Button>}
                                </div>
                            </div>
                            <div className={`${styles['card-body']} ${this.zonesHidden.includes(zone.id) ? styles.hide : ''} ${this.props.template.squadsPerZone < squads.length ? styles.excludedPlayer : ''}`}>
                                {!this.props.isCompactMode && <TWCardRow
                                    {...this.props}
                                    onDelete={squad => this.props.onDelete(squad.zoneId, squad.squadId)}
                                    zone={zone}
                                    showUnitNames={this.props.showUnitNames}
                                    showSquadModal={(squadTW) => runInAction(() => {
                                        this.squad = squadTW;
                                        this.zone = zone;
                                        this.switchUnits = false;
                                        this.showModal = true;
                                    })}
                                />}
                                {this.props.isCompactMode && <TWCardCompactRow
                                    {...this.props}
                                    zone={zone}
                                    showSquadModal={squadLeader => runInAction(() => {
                                        this.squadLeader = squadLeader;
                                        this.zone = zone;
                                        this.showModal = true;
                                    })}
                                />}
                            </div>
                        </div>
                    );
                })}

                {this.renderSquadModal()}
            </div>
        );
    }
}
