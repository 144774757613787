import React from "react";
import { observer } from "mobx-react";
import styles from "./../styles/Playground.module.scss";
import { Alert, InputNumber, Select, Switch as UiSwitch } from "antd";
import { ModAutomationSettings } from '../PlaygroundCalculator/ModAutomation';
import WikiHelp, { WIKI_PLAYGROUND_AUTOMATION_SETTINGS } from "../../../../components/WikiHelp/WikiHelp";
import GameData from "../../../../model/GameData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faSolidStar } from "@fortawesome/pro-solid-svg-icons";
import { Collapse } from 'antd';

const { Panel } = Collapse;

const { Option } = Select;

interface IProps
{
    gameData: GameData;
    modAutomationSettings: ModAutomationSettings;
    priorityOrder: string[];
    onClickSettingFavorite: (key: string, include: boolean) => void;
    settingsFavorites: string[];
    onUpdateModAutomationSettings: (mas: ModAutomationSettings) => void;
    showResetCachedSets?: boolean;
    onResetCachedSets: () => void;
}

interface IPlaygroundSettingProps
{
    favoriteKey: string;
    favoriteOptions: string[];
    children: JSX.Element;
    showAll: boolean
    onClickFavorite: (key: string, include: boolean) => void;
    className?: string;
}

const PlaygroundSetting = ({ className, children, favoriteOptions, favoriteKey, showAll, onClickFavorite }: IPlaygroundSettingProps) =>
{
    let selected = favoriteOptions.indexOf(favoriteKey) !== -1;

    if (selected || showAll)
    {
        return <div className={styles.paddedrow}>

            <FontAwesomeIcon className={styles.buttonicon} icon={selected ? faSolidStar : faStar} onClick={() => onClickFavorite(favoriteKey, selected === false)} />
            {children}
        </div>
    } else
    {
        return <span></span>;
    };
}


@observer
class AutomationSettingsWorkspace extends React.Component<IProps>
{

    setDontBreakSets(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.dontBreakSets = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setSimulateAsSix(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.simulateAsSix = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setFavorSpeedArrows(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.favorSpeedArrows = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }


    setUseExistingSet(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.useExistingSet = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setMinimizeSpeed(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.minimizeSpeed = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setAutoReorder(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.autoReorder = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setPromptSettings(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.promptSettings = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setPromptLocker(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.dontPromptLocker = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    updateMinSpeedSimulateSix(val: number | undefined | null)
    {
        if (val === undefined || val === null)
        {
            val = 0;
        }
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.simulateAsSixMinSpeed = val;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    updateFavorSpeedFactor(val: number | undefined | null)
    {
        if (val === undefined || val === null)
        {
            val = 0;
        }
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.favorWeight = val;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    updateSpeedSmoothingFactor(val: number | undefined | null)
    {
        if (val === undefined || val === null)
        {
            val = 0;
        }
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.speedSmoothingFactor = val;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    updateCompareAfterFound(val: number | undefined | null)
    {
        if (val === undefined || val === null)
        {
            val = 0;
        }
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.searchAfterFoundDepth = val;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    updatedMaxUnitReorder(val: number | undefined | null)
    {
        if (val === undefined || val === null)
        {
            val = 0;
        }
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.maxReorderPerUnit = val;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setUseDerivedRequirements(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.deriveRequirements = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setUncappedReorder(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.uncappedReorder = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }


    setExitAfterFailure(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.exitAfterFailure = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setIgnoreNonLockerMods(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.ignoreNonLockerMods = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setFillLockerUnit(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.fillLockerUnit = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setAutoFillLockerUnits(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.autoFillLockerUnits = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setUseBeta(value: boolean)
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.useBeta = value;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setAttemptDesiredTargets(checked: boolean): void
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.attemptDesiredTargets = checked;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setLimitAutomation(checked: boolean): void
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.limitAutomation = checked;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setReorderStopCharacter(unitBaseId: string): void
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.stopAtAutoReorder = unitBaseId;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    setDesiredAsRequired(unitBaseId: string | undefined): void
    {
        let newModAutomationSettings: ModAutomationSettings = new ModAutomationSettings(this.props.modAutomationSettings);
        newModAutomationSettings.desiredAsRequiredAboveUnit = unitBaseId;
        this.props.onUpdateModAutomationSettings(newModAutomationSettings);
    }

    public static PROMPT_EVERY_RUN = "PROMPT_EVERY_RUN";
    public static DONT_BREAK_SETS = "DONT_BREAK_SETS";
    public static LIMIT_SEARCHES = "LIMIT_SEARCHES";
    public static SIMULATE_MODS = "SIMULATE_MODS";
    public static SMOOTH_SPEED = "SMOOTH_SPEED";
    public static AUTO_REORDER = "AUTO_REORDER";
    public static DESIRED_AS_REQUIRED = "DESIRED_AS_REQUIRED";
    public static ATTEMPT_DESIRED = "ATTEMPT_DESIRED";
    public static USE_DERIVED = "USE_DERIVED";
    public static USE_BETA = "USE_BETA";
    public static USE_EXISTING_SET = "USE_EXISTING_SET";
    public static FAVOR_SPEED_ARROWS = "FAVOR_SPEED_ARROW";
    public static UNCAPPED_REORDER = "UNCAPPED_REORDER";
    public static EXIT_AFTER_FAILURE = "EXIT_AFTER_FAILURE";
    public static MAX_UNIT_REORDER = "MAX_UNIT_REORDER";

    public static IGNORE_NON_LOCKER = "IGNORE_NON_LOCKER";
    public static FILL_LOCKER_UNIT = "FILL_LOCKER_UNIT";
    public static AUTO_FILL_LOCKER = "AUTO_FILL_LOCKER";

    public static DONT_PROP_LOCKER = "DONT_PROP_LOCKER";

    renderOptions(showAll: boolean)
    {
        const unitList = this.props.gameData.units!.filter(u => u.combatType === 1).sort((a, b) => this.props.priorityOrder.indexOf(a.baseId) -
            this.props.priorityOrder.indexOf(b.baseId));

        const unitOptions = unitList.map(unit => <Option key={unit.baseId} value={unit.baseId}>{unit.name}</Option>);

        return <React.Fragment>



            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.PROMPT_EVERY_RUN} showAll={showAll}
                favoriteOptions={this.props.settingsFavorites} onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>
                    <UiSwitch defaultChecked={this.props.modAutomationSettings.promptSettings}
                        onChange={(checked) => this.setPromptSettings(checked)} /> Prompt setting every automation run
                </React.Fragment>
            </PlaygroundSetting>


            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.DONT_BREAK_SETS} showAll={showAll}
                favoriteOptions={this.props.settingsFavorites} onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>
                    <UiSwitch defaultChecked={this.props.modAutomationSettings.dontBreakSets}
                        onChange={(checked) => this.setDontBreakSets(checked)} /> Don't break mod sets
                </React.Fragment>
            </PlaygroundSetting>


            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.LIMIT_SEARCHES}
                showAll={showAll} favoriteOptions={this.props.settingsFavorites} onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>
                    <UiSwitch defaultChecked={this.props.modAutomationSettings.limitAutomation}
                        onChange={(checked) => this.setLimitAutomation(checked)} /> Limit searches with stat targets
                    <div>
                        (Note: disabling this can drastically increase automation time, try increasing min compares)
                    </div>
                </React.Fragment>
            </PlaygroundSetting>

            {this.props.modAutomationSettings.limitAutomation &&
                (showAll || this.props.settingsFavorites.indexOf(AutomationSettingsWorkspace.LIMIT_SEARCHES) !== -1) &&
                <React.Fragment>
                    <div className={styles.paddedrow}>
                        Minimum compares with match found:
                    </div>
                    <div className={styles.paddedrow}>
                        <InputNumber
                            onChange={(val: number | undefined | null) => this.updateCompareAfterFound(val)}
                            value={this.props.modAutomationSettings.searchAfterFoundDepth} />
                    </div>
                </React.Fragment>
            }
            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.USE_EXISTING_SET} showAll={showAll}
                favoriteOptions={this.props.settingsFavorites} onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>
                    <UiSwitch defaultChecked={this.props.modAutomationSettings.useExistingSet}
                        onChange={(checked) => this.setUseExistingSet(checked)} /> Try to use existing set if meets stat requirements (NOTE: WONT RE-OPTIMIZE!)
                </React.Fragment>
            </PlaygroundSetting>

            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.SIMULATE_MODS}
                showAll={showAll} favoriteOptions={this.props.settingsFavorites} onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>

                    <UiSwitch defaultChecked={this.props.modAutomationSettings.simulateAsSix}
                        onChange={(checked) => this.setSimulateAsSix(checked)} /> Simulate Mods as SixE

                </React.Fragment>
            </PlaygroundSetting>

            {this.props.modAutomationSettings.simulateAsSix && (showAll || this.props.settingsFavorites.indexOf(AutomationSettingsWorkspace.SIMULATE_MODS) !== -1) &&
                <React.Fragment>
                    <div className={styles.paddedrow}>
                        Simulate as SixE min speed:
                    </div>

                    <div className={styles.paddedrow}>
                        <InputNumber
                            onChange={(val: number | undefined | null) => this.updateMinSpeedSimulateSix(val)}
                            value={this.props.modAutomationSettings.simulateAsSixMinSpeed} />
                    </div>
                </React.Fragment>
            }

            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.FAVOR_SPEED_ARROWS} showAll={showAll} favoriteOptions={this.props.settingsFavorites}
                onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>

                    <UiSwitch defaultChecked={this.props.modAutomationSettings.favorSpeedArrows}
                        onChange={(checked) => this.setFavorSpeedArrows(checked)} /> Favor speed arrows

                </React.Fragment>
            </PlaygroundSetting>

            {this.props.modAutomationSettings.favorSpeedArrows && (showAll || this.props.settingsFavorites.indexOf(AutomationSettingsWorkspace.FAVOR_SPEED_ARROWS) !== -1) &&
                <React.Fragment>
                    <div className={styles.paddedrow}>
                        Favor speed factor (0-99):
                    </div>

                    <div className={styles.paddedrow}>
                        <InputNumber
                            min={0}
                            max={1000}
                            onChange={(val: number | undefined | null) => this.updateFavorSpeedFactor(val)}
                            value={this.props.modAutomationSettings.favorWeight} />
                    </div>
                </React.Fragment>
            }


            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.SMOOTH_SPEED} showAll={showAll} favoriteOptions={this.props.settingsFavorites}
                onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>

                    <UiSwitch defaultChecked={this.props.modAutomationSettings.minimizeSpeed}
                        onChange={(checked) => this.setMinimizeSpeed(checked)} /> Smooth Speed on required Targets

                </React.Fragment>
            </PlaygroundSetting>

            {this.props.modAutomationSettings.minimizeSpeed && (showAll || this.props.settingsFavorites.indexOf(AutomationSettingsWorkspace.SMOOTH_SPEED) !== -1) &&
                <React.Fragment>
                    <div className={styles.paddedrow}>
                        Speed smoothing factor:
                    </div>

                    <div className={styles.paddedrow}>
                        <InputNumber
                            onChange={(val: number | undefined | null) => this.updateSpeedSmoothingFactor(val)}
                            value={this.props.modAutomationSettings.speedSmoothingFactor} />
                    </div>
                </React.Fragment>
            }

            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.AUTO_REORDER}
                showAll={showAll} favoriteOptions={this.props.settingsFavorites} onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>

                    <UiSwitch defaultChecked={this.props.modAutomationSettings.autoReorder}
                        onChange={(checked) => this.setAutoReorder(checked)} /> Auto-reorder on failed target
                    <div>
                        (Note: leaving this enabled can increase automation time)
                    </div>
                </React.Fragment>
            </PlaygroundSetting>

            {this.props.modAutomationSettings.autoReorder && (showAll || this.props.settingsFavorites.indexOf(AutomationSettingsWorkspace.AUTO_REORDER) !== -1) &&
                <React.Fragment>
                    <div>Stop Auto-reordering at character</div>
                    <div className={styles.paddedrow}>
                        <Select placeholder="Select Character to stop autoreorder" className={styles.select}
                            showSearch
                            filterOption={(input, option) =>
                                option !== undefined && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={this.props.modAutomationSettings.stopAtAutoReorder} onChange={(value: string) => this.setReorderStopCharacter(value)} >
                            {unitOptions}
                        </Select>
                    </div>
                </React.Fragment>}

            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.DESIRED_AS_REQUIRED} showAll={showAll} favoriteOptions={this.props.settingsFavorites} onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>

                    <UiSwitch defaultChecked={this.props.modAutomationSettings.desiredAsRequiredAboveUnit !== undefined}
                        onChange={(checked) => checked ? this.setDesiredAsRequired(unitList[0].baseId) : this.setDesiredAsRequired(undefined)} /> Treat desired as required (including reordering)
                </React.Fragment>
            </PlaygroundSetting>

            {this.props.modAutomationSettings.desiredAsRequiredAboveUnit !== undefined &&
                (showAll || this.props.settingsFavorites.indexOf(AutomationSettingsWorkspace.DESIRED_AS_REQUIRED) !== -1) &&
                <React.Fragment>
                    <div>Treat desired as required up to:</div>
                    <div className={styles.paddedrow}>
                        <Select placeholder="Select Character to stop desired as required" className={styles.select}
                            showSearch
                            filterOption={(input, option) =>
                                option !== undefined && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={this.props.modAutomationSettings.desiredAsRequiredAboveUnit} onChange={(value: string) => this.setDesiredAsRequired(value)} >
                            {unitOptions}
                        </Select>
                    </div>
                </React.Fragment>}

            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.ATTEMPT_DESIRED} showAll={showAll} favoriteOptions={this.props.settingsFavorites}
                onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>

                    <UiSwitch defaultChecked={this.props.modAutomationSettings.attemptDesiredTargets}
                        onChange={(checked) => this.setAttemptDesiredTargets(checked)} /> Attempt desired targets for all units(but dont reorder)
                    <div>
                        (Note: this can increase automation time)
                    </div>
                </React.Fragment>
            </PlaygroundSetting>


            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.USE_DERIVED} showAll={showAll} favoriteOptions={this.props.settingsFavorites} onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>

                    <UiSwitch defaultChecked={this.props.modAutomationSettings.deriveRequirements}
                        onChange={(checked) => this.setUseDerivedRequirements(checked)} /> Use derived requirements
                </React.Fragment>
            </PlaygroundSetting>

            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.UNCAPPED_REORDER} showAll={showAll} favoriteOptions={this.props.settingsFavorites}
                onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>

                    <UiSwitch defaultChecked={this.props.modAutomationSettings.uncappedReorder}
                        onChange={(checked) => this.setUncappedReorder(checked)} /> Uncapped reorder
                </React.Fragment>
            </PlaygroundSetting>

            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.EXIT_AFTER_FAILURE} showAll={showAll} favoriteOptions={this.props.settingsFavorites}
                onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>

                    <UiSwitch defaultChecked={this.props.modAutomationSettings.exitAfterFailure}
                        onChange={(checked) => this.setExitAfterFailure(checked)} /> Exit after unit failure
                </React.Fragment>
            </PlaygroundSetting>

            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.MAX_UNIT_REORDER} showAll={showAll} favoriteOptions={this.props.settingsFavorites}
                onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>
                    Max Reorder Per Unit:

                    <div className={styles.paddedrow}>
                        <InputNumber
                            min={0}
                            onChange={(val: number | undefined | null) => this.updatedMaxUnitReorder(val)}
                            value={this.props.modAutomationSettings.maxReorderPerUnit} />
                    </div>
                </React.Fragment>
            </PlaygroundSetting>

            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.IGNORE_NON_LOCKER} showAll={showAll} favoriteOptions={this.props.settingsFavorites}
                onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>

                    <UiSwitch defaultChecked={this.props.modAutomationSettings.ignoreNonLockerMods}
                        onChange={(checked) => this.setIgnoreNonLockerMods(checked)} /> Only use locker \ unequipped mods on single automate
                </React.Fragment>
            </PlaygroundSetting>

            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.FILL_LOCKER_UNIT} showAll={showAll} favoriteOptions={this.props.settingsFavorites}
                onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>

                    <UiSwitch defaultChecked={this.props.modAutomationSettings.fillLockerUnit}
                        onChange={(checked) => this.setFillLockerUnit(checked)} /> Only fill locker units (minimize mod swapping)
                </React.Fragment>
            </PlaygroundSetting>


            <PlaygroundSetting favoriteKey={AutomationSettingsWorkspace.DONT_PROP_LOCKER} showAll={showAll}
                favoriteOptions={this.props.settingsFavorites} onClickFavorite={(key, include) => this.props.onClickSettingFavorite(key, include)} >
                <React.Fragment>
                    <UiSwitch defaultChecked={this.props.modAutomationSettings.dontPromptLocker}
                        onChange={(checked) => this.setPromptLocker(checked)} /> Don't prompt locker unit
                </React.Fragment>
            </PlaygroundSetting>

        </React.Fragment>;
    }

    render()
    {
        return <React.Fragment>
            <Alert message={<React.Fragment>
                <WikiHelp wikiId={WIKI_PLAYGROUND_AUTOMATION_SETTINGS} showHelpText={true} />
                Configure settings when automating assignment of mods
            </React.Fragment>} type="info" />

            <Collapse accordion defaultActiveKey={['1']} >
                <Panel header="Favorites" key="1">
                    {this.renderOptions(false)}
                </Panel>
                <Panel header="All Options" key="2">
                    {this.renderOptions(true)}
                </Panel>
            </Collapse>

        </React.Fragment>;
    }


}

export default AutomationSettingsWorkspace;
