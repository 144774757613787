import UnitData from "../model/UnitData";

export function searchRoles(unit: UnitData): string[] {
    let roles: string[] = [unit.baseId, unit.name];

    if(unit.role){
        for (let i = 0; i < unit.role.length; i++) {
            roles.push(unit.role[i].display);
        }
    }
    if(unit.profession){
        for (let j = 0; j < unit.profession.length; j++) {
            roles.push(unit.profession[j].display);
        }
    }
    if(unit.other){
        for (let i = 0; i < unit.other.length; i++) {
            roles.push(unit.other[i].display);
        }
    }
    if(unit.affiliation){
        for (let i = 0; i < unit.affiliation.length; i++) {
            const unitAffiliation = unit.affiliation[i].display.includes("affiliation") ? unit.affiliation[i].display.replace("affiliation_", "") : unit.affiliation[i].display;
            roles.push(unitAffiliation);
        }
    }
    if(unit.species){
        for (let i = 0; i < unit.species.length; i++) {
            const unitSpecies = unit.species[i].display.includes("species") ? unit.species[i].display.replace("species_", "") : unit.species[i].display;
            roles.push(unitSpecies);
        }
    }

    return roles;
}
