import React from "react";
import ModData from './../../../model/ModData';
import Mod, { MOD_WIDTH, MOD_HEIGHT } from './../Mod/index';
import ModSlots from './../../../model/ModSlots';
import styled from 'styled-components';
import 'mobx-react-lite/batchingForReactDom';
import ModSlotIcon from './../ModSlotIcon/ModSlotIcon';


export interface SlotStyle
{
  slot: ModSlots;
  border?: boolean;
  backgroundColor: string | null;
  borderColor: string | null;
}

interface IHorizontalModContainerProps
{
  children?: React.ReactNode;
  className?: string;
  width: number;
}

const HorizontalModContainerRenderer = ({ className, children }: IHorizontalModContainerProps) =>
{
  return <div className={className} >
    {children !== undefined ? children : null}
  </div>;
}

const HorizontalModContainer = styled(HorizontalModContainerRenderer)`
    width: ${props => props.width + "px"};
`;


interface IVerticalModContainerProps
{
  children?: React.ReactNode;
  className?: string;
  height: number;
}

const VerticalModContainerRenderer = ({ className, children }: IVerticalModContainerProps) =>
{
  return <div className={className} >
    {children !== undefined ? children : null}
  </div>;
}

const VerticalModContainer = styled(VerticalModContainerRenderer)`
    height: ${props => props.height + "px"};
`;




interface IModSlotProps
{
  slotStyle: SlotStyle | null;
  slot: ModSlots;
  mod: ModData | undefined;
  className?: string;
  horizontal?: boolean;
  onClickModSlot?: () => void;
}

const EmptyModSlot = styled(ModSlotIcon)`
  margin-top: 10px !important;
  width: 80px !important;
  height: 80px !important;
  background-size: 80px 80px !important;
`;


const ModSlotRenderer = ({ className, mod, slotStyle, slot, onClickModSlot }: IModSlotProps) =>
{
  return <div className={className} onClick={() => { if (onClickModSlot != null) onClickModSlot(); }}>
    {mod !== undefined ? <Mod mod={mod} onClick={() => { }} selected={false} viewMode={'Normal'} /> : <EmptyModSlot empty={true} slot={slot} />}
  </div>;
}

const ModSlot = styled(ModSlotRenderer)`
    display: flex;
    height: 100px;
    width: 240px;
    float:  ${props => props.horizontal === true ? "left" : null};
    border-style: ${props => props.slotStyle !== null && props.slotStyle.border ? "solid" : ""};
    border-color: ${props => props.slotStyle?.borderColor};
    background: ${props => props.slotStyle?.backgroundColor};
`;

const ModSetContainer = styled.div`
    display: flex;
`;

const LeftModsContainer = styled.div`
    flex-shrink: 1;
`;

const RightModsContainer = styled.div`
    flex-shrink: 1;
`;

const HorizontalModScrollContainer = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
`;

const VerticalModScrollContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
`;


export interface ModSetClickEvent
{
  slot: number;
  mod: ModData | null;
}

export enum ModSetViewTypeEnum
{
  Split = 1,
  Horizontal,
  Vertical
}

interface IProps
{
  children?: React.ReactNode;
  mods?: ModData[];
  modSetStyles?: SlotStyle[];
  viewType?: ModSetViewTypeEnum;
  onClickModSlot?: (event: ModSetClickEvent) => void;
}

const ModSet = ({ children, mods, onClickModSlot, viewType, modSetStyles }: IProps) =>
{
  let square: ModData | undefined = mods?.find(mod => mod.slot === ModSlots.Square);
  let diamond: ModData | undefined = mods?.find(mod => mod.slot === ModSlots.Diamond);
  let circle: ModData | undefined = mods?.find(mod => mod.slot === ModSlots.Circle);
  let arrow: ModData | undefined = mods?.find(mod => mod.slot === ModSlots.Arrow);
  let triangle: ModData | undefined = mods?.find(mod => mod.slot === ModSlots.Triangle);
  let cross: ModData | undefined = mods?.find(mod => mod.slot === ModSlots.Cross);


  let squareSlotStyle: SlotStyle | null = modSetStyles == null ? null : modSetStyles.find(style => style.slot === ModSlots.Square)!;
  let diamondSlotStyle: SlotStyle | null = modSetStyles == null ? null : modSetStyles.find(style => style.slot === ModSlots.Diamond)!;
  let circleSlotStyle: SlotStyle | null = modSetStyles == null ? null : modSetStyles.find(style => style.slot === ModSlots.Circle)!;
  let arrowSlotStyle: SlotStyle | null = modSetStyles == null ? null : modSetStyles.find(style => style.slot === ModSlots.Arrow)!;
  let triangleSlotStyle: SlotStyle | null = modSetStyles == null ? null : modSetStyles.find(style => style.slot === ModSlots.Triangle)!;
  let crossSlotStyle: SlotStyle | null = modSetStyles == null ? null : modSetStyles.find(style => style.slot === ModSlots.Cross)!;


  return (
    <React.Fragment>

      {viewType === ModSetViewTypeEnum.Horizontal
        ?
        <HorizontalModScrollContainer>
          <HorizontalModContainer width={MOD_WIDTH * 6}>
            <ModSlot horizontal={true} mod={square} slotStyle={squareSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Square, mod: square! }); }} slot={ModSlots.Square} />
            <ModSlot horizontal={true} mod={diamond} slotStyle={diamondSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Diamond, mod: diamond! }); }} slot={ModSlots.Diamond} />
            <ModSlot horizontal={true} mod={circle} slotStyle={circleSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Circle, mod: circle! }); }} slot={ModSlots.Circle} />
            <ModSlot horizontal={true} mod={arrow} slotStyle={arrowSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Arrow, mod: arrow! }); }} slot={ModSlots.Arrow} />
            <ModSlot horizontal={true} mod={triangle} slotStyle={triangleSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Triangle, mod: triangle! }); }} slot={ModSlots.Triangle} />
            <ModSlot horizontal={true} mod={cross} slotStyle={crossSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Cross, mod: cross! }); }} slot={ModSlots.Cross} />
          </HorizontalModContainer>
        </HorizontalModScrollContainer>
        :
        viewType === ModSetViewTypeEnum.Vertical ?
          <VerticalModScrollContainer>
            <VerticalModContainer height={MOD_HEIGHT * 6}>
              <ModSlot mod={square} slotStyle={squareSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Square, mod: square! }); }} slot={ModSlots.Square} />
              <ModSlot mod={diamond} slotStyle={diamondSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Diamond, mod: diamond! }); }} slot={ModSlots.Diamond} />
              <ModSlot mod={circle} slotStyle={circleSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Circle, mod: circle! }); }} slot={ModSlots.Circle} />
              <ModSlot mod={arrow} slotStyle={arrowSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Arrow, mod: arrow! }); }} slot={ModSlots.Arrow} />
              <ModSlot mod={triangle} slotStyle={triangleSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Triangle, mod: triangle! }); }} slot={ModSlots.Triangle} />
              <ModSlot mod={cross} slotStyle={crossSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Cross, mod: cross! }); }} slot={ModSlots.Cross} />
            </VerticalModContainer>
          </VerticalModScrollContainer>
          :
          <ModSetContainer>
            <LeftModsContainer>
              <ModSlot mod={square} slotStyle={squareSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Square, mod: square! }); }} slot={ModSlots.Square} />
              <ModSlot mod={diamond} slotStyle={diamondSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Diamond, mod: diamond! }); }} slot={ModSlots.Diamond} />
              <ModSlot mod={circle} slotStyle={circleSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Circle, mod: circle! }); }} slot={ModSlots.Circle} />
            </LeftModsContainer>
            <RightModsContainer>
              <ModSlot mod={arrow} slotStyle={arrowSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Arrow, mod: arrow! }); }} slot={ModSlots.Arrow} />
              <ModSlot mod={triangle} slotStyle={triangleSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Triangle, mod: triangle! }); }} slot={ModSlots.Triangle} />
              <ModSlot mod={cross} slotStyle={crossSlotStyle} onClickModSlot={() => { if (onClickModSlot != null) onClickModSlot({ slot: ModSlots.Cross, mod: cross! }); }} slot={ModSlots.Cross} />
            </RightModsContainer>
          </ModSetContainer>
      }

      {children !== undefined ? children : null}
    </React.Fragment>
  );
};

export default ModSet;