import React from "react";
import { Button, Form, Input, Layout, Modal, Spin } from "antd";
import { observer } from "mobx-react";
import IPageProps from "../IPageProps";
import BaseAPI from "../../service/BaseAPI";
import { observable, runInAction } from "mobx";
import styles from "./styles/EAConnect.module.scss";
import Title from "antd/lib/typography/Title";
import i18next from "i18next";

@observer
class EAConnect extends React.Component<IPageProps, any> 
{
    @observable error: any = null;
    @observable email: string = "";
    @observable currState: "Email" | "Code" = "Email";
    private auth: {authId: string, cookie: string[]} = {authId: "", cookie: []};

    componentDidMount()
    {
        
    }

    render()
    {
        return <Layout className={`${styles.container} {"site-layout"}`}>
                <div className={styles.top}>
                    <img src={"hotsauce-logo.png"} alt={"Hot Sauce Logo"} title={"Hot Sauce"} />
                    <Title level={3}>Enter EAConnect Information</Title>
                </div>
                <div className={styles.bottom}>
                    {this.currState === "Email" && this.renderEmail()}
                    {this.currState === "Code" && this.renderCode()}
                </div>
            </Layout>
    }

    private renderEmail(): React.ReactNode
    {
        return <Form name="email" 
                    onFinish={(values) => this.onEmailFinished(values)}>
                    
                    <Form.Item
                        name="email"
                        label="Email Address"
                        rules={[
                        {
                            type: 'email',
                            message: 'The input is not a valid email address',
                        },
                        {
                            required: true,
                            message: 'Please input the email address used for EAConnect',
                        },
                        ]}
                    >
                        <Input autoComplete="email" inputMode="email"/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                        Submit
                        </Button>
                    </Form.Item>
                </Form>
    }

    private async onEmailFinished(values: any): Promise<void>
    {
        runInAction(() => this.email = values.email);
        try
        {
            this.auth = await BaseAPI.eaConnectEmail(this.props.user, this.email, this.props.cookies);
        }
        catch(err: any)
        {
            Modal.error({ title: i18next.t("common:ui.error_dlg_title"), content: <span>{err.errorMessage}</span>, maskClosable: false });
            return;
        }
        
        runInAction(() => this.currState = "Code");
    }

    private renderCode(): React.ReactNode
    {
        return <Form name="code"
                    onFinish={(values) => this.onCodeFinished(values)}>
                    
                    <Form.Item
                        name="code"
                        label="Code"
                        rules={[{
                            required: true,
                            message: "Enter the code that was sent to " + this.email,
                            min: 4
                        },
                        ]}
                    >
                        <Input autoComplete="off" inputMode="numeric" />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                        Submit
                        </Button>
                    </Form.Item>
                </Form>
    }

    private async onCodeFinished(values: any): Promise<void>
    {
        let modal = Modal.info({ maskClosable: false, okButtonProps: { disabled: true, style: { display: 'none' } }, title: "Please wait, logging in", content: <Spin size="large" /> });
        try
        {
            await BaseAPI.eaConnectCode(this.props.user, this.props.gameData, this.email, values.code, this.auth.authId, this.auth.cookie, this.props.cookies);
        }
        catch(err: any)
        {
            modal.destroy();
            Modal.error({ title: i18next.t("common:ui.error_dlg_title"), content: <span>{err.errorMessage}</span>, maskClosable: false });
            return;
        }
        modal.destroy();
        this.props.history.push('/');
    }
}

export default EAConnect;
