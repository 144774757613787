import styled from 'styled-components';

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 4px 0;
  
  > * {
    margin: 4px 0;
  }
`;