import React from "react";
import IPageProps from "../../IPageProps";
import { observer, Observer } from "mobx-react";
import { Button, Modal, Row } from 'antd';
import { action, observable } from "mobx";
import { Radio } from 'antd';
import styles from "./styles/ModWizard.module.scss";
import { LoadoutDefinition } from "../../../model/LoadoutDefinition";
import { ReviewViewModeEnum } from "./ModWizard";
import UnitAvatar from "../../../components/swgoh/Character/UnitAvatar";
import { Divider } from "antd";
import { ModAutomationCalculatorOutput } from "../PlaygroundBeta/PlaygroundCalculator/ModAutomation";
import { AutoSizer, WindowScroller, List, ListRowProps } from "react-virtualized";
import PlayerData from "../../../model/PlayerData";
import { Tabs } from 'antd';
import ModSet from "../../../components/swgoh/ModSet";
import PlaygroundStats from "../PlaygroundBeta/PlaygroundComponents/PlaygroundStats";
import { convertToReadableStats, PlaygroundUnitSettings } from "../PlaygroundBeta/PlaygroundData";
import PlaygroundUnit from "../PlaygroundBeta/PlaygroundModUnitsWorkspace/PlaygroundUnit";
import { ModAssignmentConfiguration } from "../PlaygroundBeta/PlaygroundCalculator/ModAssignment";
import { Collapse } from 'antd';

const { Panel } = Collapse;

const { TabPane } = Tabs;

interface IModWizardAutomationResultsProps extends IPageProps
{
    player: PlayerData;
    loadoutDefinition: LoadoutDefinition;
    calculatorOutput: ModAutomationCalculatorOutput | null;
    tinyScreen: boolean;
    showStatDetails: boolean;
    reviewViewModeEnum: ReviewViewModeEnum;
    setReviewViewMode: (mode: ReviewViewModeEnum) => void;
}

export const WIZARD_RESULTS_UNIT_SUMMARY_HEIGHT = 400; // should match the height below...
export const WIZARD_RESULTS_UNIT_SUMMARY_HEIGHT_DETAILS = 485; // should match the height below...

@observer
class ModWizardAutomationResults extends React.Component<IModWizardAutomationResultsProps>
{
    public static VISIBLE_PADDING_WIDTH = 0; // number of pixels to pad for scroll bars on autoscroller
    public static CHARACTER_WIDTH = 164;
    public static CHARACTER_HEIGHT = 200;

    private windowScroller: any = null;
    private modListRef: any = null;

    @observable showUnitModal: boolean = false;
    @observable showUnitBaseId: string | undefined = undefined;

    private portraitRowRenderer(props: ListRowProps, unitList: string[], width: number): React.ReactNode
    {
        let index = props.index;
        let renderWidth = Math.max(width, ModWizardAutomationResults.CHARACTER_WIDTH);
        let unitsPerRow = Math.trunc(renderWidth / ModWizardAutomationResults.CHARACTER_WIDTH);

        return <div className={styles.unitsrow} style={props.style} key={props.key} >
            <Observer>
                {() =>
                {
                    const rowUnits = unitList.slice(index * unitsPerRow, index * unitsPerRow + unitsPerRow);

                    return <>{rowUnits.map((unitId) =>
                    {
                        let playerUnit = this.props.player.characters.get(unitId);
                        let gameUnit = this.props.gameData.units!.find(gu => gu.baseId === unitId)!;
                        let calculationResult = this.props.calculatorOutput!.units.find(cuo => cuo.unitBaseId === unitId)!;
                        let target = this.props.loadoutDefinition.targets.find(t => t.unitBaseId === unitId)!;

                        let style = styles.playerunit;

                        if (calculationResult.statCalculatorOutput.requirementsResult.passed === false)
                        {
                            style = styles.errorplayerunit;

                        } else if (calculationResult.statCalculatorOutput.desiredRequirementsResult.passed === false)
                        {
                            style = styles.warnplayerunit;
                        } else if (target !== undefined && target.hasStatTarget())
                        {
                            style = styles.passplayerunit;
                        }

                        return <UnitAvatar className={style}
                            playerUnit={playerUnit}
                            unitData={gameUnit}
                            size={"large"}
                            displayName={true}
                            onClick={() => this.onClickUnitPortrait(unitId)} />

                    })}</>;
                }}
            </Observer>
        </div>;
    }

    @action
    onClickUnitPortrait(unitId: string)
    {
        this.showUnitModal = true;
        this.showUnitBaseId = unitId;
    }

    getRowCount(width: number, unitList: string[])
    {
        return Math.ceil(unitList.length / ((width - ModWizardAutomationResults.VISIBLE_PADDING_WIDTH) / ModWizardAutomationResults.CHARACTER_WIDTH));
    }

    renderShowUnitModal()
    {
        if (this.showUnitModal === false)
        {
            return null;
        }
        let gameUnits = this.props.gameData.units!;
        let unitData = gameUnits.find(u => u.baseId === this.showUnitBaseId)!;
        let target = this.props.loadoutDefinition.targets.find(t => t.unitBaseId === this.showUnitBaseId)!;
        let playerCharacterData = this.props.player.characters.get(this.showUnitBaseId!)!;
        let pus = new PlaygroundUnitSettings(playerCharacterData, unitData);

        let calculationResult = this.props.calculatorOutput!.units.find(cuo => cuo.unitBaseId === this.showUnitBaseId)!;

        //  let us = this.props.unitSettings.get(this.unitData!.baseId)!;
        let sco = calculationResult === undefined ? null : calculationResult.statCalculatorOutput;
        let visibleStats = calculationResult === undefined || calculationResult.statCalculatorOutput === null ? undefined :
            calculationResult.statCalculatorOutput.equippedStats;
        let readableVisibleStats = visibleStats === undefined ? undefined : convertToReadableStats(visibleStats, playerCharacterData);

        //   let target = this.props.targets.get(this.unitData.baseId)!;
        let reportTargets = target === undefined || target.getEffectiveOptimizeTargets() === undefined ? undefined :
            target.getEffectiveOptimizeTargets()!.map((rt, index) =>
            {
                return <div key={index}>{rt.description(target!.unitBaseId, gameUnits)}</div>
            });

        let desiredTargets = target === undefined || target.getEffectiveReportTargets() === undefined ? undefined :
            target.getEffectiveReportTargets()!.map((rt, index) =>
            {
                return <div key={index}>{rt.description(target!.unitBaseId, gameUnits)}</div>
            });

        let automateMods = calculationResult.modIds == null ? [] : calculationResult.modIds!.map(modId =>
        {
            let mod = this.props.player.mods.find(mod => mod.id === modId)!;
            return new ModAssignmentConfiguration(mod, false);
        });
        let gameMods = playerCharacterData.mods.map(mod =>
        {
            return new ModAssignmentConfiguration(mod, false);
        });

        const modSetSyles = PlaygroundUnit.deriveSlotStyles(automateMods, gameMods, gameMods, sco);

        const failedRequirement = sco !== null && (sco.requirementsResult.passed === false || sco.desiredRequirementsResult.passed === false);
        const statsTabTitle = failedRequirement ? "Stats (!)" : "Stats";

        let modSetClassName = sco !== null && sco.requirementsResult.setRequirementsIndexFailed !== null ?
            styles.failedsetsummarycontainer : styles.summarycontainer;

        let editUnitTitle = <div>
            <span className={styles.editunitname}>
                {unitData.name}
            </span>
        </div>;

        return <Modal title={editUnitTitle} width={520} visible={this.showUnitModal}
            maskClosable={false}
            onCancel={action(() => this.showUnitModal = false)}
            cancelText="Close"
            okButtonProps={{ disabled: true, style: { display: 'none' } }}
            onOk={action(() => this.showUnitModal = false)}>
            <div >

                <React.Fragment>
                    <Row gutter={16}>

                        <Tabs defaultActiveKey="1" centered>
                            <TabPane tab="Mods" key="1">
                                <div className={styles.scrollable}>
                                    <div className={styles.editortabcontent}>


                                        <div className={modSetClassName}>
                                            <ModSet modSetStyles={modSetSyles}
                                                mods={automateMods.map(mas => mas.sliceTarget)} />
                                        </div>

                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tab={statsTabTitle} key="2">
                                <div className={styles.scrollable}>
                                    <div className={styles.editortabcontent}>
                                        <div className={styles.summarycontainer}>
                                            <PlaygroundStats
                                                hideTargetLink={true}
                                                baseStats={pus.baseStatsReadable}
                                                gearStats={pus.gearStatsReadable}
                                                originalStats={pus.inGameStats}
                                                gameUnits={this.props.gameData.units!}
                                                target={target}
                                                requirementsResult={sco === null ? undefined : sco.requirementsResult}
                                                desiredRequirementsResult={sco === null ? undefined : sco.desiredRequirementsResult}
                                                currentStats={readableVisibleStats} />
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tab="Targets" key="3">
                                <div className={styles.scrollable}>
                                    <div className={styles.editortabcontent}>
                                        {reportTargets !== undefined &&
                                            <React.Fragment>
                                                <div className={styles.paddedrow}>
                                                    Requirements:
                                                </div>
                                                <div className={styles.paddedrow}>
                                                    {reportTargets}
                                                </div>
                                            </React.Fragment>}


                                        {desiredTargets !== undefined &&
                                            <React.Fragment>
                                                <div className={styles.paddedrow}>
                                                    Desired:
                                                </div>
                                                <div className={styles.paddedrow}>
                                                    {desiredTargets}
                                                </div>
                                            </React.Fragment>}
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>

                    </Row>

                </React.Fragment>
            </div>
        </Modal>;
    }

    renderResultsPortrait(unitList: string[])
    {
        return <React.Fragment>
            <div className={styles.paddedrow}>
                Click unit to view results.
            </div>

            <WindowScroller ref={(r) => this.windowScroller = r} scrollElement={window}>
                {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                    <div style={{ display: "flex", flexGrow: 1, flexShrink: 1, flexBasis: "auto" }}>
                        <AutoSizer disableHeight>
                            {({ width }) => (
                                <div ref={registerChild}>
                                    <List
                                        ref={el =>
                                        {
                                            this.modListRef = el;
                                        }}
                                        autoHeight
                                        height={height}
                                        isScrolling={isScrolling}
                                        onScroll={onChildScroll}
                                        overscanRowCount={8}
                                        rowCount={this.getRowCount(width, unitList)}
                                        rowHeight={ModWizardAutomationResults.CHARACTER_HEIGHT}
                                        rowRenderer={(props) => this.portraitRowRenderer(props, unitList, width)}
                                        scrollTop={scrollTop}
                                        width={width}
                                    />
                                </div>
                            )}
                        </AutoSizer>
                    </div>)
                }
            </WindowScroller>
        </React.Fragment>;
    }

    private renderSummaryUnit(unitId: string)
    {

        let gameUnits = this.props.gameData.units!;
        let unitData = gameUnits.find(u => u.baseId === unitId)!;
        let target = this.props.loadoutDefinition.targets.find(t => t.unitBaseId === unitId)!;
        let playerCharacterData = this.props.player.characters.get(unitId!)!;

        const us = new PlaygroundUnitSettings(playerCharacterData, unitData);
        let playerUnit = us.playerCharacterData;

        let playerUnitClassName = styles.playerunit;

        let calculationResult = this.props.calculatorOutput!.units.find(cuo => cuo.unitBaseId === unitId)!;
        let sco = calculationResult === undefined ? null : calculationResult.statCalculatorOutput;
        let automateMods = calculationResult.modIds == null ? [] : calculationResult.modIds!.map(modId =>
        {
            let mod = this.props.player.mods.find(mod => mod.id === modId)!;
            return new ModAssignmentConfiguration(mod, false);
        });

        const modSetSyles = PlaygroundUnit.deriveSlotStyles(automateMods, us.gameMods, us.gameMods, sco);

        let visibleStats = sco === null ? undefined : sco.equippedStats;
        let readableVisibleStats = visibleStats === undefined ? undefined : convertToReadableStats(visibleStats, us.playerCharacterData);

        let modSetClassName = sco !== null && sco.requirementsResult.setRequirementsIndexFailed !== null ?
            styles.failedsetsummarycontainer : styles.summarycontainer;

        return <div>
            <div className={styles.unitsummarycontainer}>

                <div className={styles.portraitsummarycontainer}>
                    <div className={styles.clickable}>
                        <UnitAvatar className={playerUnitClassName}
                            playerUnit={playerUnit}
                            unitData={unitData}
                            size={"large"}
                            displayName={true}
                            onClick={() => null} />
                    </div>
                </div>

                <div className={modSetClassName} >
                    <div onClick={() => null}>
                        <ModSet modSetStyles={modSetSyles}
                            mods={automateMods.map(mas => mas.sliceTarget)} />
                    </div>
                </div>

                {this.props.showStatDetails &&
                    <div className={styles.summarycontainer}>
                        <PlaygroundStats
                            baseStats={us.baseStatsReadable}
                            originalStats={us.inGameStats}
                            gameUnits={this.props.gameData.units!}
                            gearStats={us.gearStatsReadable}
                            target={target}
                            requirementsResult={sco === null ? undefined : sco.requirementsResult}
                            desiredRequirementsResult={sco === null ? undefined : sco.desiredRequirementsResult}
                            currentStats={readableVisibleStats} />
                    </div>
                }
            </div>
            <Divider></Divider>
        </div>;
    }

    private summaryRowRenderer(props: ListRowProps, unitList: string[]): React.ReactNode
    {
        let index = props.index;

        let unit = unitList[index];

        return <div className={styles.unitrow} style={props.style} key={props.key}>
            <Observer>
                {() =>
                {
                    return this.renderSummaryUnit(unit);
                }}
            </Observer>
        </div>;
    }

    renderResultsSummary(unitList: string[])
    {
        return <React.Fragment>

            <WindowScroller ref={(r) => this.windowScroller = r} scrollElement={window}>
                {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                    <div style={{ display: "flex", flexGrow: 1, flexShrink: 1, flexBasis: "auto" }}>
                        <AutoSizer disableHeight>
                            {({ width }) => (
                                <div ref={registerChild}>
                                    <List
                                        ref={el =>
                                        {
                                            this.modListRef = el;
                                        }}
                                        autoHeight
                                        height={height}
                                        isScrolling={isScrolling}
                                        onScroll={onChildScroll}
                                        overscanRowCount={3}
                                        rowCount={unitList.length}
                                        rowHeight={this.props.showStatDetails ? WIZARD_RESULTS_UNIT_SUMMARY_HEIGHT_DETAILS : WIZARD_RESULTS_UNIT_SUMMARY_HEIGHT}
                                        rowRenderer={(props) => this.summaryRowRenderer(props, unitList)}
                                        scrollTop={scrollTop}
                                        width={width}
                                    />
                                </div>
                            )}
                        </AutoSizer>
                    </div>)
                }
            </WindowScroller>

        </React.Fragment>;
    }


    render()
    {
        let unitList = this.props.loadoutDefinition.targets.map(target => target.unitBaseId).filter(target =>
        {
            let output = this.props.calculatorOutput!.units.find(cuo => cuo.unitBaseId === target);
            return output !== undefined && output.statCalculatorOutput !== null && output.statCalculatorOutput !== null;
        });

        return <React.Fragment>
            {this.renderShowUnitModal()}

            {this.props.tinyScreen === false &&
                <div className={styles.paddedrow}>
                    <Radio.Group className={styles.toolbarbutton} value={"med"}>

                        <Button onClick={() => this.props.setReviewViewMode(ReviewViewModeEnum.Portrait)}
                            type={this.props.reviewViewModeEnum === ReviewViewModeEnum.Portrait ? "primary" : undefined} value={ReviewViewModeEnum.Portrait}>Basic</Button>
                        <Button onClick={() => this.props.setReviewViewMode(ReviewViewModeEnum.Summary)}
                            type={this.props.reviewViewModeEnum === ReviewViewModeEnum.Summary ? "primary" : undefined} value={ReviewViewModeEnum.Summary}>Advanced</Button>

                    </Radio.Group>
                </div>
            }

            {
                unitList.length > 15 ?
                    <Collapse >
                        <Panel header="View Results Summary" key="1">
                            {this.props.reviewViewModeEnum === ReviewViewModeEnum.Summary &&
                                this.renderResultsSummary(unitList)
                            }

                            {this.props.reviewViewModeEnum === ReviewViewModeEnum.Portrait &&
                                this.renderResultsPortrait(unitList)
                            }
                        </Panel>
                    </Collapse>
                    :
                    <React.Fragment>
                        {this.props.reviewViewModeEnum === ReviewViewModeEnum.Summary &&
                            this.renderResultsSummary(unitList)
                        }

                        {this.props.reviewViewModeEnum === ReviewViewModeEnum.Portrait &&
                            this.renderResultsPortrait(unitList)
                        }
                    </React.Fragment>
            }

        </React.Fragment>;
    }
}

export default ModWizardAutomationResults;
