import React from "react";
import {BasicLayout} from '../../../layouts/BasicLayout';
import IPageProps from "../../IPageProps";
import {observer} from "mobx-react";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import tbGameData from "../../../model/gen_tb.json";

import styles from "./styles/Command.module.scss";
import {action, observable, runInAction, toJS} from "mobx";
import TBPlayers from "../TBPlayers/tb-players";
import {
    Alert,
    Button,
    Card,
    Collapse,
    Dropdown,
    Input,
    Menu,
    message,
    Modal,
    Popconfirm,
    Select,
    Tabs, Typography
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookJournalWhills, faSave, faSyncAlt, faTrash} from "@fortawesome/pro-duotone-svg-icons";
import {TBController} from "../TBController";
import i18next from "i18next";
import {
    TBAllZones,
    TBReconZoneStatus, TBSavedMessages,
    TBZoneCommands,
    TZoneType
} from "../../../model/TerritoryBattleData";
import BaseAPI from "../../../service/BaseAPI";
import {faAngleDown, faArrowAltLeft} from "@fortawesome/pro-solid-svg-icons";
import uuid from "../../../utils/uuid";
import {TerritoryBattleDefinition} from "../../../model/TerritoryBattleGameData";
import moment from "moment";

const {TabPane} = Tabs;
const {Panel} = Collapse;
const {Title} = Typography;

const GUILD_TB_COMMAND_CENTER = "guildTbCommandCenter";
const GUILD_ALL_TB_COMMAND_CENTER = "guildAllTbCommandCenter";

export interface ICommandCenterProps extends IPageProps {
    tb?: string;
    back: () => void;
}

interface ISpecialText {
    id: string;
    text: string;
}

@observer
export default class CommandCenter extends React.Component<ICommandCenterProps> {
    @observable errorGuildFetch?: string = undefined;
    @observable tbAllZonesData: TBAllZones[] = [];
    @observable tbNewCommands: TBZoneCommands[] = [];
    @observable currentPhase: number = 1;
    @observable numberOfzones: number = 0;
    @observable isROETB: boolean = false;
    @observable locationsZones: TBReconZoneStatus[] = [];
    @observable locationsCM: TBReconZoneStatus[] = [];
    @observable locationsSM: TBReconZoneStatus[] = [];
    @observable locationsPlatoons: TBReconZoneStatus[] = [];
    @observable specialText: ISpecialText[] = [];
    @observable isAdmin: boolean = true;
    @observable reloading: boolean = false;
    @observable reloadingOrders: boolean = false;
    @observable savingAllOrders: boolean = false;
    @observable savedMessages: TBSavedMessages[] = [];
    private _retrySettings = 0;

    constructor(props: ICommandCenterProps) {
        super(props);

        this.renderTabPhase = this.renderTabPhase.bind(this);
    }

    componentDidMount() {
        if (!this.props.user.isAdminTB || !this.props.user.isAdmin) {
            runInAction(() => {
                this.isAdmin = false;
            })
        }
        if (this.props.user.isAdminTB || this.props.user.isAdmin) {
            this.fetchTBData(false);
        }
    }

    componentWillUnmount() {
        clearTimeout(this._retrySettings);
    }

    @action
    private onTbMessageSave(zoneId: string, phase: number, msg: string) {
        const allMessages = [...this.savedMessages];

        allMessages.push({
            id: uuid(),
            zoneId,
            phase,
            message: msg
        })

        this.savedMessages = allMessages;

        BaseAPI.setGuildSetting(this.props.user, GUILD_TB_COMMAND_CENTER, JSON.stringify(allMessages), true).then((response) => {
            const resp = response.responseMessage.toLowerCase();
            runInAction(() => this.reloadingOrders = false);
            if (response.errorMessage) {
                return message.error(resp);
            }

            return message.info(resp);
        }).catch((e) => message.error(e));
    }

    @action
    private onTbMessageDelete(id: string) {
        const allMsg = [...this.savedMessages];
        const msgIdx = allMsg.findIndex((x) => x.id === id);

        allMsg.splice(msgIdx, 1);

        this.savedMessages = allMsg;

        BaseAPI.setGuildSetting(this.props.user, GUILD_TB_COMMAND_CENTER, JSON.stringify(allMsg), true).then((response) => {
            const resp = response.responseMessage.toLowerCase();
            runInAction(() => this.reloadingOrders = false);
            if (response.errorMessage) {
                return message.error(resp);
            }

            return message.info(resp);
        }).catch((e) => message.error(e));
    }


    private async saveAllTBMessages(dataJS: TBZoneCommands[]) {
        runInAction(() => this.savingAllOrders = true);
        const tbAllZonesData = toJS(this.tbAllZonesData);
        const allMessages: TBAllZones[] = [];

        const dataByZoneType: { [key: string]: TBReconZoneStatus[] } = {};

        tbAllZonesData.forEach((zoneType) => {
            zoneType.data.forEach((zone) => {
                const dataJSExist = dataJS.find((x) => x.zoneId === zone.zoneId);

                let data: TBReconZoneStatus = {
                    phase: zone.phase,
                    location: zone.location,
                    commandMessage: zone.commandMessage,
                    commandState: zone.commandState,
                    zoneId: zone.zoneId,
                    specialText: zone.specialText,
                    zoneStatus: zone.zoneStatus
                };

                if (dataJSExist) {
                    data = {
                        ...data,
                        commandMessage: dataJSExist.message,
                        commandState: this.hotZoneCommandStr(dataJSExist.commandState),
                        specialText: dataJSExist.specialText,
                    }
                }

                // Store the data in an object based on the zoneType
                if (!dataByZoneType[zoneType.zoneType]) {
                    dataByZoneType[zoneType.zoneType] = [];
                }
                dataByZoneType[zoneType.zoneType].push(data);
            });
        });

        for (const zoneType in dataByZoneType) {
            allMessages.push({
                zoneType: zoneType as TZoneType,
                data: dataByZoneType[zoneType]
            });
        }

        await BaseAPI.setGuildSetting(this.props.user, GUILD_ALL_TB_COMMAND_CENTER, JSON.stringify(allMessages), true).then((response) => {
            const resp = response.responseMessage.toLowerCase();
            runInAction(() => this.savingAllOrders = false);
            if (response.errorMessage) {
                return message.error(resp);
            }

            return message.info(resp);
        }).catch((err) => {
            Modal.error({
                title: i18next.t("common:ui.error_dlg_title"),
                content: <span>{err.errorMessage}</span>,
                maskClosable: false
            });
        });
    }

    private async sendTBCommands(dataJS: TBZoneCommands[]) {
        runInAction(() => this.reloadingOrders = true);

        await BaseAPI.setTBCommands(this.props.user, dataJS).then((response) => {
            runInAction(() => this.reloadingOrders = false);
            if (response.errorMessage) {
                return message.error(response.errorMessage);
            }
            const resp = response.responseMessage.toLowerCase();

            return message.info(resp);
        }).catch((err) => {
            runInAction(() => this.reloadingOrders = false);

            Modal.error({
                title: i18next.t("common:ui.error_dlg_title"),
                content: <span>{err.errorMessage}</span>,
                maskClosable: false
            });
        });
    }

    private async fetchTBData(refresh: boolean) {
        let gameDataJson = await BaseAPI.fetchTbGameData(this.props.user, this.props.gameData);

        runInAction(() => {
            this.tbAllZonesData = [];
            this.reloading = true;
            this.numberOfzones = 0;
            this.props.gameData.tbDataFromJson(gameDataJson);
            this.props.gameData.tbData.addDefinition(tbGameData.t05D, gameDataJson.tables, this.props.gameData.units!);
        });
        try {
            const settings = await BaseAPI.getGuildSetting(this.props.user, GUILD_TB_COMMAND_CENTER);
            const allTBMessages = await BaseAPI.getGuildSetting(this.props.user, GUILD_ALL_TB_COMMAND_CENTER);
            let zoneStatuses = await TBController.getZonesStatus(this.props.user, refresh);
            const thisTBMsg: TBSavedMessages[] = [];

            const riseTbData: TerritoryBattleDefinition = this.props.gameData.tbData.definitions.find(d => d.id === "t05D")!;
            const tbmissionInfo = riseTbData.getTbMissionInfo();
            let data: ISpecialText[] = [];

            Array.from(tbmissionInfo.keys()).forEach(zoneId => {
                data.push({
                    id: tbmissionInfo.get(zoneId)!.zoneId,
                    text: tbmissionInfo.get(zoneId)!.getDescription(this.props.gameData.units ?? [])
                });

            });
            runInAction(() => this.specialText = data);

            if (allTBMessages && allTBMessages.length > 0 && !refresh) {
                const allMsg = JSON.parse(allTBMessages[0].value);

                zoneStatuses.forEach((zoneStatus) => {
                    const zoneMsg = allMsg.find((x: TBAllZones) => x.zoneType === zoneStatus.zoneType);

                    if (zoneMsg) {
                        zoneStatus.data.forEach((zone) => {
                            const zoneMsgData = zoneMsg.data.find((x: TBReconZoneStatus) => x.zoneId === zone.zoneId);
                            if (zoneMsgData) {
                                zone.commandMessage = zoneMsgData.commandMessage;
                                zone.commandMessage = zoneMsgData.commandMessage;
                                zone.commandState = zoneMsgData.commandState;
                                zone.specialText = zoneMsgData.specialText;
                            }
                        });
                    }
                });
            }

            if (settings && settings.length > 0) {
                const allMsg = JSON.parse(settings[0].value);
                const allMsgFiltered = allMsg.filter((x: TBSavedMessages) => x.message.length > 0);

                for (let i = 0; i < allMsgFiltered.length; i++) {
                    const msg = allMsgFiltered[i];
                    const allZonesMsg = zoneStatuses.map((x) => x.data);

                    for (let j = 0; j < allZonesMsg.length; j++) {
                        const zoneMsg = allZonesMsg[j];

                        if (zoneMsg.find((x) => x.zoneId === msg.zoneId)) {
                            thisTBMsg.push(msg);
                        }
                    }
                }
            }

            runInAction(() => {
                this.reloading = false;
                this.savedMessages = thisTBMsg;
                this.tbAllZonesData = zoneStatuses;
                const arrayPhases = zoneStatuses[0].data.map((x) => x.phase);
                if (zoneStatuses[0].data[0].zoneId.includes('tb3')) {
                    this.isROETB = true;
                }
                for (let i = 0; i < zoneStatuses.length; i++) {
                    const zone = zoneStatuses[i];

                    if (zone.zoneType === "zone") {
                        this.locationsZones = zone.data.filter(x => x.phase === this.currentPhase);
                    }
                    if (zone.zoneType === "platoon") {
                        this.locationsPlatoons = zone.data.filter(x => x.phase === this.currentPhase);

                    }
                    if (zone.zoneType === "special_mission") {
                        this.locationsSM = zone.data.filter(x => x.phase === this.currentPhase);
                    }
                    if (zone.zoneType === "combat_mission") {
                        this.locationsCM = zone.data.filter(x => x.phase === this.currentPhase);
                    }
                }

                this.numberOfzones = Math.max(...arrayPhases);
            });

        } catch (err: any) {
            Modal.error({
                title: i18next.t("common:ui.error_dlg_title"),
                content: <span>{err.errorMessage}</span>,
                maskClosable: false
            });
        }
    };

    private hotZoneCommand(type: string) {
        switch (type) {
            case 'Focused':
                return 'Focus';
            case 'Ignored':
                return 'Ignore';
            case 'Nocommand':
                return 'No command';
            case '':
            default:
                return 'None';
        }
    }

    private hotZoneCommandNo(type: string | number): number {
        if (!isNaN(type as number) && type !== '') {
            return type as number;
        }
        switch (type) {
            case 'Focused':
                return 2;
            case 'Ignored':
                return 3;
            case 'Nocommand':
                return 1;
            case '':
            default:
                return 0;
        }
    }

    private hotZoneCommandStr(type: number): string {
        switch (type) {
            case 2:
                return 'Focused';
            case 3:
                return 'Ignored';
            case 1:
                return 'Nocommand';
            case 0:
            default:
                return '';
        }
    }

    private locationTitle(no: number) {
        if (this.isROETB) {
            switch (no) {
                case 1:
                    return 'LS';
                case 2:
                    return 'DS';
                case 3:
                    return 'Neutral';
            }
        }
        switch (no) {
            case 1:
                return 'Ships';
            case 2:
                return 'Middle';
            case 3:
                return 'Bottom';
        }
    }

    private overlayMenu(data: TBReconZoneStatus) {
        return <Menu className={styles.dropMenu}>
            {this.savedMessages.map((s) => {
                return <Menu.Item title={s.message} key={s.id}>
                    <div className={styles.rowMenu}>
                        <Button type={"text"} onClick={() => {
                            const oldData = this.tbNewCommands.find(x => x.zoneId === data.zoneId);

                            if (oldData) {
                                const tbIdx = this.tbNewCommands.findIndex(x => x.zoneId === data.zoneId);

                                const newData: TBZoneCommands = {
                                    zoneId: data.zoneId,
                                    phase: data.phase,
                                    location: data.location,
                                    specialText: data.specialText,
                                    message: s.message,
                                    commandState: oldData.commandState
                                };

                                return runInAction(() => {
                                    this.tbNewCommands.splice(tbIdx, 1);
                                    this.tbNewCommands.push(newData);
                                });
                            }

                            const newData: TBZoneCommands = {
                                zoneId: data.zoneId,
                                phase: data.phase,
                                location: data.location,
                                specialText: data.specialText,
                                message: s.message,
                                commandState: this.hotZoneCommandNo(data.commandState) ?? 0
                            };
                            runInAction(() => {
                                this.tbNewCommands.push(newData);
                            });
                        }}>
                            {s.message}
                        </Button>
                        <Button size={"small"} danger={true} onClick={(e) => {
                            e.stopPropagation();
                            this.onTbMessageDelete(s.id);
                        }}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </div>
                </Menu.Item>;
            })}
        </Menu>;
    }

    private renderRelicPhase(phase: number) {
        switch (phase) {
            case 1:
                return '(R5+)'
            case 2:
                return '(R6+)'
            case 3:
                return '(R7+)'
            case 4:
                return '(R8+)'
            case 5:
            default:
                return '(R9+)'
        }
    }

    private renderLocations(zoneType: TZoneType) {
        const zoneData = this.tbAllZonesData.find((z) => z.zoneType === zoneType);
        const allCards: JSX.Element[] = [];

        if (zoneData) {
            const zoneDataPerPhase = zoneData.data.filter((x) => x.phase === this.currentPhase);
            const arrayLocationsNo = zoneDataPerPhase.map((x) => x.location);
            const cardNo = [...arrayLocationsNo].length;

            for (let i = 0; i < cardNo; i++) {
                const data = zoneDataPerPhase[i];
                const newTBData = this.tbNewCommands.find((z) => z.zoneId === data.zoneId);

                if (data) {
                    const specialText = this.specialText.find((s) => s.id === data.zoneId);
                    const specialTitle = data.specialText !== null ? data.specialText : specialText?.text;
                    const dataCard = <div key={`DataCard-${i}`}
                                          className={`${this.isROETB ? styles.orderRoetb : styles.order} ${styles['order-' + arrayLocationsNo[i]]}`}>
                        <Card
                            title={`${this.locationTitle(arrayLocationsNo[i])} ${this.isROETB ? this.renderRelicPhase(data.phase) : ''} ${specialTitle !== undefined ? `- ${specialTitle}` : ''}`}
                            bordered={true}
                            className={"antCard-header"}>
                            <div className={styles.contentRow}>
                                <span className={styles.label}>
                                    Message:
                                </span>
                                <Input
                                    size={"middle"}
                                    maxLength={74}
                                    className={`${styles.select} ${styles['form-item']}`}
                                    value={newTBData !== undefined ? newTBData.message : data.commandMessage}
                                    addonBefore={
                                        <Button type={"text"} size={"middle"} className={styles.btnSave}
                                                disabled={ (newTBData?.message.length ?? data.commandMessage.length) < 1}
                                                onClick={() => {
                                                    this.onTbMessageSave(data.zoneId, data.phase, newTBData ? newTBData.message : data.commandMessage);
                                                }}>
                                            <FontAwesomeIcon icon={faSave}/>
                                        </Button>
                                    }
                                    addonAfter={
                                        <Dropdown
                                            overlay={this.overlayMenu(data)}
                                            trigger={['click']}
                                            arrow={false}
                                            placement={"bottomRight"}
                                            disabled={this.savedMessages.length === 0}
                                        >
                                            <FontAwesomeIcon icon={faAngleDown} className={styles.btnArrow}/>
                                        </Dropdown>
                                    }
                                    placeholder={data.commandMessage ?? 'Please insert a message'}
                                    autoFocus={true}
                                    onChange={(event) => {
                                        const oldData = this.tbNewCommands.find(x => x.zoneId === data.zoneId);
                                        if (oldData) {
                                            const tbIdx = this.tbNewCommands.findIndex(x => x.zoneId === data.zoneId);

                                            const newData: TBZoneCommands = {
                                                zoneId: data.zoneId,
                                                phase: data.phase,
                                                location: data.location,
                                                specialText: data.specialText,
                                                message: event.target.value,
                                                commandState: oldData.commandState
                                            };

                                            return runInAction(() => {
                                                this.tbNewCommands.splice(tbIdx, 1);
                                                this.tbNewCommands.push(newData);
                                            });
                                        }

                                        const newData: TBZoneCommands = {
                                            zoneId: data.zoneId,
                                            phase: data.phase,
                                            location: data.location,
                                            specialText: data.specialText,
                                            message: event.target.value,
                                            commandState: this.hotZoneCommandNo(data.commandState) ?? 0
                                        };
                                        runInAction(() => {
                                            this.tbNewCommands.push(newData);
                                        });
                                    }}
                                />
                            </div>
                            <div className={styles.contentRow}>
                                <span className={styles.label}>
                                    State:
                                </span>
                                <Select
                                    value={newTBData !== undefined ? newTBData.commandState : this.hotZoneCommand(data.commandState ?? '')}
                                    className={styles.select}
                                    onChange={(value) => {
                                        const savedData = this.tbNewCommands.find(x => x.zoneId === data.zoneId);

                                        if (savedData) {
                                            const tbIdx = this.tbNewCommands.findIndex(x => x.zoneId === data.zoneId);

                                            const newData: TBZoneCommands = {
                                                zoneId: data.zoneId,
                                                phase: data.phase,
                                                location: data.location,
                                                specialText: data.specialText,
                                                commandState: this.hotZoneCommandNo(value),
                                                message: savedData.message
                                            };

                                            return runInAction(() => {
                                                this.tbNewCommands.splice(tbIdx, 1);
                                                this.tbNewCommands.push(newData);
                                            });
                                        }
                                        const newData: TBZoneCommands = {
                                            zoneId: data.zoneId,
                                            phase: data.phase,
                                            location: data.location,
                                            specialText: data.specialText,
                                            message: data.commandMessage ?? '',
                                            commandState: this.hotZoneCommandNo(value)
                                        };

                                        return runInAction(() => {
                                            this.tbNewCommands.push(newData);
                                        });
                                    }}
                                    options={[
                                        {
                                            value: 0,
                                            label: 'None',
                                        },
                                        {
                                            value: 1,
                                            label: 'No command',
                                        },
                                        {
                                            value: 2,
                                            label: 'Focus',
                                        },
                                        {
                                            value: 3,
                                            label: 'Ignore',
                                        },
                                    ]}
                                />
                            </div>
                        </Card>
                    </div>;

                    allCards.push(dataCard);
                }
            }
        }

        return allCards;
    }

    private changeAllStates(zoneType: TZoneType) {
        return (
            <div className={styles.extraRow} onClick={event => event.stopPropagation()}>
                <span className={styles.label}>
                    Change all the state to:
                </span>
                <Select
                    defaultValue={'None'}
                    className={styles.select}
                    onChange={(value) => {
                        const allCards: TBZoneCommands[] = [];
                        const zoneData = this.tbAllZonesData.find((z) => z.zoneType === zoneType);

                        if (zoneData) {
                            const zoneDataPerPhase = zoneData.data.filter((x) => x.phase === this.currentPhase);

                            // console.log('Data: ', zoneDataPerPhase);

                            for (let i = 0; i < zoneDataPerPhase.length; i++) {
                                const data = zoneDataPerPhase[i];
                                const savedData = this.tbNewCommands.find(x => x.zoneId === data.zoneId);

                                if (savedData) {
                                    const tbIdx = this.tbNewCommands.findIndex(x => x.zoneId === data.zoneId);

                                    const newData: TBZoneCommands = {
                                        zoneId: data.zoneId,
                                        phase: data.phase,
                                        location: data.location,
                                        specialText: data.specialText,
                                        commandState: this.hotZoneCommandNo(value),
                                        message: savedData.message
                                    };

                                    allCards.push(newData);
                                    runInAction(() => {
                                        this.tbNewCommands.splice(tbIdx, 1);
                                    });
                                } else {
                                    const newData: TBZoneCommands = {
                                        zoneId: data.zoneId,
                                        phase: data.phase,
                                        location: data.location,
                                        specialText: data.specialText,
                                        message: data.commandMessage ?? '',
                                        commandState: this.hotZoneCommandNo(value)
                                    };

                                    allCards.push(newData);
                                }
                            }
                            return runInAction(() => {
                                this.tbNewCommands = this.tbNewCommands.concat(allCards);
                            });
                        }

                    }}
                    options={[
                        {
                            value: 0,
                            label: 'None',
                        },
                        {
                            value: 1,
                            label: 'No command',
                        },
                        {
                            value: 2,
                            label: 'Focus',
                        },
                        {
                            value: 3,
                            label: 'Ignore',
                        },
                    ]}
                />
            </div>
        );
    }

    private renderTabPhase() {
        const tabs: any[] = [];

        for (let i = 0; i < this.numberOfzones; i++) {
            const tab = <TabPane
                tab={
                    <span title={`Phase ${i + 1}`}>
                        <FontAwesomeIcon icon={faBookJournalWhills} className={styles.icon}/>
                        Phase {i + 1}
                    </span>
                }
                key={`${i + 1}`}
            >
                <div className={styles.tabContainer}>
                    {this.tbAllZonesData.find((z) => z.zoneType === 'zone')?.data.find(y => y.phase === this.currentPhase) && this.numberOfzones > 0 &&
						<Collapse defaultActiveKey={['1']} className={styles.panel}>
							<Panel header="Zone" key="1" className={"panel-header"}
								   extra={this.changeAllStates("zone")}>
								<div className={styles.gridRow}>
                                    {this.renderLocations("zone")}
								</div>
							</Panel>
						</Collapse>
                    }
                    {this.tbAllZonesData.find((z) => z.zoneType === 'platoon')?.data.find(y => y.phase === this.currentPhase) && this.numberOfzones > 0 &&
						<Collapse defaultActiveKey={undefined} className={styles.panel}>
							<Panel header="Platoons" key="2" className={"panel-header"}
								   extra={this.changeAllStates("platoon")}>
								<div className={styles.gridRow}>
                                    {this.renderLocations("platoon")}
								</div>
							</Panel>
						</Collapse>
                    }
                    {this.tbAllZonesData.find((z) => z.zoneType === 'combat_mission')?.data.find(y => y.phase === this.currentPhase) && this.numberOfzones > 0 &&
						<Collapse defaultActiveKey={undefined} className={styles.panel}>
							<Panel header="Combat Missions" key="3" className={"panel-header"}
								   extra={this.changeAllStates("combat_mission")}>
								<div className={styles.gridRow}>
                                    {this.renderLocations("combat_mission")}
								</div>
							</Panel>
						</Collapse>
                    }
                    {this.tbAllZonesData.find((z) => z.zoneType === 'special_mission')?.data.find(y => y.phase === this.currentPhase) && this.numberOfzones > 0 &&
						<Collapse defaultActiveKey={undefined} className={styles.panel}>
							<Panel header="Special Missions" key="4" className={"panel-header"}
								   extra={this.changeAllStates("special_mission")}>
								<div className={styles.gridRow}>
                                    {this.renderLocations("special_mission")}
								</div>
							</Panel>
						</Collapse>
                    }
                </div>
            </TabPane>;

            tabs.push(tab);
        }

        return tabs;
    }

    private pushCommands = (pushAllOrder: boolean) => {
        const dataJS = toJS(this.tbNewCommands);
        const oldSavedData = toJS(this.tbAllZonesData);
        const allData = [];

        if (pushAllOrder) {
            const newDataMap = new Map(); // Create a map for faster lookup
            for (const newData of dataJS) {
                const key = `${newData.zoneId}-${newData.phase}-${newData.location}`;
                newDataMap.set(key, newData);
            }
            for (const zoneData of oldSavedData) {
                for (const zone of zoneData.data) {
                    const key = `${zone.zoneId}-${zone.phase}-${zone.location}`;
                    const newData = newDataMap.get(key);

                    if (newData) {
                        allData.push(newData);
                    } else {
                        const data: TBZoneCommands = {
                            zoneId: zone.zoneId,
                            phase: zone.phase,
                            location: zone.location,
                            specialText: zone.specialText,
                            message: zone.commandMessage,
                            commandState: this.hotZoneCommandNo(zone.commandState)
                        };
                        allData.push(data);
                    }
                }
            }
        } else {
            allData.push(...dataJS);
        }

        return this.sendTBCommands(allData);
    };


    render() {
        if (this.errorGuildFetch !== undefined) {
            return (
                <BasicLayout {...this.props} requiredApiRights={[TBPlayers.PERMISSION_KEY]}>
                    <LoadingSpinner size={"large"} error={true} spinning={false} text={this.errorGuildFetch}/>
                </BasicLayout>
            );
        }
        if (!this.props.user.currentPlayer) {
            return <BasicLayout {...this.props} requiredApiRights={[TBPlayers.PERMISSION_KEY]}>
                <LoadingSpinner size={"large"} spinning={true} text={"Loading.."}/>
            </BasicLayout>;
        }
        let outOfDate = true;
        if (this.props.user.currentPlayer?.territoryBattleData?.currentRoundEndTime)
        {
            let endTime = moment.unix(this.props.user.currentPlayer!.territoryBattleData!.currentRoundEndTime);
            outOfDate = endTime.diff(moment(), "minutes") < 0;
        }
        return (
            <BasicLayout {...this.props} requiredApiRights={[TBPlayers.PERMISSION_KEY]} extraRefresh={() => this.fetchTBData(true)}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <div className={styles.bg}/>
                        <Title level={3} className={styles.title}>
                            Command Center
                        </Title>
                        <div className={styles.row}>
                            <Button type={"default"} className={styles.btn} title={'Go back to selection of command center'} onClick={() => this.props.back()}>
                                <FontAwesomeIcon icon={faArrowAltLeft} className={styles.icon}/>
                                Back on selection screen
                            </Button>
                            {this.isAdmin && <Popconfirm
                                title={`Do you want to break the game connection and get fresh data?`}
                                onConfirm={() => this.fetchTBData(true)}
                                key={'popconfirmFetchTBData'}
                                onCancel={() => this.fetchTBData(false)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type={"default"} className={styles.btn} title={'Sync the platoons with the game'}>
                                    <FontAwesomeIcon icon={faSyncAlt} spin={this.reloading} className={styles.icon}/>
                                    Get data from the game
                                </Button>
                            </Popconfirm>}
                            <Button
                                className={styles.btn}
                                disabled={this.savingAllOrders}
                                onClick={() => {
                                    const dataJS = toJS(this.tbNewCommands);

                                    this.saveAllTBMessages(dataJS)
                                }}>
                                <FontAwesomeIcon icon={faSyncAlt} spin={this.savingAllOrders} className={styles.icon}/>
                                Save all the orders!
                            </Button>
                        </div>
                    </div>
                    {this.numberOfzones === 0 && !this.reloading &&
						<Alert message="Please refresh the data!" type="info"
							   style={{width: '100%', textAlign: 'center'}}/>}
                    {outOfDate && !this.reloading &&
						<Alert message="Please refresh the data!" type="info"
							   style={{width: '100%', textAlign: 'center'}}/>}
                    {this.numberOfzones === 0 && this.reloading &&
						<Alert message="Patience you must have, my young padawan! Refreshing, the data is!" type="info"
							   style={{width: '100%', textAlign: 'center'}}/>}
                    {!this.isAdmin &&
						<Alert message="You need to be an admin or an officer to access this page!" type="warning"
							   style={{width: '100%', textAlign: 'center'}}/>}
                    <div className={styles.body}>
                        <Tabs
                            defaultActiveKey="`tab-phase1"
                            size={"large"}
                            onChange={(event) => runInAction(() => {
                                this.currentPhase = Number(event);
                            })}
                            destroyInactiveTabPane={false}
                            centered={false}
                            tabBarExtraContent={this.numberOfzones && <Popconfirm
								title={`Do you want to push all the orders? By doing this you will overwrite all the orders in the game and it can take a couple of minutes!`}
								onConfirm={() => this.pushCommands(true)}
								key={'popconfirmPushAllTBOrders'}
								onCancel={() => this.pushCommands(false)}
								okText="Push all the order"
								cancelText="Push only the new orders"
							>
                                <Button type={'primary'}>
                                    <FontAwesomeIcon icon={faSyncAlt} spin={this.reloadingOrders} className={styles.icon}/>
                                    Send all the orders
                                </Button>
                            </Popconfirm>
                            }
                        >
                            {this.renderTabPhase()}
                        </Tabs>
                    </div>
                </div>

            </BasicLayout>
        );
    }
}

