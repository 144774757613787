import { observable } from "mobx";
import StatIds from "./StatIds";
import ModSlots from "./ModSlots";
import PlayerCharacterData from "./PlayerCharacterData";
import { UnitData } from './UnitData';
import ModSets from "./ModSets";
import { ModUnitAutomationSettings } from './../utils/mod-automation-calculator';
import { optimizationData } from "../pages/Mods/PlaygroundBeta/PlaygroundLegacy/automate-settings";
import { GrandIvoryTemplate } from "./GrandIvory";


export class PreferenceWeight
{
    @observable id: string;
    @observable weight: number = 0;

    constructor(json: any)
    {
        this.id = json.id.toString();
        this.weight = json.weight;
    }
}

export class PrimaryPreference
{
    @observable slot: ModSlots;
    @observable primaries: PreferenceWeight[] = [];

    constructor(slot: ModSlots, stats: StatIds[] = [])
    {
        this.slot = slot;

        this.primaries = stats.map(s =>
        {
            return {
                id: s.toString(),
                weight: 0
            }
        })
    }

    public static fromJson(json: any): PrimaryPreference
    {
        let retVal = new PrimaryPreference(json.slot);
        if (json.primaries)
        {
            retVal.primaries = json.primaries.map((p: any) => new PreferenceWeight(p));
        }
        return retVal;
    }
}

export class UnitPriorization
{
    @observable unitRankings: UnitRanking[] = [];
    @observable disposableRankings: UnitRanking[] = [];

    constructor(json: any = undefined)
    {
        if (json !== undefined)
        {
            if (json.unitRankings !== undefined)
            {
                this.unitRankings = json.unitRankings.map((ur: any) => new UnitRanking(ur.baseId, ur.prioritizedRanking));
            }
            if (json.disposableRankings !== undefined)
            {
                this.disposableRankings = json.disposableRankings.map((ur: any) => new UnitRanking(ur.baseId, ur.prioritizedRanking));
            }
        }
    }

    public isDisposable(baseId: string)
    {
        return this.disposableRankings.find(u => u.baseId === baseId) !== undefined;
    }

    public makeDisposable(ur: UnitRanking, val: boolean, holdReset: boolean = false)
    {
        if (val)
        {
            this.unitRankings = this.unitRankings.filter(u => u.baseId !== ur.baseId);
            if (this.disposableRankings.find(u => u.baseId === ur.baseId) === undefined)
            {
                this.disposableRankings.push(ur);
            }
        } else
        {
            this.disposableRankings = this.disposableRankings.filter(u => u.baseId !== ur.baseId);
            if (this.unitRankings.find(u => u.baseId === ur.baseId) === undefined)
            {
                this.unitRankings.push(ur);
            }
        }
        if (holdReset === false)
            this.resetPrioritizationNumber();
    }

    public makeAllBelowDisposable(ur: UnitRanking, val: boolean)
    {
        this.unitRankings.filter(u => u.prioritizedRanking >= ur.prioritizedRanking).forEach(u => this.makeDisposable(u, val, true));
        this.resetPrioritizationNumber();
    }

    public resetPrioritizationNumber()
    {
        this.unitRankings.forEach((ur, index) => ur.prioritizedRanking = index + 1);
        this.disposableRankings.forEach((ur, index) => ur.prioritizedRanking = index + 1);
    }

    public reset(characters: Map<string, PlayerCharacterData>, units: UnitData[])
    {
        this.unitRankings = [];
        this.disposableRankings = [];
        this.initializeUnits(characters, units);
    }

    public initializeUnits(characters: Map<string, PlayerCharacterData>, units: UnitData[])
    {
        if (this.disposableRankings === undefined)
        {
            this.disposableRankings = [];
        }
        units.filter(u => u.combatType === 1 &&
            characters.has(u.baseId) &&
            this.unitRankings.find(ur => ur.baseId === u.baseId) === undefined &&
            this.disposableRankings.find(ur => ur.baseId === u.baseId) === undefined).sort((u1, u2) =>
            {
                let p1: PlayerCharacterData = characters.get(u1.baseId)!;
                let p2: PlayerCharacterData = characters.get(u2.baseId)!;

                return p2.powerTotal - p1.powerTotal;
            }).forEach(u =>
            {
                this.unitRankings.push(new UnitRanking(u.baseId, -1));
            });
        this.resetPrioritizationNumber();
    }
}

export class UnitRanking
{
    @observable baseId: string;
    @observable prioritizedRanking: number;

    constructor(baseId: string, prioritizedRanking: number)
    {
        this.baseId = baseId;
        this.prioritizedRanking = prioritizedRanking;
    }
}

export class UnitModPreferences
{
    public static SETTINGS_KEY = "unit_mod_prefs";

    public static CIRCLE_PRIMARIES: StatIds[] = [StatIds.ProtectionPct, StatIds.HealthPct];
    public static ARROW_PRIMARIES: StatIds[] = [StatIds.ProtectionPct, StatIds.HealthPct, StatIds.Speed, StatIds.OffensePct, StatIds.DefensePct, StatIds.CriticalAvoidance, StatIds.Accuracy];
    public static TRIANGLE_PRIMARIES: StatIds[] = [StatIds.ProtectionPct, StatIds.HealthPct, StatIds.OffensePct, StatIds.DefensePct, StatIds.CriticalChance, StatIds.CriticalDamage];
    public static CROSS_PRIMARIES: StatIds[] = [StatIds.ProtectionPct, StatIds.HealthPct, StatIds.OffensePct, StatIds.DefensePct, StatIds.Potency, StatIds.Tenacity];
    public static SECONDARY_LIST: string[] = ["Speed", "Critical Chance", "Health", "Potency", "Offense", "Tenacity", "Protection", "Defense"];

    public static SET_LIST: ModSets[] = [ModSets.CriticalChance, ModSets.CriticalDamage, ModSets.Defense, ModSets.Health, ModSets.Offense, ModSets.Potency, ModSets.Speed, ModSets.Tenacity];

    @observable baseId: string;
    @observable primaryPreferences: PrimaryPreference[] = [];
    @observable setPreferences: PreferenceWeight[] = [];
    @observable secondaryPreferences: PreferenceWeight[] = [];

    constructor(baseId: string)
    {
        this.baseId = baseId;

        UnitModPreferences.clearPrimaryList(this);
        UnitModPreferences.clearSecondaryLists(this);
        UnitModPreferences.clearSetLists(this);
    }

    setSecondaryDefaults(optimizationData: ModUnitAutomationSettings)
    {
        let secondaryMultipliers = optimizationData.secondaryTypeMultipliers;
        this.secondaryPreferences.forEach(sp =>
        {
            sp.weight = secondaryMultipliers[sp.id];
        });
    }

    setSetDefaults(optimizationData: ModUnitAutomationSettings)
    {
        let fourSetWeight = optimizationData.fourSetMultipliers;

        this.setPreferences.forEach(sp =>
        {
            sp.weight = fourSetWeight[sp.id] === undefined ? 0 : fourSetWeight[sp.id];
        });
    }

    setPrimariesBasedOnEquipped(pcd: PlayerCharacterData)
    {
        pcd.mods.filter(mod => mod.slot === ModSlots.Circle || mod.slot === ModSlots.Cross || mod.slot === ModSlots.Triangle || mod.slot === ModSlots.Arrow).forEach(mod =>
        {
            let statWeight = this.getPrimaryPref(mod.slot, mod.primary.statId);
            statWeight.weight = 1;
        });
    }

    fromSystemAutomationSettings(optimizationData: ModUnitAutomationSettings)
    {
        this.primaryPreferences.forEach(slot =>
        {
            let slotPreferences = optimizationData.primaryMultipliers[slot.slot.toString()];
            if (slotPreferences !== undefined)
            {
                slot.primaries.forEach(primary =>
                {
                    let primaryPreference = slotPreferences[primary.id.toString()];
                    if (primaryPreference !== undefined)
                    {
                        primary.weight = primaryPreference > .2 ? primaryPreference : 0;
                    }
                });
            }
        });

        this.setSecondaryDefaults(optimizationData);
        this.setSetDefaults(optimizationData);

    }

    mapModUnitAutomationSettings(ignoreSet: boolean = false): ModUnitAutomationSettings
    {
        let retVal = new ModUnitAutomationSettings();
        retVal.name = this.baseId;


        // primaryMultipliers: { [index: string]: { [index: string]: number } } = {};
        this.primaryPreferences.forEach(pp =>
        {
            let ppMultiplier: {
                [index: string]: number;
            } = {};
            retVal.primaryMultipliers[pp.slot.toString()] = ppMultiplier;

            let primarySettingExists = pp.primaries.find(p => p.weight > 0) !== undefined;

            pp.primaries.forEach(p =>
            {
                ppMultiplier[p.id.toString()] = primarySettingExists ? p.weight : 1;
            });
        });

        retVal.primaryMultipliers[ModSlots.Square.toString()] = {};
        retVal.primaryMultipliers[ModSlots.Square.toString()][StatIds.OffensePct.toString()] = 1;
        retVal.primaryMultipliers[ModSlots.Diamond.toString()] = {};
        retVal.primaryMultipliers[ModSlots.Diamond.toString()][StatIds.DefensePct.toString()] = 1;

        // secondaryTypeMultipliers: { [index: string]: number } = {};
        this.secondaryPreferences.forEach(sp =>
        {
            retVal.secondaryTypeMultipliers[sp.id.toString()] = sp.weight;
        });

        // fourSetMultipliers: { [index: string]: number } = {};
        this.setPreferences.forEach(sp =>
        {
            retVal.fourSetMultipliers[sp.id.toString()] = ignoreSet ? 1 : sp.weight;
        });
        return retVal;
    }

    clone(): UnitModPreferences
    {
        return UnitModPreferences.fromJson(JSON.parse(JSON.stringify(this)));
    }

    public static getPrimaryPreference(umps: UnitModPreferences[], baseId: string, slot: ModSlots, statId: StatIds): PreferenceWeight 
    {
        let unitPreferences = umps.find(ump => ump.baseId === baseId);
        if (unitPreferences === undefined)
        {
            throw new Error("Unable to find primary preference: " + baseId + " " + slot + " " + statId);
        }
        return unitPreferences.getPrimaryPref(slot, statId);
    }

    getPrimaryPref(slot: ModSlots, statId: StatIds): PreferenceWeight
    {
        let primaryPreference = this.primaryPreferences.find(pp => pp.slot === slot);
        let statPreference = primaryPreference === undefined ? undefined : primaryPreference.primaries.find(p => p.id === statId.toString());
        if (statPreference === undefined)
        {
            throw new Error("Unable to find primary preference: " + slot + " " + statId);
        }
        return statPreference;
    }
    getSecondaryPref(secondaryName: string): PreferenceWeight
    {
        let retVal = this.secondaryPreferences.find(p => p.id === secondaryName.toString());
        if (retVal === undefined)
        {
            throw new Error("Unable to find secondary preference: " + secondaryName);
        }
        return retVal;
    }

    getSetPref(set: ModSets): PreferenceWeight
    {
        let retVal = this.setPreferences.find(p => p.id === set.toString());
        if (retVal === undefined)
        {
            throw new Error("Unable to find set preference: " + set);
        }
        return retVal;
    }

    public static fromJson(json: any): UnitModPreferences
    {
        let retVal = new UnitModPreferences(json.baseId);

        if (json.primaryPreferences)
        {
            retVal.primaryPreferences = json.primaryPreferences.map((pp: any) => PrimaryPreference.fromJson(pp));
        }
        if (json.setPreferences !== undefined)
        {
            retVal.setPreferences = json.setPreferences.map((sp: any) => new PreferenceWeight(sp));
        }
        if (json.secondaryPreferences !== undefined)
        {
            retVal.secondaryPreferences = json.secondaryPreferences.map((sp: any) => new PreferenceWeight(sp));
        }
        if (retVal.secondaryPreferences.length === 0)
        {
            UnitModPreferences.clearSecondaryLists(retVal);
        }
        if (retVal.setPreferences.length === 0)
        {
            UnitModPreferences.clearSetLists(retVal);
        }
        return retVal;
    }

    public static clearPrimaryList(up: UnitModPreferences, pcds: PlayerCharacterData[] | null = null): void
    {
        up.primaryPreferences = [];
        up.primaryPreferences.push(new PrimaryPreference(ModSlots.Circle, UnitModPreferences.CIRCLE_PRIMARIES));
        up.primaryPreferences.push(new PrimaryPreference(ModSlots.Arrow, UnitModPreferences.ARROW_PRIMARIES));
        up.primaryPreferences.push(new PrimaryPreference(ModSlots.Triangle, UnitModPreferences.TRIANGLE_PRIMARIES));
        up.primaryPreferences.push(new PrimaryPreference(ModSlots.Cross, UnitModPreferences.CROSS_PRIMARIES));

    }

    public static clearSecondaryLists(up: UnitModPreferences): void
    {
        up.secondaryPreferences = [];
        up.secondaryPreferences = UnitModPreferences.SECONDARY_LIST.map(sp => new PreferenceWeight({ id: sp, weight: 1 }));
    }


    public static clearSetLists(up: UnitModPreferences): void
    {
        up.setPreferences = [];
        up.setPreferences = UnitModPreferences.SET_LIST.map(sp => new PreferenceWeight({ id: sp.toString(), weight: 1 }));
    }

    public static initializeUnits(umps: UnitModPreferences[], units: UnitData[], characters: Map<string, PlayerCharacterData>)
    {
        let optimizationDataObject = JSON.parse(optimizationData);
        let unitAutomationSettings: ModUnitAutomationSettings[] = optimizationDataObject.characterResults;

        units.filter(u => u.combatType === 1).forEach(u =>
        {

            if (umps.find(ump => ump.baseId === u.baseId) === undefined)
            {
                let newUmp = new UnitModPreferences(u.baseId);
                umps.push(newUmp);

                let pcd = characters.get(u.baseId);
                let uas = unitAutomationSettings.find(uas => uas.name === u.baseId);

                if (pcd !== undefined)
                {
                    newUmp.setPrimariesBasedOnEquipped(pcd);
                }
                if (uas !== undefined)
                {
                    newUmp.setSecondaryDefaults(uas);
                    newUmp.setSetDefaults(uas);
                }
            }
        });
    }

    private static max(v1: number, v2: number): number
    {
        return v1 > v2 ? v1 : v2;
    }

    public static setGrandIvoryPrimaryPreference(ump: UnitModPreferences, slot: ModSlots, grandIvoryPreference: string | null)
    {
        if (grandIvoryPreference !== null)
        {
            let pref = ump.primaryPreferences.find(pp => pp.slot === slot);
            if (pref !== undefined)
            {
                pref.primaries.forEach(primary =>
                {
                    if (grandIvoryPreference === "Critical Damage %" && primary.id === StatIds.CriticalDamage.toString())
                    {
                        primary.weight = 1;
                    } else if (grandIvoryPreference === "Speed" && primary.id === StatIds.Speed.toString())
                    {
                        primary.weight = 1;
                    } else if (grandIvoryPreference === "Protection %" && primary.id === StatIds.ProtectionPct.toString())
                    {
                        primary.weight = 1;
                    } else if (grandIvoryPreference === "Potency %" && primary.id === StatIds.Potency.toString())
                    {
                        primary.weight = 1;
                    } else if (grandIvoryPreference === "Offense %" && primary.id === StatIds.OffensePct.toString())
                    {
                        primary.weight = 1;
                    } else if (grandIvoryPreference === "Defense %" && primary.id === StatIds.DefensePct.toString())
                    {
                        primary.weight = 1;
                    } else if (grandIvoryPreference === "Critical Avoidance %" && primary.id === StatIds.CriticalAvoidance.toString())
                    {
                        primary.weight = 1;
                    } else if (grandIvoryPreference === "Health %" && primary.id === StatIds.HealthPct.toString())
                    {
                        primary.weight = 1;
                    } else if (grandIvoryPreference === "Tenacity %" && primary.id === StatIds.Tenacity.toString())
                    {
                        primary.weight = 1;
                    } else if (grandIvoryPreference === "Critical Chance %" && primary.id === StatIds.CriticalChance.toString())
                    {
                        primary.weight = 1;
                    } else
                    {
                        primary.weight = 0;
                    }
                });
            }
        } else
        {
            let pref = ump.primaryPreferences.find(pp => pp.slot === slot);
            if (pref !== undefined)
            {
                pref.primaries.forEach(p => p.weight = 1);
            }
            // if you only want to show whats equipped, show this
            // if (pc !== undefined)
            // {
            //     pc.mods.filter(mod => mod.slot === slot).forEach(mod =>
            //     {
            //         let statWeight = ump.getPrimaryPref(mod.slot, mod.primary.statId);
            //         statWeight.weight = 1;
            //     });
            // }
        }
    }

    public static loadFromGrandIvory(umps: UnitModPreferences[], git: GrandIvoryTemplate)
    {

        umps.forEach(ump =>
        {
            let baseId = ump.baseId;
            let unitTemplate = git.selectedCharacters.find(sc => sc.id === baseId);

            if (unitTemplate !== undefined)
            {
                ump.secondaryPreferences.forEach(sp =>
                {
                    switch (sp.id)
                    {
                        case "Speed":
                            sp.weight = unitTemplate!.target.speed / 100;
                            break;
                        case "Critical Chance":
                            sp.weight = unitTemplate!.target.critChance / 100;
                            break;
                        case "Health":
                            sp.weight = unitTemplate!.target.health / 100;
                            break;
                        case "Potency":
                            sp.weight = unitTemplate!.target.potency / 100;
                            break;
                        case "Offense":
                            sp.weight = UnitModPreferences.max(unitTemplate!.target.physDmg, unitTemplate!.target.specDmg) / 100;
                            break;
                        case "Tenacity":
                            sp.weight = unitTemplate!.target.tenacity / 100;
                            break;
                        case "Protection":
                            sp.weight = unitTemplate!.target.protection / 100;
                            break;
                        case "Defense":
                            sp.weight = UnitModPreferences.max(unitTemplate!.target.armor, unitTemplate!.target.resistance) / 100;
                            break;
                    }
                });

                UnitModPreferences.setGrandIvoryPrimaryPreference(ump, ModSlots.Arrow, unitTemplate.target.primaryStatRestrictions.arrow);
                UnitModPreferences.setGrandIvoryPrimaryPreference(ump, ModSlots.Circle, unitTemplate.target.primaryStatRestrictions.circle);
                UnitModPreferences.setGrandIvoryPrimaryPreference(ump, ModSlots.Triangle, unitTemplate.target.primaryStatRestrictions.triangle);
                UnitModPreferences.setGrandIvoryPrimaryPreference(ump, ModSlots.Cross, unitTemplate.target.primaryStatRestrictions.cross);

                UnitModPreferences.clearSetLists(ump);

                if (unitTemplate.target.setRestrictions.fullSpecified())
                {
                    ump.setPreferences.forEach(sp =>
                    {
                        switch (sp.id)
                        {
                            case ModSets.Speed.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.speed !== null && unitTemplate!.target.setRestrictions.speed) > 0 ? 1 : 0;
                                break;
                            case ModSets.CriticalDamage.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.critdamage !== null && unitTemplate!.target.setRestrictions.critdamage) > 0 ? 1 : 0;
                                break;
                            case ModSets.Offense.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.offense !== null && unitTemplate!.target.setRestrictions.offense) > 0 ? 1 : 0;
                                break;

                            case ModSets.Potency.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.potency !== null && unitTemplate!.target.setRestrictions.potency) > 0 ? 1 : 0;
                                break;
                            case ModSets.Tenacity.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.tenacity !== null && unitTemplate!.target.setRestrictions.tenacity) > 0 ? 1 : 0;
                                break;
                            case ModSets.Defense.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.defense !== null && unitTemplate!.target.setRestrictions.defense) > 0 ? 1 : 0;
                                break;
                            case ModSets.Health.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.health !== null && unitTemplate!.target.setRestrictions.health) > 0 ? 1 : 0;
                                break;
                            case ModSets.CriticalChance.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.critchance !== null && unitTemplate!.target.setRestrictions.critchance) > 0 ? 1 : 0;
                                break;
                        }
                    });
                } else if (unitTemplate.target.setRestrictions.specified())
                {
                    ump.setPreferences.forEach(sp =>
                    {
                        switch (sp.id)
                        {
                            case ModSets.Speed.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.speed !== null && unitTemplate!.target.setRestrictions.speed) > 0 ? 1 : sp.weight;
                                break;
                            case ModSets.CriticalDamage.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.critdamage !== null && unitTemplate!.target.setRestrictions.critdamage) > 0 ? 1 : sp.weight;
                                break;
                            case ModSets.Offense.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.offense !== null && unitTemplate!.target.setRestrictions.offense) > 0 ? 1 : sp.weight;
                                break;

                            case ModSets.Potency.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.potency !== null && unitTemplate!.target.setRestrictions.potency) > 0 ? 1 : sp.weight;
                                break;
                            case ModSets.Tenacity.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.tenacity !== null && unitTemplate!.target.setRestrictions.tenacity) > 0 ? 1 : sp.weight;
                                break;
                            case ModSets.Defense.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.defense !== null && unitTemplate!.target.setRestrictions.defense) > 0 ? 1 : sp.weight;
                                break;
                            case ModSets.Health.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.health !== null && unitTemplate!.target.setRestrictions.health) > 0 ? 1 : sp.weight;
                                break;
                            case ModSets.CriticalChance.toString():
                                sp.weight = (unitTemplate!.target.setRestrictions.critchance !== null && unitTemplate!.target.setRestrictions.critchance) > 0 ? 1 : sp.weight;
                                break;
                        }
                    });
                }
            }
        });
    }
}