import React from "react";
import {observer} from "mobx-react";
import IPageProps from "../../../IPageProps";
import {action, observable, runInAction} from "mobx";
import {Button, Modal, Select, Switch} from "antd";
import styles from "../../../../styles/components/Modal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/pro-duotone-svg-icons";
import {
    IGuildSquadTemplate,
    IGuildSquadUnits,
    IFilterUnitStatsGuildSquad,
    IFilterSpecialUnitGuildSquad
} from "../../../../model/Squads";
import modal from "../../../../styles/components/Modal.module.scss";
import {gear, gearSub6Stars, stars} from "../../../../model/Level";
import {ShipStats, Stats} from "../../../../model/Stats";

interface IFilterSquadModalProps extends IPageProps{
    showModal: boolean;
    onClose: () => void;
    showIncludedUnit: boolean;
    showExcludedUnit: boolean;
    showSpecificUnitStats: boolean;
    template: IGuildSquadTemplate;
    includedCharacter: IFilterSpecialUnitGuildSquad;
    excludedCharacter: IFilterSpecialUnitGuildSquad;
    specificUnitStats: IFilterUnitStatsGuildSquad;
    onSaveIncludedUnit: (showIncludedUnit: boolean, includedCharacter: IFilterSpecialUnitGuildSquad) => void;
    onSaveExcludedUnit: (showExcludedUnit: boolean, excludedCharacter: IFilterSpecialUnitGuildSquad) => void;
    onSaveSpecificUnitStats: (showSpecificUnitStats: boolean, specificUnitStats: IFilterUnitStatsGuildSquad) => void;
}

@observer
export class FilterSquadModal extends React.PureComponent<IFilterSquadModalProps> {
    @observable showModal: boolean = false;
    @observable name = this.props.template.name;
    @observable selectedCharacters: IGuildSquadUnits[] = this.props.template.units;
    @observable filterUnitType: number = this.props.template.combatType;
    @observable showIncludedUnit: boolean = this.props.showIncludedUnit;
    @observable showExcludedUnit: boolean = this.props.showExcludedUnit;
    @observable showSpecificUnitStats: boolean = this.props.showSpecificUnitStats;
    @observable includedCharacter: IFilterSpecialUnitGuildSquad = {characterId: this.props.includedCharacter.characterId, minStars: this.props.includedCharacter.minStars, minGear: this.props.includedCharacter.minGear};
    @observable excludedCharacter: IFilterSpecialUnitGuildSquad = {characterId: this.props.excludedCharacter.characterId, minStars: this.props.excludedCharacter.minStars, minGear: this.props.excludedCharacter.minGear};
    @observable specificUnitStats: IFilterUnitStatsGuildSquad = {characterId: this.props.specificUnitStats.characterId, stats: this.props.specificUnitStats.stats, statsName: this.props.specificUnitStats.statsName};

    @action private cancel() {
        return "";
    }

    render() {
        const unit = this.props.gameData.units;
        const units = this.props.gameData.units || [];

        return (
            <Modal
                title={`Filters for ${this.props.template.name} squad template`}
                visible={this.props.showModal}
                onCancel={this.props.onClose}
                maskClosable={false}
                width={680}
                footer={[
                    <Button
                        key="saveGuildSquadFilters"
                        type="primary"
                        onClick={() => {
                            this.props.onSaveIncludedUnit(this.showIncludedUnit, this.includedCharacter)
                            this.props.onSaveExcludedUnit(this.showExcludedUnit, this.excludedCharacter)
                            this.props.onSaveSpecificUnitStats(this.showSpecificUnitStats, this.specificUnitStats)
                        }}
                        disabled={
                            (this.showIncludedUnit && this.includedCharacter.characterId === "Select a unit") ||
                            this.selectedCharacters.length === 0 ||
                            (this.showSpecificUnitStats && this.specificUnitStats.characterId === "Select a unit")
                        }
                    >
                        <FontAwesomeIcon icon={faSave} className={styles.icon} />
                        Save
                    </Button>
                ]}
            >
                <div className={styles.modal}>
                    <div className={modal.row}>
                        <div className={styles.switch}>
                            <Switch
                                checked={this.showIncludedUnit}
                                onChange={ () => runInAction(() => {
                                    this.showIncludedUnit = !this.showIncludedUnit;
                                    this.excludedCharacter = {characterId: "Select a unit", minStars: 1, minGear: 1};
                                    if(!this.showIncludedUnit){
                                        this.includedCharacter = {characterId: "Select a unit", minStars: 1, minGear: 1};
                                    }
                                })}
                                className={styles.item}
                                disabled={this.showExcludedUnit}
                            />
                                Show only the players that have the following unit:
                        </div>
                    </div>
                    {this.showIncludedUnit && <div className={modal.row}>
                        <Select
                            placeholder={'Select a unit'}
                            showSearch={true}
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            defaultValue={this.includedCharacter.characterId}
                            onSelect={value => {
                                runInAction(() => {
                                    this.includedCharacter.characterId = value;
                                })
                            }}
                            showArrow={true}
                        >
                            {units.map(unit => {
                                return(
                                    <Select.Option key={unit.baseId} value={unit.baseId}>{unit.name}</Select.Option>
                                );
                            })}
                        </Select>
                    </div>}
                    {this.showIncludedUnit && <div className={modal.column}>
                        <div className={modal.row}>
                            <div className={modal.label}>The minimum stars:</div>
                            <Select
                                showSearch={true}
                                className={`${styles.select}`}
                                value={this.includedCharacter.minStars}
								disabled={this.includedCharacter.characterId === "Select a unit"}
                                onSelect={value => {
                                    runInAction(() => this.includedCharacter.minStars = value)
                                }}
                                showArrow={true}
                            >
                                {stars.map(i =>
                                    <Select.Option key={i} value={i}>{i}</Select.Option>
                                )}
                            </Select>
                        </div>
                        {(this.includedCharacter.characterId !== 'Select a unit' && unit?.find(x => x.baseId === this.includedCharacter.characterId)!.combatType === 1) && <div className={modal.row}>
                            <div className={modal.label}>The minimum gear:</div>
                            <Select
                                showSearch={true}
                                className={`${styles.select}`}
                                value={this.includedCharacter.minGear}
                                disabled={this.includedCharacter.minStars < 6}
                                onSelect={value => {
                                    runInAction(() => this.includedCharacter.minGear = value)
                                }}
                                showArrow={true}
                            >
                                {gear.map(i =>
                                    <Select.Option key={i} value={i}>{i}</Select.Option>
                                )}
                            </Select>
                        </div>}
                    </div>}

                    <div className={modal.row}>
                        <div className={styles.switch}>
                            <Switch
                                checked={this.showExcludedUnit}
                                onChange={ () => runInAction(() => {
                                    this.showExcludedUnit = !this.showExcludedUnit;
                                    this.includedCharacter = {characterId: "Select a unit", minStars: 1, minGear: 1};
                                    if(!this.showExcludedUnit){
                                        this.excludedCharacter = {characterId: "Select a unit", minStars: 1, minGear: 1};
                                    }
                                })}
                                className={styles.item}
                                disabled={this.showIncludedUnit}
                            />
                                Show only the players that don't have the following unit:
                        </div>
                    </div>
                    {this.showExcludedUnit && <div className={modal.row}>
						<Select
							placeholder={'Select a unit'}
							showSearch={true}
							filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
							defaultValue={this.excludedCharacter.characterId}
							onSelect={value => {
                                runInAction(() => {
                                    this.excludedCharacter.characterId = value;
                                })
                            }}
							showArrow={true}
						>
                            {units.map(unit => {
                                return(
                                    <Select.Option key={unit.baseId} value={unit.baseId}>{unit.name}</Select.Option>
                                );
                            })}
						</Select>
					</div>}
                    {this.showExcludedUnit && <div className={modal.column}>
                        <div className={modal.row}>
                            <div className={modal.label}>The maximum stars:</div>
                            <Select
                                showSearch={true}
                                className={`${styles.select}`}
                                value={this.excludedCharacter.minStars}
								disabled={this.excludedCharacter.characterId === "Select a unit"}
                                onSelect={value => {
                                    runInAction(() => this.excludedCharacter.minStars = value)
                                }}
                                showArrow={true}
                            >
                                {stars.map(i =>
                                    <Select.Option key={i} value={i}>{i}</Select.Option>
                                )}
                            </Select>
                        </div>
                        {(this.excludedCharacter.characterId !== 'Select a unit' && unit?.find(x => x.baseId === this.excludedCharacter.characterId)!.combatType === 1) && <div className={modal.row}>
                            <div className={modal.label}>The maximum gear:</div>
                            <Select
                                showSearch={true}
                                className={`${styles.select}`}
                                value={this.excludedCharacter.minGear}
                                disabled={this.excludedCharacter.minStars < 1}
                                onSelect={value => {
                                    runInAction(() => this.excludedCharacter.minGear = value)
                                }}
                                showArrow={true}
                            >
                                {this.excludedCharacter.minStars < 7 ? gearSub6Stars.map(i =>
                                    <Select.Option key={i} value={i}>{i}</Select.Option>
                                ) : gear.map(i =>
                                    <Select.Option key={i} value={i}>{i}</Select.Option>
                                )}
                            </Select>
                        </div>}
                    </div>}

                    <div className={modal.row}>
                        <div className={styles.switch}>
                            <Switch
                                checked={this.showSpecificUnitStats}
                                onChange={ () => runInAction(() => {
                                    this.showSpecificUnitStats = !this.showSpecificUnitStats
                                    if(!this.showSpecificUnitStats){
                                        this.specificUnitStats = {characterId: "Select a unit", stats: "speed", statsName: "Speed"}
                                    }
                                })}
                                className={styles.item}
                                disabled={this.selectedCharacters.length === 0}
                            />
                            Add a column with the stat from a selected unit
                        </div>
                    </div>
                    {this.showSpecificUnitStats && <div className={modal.row}>
                        <Select
                            placeholder={'Select a unit'}
                            showSearch={true}
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            defaultValue={this.specificUnitStats.characterId}
                            onSelect={value => {
                                runInAction(() => {
                                    this.specificUnitStats.characterId = value;
                                })
                            }}
                            showArrow={true}
                        >
                            {units.filter(f => this.selectedCharacters.find(u => u.characterId === f.baseId)).map(unit => {
                                return(
                                    <Select.Option key={unit.baseId} value={unit.baseId}>{unit.name}</Select.Option>
                                );
                            })}
                        </Select>
                    </div>}
                    {this.showSpecificUnitStats && <div className={modal.row}>
						<Select
							placeholder={'Select a stat'}
							showSearch={true}
							filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
							className={`${styles.select}`}
							value={this.specificUnitStats.statsName}
							onSelect={(value, option) => runInAction(() => {
                                this.specificUnitStats.stats = option.props.key
                                this.specificUnitStats.statsName = value
                            })}
							showArrow={true}
						>
                            {unit?.find(x => x.baseId === this.specificUnitStats.characterId)?.combatType === 1 ? (Object.getOwnPropertyNames(Stats) as (keyof typeof Stats)[]).map(i =>
                                <Select.Option key={i} value={Stats[i]}>{Stats[i]}</Select.Option>
                            ) : (Object.getOwnPropertyNames(ShipStats) as (keyof typeof ShipStats)[]).map(i =>
                                <Select.Option key={i} value={ShipStats[i]}>{ShipStats[i]}</Select.Option>
                            )}
						</Select>
                    </div>}
                </div>
            </Modal>
        );
    }
}
