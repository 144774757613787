enum PatreonLevel
{
    None = 0,
    Cherry = 1,
    Chile = 2,
    Jalapeno = 3,
    Habanero = 4,
    WarTools = 8
}

export default PatreonLevel;