import React from "react";
import UnitData from './../../../model/UnitData';

import styles from "./styles/UnitAvatarGroup.module.scss";
import avatar from "./styles/UnitAvatar.module.scss";
import {IGuildSquadSubUnits} from "../../../model/Squads";

interface IProps
{
    unitData: UnitData;
    className?: string;
    subsPriority?: 'order' | 'gear';
    ultimate?: boolean;
    stars: number;
    gear: number;
    relic: number;
    zeta?: boolean;
    omi?: boolean;
    showName: boolean;
    subsUnit?: UnitData[];
    subs?: IGuildSquadSubUnits[];
    onClick?: () => void;
}

export default function GroupUnitAvatar({ unitData, zeta, omi, subsPriority, stars, gear, relic, ultimate, subs, subsUnit, showName, onClick, className }: IProps) {

    const star = [];

    if (stars) {
        for (let i = 0; i < 7; i++) {

            star.push(
                <span key={i} className={` ${avatar.star} ${unitData.combatType === 2 ? (avatar.ship + ' ' + styles['ship-star']) : avatar.squad} ${avatar[`star-${i + 1}`]} ${stars <= i ? avatar[`star-inactive`] : ''}`} />
            );
        }
    }

    const forceAlignment =  unitData.alignment === 2 ? "light" : unitData.alignment === 3 ? "dark" : "neutral";
    const styleForceAlignment = "force-" + forceAlignment;

    return (
        <div className={`${className} ${styles['unit-avatar-group']} ${styles['size-small']}`} onClick={() => onClick !== undefined ? onClick() : undefined}>
            <div className={styles.wrapper}>
                <div className={`${avatar['unit-avatar']} ${styles['unit-avatar']}`} title={unitData.name}>

                    <div className={`${avatar[styleForceAlignment]} ${avatar.wrapper} ${unitData.combatType === 1 ? 'circle' : 'square'}`}>
                        <div className={`${avatar.avatar} ${styles.avatar}`} >
                            <img src={'https://api.hotutils.com/images' + unitData.baseImage} className={`${styles['size-medium']} ${styles['simple-avatar']}`} alt={unitData.name} title={unitData.name} />
                        </div>
                        {(unitData.combatType === 1  && gear) ? (
                            <span
                                className={avatar["gear" + (gear === 13 ? 13 : '')]}
                                style={{
                                    backgroundImage: gear.toString() === '13' ? `url(https://api.hotutils.com/images/gear/g13-atlas.png)` :
                                        `url(https://api.hotutils.com/images/gear/g${Number(gear)}.png)`
                                }}
                            />
                        ) : null}
                        {gear === 13 ? <span className={`${avatar.relic} ${styles.relic} ${(ultimate && unitData.galacticLegend) ? avatar.ultimate : ''}`}>{Number(relic)}</span> : null}

                    </div>
                    {star}
                    {zeta && <span className={styles["main-zeta"]} />}
                    {omi && <span className={styles["main-omi"]} />}

                    {subsPriority && subsUnit?.length ? (
                        <span className={styles["sub-factor"]}>
                          By {subsPriority}
                        </span>
                    ) : null}
                </div>
                {subsUnit && subsUnit.length ? (
                    <div className={styles.subs}>
                        {subsUnit.map((sub, i) => {

                            if(!subs) {
                                return null;
                            }

                            const subUnit = subs.find(s => s.characterId === sub.baseId)

                            if(!subUnit){
                                return <div className={styles['sub-wrapper']} key={sub.name}>
                                    <div className={styles.number}>
                                        {subs.length - i}
                                    </div>
                                </div>;
                            }
                            const subUnitFilters = subUnit?.filters

                            return sub && ((unitData && i < 2) || (!unitData && i < 4)) ? (
                                <div className={styles['sub-wrapper']} key={sub.name}>
                                    {((unitData && i === 1) || (!unitData && i === 3)) && subs.length > i + 1 ? (
                                        <div className={styles['multiple-subs']}>
                                            + {subs.length - i}
                                        </div>
                                    ) : (
                                        <div className={`${styles['avatar']} `}>
                                            <div className={`${styles.sub} ${styles['unit-avatar']}`}>
                                                <img
                                                    src={'https://api.hotutils.com/images' + sub.baseImage}
                                                    className={`${styles['size-small']} ${styles['simple-avatar']}`}
                                                    alt={sub.name}
                                                    title={sub.name}
                                                />
                                            </div>
                                            {(unitData.combatType === 1) && <div className={`${styles['sub-gear']} ${styles.number}`}>
                                                <span>
                                                    {subUnitFilters.gear === 13 ? ('R' + subUnitFilters.relic) : ('G' + subUnitFilters.gear)}
                                                </span>
                                            </div>}
                                        </div>
                                    )}
                                </div>
                            ) : null;
                        })}
                    </div>
                ) : null}
            </div>
            {showName && <div className={styles["main-name"]}>{unitData.name}</div>}
        </div>
    );
}