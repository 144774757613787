
import { TerritoryBattlePlayerStatusSetting } from './../../model/TerritoryBattleStatusPreferences';
import TerritoryBattleData, { TerritoryBattleZoneStatus } from './../../model/TerritoryBattleData';
import { TerritoryBattleGameData, TerritoryBattleDefinition } from './../../model/TerritoryBattleGameData';
import { RewardCombinations, CombatMissionReward } from './tb-status-calcs';


export class MissionColumnResult
{
    columnName: string;
    wavesCompleted: number;

    constructor(columnName: string, wavesCompleted: number)
    {
        this.columnName = columnName;
        this.wavesCompleted = wavesCompleted;
    }
}

export class TerritoryBattlePlayerCalc
{
    get key()
    {
        return this.allyCode;
    }
    name: string;
    allyCode: number;
    shipGP: number = 0;
    characterGP: number = 0;

    playerPlatoonedShipGp: number = 0;
    playerPlatoonedCombatGp: number = 0;

    territoryPointsContributed: number = 0;
    platoonMissionUnitsAssigned: number = 0;
    combatMissionWavesCompleted: number = 0;
    rogueActions: number = 0;

    currentRoundTerritoryPoints: number = 0;
    currentRoundGpDeployed: number = 0;

    get currentRoundGpDeployedRemaining()
    {
        let retVal = this.shipGP + this.characterGP - this.currentRoundGpDeployed;
        return retVal < 0 ? 0 : retVal;
    }

    currentRoundCombatMissions: number = 0;
    currentRoundSpecialMissions: number = 0;

    currentRoundMissionPoints: number = 0;

    deployedShips: boolean = true;
    deployedCharacters: boolean = true;

    deployShipsConfirmed: boolean = false;
    deployCombatConfirmed: boolean = false;
    lowConfidence: boolean = false;


    missionsShips: boolean = false;
    lowConfidenceShipsMissions: boolean = false;
    missionsCharacters: boolean = false;
    lowConfidenceCharacterMissions: boolean = false;

    specialMissions: boolean = false;
    specialMissionsConfirmed: boolean = false;

    missionsShipsConfirmed: boolean = false;
    missionsCharactersConfirmed: boolean = false;

    minEstimatedCombatDeploymentRemaining: number = 0;
    minEstimatedShipDeploymentRemaining: number = 0;

    unknownDeploymentPowerRemaining: number = 0;

    possibleMissionCombinations: RewardCombinations | null = null;

    statusSetting: TerritoryBattlePlayerStatusSetting | null = null;

    missionComboUncertain: boolean = false;

    missionColumns: MissionColumnResult[] = [];

    constructor(name: string, allyCode: number)
    {
        this.name = name;
        this.allyCode = allyCode;
    }
}

export interface IPlayerMissionCombination
{
    combatMissionsCount: number;
    specialMissionsCount: number;

    currentRoundMissionPoints: number;
    derivedRoundMissionWavesCompleted: number;
    specialMissions: boolean; // output
    possibleMissionCombinations: RewardCombinations | null;
    missionColumns: MissionColumnResult[];

}

class TbPlayerCalcs 
{
    public static GP_WIGGLE = 10000;

    public static calculatePossibleMissionCombinatationsWaves(
        playerCalcs: IPlayerMissionCombination[],
        uniqueUnitMissionsCount: Map<string, number>,
        uniqueFleetMissionsCount: Map<string, number>,
        pointsMap: Map<string, RewardCombinations>)
    {

        playerCalcs.forEach(tbPlayer =>
        {
            let numberOfMissionsDone = tbPlayer.combatMissionsCount;
            let pointsEarned = tbPlayer.currentRoundMissionPoints;

            let pointsKey = pointsEarned + "_" + tbPlayer.derivedRoundMissionWavesCompleted;
            if (pointsMap.has(pointsKey))
            {
                let fullPossibleList = pointsMap.get(pointsKey)!;
                if (fullPossibleList.cmrs.length === 1)
                {
                    tbPlayer.possibleMissionCombinations = fullPossibleList;
                } else
                {
                    let possibleCombinations = pointsMap.get(pointsKey)!.cmrs.filter(pcs =>
                    {
                        return numberOfMissionsDone >= pcs.length;
                    });
                    if (possibleCombinations.length === fullPossibleList.cmrs.length)
                    {
                        tbPlayer.possibleMissionCombinations = new RewardCombinations(pointsEarned);
                        TbPlayerCalcs.addUniqueMissionCombinationsWaves(fullPossibleList.cmrs, tbPlayer);
                        // tbPlayer.possibleMissionCombinations = fullPossibleList;
                    } else
                    {
                        tbPlayer.possibleMissionCombinations = new RewardCombinations(pointsEarned);
                        tbPlayer.possibleMissionCombinations.cmrs = possibleCombinations;
                        TbPlayerCalcs.addUniqueMissionCombinationsWaves(possibleCombinations, tbPlayer);
                    }
                }

            } else if (pointsEarned !== 0)
            {
                //    console.error("Unable to determine point combination: " + pointsEarned.toLocaleString());
            }


            TbPlayerCalcs.addMissionColumnsWaves(uniqueUnitMissionsCount, tbPlayer, 'UNIT_');
            TbPlayerCalcs.addMissionColumnsWaves(uniqueFleetMissionsCount, tbPlayer, 'FLEET_');

        });

    }


    private static addMissionColumnsWaves(missionsCount: Map<string, number>, tbPlayer: IPlayerMissionCombination, columnPrefix: string)
    {
        let combatTypeCount: number = 0;
        Array.from(missionsCount.keys()).forEach((missionKey: string, index: number) =>
        {
            let count: number = missionsCount.get(missionKey)!;
            let playerCount: number = 0;
            if (tbPlayer.possibleMissionCombinations !== null && tbPlayer.possibleMissionCombinations.cmrs.length === 1)
            {
                let playerResults = tbPlayer.possibleMissionCombinations.cmrs[0].filter(cmr => cmr.missionRewardKey === missionKey && cmr.fleet === false);
                playerResults.sort((pr1, pr2) => pr1.wavesCompleted - pr2.wavesCompleted).forEach(pr =>
                {
                    tbPlayer.missionColumns.push(new MissionColumnResult('UNIT_' + (combatTypeCount + 1), pr.wavesCompleted));
                    combatTypeCount = combatTypeCount + 1;
                });
                playerCount = playerResults.length;
            }
            for (let x: number = playerCount; x < count; x++)
            {
                tbPlayer.missionColumns.push(new MissionColumnResult(columnPrefix + (combatTypeCount + 1), 0));
                combatTypeCount = combatTypeCount + 1;
            }
        });
    }

    public static calculatePossibleMissionCombinatations(
        playerCalcs: TerritoryBattlePlayerCalc[],
        fleetMissionCount: number,
        unitMissionCount: number,
        specialMissionCount: number,
        uniqueUnitMissionsCount: Map<string, number>,
        uniqueFleetMissionsCount: Map<string, number>,
        pointsMap: Map<number, RewardCombinations>)
    {

        playerCalcs.forEach(tbPlayer =>
        {
            let numberOfMissionsDone = tbPlayer.currentRoundCombatMissions;
            let pointsEarned = tbPlayer.currentRoundMissionPoints;
            let specialMissionsCompleted = tbPlayer.currentRoundSpecialMissions;

            tbPlayer.specialMissions = tbPlayer.specialMissionsConfirmed || specialMissionsCompleted === specialMissionCount;

            if (pointsMap.has(pointsEarned))
            {
                let fullPossibleList = pointsMap.get(pointsEarned)!;
                if (fullPossibleList.cmrs.length === 1)
                {
                    tbPlayer.possibleMissionCombinations = fullPossibleList;
                } else
                {
                    let possibleCombinations = pointsMap.get(pointsEarned)!.cmrs.filter(pcs =>
                    {
                        return numberOfMissionsDone >= pcs.length;
                    });
                    if (possibleCombinations.length === fullPossibleList.cmrs.length)
                    {
                        tbPlayer.possibleMissionCombinations = new RewardCombinations(pointsEarned);
                        TbPlayerCalcs.addUniqueMissionCombinations(fullPossibleList.cmrs, tbPlayer);
                        // tbPlayer.possibleMissionCombinations = fullPossibleList;
                    } else
                    {
                        tbPlayer.possibleMissionCombinations = new RewardCombinations(pointsEarned);
                        tbPlayer.possibleMissionCombinations.cmrs = possibleCombinations;
                        TbPlayerCalcs.addUniqueMissionCombinations(possibleCombinations, tbPlayer);
                    }
                }
                let smallestMissionCountPossible = Math.min(...tbPlayer.possibleMissionCombinations.cmrs.map(cmr => cmr.length));

                let possibleFailedMissionCount = numberOfMissionsDone - smallestMissionCountPossible;
                let unknownFailedMission = possibleFailedMissionCount > 1;

                tbPlayer.missionComboUncertain = tbPlayer.possibleMissionCombinations === undefined ? true :
                    (tbPlayer.possibleMissionCombinations.cmrs.length !== 1 || unknownFailedMission);

                if (numberOfMissionsDone === (fleetMissionCount + unitMissionCount))
                {
                    tbPlayer.missionsShips = true;
                    tbPlayer.missionsCharacters = true;
                } else
                {
                    let shipsPossiblyDone: boolean | undefined = undefined;
                    let shipsPossiblyNotDone: boolean | undefined = undefined;

                    let combatPossiblyDone: boolean | undefined = undefined;
                    let combatPossiblyNotDone: boolean | undefined = undefined;

                    tbPlayer.possibleMissionCombinations.cmrs.forEach(cmr =>
                    {
                        let combatMissionsCount = cmr.filter(cm => cm.fleet === false).length;
                        let shipMissionCount = cmr.filter(cm => cm.fleet === true).length;

                        if (combatMissionsCount === unitMissionCount)
                        {
                            combatPossiblyDone = true;
                        } else
                        {
                            combatPossiblyNotDone = true;
                        }
                        if (shipMissionCount === fleetMissionCount)
                        {
                            shipsPossiblyDone = true;
                        } else
                        {
                            shipsPossiblyNotDone = true;
                        }
                    });


                    tbPlayer.missionsShips = tbPlayer.missionsShipsConfirmed ||
                        (numberOfMissionsDone >= fleetMissionCount && shipsPossiblyDone === true && shipsPossiblyNotDone !== true);
                    tbPlayer.missionsCharacters = tbPlayer.missionsCharactersConfirmed ||
                        (numberOfMissionsDone >= unitMissionCount && combatPossiblyDone === true && combatPossiblyNotDone !== true);
                    tbPlayer.lowConfidenceShipsMissions = tbPlayer.missionsShipsConfirmed !== true &&
                        (numberOfMissionsDone >= fleetMissionCount && shipsPossiblyDone === true && shipsPossiblyNotDone === true);
                    tbPlayer.lowConfidenceCharacterMissions = tbPlayer.missionsShipsConfirmed !== true &&
                        (numberOfMissionsDone >= unitMissionCount && combatPossiblyDone === true && combatPossiblyNotDone === true);
                }


            } else if (pointsEarned !== 0)
            {
                console.error("Unable to determine point combination: " + tbPlayer.name + " " + pointsEarned.toLocaleString());
            }

            TbPlayerCalcs.addMissionColumns(uniqueUnitMissionsCount, tbPlayer, 'UNIT_');
            TbPlayerCalcs.addMissionColumns(uniqueFleetMissionsCount, tbPlayer, 'FLEET_');

        });
        console.log("complete");
    }

    private static addUniqueMissionCombinationsWaves(fullPossibleList: CombatMissionReward[][], tbPlayer: IPlayerMissionCombination)
    {
        for (let x = 0; x < fullPossibleList.length; x++)
        {
            let existing = tbPlayer.possibleMissionCombinations!.cmrs.find(cmr =>
            {
                let retVal = true;
                if (cmr.length === fullPossibleList[x].length)
                {
                    cmr.forEach(mrc =>
                    {
                        if (fullPossibleList[x].filter(fmrc => fmrc.same(mrc)).length !==
                            cmr.filter(fmrc => fmrc.same(mrc)).length)
                        {
                            retVal = false;
                        }
                    });
                }
                return cmr.length === fullPossibleList[x].length && retVal;
            });
            if (existing === undefined)
            {
                tbPlayer.possibleMissionCombinations!.cmrs.push(fullPossibleList[x]);
            }
        }
    }

    private static addUniqueMissionCombinations(fullPossibleList: CombatMissionReward[][], tbPlayer: TerritoryBattlePlayerCalc)
    {
        for (let x = 0; x < fullPossibleList.length; x++)
        {
            let existing = tbPlayer.possibleMissionCombinations!.cmrs.find(cmr =>
            {
                let retVal = true;
                if (cmr.length === fullPossibleList[x].length)
                {
                    cmr.forEach(mrc =>
                    {
                        if (fullPossibleList[x].filter(fmrc => fmrc.same(mrc)).length !==
                            cmr.filter(fmrc => fmrc.same(mrc)).length)
                        {
                            retVal = false;
                        }
                    });
                }
                return cmr.length === fullPossibleList[x].length && retVal;
            });
            if (existing === undefined)
            {
                tbPlayer.possibleMissionCombinations!.cmrs.push(fullPossibleList[x]);
            }
        }
    }

    private static addMissionColumns(missionsCount: Map<string, number>, tbPlayer: TerritoryBattlePlayerCalc, columnPrefix: string)
    {
        let combatTypeCount: number = 0;
        Array.from(missionsCount.keys()).forEach((missionKey: string, index: number) =>
        {
            let count: number = missionsCount.get(missionKey)!;
            let playerCount: number = 0;
            if (tbPlayer.possibleMissionCombinations !== null && tbPlayer.possibleMissionCombinations.cmrs.length === 1)
            {
                let playerResults = tbPlayer.possibleMissionCombinations.cmrs[0].filter(cmr => cmr.missionRewardKey === missionKey && cmr.fleet === false);
                playerResults.sort((pr1, pr2) => pr1.wavesCompleted - pr2.wavesCompleted).forEach(pr =>
                {
                    tbPlayer.missionColumns.push(new MissionColumnResult('UNIT_' + (combatTypeCount + 1), pr.wavesCompleted));
                    combatTypeCount = combatTypeCount + 1;
                });
                playerCount = playerResults.length;
            }
            for (let x: number = playerCount; x < count; x++)
            {
                tbPlayer.missionColumns.push(new MissionColumnResult(columnPrefix + (combatTypeCount + 1), 0));
                combatTypeCount = combatTypeCount + 1;
            }
        });
    }

    public static getPlayerValue(tbData: TerritoryBattleData, boardName: string, allyCode: number): number
    {
        let retVal: number = 0;
        let leaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === boardName);
        retVal = leaderBoard === undefined ? 0 : leaderBoard.getPlayerLeaderBoardScore(allyCode);
        return retVal;
    }

    public static calculatePlayers(tbData: TerritoryBattleData, tbGameData: TerritoryBattleGameData,
        playerSettings: TerritoryBattlePlayerStatusSetting[]): TerritoryBattlePlayerCalc[]
    {
        let retVal: TerritoryBattlePlayerCalc[] = [];

        let tbDef = tbGameData.getTerritoryBattleDefinition(tbData.definitionId);

        let currentRound = tbData.currentRound;

        let shipZone: TerritoryBattleZoneStatus | undefined = tbData.getOpenShipZone(tbDef);
        let combatZones: TerritoryBattleZoneStatus[] = tbData.getOpenMissionZones(tbDef);

        let phaseIncludedShips = shipZone !== undefined;
        let shipsClosed = shipZone === undefined ? true : shipZone.getZoneCompleted(tbDef);

        let territoryPointsLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "summary");
        let platoonMissionUnitsAssignedLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "unit_donated");
        let combatMissionWavesCompletedLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "strike_encounter");
        let rougeActionsLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "disobey");

        let currentRoundTerritoryPointsLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "summary_round_" + currentRound);
        let currentRoundGpDeployedLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "power_round_" + currentRound);
        let currentRoundCombatMissionsLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "strike_attempt_round_" + currentRound);
        let currentRoundSpecialMissionsLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "covert_attempt_round_" + currentRound);

        tbData.players.forEach(player =>
        {
            let tbPlayer = new TerritoryBattlePlayerCalc(player.name, player.allyCode);
            retVal.push(tbPlayer);
            tbPlayer.shipGP = player.shipGP;
            tbPlayer.characterGP = player.characterGP;

            let playerPreference = playerSettings.find(ps => ps.allyCode === player.allyCode);

            // TODO - these platoons may have been filled by the player last round "sandbagged"
            if (shipZone !== undefined)
            {
                tbPlayer.playerPlatoonedShipGp = TbPlayerCalcs.calculatePlayerPlatoons(shipZone.status.zoneId, player.allyCode, shipZone, tbDef, tbData);
            }
            combatZones.forEach(zone =>
            {
                tbPlayer.playerPlatoonedCombatGp = tbPlayer.playerPlatoonedCombatGp + TbPlayerCalcs.calculatePlayerPlatoons(zone.status.zoneId, player.allyCode, zone, tbDef, tbData);
            });

            tbPlayer.territoryPointsContributed = territoryPointsLeaderBoard === undefined ? 0 : territoryPointsLeaderBoard.getPlayerLeaderBoardScore(player.allyCode);
            tbPlayer.platoonMissionUnitsAssigned = platoonMissionUnitsAssignedLeaderBoard === undefined ? 0 : platoonMissionUnitsAssignedLeaderBoard.getPlayerLeaderBoardScore(player.allyCode);
            tbPlayer.combatMissionWavesCompleted = combatMissionWavesCompletedLeaderBoard === undefined ? 0 : combatMissionWavesCompletedLeaderBoard.getPlayerLeaderBoardScore(player.allyCode);
            tbPlayer.rogueActions = rougeActionsLeaderBoard === undefined ? 0 : rougeActionsLeaderBoard.getPlayerLeaderBoardScore(player.allyCode);
            tbPlayer.currentRoundTerritoryPoints = currentRoundTerritoryPointsLeaderBoard === undefined ? 0 : currentRoundTerritoryPointsLeaderBoard.getPlayerLeaderBoardScore(player.allyCode);
            tbPlayer.currentRoundCombatMissions = currentRoundCombatMissionsLeaderBoard === undefined ? 0 : currentRoundCombatMissionsLeaderBoard.getPlayerLeaderBoardScore(player.allyCode);

            // TODO - player may be getting platoon completion bonus here, so they may have l ess currentRoundGpDeployed then leaderboard says
            tbPlayer.currentRoundGpDeployed = currentRoundGpDeployedLeaderBoard === undefined ? 0 : currentRoundGpDeployedLeaderBoard.getPlayerLeaderBoardScore(player.allyCode);

            tbPlayer.currentRoundSpecialMissions = currentRoundSpecialMissionsLeaderBoard === undefined ? 0 : currentRoundSpecialMissionsLeaderBoard.getPlayerLeaderBoardScore(player.allyCode);
            tbPlayer.currentRoundMissionPoints = tbPlayer.currentRoundTerritoryPoints - tbPlayer.currentRoundGpDeployed;

            if (playerPreference !== undefined)
            {
                tbPlayer.missionsCharacters = playerPreference.completedCharacterMissions === true;
                tbPlayer.missionsCharactersConfirmed = playerPreference.completedCharacterMissions === true;
                tbPlayer.missionsShips = playerPreference.completedShipMissions === true;
                tbPlayer.missionsShipsConfirmed = playerPreference.completedShipMissions === true;

                tbPlayer.deployedCharacters = playerPreference.deployedCombatGp === true;
                tbPlayer.deployCombatConfirmed = playerPreference.deployedCombatGp === true;
                tbPlayer.deployedShips = playerPreference.deployedShipGp === true;
                tbPlayer.deployShipsConfirmed = playerPreference.deployedShipGp === true;

                tbPlayer.specialMissionsConfirmed = playerPreference.completedSpecialMissions === true;
            }


            let deployedEnoughForCharacters = (tbPlayer.currentRoundGpDeployed - tbPlayer.playerPlatoonedShipGp) - (tbPlayer.characterGP - TbPlayerCalcs.GP_WIGGLE) > 0;
            let deployedEnoughForShips = (tbPlayer.currentRoundGpDeployed - tbPlayer.playerPlatoonedCombatGp) - (tbPlayer.shipGP - TbPlayerCalcs.GP_WIGGLE) > 0;
            let deployedEnoughForBoth = tbPlayer.currentRoundGpDeployed - (tbPlayer.characterGP + tbPlayer.shipGP - TbPlayerCalcs.GP_WIGGLE) > 0;

            tbPlayer.deployedShips = false;
            tbPlayer.deployedCharacters = false;

            if (phaseIncludedShips === false)
            {
                tbPlayer.deployedCharacters = deployedEnoughForCharacters || (playerPreference !== undefined && playerPreference.deployedCombatGp === true);
                tbPlayer.minEstimatedCombatDeploymentRemaining = tbPlayer.characterGP - tbPlayer.currentRoundGpDeployed;
            } else
            {
                if (playerPreference !== undefined && playerPreference.deployedShipGp === true && playerPreference.deployedCombatGp === true)
                {
                    tbPlayer.deployedShips = true;
                    tbPlayer.deployedCharacters = true;

                    tbPlayer.deployShipsConfirmed = true;
                    tbPlayer.deployCombatConfirmed = true;
                } else if (playerPreference !== undefined && playerPreference.deployedShipGp === true)
                {
                    tbPlayer.deployedShips = true;
                    tbPlayer.deployedCharacters = deployedEnoughForBoth;
                    tbPlayer.deployShipsConfirmed = true;
                } else if (playerPreference !== undefined && playerPreference.deployedCombatGp === true)
                {
                    tbPlayer.deployedCharacters = true;
                    tbPlayer.deployedShips = deployedEnoughForBoth;
                    tbPlayer.deployCombatConfirmed = true;
                }
                else if (deployedEnoughForBoth)
                {
                    tbPlayer.deployedShips = true;
                    tbPlayer.deployedCharacters = true;
                } else if (deployedEnoughForShips && deployedEnoughForCharacters === false)
                {
                    tbPlayer.deployedShips = true;

                } else if (deployedEnoughForShips && deployedEnoughForCharacters && shipsClosed === false)
                {
                    // assume players deploy ships before they deploy character GP
                    tbPlayer.deployedShips = true;
                    tbPlayer.lowConfidence = true;
                } else if (deployedEnoughForCharacters === true)
                {
                    // this is the trickiest category, ships are close but they have deployed enough for either
                    tbPlayer.deployedCharacters = true;
                    tbPlayer.lowConfidence = true;
                }

                if (tbPlayer.deployedCharacters === true && tbPlayer.deployedShips === false)
                {
                    tbPlayer.minEstimatedShipDeploymentRemaining = (tbPlayer.characterGP + tbPlayer.shipGP) - tbPlayer.currentRoundGpDeployed;

                } else if (tbPlayer.deployedCharacters === false && tbPlayer.deployedShips === true)
                {
                    tbPlayer.minEstimatedCombatDeploymentRemaining = (tbPlayer.characterGP + tbPlayer.shipGP) - tbPlayer.currentRoundGpDeployed;
                } else if (tbPlayer.deployedCharacters === false && tbPlayer.deployedShips === false)
                {
                    tbPlayer.minEstimatedCombatDeploymentRemaining = TbPlayerCalcs.gtZero(tbPlayer.characterGP - tbPlayer.currentRoundGpDeployed);
                    tbPlayer.minEstimatedShipDeploymentRemaining = TbPlayerCalcs.gtZero(tbPlayer.shipGP - tbPlayer.currentRoundGpDeployed);

                    // player has deployed some GP - probably via missions, but we dont know if it was ship missions or combat missions
                    tbPlayer.unknownDeploymentPowerRemaining = tbPlayer.currentRoundGpDeployed;
                }

            }
        });
        return retVal;
    }

    private static gtZero(value: number): number
    {
        return value >= 0 ? value : 0;
    }

    private static calculatePlayerPlatoons(zoneId: string, allyCode: number, tbzStatus: TerritoryBattleZoneStatus,
        tbBattleDefintion: TerritoryBattleDefinition, tbData: TerritoryBattleData): any
    {
        let retVal: number = 0;

        let platoonZone = tbBattleDefintion.getReconZone(tbzStatus.status.zoneId);
        if (platoonZone !== undefined)
        {
            let platoonZoneStatus = tbData.getReconZoneStatus(platoonZone.zoneDefinition.zoneId);
            platoonZoneStatus.platoon.forEach(ps =>
            {
                ps.squad.forEach(ss =>
                {
                    ss.unit.forEach(unit =>
                    {
                        if (unit.allyCode === allyCode && unit.unitPower !== null)
                        {
                            retVal = retVal + unit.unitPower;
                        }
                    })
                })
            })
        }

        return retVal;
    }
}

export default TbPlayerCalcs;