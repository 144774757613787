import React from "react";
import { BasicLayout } from '../../../layouts/BasicLayout';
import IPageProps from "../../IPageProps";
import { observer } from "mobx-react";
import { TBController, WaveCombinationSelection } from "../TBController";
import { action, observable, runInAction } from "mobx";
import BaseAPI from "../../../service/BaseAPI";
import { Alert, Button, Spin } from "antd";
import TBHistorySelector, { ITBHistoryInstance } from "../../../components/swgoh/TBHistory/TBHistorySelector";
import TerritoryBattleData, { TerritoryBattlePlayer, TerritoryBattleZoneStatus } from "../../../model/TerritoryBattleData";
import TbPlayerCalcs, { IPlayerMissionCombination, MissionColumnResult } from "../tb-player-calcs";
import Table, { ColumnsType } from "antd/lib/table";
import styled from 'styled-components';
import TbStatusCalcs, { CombatMissionReward, CombatMissionsCombinations, RewardCombinations } from "../tb-status-calcs";
import localforage from "localforage";
import TerritoryBattleGameData from "../../../model/TerritoryBattleGameData";
import Modal from "antd/lib/modal/Modal";
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const StatusContainer = styled.div` 
    display: flex;
    flex-direction: column; 
    height: 100%;
`;


// let territoryPointsLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "summary");
// let platoonMissionUnitsAssignedLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "unit_donated");
// let combatMissionWavesCompletedLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "strike_encounter");
// let rougeActionsLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "disobey");

// let currentRoundTerritoryPointsLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "summary_round_" + currentRound);
// let currentRoundGpDeployedLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "power_round_" + currentRound);
// let currentRoundCombatMissionsLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "strike_attempt_round_" + currentRound);
// let currentRoundSpecialMissionsLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === "covert_attempt_round_" + currentRound);

interface UncertainValue
{
    certain: boolean;
    value: any;
}

interface CombatMissionResultColumn
{
    columnHeader: string;
    certain: boolean;
    value: number[] | undefined;
}

interface ISelectedDivProps
{
    className?: string;
    children: JSX.Element;
    selected: boolean;

}
const SelectedDivRenderer = ({ className, children }: ISelectedDivProps) =>
{
    return <div className={className} >
        {children}
    </div>;
}

const SelectedDiv = styled(SelectedDivRenderer)`
    border-style:  ${props => props.selected ? "solid" : undefined};
`;


interface ITBPlayerPhase extends IPlayerMissionCombination
{
    characterGp: number;
    shipGp: number;
    totalGp: number;
    territoryPoints: number;
    gpDeployed: number;
    combatMissionsCount: number;
    specialMissionsCount: number;

    totalPlatoonsAssigned: number;
    totalMissionWavesCompleted: number;
    totalRougeActions: number;
    totalTerritoryPoints: number;

    derivedRoundPlatoonsAssigned: number;
    derivedRoundMissionWavesCompleted: number;
    derivedRoundRougeActions: number;

    currentRoundMissionPoints: number;

    specialMissions: boolean; // output
    possibleMissionCombinations: RewardCombinations | null;
    missionColumns: MissionColumnResult[];

    cmrc: CombatMissionResultColumn[];
}


interface ITBPlayerData
{
    name: string;
    allyCode: number;

    platoonsAssigned: number;
    missionWavesCompleted: number;
    rougeActions: number;

    territoryPoints: number;
    phases: Map<number, ITBPlayerPhase>;
}

enum ColumnTypeEnum
{
    Text,
    Number,
    NumberOrUnknown
}

@observer
class TBData extends React.Component<IPageProps>
{
    static PERMISSION_KEY = "TERRITORY_BATTLES";
    static RISE_KEY = "t05D";


    @observable loadingTbData: boolean = false;
    @observable tbHistories: TerritoryBattleData[] = [];

    @observable tbPlayerData: ITBPlayerData[] = [];

    loadedTbHistories: Map<string, TerritoryBattleData> = new Map();

    @observable tableData: string[][] = [];
    @observable columnsType: ColumnTypeEnum[] = [];
    @observable tbGameData: TerritoryBattleGameData | null = null;
    @observable cmcs: Map<string, CombatMissionsCombinations> = new Map();
    @observable tbHistoryInstanceId: string | undefined = "";

    @observable showOptionsModal: boolean = false;
    @observable playerSelectedAllyCode: number | undefined = undefined;

    @observable userWaveSelectionMap: Map<string, WaveCombinationSelection[]> = new Map();

    @observable combatMissionPointCombinations: Map<string, Map<string, RewardCombinations>> = new Map();

    @action
    componentDidMount()
    {
        this.loadTbGameData(false);
    }
    @action
    private async loadTbGameData(refresh: boolean)
    {
        if (this.props.gameData.tbData.definitions.length === 0)
        {
            let tbgameDataJson: any | null = await localforage.getItem(TerritoryBattleGameData.TB_GAME_DATA_KEY);
            if (tbgameDataJson !== null)
            {
                let notNullJson = tbgameDataJson;
                try
                {
                    runInAction(() =>
                    {
                        this.props.gameData.tbDataFromJson(notNullJson);
                    });
                } catch (e)
                {
                    await localforage.setItem(TerritoryBattleGameData.TB_GAME_DATA_KEY, null);
                    console.error("Unable to unserialize tb game data");
                }
            }

            if (this.props.gameData.tbData.definitions.length === 0)
            {
                let gameDataJson = await BaseAPI.fetchTbGameData(this.props.user, this.props.gameData);
                this.props.gameData.tbDataFromJson(gameDataJson);
                await localforage.setItem(TerritoryBattleGameData.TB_GAME_DATA_KEY, gameDataJson);

            }

        }
        runInAction(() =>
        {
            this.tbGameData = this.props.gameData.tbData;
        });

        this.forceUpdate();
    }

    private async loadTbData()
    {
        try
        {
            await BaseAPI.fetchTbHistory(this.props.user);
        } catch (e)
        {
            console.log("error: " + e);
        }
    }

    generateTbRecordData()
    {

        let playerData: Map<number, ITBPlayerData> = new Map();

        this.tbHistories.forEach(tbHistory =>
        {
            let playersProcessedInPhase: number[] = [];
            tbHistory.players.forEach(player =>
            {
                let allyCode = player.allyCode;
                if (playersProcessedInPhase.indexOf(allyCode) === -1)
                {
                    playersProcessedInPhase.push(allyCode);
                    if (playerData.has(allyCode) === false)
                    {
                        playerData.set(allyCode, {
                            name: player.name,
                            allyCode: player.allyCode,
                            territoryPoints: 0,
                            platoonsAssigned: 0,
                            missionWavesCompleted: 0,
                            rougeActions: 0,
                            phases: new Map()
                        });
                    }
                    this.addPhaseData(playerData.get(allyCode)!, player, tbHistory);
                }
            });
        });

        Array.from(playerData.values()).forEach(playerData =>
        {
            this.calculateTotals(playerData);
        });
        this.tbPlayerData = Array.from(playerData.values());

        this.tbHistories.forEach(tbHistory =>
        {
            if (tbHistory.currentRound !== null)
            {

                let userSelections = this.userWaveSelectionMap.get(this.tbHistoryInstanceId!);
                let historyKey = TBData.createTbHistoryKey(tbHistory.instanceId, tbHistory.currentRound);

                if (this.combatMissionPointCombinations.has(historyKey) === false)
                {
                    let pointsMap: Map<string, RewardCombinations> = new Map();

                    let openZones: TerritoryBattleZoneStatus[] = tbHistory.getOpenZones();
                    let tbBattleDefintion = this.tbGameData!.getTerritoryBattleDefinition(tbHistory.definitionId);

                    let cmc = TbStatusCalcs.generateCms(openZones, tbBattleDefintion);
                    TbStatusCalcs.generatePointCombinationsWithWaves(cmc.cms, [], pointsMap);

                    this.cmcs.set(historyKey, cmc);
                    this.combatMissionPointCombinations.set(historyKey, pointsMap);
                }

                let playerCalcs: IPlayerMissionCombination[] = [];
                this.tbPlayerData.forEach(player =>
                {
                    if (player.phases.has(tbHistory.currentRound!))
                    {
                        playerCalcs.push(player.phases.get(tbHistory.currentRound!)!);
                    }
                })

                let cmc = this.cmcs.get(historyKey)!;
                let pointsMap = this.combatMissionPointCombinations.get(historyKey)!;

                TbPlayerCalcs.calculatePossibleMissionCombinatationsWaves(playerCalcs, cmc.uniqueUnitMissionsCount, cmc.uniqueFleetMissionsCount, pointsMap);

                this.generatePlayerPhases(tbHistory, userSelections, cmc);
            }
        });
    }

    private generatePlayerPhases(tbHistory: TerritoryBattleData, userSelections: WaveCombinationSelection[] | undefined, cmc: CombatMissionsCombinations)
    {
        this.tbPlayerData.forEach(player =>
        {
            if (player.phases.has(tbHistory.currentRound!))
            {
                let userSelection = userSelections === undefined ? undefined : userSelections.find(us => us.allyCode === player.allyCode && us.phase === tbHistory.currentRound!);


                let playPhase = player.phases.get(tbHistory.currentRound!)!;
                playPhase.cmrc = [];

                this.addMissionsCompleted(cmc.uniqueUnitMissionsCount, playPhase, userSelection, false, tbHistory, player);
                this.addMissionsCompleted(cmc.uniqueFleetMissionsCount, playPhase, userSelection, true, tbHistory, player);
            }
        });
    }

    private addMissionsCompleted(missionCounts: Map<string, number>, playPhase: ITBPlayerPhase, userSelection: WaveCombinationSelection | undefined,
        fleet: boolean, tbHistory: TerritoryBattleData, player: ITBPlayerData)
    {
        Array.from(missionCounts.keys()).forEach(missionKey =>
        {
            let numberOfMissionsWithThisReward = missionCounts.get(missionKey)!;

            let cmr: CombatMissionReward[] | undefined = undefined;

            let certain = false;
            if (playPhase.possibleMissionCombinations !== null && playPhase.possibleMissionCombinations.cmrs.length === 1)
            {
                cmr = playPhase.possibleMissionCombinations.cmrs[0].filter(cmr => cmr.fleet === fleet && cmr.missionRewardKey === missionKey);
                certain = true;
            } else if (userSelection !== undefined)
            {
                cmr = userSelection.rewards.filter(reward => reward.fleet === fleet && reward.missionRewardKey === missionKey);
                certain = false;
            }

            if (playPhase.totalMissionWavesCompleted === 0)
            {
                cmr = [];
                certain = true;
            }

            for (var x = 0; x < numberOfMissionsWithThisReward; x++)
            {
                let reward = cmr === undefined ? undefined : x >= cmr.length ? [0] : [cmr[x].wavesCompleted];

                if (playPhase.derivedRoundMissionWavesCompleted === 0)
                {
                    reward = [0];
                    certain = true;
                }
                if (reward === undefined)
                {
                    console.log("unable to find point combination for: " + player.name);
                    console.log("playPhase.totalMissionWavesCompleted: " + playPhase.totalMissionWavesCompleted);
                    console.log("playPhase.currentRoundMissionPoints: " + playPhase.currentRoundMissionPoints);
                }
                playPhase.cmrc.push({
                    columnHeader: TBData.createMissionNodeKey(tbHistory.currentRound!, fleet, missionKey, x),
                    certain: certain,
                    value: reward
                });
            }
        });
    }

    static createMissionNodeKey(phase: number, fleet: boolean, missionKey: string, instance: number)
    {
        if (fleet)
        {
            return "P" + phase + " Fleet " + missionKey + " [" + (instance + 1) + "]";
        } else
        {
            return "P" + phase + " Ground " + missionKey + " [" + (instance + 1) + "]";
        }

    }

    calculateTotals(playerData: ITBPlayerData)
    {
        Array.from(playerData.phases.keys()).forEach(phase =>
        {
            let phaseData = playerData.phases.get(phase)!;
            let previousPhase = playerData.phases.get(phase - 1);

            playerData.territoryPoints = playerData.territoryPoints > phaseData.totalTerritoryPoints ? playerData.territoryPoints : phaseData.totalTerritoryPoints;
            playerData.platoonsAssigned = playerData.platoonsAssigned > phaseData.totalPlatoonsAssigned ? playerData.platoonsAssigned : phaseData.totalPlatoonsAssigned;
            playerData.missionWavesCompleted = playerData.missionWavesCompleted > phaseData.totalMissionWavesCompleted ? playerData.missionWavesCompleted : phaseData.totalMissionWavesCompleted;
            playerData.rougeActions = playerData.rougeActions > phaseData.totalRougeActions ? playerData.rougeActions : phaseData.totalRougeActions;


            phaseData.derivedRoundMissionWavesCompleted = previousPhase === undefined ? phaseData.totalMissionWavesCompleted : phaseData.totalMissionWavesCompleted - previousPhase.totalMissionWavesCompleted;
            phaseData.derivedRoundPlatoonsAssigned = previousPhase === undefined ? phaseData.totalPlatoonsAssigned : phaseData.totalPlatoonsAssigned - previousPhase.totalPlatoonsAssigned;
            phaseData.derivedRoundRougeActions = previousPhase === undefined ? phaseData.totalRougeActions : phaseData.totalRougeActions - previousPhase.totalRougeActions;

            phaseData.currentRoundMissionPoints = phaseData.territoryPoints - phaseData.gpDeployed;


        });

    }

    addPhaseData(tbPlayerData: ITBPlayerData, tbPlayer: TerritoryBattlePlayer, tbBattleData: TerritoryBattleData)
    {
        if (tbBattleData.currentRound !== null)
        {
            tbPlayerData.phases.set(tbBattleData.currentRound, {
                characterGp: tbPlayer.characterGP,
                shipGp: tbPlayer.shipGP,
                totalGp: tbPlayer.shipGP + tbPlayer.characterGP,


                territoryPoints: TbPlayerCalcs.getPlayerValue(tbBattleData, "summary_round_" + tbBattleData.currentRound, tbPlayerData.allyCode),
                gpDeployed: TbPlayerCalcs.getPlayerValue(tbBattleData, "power_round_" + tbBattleData.currentRound, tbPlayerData.allyCode),
                combatMissionsCount: TbPlayerCalcs.getPlayerValue(tbBattleData, "strike_attempt_round_" + tbBattleData.currentRound, tbPlayerData.allyCode),
                specialMissionsCount: TbPlayerCalcs.getPlayerValue(tbBattleData, "covert_attempt_round_" + tbBattleData.currentRound, tbPlayerData.allyCode),

                totalTerritoryPoints: TbPlayerCalcs.getPlayerValue(tbBattleData, "summary", tbPlayerData.allyCode),

                totalPlatoonsAssigned: TbPlayerCalcs.getPlayerValue(tbBattleData, "unit_donated", tbPlayerData.allyCode),
                totalMissionWavesCompleted: TbPlayerCalcs.getPlayerValue(tbBattleData, "strike_encounter", tbPlayerData.allyCode),
                totalRougeActions: TbPlayerCalcs.getPlayerValue(tbBattleData, "disobey", tbPlayerData.allyCode),


                specialMissions: false, // output
                possibleMissionCombinations: null,
                missionColumns: [],

                derivedRoundPlatoonsAssigned: 0,
                derivedRoundMissionWavesCompleted: 0,
                derivedRoundRougeActions: 0,
                currentRoundMissionPoints: 0,
                cmrc: []

            });
        }
    }


    static createTbHistoryKey(instanceId: string, phase: number): string
    {
        return instanceId + "_" + phase;
    }


    async onSelectHistory(tbSelection: ITBHistoryInstance | null)
    {
        runInAction(() =>
        {
            this.loadingTbData = true;
            this.tbHistoryInstanceId = undefined;
        });

        let newTbHistories: TerritoryBattleData[] = [];
        if (tbSelection !== null && this.props.user.currentPlayer!.territoryBattleHistory !== null)
        {
            this.tbHistoryInstanceId = tbSelection.instanceId;

            if (this.userWaveSelectionMap.has(this.tbHistoryInstanceId) === false)
            {
                let userWaveSelectionString = await BaseAPI.getGuildSetting(this.props.user, TBController.TB_MISSION_WAVES_KEY + "_" + this.tbHistoryInstanceId!);

                if (userWaveSelectionString.length > 0)
                {
                    let userWaveSelection = JSON.parse(userWaveSelectionString[0].value);
                    runInAction(() =>
                    {
                        this.userWaveSelectionMap.set(this.tbHistoryInstanceId!, userWaveSelection);
                    });
                } else
                {
                    runInAction(() =>
                    {
                        this.userWaveSelectionMap.set(this.tbHistoryInstanceId!, []);
                    });
                }
            }

            for (var tbHistoryIndex = 0; tbHistoryIndex < this.props.user.currentPlayer!.territoryBattleHistory.length; tbHistoryIndex++)
            {
                let tbHistory = this.props.user.currentPlayer!.territoryBattleHistory[tbHistoryIndex];
                if (tbHistory.instanceId === tbSelection.instanceId)
                {
                    for (var phaseIndex = 0; phaseIndex < tbHistory.phase.length; phaseIndex++)
                    {
                        let phase = tbHistory.phase[phaseIndex];

                        let tbHistoryKey = TBData.createTbHistoryKey(tbHistory.instanceId, phase.phase);
                        if (this.loadedTbHistories.has(tbHistoryKey) === false)
                        {
                            let selection = await BaseAPI.fetchHistoricalTbData(this.props.user, tbHistory.instanceId, phase.phase);
                            this.loadedTbHistories.set(tbHistoryKey, selection);
                        }
                        const tbHist = this.loadedTbHistories.get(tbHistoryKey)!;

                        const def = this.tbGameData === null ? undefined : this.tbGameData.getTerritoryBattleDefinition(tbHist.definitionId);
                        const roundCount = def === undefined ? 0 : def.roundCount;

                        if (tbHist.currentRound !== null && roundCount >= tbHist.currentRound)
                        {
                            newTbHistories.push(this.loadedTbHistories.get(tbHistoryKey)!);
                        }
                    }
                }
            }

            runInAction(() =>
            {
                this.loadingTbData = false;
                this.tbHistories = newTbHistories;


                if (this.tbGameData !== null)
                {
                    if (this.tbHistories.length > 0 && this.tbHistories[0].definitionId !== TBData.RISE_KEY)
                    {
                        this.generateTbRecordData();
                        this.createTableData();
                    }

                }

            });
        }
    }

    addColumn(columnName: string, columnType: ColumnTypeEnum, rows: any[][], newColumnTypes: ColumnTypeEnum[], getValue: (player: ITBPlayerData) => any)
    {
        rows[0].push(columnName);
        newColumnTypes.push(columnType);

        this.tbPlayerData.forEach((player, index) =>
        {
            let value = getValue(player);
            rows[index + 1].push(value);
        })

    }

    addPhaseColumn(columnName: string, columnType: ColumnTypeEnum, rows: any[][], newColumnTypes: ColumnTypeEnum[], phases: number[], getValue: (player: ITBPlayerPhase) => any)
    {
        phases.forEach(phase =>
        {
            this.addColumn(columnName + phase, ColumnTypeEnum.Number, rows, newColumnTypes, (player) =>
            {
                let phaseData = player.phases.get(phase);
                return phaseData === undefined ? undefined : getValue(phaseData);
            });
        });
    }

    addPhaseColumns(columnName: string, columnType: ColumnTypeEnum, rows: any[][], newColumnTypes: ColumnTypeEnum[], phases: number[], getValues: { (player: ITBPlayerPhase): any; }[])
    {
        phases.forEach(phase =>
        {
            getValues.forEach(getValue =>
            {
                this.addColumn(columnName + phase, ColumnTypeEnum.Number, rows, newColumnTypes, (player) =>
                {
                    let phaseData = player.phases.get(phase);
                    return phaseData === undefined ? undefined : getValue(phaseData);
                });
            });
        });
    }

    createTableData()
    {
        let retVal: string[][] = [];
        let newColumnTypes: ColumnTypeEnum[] = [];

        let phases = this.getPhases();

        let headerRow: string[] = [];
        retVal.push(headerRow);
        this.tbPlayerData.forEach((player, index) =>
        {
            retVal.push([]);
        })

        this.addColumn("Name", ColumnTypeEnum.Text, retVal, newColumnTypes, (player) => player.name);
        this.addColumn("Ally Code", ColumnTypeEnum.Text, retVal, newColumnTypes, (player) => player.allyCode);
        this.addColumn("Territory Points Total", ColumnTypeEnum.Number, retVal, newColumnTypes, (player) => player.territoryPoints);
        this.addPhaseColumn("Territory Points Phase ", ColumnTypeEnum.Number, retVal, newColumnTypes, phases, (player) => player.territoryPoints);
        this.addPhaseColumn("Mission Attempts Phase ", ColumnTypeEnum.Number, retVal, newColumnTypes, phases, (player) => player.combatMissionsCount);
        this.addColumn("Mission Waves Total", ColumnTypeEnum.Number, retVal, newColumnTypes, (player) => player.missionWavesCompleted);
        this.addPhaseColumn("Mission Waves Phase ", ColumnTypeEnum.Number, retVal, newColumnTypes, phases, (player) => player.derivedRoundMissionWavesCompleted);

        phases.forEach(phase =>
        {
            let historyKey = TBData.createTbHistoryKey(this.tbHistoryInstanceId!, phase);

            let cmc = this.cmcs.get(historyKey)!;


            this.addMissionColumns(cmc.uniqueUnitMissionsCount, phase, false, retVal, newColumnTypes);
            this.addMissionColumns(cmc.uniqueFleetMissionsCount, phase, true, retVal, newColumnTypes);
        });


        this.addColumn("Platoons Assigned Total", ColumnTypeEnum.Number, retVal, newColumnTypes, (player) => player.platoonsAssigned);
        this.addPhaseColumn("Platoons Assigned Phase ", ColumnTypeEnum.Number, retVal, newColumnTypes, phases, (player) => player.derivedRoundPlatoonsAssigned);

        this.addColumn("Rogue Actions Total", ColumnTypeEnum.Number, retVal, newColumnTypes, (player) => player.rougeActions);
        this.addPhaseColumn("Rogue Actions Phase ", ColumnTypeEnum.Number, retVal, newColumnTypes, phases, (player) => player.derivedRoundRougeActions);


        this.tableData = retVal;
        this.columnsType = newColumnTypes;
    }

    private addMissionColumns(missionCounts: Map<string, number>, phase: number, fleet: boolean, retVal: string[][], newColumnTypes: ColumnTypeEnum[])
    {
        Array.from(missionCounts.keys()).forEach(missionKey =>
        {
            let numberOfMissionsWithThisReward = missionCounts.get(missionKey)!;
            for (var x = 0; x < numberOfMissionsWithThisReward; x++)
            {
                let columnKey = TBData.createMissionNodeKey(phase, fleet, missionKey, x);
                this.addColumn(columnKey, ColumnTypeEnum.NumberOrUnknown, retVal, newColumnTypes, (player) =>
                {
                    let columnVal: UncertainValue = {
                        certain: false,
                        value: undefined
                    };
                    let playerPhase = player.phases.get(phase);
                    if (playerPhase !== undefined)
                    {
                        let columnRewards = playerPhase.cmrc.find(cmr => cmr.columnHeader === columnKey);
                        if (columnRewards !== undefined && columnRewards.value !== undefined)
                        {
                            if (columnRewards.value.length > 0)
                            {
                                columnVal.value = columnRewards.value[0];
                                columnVal.certain = columnRewards.certain;
                            }

                        }
                    } else
                    {
                        columnVal.value = 0;
                        columnVal.certain = true;
                    }
                    return columnVal;
                });
            }
        });
    }

    private getPhases()
    {
        let retVal: number[] = [];
        this.tbPlayerData.forEach(tpd =>
        {
            Array.from(tpd.phases.keys()).forEach(phase =>
            {
                if (retVal.indexOf(phase) === -1)
                    retVal.push(phase);
            });
        });
        retVal = retVal.sort((a, b) => a - b);
        return retVal;
    }


    async selectMissionCombination(allyCode: number, phase: number, cmr: CombatMissionReward[])
    {
        runInAction(() =>
        {
            if (this.userWaveSelectionMap.has(this.tbHistoryInstanceId!) === false)
            {
                this.userWaveSelectionMap.set(this.tbHistoryInstanceId!, []);
            }
            let userSelections = this.userWaveSelectionMap.get(this.tbHistoryInstanceId!)!;
            userSelections = userSelections.filter(us => us.allyCode !== allyCode || us.phase !== phase);
            userSelections.push({
                allyCode: allyCode,
                phase: phase,
                rewards: cmr
            });
            this.userWaveSelectionMap.set(this.tbHistoryInstanceId!, userSelections);
            this.tbHistories.forEach(tbHistory =>
            {
                if (tbHistory.currentRound !== null)
                {

                    let historyKey = TBData.createTbHistoryKey(tbHistory.instanceId, tbHistory.currentRound);
                    let cmc: CombatMissionsCombinations = this.cmcs.get(historyKey)!;
                    this.generatePlayerPhases(tbHistory, this.userWaveSelectionMap.get(this.tbHistoryInstanceId!), cmc);
                }
            });
            this.createTableData();
        });

        await BaseAPI.setGuildSetting(this.props.user,
            TBController.TB_MISSION_WAVES_KEY + "_" + this.tbHistoryInstanceId!,
            JSON.stringify(this.userWaveSelectionMap.get(this.tbHistoryInstanceId!)), true);

        this.forceUpdate();
    }

    renderOptionalModal()
    {
        let phases = this.getPhases();
        let playerData = this.tbPlayerData.find(tbPlayer => tbPlayer.allyCode === this.playerSelectedAllyCode);
        let tabs: JSX.Element[] = [];

        let userSelections = this.userWaveSelectionMap.get(this.tbHistoryInstanceId!)!;

        if (playerData !== undefined)
        {
            phases.forEach(phase =>
            {
                if (playerData!.phases.has(phase))
                {
                    let playPhase = playerData!.phases.get(phase!)!;

                    if (playPhase.possibleMissionCombinations !== null && playPhase.possibleMissionCombinations.cmrs.length > 1)
                    {
                        let userSelection = userSelections === undefined ? undefined : userSelections.find(us => us.allyCode === this.playerSelectedAllyCode && us.phase === phase);

                        let missionCombinations = playPhase.possibleMissionCombinations.cmrs.map((cmr, index) =>
                        {
                            return <SelectedDiv selected={userSelection !== undefined && JSON.stringify(userSelection.rewards) === JSON.stringify(cmr)} key={index} >
                                <div onClick={() => this.selectMissionCombination(this.playerSelectedAllyCode!, phase, cmr)}>
                                    {playPhase.possibleMissionCombinations!.cmrs.length > 1 &&
                                        <div>
                                            Possiblity {index + 1}
                                        </div>
                                    }
                                    {
                                        cmr.map((cmr: CombatMissionReward, index: number) =>
                                        {
                                            return <div key={index} >
                                                {cmr.wavesCompleted} / {cmr.totalWavesPossible} {cmr.fleet ? 'Fleet' : 'Character'} {cmr.gpReward.toLocaleString()}
                                            </div>
                                        })
                                    }
                                </div>
                            </SelectedDiv>

                        });

                        tabs.push(
                            <TabPane tab={'Phase ' + phase} key={tabs.length}>
                                {missionCombinations}
                            </TabPane>
                        );
                    }
                }

            });
        }

        return <Modal title="Choose User" visible={this.showOptionsModal}
            maskClosable={false}
            cancelButtonProps={{ disabled: true, style: { display: 'none' } }}
            onCancel={action(() => this.showOptionsModal = false)}
            onOk={action(() => this.showOptionsModal = false)} >
            <div>

                <Tabs defaultActiveKey={'Phase 1'}>
                    {tabs}
                </Tabs>
            </div>
        </Modal>;
    }

    getCellText(record: any[], index: number, format: boolean = true)
    {
        let value = record[index];
        let columnType = this.columnsType[index];

        switch (columnType)
        {
            case ColumnTypeEnum.Text:
                return value;
            case ColumnTypeEnum.Number:
                return value === undefined ? "" : format ? Number(value).toLocaleString() : Number(value);
            case ColumnTypeEnum.NumberOrUnknown:
                return value.value === undefined || value.value === "" ? " ?" : format ? Number(value.value).toLocaleString() : Number(value.value);
        }
    }

    onExport()
    {
        let headerRow = this.tableData[0].join(",");

        let dataRows = this.tableData.slice(0);
        dataRows.shift();

        let csvContent = "data:text/csv;charset=utf-8,"
            + headerRow + "\n" + dataRows.map((record: any[]) =>
            {

                return record.map((cell, index) => this.getCellText(record, index, false)).join(",");
            }).join("\n");

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "tb_data.csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
    }

    render()
    {
        if (this.props.user.currentPlayer === null)
        {
            throw new Error("No current player selected");
        }

        if (this.tbGameData === null)
        {
            <BasicLayout {...this.props} requiredApiRights={[TBData.PERMISSION_KEY]} >
                <Spin size="small" />
            </BasicLayout>
        }

        let columns: ColumnsType<string[]> | undefined = undefined;

        if (this.tableData.length > 0)
        {
            columns = this.tableData[0].map((col, i) =>
            {

                return {
                    title: col,
                    dataIndex: i,
                    fixed: i === 0 ? 'left' : undefined,
                    sorter: (a: any, b: any) =>
                    {
                        let columnType = this.columnsType[i];
                        let aValue = a[i];
                        let bValue = b[i];

                        if (columnType === ColumnTypeEnum.Text)
                        {
                            aValue = aValue === undefined ? "" : aValue;
                            bValue = bValue === undefined ? "" : bValue;

                            return aValue.localeCompare(bValue);
                        } else if (columnType === ColumnTypeEnum.Number)
                        {
                            aValue = aValue === undefined || aValue === "" ? 0 : Number(aValue);
                            bValue = bValue === undefined || bValue === "" ? 0 : Number(bValue);
                            return aValue - bValue;
                        } else
                        {
                            aValue = aValue === undefined || aValue === "" ? -1 : Number(aValue.value);
                            bValue = bValue === undefined || bValue === "" ? -1 : Number(bValue.value);
                            return aValue - bValue;
                        }

                    },
                    render: (text: string, record: any[], index: number) =>
                    {
                        let columnType = this.columnsType[i];
                        let value = record[i];
                        let allyCode = Number(record[1]);
                        let cellText = this.getCellText(record, i);

                        switch (columnType)
                        {
                            case ColumnTypeEnum.Text:
                                return <div>{cellText}</div>
                            case ColumnTypeEnum.Number:
                                return <div>{cellText}</div>
                            case ColumnTypeEnum.NumberOrUnknown:
                                let themeColors = this.props.user.darkModeEnabled ? { green: '#3C8618', yellow: '#7C6E14' } : { green: '#7FFFD4', yellow: 'yellow' };
                                return {
                                    props: {
                                        style: {
                                            background: value.certain === false ? themeColors.yellow : undefined

                                        }
                                    },
                                    children:

                                        <div>
                                            {value.certain === false ?
                                                <div onClick={action(() => { this.playerSelectedAllyCode = allyCode; this.showOptionsModal = true })}>
                                                    {cellText}
                                                </div>
                                                :
                                                <div>
                                                    {cellText}
                                                </div>
                                            }
                                        </div>
                                }
                        }
                    }
                }
            });

        }


        let height = 400;
        let width: number | undefined = columns === undefined ? 0 : columns.length * 150;
        var elmnt = document.getElementById("player-container-id");
        if (elmnt !== null)
        {
            let visibleArea = elmnt.offsetHeight - 150;
            let visibleAreaWidth = elmnt.offsetWidth - 200;
            height = visibleArea > height ? visibleArea : height;
            width = visibleAreaWidth > width ? undefined : width;
        }

        let dataRows = this.tableData.slice(0);
        dataRows.shift();

        return <BasicLayout {...this.props} requiredApiRights={[TBData.PERMISSION_KEY]} >
            <React.Fragment>

                {this.renderOptionalModal()}
                <div>
                    <Alert message="Use TB -> Manage for the Rise TB" type="warning" />
                    <TBHistorySelector
                        gameData={this.props.gameData}
                        fullPhaseSelector={true}
                        user={this.props.user}
                        onSelectHistory={(tbHistorySelection) => this.onSelectHistory(tbHistorySelection)}>
                    </TBHistorySelector>
                    <Button disabled={this.loadingTbData || columns === undefined} onClick={() => this.onExport()}>Export</Button>
                </div>
                <StatusContainer id="player-container-id">

                    {this.loadingTbData ?
                        <React.Fragment>
                            <Spin size="small" />
                        </React.Fragment>
                        :
                        <React.Fragment>


                            {columns !== undefined &&
                                <Table
                                    dataSource={dataRows}
                                    columns={columns}
                                    pagination={false}
                                    scroll={{ y: height, x: width }}
                                    rowKey={record => record[1]} />
                            }

                        </React.Fragment>
                    }
                </StatusContainer>
            </React.Fragment>
        </BasicLayout>;

    }
}

export default TBData;
