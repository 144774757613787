import React from "react";
import {BasicLayout} from '../../../layouts/BasicLayout';
import IPageProps from "../../IPageProps";
import {observer} from "mobx-react";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import {observable, runInAction, toJS} from "mobx";
import header from "../../../components/Player/styles/Header.module.scss";
import styles from "../../TB/TBPlanning/styles/Templates.module.scss";
import {Cards} from "../../../components/Cards/Cards";
import twNewPlan from "../../../components/Cards/media/img/tw-new-plan_guetzli.jpg";
import twDefense from "../../../components/Cards/media/img/tw-planning_guetzli.jpg";
import twOffense from "../../../components/Cards/media/img/tw-offense_guetzli.jpg";
import BaseAPI from "../../../service/BaseAPI";
import {
    ITerritoryWarsArray,
    ITerritoryWarsData,
    ITerritoryWarsPlanning, squadsTW, zonesTW,
} from "../../../model/TWPlanning";
import {TWModal} from "./Modal/TWModal";
import {IGuildData} from "../../../model/GuildData";
import moment from "moment";
import TWTemplate from "./TWTemplate";
import {Alert} from "antd";
import TWAPI from "../../../service/TWAPI";
import {TWWarData} from "../../../model/TWData";

export interface ITWTemplatesOverviewProps extends IPageProps{
    templateId?: string;
}

const GUILD_TW_PLANNING_STRUCTURE = "guildTwPlanningTemplateStructure";

@observer
export default class TWTemplatesOverview extends React.Component<ITWTemplatesOverviewProps> {
    @observable.ref memberList: IGuildData | null = null;
    @observable showModal: boolean = false;
    @observable errorGuildFetch?: string = undefined;
    @observable twTemplates: ITerritoryWarsPlanning[] = [];
    @observable templateId: string | number | undefined = undefined;
    @observable isLoading: boolean = true;
    @observable isAdmin: boolean = false;
    @observable instanceId: string | null = null;
    @observable twZones: zonesTW[] | undefined = undefined;
    @observable twData: TWWarData | null = null;
    @observable refreshingTemplate: boolean = false;
    private _retry = 0;
    private _retrySettings = 0;

    getFullDataGuild() {
        return this.fetchMembersAllRoster().catch(() => this._retry = window.setTimeout(() => this.getFullDataGuild(), 2000));
    }

    componentDidMount() {
        this.refreshData(false);
        if(this.props.user.isAdminTW || this.props.user.isAdmin) {
            runInAction(() => this.isAdmin = true);
        }
    }

    async refreshData(refresh: boolean) {
        await this.getGuildDataTWTemplates();
        await this.getFullDataGuild();
        await this.fetchCurrentTW(refresh);
    }

    componentWillUnmount() {
        clearTimeout(this._retrySettings);
        clearTimeout(this._retry);
    }

    getGuildDataTWTemplates() {
        return this.fetchTWTemplates()
            .catch(e => {
                console.error(e);
                this._retrySettings = window.setTimeout(() => this.getGuildDataTWTemplates(), 2000);
            });
    }
    
    private async fetchMembersAllRoster(): Promise<void> {
        const response = await BaseAPI.getGuildList(this.props.user, true, true, undefined, parseInt(this.props.user.currentPlayer!.allyCode), 24);

        runInAction(() => {
            this.memberList = response;
        });
    }

    private async fetchCurrentTW(refresh: boolean): Promise<void> {
        let response = await TWAPI.get(this.props.user, refresh, false, undefined, undefined, undefined);

        if (!response.tw.away) {
            runInAction(() => this.instanceId = null);
        } else {
            runInAction(() => {
                this.instanceId = response.tw.instanceId;
                this.twData = response.tw
            });
        }
    }

    private async activatePlan(planId: string): Promise<void> {
        await TWAPI.setCurrentTWPlan(this.props.user, planId);
        runInAction(() => {
            if (this.twData)
                this.twData.planId = planId;
        });
    }

    private saveTWTemplate(name: string, twType: typeof ITerritoryWarsArray, allianceShared: boolean, squadsPerZone: number, description: string, excludedPlayersAllyCode: number[], preferredPlayers: number[], isDuplicate: boolean) {
        if ((this.props.templateId || this.templateId) && !isDuplicate) {
            this.updateTWTemplate(
                {categories: twType},
                {name: name, allianceShared: allianceShared, squadsPerZone: squadsPerZone, description: description, excludedPlayers: excludedPlayersAllyCode, preferredPlayers: preferredPlayers}
            );
        } else {
            this.addTWTemplate(name, twType, allianceShared, squadsPerZone, description, excludedPlayersAllyCode, preferredPlayers, isDuplicate);
        }
    }

    public static getZones(): zonesTW[] {
        const templateStructure: zonesTW[] = [];

        templateStructure.push(
            {zoneId: "tw_jakku01_phase01_conflict01", index: 1, id: 1, name: "Zone 1", combatType: "Character" },
            {zoneId: "tw_jakku01_phase01_conflict02", index: 2, id: 2, name: "Zone 2", combatType: "Character" },
            {zoneId: "tw_jakku01_phase02_conflict01", index: 3, id: 3, name: "Zone 3", combatType: "Character" },
            {zoneId: "tw_jakku01_phase02_conflict02", index: 4, id: 4, name: "Zone 4", combatType: "Character" },
            {zoneId: "tw_jakku01_phase03_conflict01", index: 5, id: 5, name: "Zone 5", combatType: "Ship" },
            {zoneId: "tw_jakku01_phase03_conflict02", index: 6, id: 6, name: "Zone 6", combatType: "Character" },
            {zoneId: "tw_jakku01_phase03_conflict03", index: 7, id: 7, name: "Zone 7", combatType: "Character" },
            {zoneId: "tw_jakku01_phase04_conflict01", index: 8, id: 8, name: "Zone 8", combatType: "Ship" },
            {zoneId: "tw_jakku01_phase04_conflict02", index: 9, id: 9, name: "Zone 9", combatType: "Character" },
            {zoneId: "tw_jakku01_phase04_conflict03", index: 10, id: 10, name: "Zone 10", combatType: "Character" }
        );

        return templateStructure;
    }

    private async addTWTemplate(name: string, twType: typeof ITerritoryWarsArray, allianceShared: boolean, squadsPerZone: number, description: string, excludedPlayersAllyCode: number[], preferredPlayers: number[], isDuplicate: boolean): Promise<void> {

        let templateSquads = this.twTemplates.find(x => x.id === this.templateId || x.id === this.props.templateId)?.data.squads;
        let templateZones = isDuplicate ? this.twTemplates.find(x => x.id === this.templateId || x.id === this.props.templateId)?.data.zones : undefined;

        if(!templateSquads) {
            templateSquads = [];
        }
        if(!templateZones) {
            if(this.twZones){
                templateZones = this.twZones;
            } else {
                templateZones = TWTemplatesOverview.getZones();
            }
        }
        const sameName = this.twTemplates.filter(x => x.data.name === name);
        const user = this.props.user.currentPlayer;
        const templateName = (isDuplicate && sameName.length > 0) ? (name + ` (${sameName.length + 1})`) : name;
        const templateData: ITerritoryWarsData = {
            name: templateName,
            creator: {
                playerName: user!.name,
                allyCode: Number(user!.allyCode),
                discordTag: user!.discordTag,
                createdUTC: moment.utc()
            },
            updator: {
                playerName: "",
                allyCode: 0,
                discordTag: "",
                createdUTC: ""
            },
            isCompactMode: false,
            allianceShared: allianceShared,
            squadsPerZone: squadsPerZone,
            description: description,
            excludedPlayers: excludedPlayersAllyCode,
            preferredPlayers: preferredPlayers,
            zones: templateZones,
            squads: isDuplicate ? templateSquads : []
        };

        await BaseAPI.setGuildSetting(this.props.user, GUILD_TW_PLANNING_STRUCTURE, JSON.stringify(templateZones), true);
        const insertedTWTemplate = await BaseAPI.setTWDataTemplates(this.props.user, twType, templateData);

        runInAction(() => {
            this.twTemplates = [...this.twTemplates, insertedTWTemplate];
            this.showModal = false;
            this.isLoading = false;
            if(this.props.templateId !== undefined){
                window.history.back();
            }
        })
    }

    private async updateTWTemplate(template: Partial<ITerritoryWarsPlanning>, data: Partial<ITerritoryWarsData>) {
        const templateId = template.id ?? this.props.templateId ?? this.templateId;
        const user = this.props.user.currentPlayer;

        const currentViewedTemplate = this.twTemplates.find(x => x.id === templateId);

        if (!currentViewedTemplate) {
            return;
        }

        const currentViewedTemplateObj = toJS(currentViewedTemplate);
        // console.log(currentViewedTemplateObj.data.zones);

        let viewedTemplate = {
            ...currentViewedTemplateObj,
            ...template,
            data: {
                ...currentViewedTemplateObj.data,
                ...data
            }
        };

        if(data.excludedPlayers){
            for (let i = 0; i < data.excludedPlayers.length; i++) {
                const allyCode = data.excludedPlayers[i];

                viewedTemplate.data.squads = viewedTemplate.data.squads.filter(x => x.allyCode !== allyCode);
            }
        }

        viewedTemplate.data.updator = {
            playerName: user!.name,
            allyCode: Number(user!.allyCode),
            discordTag: user!.discordTag,
            createdUTC: moment.utc()
        }

        let twTemplates = [...this.twTemplates];
        twTemplates[twTemplates.indexOf(currentViewedTemplate)] = toJS(viewedTemplate);

        runInAction(() => {
            this.twTemplates = twTemplates;
            this.twZones = viewedTemplate.data.zones;
            this.showModal = false;
            this.isLoading = false;
        });

        await BaseAPI.setGuildSetting(this.props.user, GUILD_TW_PLANNING_STRUCTURE, JSON.stringify(viewedTemplate.data.zones), true);
        await BaseAPI.setTWDataTemplates(this.props.user, viewedTemplate.categories, viewedTemplate.data, viewedTemplate.id);
    }

    private async deleteTWTemplate() {
        const templateId = this.props.templateId;

        if (!templateId) {
            return;
        }

        runInAction(() => {
            const TWTemplates = this.twTemplates.filter(template => template.id !== templateId);

            this.showModal = false;
            this.twTemplates = TWTemplates;

            BaseAPI.deleteTWDataTemplate(this.props.user, templateId);
        });
    }

    private async fetchTWTemplates(): Promise<void> {
        try {
            runInAction(() => this.refreshingTemplate = true)
            const settingsTW = await BaseAPI.getGuildSetting(this.props.user, GUILD_TW_PLANNING_STRUCTURE);
            const dataTW = await BaseAPI.getTWDataTemplates(this.props.user);

            runInAction(() => {
                this.twTemplates = dataTW;
                this.isLoading = false;
                this.refreshingTemplate = false;
                this.twZones = JSON.parse(settingsTW[0].value);
                // console.log('dataTW: ', toJS(dataTW));
                // console.log(toJS(this.twZones));
            });
        } catch (ex) {}
    }

    private renderModal() {
        if (!this.showModal) {
            return null;
        }

        if (!this.memberList) {
            return <LoadingSpinner size={"large"} spinning={true} text={'Loading the guild data...'}/>;
        }

        let viewedTemplate: ITerritoryWarsPlanning | undefined;

        if(this.templateId){
            viewedTemplate = this.twTemplates.find(template => template.id === this.templateId);
        } else {
            viewedTemplate = this.twTemplates.find(template => template.id === this.props.templateId);
        }

        return (
            <TWModal
                {...this.props}
                showModal={this.showModal}
                onClose={() => runInAction(() => this.showModal = false)}
                twTemplateData={viewedTemplate?.data}
                category={viewedTemplate?.categories ?? ['Defence']}
                onDelete={() => this.deleteTWTemplate()}
                saveHistoryTemplate={false}
                onSave={(name, twType, allianceShared, squadsPerZone, description, excludedPlayersAllyCode, preferredPlayers, isDuplicate) => {
                    runInAction(() => this.isLoading = true);
                    this.saveTWTemplate(name, twType, allianceShared, squadsPerZone, description, excludedPlayersAllyCode, preferredPlayers, isDuplicate);
                }}
                members={this.memberList.players}
            />
        );
    }

    render() {
        if (this.errorGuildFetch) {
            return (
                <BasicLayout {...this.props} requiredApiRights={["TERRITORY_WARS"]} extraRefresh={() => this.refreshData(true)}>
                    <LoadingSpinner size={"large"} error={true} spinning={false} text={this.errorGuildFetch}/>
                </BasicLayout>
            );
        }
        if (!this.props.user.currentPlayer || this.isLoading) {
            return <BasicLayout {...this.props} requiredApiRights={["TERRITORY_WARS"]} extraRefresh={() => this.refreshData(true)}>
                <LoadingSpinner size={"large"} spinning={true} text={"Loading.."}/>
            </BasicLayout>;
        }
        if (!this.memberList) {
            return  <BasicLayout {...this.props} extraRefresh={() => this.refreshData(true)} requiredApiRights={["TERRITORY_WARS"]}>
                <LoadingSpinner size={"large"} spinning={true} text={'Loading the guild data...'}/>
            </BasicLayout>;
        }

        if (this.props.templateId) {
            const viewedTemplate = this.twTemplates.slice().find(template => template.id === this.props.templateId);

            if (viewedTemplate) {
                return (
                    <BasicLayout {...this.props} requiredApiRights={["TERRITORY_WARS"]} extraRefresh={() => this.refreshData(true)}>
                        <TWTemplate
                            {...this.props}
                            templateId={this.props.templateId}
                            template={viewedTemplate.data}
                            refreshingTemplate={this.refreshingTemplate}
                            fetchTWTemplate={() => this.fetchTWTemplates()}
                            members={this.memberList.players}
                            category={viewedTemplate.categories.slice()}
                            guildName={this.memberList.guild.name}
                            guildId={this.memberList.guild.id}
                            isAdmin={this.isAdmin}
                            onClose={() => runInAction(() => window.history.back())}
                            onEditSettings={(templateId) => runInAction(() => {
                                this.showModal = true;
                                this.templateId = templateId;
                            })}
                            twData={this.twData}
                            guildTWSwttings={GUILD_TW_PLANNING_STRUCTURE}
                            planId={this.twData?.planId}
                            activatePlan={planId => this.activatePlan(planId)}
                            instanceId={this.instanceId}
                            updateZoneId={zonesTW => runInAction(() => {
                                viewedTemplate.data.zones = zonesTW;
                            })}
                            onSaveZone={(zoneId, index, id, newZoneId, zoneName, combatType) => {
                                runInAction(() => {
                                    let newZones = viewedTemplate.data.zones.slice();
                                    const oldZone = newZones.find(x => x.id === newZoneId);
                                    const idxOldZone = newZones.findIndex(x => x.id === id);
                                    const idxNewZone = newZones.findIndex(x => x.id === newZoneId);

                                    if(id !== newZoneId){
                                        [newZones[idxOldZone], newZones[idxNewZone]] = [newZones[idxNewZone], newZones[idxOldZone]];

                                        if(oldZone){
                                            newZones[idxOldZone] = {
                                                zoneId: oldZone.zoneId,
                                                index: oldZone.index,
                                                id: id,
                                                name: oldZone.name,
                                                combatType: oldZone.combatType
                                            }
                                        }
                                    }

                                    newZones[idxNewZone] = {
                                        zoneId: zoneId,
                                        index: index,
                                        id: newZoneId,
                                        name: zoneName,
                                        combatType: combatType
                                    }

                                    let squadsTW: squadsTW[] = [...viewedTemplate.data.squads];

                                    for (let i = 0; i < squadsTW.length; i++) {
                                        const squad = squadsTW[i];

                                        if(squad.zoneId === id) {
                                            squad.zoneId = newZoneId;
                                        } else if(squad.zoneId === newZoneId) {
                                            squad.zoneId = id;
                                        }
                                    }

                                    this.updateTWTemplate({}, {zones: newZones, squads: squadsTW})
                                })
                            }}
                            isCompactMode={viewedTemplate.data.isCompactMode}
                            compactMode={isCompactMode => this.updateTWTemplate({}, {isCompactMode})}
                            onSaveSquads={squadsTW => {
                                this.updateTWTemplate({}, {squads: squadsTW});
                            }}
                            onDelete={(zoneId, squadId) => {
                                runInAction(() => {
                                    let newSquads = [...viewedTemplate.data.squads];

                                    if(squadId === 'all'){
                                        newSquads = newSquads.filter(item => item.zoneId !== zoneId)
                                    } else {
                                        const index = newSquads.findIndex(item => item.squadId === squadId);

                                        newSquads.splice(index, 1);
                                    }

                                    this.updateTWTemplate({}, {squads: newSquads})
                                })
                            }}
                        />
                        {this.renderModal()}
                    </BasicLayout>
                );
            }
        }
        return (
            <BasicLayout {...this.props} requiredApiRights={["TERRITORY_WARS"]} extraRefresh={() => this.refreshData(true)}>
                <div className={header.header}>
                    <div className={header.left}>
                        <h1 className={header.title}>
                            Territory Wars Templates
                        </h1>
                    </div>
                </div>
                <div className={`${styles.container} ${styles.space15}`}>
                    {this.twTemplates.length === 0 && <Alert
                        message="Warning"
                        description="There is no TW templates created. Only a TW admin or a game officer can create one."
                        type="warning"
                        style={{marginBottom: "20px"}}
                        showIcon
                        closable
                    />}
                    <Cards
                        cardLink={`/tw/planning`}
                        addImage={twNewPlan}
                        viewImage={twDefense}
                        viewImage2={twOffense}
                        isAdmin={this.isAdmin}
                        toggleModal={() => runInAction(() => {
                            this.templateId = undefined;
                            this.showModal = !this.showModal;
                        })}
                        cardsTemplate={undefined}
                        twCardsTemplate={this.twTemplates}
                        editTemplate={idx => {
                            runInAction(() => {
                                this.showModal = true;
                                this.templateId = idx;
                            });
                        }}
                        deleteTemplate={idx => runInAction(() => {
                            const twTemplate = this.twTemplates.find(template => template.id === idx.toString());
                            const TWTemplates = this.twTemplates.filter(template => template.id !== idx.toString());

                            if(twTemplate) {
                                BaseAPI.deleteTWDataTemplate(this.props.user, twTemplate.id);
                            }
                            runInAction(() => this.twTemplates = TWTemplates);
                        })}
                    />
                    {this.renderModal()}
                </div>

            </BasicLayout>
        );
    }
}