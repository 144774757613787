import React from "react";
import { BasicLayout } from '../../../layouts/BasicLayout';
import IPageProps from "../../IPageProps";
import { observer } from "mobx-react";
import styled from 'styled-components';
import { action, observable, runInAction } from "mobx";
import BaseAPI from './../../../service/BaseAPI';
import TerritoryBattleData from './../../../model/TerritoryBattleData';
import { TerritoryBattleGameData } from '../../../model/TerritoryBattleGameData';
import { Table, Button, Menu, Dropdown, Alert } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import TerritoryBattleStatusPreferences, { TerritoryBattlePlayerStatusSetting } from "../../../model/TerritoryBattleStatusPreferences";
import { CheckSquareOutlined, BorderOutlined, CheckSquareFilled, CloudSyncOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Tooltip } from 'antd';
import { ColumnsType } from "antd/lib/table";
import TbPlayerCalcs from "../tb-player-calcs";
import { TerritoryBattlePlayerCalc } from '../tb-player-calcs';
import TbStatusCalcs, { TbStatusCalculationResults } from "../tb-status-calcs";
import localforage from "localforage";


import { CombatMissionReward } from '../tb-status-calcs';
import TBPlayersMessage from './tb-players-message';
import TbPlayersConfigColumns from './tb-players-config-columns';
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import TBHistorySelector from "../../../components/swgoh/TBHistory/TBHistorySelector";
import { TBHistorySelection } from '../../../model/TerritoryBattleStatusPreferences';
import { ITBHistoryInstance } from '../../../components/swgoh/TBHistory/TBHistorySelector';
import { TBController } from '../TBController';

const StatusContainer = styled.div` 
    display: flex;
    flex-direction: column; 
    height: 100%;
`;

const Toolbar = styled.div` 
    padding: 5px;
    padding-bottom: 15px;
`;

const ToolbarButton = styled(Button)` 
    margin: 5px;
`;

const MissionsContainer = styled.div` 
    padding: 30px;
`;

const ToolbarDropDownButton = styled(Dropdown.Button)` 
    margin: 5px;
`;

interface IDeploymentCellProps
{
    className?: string;
    confirmed?: boolean;
    checked: boolean;
    onClick?: (confirm: boolean | undefined) => void;

}
const DeploymentCellRenderer = ({ className, checked, confirmed, onClick }: IDeploymentCellProps) =>
{
    let checkedValue = checked ? confirmed === true ? undefined : false : true;

    return checked ?
        confirmed === true ? <CheckSquareFilled className={className} onClick={() => { if (onClick) onClick(checkedValue) }} /> :
            <CheckSquareOutlined className={className} onClick={() => { if (onClick) onClick(checkedValue) }} />
        :
        <BorderOutlined className={className} onClick={() => { if (onClick) onClick(checkedValue) }} />;
}

const DeploymentCell = styled(DeploymentCellRenderer)`
    font-weight:  ${props => props.confirmed === true ? "bold" : undefined};
`;

interface IViewButtonTextProps
{
    className?: string;
    selected: boolean;
    description: string;
}

const ViewButtonTextRenderer = ({ className, description }: IViewButtonTextProps) =>
{
    return <span className={className} >
        {description}
    </span>;
}

const ViewButtonText = styled(ViewButtonTextRenderer)`
    font-weight:  ${props => props.selected ? "bold" : undefined};
`;

export enum TableViewType
{
    Deployments = 1,
    Missions
}

class PendingPlayerUpdate
{
    allyCode: number;

    confirmShipDeployment: boolean | undefined;
    confirmCombatDeployment: boolean | undefined;

    confirmShipMissions: boolean | undefined;
    confirmCombatMissions: boolean | undefined;

    confirmSpecialMissions: boolean | undefined;

    constructor(allyCode: number)
    {
        this.allyCode = allyCode;
    }
}


@observer
class TBPlayers extends React.Component<IPageProps>
{

    static SELECTED_COLUMNS_KEY = "tb_player_table_columns";
    static PERMISSION_KEY = "TERRITORY_BATTLES";

    static RISE_KEY = "t05D";
    @observable useSimulatedData: boolean = false;

    @observable tbGameData: TerritoryBattleGameData | null = null;

    get tbData(): TerritoryBattleData | null
    {
        return this.tbHistorySelection === null ? null : this.tbHistorySelection.tbData;
    }

    get tbStatusPreferences(): TerritoryBattleStatusPreferences | null
    {
        return this.tbHistorySelection === null ? null : this.tbHistorySelection.tbsp;
    }

    @observable errorMessage: string | undefined = undefined;

    @observable viewType: TableViewType = TableViewType.Deployments;

    @observable pendingUpdates: PendingPlayerUpdate[] = [];

    @observable deployedCharactersFilter: string[] = [];
    @observable deployedShipsFilter: string[] = [];
    @observable missionsCharactersFilter: string[] = [];
    @observable missionsShipsFilter: string[] = [];
    @observable missionsSpecialFilter: string[] = [];

    @observable visibleColumns: ColumnsType<TerritoryBattlePlayerCalc> = [];
    @observable tableExpandable: any = undefined;

    @observable players: TerritoryBattlePlayerCalc[] = [];
    @observable terrCalcs: TbStatusCalculationResults | null = null;

    @observable selectedDeploymentColumns: Map<string, boolean> = new Map();

    @observable tbHistorySelection: TBHistorySelection | null = null;
    @observable loadingTbData: boolean = false;


    deploymentColumns: ColumnsType<TerritoryBattlePlayerCalc> = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
            key: 'name',
            fixed: 'left'
        },
        {
            title: 'Character GP',
            dataIndex: 'characterGP',
            sorter: (a: any, b: any) => a.characterGP - b.characterGP,
            key: 'characterGP',
            render: (text: number) => text.toLocaleString(),
        },
        {
            title: 'Ship GP',
            dataIndex: 'shipGP',
            sorter: (a: any, b: any) => a.shipGP - b.shipGP,
            key: 'shipGP',
            render: (text: number) => text.toLocaleString(),
        },
        {
            title: 'Deployed GP',
            dataIndex: 'currentRoundGpDeployed',
            sorter: (a: any, b: any) => a.currentRoundGpDeployed - b.currentRoundGpDeployed,
            key: 'currentRoundGpDeployed',
            render: (text: number) => text.toLocaleString()
        },
        {
            title: 'Remaining GP',
            dataIndex: 'currentRoundGpDeployedRemaining',
            sorter: (a: any, b: any) => a.currentRoundGpDeployedRemaining - b.currentRoundGpDeployedRemaining,
            key: 'currentRoundGpDeployedRemaining',
            render: (text: number) => text.toLocaleString()
        },
        {
            title: 'Deployed Characters',
            dataIndex: 'deployedCharacters',
            sorter: (a: any, b: any) => a.deployedCharacters - b.deployedCharacters,
            key: 'deployedCharacters',
            filters: [
                {
                    text: 'true',
                    value: true,
                },
                {
                    text: 'false',
                    value: false,
                },
            ],
            filteredValue: this.deployedCharactersFilter,
            filterMultiple: false,
            onFilter: (value: any, record: any) =>
            {
                if (value === "uncertain")
                {
                    return record.lowConfidence;
                }
                return record.deployedCharacters === value
            },
            render: (text: boolean, record: TerritoryBattlePlayerCalc, index: number) =>
            {
                let newValue = record.deployCombatConfirmed ? undefined : true;
                let themeColors = this.props.user.darkModeEnabled ? { green: '#3C8618', yellow: '#7C6E14' } : { green: '#7FFFD4', yellow: 'yellow' };

                return {
                    props: {
                        style: {
                            background: record.lowConfidence ? themeColors.yellow : text ? themeColors.green : undefined
                        }
                    },
                    children:
                        <DeploymentCell confirmed={record.deployCombatConfirmed} checked={text}
                            onClick={(value) =>
                                this.clickConfirm(record.allyCode, newValue,
                                    (ppu: PendingPlayerUpdate, fvalue: boolean | undefined) => ppu.confirmCombatDeployment = fvalue,
                                    (pss: TerritoryBattlePlayerStatusSetting, fvalue: boolean | undefined) => pss.deployedCombatGp = fvalue)}></DeploymentCell>
                }
            }
        },
        {
            title: 'Deployed Ships',
            dataIndex: 'deployedShips',
            sorter: (a: any, b: any) => a.deployedShips - b.deployedShips,
            key: 'deployedShips',
            filters: [
                {
                    text: 'true',
                    value: true,
                },
                {
                    text: 'false',
                    value: false,
                },
            ],
            filterMultiple: false,
            filteredValue: this.deployedShipsFilter,
            onFilter: (value: any, record: any) => record.deployedShips === value,
            render: (text: boolean, record: TerritoryBattlePlayerCalc, index: number) =>
            {
                let newValue = record.deployShipsConfirmed ? undefined : true;
                let themeColors = this.props.user.darkModeEnabled ? { green: '#3C8618', yellow: '#7C6E14' } : { green: '#7FFFD4', yellow: 'yellow' };

                return {
                    props: {
                        style: {
                            background: record.lowConfidence ? themeColors.yellow : text ? themeColors.green : undefined
                        }
                    },
                    children:
                        <DeploymentCell confirmed={record.deployShipsConfirmed} checked={text}
                            onClick={(value) =>
                                this.clickConfirm(record.allyCode, newValue,
                                    (ppu: PendingPlayerUpdate, fvalue: boolean | undefined) => ppu.confirmShipDeployment = fvalue,
                                    (pss: TerritoryBattlePlayerStatusSetting, fvalue: boolean | undefined) => pss.deployedShipGp = fvalue)}></DeploymentCell>


                }
            }
        },
        {
            title: 'Fleet GP',
            dataIndex: 'minEstimatedShipDeploymentRemaining',
            sorter: (a: any, b: any) => a.minEstimatedShipDeploymentRemaining - b.minEstimatedShipDeploymentRemaining,
            key: 'minEstimatedShipDeploymentRemaining',
            render: (text: number) => text.toLocaleString()
        },
        {
            title: 'Character GP',
            dataIndex: 'minEstimatedCombatDeploymentRemaining',
            sorter: (a: any, b: any) => a.minEstimatedCombatDeploymentRemaining - b.minEstimatedCombatDeploymentRemaining,
            key: 'minEstimatedCombatDeploymentRemaining',
            render: (text: number) => text.toLocaleString()
        },
        {
            title: 'Unknown GP',
            dataIndex: 'unknownDeploymentPowerRemaining',
            sorter: (a: any, b: any) => a.unknownDeploymentPowerRemaining - b.unknownDeploymentPowerRemaining,
            key: 'unknownDeploymentPowerRemaining',
            render: (text: number) => text.toLocaleString()
        }
    ];

    missionColumns: ColumnsType<TerritoryBattlePlayerCalc> = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
            fixed: 'left',
            key: 'name',
            render: (text: string, record: TerritoryBattlePlayerCalc, index: number) =>
            {
                let themeColors = this.props.user.darkModeEnabled ? { yellow: '#7C6E14' } : { yellow: 'yellow' };

                return {
                    props: {
                        style: {
                            background: record.possibleMissionCombinations !== null && record.possibleMissionCombinations.cmrs.length > 1 ? themeColors.yellow : undefined
                        }
                    },
                    children:
                        <div>{text}</div>
                }
            }
        },
        {
            title: 'Mission Waves (all rounds)',
            dataIndex: 'combatMissionWavesCompleted',
            sorter: (a: any, b: any) => a.combatMissionWavesCompleted - b.combatMissionWavesCompleted,
            key: 'combatMissionWavesCompleted',
            render: (text: number) => text.toLocaleString()
        },
        {
            title: 'Mission Points',
            dataIndex: 'currentRoundMissionPoints',
            sorter: (a: any, b: any) => a.currentRoundMissionPoints - b.currentRoundMissionPoints,
            key: 'currentRoundMissionPoints',
            render: (text: number) => text.toLocaleString()
        },
        {
            title: 'Missions',
            dataIndex: 'currentRoundCombatMissions',
            sorter: (a: any, b: any) => a.currentRoundCombatMissions - b.currentRoundCombatMissions,
            key: 'currentRoundCombatMissions',
            render: (text: number) => text.toLocaleString()
        },
        {
            title: 'Special',
            dataIndex: 'currentRoundSpecialMissions',
            sorter: (a: any, b: any) => a.currentRoundSpecialMissions - b.currentRoundSpecialMissions,
            key: 'currentRoundSpecialMissions',
            render: (text: number) => text.toLocaleString()
        },
        {
            title: 'Special Complete',
            dataIndex: 'specialMissions',
            sorter: (a: any, b: any) => a.specialMissions - b.specialMissions,
            key: 'specialMissions',
            filters: [
                {
                    text: 'true',
                    value: true,
                },
                {
                    text: 'false',
                    value: false,
                },
            ],
            filteredValue: this.missionsSpecialFilter,
            filterMultiple: false,
            onFilter: (value: any, record: any) => record.specialMissions === value,
            render: (text: boolean, record: TerritoryBattlePlayerCalc, index: number) =>
            {
                let newValue = record.specialMissionsConfirmed ? undefined : true;
                let themeColors = this.props.user.darkModeEnabled ? { green: '#3C8618' } : { green: '#7FFFD4' };

                return {
                    props: {
                        style: {
                            background: text ? themeColors.green : undefined
                        }
                    },
                    children:
                        <DeploymentCell confirmed={record.specialMissionsConfirmed} checked={text}
                            onClick={(value) =>
                                this.clickConfirm(record.allyCode, newValue,
                                    (ppu: PendingPlayerUpdate, fvalue: boolean | undefined) => ppu.confirmSpecialMissions = fvalue,
                                    (pss: TerritoryBattlePlayerStatusSetting, fvalue: boolean | undefined) => pss.completedSpecialMissions = fvalue)}></DeploymentCell>
                }
            }
        },
        {
            title: 'Characters Complete',
            dataIndex: 'missionsCharacters',
            sorter: (a: any, b: any) => a.missionsCharacters - b.missionsCharacters,
            key: 'missionsCharacters',
            filters: [
                {
                    text: 'true',
                    value: true,
                },
                {
                    text: 'false',
                    value: false,
                },
            ],
            filteredValue: this.missionsCharactersFilter,
            filterMultiple: false,
            onFilter: (value: any, record: any) => record.missionsCharacters === value,
            render: (text: boolean, record: TerritoryBattlePlayerCalc, index: number) =>
            {
                let newValue = record.missionsCharactersConfirmed ? undefined : true;
                let themeColors = this.props.user.darkModeEnabled ? { green: '#3C8618', yellow: '#7C6E14' } : { green: '#7FFFD4', yellow: 'yellow' };

                return {
                    props: {
                        style: {
                            background: record.lowConfidenceCharacterMissions ? themeColors.yellow : text ? themeColors.green : undefined
                        }
                    },
                    children:
                        <DeploymentCell confirmed={record.missionsCharactersConfirmed} checked={text}
                            onClick={(value) =>
                                this.clickConfirm(record.allyCode, newValue,
                                    (ppu: PendingPlayerUpdate, fvalue: boolean | undefined) => ppu.confirmCombatMissions = fvalue,
                                    (pss: TerritoryBattlePlayerStatusSetting, fvalue: boolean | undefined) => pss.completedCharacterMissions = fvalue)}></DeploymentCell>
                }
            }
        },
        {
            title: 'Ships Complete',
            dataIndex: 'missionsShips',
            sorter: (a: any, b: any) => a.missionsShips - b.missionsShips,
            key: 'missionsShips',
            filters: [
                {
                    text: 'true',
                    value: true,
                },
                {
                    text: 'false',
                    value: false,
                },
            ],
            filterMultiple: false,
            filteredValue: this.missionsShipsFilter,
            onFilter: (value: any, record: any) => record.missionsShips === value,
            render: (text: boolean, record: TerritoryBattlePlayerCalc, index: number) =>
            {
                let newValue = record.missionsShipsConfirmed ? undefined : true;
                let themeColors = this.props.user.darkModeEnabled ? { green: '#3C8618', yellow: '#7C6E14' } : { green: '#7FFFD4', yellow: 'yellow' };

                return {
                    props: {
                        style: {

                            background: record.lowConfidenceShipsMissions ? themeColors.yellow : text ? themeColors.green : undefined
                        }
                    },
                    children:
                        <DeploymentCell confirmed={record.missionsShipsConfirmed} checked={text}
                            onClick={(value) =>
                                this.clickConfirm(record.allyCode, newValue,
                                    (ppu: PendingPlayerUpdate, fvalue: boolean | undefined) => ppu.confirmShipMissions = fvalue,
                                    (pss: TerritoryBattlePlayerStatusSetting, fvalue: boolean | undefined) => pss.completedShipMissions = fvalue)}></DeploymentCell>
                }
            }
        }
    ];

    missionColumnsWithSpecificMissions: ColumnsType<TerritoryBattlePlayerCalc> = [];

    @action
    componentDidMount()
    {
        if (this.checkApiRightsList([TBPlayers.PERMISSION_KEY]))
        {
            this.loadTbGameData(false);

            let selectedColumns = localStorage.getItem(TBPlayers.SELECTED_COLUMNS_KEY);
            if (selectedColumns !== null)
            {
                let colArray = selectedColumns.split(",")
                colArray.forEach(col => this.selectedDeploymentColumns.set(col, true));
                this.deploymentColumns.forEach(col => this.selectedDeploymentColumns.set(col.key!.toString(), colArray.indexOf(col.key!.toString()) !== -1));
                this.missionColumns.forEach(col => this.selectedDeploymentColumns.set(col.key!.toString(), colArray.indexOf(col.key!.toString()) !== -1));
            } else
            {
                this.deploymentColumns.forEach(col => this.selectedDeploymentColumns.set(col.key!.toString(), true));
                this.missionColumns.forEach(col => this.selectedDeploymentColumns.set(col.key!.toString(), true));
            }

            window.addEventListener("resize", () => this.forceUpdate());
            this.loadTbData(false);
        }
    }


    private checkApiRightsList(requiredApiRights: string[] | undefined)
    {
        if (!requiredApiRights)
            return true;
        if (!this.props.user || !this.props.user.apiRights)
            return false;

        for (let r of requiredApiRights)
        {
            if (!this.props.user.apiRights.includes(r))
                return false;
        }
        return true;
    }

    @action
    private async loadTbData(refresh: boolean)
    {
        await TBController.generateCurrentTbHistorySelection(this.props.user!, this.props.gameData.tbData, refresh);
        if (this.tbHistorySelection !== null)
        {
            await this.onSelectHistory({
                instanceId: this.tbHistorySelection.tbData.instanceId,
                phase: this.tbHistorySelection.tbData.currentRound!
            });
        }
        this.forceUpdate();
    }

    @action
    private async loadTbGameData(refresh: boolean)
    {
        if (this.props.gameData.tbData.definitions.length === 0)
        {
            let tbgameDataJson: any | null = await localforage.getItem(TerritoryBattleGameData.TB_GAME_DATA_KEY);
            if (tbgameDataJson !== null)
            {
                let notNullJson = tbgameDataJson;
                try
                {
                    runInAction(() =>
                    {
                        this.props.gameData.tbDataFromJson(notNullJson);
                    });
                } catch (e)
                {
                    await localforage.setItem(TerritoryBattleGameData.TB_GAME_DATA_KEY, null);
                    console.error("Unable to unserialize tb game data");
                }
            }

            if (this.props.gameData.tbData.definitions.length === 0)
            {
                let gameDataJson = await BaseAPI.fetchTbGameData(this.props.user, this.props.gameData);
                this.props.gameData.tbDataFromJson(gameDataJson);
                await localforage.setItem(TerritoryBattleGameData.TB_GAME_DATA_KEY, gameDataJson);

            }
            runInAction(() =>
            {
                this.tbGameData = this.props.gameData.tbData;
            });
            await TBController.generateCurrentTbHistorySelection(this.props.user, this.tbGameData!, refresh);
            this.forceUpdate();
        }
    }

    recalculate()
    {
        if (this.tbData!.definitionId === TBPlayers.RISE_KEY)
        {
            return;
        }
        this.players = TbPlayerCalcs.calculatePlayers(this.tbData!, this.props.gameData.tbData, this.tbStatusPreferences!.players);
        this.terrCalcs = TbStatusCalcs.calculateTbStatus(this.tbData!,
            this.props.gameData.tbData, this.tbStatusPreferences!, this.players, []);

        TbPlayerCalcs.calculatePossibleMissionCombinatations(this.players, this.terrCalcs.fleetMissionsCount, this.terrCalcs.unitsMissionsCount,
            this.terrCalcs.fleetSpecialMissionCount + this.terrCalcs.combatSpecialMissionCount,
            this.terrCalcs.uniqueUnitMissionsCount,
            this.terrCalcs.uniqueFleetMissionsCount,
            this.terrCalcs.pointsMap);

        this.missionColumnsWithSpecificMissions = this.missionColumns.slice();
        this.addCombatMissionColumns(this.terrCalcs!.uniqueUnitMissionsCount, 'Ch ', this.missionColumnsWithSpecificMissions, false);
        this.addCombatMissionColumns(this.terrCalcs!.uniqueFleetMissionsCount, 'Fl ', this.missionColumnsWithSpecificMissions, true);

    }

    generateVisibleColumns()
    {
        if (this.tbData!.definitionId === TBPlayers.RISE_KEY)
        {
            return;
        }
        this.visibleColumns = this.deploymentColumns.slice();
        this.tableExpandable = undefined;

        switch (this.viewType)
        {
            case (TableViewType.Missions):
                this.visibleColumns = this.missionColumnsWithSpecificMissions.slice();

                this.tableExpandable = {
                    expandedRowRender: (record: TerritoryBattlePlayerCalc) =>
                    {


                        return <MissionsContainer>
                            {record.possibleMissionCombinations !== null &&
                                record.possibleMissionCombinations.cmrs.map((cmr, index) =>
                                {
                                    return <div key={index}>
                                        {record.possibleMissionCombinations!.cmrs.length > 1 &&
                                            <div>
                                                Possiblity {index + 1}
                                            </div>
                                        }
                                        {
                                            cmr.map((cmr: CombatMissionReward, index: number) =>
                                            {
                                                return <div key={index}>
                                                    {cmr.wavesCompleted} / {cmr.totalWavesPossible} {cmr.fleet ? 'Fleet' : 'Character'} {cmr.gpReward.toLocaleString()}
                                                </div>
                                            })
                                        }
                                    </div>

                                })
                            }

                        </MissionsContainer>
                    },
                    defaultExpandAllRows: false,
                    rowExpandable: (record: TerritoryBattlePlayerCalc) =>
                        record.possibleMissionCombinations !== null && record.possibleMissionCombinations.cmrs.length > 0
                };
                break;
        }

        if (this.terrCalcs!.fleetZoneExists === false)
        {
            this.visibleColumns = this.visibleColumns.filter(vc => vc.title?.toString().includes("Ship") === false)
        }
        this.visibleColumns = this.visibleColumns.filter(vc => this.selectedDeploymentColumns.get(vc.key!.toString()) === true);
    }


    @action
    async onSync()
    {
        if (this.tbStatusPreferences !== null)
        {
            let currentTbsp: TerritoryBattleStatusPreferences = this.tbStatusPreferences;
            // get fresh set of data to update deployment values (also other guild members may have updated confirmatin)
            let modal = Modal.info({
                maskClosable: false,
                okButtonProps: { disabled: true, style: { display: 'none' } },
                title: "Please wait, syncing",
                content: <Spin size="large" />
            });

            try
            {
                //    await this.fetchTbStatusData();
                let tbData = this.tbHistorySelection!.tbData;
                let tbHistory = await TBController.getTbHistorySelection(this.props.user!, this.props.gameData.tbData,
                    tbData!.instanceId, tbData!.currentRound!, true);
                runInAction(() =>
                {
                    this.tbHistorySelection = tbHistory;
                });
            }
            catch (response: any)
            {
                modal.update({ content: "Error: " + response.errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
                return;
            }


            if (this.pendingUpdates.length > 0 && currentTbsp.instanceId === this.tbStatusPreferences.instanceId &&
                currentTbsp.currentRound === this.tbStatusPreferences.currentRound)
            {
                runInAction(() =>
                {
                    this.pendingUpdates.forEach(pu =>
                    {
                        this.updatePlayerSetting(pu.allyCode, pu.confirmShipDeployment,
                            (pss: TerritoryBattlePlayerStatusSetting, fvalue: boolean | undefined) => pss.deployedShipGp = fvalue);
                        this.updatePlayerSetting(pu.allyCode, pu.confirmCombatDeployment,
                            (pss: TerritoryBattlePlayerStatusSetting, fvalue: boolean | undefined) => pss.deployedCombatGp = fvalue);
                        this.updatePlayerSetting(pu.allyCode, pu.confirmShipMissions,
                            (pss: TerritoryBattlePlayerStatusSetting, fvalue: boolean | undefined) => pss.completedShipMissions = fvalue);
                        this.updatePlayerSetting(pu.allyCode, pu.confirmCombatMissions,
                            (pss: TerritoryBattlePlayerStatusSetting, fvalue: boolean | undefined) => pss.completedCharacterMissions = fvalue);
                        this.updatePlayerSetting(pu.allyCode, pu.confirmSpecialMissions,
                            (pss: TerritoryBattlePlayerStatusSetting, fvalue: boolean | undefined) => pss.completedSpecialMissions = fvalue);
                    });
                });
                try
                {
                    await BaseAPI.setGuildSetting(this.props.user, TerritoryBattleStatusPreferences.SETTING_KEY + "_" + this.tbData!.instanceId + "_" + this.tbData!.currentRound, JSON.stringify(this.tbStatusPreferences), true);
                }
                catch (response: any)
                {
                    modal.update({ content: "Error: " + response.errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
                    return;
                }
            } // else user has likely kept web page open while rounds change, discard any pending updates

            modal.destroy();

        } else
        {
            throw new Error("Unexpected error, tbStatusPreferences null");
        }
    }



    @action
    clickConfirm(allyCode: number, value: boolean | undefined, updatePending: (ppu: PendingPlayerUpdate, value: boolean | undefined) => void,
        updateSetting: (pss: TerritoryBattlePlayerStatusSetting, value: boolean | undefined) => void)
    {
        let playerPending = this.pendingUpdates.find(pu => pu.allyCode === allyCode);
        if (playerPending === undefined)
        {
            playerPending = new PendingPlayerUpdate(allyCode);
            this.pendingUpdates.push(playerPending);
        }
        if (playerPending !== undefined)
        {
            updatePending(playerPending, value);
        }

        this.updatePlayerSetting(allyCode, value, updateSetting);
        this.recalculate();
    }

    private updatePlayerSetting(allyCode: number, value: boolean | undefined, updateSetting: (pss: TerritoryBattlePlayerStatusSetting, value: boolean | undefined) => void)
    {
        if (this.tbStatusPreferences !== null)
        {
            let pp = this.tbStatusPreferences.players.find(player => player.allyCode === allyCode);
            if (pp === undefined)
            {
                pp = new TerritoryBattlePlayerStatusSetting(allyCode);
                this.tbStatusPreferences.players.push(pp);
            }
            if (pp !== undefined)
            {
                updateSetting(pp, value);
            }
        }
    }

    getWavesCompleted(player: TerritoryBattlePlayerCalc, uniqueCountIndex: number, key: string, fleet: boolean): number | undefined
    {
        if (player.possibleMissionCombinations !== null && player.possibleMissionCombinations.cmrs.length === 1)
        {
            let matches = player.possibleMissionCombinations.cmrs[0].filter(cmr => cmr.missionRewardKey === key);
            if (matches.length > uniqueCountIndex)
            {
                return matches[uniqueCountIndex].wavesCompleted;
            }
        }
        let retVal = fleet ? player.missionsShips ? 0 : undefined :
            player.missionsCharacters ? 0 : undefined;
        return retVal;
    }


    renderPlayerTable(players: TerritoryBattlePlayerCalc[], fleetZoneExists: boolean, uniqueUnitMissionsCount: Map<string, number>, uniqueFleetMissionsCount: Map<string, number>)
    {

        let height = 400;
        let width: number | undefined = this.visibleColumns.length * 150;
        var elmnt = document.getElementById("player-container-id");
        if (elmnt !== null)
        {
            let visibleArea = elmnt.offsetHeight - 200;
            let visibleAreaWidth = elmnt.offsetWidth - 200;
            height = visibleArea > height ? visibleArea : height;
            width = visibleAreaWidth > width ? undefined : width;
        }

        return <div>

            <Table
                dataSource={players}
                columns={this.visibleColumns}
                pagination={false}
                scroll={{ y: height, x: width }}
                expandable={this.tableExpandable}
                rowKey={record => record.allyCode + record.name}
                onChange={(pagination: any,
                    filters: any,
                    sorter: any) => this.handleTableChange(pagination, filters, sorter)} />
        </div>;
    }


    private addCombatMissionColumns(missionCounts: Map<string, number>, columnPrefix: string, viewColumns: ColumnsType<TerritoryBattlePlayerCalc>, fleet: boolean)
    {
        let unitMissionIndex: number = 1;
        Array.from(missionCounts.keys()).forEach((key, index) =>
        {
            let count = missionCounts.get(key)!;
            for (let x = 0; x < count; x++)
            {
                let columnName = columnPrefix + unitMissionIndex;
                let missionParts = key.split('_');
                let rewardList = missionParts.map((amount, index) =>
                {
                    let amountVal = Number(amount);
                    if (isNaN(amountVal))
                    {
                        return <div key={index}>
                            Wave {index + 1}: ?
                        </div>;
                    }
                    else
                    {
                        return <div key={index}>
                            Wave {index + 1}: {amountVal.toLocaleString()}
                        </div>;
                    }
                });
                let columnDescription = <div>
                    <div>
                        Waves: {missionParts.length}
                    </div>
                    {rewardList}
                </div>;


                viewColumns.push({
                    title: columnName,
                    dataIndex: columnName,
                    sorter: (a: any, b: any) =>
                    {
                        let aVal = this.getWavesCompleted(a, x, key, fleet);
                        let bVal = this.getWavesCompleted(b, x, key, fleet);

                        aVal = aVal === undefined ? -1 : aVal;
                        bVal = bVal === undefined ? -1 : bVal;
                        return aVal - bVal;
                    },
                    key: columnName,
                    render: (text: any, record: TerritoryBattlePlayerCalc, index: number) =>
                    {
                        let wavesCompleted = this.getWavesCompleted(record, x, key, fleet);

                        return <Tooltip title={columnDescription} placement="bottom">
                            <span>
                                {wavesCompleted}
                            </span>
                        </Tooltip>;
                    }
                });


                unitMissionIndex = unitMissionIndex + 1;
            }
        });
    }

    handleTableChange(pagination: any, filters: Record<string, React.ReactText[] | null>, sorter: any)
    {
        this.clearFilters();
        if (filters.deployedCharacters && filters.deployedCharacters.length > 0)
        {
            this.deployedCharactersFilter.push(filters.deployedCharacters[0].toString());
        }
        if (filters.deployedShips && filters.deployedShips.length > 0)
        {
            this.deployedShipsFilter.push(filters.deployedShips[0].toString());
        }
        if (filters.missionsCharacters && filters.missionsCharacters.length > 0)
        {
            this.missionsCharactersFilter.push(filters.missionsCharacters[0].toString());
        }
        if (filters.specialMissions && filters.specialMissions.length > 0)
        {
            this.missionsSpecialFilter.push(filters.specialMissions[0].toString());
        }
        if (filters.missionsShips && filters.missionsShips.length > 0)
        {
            this.missionsShipsFilter.push(filters.missionsShips[0].toString());
        }

        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };


    private addCombatMissionColumnsExport(missionCounts: Map<string, number>, columnPrefix: string, existingColumns: string[], fleet: boolean)
    {
        let unitMissionIndex: number = 1;
        Array.from(missionCounts.keys()).forEach((key, index) =>
        {
            let count = missionCounts.get(key)!;
            for (let x = 0; x < count; x++)
            {
                let columnName = columnPrefix + unitMissionIndex;
                existingColumns.push(columnName);
                unitMissionIndex = unitMissionIndex + 1;
            }
        });
    }

    private addCombatMissionColumnsData(missionCounts: Map<string, number>, player: TerritoryBattlePlayerCalc, fields: string[], fleet: boolean)
    {
        let unitMissionIndex: number = 1;
        Array.from(missionCounts.keys()).forEach((key, index) =>
        {
            let count = missionCounts.get(key)!;
            for (let x = 0; x < count; x++)
            {
                let wc = this.getWavesCompleted(player, x, key, fleet);
                let cellVal = wc === undefined ? "" : wc.toString();
                fields.push(cellVal);
                unitMissionIndex = unitMissionIndex + 1;
            }
        });
    }



    exportTbPlayerData(tcPlayers: TerritoryBattlePlayerCalc[])
    {
        const tbCalcFields = [
            "name",
            "allyCode",
            "shipGP",
            "characterGP",
            "territoryPointsContributed",
            "platoonMissionUnitsAssigned",
            "combatMissionWavesCompleted",
            "rogueActions",
            "currentRoundTerritoryPoints",
            "currentRoundGpDeployed",
            "currentRoundCombatMissions",
            "currentRoundSpecialMissions",
            "deployedShips",
            "deployedCharacters",
            "playerPlatoonedShipGp",
            "playerPlatoonedCombatGp",
            "missionsCharacters",
            "missionsShips",
            "minEstimatedCombatDeploymentRemaining",
            "minEstimatedShipDeploymentRemaining",
            "unknownDeploymentPowerRemaining"

        ];

        let combatColumns: string[] = [];
        this.addCombatMissionColumnsExport(this.terrCalcs!.uniqueUnitMissionsCount, 'Ch ', combatColumns, false);
        this.addCombatMissionColumnsExport(this.terrCalcs!.uniqueFleetMissionsCount, 'Fl ', combatColumns, true);

        let csvContent = "data:text/csv;charset=utf-8,"
            + tbCalcFields.join(",") + "," + combatColumns.join(",") + "\n" + tcPlayers.map((e: any) =>
            {
                let fields: string[] = [];
                tbCalcFields.forEach(propertyName =>
                {
                    fields.push(e[propertyName]);
                })
                // add missions here
                this.addCombatMissionColumnsData(this.terrCalcs!.uniqueUnitMissionsCount, e, fields, false)
                this.addCombatMissionColumnsData(this.terrCalcs!.uniqueFleetMissionsCount, e, fields, true)
                return fields.join(",");
            }).join("\n");

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "tb_data.csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
    }

    @action
    setView(view: TableViewType)
    {
        this.viewType = view;
        this.clearFilters();
        this.generateVisibleColumns();
        this.forceUpdate();
    }

    @action
    removeFilters()
    {
        // cant replace the array, need to pop all, probably because array is managed
        this.clearFilters();
        this.forceUpdate();
    }

    clearFilters()
    {
        // cant replace the array, need to pop all, probably because array is managed
        while (this.deployedCharactersFilter.length) { this.deployedCharactersFilter.pop(); }
        while (this.deployedShipsFilter.length) { this.deployedShipsFilter.pop(); }
        while (this.missionsCharactersFilter.length) { this.missionsCharactersFilter.pop(); }
        while (this.missionsSpecialFilter.length) { this.missionsSpecialFilter.pop(); }
        while (this.missionsShipsFilter.length) { this.missionsShipsFilter.pop(); }
    }

    @action
    setFilterConfig(view: TableViewType, filters: string[], value: string)
    {
        this.setView(view);
        this.clearFilters();
        filters.push(value);
        this.forceUpdate();
    }

    @action
    setSelectedColumns(selectedDeploymentColumns: Map<string, boolean>)
    {
        this.selectedDeploymentColumns = selectedDeploymentColumns;
        localStorage.setItem(TBPlayers.SELECTED_COLUMNS_KEY, Array.from(selectedDeploymentColumns.keys()).filter(key => selectedDeploymentColumns.get(key) === true).join(","));

        this.generateVisibleColumns();
        this.forceUpdate();
    }

    showErrorMessage()
    {
        return this.errorMessage && this.useSimulatedData === false;
    }

    tbDataLoading()
    {
        return !this.props.user.currentPlayer || this.props.gameData.tbData === null ||
            this.props.gameData.tbData.definitions.length === 0;
    }


    @action
    async onSelectHistory(tbSelection: ITBHistoryInstance | null)
    {
        runInAction(() =>
        {
            this.tbHistorySelection = null;
            this.loadingTbData = true;
        });

        if (tbSelection !== null)
        {
            let selection = await TBController.getTbHistorySelection(this.props.user!, this.props.gameData.tbData, tbSelection.instanceId, tbSelection.phase);

            runInAction(() =>
            {
                this.loadingTbData = false;
                this.tbHistorySelection = selection;
                this.recalculate();
                this.generateVisibleColumns();
            });
        }
    }


    renderPlayersContent()
    {
        if (this.loadingTbData)
        {
            return <React.Fragment>
                <LoadingSpinner size={"small"} spinning={true} text={'Loading...'} />
            </React.Fragment>
        } else if (this.tbHistorySelection === null || this.tbData === null)
        {
            return <React.Fragment>
                Select Territory Battle
            </React.Fragment>
        } if (this.tbHistorySelection.tbData.definitionId === TBPlayers.RISE_KEY)
        {
            return <Alert message="Use TB -> Manage for the Rise TB" type="warning" />;
        }
        else 
        {
            const deploymentsMenu = (
                <Menu>
                    <Menu.Item key="1" onClick={() => this.removeFilters()}>All</Menu.Item>
                    <Menu.Item key="2" onClick={() => this.setFilterConfig(TableViewType.Deployments, this.deployedShipsFilter, "false")}>Missing Ships</Menu.Item>
                    <Menu.Item key="3" onClick={() => this.setFilterConfig(TableViewType.Deployments, this.deployedCharactersFilter, "false")}>Missing Characters</Menu.Item>
                    <Menu.Item key="3" onClick={() => this.setFilterConfig(TableViewType.Deployments, this.deployedCharactersFilter, "uncertain")}>Uncertain</Menu.Item>
                </Menu >
            );

            const missionsMenu = (
                <Menu>
                    <Menu.Item key="1" onClick={() => this.removeFilters()}>All</Menu.Item>
                    <Menu.Item key="2" onClick={() => this.setFilterConfig(TableViewType.Missions, this.missionsShipsFilter, "false")}>Missing Ships</Menu.Item>
                    <Menu.Item key="3" onClick={() => this.setFilterConfig(TableViewType.Missions, this.missionsCharactersFilter, "false")}>Missing Characters</Menu.Item>
                    <Menu.Item key="3" onClick={() => this.setFilterConfig(TableViewType.Missions, this.missionsSpecialFilter, "false")}>Missing Specials</Menu.Item>
                </Menu>
            );

            return (

                <StatusContainer id="player-container-id">

                    <div>Current Round: {this.tbData!.currentRound}</div>
                    <Toolbar>
                        <Tooltip placement="bottomLeft" title="Save manual updates and refresh tb data">
                            <ToolbarButton type={this.pendingUpdates.length > 0 ? 'primary' : undefined} onClick={() => this.onSync()} >
                                <CloudSyncOutlined />
                            </ToolbarButton>
                        </Tooltip>

                        <Tooltip placement="bottomLeft" title="Export player data to csv">
                            <ToolbarButton onClick={() => this.exportTbPlayerData(this.players)}><ExportOutlined /></ToolbarButton>
                        </Tooltip>

                        <ToolbarDropDownButton overlay={deploymentsMenu} onClick={() => this.setView(TableViewType.Deployments)}>
                            <ViewButtonText description="Deployments" selected={this.viewType === TableViewType.Deployments} />
                        </ToolbarDropDownButton>

                        <ToolbarDropDownButton overlay={missionsMenu} onClick={() => this.setView(TableViewType.Missions)}>
                            <ViewButtonText description="Missions" selected={this.viewType === TableViewType.Missions} />
                        </ToolbarDropDownButton>


                        <TBPlayersMessage
                            tbData={this.tbData!}
                            players={this.players}
                            user={this.props.user} />


                        <TbPlayersConfigColumns
                            possibleColumns={this.viewType === TableViewType.Missions ? this.missionColumnsWithSpecificMissions : this.deploymentColumns}
                            selectedDeploymentColumns={this.selectedDeploymentColumns}
                            onConfigureColumns={(selectedDeploymentColumns) => this.setSelectedColumns(selectedDeploymentColumns)} />

                    </Toolbar>

                    {this.renderPlayerTable(this.players,
                        this.terrCalcs!.fleetZoneExists,
                        this.terrCalcs!.uniqueUnitMissionsCount,
                        this.terrCalcs!.uniqueFleetMissionsCount)}
                </StatusContainer>
            );
        }
    }

    render()
    {
        if (this.showErrorMessage())
        {
            return <BasicLayout {...this.props} extraRefresh={() => this.loadTbData(true)} requiredApiRights={[TBPlayers.PERMISSION_KEY]}>
                <StatusContainer>
                    {this.errorMessage}
                </StatusContainer>
            </BasicLayout>;
        }

        if (this.tbDataLoading())
        {
            return <BasicLayout {...this.props} extraRefresh={() => this.loadTbData(true)} requiredApiRights={[TBPlayers.PERMISSION_KEY]}>
                <LoadingSpinner size={"large"} spinning={true} text={"Loading.."} />
            </BasicLayout>;
        }

        return <BasicLayout {...this.props} extraRefresh={() => this.loadTbData(true)} requiredApiRights={[TBPlayers.PERMISSION_KEY]}>
            <StatusContainer>

                <TBHistorySelector
                    gameData={this.props.gameData}
                    user={this.props.user}
                    onSelectHistory={(tbHistorySelection) => this.onSelectHistory(tbHistorySelection)}>
                </TBHistorySelector>

                {this.renderPlayersContent()}
            </StatusContainer>
        </BasicLayout>;

    }


}

export default TBPlayers;
