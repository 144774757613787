import React from "react";
import ModData from "../../../model/ModData";
import { observer } from "mobx-react";
import "./Mod.css";
import StatIds, { statIdToString } from "../../../model/StatIds";
import PlayerCharacterData from './../../../model/PlayerCharacterData';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDice } from "@fortawesome/pro-duotone-svg-icons";
import GameData from "../../../model/GameData";

interface ModProps
{
  mod: ModData;
  viewMode?: "Normal" | "PctOfPossible" | "PctOfPossibleByTier" | "RollQuality" | "WithSpeedRemainder";
  onClick: () => void;
  selected: boolean;
  equippedCharacterOverride?: PlayerCharacterData;
  showPortrait?: boolean;
  allowSecondaryOverflow?: boolean;
  disabled?: boolean;
  colorBlindMode?: boolean;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  gameData?: GameData;
}

export const MOD_WIDTH = 240; // used by virtual scroll, keep this accurate if / when this is moved to styled component
export const MOD_HEIGHT = 100;

const Mod = observer((props: ModProps) =>
{
  let outerClass = "mod-summary mod-content-item";
  if (props.selected)
    outerClass += " mod-selected";
  if (props.mod.locked)
    outerClass += " mod-locked";
  if (props.mod.level < 15)
    outerClass += " mod-upgradeable";

  let dots: React.ReactNode[] = [];
  for (let i = 0; i < props.mod.rarity; i++)
  {
    dots.push(<div key={"dots" + i} className="mod-dot mod-content-item" />);
  }

  let secondaries: React.ReactNode[];
  let statOverflow = props.allowSecondaryOverflow ? " mod-secondary-overflow" : "";

  if (!props.viewMode || props.viewMode === "Normal" || props.viewMode === "WithSpeedRemainder")
  {
    secondaries = props.mod.secondaries.map((s) =>
      <div key={s.statId} className={"mod-content-item stat-secondary roll-" + s.statRolls + statOverflow}>
        {s.formattedValue + " (" + s.statRolls + ")"}
        {s.statId === StatIds.Speed && props.viewMode === "WithSpeedRemainder" && (" R" + props.mod.speedRemainder.toFixed(2))}
      </div>);
  }
  else if (props.viewMode === "RollQuality")
  {
    secondaries = props.mod.secondaries.map((s) =>
    {
      let pct = s.rollQuality(props.mod.rarity);
      pct = pct * 100;

      return <div key={s.statId} className={"mod-content-item stat-secondary roll-" + s.statRolls + statOverflow}>
        {pct.toFixed(0) + "% "} -  {s.formattedValue + " (" + s.statRolls + ")"}
      </div>
    });
  }
  else
  {
    secondaries = props.mod.secondaries.map((s) =>
    {
      let pct = props.viewMode === "PctOfPossible" ? s.percentOfMax(props.mod.rarity) : s.percentOfMaxByTier(props.mod.rarity, props.mod.tier);
      let rollEquiv = pct < 0.25 ? 1 : (pct < 0.5 ? 2 : (pct < 0.75 ? 3 : 4));
      pct = pct * 100;


      return <div key={s.statId} className={"mod-content-item stat-secondary roll-" + rollEquiv + statOverflow}>
        {pct.toFixed(1) + "% " + statIdToString(s.statId, true) + " (" + s.rawValue.toFixed(0) + ")"}
      </div>;
    })
  }

  let innerClass = "mod mod-content-item " + (props.mod.rarity < 6 ? "Std" : "Six") + props.mod.slot + "-" + props.mod.tier;
  let iconClass = "mod-content-item mod-icon icon-slot" + props.mod.slot + " icon-set-" + props.mod.set + "-" + props.mod.tier;
  let overlayContent: React.ReactNode = false;
  if (props.disabled)
    innerClass += " mod-disabled";
    
  let displayCharacter = props.equippedCharacterOverride ? props.equippedCharacterOverride : props.mod.equippedCharacter;
  if (displayCharacter && props.showPortrait !== false)
  {
    // todo: change baseID to name
    overlayContent = <div style={{
      whiteSpace: "pre-line",
      backgroundImage: "url('https://api.hotutils.com/images/units/" + displayCharacter.thumbnailName + ".png')"
    }}
      title={"Equipped to " + (props.gameData?.getNameFromBaseId(displayCharacter.baseId) ?? displayCharacter.baseId)} className="mod-character-image"></div>;
  }
  else if (props.mod.locked)
  {
    overlayContent = <div style={{ whiteSpace: "pre-line" }} title="Locked In Game" className="mod-locked-image"></div>;
  }
  else if (props.mod.inLoadout)
  {
    let loadoutTooltip = "Used in Loadout in Game";
    if (props.mod.inGameLoadouts && props.mod.inGameLoadouts.length > 0)
    {
      loadoutTooltip = "Used in game loadouts: " + props.mod.inGameLoadouts.join(', ');
    }
    overlayContent = <div style={{ whiteSpace: "pre-line" }} title={loadoutTooltip} className="mod-loadout-image"></div>;
  }

  return (
    <div className={outerClass} onClick={() => props.onClick()} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} onFocus={props.onFocus}>
      <div className="mod-dots mod-content-item">
        {dots}
      </div>
      <div className={innerClass}>
        <div className={iconClass} />
      </div>
      <div className="mod-content-item mod-stats">
        <div className="mod-content-item stat-primary">{props.mod.primary.formattedValue}</div>
        {secondaries}
        {props.colorBlindMode ? 
          (props.mod.level < 15 ? <div className="mod-content-item mod-level-colorblind">{props.mod.level + props.mod.tierLetter}</div> : <div className="mod-content-item mod-level-colorblind">{props.mod.tierLetter}</div>) :
          (props.mod.level < 15 && <div className="mod-content-item mod-level">{props.mod.level}</div>)}
      </div>
      {overlayContent}
      {props.mod.rerolledCount > 0 && <div className="mod-reroll-count"><FontAwesomeIcon icon={faDice} />{props.mod.rerolledCount}</div>}
    </div>
  );
});

export default Mod;