import React from "react";
import { observer } from "mobx-react";
import styled from 'styled-components';
import ModData from "../../../model/ModData";
import { Select } from 'antd';
import { action, observable } from "mobx";
import StatIds, { statIdToString } from './../../../model/StatIds';
import ModSets from './../../../model/ModSets';
import ModSetIcon from './../../../components/swgoh/ModSetIcon/ModSetIcon';
import { Button } from 'antd';
import ModSlots from "../../../model/ModSlots";
import ModSlotIcon from './../../../components/swgoh/ModSlotIcon/ModSlotIcon';

const { Option } = Select;


const ModFilterContainer = styled.div`
    display: flex;
    flex-flow: column;
    min-height: 570px;
`;


interface IModSetFilterProps
{
    set: ModSets;
    selected: boolean;
    className?: string;
    onClick: () => void;
}

const ModSetFilterRenderer = ({ className, set, onClick }: IModSetFilterProps) =>
{
    return <div className={className} onClick={() => onClick()}><ModSetIcon set={set} /></div>;
}

export const ModActionButton = styled(Button)`
    margin-top: 15px;
    min-height: 32px; 
`;


const ModsSetContainer = styled.div`
    display: flex;
    min-height: 38px;
`;

const ModsSlotsContainer = styled.div`
    display: flex;
    min-height: 38px;
`;

const ModSetButton = styled(ModSetFilterRenderer)`
    float: left;
    padding: 3px;
    background-color: ${props => props.selected === false ? "blue" : ""};
`;

interface IModSlotFilterProps
{
    slot: ModSlots;
    selected: boolean;
    className?: string;
    onClick: () => void;
}

const ModSlotFilterRenderer = ({ className, slot, onClick }: IModSlotFilterProps) =>
{
    return <div className={className} onClick={() => onClick()}><ModSlotIcon slot={slot} /></div>;
}
const ModSlotButton = styled(ModSlotFilterRenderer)`
    float: left;
    padding: 3px;
    background-color: ${props => props.selected === false ? "blue" : ""};
`;

export class SecondaryFilter
{
    @observable name: string = "";
    @observable statIds: StatIds[] = [];
}

export class FilterConfig
{
    @observable sets: ModSets[] = [];
    @observable slots: ModSlots[] = [];
    @observable circle: StatIds[] = [];
    @observable arrow: StatIds[] = [];
    @observable triangle: StatIds[] = [];
    @observable cross: StatIds[] = [];

    @observable equipped: string[] = [];

    @observable secondaryIncludes: SecondaryFilter[] = [];
    @observable secondaryExcludes: SecondaryFilter[] = [];

    @observable secondariesIncludeText: string[] = [];
    @observable secondariesExcludeText: string[] = [];


    static copy(existing: FilterConfig, clone: FilterConfig)
    {
        existing.sets = clone.sets.slice(0);
        existing.circle = clone.circle.slice(0);
        existing.arrow = clone.arrow.slice(0);
        existing.triangle = clone.triangle.slice(0);
        existing.cross = clone.cross.slice(0);
        existing.slots = clone.slots.slice(0);
        existing.equipped = clone.equipped.slice(0);
        existing.secondaryIncludes = clone.secondaryIncludes.slice(0);
        existing.secondaryExcludes = clone.secondaryExcludes.slice(0);
        existing.secondariesIncludeText = clone.secondariesIncludeText.slice(0);
        existing.secondariesExcludeText = clone.secondariesExcludeText.slice(0);
    }

    public clear()
    {
        this.sets = [];
        this.circle = [];
        this.arrow = [];
        this.triangle = [];
        this.cross = [];
        this.slots = [];
        this.equipped = [];
        this.secondaryIncludes = [];
        this.secondaryExcludes = [];
        this.secondariesIncludeText = [];
        this.secondariesExcludeText = [];
    }

    public isEmpty(): boolean
    {
        return this.sets.length === 0 && this.circle.length === 0 && this.arrow.length === 0 &&
            this.triangle.length === 0 && this.cross.length === 0 && this.slots.length === 0 &&
            this.secondaryIncludes.length === 0 && this.secondaryExcludes.length === 0 && this.equipped.length === 0;
    }
}

export class ModFilterOptions
{
    showSlots: boolean = false;
    showSets: boolean = false;
    showPrimaries: boolean = false;
    showSecondaries: boolean = false;
    showEquipped: boolean = false;
}

export interface IModFilterProps
{
    mods: ModData[];
    filterConfig: FilterConfig;
    options?: ModFilterOptions;
    equippedMods?: ModData[];
    onUpdate: (filterConfig: FilterConfig, filteredMods: ModData[]) => void;
}

@observer
class ModFilter extends React.Component<IModFilterProps>
{
    modSetList: ModSets[] = [ModSets.Speed, ModSets.Offense, ModSets.CriticalDamage,
    ModSets.Health, ModSets.Potency, ModSets.CriticalChance,
    ModSets.Tenacity, ModSets.Defense];

    modSlotList: ModSlots[] = [ModSlots.Square, ModSlots.Diamond, ModSlots.Circle,
    ModSlots.Arrow, ModSlots.Triangle, ModSlots.Cross];

    CIRCLE_PRIMARIES: StatIds[] = [StatIds.ProtectionPct, StatIds.HealthPct];
    ARROW_PRIMARIES: StatIds[] = [StatIds.ProtectionPct, StatIds.HealthPct, StatIds.Speed, StatIds.OffensePct, StatIds.DefensePct, StatIds.CriticalAvoidance, StatIds.Accuracy];
    TRIANGLE_PRIMARIES: StatIds[] = [StatIds.ProtectionPct, StatIds.HealthPct, StatIds.OffensePct, StatIds.DefensePct, StatIds.CriticalChance, StatIds.CriticalDamage];
    CROSS_PRIMARIES: StatIds[] = [StatIds.ProtectionPct, StatIds.HealthPct, StatIds.OffensePct, StatIds.DefensePct, StatIds.Potency, StatIds.Tenacity];


    SECONDARY_OPTIONS: SecondaryFilter[] = [
        {
            name: statIdToString(StatIds.Speed),
            statIds: [StatIds.Speed]
        },
        {
            name: statIdToString(StatIds.Offense, true),
            statIds: [StatIds.Offense]
        },
        {
            name: statIdToString(StatIds.OffensePct, true),
            statIds: [StatIds.OffensePct]
        },
        {
            name: "Offense or Offense %",
            statIds: [StatIds.OffensePct, StatIds.Offense]
        },
        {
            name: statIdToString(StatIds.CriticalChance, true),
            statIds: [StatIds.CriticalChance]
        },
        {
            name: statIdToString(StatIds.Potency, true),
            statIds: [StatIds.Potency]
        },
        {
            name: statIdToString(StatIds.Health, true),
            statIds: [StatIds.Health]
        },
        {
            name: statIdToString(StatIds.HealthPct, true),
            statIds: [StatIds.HealthPct]
        },
        {
            name: "Health or Health %",
            statIds: [StatIds.HealthPct, StatIds.Health]
        },
        {
            name: statIdToString(StatIds.Protection, true),
            statIds: [StatIds.Protection]
        },
        {
            name: statIdToString(StatIds.ProtectionPct, true),
            statIds: [StatIds.ProtectionPct]
        },
        {
            name: "Protection or Protection %",
            statIds: [StatIds.ProtectionPct, StatIds.Protection]
        },
        {
            name: statIdToString(StatIds.Tenacity, true),
            statIds: [StatIds.Tenacity]
        },
        {
            name: statIdToString(StatIds.Defense, true),
            statIds: [StatIds.Defense]
        },
        {
            name: statIdToString(StatIds.DefensePct, true),
            statIds: [StatIds.DefensePct]
        },
        {
            name: "Defense or Defense %",
            statIds: [StatIds.Defense, StatIds.DefensePct]
        }

    ];

    @action
    updateConfig(updateConfigFn: (config: FilterConfig) => void)
    {
        let newConfig = new FilterConfig();
        FilterConfig.copy(newConfig, this.props.filterConfig);
        updateConfigFn(newConfig);
        this.props.onUpdate(newConfig, ModFilter.filterModList(this.props.mods, newConfig, this.props.equippedMods));
    }

    @action
    toggleSet(set: ModSets)
    {
        this.updateConfig((config) =>
        {
            if (config.sets.indexOf(set) === -1)
            {
                config.sets.push(set);
            } else
            {
                config.sets = config.sets.filter(iSet => iSet !== set);
            }
        });
    }

    @action
    toggleSlot(slot: ModSlots)
    {
        this.updateConfig((config) =>
        {
            if (config.slots.indexOf(slot) === -1)
            {
                config.slots.push(slot);
            } else
            {
                config.slots = config.slots.filter(iSlot => iSlot !== slot);
            }
        });
    }


    render()
    {

        const equippedOptions = [<Option value={'true'} key={'true'}>Equipped</Option>, <Option value={'false'} key={'false'}>Not Equipped</Option>]

        const modSlots = this.modSlotList.map((slot: ModSlots) =>
        {
            return (<ModSlotButton key={slot} slot={slot} selected={this.props.filterConfig.slots.indexOf(slot) === -1} onClick={() => this.toggleSlot(slot)} />);
        })

        const modSets = this.modSetList.map((set: ModSets) =>
        {
            return (<ModSetButton key={set} set={set} selected={this.props.filterConfig.sets.indexOf(set) === -1} onClick={() => this.toggleSet(set)} />);
        })

        const circleOptions = this.CIRCLE_PRIMARIES.map(statId => <Option value={statId} key={statId}>{statIdToString(statId)}</Option>);
        const arrowOptions = this.ARROW_PRIMARIES.map(statId => <Option value={statId} key={statId}>{statIdToString(statId)}</Option>);
        const triangleOptions = this.TRIANGLE_PRIMARIES.map(statId => <Option value={statId} key={statId}>{statIdToString(statId)}</Option>);
        const crossOptions = this.CROSS_PRIMARIES.map(statId => <Option value={statId} key={statId}>{statIdToString(statId)}</Option>);


        const secondaryOptions = this.SECONDARY_OPTIONS.map(secondary => <Option value={secondary.name} key={secondary.name}>{secondary.name}</Option>);
        const secondaryExcludeOptions = this.SECONDARY_OPTIONS.filter(secondary => secondary.statIds.length === 1).map(
            secondary => <Option value={secondary.name} key={secondary.name}>{secondary.name}</Option>);

        return (
            <ModFilterContainer>


                {(this.props.options === undefined || this.props.options.showSlots === true) &&
                    <ModsSlotsContainer>
                        {modSlots}
                    </ModsSlotsContainer>
                }

                {(this.props.options === undefined || this.props.options.showSets === true) &&
                    <ModsSetContainer>
                        {modSets}
                    </ModsSetContainer>
                }

                {(this.props.options === undefined || this.props.options.showEquipped === true) &&
                    <React.Fragment>
                        <p>Equipped: </p>
                        <Select mode="multiple" placeholder="Leave empty for all"
                            value={this.props.filterConfig.equipped} onChange={action((values: string[]) => { this.updateConfig((config) => config.equipped = values); })} >
                            {equippedOptions}
                        </Select>
                    </React.Fragment>
                }

                {(this.props.options === undefined || this.props.options.showPrimaries === true) &&
                    <React.Fragment>
                        <p>Circle: </p>
                        <Select mode="multiple" placeholder="Leave empty for all"
                            value={this.props.filterConfig.circle} onChange={action((values: number[]) => { this.updateConfig((config) => config.circle = values); })} >
                            {circleOptions}
                        </Select>
                        <p>Arrow: </p>
                        <Select mode="multiple" placeholder="Leave empty for all"
                            value={this.props.filterConfig.arrow} onChange={
                                action((values: number[]) =>
                                { this.updateConfig((config) => config.arrow = values); })} >
                            {arrowOptions}
                        </Select>
                        <p>Triangle: </p>
                        <Select mode="multiple" placeholder="Leave empty for all"
                            value={this.props.filterConfig.triangle} onChange={action((values: number[]) => { this.updateConfig((config) => config.triangle = values); })} >
                            {triangleOptions}
                        </Select>
                        <p>Cross: </p>
                        <Select mode="multiple" placeholder="Leave empty for all"
                            value={this.props.filterConfig.cross} onChange={action((values: number[]) => { this.updateConfig((config) => config.cross = values); })} >
                            {crossOptions}
                        </Select>
                    </React.Fragment>
                }

                {(this.props.options === undefined || this.props.options.showSecondaries === true) &&
                    <React.Fragment>
                        <p>Secondary: </p>
                        <Select mode="multiple" placeholder="Leave empty for none"
                            value={this.props.filterConfig.secondariesIncludeText} onChange={action((values: string[]) =>
                            {
                                this.updateConfig((config) =>
                                {
                                    config.secondariesIncludeText = values;
                                    config.secondaryIncludes = [];
                                    config.secondariesIncludeText.forEach(si =>
                                    {
                                        let secondary = this.SECONDARY_OPTIONS.find(so => so.name === si);
                                        if (secondary !== undefined)
                                        {
                                            config.secondaryIncludes.push(secondary);
                                        }
                                    });
                                });
                            })}>
                            {secondaryOptions}
                        </Select>

                        <p>Exclude Secondary: </p>
                        <Select mode="multiple" placeholder="Leave empty for none"
                            value={this.props.filterConfig.secondariesExcludeText} onChange={action((values: string[]) => 
                            {
                                this.updateConfig((config) =>
                                {
                                    config.secondariesExcludeText = values;
                                    config.secondaryExcludes = [];
                                    config.secondariesExcludeText.forEach(si =>
                                    {
                                        let secondary = this.SECONDARY_OPTIONS.find(so => so.name === si);
                                        if (secondary !== undefined)
                                        {
                                            config.secondaryExcludes.push(secondary);
                                        }
                                    });
                                });
                            })}>
                            {secondaryExcludeOptions}
                        </Select>
                    </React.Fragment>
                }
            </ModFilterContainer>
        );
    }

    public static filterModList(mods: ModData[], config: FilterConfig, equippedMods: ModData[] | undefined = undefined): ModData[]
    {
        return mods.filter(mod => ModFilter.filterMod(mod, config, equippedMods));
    }

    public static filterMod(mod: ModData, config: FilterConfig, equippedMods: ModData[] | undefined = undefined): boolean
    {
        let modIsEquipped = equippedMods === undefined ? mod.equippedCharacter !== null : equippedMods.find(m => m.id === mod.id) !== undefined;

        if (config.equipped.length > 0
            && ((modIsEquipped === false && config.equipped.indexOf('false') === -1) ||
                (modIsEquipped && config.equipped.indexOf('true') === -1)))
        {
            return false;
        }

        if (config.slots.length > 0
            && config.slots.indexOf(Number(mod.slot)) === -1)
        {
            return false;
        }
        if (config.sets.length > 0
            && config.sets.indexOf(Number(mod.set)) === -1)
        {
            return false;
        }
        if (config.arrow.length > 0 && mod.slot === ModSlots.Arrow
            && config.arrow.indexOf(mod.primary.statId) === -1)
        {
            return false;
        }
        if (config.circle.length > 0 && mod.slot === ModSlots.Circle
            && config.circle.indexOf(mod.primary.statId) === -1)
        {
            return false;
        }
        if (config.triangle.length > 0 && mod.slot === ModSlots.Triangle
            && config.triangle.indexOf(mod.primary.statId) === -1)
        {
            return false;
        }
        if (config.cross.length > 0 && mod.slot === ModSlots.Cross
            && config.cross.indexOf(mod.primary.statId) === -1)
        {
            return false;
        }

        if (config.secondaryIncludes.length > 0)
        {
            let missingFilterDependency = config.secondaryIncludes.find(si => ModFilter.satisfiesDependency(si, mod) === false) === undefined;
            if (missingFilterDependency === false)
            {
                return false;
            }
        }

        if (config.secondaryExcludes.length > 0)
        {
            let hasFilterDependency = config.secondaryExcludes.find(si => ModFilter.satisfiesDependency(si, mod, false) === true) !== undefined;
            if (hasFilterDependency === true)
            {
                return false;
            }
        }

        return true;
    }

    private static satisfiesDependency(secondaryFilter: SecondaryFilter, mod: ModData, considerPrimary: boolean = true): boolean
    {
        let retVal = false;

        secondaryFilter.statIds.forEach(statId =>
        {
            mod.secondaries.forEach(secondary =>
            {
                retVal = retVal || secondary.statId === statId;
            })
        });

        if (secondaryFilter.statIds.length === 1 && considerPrimary)
        {
            retVal = retVal || mod.primary.statId === secondaryFilter.statIds[0];
        }

        return retVal;
    }

}

export default ModFilter;
