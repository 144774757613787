// OffensePct = 48
// Offense = 41,
// DefensePct = 49,
// Defense = 42,
// Health = 1,
// HealthPct = 55,
// Protection = 28,
// ProtectionPct = 56
// Speed = 5,
// Potency = 17,
// CriticalChance = 53,
// Tenacity = 18,

// CriticalDamage = 16,
// CriticalAvoidance = 54,
// Accuracy = 52,

import ModData, { ModStat } from "../model/ModData";
import ModSets from "../model/ModSets";
import ModSlots from "../model/ModSlots";
import StatIds from "../model/StatIds";

const perfectCritChancePrimary = `{
    "updaterId": "1012",
    "stat": {
        "unitStatId": 53,
        "statValueDecimal": 2000,
        "unscaledDecimalValue": 20000000
    },
    "roll": [],
    "statRolls": 0
}`;


const perfectCritChanceSecondary = `{
    "updaterId": "1012",
    "stat": {
        "unitStatId": 53,
        "statValueDecimal": 1170,
        "unscaledDecimalValue": 11700000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectTenacityPrimary = `{
    "updaterId": "1152",
    "stat": {
        "unitStatId": 18,
        "statValueDecimal": 3500,
        "unscaledDecimalValue": 35000000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectTenacitySecondary = `{
    "updaterId": "1152",
    "stat": {
        "unitStatId": 18,
        "statValueDecimal": 1496,
        "unscaledDecimalValue": 14960000
    },
    "roll": [],
    "statRolls": 0
}`;


const perfectPotencyPrimary = `{
    "updaterId": "1005",
    "stat": {
        "unitStatId": 17,
        "statValueDecimal": 3000,
        "unscaledDecimalValue": 30000000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectPotencySecondary = `{
    "updaterId": "1005",
    "stat": {
        "unitStatId": 17,
        "statValueDecimal": 1496,
        "unscaledDecimalValue": 14960000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectSpeedPrimary = `{
    "updaterId": "1159",
    "stat": {
        "unitStatId": 5,
        "statValueDecimal": 320000,
        "unscaledDecimalValue": 3200000000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectSpeedSecondary = `{
    "updaterId": "1159",
    "stat": {
        "unitStatId": 5,
        "statValueDecimal": 310000,
        "unscaledDecimalValue": 3100000000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectOffensePrimary = `{
    "updaterId": "1145",
    "stat": {
        "unitStatId": 48,
        "statValueDecimal": 850,
        "unscaledDecimalValue": 8500000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectOffensePercentSecondary = `{
    "updaterId": "1145",
    "stat": {
        "unitStatId": 48,
        "statValueDecimal": 846,
        "unscaledDecimalValue": 8460000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectOffenseFlatSecondary = `{
    "updaterId": "2138",
    "stat": {
        "unitStatId": 41,
        "statValueDecimal": 2530000,
        "unscaledDecimalValue": 25300000000
    },
    "roll": [],
    "statRolls": 3
}`;

const perfectDefensePrimary = `{
    "updaterId": "1068",
    "stat": {
        "unitStatId": 49,
        "statValueDecimal": 2000,
        "unscaledDecimalValue": 20000000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectDefensePercentSecondary = `{
    "updaterId": "1068",
    "stat": {
        "unitStatId": 49,
        "statValueDecimal": 1989,
        "unscaledDecimalValue": 19890000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectDefenseFlatSecondary = `{
    "updaterId": "2047",
    "stat": {
        "unitStatId": 42,
        "statValueDecimal": 730000,
        "unscaledDecimalValue": 7300000000
    },
    "roll": [],
    "statRolls": 3
}`;

const perfectHealthPrimary = `{
    "updaterId": "1117",
    "stat": {
        "unitStatId": 55,
        "statValueDecimal": 1600,
        "unscaledDecimalValue": 16000000
    },
    "roll": [],
    "statRolls": 0
}`;


const perfectHealthPercentSecondary = `{
    "updaterId": "1117",
    "stat": {
        "unitStatId": 55,
        "statValueDecimal": 1051,
        "unscaledDecimalValue": 10510000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectHealthFlatSecondary = `{
    "updaterId": "2110",
    "stat": {
        "unitStatId": 1,
        "statValueDecimal": 26960000,
        "unscaledDecimalValue": 269600000000
    },
    "roll": [],
    "statRolls": 4
}`;

const perfectProtectionPrimary = `{
    "updaterId": "1131",
    "stat": {
        "unitStatId": 56,
        "statValueDecimal": 2400,
        "unscaledDecimalValue": 24000000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectProtectionPercentSecondary = `{
    "updaterId": "1131",
    "stat": {
        "unitStatId": 56,
        "statValueDecimal": 1549,
        "unscaledDecimalValue": 15490000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectProtectionFlatSecondary = `{
    "updaterId": "2124",
    "stat": {
        "unitStatId": 28,
        "statValueDecimal": 46070000,
        "unscaledDecimalValue": 460700000000
    },
    "roll": [],
    "statRolls": 3
}`;

const perfectCritAvoidPrimary = `{
    "updaterId": "1026",
    "stat": {
        "unitStatId": 54,
        "statValueDecimal": 3500,
        "unscaledDecimalValue": 35000000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectCritDamagePrimary = `{
    "updaterId": "1019",
    "stat": {
        "unitStatId": 16,
        "statValueDecimal": 4200,
        "unscaledDecimalValue": 42000000
    },
    "roll": [],
    "statRolls": 0
}`;

const perfectAccuracyPrimary = `{
    "updaterId": "1075",
    "stat": {
        "unitStatId": 52,
        "statValueDecimal": 3000,
        "unscaledDecimalValue": 30000000
    },
    "roll": [],
    "statRolls": 0
}`;

const defaultMod = `{
    "bonusQuantity": 0,
    "id": "0OpmLt6JTXe-OCWHXrJCcg",
    "level": 15,
    "levelCost": {
        "currency": 1,
        "quantity": 0,
        "bonusQuantity": 0
    },
    "primaryStat": {
        "updaterId": "1144",
        "stat": {
            "unitStatId": 48,
            "statValueDecimal": 588,
            "unscaledDecimalValue": 5880000
        },
        "roll": [],
        "statRolls": 0
    },
    "locked": false,
    "secondaryStat": [],
    "tier": 5,
    "xp": 486000,
    "unit": null,
    "rarity": 5,
    "slot": 2,
    "setId": "2"
}`;


export class PerfectSets
{

    static MOD_SETS_LIST = [ModSets.Health, ModSets.Offense, ModSets.Defense, ModSets.Speed, ModSets.CriticalChance, ModSets.CriticalDamage, ModSets.Potency, ModSets.Tenacity];
    static CIRCLE_PRIMARIES: StatIds[] = [StatIds.ProtectionPct, StatIds.HealthPct];
    static ARROW_PRIMARIES: StatIds[] = [StatIds.ProtectionPct, StatIds.HealthPct, StatIds.Speed, StatIds.OffensePct, StatIds.DefensePct, StatIds.CriticalAvoidance, StatIds.Accuracy];
    static TRIANGLE_PRIMARIES: StatIds[] = [StatIds.ProtectionPct, StatIds.HealthPct, StatIds.OffensePct, StatIds.DefensePct, StatIds.CriticalChance, StatIds.CriticalDamage];
    static CROSS_PRIMARIES: StatIds[] = [StatIds.ProtectionPct, StatIds.HealthPct, StatIds.OffensePct, StatIds.DefensePct, StatIds.Potency, StatIds.Tenacity];

    PERFECT_SPEED_SET: ModData[];
    PERFECT_OFFENSE_SET: ModData[];

    MIN_OFFENSE_SET: ModData[];


    PERFECT_HEALTH_SET: ModData[];
    PERFECT_TENACITY_SET: ModData[];
    PERFECT_POTENCY_SET: ModData[];
    PERFECT_CRIT_DAMAGE_SET: ModData[];
    PERFECT_CRIT_CHANCE_SET: ModData[];
    PERFECT_DEFENSE_SET: ModData[];

    MIN_DEFENSE_SET: ModData[];

    PERFECT_PROTECTION_SET: ModData[];
    PERFECT_CRIT_AVOID_SET: ModData[];
    PERFECT_ACCURACY_SET: ModData[];

    PERFECT_MODS: ModData[] = [];

    static addModsTo(listToAdd: ModData[], set: ModSets, slot: ModSlots, primaries: string[])
    {
        primaries.forEach(primary =>
        {
            const mod = PerfectSets.bm(set, slot, primary, []);
            mod.id = set + "_" + slot + "_" + mod.primary.statId;
            listToAdd.push(mod);
        });
    }

    constructor()
    {

        PerfectSets.MOD_SETS_LIST.forEach(set =>
        {
            PerfectSets.addModsTo(this.PERFECT_MODS, set, ModSlots.Square, [perfectOffensePrimary]);
            PerfectSets.addModsTo(this.PERFECT_MODS, set, ModSlots.Diamond, [perfectDefensePrimary]);
            PerfectSets.addModsTo(this.PERFECT_MODS, set, ModSlots.Circle, [perfectProtectionPrimary, perfectHealthPrimary]);
            PerfectSets.addModsTo(this.PERFECT_MODS, set, ModSlots.Cross, [perfectProtectionPrimary, perfectHealthPrimary,
                perfectOffensePrimary, perfectDefensePrimary, perfectPotencyPrimary, perfectTenacityPrimary]);
            PerfectSets.addModsTo(this.PERFECT_MODS, set, ModSlots.Triangle, [perfectProtectionPrimary, perfectHealthPrimary,
                perfectOffensePrimary, perfectDefensePrimary, perfectCritChancePrimary, perfectCritDamagePrimary]);
            PerfectSets.addModsTo(this.PERFECT_MODS, set, ModSlots.Arrow, [perfectProtectionPrimary, perfectHealthPrimary,
                perfectOffensePrimary, perfectDefensePrimary, perfectSpeedPrimary, perfectCritAvoidPrimary, perfectAccuracyPrimary]);
        });

        this.PERFECT_SPEED_SET = [
            PerfectSets.bm(ModSets.Speed, ModSlots.Square, perfectOffensePrimary, [perfectSpeedSecondary]),
            PerfectSets.bm(ModSets.Speed, ModSlots.Diamond, perfectDefensePrimary, [perfectSpeedSecondary]),
            PerfectSets.bm(ModSets.Speed, ModSlots.Circle, perfectHealthPrimary, [perfectSpeedSecondary]),
            PerfectSets.bm(ModSets.Speed, ModSlots.Cross, perfectHealthPrimary, [perfectSpeedSecondary]),
            PerfectSets.bm(ModSets.Speed, ModSlots.Triangle, perfectHealthPrimary, [perfectSpeedSecondary]),
            PerfectSets.bm(ModSets.Speed, ModSlots.Arrow, perfectSpeedPrimary, [])
        ];

        this.MIN_OFFENSE_SET = [
            PerfectSets.bm(ModSets.Offense, ModSlots.Square, perfectOffensePrimary, [])
        ];


        this.PERFECT_OFFENSE_SET = [
            PerfectSets.bm(ModSets.Offense, ModSlots.Square, perfectOffensePrimary, [perfectOffenseFlatSecondary]),
            PerfectSets.bm(ModSets.Offense, ModSlots.Diamond, perfectDefensePrimary, [perfectOffenseFlatSecondary, perfectOffensePercentSecondary]),
            PerfectSets.bm(ModSets.Offense, ModSlots.Circle, perfectHealthPrimary, [perfectOffenseFlatSecondary, perfectOffensePercentSecondary]),
            PerfectSets.bm(ModSets.Offense, ModSlots.Cross, perfectOffensePrimary, [perfectOffenseFlatSecondary]),
            PerfectSets.bm(ModSets.Offense, ModSlots.Triangle, perfectOffensePrimary, [perfectOffenseFlatSecondary]),
            PerfectSets.bm(ModSets.Offense, ModSlots.Arrow, perfectOffensePrimary, [perfectOffenseFlatSecondary])
        ];

        this.PERFECT_HEALTH_SET = [
            PerfectSets.bm(ModSets.Health, ModSlots.Square, perfectOffensePrimary, [perfectHealthFlatSecondary, perfectHealthPercentSecondary]),
            PerfectSets.bm(ModSets.Health, ModSlots.Diamond, perfectDefensePrimary, [perfectHealthFlatSecondary, perfectHealthPercentSecondary]),
            PerfectSets.bm(ModSets.Health, ModSlots.Circle, perfectHealthPrimary, [perfectHealthFlatSecondary]),
            PerfectSets.bm(ModSets.Health, ModSlots.Cross, perfectHealthPrimary, [perfectHealthFlatSecondary]),
            PerfectSets.bm(ModSets.Health, ModSlots.Triangle, perfectHealthPrimary, [perfectHealthFlatSecondary]),
            PerfectSets.bm(ModSets.Health, ModSlots.Arrow, perfectHealthPrimary, [perfectHealthFlatSecondary])
        ];

        this.PERFECT_TENACITY_SET = [
            PerfectSets.bm(ModSets.Tenacity, ModSlots.Square, perfectOffensePrimary, [perfectTenacitySecondary]),
            PerfectSets.bm(ModSets.Tenacity, ModSlots.Diamond, perfectDefensePrimary, [perfectTenacitySecondary]),
            PerfectSets.bm(ModSets.Tenacity, ModSlots.Circle, perfectHealthPrimary, [perfectTenacitySecondary]),
            PerfectSets.bm(ModSets.Tenacity, ModSlots.Cross, perfectTenacityPrimary, []),
            PerfectSets.bm(ModSets.Tenacity, ModSlots.Triangle, perfectHealthPrimary, [perfectTenacitySecondary]),
            PerfectSets.bm(ModSets.Tenacity, ModSlots.Arrow, perfectHealthPrimary, [perfectTenacitySecondary])
        ];

        this.PERFECT_POTENCY_SET = [
            PerfectSets.bm(ModSets.Potency, ModSlots.Square, perfectOffensePrimary, [perfectPotencySecondary]),
            PerfectSets.bm(ModSets.Potency, ModSlots.Diamond, perfectDefensePrimary, [perfectPotencySecondary]),
            PerfectSets.bm(ModSets.Potency, ModSlots.Circle, perfectHealthPrimary, [perfectPotencySecondary]),
            PerfectSets.bm(ModSets.Potency, ModSlots.Cross, perfectPotencyPrimary, []),
            PerfectSets.bm(ModSets.Potency, ModSlots.Triangle, perfectHealthPrimary, [perfectPotencySecondary]),
            PerfectSets.bm(ModSets.Potency, ModSlots.Arrow, perfectHealthPrimary, [perfectPotencySecondary])
        ];

        this.PERFECT_CRIT_DAMAGE_SET = [
            PerfectSets.bm(ModSets.CriticalDamage, ModSlots.Square, perfectOffensePrimary, []),
            PerfectSets.bm(ModSets.CriticalDamage, ModSlots.Diamond, perfectDefensePrimary, []),
            PerfectSets.bm(ModSets.CriticalDamage, ModSlots.Circle, perfectHealthPrimary, []),
            PerfectSets.bm(ModSets.CriticalDamage, ModSlots.Cross, perfectHealthPrimary, []),
            PerfectSets.bm(ModSets.CriticalDamage, ModSlots.Triangle, perfectCritDamagePrimary, []),
            PerfectSets.bm(ModSets.CriticalDamage, ModSlots.Arrow, perfectHealthPrimary, [])
        ];

        this.PERFECT_CRIT_CHANCE_SET = [
            PerfectSets.bm(ModSets.CriticalChance, ModSlots.Square, perfectOffensePrimary, [perfectCritChanceSecondary]),
            PerfectSets.bm(ModSets.CriticalChance, ModSlots.Diamond, perfectDefensePrimary, [perfectCritChanceSecondary]),
            PerfectSets.bm(ModSets.CriticalChance, ModSlots.Circle, perfectHealthPrimary, [perfectCritChanceSecondary]),
            PerfectSets.bm(ModSets.CriticalChance, ModSlots.Cross, perfectHealthPrimary, [perfectCritChanceSecondary]),
            PerfectSets.bm(ModSets.CriticalChance, ModSlots.Triangle, perfectCritChancePrimary, []),
            PerfectSets.bm(ModSets.CriticalChance, ModSlots.Arrow, perfectHealthPrimary, [perfectCritChanceSecondary])
        ];

        this.PERFECT_DEFENSE_SET = [
            PerfectSets.bm(ModSets.Defense, ModSlots.Square, perfectOffensePrimary, [perfectDefenseFlatSecondary]),
            PerfectSets.bm(ModSets.Defense, ModSlots.Diamond, perfectDefensePrimary, [perfectDefenseFlatSecondary]),
            PerfectSets.bm(ModSets.Defense, ModSlots.Circle, perfectHealthPrimary, [perfectDefenseFlatSecondary, perfectDefensePercentSecondary]),
            PerfectSets.bm(ModSets.Defense, ModSlots.Cross, perfectDefensePrimary, [perfectDefenseFlatSecondary]),
            PerfectSets.bm(ModSets.Defense, ModSlots.Triangle, perfectDefensePrimary, [perfectDefenseFlatSecondary]),
            PerfectSets.bm(ModSets.Defense, ModSlots.Arrow, perfectDefensePrimary, [perfectDefenseFlatSecondary])
        ];

        this.MIN_DEFENSE_SET = [
            PerfectSets.bm(ModSets.Defense, ModSlots.Circle, perfectHealthPrimary, [perfectDefenseFlatSecondary, perfectDefensePercentSecondary])
        ];


        this.PERFECT_PROTECTION_SET = [
            PerfectSets.bm(ModSets.Health, ModSlots.Square, perfectOffensePrimary, [perfectProtectionFlatSecondary, perfectProtectionPercentSecondary]),
            PerfectSets.bm(ModSets.Health, ModSlots.Diamond, perfectDefensePrimary, [perfectProtectionFlatSecondary, perfectProtectionPercentSecondary]),
            PerfectSets.bm(ModSets.Health, ModSlots.Circle, perfectProtectionPrimary, [perfectProtectionFlatSecondary]),
            PerfectSets.bm(ModSets.Health, ModSlots.Cross, perfectProtectionPrimary, [perfectProtectionFlatSecondary]),
            PerfectSets.bm(ModSets.Health, ModSlots.Triangle, perfectProtectionPrimary, [perfectProtectionFlatSecondary]),
            PerfectSets.bm(ModSets.Health, ModSlots.Arrow, perfectProtectionPrimary, [perfectProtectionFlatSecondary])
        ];

        this.PERFECT_CRIT_AVOID_SET = [
            PerfectSets.bm(ModSets.Health, ModSlots.Arrow, perfectCritAvoidPrimary, [])
        ];

        this.PERFECT_ACCURACY_SET = [
            PerfectSets.bm(ModSets.Health, ModSlots.Arrow, perfectAccuracyPrimary, [])
        ];
    }

    static bm(set: ModSets, slot: ModSlots, primary: string, secondary: string[]): ModData
    {
        let mod = JSON.parse(defaultMod);
        let retVal = new ModData(mod, null, false);

        retVal.set = set;
        retVal.slot = slot;
        retVal.primary = new ModStat(JSON.parse(primary));
        retVal.secondaries = [];
        retVal.secondaries = secondary.map(s =>
        {
            return new ModStat(JSON.parse(s));
        });

        return retVal;
    }
}


