import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
    activeColor: '',
    backdrop: '#f0f2f5',
    header: "#001529",
    body: '#fff',
    text: '#595959',
    border: '#d9d9d9',
    cardHeader: '#f5f7f8',
    cardBackground: 'rgba(23,191,255,0.04)',
    cardBorder: '#63b3ff',
    buttonColor: '#d9d9d9',
    buttonText: '#121212',
    buttonBorder: '',
    bgBreadcrumb: '#f8f8f8',
    buttonHover: '#40a9ff',
    textHover: '#121212',
    buttonToggle: '',
    toggleBorder: '#FFF',
    background: '#363537',
    modalBackground: '#FFF',
    alertBackground: '',
    alertBorder: '',
    alertShadow: '',
    alertText: '',
    buttonDisableColor: '',
    buttonDisableText: '',
    buttonDisableBorder: '',
    checkboxBackground: '',
    checkboxDisableBackground: '',
    primaryButtonColor: '#1890ff',
    primaryButtonHover: '#40a9ff',
    primaryButtonText: '#fff',
    primaryButtonDisabledColor: '#f5f5f5',
    primaryButtonDisabledText: 'rgba(0, 0, 0, 0.25)',
    primaryButtonDisabledBorder: '#d9d9d9',
    paginatorColor: '',
    paginatorBorder: '',
    tableHover: '',
    dangerButtonColor: 'transparent',
    dangerButtonDefaultText: '#fff',
    dangerButtonHover: 'transparent',
    dangerButtonText: '#ff4d4f',
    dangerButtonHoverText: '#d32029',
    txtButtonText: 'rgba(0, 0, 0, .85)',
    txtButtonColor: 'transparent',
    txtButtonHover: '#0000d9',
    transparent: 'transparent',
    itemSelected: '',
    toolbarColor: 'rgb(243, 248, 255)',
    inputBackground: '',
    inputText: 'rgba(0, 0, 0, 0.85)',
    inputBorder: '',
    inputDisabled: '',
    inputDisabledBorder: '',
    inputDisabledText: '#5A5A5A',
    red100: '#6f4747',
    red200: '#a95454',
    red300: '#d63c3c',
    red400: '#f90707',
    modsSelected: 'aquamarine',
    titleBattleZones: '#F2F7FB',
    borderBattleZones: '#d2dce6',
    highlightRow: '#dde7f8',
    summaryOddBackground: '#FFDBDB',
    summaryEvenBackground: '#f8f8ff',
    modalRowHighlighted: '#EBF1FB'
};

export const darkTheme: DefaultTheme = {
    activeColor: '#63b3ff',
    backdrop: '#000',
    body: "#141414",
    header: "#0c1c2b",
    text: "#f2f2f2",
    border: '#434343',
    bgBreadcrumb: '#2f2f2f',
    buttonColor: '',
    buttonText: '#ACACAC',
    buttonBorder: '',
    buttonHover: '#40a9ff',
    cardHeader: '#2f2f2f',
    cardBackground: 'rgba(23,191,255,0.02)',
    cardBorder: '#63b3ff',
    textHover: '#121212',
    buttonToggle: '#bfbfbf',
    toggleBorder: '#6B8096',
    background: '#999',
    modalBackground: '#141414',
    alertBackground: '#141414',
    alertBorder: '0',
    alertShadow: '0px 3px 6px 0 #000',
    alertText: '#f2f2f2',
    buttonDisableColor: '#262626',
    buttonDisableText: '#5A5A5A',
    buttonDisableBorder: '#434343',
    checkboxBackground: '#121212',
    checkboxDisableBackground: '#434343',
    paginatorColor: '#000',
    paginatorBorder: '#ACACAC',
    primaryButtonColor: '#1890ff',
    primaryButtonHover: '#40a9ff',
    primaryButtonText: '#fff',
    primaryButtonDisabledColor: '#262626',
    primaryButtonDisabledText: '#5A5A5A',
    primaryButtonDisabledBorder: '#434343',
    txtButtonText: 'rgba(0, 0, 0, .85)',
    txtButtonColor: 'transparent',
    txtButtonHover: '#0000d9',
    tableHover: '#1D1D1D',
    dangerButtonColor: 'transparent',
    dangerButtonHover: 'transparent',
    dangerButtonDefaultText: '#ffebeb',
    dangerButtonText: '#ef131d',
    dangerButtonHoverText: '#791A1F',
    transparent: 'transparent',
    itemSelected: '#111D2C',
    inputBackground: '#141414',
    toolbarColor: '#000',
    inputText: '#ACACAC',
    inputBorder: '#ACACAC',
    inputDisabled: '#262626',
    inputDisabledBorder: '#434343',
    inputDisabledText: '#5A5A5A',
    red100: '#F89F9A',
    red200: '#F37370',
    red300: '#E84749',
    red400: '#D32029',
    modsSelected: '#138585',
    titleBattleZones: '#0f2435',
    borderBattleZones: '#0f2435',
    highlightRow: '#27466d',
    summaryOddBackground: '#121212',
    summaryEvenBackground: '#262626',
    modalRowHighlighted: '#1b2433'
};

