import { Modal, Progress, Spin } from "antd";
import i18next from "i18next";
import { reaction, when } from "mobx";
import React from "react";
import { APITask } from "../service/APITask";
import styled from "styled-components";

const DlgContents = styled.div` 
    display: flex;
    flex-direction: column;
`;

export async function waitForTask(task: APITask, dlgTitle: string): Promise<void>
{
    let waitDlg = Modal.info({ maskClosable: false, okText: i18next.t("common:ui.task_cancel_button"), onOk: () => task.cancel(), title: dlgTitle, content: <Spin size="large" /> });

    let updateDisposer = reaction(() => { return {msg: task.cleanResponseMessage, curr: task.currProgress, max: task.maxProgress}; }, (arg: {msg: string, curr: number, max: number}) => waitDlg.update(
        { 
            maskClosable: false, 
            okText: i18next.t("common:ui.task_cancel_button"), onOk: (dummy: any) => { return task.cancel(); }, 
            title: dlgTitle, content: <DlgContents>
                {arg.msg}
                <Progress percent={Math.trunc(arg.curr / arg.max * 100)}/>
                </DlgContents> 
        }));
    
    await when(() => !task.running);

    updateDisposer();
    waitDlg.destroy();
}
