import React from "react";
import ModSets from './../../../model/ModSets';
import styled from 'styled-components';

import speedImg from './img/icon_set_speed.png';
import tenacityImg from './img/icon_set_tenacity.png';
import potencyImg from './img/icon_set_accuracy.png';
import offenseImg from './img/icon_set_attack.png';
import healthImg from './img/icon_set_health.png';
import defenseImg from './img/icon_set_armor.png';
import critDamageImg from './img/icon_set_critical_damage.png';
import critChanceImg from './img/icon_set_crit_chance.png';


const SetButton = styled.div`
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
`;

const SpeedButton = styled(SetButton)`
    background-image: url(${speedImg});
`;

const TenacityButton = styled(SetButton)`
    background-image: url(${tenacityImg});
`;

const PotencyButton = styled(SetButton)`
    background-image: url(${potencyImg});
`;

const OffenseButton = styled(SetButton)`
    background-image: url(${offenseImg});
`;

const HealthButton = styled(SetButton)`
    background-image: url(${healthImg});
`;

const DefenseButton = styled(SetButton)`
    background-image: url(${defenseImg});
`;

const CritDamageButton = styled(SetButton)`
    background-image: url(${critDamageImg});
`;

const CritChanceButton = styled(SetButton)`
    background-image: url(${critChanceImg});
`;

interface IProps
{
    set: ModSets;
    className?: string;
    onClick?: () => void;
}

const ModSetIcon = ({ set, onClick, className }: IProps) =>
{
    switch (set)
    {
        case (ModSets.Speed):
            return <SpeedButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
        case (ModSets.Tenacity):
            return <TenacityButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
        case (ModSets.Potency):
            return <PotencyButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
        case (ModSets.Offense):
            return <OffenseButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
        case (ModSets.Health):
            return <HealthButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
        case (ModSets.Defense):
            return <DefenseButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
        case (ModSets.CriticalDamage):
            return <CritDamageButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
        case (ModSets.CriticalChance):
            return <CritChanceButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
    }

    return null;
};


export default ModSetIcon;