import React from "react";
import {observer} from "mobx-react";
import IPageProps from "../../../IPageProps";
import {action, observable, runInAction} from "mobx";
import {Button, InputNumber, Modal} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsDownToPeople, faPeopleArrowsLeftRight, faSave} from "@fortawesome/pro-duotone-svg-icons";
import { squadsTW, zonesTW} from "../../../../model/TWPlanning";
import styles from "../../../../styles/components/Modal.module.scss";

interface ITWSquadZoneModalProps extends IPageProps{
    showModal: boolean;
    onClose: () => void;
    onSave: (squadTW: squadsTW | undefined, squadLeader: string | undefined, switchUnits: boolean, moveUnits: boolean, zoneId: number, newZoneId: number) => void;
    zone: zonesTW;
    zoneId: number;
    switchUnits: boolean;
    squadTW?: squadsTW;
    squadLeader?: string;
}

@observer
export class TWSquadZoneModal extends React.PureComponent<ITWSquadZoneModalProps> {
    @observable zoneId = this.props.zoneId;

    @action private cancel() {
        return "";
    }

    render() {
        const squadLeader = this.props.squadLeader ? this.props.gameData.getUnit(this.props.squadLeader)?.name : "";

        return (
            <Modal
                title={`Change the assigned zone for ${this.props.squadTW ? this.props.squadTW.playerName : squadLeader} squad `}
                visible={this.props.showModal}
                onCancel={this.props.onClose}
                footer={[
                    !this.props.switchUnits && <Button key="saveSquadZone" type="primary" onClick={() => {
                        if (this.props.zone.id !== this.zoneId) {
                            runInAction(() => {
                                if (this.props.squadTW) {
                                    let squadTW = this.props.squadTW;

                                    squadTW.zoneId = this.zoneId;
                                    this.props.onSave(squadTW, undefined, false, false, this.props.zone.id, this.zoneId);
                                } else {
                                    this.props.onSave(undefined, this.props.squadLeader, false, false, this.props.zone.id, this.zoneId);
                                }
                            })
                        }
                    }} disabled={this.zoneId === this.props.zone.id}>
                        <FontAwesomeIcon icon={faSave} className={styles.icon}/>
                        Save
                    </Button>,
                    this.props.switchUnits && <Button key="moveSquadsZone" type="default" onClick={() => {
                        if (this.props.zone.id !== this.zoneId) {
                            runInAction(() => {
                                this.props.onSave(undefined, undefined, true, true, this.props.zone.id, this.zoneId);
                            })
                        }
                    }} disabled={this.zoneId === this.props.zone.id}>
						<FontAwesomeIcon icon={faArrowsDownToPeople} className={styles.icon}/>
						Move all squads to zone {this.zoneId}
					</Button>,
                    this.props.switchUnits && <Button key="switchSquadsZone" type="primary" onClick={() => {
                        if (this.props.zone.id !== this.zoneId) {
                            runInAction(() => {
                                this.props.onSave(undefined, undefined, true, false, this.props.zone.id, this.zoneId);
                            })
                        }
                    }} disabled={this.zoneId === this.props.zone.id}>
						<FontAwesomeIcon icon={faPeopleArrowsLeftRight} className={styles.icon}/>
						Switch with zone {this.zoneId}
					</Button>
                ]}
            >
                <div className={styles.modal}>
                    <div className={styles.row}>
                        <div className={styles.label}>Move from zone <strong>#{this.props.zone.id}</strong> to:</div>
                        <InputNumber
                            className={`${styles.input}`}
                            style={{width: '100%'}}
                            value={this.zoneId}
                            max={10}
                            min={1}
                            onChange={value => {
                                runInAction(() => {
                                    this.zoneId = value;
                                })
                            }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}