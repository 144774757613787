import React from "react";
import UnitData, { IBasicUnitData } from './../../../model/UnitData';

import styles from "./styles/UnitAvatar.module.scss";

interface IProps {
    children?: React.ReactNode;
    playerUnit?: IBasicUnitData;
    unitData: UnitData;
    displayName?: boolean;
    className?: string;
    opacity?: string;
    onClick?: () => void;
    size: "large" | "medium" | "small" | "tiny";
    // any other props that come into the component
}

export default function UnitAvatar({ children, playerUnit, unitData, displayName, className, opacity, size, onClick }: IProps) {

    const sizeStyle = "size-" + size;

    if (!playerUnit) {
        return (
        <div
            className={`${className} ${styles['unit-simple-avatar']} ${styles[sizeStyle]}`}
            onClick={() => onClick !== undefined ? onClick() : undefined}
        >
            {unitData && <img
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                src={'https://api.hotutils.com/images' + unitData.baseImage}
                className={`${unitData.combatType === 2 && styles.ship} ${styles.simpleAvatar}`}
                alt={unitData.name}
                title={unitData.name}
            />}
            {children}
        </div>);
    }

    const rarity = playerUnit.rarity ?? playerUnit.stars;
    const stars = [];

    if (rarity) {
        for (let i = 0; i < 7; i++) {

            stars.push(
                <span key={i} className={` ${styles.star} ${unitData.combatType === 2 ? styles.ship : styles.squad} ${styles[`star-${i + 1}`]} ${rarity <= i ? styles[`star-inactive`] : ''}`} />
            );
        }
    }

    const forceAlignment =  unitData.alignment === 2 ? "light" : unitData.alignment === 3 ? "dark" : "neutral";
    const gear = playerUnit.gearLevel;
    const styleForceAlignment = "force-" + forceAlignment;
    const imageAvatar = "https://api.hotutils.com/images" + unitData.baseImage;

    return (
        <div className={className} style={{opacity: opacity}} onClick={() => onClick !== undefined ? onClick() : undefined}>
            <div className={`${styles['unit-avatar']} ${styles[sizeStyle]} ${unitData.combatType === 2 ? styles.ship : ''}`}  title={unitData.name} >
                <div className={`${styles[styleForceAlignment]} ${styles.wrapper} ${unitData.combatType === 1 ? 'circle' : 'square'}`}>
                    <div className={`${styles.avatar}`} >
                        <img src={imageAvatar} alt={playerUnit.baseId} />
                    </div>

                    {(unitData.combatType === 1  && gear) ? (
                        <span
                            className={styles["gear" + (gear === 13 ? 13 : '')]}
                            style={{
                                backgroundImage: gear.toString() === '13' ? `url(https://api.hotutils.com/images/gear/g13-atlas.png)` :
                                    `url(https://api.hotutils.com/images/gear/g${Number(gear)}.png)`
                            }}
                        />
                    ) : unitData.combatType === 2 ? (
                        <div className={styles.ship}>
                            <span
                                className={styles.frame}
                                style={{
                                    backgroundImage: `url(https://api.hotutils.com/images/ships/${playerUnit.baseId.toLowerCase().includes('capital') ? 'commander' : 'ship'}-frame.png`
                                }}
                            />
                            <div className={styles.lvl}>{playerUnit.level}</div>
                        </div>
                    ) : null}

                    {stars ? stars : null}

                    {(playerUnit.zetaCount > 0 || playerUnit.zetaLead) ? <span className={styles.zetas}>
                        {Number(playerUnit.zetaCount) === 0 ? 'L' : Number(playerUnit.zetaCount)}
                    </span> : null}
                    {playerUnit.omiCount > 0 ? <span className={styles.omicrons}><span className={styles.txt}>{playerUnit.omiCount}</span> </span> : null}
                    {playerUnit.relicLevel > 0 ? <span className={`${styles.relic} ${playerUnit.ultimate ? styles.ultimate : ''}`}>{playerUnit.relicLevel}</span> : null}
                </div>

                {/*{speed ? <span className="unit-avatar-highlight">{speed.replace(/^0+/, '')}</span> : null}*/}
            </div>
            {displayName ? <p className={styles.name}>{unitData.name}</p> : null}

            {children}
        </div>
    );
};