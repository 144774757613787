enum ModSlots
{
    Square = 1,
    Arrow = 2,
    Diamond = 3,
    Triangle = 4,
    Circle = 5,
    Cross = 6
}


export function slotIdToString(slot: ModSlots): string 
{
    switch (slot)
    {
        case ModSlots.Square:
            return "Transmitter";
        case ModSlots.Arrow:
            return "Receiver";
        case ModSlots.Diamond:
            return "Processor";
        case ModSlots.Triangle:
            return "Holo-Array";
        case ModSlots.Circle:
            return "Data-Bus";
        case ModSlots.Cross:
            return "Multiplexer";
    }
}

export function commonSlotIdToString(slot: ModSlots): string 
{
    switch (slot)
    {
        case ModSlots.Square:
            return "Square";
        case ModSlots.Arrow:
            return "Arrow";
        case ModSlots.Diamond:
            return "Diamond";
        case ModSlots.Triangle:
            return "Triangle";
        case ModSlots.Circle:
            return "Circle";
        case ModSlots.Cross:
            return "Cross";
    }
}

export default ModSlots;
