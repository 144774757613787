
import TerritoryBattleData, { TerritoryBattleZoneStatus, LeaderBoard } from './../../model/TerritoryBattleData';
import { TerritoryBattleGameData, TerritoryBattleDefinition, StrikeZoneDefinition, RewardRow, CovertZoneDefinition } from './../../model/TerritoryBattleGameData';
import TerritoryBattleStatusPreferences, { SandBagZone } from './../../model/TerritoryBattleStatusPreferences';
import { TerritoryBattlePlayerCalc } from './tb-player-calcs';
import { ZoneTarget } from './../../model/TerritoryBattlePreferences';

export enum SandBagStatus
{
    NoSandBag = 1,
    CombatZonesOnly,
    FleetZonesOnly,
    CombatAndFleet,
    Unknown
}

export enum ZoneLocation
{
    Top = 1,
    Middle,
    Bottom
}

export class ZoneStarProgress
{
    star: number;
    completed: boolean = false;
    remainingGpRequired: number = 0;
    totalGpRequired: number = 0;
    startingGpRequired: number = 0;
    minMissionGpRequired: number = 0;
    minPlatoonGpRequired: number = 0;
    missionSuccessRateRequired: number = 0;

    constructor(star: number)
    {
        this.star = star;
    }
}

export class ZoneProgress
{
    zoneId: string;
    location: ZoneLocation;

    fleetZone: boolean = false;
    stars: ZoneStarProgress[] = [];

    starsObtained: number = 0;
    starsTotal: number = 0;

    totalGpForAllStars: number = 0;
    score: number = 0;

    get zoneCompleted()
    {
        return this.starsObtained === this.starsTotal;
    }

    get overshootAmount(): number | undefined
    {
        return this.zoneCompleted ? this.score - this.totalGpForAllStars : undefined;
    }

    zoneWasSandbagged: boolean = false;
    remainingMissionsGpPossible: number = 0;

    constructor(zoneId: string, location: ZoneLocation)
    {
        this.zoneId = zoneId;
        this.location = location;
    }
}

export class PossibleSandBagZone
{
    zoneId: string;
    location: ZoneLocation;
    platoonGroups: number;

    constructor(zoneId: string, location: ZoneLocation, platoonGroups: number)
    {
        this.zoneId = zoneId;
        this.location = location;
        this.platoonGroups = platoonGroups;
    }
}

export class MissionChart
{
    currentPointValue: number = 0;
    maxPointsRemaining: number = 0;
    targetPointValue: number = 0;
    maxPointsRound: number = 0;

}

export class TbStatusCalculationResults
{
    tbActive: boolean = false;

    fleetZoneExists: boolean = false;
    fleetZoneCompleted: boolean = false;
    fleetZoneId: string | undefined = undefined;

    get totalGuildDeploymentPower()
    {
        return this.totalGuildCombatDeploymentPower + this.totalGuildFleetDeploymentPower;
    }
    totalGuildCombatDeploymentPower: number = 0;
    totalGuildFleetDeploymentPower: number = 0;

    gpEarnedThisRound: number = 0;

    totalDeploymentGpEarnedThisRound: number = 0;
    totalMissionGpEarnedThisRound: number = 0;

    // ----Scores for currently open zones, may include sandbag ----
    openZonesScore: number = 0;

    fleetZonesScore: number = 0;
    fleetZonesMissionsScore: number = 0;
    fleetPlatoonBonusScore: number = 0;

    combatZonesScore: number = 0;
    combatZonesMissionsScore: number = 0;

    totalUnitMissionPointsPossibleRound: number = 0;
    totalUnitMissionPointsPossibleRemainingRound: number = 0;

    totalFleetMissionPointsPossibleRound: number = 0;
    totalFleetMissionPointsPossibleRemainingRound: number = 0;


    combatPlatoonBonusScore: number = 0;

    get platoonGpScore(): number
    {
        return this.combatPlatoonBonusScore + this.fleetPlatoonBonusScore;
    }
    get platoonBonusGpScore(): number
    {
        return this.combatPlatoonBonusScore + this.fleetPlatoonBonusScore;
    }
    // --------------------------------------------------------------

    combatDeploymentPowerRemaining: number = 0;
    fleetDeploymentPowerRemaining: number = 0;

    sandBaggedZoneExists: boolean = false;
    sandBagStatus: SandBagStatus = SandBagStatus.NoSandBag;
    sandBagPlatoonBonusGp: number = 0;

    get platoonBonusGpEarnedThisRound()
    {
        return this.platoonBonusGpScore - this.sandBagPlatoonBonusGp;
    }

    zoneGoalsPossible: boolean = true;
    additionalCharacterMissionGpRequired: number = 0;
    totalCharacterMissionGpRequired: number = 0;
    characterSuccessRateRequired: number = 0;

    possibleSandbagZones: PossibleSandBagZone[] = [];

    zonesProgress: ZoneProgress[] = [];

    mostGpRequiredInOpenZone: number = 0;

    pointsMap: Map<number, RewardCombinations> = new Map();

    uniqueUnitMissionsCount: Map<string, number> = new Map(); // these will be used for creating export columns for the players table
    uniqueFleetMissionsCount: Map<string, number> = new Map();

    fleetMissionsCount: number = 0;
    unitsMissionsCount: number = 0;
    fleetSpecialMissionCount: number = 0;
    combatSpecialMissionCount: number = 0;

    zoneTargets: ZoneTarget[] = [];


    get unitMissionChart(): MissionChart
    {
        return {
            currentPointValue: this.combatZonesMissionsScore,
            maxPointsRemaining: this.totalUnitMissionPointsPossibleRemainingRound,
            targetPointValue: this.totalCharacterMissionGpRequired,
            maxPointsRound: this.totalUnitMissionPointsPossibleRound
        }
    };
    get fleetMissionChart(): MissionChart
    {
        let shipTargetPointValue: number | undefined = undefined;
        let fleetZp = this.zonesProgress.find(zp => zp.fleetZone === true);

        if (fleetZp !== undefined)
        {
            fleetZp.stars.forEach(s =>
            {
                let zt = this.zoneTargets.find(zt => zt.zoneId === fleetZp!.zoneId && zt.star === s.star);
                if (zt !== undefined)
                {
                    shipTargetPointValue = s.minMissionGpRequired;
                }
            });

        }

        return {
            currentPointValue: this.fleetZonesMissionsScore,
            maxPointsRemaining: this.totalFleetMissionPointsPossibleRemainingRound,
            targetPointValue: shipTargetPointValue === undefined ? -1 : shipTargetPointValue,
            maxPointsRound: this.totalFleetMissionPointsPossibleRound
        }
    };
}

export class CombatMissionReward
{
    wavesCompleted: number;
    totalWavesPossible: number;
    gpReward: number;
    fleet: boolean;

    missionRewardKey: string;

    constructor(wavesCompleted: number, totalWavesPossible: number, fleet: boolean, gpReward: number, missionRewardKeys: number[])
    {
        this.wavesCompleted = wavesCompleted;
        this.totalWavesPossible = totalWavesPossible;
        this.gpReward = gpReward;
        this.fleet = fleet;
        this.missionRewardKey = missionRewardKeys.map(mrk => mrk.toString()).join("_");
    }

    fromJson(json: any): CombatMissionReward
    {
        return new CombatMissionReward(json.wavesCompleted, json.totalWavesPossible, json.fleet, json.gpReward, json.missionRewardKeys);
    }

    same(cmr: CombatMissionReward)
    {
        return this.wavesCompleted === cmr.wavesCompleted && this.totalWavesPossible === cmr.totalWavesPossible && this.gpReward === cmr.gpReward && this.fleet === cmr.fleet;
    }
}

export class CombatInstance
{
    zoneId: string;
    location: ZoneLocation;
    parentZoneId: string;

    constructor(zoneId: string, parentZoneId: string, location: ZoneLocation)
    {
        this.zoneId = zoneId;
        this.location = location;
        this.parentZoneId = parentZoneId;
    }
}

export class CombatMission
{
    combatInstances: CombatInstance[];

    location: ZoneLocation;
    fleet: boolean;

    rewards: CombatMissionReward[] = [];

    constructor(zoneId: string, parentZoneId: string, location: ZoneLocation, fleet: boolean)
    {
        this.combatInstances = [new CombatInstance(zoneId, parentZoneId, location)];
        this.location = location;
        this.fleet = fleet;
    }

    same(cm: CombatMission)
    {
        return this.fleet === cm.fleet && this.rewards.length === cm.rewards.length && this.rewards.filter(tr => cm.rewards.find(or =>
        {
            return or.gpReward === tr.gpReward && or.totalWavesPossible === tr.totalWavesPossible && or.wavesCompleted === tr.wavesCompleted;
        }) !== undefined).length === this.rewards.length;
    }
}

export class RewardCombinations
{
    rewardAmount: number;
    cmrs: CombatMissionReward[][] = [];

    constructor(rewardAmount: number)
    {
        this.rewardAmount = rewardAmount;
    }
}

export interface CombatMissionsCombinations
{
    cms: CombatMission[];
    fleetSpecialMissionCount: number;
    combatSpecialMissionCount: number;
    fleetMissionsCount: number;
    unitsMissionsCount: number;
    uniqueFleetMissionsCount: Map<string, number>;
    uniqueUnitMissionsCount: Map<string, number>;
}

class TbStatusCalcs 
{
    static ZONE_OPEN: string = "Zoneopen";
    static SHIP_COMBAT_TYPE = 2;
    static GP_REWARD_TYPE = 1;

    public static calculateTbStatus(tbData: TerritoryBattleData,
        tbGameData: TerritoryBattleGameData,
        statusPrefs: TerritoryBattleStatusPreferences,
        playerCalcs: TerritoryBattlePlayerCalc[],
        zoneTargets: ZoneTarget[]): TbStatusCalculationResults 
    {
        let retVal: TbStatusCalculationResults = new TbStatusCalculationResults();

        let tbBattleDefintion = tbGameData.getTerritoryBattleDefinition(tbData.definitionId);

        let openZones: TerritoryBattleZoneStatus[] = tbData.getOpenZones();

        retVal.tbActive = openZones.length > 0 && tbData.currentRound !== null;
        if (retVal.tbActive === false || tbData.currentRound === null)
        {
            return retVal;
        }

        retVal.zoneTargets = zoneTargets;

        TbStatusCalcs.calculateFleetStatus(tbData, tbBattleDefintion, retVal);
        TbStatusCalcs.calculateOpenZonesScore(openZones, tbData, tbBattleDefintion, retVal);
        TbStatusCalcs.calculateSandbaggedPlatoonBonus(statusPrefs, retVal, tbData, tbBattleDefintion);
        TbStatusCalcs.calculateGuildDeploymentPower(tbData, retVal);
        TbStatusCalcs.calculateGpRound(tbData, tbData.currentRound, retVal);
        TbStatusCalcs.calculateSandbagStatus(retVal, openZones, tbData.currentRound);
        TbStatusCalcs.calculateRemainingDeployableGp(retVal, openZones, statusPrefs, tbData, tbBattleDefintion, playerCalcs);
        TbStatusCalcs.calculateStarProgress(retVal, openZones, tbBattleDefintion, statusPrefs, tbData);
        TbStatusCalcs.calculateSharedUnitGoals(retVal, zoneTargets);

        TbStatusCalcs.calculateTotalMissionPointsRound(openZones, tbBattleDefintion, retVal, tbData);

        let cms: CombatMission[] = [];
        openZones.forEach(zone =>
        {
            let zoneDefinition = tbBattleDefintion.getZoneGameDefinition(zone.status.zoneId);
            let strikeZoneDefinitions: StrikeZoneDefinition[] = tbBattleDefintion.getStrikeZones(zone.status.zoneId);
            let covertZoneDefinitions: CovertZoneDefinition[] = tbBattleDefintion.getCovertZones(zone.status.zoneId);

            let isFleet: boolean = tbBattleDefintion.isZoneFleet(zone.status.zoneId);
            let location: ZoneLocation = TbStatusCalcs.getZoneLocation(zone.status.zoneId, openZones.length);

            retVal.fleetSpecialMissionCount = isFleet ? retVal.fleetSpecialMissionCount + covertZoneDefinitions.length : retVal.fleetSpecialMissionCount;
            retVal.combatSpecialMissionCount = isFleet ? retVal.combatSpecialMissionCount : retVal.combatSpecialMissionCount + covertZoneDefinitions.length;

            strikeZoneDefinitions.sort((szd1, szd2) =>
            {
                let aVal = szd1.reward === null ? 0 : szd1.getGpReward(szd1.reward.row[szd1.reward.row.length - 1]);
                let bVal = szd2.reward === null ? 0 : szd2.getGpReward(szd2.reward.row[szd2.reward.row.length - 1]);
                return aVal - bVal;
            }).forEach(szd =>
            {
                retVal.fleetMissionsCount = isFleet ? retVal.fleetMissionsCount + 1 : retVal.fleetMissionsCount;
                retVal.unitsMissionsCount = isFleet ? retVal.unitsMissionsCount : retVal.unitsMissionsCount + 1;

                let uniqueMissionsCount: Map<string, number> = isFleet ? retVal.uniqueFleetMissionsCount : retVal.uniqueUnitMissionsCount;

                if (szd.reward !== null)
                {

                    let cm = new CombatMission(
                        zoneDefinition.zoneDefinition.zoneId,
                        zone.status.zoneId,
                        location,
                        isFleet
                    );

                    let allRewards: number[] = szd.reward.row.filter((rewardRow: RewardRow) => szd.getGpReward(rewardRow) !== 0).map(rewardRow => szd.getGpReward(rewardRow));

                    let keyString = allRewards.map(ar => ar.toString()).join("_");
                    let currentUmc: number = 0;
                    if (uniqueMissionsCount.has(keyString))
                    {
                        currentUmc = uniqueMissionsCount.get(keyString)!;
                    }
                    uniqueMissionsCount.set(keyString, currentUmc + 1);

                    szd.reward.row.forEach((rewardRow: RewardRow, index: number) =>
                    {
                        if (szd.reward !== null)
                        {
                            let reward = szd.getGpReward(rewardRow);
                            if (reward > 0)
                            {
                                cm.rewards.push(new CombatMissionReward(index, szd.reward.row.length - 1, cm.fleet, reward, allRewards));
                            }
                        }
                    });
                    cms.push(cm);
                }
            });

        });

        TbStatusCalcs.generatePointCombinations(cms, [], retVal.pointsMap);

        return retVal;
    }




    public static generateCms(openZones: TerritoryBattleZoneStatus[], tbBattleDefintion: TerritoryBattleDefinition): CombatMissionsCombinations
    {
        let retVal: CombatMissionsCombinations = {
            cms: [],
            fleetSpecialMissionCount: 0,
            combatSpecialMissionCount: 0,
            fleetMissionsCount: 0,
            unitsMissionsCount: 0,
            uniqueFleetMissionsCount: new Map(),
            uniqueUnitMissionsCount: new Map()
        }

        openZones.forEach(zone =>
        {
            let zoneDefinition = tbBattleDefintion.getZoneGameDefinition(zone.status.zoneId);
            let strikeZoneDefinitions: StrikeZoneDefinition[] = tbBattleDefintion.getStrikeZones(zone.status.zoneId);
            let covertZoneDefinitions: CovertZoneDefinition[] = tbBattleDefintion.getCovertZones(zone.status.zoneId);

            let isFleet: boolean = tbBattleDefintion.isZoneFleet(zone.status.zoneId);
            let location: ZoneLocation = TbStatusCalcs.getZoneLocation(zone.status.zoneId, openZones.length);

            retVal.fleetSpecialMissionCount = isFleet ? retVal.fleetSpecialMissionCount + covertZoneDefinitions.length : retVal.fleetSpecialMissionCount;
            retVal.combatSpecialMissionCount = isFleet ? retVal.combatSpecialMissionCount : retVal.combatSpecialMissionCount + covertZoneDefinitions.length;

            strikeZoneDefinitions.sort((szd1, szd2) =>
            {
                let aVal = szd1.reward === null ? 0 : szd1.getGpReward(szd1.reward.row[szd1.reward.row.length - 1]);
                let bVal = szd2.reward === null ? 0 : szd2.getGpReward(szd2.reward.row[szd2.reward.row.length - 1]);
                return aVal - bVal;
            }).forEach(szd =>
            {
                retVal.fleetMissionsCount = isFleet ? retVal.fleetMissionsCount + 1 : retVal.fleetMissionsCount;
                retVal.unitsMissionsCount = isFleet ? retVal.unitsMissionsCount : retVal.unitsMissionsCount + 1;

                let uniqueMissionsCount: Map<string, number> = isFleet ? retVal.uniqueFleetMissionsCount : retVal.uniqueUnitMissionsCount;

                if (szd.reward !== null)
                {

                    let cm = new CombatMission(
                        zoneDefinition.zoneDefinition.zoneId,
                        zone.status.zoneId,
                        location,
                        isFleet
                    );

                    let allRewards: number[] = szd.reward.row.filter((rewardRow: RewardRow) => szd.getGpReward(rewardRow) !== 0).map(rewardRow => szd.getGpReward(rewardRow));

                    let keyString = allRewards.map(ar => ar.toString()).join("_");
                    let currentUmc: number = 0;
                    if (uniqueMissionsCount.has(keyString))
                    {
                        currentUmc = uniqueMissionsCount.get(keyString)!;
                    }
                    uniqueMissionsCount.set(keyString, currentUmc + 1);

                    szd.reward.row.forEach((rewardRow: RewardRow, index: number) =>
                    {
                        if (szd.reward !== null)
                        {
                            let reward = szd.getGpReward(rewardRow);
                            if (reward > 0)
                            {
                                cm.rewards.push(new CombatMissionReward(index, szd.reward.row.length - 1, cm.fleet, reward, allRewards));
                            }
                        }
                    });
                    retVal.cms.push(cm);
                }
            });
        });
        return retVal;
    }

    private static calculateTotalMissionPointsRound(openZones: TerritoryBattleZoneStatus[], tbBattleDefintion: TerritoryBattleDefinition, retVal: TbStatusCalculationResults, tbData: TerritoryBattleData)
    {
        openZones.forEach(zone =>
        {
            if (tbBattleDefintion.isZoneFleet(zone.status.zoneId))
            {
                retVal.totalFleetMissionPointsPossibleRound = retVal.totalFleetMissionPointsPossibleRound +
                    TbStatusCalcs.calculatePossibleMissionPointsTotal(zone.status.zoneId, tbBattleDefintion, tbData, tbData.players.length);
                retVal.totalFleetMissionPointsPossibleRemainingRound = retVal.totalFleetMissionPointsPossibleRemainingRound +
                    TbStatusCalcs.calculatePossibleMissionPointsRemaining(zone.status.zoneId, tbBattleDefintion, tbData, tbData.players.length);
            }
            else
            {
                retVal.totalUnitMissionPointsPossibleRound = retVal.totalUnitMissionPointsPossibleRound +
                    TbStatusCalcs.calculatePossibleMissionPointsTotal(zone.status.zoneId, tbBattleDefintion, tbData, tbData.players.length);
                retVal.totalUnitMissionPointsPossibleRemainingRound = retVal.totalUnitMissionPointsPossibleRemainingRound +
                    TbStatusCalcs.calculatePossibleMissionPointsRemaining(zone.status.zoneId, tbBattleDefintion, tbData, tbData.players.length);
            }
        });
    }

    public static generatePointCombinationsWithWaves(cms: CombatMission[], earnedRewards: CombatMissionReward[], pointsMap: Map<string, RewardCombinations>)
    {
        let firstCm = cms[0];
        firstCm.rewards.forEach(reward =>
        {
            let newRewardList = earnedRewards.slice();
            newRewardList.push(reward);
            let gpPoints = TbStatusCalcs.calculateTotalRewards(newRewardList);
            let waves = TbStatusCalcs.generateWaves(newRewardList);

            let pointsKey = gpPoints + "_" + waves;

            if (pointsMap.has(pointsKey) === false)
            {
                let rcs = new RewardCombinations(gpPoints);
                rcs.cmrs.push(newRewardList);
                pointsMap.set(pointsKey, rcs);
            } else
            {
                let nonUniqueCombination: boolean = false;
                let currentPointCombination = pointsMap.get(pointsKey);
                if (currentPointCombination !== undefined)
                {
                    currentPointCombination.cmrs.forEach(cmrs =>
                    {
                        cmrs.forEach(cmr =>
                        {
                            let cpcRewardCount = cmrs.filter(cpr => cpr.same(cmr)).length;
                            let thizPcRewardCount = newRewardList.filter(cpr => cpr.same(cmr)).length;
                            if (cpcRewardCount !== thizPcRewardCount)
                            {
                                nonUniqueCombination = true;
                            }
                        });

                    });
                    if (nonUniqueCombination)
                    {
                        currentPointCombination.cmrs.push(newRewardList);
                        // console.info("Multiple combinations exist for point amount: " + gpPoints);
                    }
                }

            }
            if (cms.length > 1)
            {
                TbStatusCalcs.generatePointCombinationsWithWaves(cms.slice(1), newRewardList, pointsMap);
            }
        })
        if (cms.length > 1)
        {
            TbStatusCalcs.generatePointCombinationsWithWaves(cms.slice(1), earnedRewards, pointsMap);
        }
    }

    private static generatePointCombinations(cms: CombatMission[], earnedRewards: CombatMissionReward[], pointsMap: Map<number, RewardCombinations>)
    {
        let firstCm = cms[0];
        firstCm.rewards.forEach(reward =>
        {
            let newRewardList = earnedRewards.slice();
            newRewardList.push(reward);
            let gpPoints = TbStatusCalcs.calculateTotalRewards(newRewardList);
            if (pointsMap.has(gpPoints) === false)
            {
                let rcs = new RewardCombinations(gpPoints);
                rcs.cmrs.push(newRewardList);
                pointsMap.set(gpPoints, rcs);
            } else
            {
                let nonUniqueCombination: boolean = false;
                let currentPointCombination = pointsMap.get(gpPoints);
                if (currentPointCombination !== undefined)
                {
                    currentPointCombination.cmrs.forEach(cmrs =>
                    {
                        cmrs.forEach(cmr =>
                        {
                            let cpcRewardCount = cmrs.filter(cpr => cpr.same(cmr)).length;
                            let thizPcRewardCount = newRewardList.filter(cpr => cpr.same(cmr)).length;
                            if (cpcRewardCount !== thizPcRewardCount)
                            {
                                nonUniqueCombination = true;
                            }
                        });

                    });
                    if (nonUniqueCombination)
                    {
                        currentPointCombination.cmrs.push(newRewardList);
                        // console.info("Multiple combinations exist for point amount: " + gpPoints);
                    }
                }

            }
            if (cms.length > 1)
            {
                TbStatusCalcs.generatePointCombinations(cms.slice(1), newRewardList, pointsMap);
            }
        })
        if (cms.length > 1)
        {
            TbStatusCalcs.generatePointCombinations(cms.slice(1), earnedRewards, pointsMap);
        }
    }

    private static calculateTotalRewards(earnedRewards: CombatMissionReward[]): number
    {
        let retVal = 0;
        earnedRewards.forEach(reward => retVal = retVal + reward.gpReward);
        return retVal;
    }

    private static generateWaves(earnedRewards: CombatMissionReward[]): number
    {
        let retVal = 0;
        earnedRewards.forEach(reward => retVal = retVal + reward.wavesCompleted);
        return retVal;
    }

    private static calculateSandbaggedPlatoonBonus(statusPrefs: TerritoryBattleStatusPreferences, retVal: TbStatusCalculationResults, tbData: TerritoryBattleData, tbBattleDefintion: TerritoryBattleDefinition)
    {
        let sandBaggedZones: SandBagZone[] = statusPrefs.sandBagZones === undefined ? [] :
            statusPrefs.sandBagZones.filter(sbz => sbz.sandBagged);
        retVal.sandBagPlatoonBonusGp = TbStatusCalcs.calculateSandbaggedPlatoonedBonusGp(sandBaggedZones, tbData, tbBattleDefintion);
    }

    private static calculateSharedUnitGoals(retVal: TbStatusCalculationResults, zoneTargets: ZoneTarget[])
    {
        let totalGpRequired: number = 0;
        let minDeploymentRequired: number = 0;
        let accountForMissionGp: number = 0;

        let missionPointsRemaining: number = 0;

        retVal.zonesProgress.filter(zp => zp.fleetZone === false).forEach(zp =>
        {
            let starTarget: number = 0;
            zoneTargets.filter(zt => zt.zoneId === zp.zoneId).forEach(zt => starTarget = starTarget < zt.star ? zt.star : starTarget);
            if (starTarget > 0)
            {
                let starProgress = zp.stars.find(s => s.star === starTarget);
                if (starProgress !== undefined && starProgress.completed === false && starProgress.remainingGpRequired > 0)
                {
                    retVal.zoneGoalsPossible = retVal.zoneGoalsPossible &&
                        starProgress.minMissionGpRequired <= zp.remainingMissionsGpPossible &&
                        starProgress.minPlatoonGpRequired <= retVal.combatDeploymentPowerRemaining;

                    missionPointsRemaining = missionPointsRemaining + zp.remainingMissionsGpPossible;
                    totalGpRequired = totalGpRequired + starProgress.remainingGpRequired;
                    minDeploymentRequired = minDeploymentRequired + starProgress.minPlatoonGpRequired;
                    accountForMissionGp = accountForMissionGp + starProgress.minMissionGpRequired;
                }
            }
        });
        retVal.zoneGoalsPossible = retVal.zoneGoalsPossible && minDeploymentRequired <= retVal.combatDeploymentPowerRemaining;
        retVal.additionalCharacterMissionGpRequired = TbStatusCalcs.gtZero(totalGpRequired - (retVal.combatDeploymentPowerRemaining + accountForMissionGp));
        retVal.totalCharacterMissionGpRequired = totalGpRequired - retVal.combatDeploymentPowerRemaining;
        if (retVal.totalCharacterMissionGpRequired > 0 && missionPointsRemaining > 0)
        {
            retVal.characterSuccessRateRequired = retVal.totalCharacterMissionGpRequired / missionPointsRemaining * 100;
        }
    }

    private static gtZero(value: number): number
    {
        return value >= 0 ? value : 0;
    }

    private static calculateStarProgress(retVal: TbStatusCalculationResults, openZones: TerritoryBattleZoneStatus[], tbBattleDefintion: TerritoryBattleDefinition, statusPrefs: TerritoryBattleStatusPreferences, tbData: TerritoryBattleData)
    {
        retVal.zonesProgress = openZones.map(zone =>
        {
            let zoneId = zone.status.zoneId;
            let zoneProgress: ZoneProgress = new ZoneProgress(zoneId, TbStatusCalcs.getZoneLocation(zoneId, openZones.length));
            zoneProgress.fleetZone = tbBattleDefintion.isZoneFleet(zoneId);
            let deploymentRemaining = zoneProgress.fleetZone ? retVal.fleetDeploymentPowerRemaining : retVal.combatDeploymentPowerRemaining;
            zoneProgress.zoneWasSandbagged = statusPrefs.sandBagZones !== undefined && statusPrefs.sandBagZones.find(sbz => sbz.zoneId === zoneId) !== undefined;
            zoneProgress.remainingMissionsGpPossible = TbStatusCalcs.calculatePossibleMissionPointsRemaining(zoneId, tbBattleDefintion, tbData, tbData.players.length);
            let zoneDefinition = tbBattleDefintion.getZoneGameDefinition(zoneId);

            zoneProgress.score = zone.status.score;

            let previousStarRequirement: number = 0;
            zoneProgress.stars = zoneDefinition.victoryPointRewards.map((vpr, index) =>
            {
                let starNumber = index + 1;
                let zsp: ZoneStarProgress = new ZoneStarProgress(starNumber);
                zsp.completed = zone.status.score >= vpr.galacticScoreRequirement;

                zoneProgress.totalGpForAllStars = zoneProgress.totalGpForAllStars < vpr.galacticScoreRequirement ? vpr.galacticScoreRequirement : zoneProgress.totalGpForAllStars;

                zoneProgress.starsObtained = zsp.completed && zoneProgress.starsObtained < starNumber ? starNumber : zoneProgress.starsObtained;
                zoneProgress.starsTotal = zoneProgress.starsTotal < starNumber ? starNumber : zoneProgress.starsTotal;

                zsp.remainingGpRequired = TbStatusCalcs.gtZero(vpr.galacticScoreRequirement - zone.status.score);

                retVal.mostGpRequiredInOpenZone = retVal.mostGpRequiredInOpenZone < vpr.galacticScoreRequirement ? vpr.galacticScoreRequirement : retVal.mostGpRequiredInOpenZone;

                zsp.totalGpRequired = vpr.galacticScoreRequirement;
                zsp.startingGpRequired = previousStarRequirement;


                zsp.minPlatoonGpRequired = TbStatusCalcs.gtZero(zsp.remainingGpRequired - zoneProgress.remainingMissionsGpPossible);
                zsp.minMissionGpRequired = TbStatusCalcs.gtZero(zsp.remainingGpRequired - deploymentRemaining);

                if (zsp.minMissionGpRequired > 0)
                {
                    zsp.missionSuccessRateRequired = (zsp.minMissionGpRequired / zoneProgress.remainingMissionsGpPossible) * 100;
                }

                previousStarRequirement = zsp.totalGpRequired;

                return zsp;
            });
            return zoneProgress;
        });
    }


    private static calculatePossibleMissionPointsTotal(zoneId: string, tbBattleDefintion: TerritoryBattleDefinition,
        tbData: TerritoryBattleData, guildMemberCount: number): number
    {
        let retVal: number = 0;
        let strikeZoneDefinitions: StrikeZoneDefinition[] = tbBattleDefintion.getStrikeZones(zoneId);

        strikeZoneDefinitions.forEach(szd =>
        {
            let tbzs = tbData.getStrikeZoneStatus(szd.zoneDefinitionData.zoneId);
            let missionPointsPossible = szd.getTotalPossibleMissionPoints();

            if (tbzs.playersParticipated !== null)
            {
                retVal = retVal + (missionPointsPossible * guildMemberCount);
            }
        });

        return retVal;
    }


    private static calculatePossibleMissionPointsRemaining(zoneId: string, tbBattleDefintion: TerritoryBattleDefinition,
        tbData: TerritoryBattleData, guildMemberCount: number): number
    {
        let retVal: number = 0;
        let strikeZoneDefinitions: StrikeZoneDefinition[] = tbBattleDefintion.getStrikeZones(zoneId);

        strikeZoneDefinitions.forEach(szd =>
        {
            let tbzs = tbData.getStrikeZoneStatus(szd.zoneDefinitionData.zoneId);
            let missionPointsPossible = szd.getTotalPossibleMissionPoints();

            if (tbzs.playersParticipated !== null)
            {
                let remainingPossibleParticipants = guildMemberCount - tbzs.playersParticipated;
                retVal = retVal + (missionPointsPossible * remainingPossibleParticipants);
            }
        });

        return retVal;
    }

    private static getZoneLocation(zoneId: string, openZoneCount: number): ZoneLocation
    {
        let retVal: ZoneLocation = ZoneLocation.Top;

        let lastChar = zoneId[zoneId.length - 1];

        let lastCharNumber = Number(lastChar);
        if (isNaN(lastCharNumber))
        {
            throw new Error("Unable to determine location index of zone: " + zoneId);
        }

        retVal = lastCharNumber;
        if (retVal === 2 && openZoneCount === 2)
        {
            retVal = 3;
        }

        return retVal;
    }


    private static calculateRemainingDeployableGp(retVal: TbStatusCalculationResults, openZones: TerritoryBattleZoneStatus[],
        statusPrefs: TerritoryBattleStatusPreferences, tbData: TerritoryBattleData, tbBattleDefintion: TerritoryBattleDefinition,
        playerCalcs: TerritoryBattlePlayerCalc[])
    {
        let fleetZoneId = retVal.fleetZoneId === undefined ? null : retVal.fleetZoneId;
        let combatZones = openZones.filter(oz => fleetZoneId === null || fleetZoneId !== oz.status.zoneId).map(oz => oz.status.zoneId);
        let sandBaggedZones: SandBagZone[] = statusPrefs.sandBagZones === undefined ? [] :
            statusPrefs.sandBagZones.filter(sbz => sbz.sandBagged);

        if (retVal.sandBaggedZoneExists === false || statusPrefs.noSandbagOverride === true)
        {
            // easiest caluclations
            retVal.sandBagStatus = SandBagStatus.NoSandBag;
            // remove all platoon gp bonuses
            let fleetDeploymentPowerUsed = retVal.fleetZonesScore - (retVal.fleetZonesMissionsScore + retVal.fleetPlatoonBonusScore);
            retVal.fleetDeploymentPowerRemaining = retVal.totalGuildFleetDeploymentPower - fleetDeploymentPowerUsed;
            let combatDeploymentPowerUsed = retVal.combatZonesScore - (retVal.combatZonesMissionsScore + retVal.combatPlatoonBonusScore);
            retVal.combatDeploymentPowerRemaining = retVal.totalGuildCombatDeploymentPower - combatDeploymentPowerUsed;
        }
        else if (retVal.fleetZoneExists === false)
        {
            // there are no open ship zone, all deploy gp is combat
            retVal.sandBagStatus = SandBagStatus.CombatZonesOnly;
            // need to determine if platoon gp bonuses should be removed from combat zones
            let sandbaggedPlatoonsGp = TbStatusCalcs.calculateSandbaggedPlatoonedBonusGp(sandBaggedZones, tbData, tbBattleDefintion);
            let platoonGpEarnedThisRound = retVal.combatPlatoonBonusScore - sandbaggedPlatoonsGp;
            retVal.combatDeploymentPowerRemaining = retVal.totalGuildCombatDeploymentPower - (retVal.totalDeploymentGpEarnedThisRound - platoonGpEarnedThisRound);
        }
        else if (fleetZoneId !== null && TbStatusCalcs.zoneNotSandBagged(retVal, fleetZoneId, sandBaggedZones))
        {
            // fleet zone not sandbagged because it cant be (based on name) or user selected not sandbagged, fleet deploy gp can be calculated
            retVal.sandBagStatus = SandBagStatus.CombatZonesOnly;
            let fleetDeploymentPowerUsed = retVal.fleetZonesScore - (retVal.fleetZonesMissionsScore + retVal.fleetPlatoonBonusScore);
            retVal.fleetDeploymentPowerRemaining = retVal.totalGuildFleetDeploymentPower - fleetDeploymentPowerUsed;
            // need to determine if platoon gp bonuses should be removed from combat zones
            let sandbaggedCombatPlatoonsGp = TbStatusCalcs.calculateSandbaggedPlatoonedBonusGp(sandBaggedZones, tbData, tbBattleDefintion);
            let combatDeploymentPowerEarnThisRound = retVal.totalDeploymentGpEarnedThisRound - (fleetDeploymentPowerUsed + retVal.fleetPlatoonBonusScore);

            let combatPlatoonsEarnedThisRound = retVal.combatPlatoonBonusScore - sandbaggedCombatPlatoonsGp;

            let combatDeploymentUsedThisRound = combatDeploymentPowerEarnThisRound - combatPlatoonsEarnedThisRound;
            retVal.combatDeploymentPowerRemaining = retVal.totalGuildCombatDeploymentPower - combatDeploymentUsedThisRound;
        }
        else if (TbStatusCalcs.zonesNotSandBagged(retVal, combatZones, sandBaggedZones))
        {
            // only ship zone can be sandbagged, combat deploy gp can be calculated
            retVal.sandBagStatus = SandBagStatus.FleetZonesOnly;
            let combatDeploymentPowerUsed = retVal.combatZonesScore - (retVal.combatZonesMissionsScore + retVal.combatPlatoonBonusScore);
            retVal.combatDeploymentPowerRemaining = retVal.totalGuildCombatDeploymentPower - combatDeploymentPowerUsed;
            // need to determine if platoon gp bonuses should be removed from ship zone
            let sandbaggedFleetPlatoonsGp = TbStatusCalcs.calculateSandbaggedPlatoonedBonusGp(sandBaggedZones, tbData, tbBattleDefintion);
            let fleetDeploymentPowerEarnThisRound = retVal.totalDeploymentGpEarnedThisRound - (combatDeploymentPowerUsed + retVal.combatPlatoonBonusScore);
            let fleetDeploymentUsedThisRound = fleetDeploymentPowerEarnThisRound - sandbaggedFleetPlatoonsGp;
            retVal.fleetDeploymentPowerRemaining = retVal.totalGuildCombatDeploymentPower - fleetDeploymentUsedThisRound;
        }
        else if (sandBaggedZones.length > 0)
        {
            // worst case, both fleet and ship zone was sandbagged user has entered sandbagged and platooned data
            retVal.sandBagStatus = SandBagStatus.CombatAndFleet;
            let playerFleetDeploymentRemaining: number = 0;
            let playerCombatDeploymentRemaining: number = 0;
            playerCalcs.forEach(player =>
            {
                playerFleetDeploymentRemaining = playerFleetDeploymentRemaining + player.minEstimatedShipDeploymentRemaining;
                playerCombatDeploymentRemaining = playerCombatDeploymentRemaining + player.minEstimatedCombatDeploymentRemaining;
            });
            let fleetSandBagZone = sandBaggedZones.find(sbz => sbz.zoneId === retVal.fleetZoneId);
            let combatSandBagZones = sandBaggedZones.filter(sbz => sbz.zoneId !== retVal.fleetZoneId);
            let sandbaggedFleetPlatoonsGp = fleetSandBagZone === undefined ? 0 : TbStatusCalcs.calculateSandbaggedPlatoonedBonusGp([fleetSandBagZone], tbData, tbBattleDefintion);
            let sandbaggedCombatPlatoonsGp = fleetSandBagZone === undefined ? 0 : TbStatusCalcs.calculateSandbaggedPlatoonedBonusGp(combatSandBagZones, tbData, tbBattleDefintion);
            let roundFleetPlatoonScore = retVal.fleetPlatoonBonusScore - sandbaggedFleetPlatoonsGp;
            let roundCombatPlatoonScore = retVal.combatPlatoonBonusScore - sandbaggedCombatPlatoonsGp;

            retVal.combatDeploymentPowerRemaining = playerCombatDeploymentRemaining + roundCombatPlatoonScore;
            retVal.fleetDeploymentPowerRemaining = playerFleetDeploymentRemaining + roundFleetPlatoonScore;
        }
        else
        {
            // user has not entered status - unable to determined platoon status
            // This should never show in UI until user has selected the sandbagged zone
            retVal.sandBagStatus = SandBagStatus.CombatAndFleet;
            let playerFleetDeploymentRemaining: number = 0;
            let playerCombatDeploymentRemaining: number = 0;
            playerCalcs.forEach(player =>
            {
                playerFleetDeploymentRemaining = playerFleetDeploymentRemaining + player.minEstimatedShipDeploymentRemaining;
                playerCombatDeploymentRemaining = playerCombatDeploymentRemaining + player.minEstimatedCombatDeploymentRemaining;
            });
            retVal.combatDeploymentPowerRemaining = playerCombatDeploymentRemaining;
            retVal.fleetDeploymentPowerRemaining = playerFleetDeploymentRemaining;
        }
    }

    static calculateSandbaggedPlatoonedBonusGp(sandBagZones: SandBagZone[], tbData: TerritoryBattleData,
        tbBattleDefintion: TerritoryBattleDefinition)
    {
        let retVal: number = 0;
        sandBagZones.forEach(sandBagZone =>
        {
            retVal = retVal + (TbStatusCalcs.getSinglePlatoonGpReward(sandBagZone.zoneId, tbBattleDefintion) * sandBagZone.platoonsCompleted);
        });
        return retVal;
    }

    private static getSinglePlatoonGpReward(zoneId: string, tbBattleDefintion: TerritoryBattleDefinition): number
    {
        let retVal: number | undefined = undefined;

        let platoonZone = tbBattleDefintion.getReconZone(zoneId);
        if (platoonZone !== undefined)
        {
            platoonZone.platoonDefinition.forEach(platoon =>
            {
                if (platoon.reward.type === TbStatusCalcs.GP_REWARD_TYPE)
                {
                    if (retVal === undefined)
                    {
                        retVal = platoon.reward.value;
                    } else if (platoon.reward.value !== retVal)
                    {
                        throw new Error("Unexpected, platoon rewards different amount: " + zoneId);
                    }
                }
            });
        }
        return retVal === undefined ? 0 : retVal;
    }

    private static calculatePlatoonedBonusGpEarned(zoneId: string, tbData: TerritoryBattleData, tbBattleDefintion: TerritoryBattleDefinition): number
    {
        let retVal: number = 0;

        let platoonZone = tbBattleDefintion.getReconZone(zoneId);
        if (platoonZone !== undefined)
        {
            let platoonZoneStatus = tbData.getReconZoneStatus(platoonZone.zoneDefinition.zoneId);
            platoonZone.platoonDefinition.forEach(platoon =>
            {
                let platoonStatus = platoonZoneStatus.getPlatoonStatus(platoon.id);
                // if everything is filled, they get the reward
                let missingPlatoon: boolean = false;
                platoonStatus.squad.forEach(squad =>
                {
                    squad.unit.forEach(unit =>
                    {
                        if (unit.allyCode === null || unit.allyCode === 0)
                        {
                            missingPlatoon = true;
                        }
                    });
                });
                if (platoon.reward.type === TbStatusCalcs.GP_REWARD_TYPE)
                {
                    if (missingPlatoon === false)
                    {
                        retVal = retVal + platoon.reward.value;
                    }
                }
            });
        }
        return retVal;
    }

    private static zonesNotSandBagged(calculations: TbStatusCalculationResults, zoneIds: string[], sandBaggedZones: SandBagZone[]): boolean
    {
        let retVal: boolean = true;
        zoneIds.forEach(zoneId =>
        {
            if (TbStatusCalcs.zoneNotSandBagged(calculations, zoneId, sandBaggedZones) === false)
            {
                retVal = false;
            }
        })
        return retVal;
    }

    private static zoneNotSandBagged(calculations: TbStatusCalculationResults, zoneId: string, sandBaggedZones: SandBagZone[]): boolean
    {

        let notPossibleBasedonZoneId: boolean = calculations.possibleSandbagZones.find(sbz => sbz.zoneId === zoneId) === undefined;
        let userIndicatedNotSandbagged: boolean = (sandBaggedZones.length > 0 && sandBaggedZones.find(sbz => sbz.zoneId === zoneId) === undefined);

        return (notPossibleBasedonZoneId || userIndicatedNotSandbagged);
    }

    private static calculateSandbagStatus(retVal: TbStatusCalculationResults, openZones: TerritoryBattleZoneStatus[], currentRound: number)
    {
        retVal.sandBaggedZoneExists = retVal.gpEarnedThisRound < retVal.openZonesScore;
        retVal.possibleSandbagZones = openZones.filter(zone => zone.status.zoneId.includes("phase0" + currentRound) === false).map(zone =>
        {
            let zoneId = zone.status.zoneId;
            // TODO - dynamically determine number of platoon squads
            let psbz = new PossibleSandBagZone(zone.status.zoneId, TbStatusCalcs.getZoneLocation(zoneId, openZones.length), 6);
            return psbz;
        });
    }

    public static getMissionCurrentPointValue(tbData: TerritoryBattleData, tbGameData: TerritoryBattleGameData, fleet: boolean): number
    {
        let retVal = 0;

        let tbBattleDefintion = tbGameData.getTerritoryBattleDefinition(tbData.definitionId);
        let openZones: TerritoryBattleZoneStatus[] = tbData.getOpenZones();

        openZones.forEach(zone =>
        {
            let matchingZone = tbBattleDefintion.isZoneFleet(zone.status.zoneId) ? fleet : !fleet;
            if (matchingZone)
            {
                retVal = retVal + TbStatusCalcs.calculateMissionsScore(zone.status.zoneId, tbData, tbBattleDefintion);
            }
        });

        return retVal;
    }

    public static getMissionPointsRemaining(tbData: TerritoryBattleData, tbGameData: TerritoryBattleGameData, fleet: boolean)
    {
        let retVal = 0;

        let tbBattleDefintion = tbGameData.getTerritoryBattleDefinition(tbData.definitionId);
        let openZones: TerritoryBattleZoneStatus[] = tbData.getOpenZones();

        openZones.forEach(zone =>
        {
            let matchingZone = tbBattleDefintion.isZoneFleet(zone.status.zoneId) ? fleet : !fleet;
            if (matchingZone)
            {
                retVal = retVal +
                    TbStatusCalcs.calculatePossibleMissionPointsRemaining(zone.status.zoneId, tbBattleDefintion, tbData, tbData.players.length);
            }
        });
        return retVal;
    }

    private static calculateOpenZonesScore(openZones: TerritoryBattleZoneStatus[], tbData: TerritoryBattleData,
        tbBattleDefintion: TerritoryBattleDefinition, retVal: TbStatusCalculationResults)
    {
        openZones.forEach(zone =>
        {
            retVal.openZonesScore = retVal.openZonesScore + zone.status.score;

            if (tbBattleDefintion.isZoneFleet(zone.status.zoneId))
            {
                retVal.fleetZonesScore = retVal.fleetZonesScore + zone.status.score;
                retVal.fleetZonesMissionsScore = retVal.fleetZonesMissionsScore + TbStatusCalcs.calculateMissionsScore(zone.status.zoneId, tbData, tbBattleDefintion);
                retVal.fleetPlatoonBonusScore = retVal.fleetPlatoonBonusScore + TbStatusCalcs.calculatePlatoonedBonusGpEarned(zone.status.zoneId, tbData, tbBattleDefintion);
            } else
            {
                retVal.combatZonesScore = retVal.combatZonesScore + zone.status.score;
                retVal.combatZonesMissionsScore = retVal.combatZonesMissionsScore + TbStatusCalcs.calculateMissionsScore(zone.status.zoneId, tbData, tbBattleDefintion);
                retVal.combatPlatoonBonusScore = retVal.combatPlatoonBonusScore + TbStatusCalcs.calculatePlatoonedBonusGpEarned(zone.status.zoneId, tbData, tbBattleDefintion);
            }
        });
    }

    private static calculateMissionsScore(zoneId: string, tbData: TerritoryBattleData, tbBattleDefintion: TerritoryBattleDefinition): number
    {
        let retVal: number = 0;

        let strikeZoneDefinitions: StrikeZoneDefinition[] = tbBattleDefintion.getStrikeZones(zoneId);

        strikeZoneDefinitions.forEach(szd =>
        {
            let tbzs = tbData.getStrikeZoneStatus(szd.zoneDefinitionData.zoneId);
            retVal = retVal + tbzs.status.score;
        });

        return retVal;
    }

    private static calculateGpRound(tbData: TerritoryBattleData, currentRound: number, retVal: TbStatusCalculationResults)
    {
        let currentRoundTerritoryPointsLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === LeaderBoard.TERRITORY_POINTS_ROUND_LB + currentRound);
        let currentRoundGpDeployedLeaderBoard = tbData.leaderboard.find(leaderBoard => leaderBoard.statId === LeaderBoard.GP_DEPLOYED_ROUND_LB + currentRound);

        if (currentRoundGpDeployedLeaderBoard !== undefined)
        {
            currentRoundGpDeployedLeaderBoard.players.forEach(player => retVal.totalDeploymentGpEarnedThisRound = retVal.totalDeploymentGpEarnedThisRound + player.score);
        }
        if (currentRoundTerritoryPointsLeaderBoard !== undefined)
        {
            currentRoundTerritoryPointsLeaderBoard.players.forEach(player => retVal.gpEarnedThisRound = retVal.gpEarnedThisRound + player.score);
        }

        retVal.gpEarnedThisRound = retVal.gpEarnedThisRound + retVal.platoonBonusGpEarnedThisRound;  // Territory points LB does not include plaatoon bonuses
        retVal.totalMissionGpEarnedThisRound = retVal.gpEarnedThisRound - retVal.totalDeploymentGpEarnedThisRound;
    }

    private static calculateGuildDeploymentPower(tbData: TerritoryBattleData, retVal: TbStatusCalculationResults)
    {
        tbData.players.forEach(player =>
        {
            retVal.totalGuildCombatDeploymentPower = retVal.totalGuildCombatDeploymentPower + player.characterGP;
            retVal.totalGuildFleetDeploymentPower = retVal.totalGuildFleetDeploymentPower + player.shipGP;
        });
    }

    private static calculateFleetStatus(tbData: TerritoryBattleData, tbBattleDefintion: TerritoryBattleDefinition, retVal: TbStatusCalculationResults)
    {
        let fleetZone = tbData.getOpenShipZone(tbBattleDefintion);

        retVal.fleetZoneExists = fleetZone !== undefined;
        if (fleetZone !== undefined)
        {
            retVal.fleetZoneId = fleetZone.status.zoneId;
            let zoneDefinition = tbBattleDefintion.getZoneGameDefinition(fleetZone.status.zoneId);
            let finalPowerRequirement = zoneDefinition.victoryPointRewards[zoneDefinition.victoryPointRewards.length - 1].galacticScoreRequirement;
            retVal.fleetZoneCompleted = fleetZone.status.score >= finalPowerRequirement;
        }
    }
}

export default TbStatusCalcs;