import React from "react";
import { BasicLayout } from '../../layouts/BasicLayout';
import { PageTitle } from '../../ui/pageTitle';
import IPageProps from "../IPageProps";
import WhatsNew from "./WhatsNew";

import styles from "./styles/Dashboard.module.scss"
import Title from "antd/lib/typography/Title";
import { Alert, Button, Spin, Typography } from "antd";
import PatreonLevel from "../../model/PatreonLevel";
import { generateGoogleLink } from "../LoginFlow/LoginPage";
import { checkForSquads } from "../../ui/squadMigration";
import { observable, runInAction } from "mobx";
import BaseAPI from "../../service/BaseAPI";
import { IGuildData } from "../../model/GuildData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { observer } from "mobx-react";
import { InstructionsModal } from "./modal/InstructionsModal";
import { GuildEventData } from "../../model/EventData";
import EventAPI from "../../service/EventAPI";

@observer
class Home extends React.PureComponent<IPageProps> {
    @observable.ref memberList: IGuildData | null = null;
    @observable errorGuildFetch?: string = undefined;
    @observable guildEvents: GuildEventData | null = null;
    @observable isAdmin: boolean = false;
    @observable showModal: boolean = false;
    @observable gotEventsError: boolean = false;

    private _retry = 0;

    getDataGuild()
    {
        return this.fetchMembers();
    }

    constructor(props: IPageProps)
    {
        super(props);

        this.getAlertAdmins = this.getAlertAdmins.bind(this);
    }

    async componentDidMount()
    {
        if (this.props.user.currentPlayer?.guildId !== 0)
        {
            checkForSquads(this.props.user, this.props.gameData);
            if (await this.props.user.checkQueue() === false)
            {
                this.getDataGuild();
                if (this.props.user.isAdminTW || this.props.user.isAdminTB || this.props.user.isAdmin)
                {
                    runInAction(() => this.isAdmin = true);
                    if (this.props.user.checkApiRights(["EVENTS"]))
                        this.fetchEvents();
                }
            }
        }
    }

    private async fetchEvents(): Promise<void>
    {
        try
        {
            let g = await EventAPI.getGuildEvents(this.props.user);

            runInAction(() => this.guildEvents = g);
        }
        catch (err: any)
        {
            console.log(err);
            runInAction(() => this.gotEventsError = true);
        }
    }

    componentWillUnmount()
    {
        clearTimeout(this._retry);
    }

    private async fetchMembers(): Promise<void>
    {
        try
        {
            const response = await BaseAPI.getGuildList(this.props.user, false, false, undefined, parseInt(this.props.user.currentPlayer!.allyCode), 24);

            runInAction(() =>
            {
                this.memberList = response;
            });
        } catch (e: any)
        {
            runInAction(() => this.errorGuildFetch = e.errorMessage)
        }
    }

    /*private async test(): Promise<void>
    {
        let rawResp = await fetch("/Prioritization/api/stackrank/738889527", {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
            }
        });

        console.log(rawResp);
    }*/

    private renderModal()
    {
        if (!this.showModal)
        {
            return null;
        }
        return (
            <InstructionsModal
                {...this.props}
                showModal={this.showModal}
                onClose={() => runInAction(() => this.showModal = false)}
            />
        );
    }


    private static getAlertMsg(memberNS: string[])
    {
        return <>
            The following members are NOT registered on HotUtils: <strong>{memberNS.join(' | ')}</strong>
        </>;
    }

    private getAlertAdmins()
    {
        if (this.props.user.currentPlayer?.guildId === 0 || this.props.user.queueBusy)
            return null;

        if (!this.memberList)
        {
            return <div className={styles.loading}>
                <Spin size={"small"} />
                <div>Checking guild...</div>
            </div>
        }
        const memberlist = this.memberList?.players;
        let memberNS: string[] = [];

        if (!memberlist)
        {
            return;
        }
        for (let i = 0; i < memberlist.length; i++)
        {
            const member = memberlist[i];

            if (member.discordId === 0)
            {
                memberNS.push(member.name);
            }
        }
        if (memberNS.length === 0)
        {
            return <Alert
                message="All your guild members are registered on HotUtils!"
                type="success"
                showIcon={true}
                className={styles.alert}
                closable={true}
            />
        } else
        {
            return <Alert
                message={Home.getAlertMsg(memberNS)}
                type="warning"
                showIcon={true}
                className={styles.alert}
                closable={true}
                action={
                    <Button
                        size="small"
                        type={"default"}
                        danger
                        onClick={() => runInAction(() => this.showModal = true)}
                    >
                        Detail
                    </Button>
                }
            />
        }
    }

    private getEventAlerts(): React.ReactNode
    {
        if (this.props.user.currentPlayer?.guildId === 0)
            return null;

        if (!this.props.user.checkApiRights(["EVENTS"]) || this.gotEventsError)
            return null;
        if (!this.guildEvents)
        {
            return <div className={styles.loading}>
                <Spin size={"small"} />
                <div>Checking events...</div>
            </div>
        }
        return <>
            {!this.guildEvents.dataPull.enabled && <Alert message="Guild data fetch event not running, set it up at Guild➔Events. Without this, many features will not work properly"
                type="error" showIcon={true} className={styles.alert} />}
            {(this.props.user.guildPatreonLevel === PatreonLevel.WarTools && !this.guildEvents.tbDataPull.enabled) &&
                <Alert message="TB Data event not running, set it up at Guild➔Events. Without this event, TB data will not be accurate!"
                    type="error" showIcon={true} className={styles.alert} />}
        </>
    }

    render()
    {

        if (this.errorGuildFetch !== undefined)
        {
            return (
                <BasicLayout {...this.props} requiredApiRights={[]}>
                    <LoadingSpinner size={"large"} error={true} spinning={false} text={this.errorGuildFetch} />
                </BasicLayout>
            );
        }

        return (
            <BasicLayout {...this.props} requiredApiRights={[]}>
                <PageTitle>
                    Welcome to Hot Utils
                </PageTitle>
                {!this.props.user.isPatronOrInWarToolsGuild && <div>
                    <Title level={3}>You are not currently a Patreon subscriber</Title>
                    <Typography>If you think you should be showing up as a patreon subscriber, please make sure you have discord linked to patreon. You can set this up on the patreon website under <a href="https://www.patreon.com/settings/profile">My Profile Settings</a> - Apps</Typography>
                    <Typography>If your patreon payment failed and you had to retry it, try disconnecting and reconnecting to discord</Typography>
                    <Typography>Please note that until you fix this, the functionality you will be able to access on this site will be very limited</Typography>
                    <Typography>For help, reach out to the support team on <a href="https://discord.me/hotutils">Discord</a></Typography>
                </div>}
                {this.props.user.patreonLevel !== PatreonLevel.None && !this.props.user.hasGameConnection && <div>
                    <Title level={3}>Hello Patreon subscriber, you have no game connection setup yet</Title>
                    <Typography>Thanks for signing up to support us on Patreon. You currently don't have a game connection set up, which will limit what you can do on the site</Typography>
                    <Typography>If your SWGOH account is linked to a Google account, you should try logging into that <a href={generateGoogleLink()}>here</a></Typography>
                    <Typography>If that doesn't work, or if you don't have your account linked to a Google account, please see the <a href="https://discord.gg/UDFd8nS99Y">#getting-started-connections</a> channel on our main discord server.</Typography>
                    <Typography>Follow the instructions in that channel and tag <strong>@support</strong> if you have any issues</Typography>
                </div>}
                {this.isAdmin && this.getAlertAdmins()}
                {this.isAdmin && this.getEventAlerts()}
                <div className={styles.dashboard}>
                    <WhatsNew user={this.props.user} />
                </div>
                {this.renderModal()}
            </BasicLayout>
        );
    }
}

export default Home;
