import { observable } from "mobx";

export class PlayerEventData
{
    @observable lastUpdatedUTC: Date | null = null;
    @observable dataPull: PlayerDataPullEvent;
    @observable gac: GacEvent;

    constructor(json: any)
    {
        if (json.events.lastUpdatedUtc)
            this.lastUpdatedUTC = new Date(json.events.lastUpdatedUtc);
        this.dataPull = new PlayerDataPullEvent(json.events.dataPull);
        this.gac = new GacEvent(json.events.gac);
    }

    public toJSON(): any
    {
        return {
            dataPull: this.dataPull.toJSON(),
            gac: this.gac.toJSON()
        }
    }
}

export class GacEvent
{
    @observable joinEnabled: boolean;
    @observable joinOffsetMinutes: number;

    @observable defenseCheckEnabled: boolean;
    @observable defenseCheckOffsetMinutes: number;
    @observable defenseCheckAfterRound1: boolean;

    @observable attackReminderEnabled: boolean;
    @observable attackReminderOffsetMinutes: number;

    @observable resultsRecordEnabled: boolean;
    @observable resultsRecordOffsetMinutes: number;

    constructor(json: any)
    {
        this.joinEnabled = json.joinEnabled;
        this.joinOffsetMinutes = json.joinOffsetMinutes;
        
        this.defenseCheckEnabled = json.defenseCheckEnabled;
        this.defenseCheckOffsetMinutes = json.defenseCheckOffsetMinutes;
        this.defenseCheckAfterRound1 = json.defenseCheckAfterRound1;

        this.attackReminderEnabled = json.attackReminderEnabled;
        this.attackReminderOffsetMinutes = json.attackReminderOffsetMinutes;

        this.resultsRecordEnabled = json.resultsRecordEnabled;
        this.resultsRecordOffsetMinutes = json.resultsRecordOffsetMinutes;
    }

    public toJSON(): any
    {
        return {
            joinEnabled: this.joinEnabled,
            joinOffsetMinutes: this.joinOffsetMinutes,
            defenseCheckEnabled: this.defenseCheckEnabled,
            defenseCheckOffsetMinutes: this.defenseCheckOffsetMinutes,
            defenseCheckAfterRound1: this.defenseCheckAfterRound1,
            attackReminderEnabled: this.attackReminderEnabled,
            attackReminderOffsetMinutes: this.attackReminderOffsetMinutes,
            resultsRecordEnabled: this.resultsRecordEnabled,
            resultsRecordOffsetMinutes: this.resultsRecordOffsetMinutes
        };
    }
}


export class GuildEventData
{
    @observable lastUpdatedUTC: Date | null = null;
    @observable lastUpdatedAllyCode: number | null = null;
    @observable lastUpdatedName: string | null = null;
    @observable ticketCheck: TicketCheckEvent;
    @observable raidCheck: RaidCheckEvent;
    @observable dataPull: DataPullEvent;
    @observable tbDataPull: TBPullEvent;
    @observable twCheckEvent: TWCheckEvent;
    @observable schedulePending: boolean;
    @observable schedule: IScheduledEvent[];
    public channels: IEventChannel[] = [];
    public roles: IEventRole[] = [];

    constructor(json: any)
    {
        if (json.events.lastUpdatedUtc)
            this.lastUpdatedUTC = new Date(json.events.lastUpdatedUtc);
        this.lastUpdatedAllyCode = json.events.lastUpdatedAllyCode;
        this.lastUpdatedName = json.events.lastUpdatedName;
        this.ticketCheck = new TicketCheckEvent(json.events.ticketCheck);
        this.raidCheck = new RaidCheckEvent(json.events.raidCheck);
        this.dataPull = new DataPullEvent(json.events.dataPull);
        this.tbDataPull = new TBPullEvent(json.events.tbDataPull);
        this.twCheckEvent = new TWCheckEvent(json.events.twCheckEvent);
        this.channels = json.channels || [];
        this.roles = json.roles || [];
        this.schedulePending = json.schedulePending;
        this.schedule = json.schedule || [];
    }

    public toJSON(): any
    {
        return {
            ticketCheck: this.ticketCheck.toJSON(),
            raidCheck: this.raidCheck.toJSON(),
            dataPull: this.dataPull.toJSON(),
            tbDataPull: this.tbDataPull.toJSON(),
            twCheckEvent: this.twCheckEvent.toJSON()
        }
    }
}

export class TicketCheckEvent
{
    @observable enabled: boolean = false;
    @observable offsetMinutesWarning1: number;
    @observable offsetMinutesWarning2: number;
    @observable scoreThreshold1: number;
    @observable scoreThreshold2: number;
    @observable recordViolations: boolean;
    @observable notification: NotificationProperties;
    @observable violationNotification: NotificationProperties;
    @observable violationThreshold: number;

    constructor(json: any)
    {
        if (json.enabled !== null)
            this.enabled = json.enabled;
        this.offsetMinutesWarning1 = json.offsetMinutesWarning1;
        this.offsetMinutesWarning2 = json.offsetMinutesWarning2;
        this.scoreThreshold1 = json.scoreThreshold1;
        this.scoreThreshold2 = json.scoreThreshold2;
        this.recordViolations = json.recordViolations;
        this.notification = new NotificationProperties(json.notification);
        this.violationNotification = new NotificationProperties(json.violationNotification);
        this.violationThreshold = json.violationThreshold;
    }

    public toJSON()
    {
        return {
            enabled: this.enabled,
            offsetMinutesWarning1: this.offsetMinutesWarning1,
            offsetMinutesWarning2: this.offsetMinutesWarning2,
            scoreThreshold1: this.scoreThreshold1,
            scoreThreshold2: this.scoreThreshold2,
            recordViolations: this.recordViolations,
            notification: this.notification.toJSON(),
            violationNotification: this.violationNotification.toJSON(),
            violationThreshold: this.violationThreshold
        };
    }
}

export class RaidCheckEvent
{
    @observable enabled: boolean = false;
    @observable offsetMinutes: number;
    @observable checkTimeUTC: string | null;
    @observable recordViolations: boolean;
    @observable player: EventPlayer;
    @observable notification: RaidNotificationProperties;
    @observable violationNotification: RaidNotificationProperties;
    @observable announce: boolean;
    @observable announceNotification: RaidNotificationProperties;
    @observable remind: boolean;
    @observable remindOffsetMinutes: number;
    @observable remindNotification: RaidNotificationProperties;

    constructor(json: any)
    {
        if (json.enabled !== null)
            this.enabled = json.enabled;
        this.offsetMinutes = json.offsetMinutes;
        this.checkTimeUTC = json.checkTimeUTC;
        this.recordViolations = json.recordViolations;
        this.notification = new RaidNotificationProperties(json.notification);
        this.violationNotification = new RaidNotificationProperties(json.violationNotification);
        this.player = new EventPlayer(json.player);
        this.announceNotification = new RaidNotificationProperties(json.announceNotification);
        this.announce = json.announce;
        this.remind = json.remind;
        this.remindOffsetMinutes = json.remindOffsetMinutes;
        this.remindNotification = new RaidNotificationProperties(json.remindNotification);
    }

    public toJSON()
    {
        return {
            enabled: this.enabled,
            offsetMinutes: this.offsetMinutes,
            checkTimeUTC: this.checkTimeUTC,
            recordViolations: this.recordViolations,
            player: this.player.toJSON(),
            notification: this.notification.toJSON(),
            violationNotification: this.violationNotification.toJSON(),
            announce: this.announce,
            announceNotification: this.announceNotification.toJSON(),
            remind: this.remind,
            remindOffsetMinutes: this.remindOffsetMinutes,
            remindNotification: this.remindNotification.toJSON()
        };
    }
}

export class PlayerDataPullEvent
{
    @observable enabled: boolean = false;
    @observable timeUTC: string;

    constructor(json: any)
    {
        if (json.enabled !== null)
            this.enabled = json.enabled;
        this.timeUTC = json.timeUTC;
    }

    public toJSON()
    {
        return {
            enabled: this.enabled,
            timeUTC: this.timeUTC
        };
    }
}

export class DataPullEvent extends PlayerDataPullEvent
{
    @observable player: EventPlayer;

    constructor(json: any)
    {
        super(json);
        this.player = new EventPlayer(json.player);
    }

    public toJSON()
    {
        return {
            ...super.toJSON(),
            player: this.player.toJSON()
        };
    }
}

export class TBPullEvent
{
    @observable player: EventPlayer;
    @observable enabled: boolean = false;
    @observable smCheckEnabled: boolean = false;
    @observable smOffsetMinutes: number = 0;
    @observable notification: NotificationProperties;
    @observable violationNotification: NotificationProperties;
    @observable recordViolations: boolean = false;

    constructor(json: any)
    {
        this.player = new EventPlayer(json.player);
        if (json.enabled !== null)
            this.enabled = json.enabled;
        this.notification = new NotificationProperties(json.notification);
        this.violationNotification = new NotificationProperties(json.violationNotification);
        if (json.smOffsetMinutes)
            this.smOffsetMinutes = json.smOffsetMinutes;
        if (json.smCheckEnabled)
            this.smCheckEnabled = json.smCheckEnabled;
        if (json.smRecordViolations)
            this.recordViolations = json.smRecordViolations;
    }

    public toJSON()
    {
        return {
            player: this.player.toJSON(),
            enabled: this.enabled,
            notification: this.notification.toJSON(),
            violationNotification: this.violationNotification.toJSON(),
            smOffsetMinutes: this.smOffsetMinutes,
            smCheckEnabled: this.smCheckEnabled,
            smRecordViolations: this.recordViolations
        };
    }
}

export class TWCheckEvent
{
    @observable enabled: boolean = false;
    @observable offsetMinutes: number;
    @observable player: EventPlayer;
    @observable recordViolations: boolean;
    @observable notification: NotificationProperties;
    @observable violationNotification: NotificationProperties;

    constructor(json: any)
    {
        if (json.enabled !== null)
            this.enabled = json.enabled;
        this.offsetMinutes = json.offsetMinutes;
        this.player = new EventPlayer(json.player);
        this.recordViolations = json.recordViolations;
        this.notification = new NotificationProperties(json.notification);
        this.violationNotification = new NotificationProperties(json.violationNotification);
    }

    public toJSON()
    {
        return {
            enabled: this.enabled,
            offsetMinutes: this.offsetMinutes,
            player: this.player.toJSON(),
            recordViolations: this.recordViolations,
            notification: this.notification.toJSON(),
            violationNotification: this.violationNotification.toJSON()
        };
    }
}

export class EventPlayer
{
    @observable allyCode: number;
    @observable name: string;
    @observable discordTag: string;
    @observable noWarning: boolean | null = null;

    constructor(json: any)
    {
        if (json === null)
        {
            this.allyCode = 0;
            this.name = "";
            this.discordTag = "";
        }
        else
        {
            this.allyCode = json.allyCode;
            this.name = json.name;
            this.discordTag = json.discordTag;
            if (json.noWarning !== undefined && json.noWarning !== null)
                this.noWarning = json.noWarning;
        }
    }

    public toJSON()
    {
        return {
            allyCode: this.allyCode,
            name: this.name,
            discordTag: this.discordTag,
            noWarning: this.noWarning
        };
    }
}

export class NotificationProperties
{
    @observable discordRoleId: string | null = null;
    @observable discordRoleName: string | null = null;
    @observable discordServerId: string | null = null;
    @observable discordServerName: string | null = null;
    @observable discordChannelId: string | null = null;
    @observable discordChannelName: string | null = null;
    @observable tagUsers: boolean = false;
    @observable dmUsers: boolean = false;
    @observable customMessage: string | null = null;

    constructor(json: any)
    {
        if (!json)
            return;
        if (json.discordRoleId)
            this.discordRoleId = json.discordRoleId;
        if (json.discordRoleName)
            this.discordRoleName = json.discordRoleName;
        if (json.discordServerId)
            this.discordServerId = json.discordServerId;
        if (json.discordServerName)
            this.discordServerName = json.discordServerName;
        if (json.discordChannelId)
            this.discordChannelId = json.discordChannelId;
        if (json.discordChannelName)
            this.discordChannelName = json.discordChannelName;
        if (json.tagUsers !== null)
            this.tagUsers = json.tagUsers;
        if (json.dmUsers !== null)
            this.dmUsers = json.dmUsers;
        if (json.customMessage)
            this.customMessage = json.customMessage;
    }
    public toJSON(): any
    {
        return {
            discordRoleId: this.discordRoleId,
            discordRoleName: this.discordRoleName,
            discordServerId: this.discordServerId,
            discordServerName: this.discordServerName,
            discordChannelId: this.discordChannelId,
            discordChannelName: this.discordChannelName,
            tagUsers: this.tagUsers,
            dmUsers: this.dmUsers,
            customMessage: this.customMessage
        };
    }
}

export class RaidNotificationProperties extends NotificationProperties
{
    @observable raidType: number = 0;

    constructor(json: any)
    {
        super(json);
        this.raidType = json.raidType;
    }

    public toJSON(): any
    {
        let ret: any = super.toJSON();
        ret.raidType = this.raidType;
        return ret;
    }
}

export interface IEventChannel
{
    serverId: string;
    serverName: string;
    channelId: string;
    channelName: string;
}

export interface IEventRole
{
    serverId: string;
    serverName: string;
    roleId: string;
    roleName: string;
}

export interface IScheduledEvent
{
    guildId: string;
    guildName: string;
    eventType: number;
    eventTypeName: string;
    time: string;
    playerAllyCode: number;
    playerName: string | null;
    raidInstanceId: string | null;
    discordServerName: string;
    discordChannelName: string;
    discordRoleName: string;
    cancelled: boolean;
    cancelledByDiscordTag: string;
}

export interface IPlayerViolation
{
    allyCode: number;
    playerName: string;
    discordTag: string;
    inGuild: boolean;
    violations: IViolation[];
}

export interface IViolation
{
    id: number;
    type: "TICKETS" | "RAID_RANCOR" | "RAID_TANK" | "RAID_SITH" | "WAR" | "BATTLE" | "RAID_RANCOR_CHALLENGE" | "BATTLE_SM";
    violation: number;
    threshold: number;
    time: string;
    enteredBy: string | null;
}

export interface IExcusedPlayer
{
    allyCode: number;
    playerName: string;
    discordTag: string;
    excused: IExcuseInstance[];
}

export interface IExcuseInstance
{
    id: number;
    startDate: string;
    endDate: string | null;
    note: string | null;
    warn: boolean;
    violate: boolean;
    notifyBoth: boolean;
    alternateDiscordTag: string | null;
}
