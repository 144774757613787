import React from "react";
import { observer } from "mobx-react";
import IPageProps from "../IPageProps";
import BaseAPI from "../../service/BaseAPI";
import { observable, action } from "mobx";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

@observer
class Callback extends React.Component<IPageProps, any> {
    @observable error: any = null;
    
    componentDidMount() {
        // get all entities - GET  

        let params = new URLSearchParams(window.location.search.substring(1));
        const rememberMe = localStorage.getItem("rememberMe") === "1";

        if (params.has("hutoken"))
        {
            BaseAPI.huLogin(this.props.user, params.get("hutoken")!, "/callback", rememberMe ? this.props.cookies : undefined)
            .then(() => {
                this.props.history.push('/accountselect');
            }).catch(action((error) => {
                this.error = error;
            }));
        }
        else
        {
            let accessToken = params.get("code");
            //console.log(accessToken)

            //let sessionId = ""
            BaseAPI.discordLogin(this.props.user, accessToken!, "/callback", rememberMe ? this.props.cookies : undefined)
            .then(() => {
                this.props.history.push('/accountselect');
            }).catch(action((error) => {
                this.error = error;
            }));
        }
    }

    render() {
        if (this.error)
        {
            return <div>{this.error.errorMessage}</div>;
        }
        return (
            <LoadingSpinner spinning={true} size={"large"} text={'Loading..'} />
        );
    }
}

export default Callback;