import TWAPI from "../service/TWAPI";
import BaseAPI from "../service/BaseAPI";
import {IGuildSquadTemplate, squadCategories} from "../model/Squads";
import UserData from "../model/UserData";
import GameData from "../model/GameData";
import i18next from "i18next";
import {Modal, Spin} from "antd";
import {ShareType} from "../model/IShare";

interface IOldGuildSquadTemplate {
    id: number;
    name: string;
    category: string;
    createdBy: string;
    editedBy: string;
    units: IOldGuildSquadUnits[];
}

interface IOldGuildSquadUnits {
    id: number;
    characterId: string;
    characterName: string;
    hasZeta: boolean;
    hasOmicron: boolean;
    hasUltimate: boolean;
    preferredUnit: string | undefined;
    filters: IOldGuildSquadFilter;
    subsPriority: 'order' | 'gear';
    subs?: IOldGuildSquadSubUnits[];
}

interface IOldGuildSquadSubUnits {
    id: number;
    characterId: string;
    characterName: string;
    hasZeta: boolean;
    filters: IOldGuildSquadFilter;
}

interface IOldGuildSquadFilter {
    minGP: number;
    gear: number;
    relic: number;
    stars: number;
}

export async function checkForSquads(user: UserData, gameData: GameData): Promise<void>
{
    let cancel = false;

    if (!user.isAdmin || user.readOnly)
        return;

    let done = await BaseAPI.getGuildSetting(user, "squadUpgradeDone2");
    if (done.length > 0)
        return;

    let waitDlg = Modal.info({ maskClosable: false, okText: i18next.t("common:ui.task_cancel_button"), onOk: () => cancel = true, title: "Please Wait, upgrading squads", content: <Spin size="large" /> });
    
    try
    {
        await BaseAPI.getSquads(user); // to populate the guild id
        await TWAPI.teamList(user);
        const settings = await BaseAPI.getGuildSetting(user, "guildSquadTemplate");
        const guildSquadTemplate: IOldGuildSquadTemplate[] = settings.length > 0 ? JSON.parse(settings[0].value) : [];

        if (user.currentPlayer && user.currentPlayer.tWTeams && user.currentPlayer.tWTeams.length > 0)
        {
            waitDlg.update({ 
                content: <div>
                    <div>Upgrading {user.currentPlayer.tWTeams.length} TW teams</div>
                    <Spin size="large" />
                    </div>,
                okButtonProps: { disabled: true, style: { display: 'none' } }
            });
            for (let t of user.currentPlayer.tWTeams)
            {
                if (cancel)
                {
                    waitDlg.destroy();
                    return;
                }
                const template: IGuildSquadTemplate = {
                    id: 0,
                    name: t.name,
                    category: ["TW"],
                    editedBy: {
                        playerName: "",
                        allyCode: 0,
                        guildName: "",
                        discordTag: "",
                        createdUTC: ""
                    },
                    createdBy: {
                        playerName: "",
                        allyCode: 0,
                        guildName: "",
                        discordTag: "",
                        createdUTC: ""
                    },
                    sortOrder: user.currentPlayer.tWTeams.length,
                    combatType: t.fleet ? 2 : 1,
                    units: t.unit.map((u, index) => {
                        return {
                            id: index,
                            characterId: u.baseId,
                            characterName: u.name,
                            requirements: {
                                hasOmicron: false,
                                hasZeta: false,
                                hasUltimate: false,
                                filters: {
                                    minGP: 1000,
                                    gear: 1,
                                    relic: 0,
                                    stars: 2
                                },
                                subsPriority: "order"
                            }
                        }
                    }),
                    poolUnits: undefined,
                    type: undefined,
                    version: 1
                };

                await BaseAPI.upsertGuildSquad(user, template, ShareType.Guild);
                await TWAPI.teamDelete(user, t.name);
            }
        }

        if (guildSquadTemplate.length > 0)
        {
            waitDlg.update({ 
                content: <div>
                    <div>Upgrading {guildSquadTemplate.length} Old-style guild teams</div>
                    <Spin size="large" />
                    </div> 
            });
            for (let t of guildSquadTemplate)
            {
                let cat = t.category;
                if (!(squadCategories.includes(cat)))
                {
                    cat = "Event";
                }
                if (t.units.length === 0)
                    continue;
                let u = gameData.getUnit(t.units[0].characterId);
                let combatType = u!.combatType;

                const template: IGuildSquadTemplate = {
                    id: 0,
                    name: t.name,
                    category: [cat],
                    editedBy: {
                        playerName: "",
                        allyCode: 0,
                        guildName: "",
                        discordTag: "",
                        createdUTC: ""
                    },
                    createdBy: {
                        playerName: "",
                        allyCode: 0,
                        guildName: "",
                        discordTag: "",
                        createdUTC: ""
                    },
                    sortOrder: guildSquadTemplate.length,
                    combatType,
                    units: t.units.map((u, index) => {
                        return {
                            id: index,
                            characterId: u.characterId,
                            characterName: u.characterName,
                            preferredUnit: u.preferredUnit,
                            requirements: {
                                hasOmicron: u.hasOmicron,
                                hasZeta: u.hasZeta,
                                hasUltimate: u.hasUltimate,
                                filters: {
                                    minGP: u.filters.minGP,
                                    gear: u.filters.gear,
                                    relic: u.filters.relic,
                                    stars: u.filters.stars
                                },
                                subsPriority: u.subsPriority,
                                subs: u.subs ? u.subs.map((s, index) => ({
                                    id: s.id,
                                    characterId: s.characterId,
                                    characterName: s.characterName,
                                    hasZeta: s.hasZeta,
                                    filters: s.filters
                                })) : undefined
                            }
                        }
                    }),
                    poolUnits: undefined,
                    type: undefined,
                    version: 1
                };
        
                await BaseAPI.upsertGuildSquad(user, template, ShareType.Guild);
            }
            await BaseAPI.setGuildSetting(user, "guildSquadTemplate", JSON.stringify([]));
        }

        await BaseAPI.setGuildSetting(user, "squadUpgradeDone2", "true");
        waitDlg.destroy();
    }
    catch (err)
    {
        waitDlg.destroy();
        Modal.error({title: i18next.t("common:ui.error_dlg_title"), content: <span>{err.errorMessage}</span>, maskClosable: false});
    }
}
