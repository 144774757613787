
export function sorting(a?: string, b?: string) {
    if (typeof a !== "string" && typeof b !== "string") {
        return 0;
    }

    if (typeof a !== "string") {
        return -1;
    }

    if (typeof b !== "string") {
        return 1;
    }

    return a.localeCompare(b);
}

export function sortNumber(a?: number, b?: number) {
    if (typeof a !== "number" && typeof b !== "number") {
        return 0;
    }

    if (typeof a !== "number") {
        return -1;
    }

    if (typeof b !== "number") {
        return 1;
    }

    return a - b;
}