
import ModData from './../model/ModData';
import StatIds from '../model/StatIds';
import { UnitStats, ModCalculator, ModCompletedSet } from './mod-calculator';
import PlayerCharacterData from './../model/PlayerCharacterData';
import ModSets from './../model/ModSets';

export class AppliedMod 
{
    appliedStats: UnitStats;
    mod: ModData;

    primaryStrength: number = 0;
    secondaryStrength: number = 0;
    setStrength: number = 0;
    independentStrength: number = 0;
    usable: boolean = true;

    constructor(mod: ModData, unit: PlayerCharacterData, baseStats: UnitStats)
    {
        this.mod = mod;
        this.usable = ModCalculator.useable(mod, unit);
        this.appliedStats = ModCalculator.calculateUpdatedStats(unit, baseStats, [mod]);
    }

    public static getAppliedMods(playerUnit: PlayerCharacterData,
        convertToSixE: boolean,
        mods: ModData[],
        unitAutomationSettings: ModUnitAutomationSettings[],
        globalAutomationAlgorithmSettings: ModAutomationSettings): AppliedMod[]
    {
        let retVal: AppliedMod[] = [];

        if (convertToSixE)
        {
            retVal = mods.map(mod => new AppliedMod(mod.slicedMod === null ? mod : mod.slicedMod, playerUnit, playerUnit.baseStats));
        } else
        {
            retVal = mods.map(mod => new AppliedMod(mod, playerUnit, playerUnit.baseStats));
        }

        let modUnitAutomationSettings = unitAutomationSettings.find(setting => setting.name === playerUnit.baseId)!;

        retVal.forEach(evaluatedMod =>
        {
            ModAutomationCalculator.evaluateMod(evaluatedMod, modUnitAutomationSettings, globalAutomationAlgorithmSettings);
        })

        return retVal;
    }
}

export class ModAutomationSettings
{

    primaryWeight: number = 3;
    secondaryWeight: number = 1;
    setWeight: number = 3;
}

export class ModUnitAutomationSettings
{
    name: string = "";
    primaryMultipliers: { [index: string]: { [index: string]: number } } = {};
    secondaryTypeMultipliers: { [index: string]: number } = {};
    fourSetMultipliers: { [index: string]: number } = {};
    fourSetsMultiplier: number = 1;
    twoSetsMultiplier: number = 1;
    twoSetOccurrenceCounts: TwoSetOccurrence[] = [];
    statTotals: { [index: string]: number } = {};

    commonSet1Name: string | null = null;
    commonSet2Name: string | null = null;
    commonSet3Name: string | null = null;
}
export interface TwoSetOccurrence
{
    set: number;
    occurrence: number;
    count: number;
}

export class ModAutomationCalculator
{
    static MOD_SECONDARY_HEALTH_NAME = "Health";
    static MOD_SECONDARY_OFFENSE_NAME = "Offense";
    static MOD_SECONDARY_DEFENSE_NAME = "Defense";
    static MOD_SECONDARY_SPEED_NAME = "Speed";
    static MOD_SECONDARY_CRIT_CHANCE_NAME = "Critical Chance";
    static MOD_SECONDARY_POTENCY_NAME = "Potency";
    static MOD_SECONDARY_TENACITY_NAME = "Tenacity";
    static MOD_SECONDARY_PROTECTION_NAME = "Protection";

    static MAX_SECONDARY_HEALTH = 428;
    static MAX_SECONDARY_HEALTH_PERCENT = 1.13;
    static MAX_SECONDARY_PROTECTION = 830;
    static MAX_SECONDARY_PROTECTION_PERCENT = 2.33;
    static MAX_SECONDARY_OFFENSE = 46;
    static MAX_SECONDARY_OFFENSE_PERCENT = .56;
    static MAX_SECONDARY_DEFENSE = 9;
    static MAX_SECONDARY_DEFENSE_PERCENT = 1.7;
    static MAX_SECONDARY_CRIT_CHANCE = 2.25;
    static MAX_SECONDARY_POTENCY = 2.25;
    static MAX_SECONDARY_SPEED = 6;
    static MAX_SECONDARY_TENACITY = 2.25;

    public static ALL_SECONDARIES: string[] = [ModAutomationCalculator.MOD_SECONDARY_HEALTH_NAME, ModAutomationCalculator.MOD_SECONDARY_OFFENSE_NAME, ModAutomationCalculator.MOD_SECONDARY_DEFENSE_NAME,
    ModAutomationCalculator.MOD_SECONDARY_SPEED_NAME, ModAutomationCalculator.MOD_SECONDARY_CRIT_CHANCE_NAME, ModAutomationCalculator.MOD_SECONDARY_POTENCY_NAME,
    ModAutomationCalculator.MOD_SECONDARY_TENACITY_NAME, ModAutomationCalculator.MOD_SECONDARY_PROTECTION_NAME];

    static MAX_PRIMARY_POINTS = 6;
    static MAX_SECONDARY_POINTS = 8 * 6;

    public static calculateMatchStrength(evaluatedMods: AppliedMod[], completedSets: ModCompletedSet[],
        unitSettings: ModUnitAutomationSettings, settings: ModAutomationSettings): number
    {
        let primaryStrength = 0;
        let secondaryStrength = 0;
        let setStrength = ModAutomationCalculator.calculateMatchSetStrength(evaluatedMods, completedSets, unitSettings);

        evaluatedMods.forEach(evaluatedMod =>
        {
            primaryStrength = primaryStrength + evaluatedMod.primaryStrength;
            secondaryStrength = secondaryStrength + evaluatedMod.secondaryStrength;

        });

        primaryStrength = primaryStrength / ModAutomationCalculator.MAX_PRIMARY_POINTS;
        secondaryStrength = secondaryStrength / ModAutomationCalculator.MAX_SECONDARY_POINTS;
        return (primaryStrength * settings.primaryWeight) + (secondaryStrength * settings.secondaryWeight) + (setStrength * settings.setWeight);
    }

    private static getCompletedCount(completedSets: ModCompletedSet[], set: ModSets): number
    {
        let completed = completedSets.find(completedSet => completedSet.id === set);
        return completed === undefined ? 0 : completed.fullCount + completed.partialCount;
    }

    public static calculateMatchSetStrength(evaluatedMods: AppliedMod[], completedSets: ModCompletedSet[], unitSettings: ModUnitAutomationSettings): number
    {

        let retVal: number = 0;


        if (completedSets.length === 0)
        {
            return 0.00001;
        }

        let offenseCompleted = this.getCompletedCount(completedSets, ModSets.Offense);
        let critDamageCompleted = this.getCompletedCount(completedSets, ModSets.CriticalDamage);
        let speedCompleted = this.getCompletedCount(completedSets, ModSets.Speed);

        let critChanceCompleted = this.getCompletedCount(completedSets, ModSets.CriticalChance);
        let defenseCompleted = this.getCompletedCount(completedSets, ModSets.Defense);
        let healthCompleted = this.getCompletedCount(completedSets, ModSets.Health);
        let potencyCompleted = this.getCompletedCount(completedSets, ModSets.Potency);
        let tenacityCompleted = this.getCompletedCount(completedSets, ModSets.Tenacity);

        if (offenseCompleted > 0 || critDamageCompleted > 0 || speedCompleted > 0)
        {
            let twoFourMultiplier = unitSettings.fourSetsMultiplier;

            let offenseMultipler = unitSettings.fourSetMultipliers[ModSets.Offense];
            let critDmgMultipler = unitSettings.fourSetMultipliers[ModSets.CriticalDamage];
            let speedMultipler = unitSettings.fourSetMultipliers[ModSets.Speed];

            let critChanceMultiplier = unitSettings.fourSetMultipliers[ModSets.CriticalChance];
            let defenseMultiplier = unitSettings.fourSetMultipliers[ModSets.Defense];
            let healthMultiplier = unitSettings.fourSetMultipliers[ModSets.Health];
            let potencyMultiplier = unitSettings.fourSetMultipliers[ModSets.Potency];
            let tenacityMultiplier = unitSettings.fourSetMultipliers[ModSets.Tenacity];

            offenseMultipler = offenseMultipler == null ? 0 : offenseMultipler;
            critDmgMultipler = critDmgMultipler == null ? 0 : critDmgMultipler;
            speedMultipler = speedMultipler == null ? 0 : speedMultipler;

            critChanceMultiplier = critChanceMultiplier == null ? 0 : critChanceMultiplier;
            defenseMultiplier = defenseMultiplier == null ? 0 : defenseMultiplier;
            healthMultiplier = healthMultiplier == null ? 0 : healthMultiplier;
            potencyMultiplier = potencyMultiplier == null ? 0 : potencyMultiplier;
            tenacityMultiplier = tenacityMultiplier == null ? 0 : tenacityMultiplier;

            let fourSetMultiplier = (offenseMultipler * offenseCompleted) +
                (speedMultipler * speedCompleted) +
                (critDmgMultipler * critDamageCompleted);

            let twoSetMultiplier = (critChanceMultiplier * critChanceCompleted) +
                (defenseMultiplier * defenseCompleted) +
                (healthMultiplier * healthCompleted) +
                (potencyMultiplier * potencyCompleted) +
                (tenacityMultiplier * tenacityCompleted);

            let multiplier = ((fourSetMultiplier * 2) + twoSetMultiplier) / 3;
            retVal = multiplier * twoFourMultiplier;

        } else
        {

            let twoFourMultiplier = unitSettings.twoSetsMultiplier;

            let twoSetCounts: number[] = [];
            unitSettings.twoSetOccurrenceCounts.forEach(twoSetOccurance =>
            {
                let setOccuranceCount = this.getCompletedCount(completedSets, twoSetOccurance.set);
                if (setOccuranceCount >= twoSetOccurance.occurrence)
                {
                    twoSetCounts.push(twoSetOccurance.count);
                }
            });

            let set1Value = twoSetCounts.length > 0 ? (twoFourMultiplier * twoSetCounts[0] / unitSettings.twoSetOccurrenceCounts[0].count) : 0;
            let set2Value = twoSetCounts.length > 1 ? (twoFourMultiplier * twoSetCounts[1] / unitSettings.twoSetOccurrenceCounts[1].count) : 0;
            let set3Value = twoSetCounts.length > 2 ? (twoFourMultiplier * twoSetCounts[2] / unitSettings.twoSetOccurrenceCounts[2].count) : 0;
            retVal = (set1Value / 3) + (set2Value / 3) + (set3Value / 3);
        }

        return retVal;
    }

    public static evaluateMod(evaluatedMod: AppliedMod, unitSettings: ModUnitAutomationSettings | null, settings: ModAutomationSettings)
    {
        if (unitSettings !== null && unitSettings !== undefined)
        {
            evaluatedMod.primaryStrength = ModAutomationCalculator.calculatePrimaryStrength(evaluatedMod.mod, unitSettings);
            evaluatedMod.secondaryStrength = ModAutomationCalculator.caclulateSecondaryStrength(evaluatedMod.mod, unitSettings);
            evaluatedMod.setStrength = ModAutomationCalculator.calculateSetStrength(evaluatedMod.mod, unitSettings);
            evaluatedMod.independentStrength = (evaluatedMod.primaryStrength * settings.primaryWeight) *
                (evaluatedMod.secondaryStrength * settings.secondaryWeight) *
                (evaluatedMod.setStrength * settings.setWeight);
        }
    }

    public static calculatePrimaryStrength(mod: ModData, unitSettings: ModUnitAutomationSettings)
    {
        let primaryMultiplier = unitSettings.primaryMultipliers[mod.slot.toString()][mod.primary.statId];
        return primaryMultiplier == null || isNaN(Number(primaryMultiplier)) ? 0 : Number(primaryMultiplier);
    }

    public static calculateSetStrength(mod: ModData, unitSettings: ModUnitAutomationSettings): number
    {
        let setStrength = unitSettings.fourSetMultipliers[mod.set];
        return setStrength == null || isNaN(Number(setStrength)) ? 0 : Number(setStrength);
    }

    public static caclulateSecondaryStrength(mod: ModData, unitSettings: ModUnitAutomationSettings): number
    {
        let secondaryStrength: number = 0;


        ModAutomationCalculator.ALL_SECONDARIES.forEach(secondaryName =>
        {
            let modQualityScore = this.getSecondaryQuality(secondaryName, mod);
            let optimizationMultiplier = unitSettings.secondaryTypeMultipliers[secondaryName];
            optimizationMultiplier = optimizationMultiplier == null || isNaN(Number(optimizationMultiplier)) ? 0 : Number(optimizationMultiplier);
            secondaryStrength = secondaryStrength + (modQualityScore * optimizationMultiplier);
        });

        return secondaryStrength;
    }

    public static getSecondaryQuality(secondary: string, mod: ModData): number
    {

        switch (secondary)
        {
            case ModAutomationCalculator.MOD_SECONDARY_HEALTH_NAME: {
                let healthRollQuality: number = ModAutomationCalculator.getSecondaryValueFromMod(mod, StatIds.Health) / ModAutomationCalculator.MAX_SECONDARY_HEALTH;
                let healthPercentRollQuality: number = ModAutomationCalculator.getSecondaryValueFromMod(mod, StatIds.HealthPct) / ModAutomationCalculator.MAX_SECONDARY_HEALTH_PERCENT;
                let healthOverallQuality: number = healthRollQuality + healthPercentRollQuality;
                return healthOverallQuality;
            }
            case ModAutomationCalculator.MOD_SECONDARY_PROTECTION_NAME: {
                let protectionRollQuality: number = ModAutomationCalculator.getSecondaryValueFromMod(mod, StatIds.Protection) / ModAutomationCalculator.MAX_SECONDARY_PROTECTION;
                let protectionPercentRollQuality: number = ModAutomationCalculator.getSecondaryValueFromMod(mod, StatIds.ProtectionPct) / ModAutomationCalculator.MAX_SECONDARY_PROTECTION_PERCENT;
                let protectionOverallQuality: number = protectionRollQuality + protectionPercentRollQuality;
                return protectionOverallQuality;
            }
            case ModAutomationCalculator.MOD_SECONDARY_OFFENSE_NAME: {
                let offenseRollQuality: number = ModAutomationCalculator.getSecondaryValueFromMod(mod, StatIds.Offense) / ModAutomationCalculator.MAX_SECONDARY_OFFENSE;
                let offensePercentRollQuality: number = ModAutomationCalculator.getSecondaryValueFromMod(mod, StatIds.OffensePct) / ModAutomationCalculator.MAX_SECONDARY_OFFENSE_PERCENT;
                let offenseOverallQuality: number = offenseRollQuality + offensePercentRollQuality;

                return offenseOverallQuality;
            }
            case ModAutomationCalculator.MOD_SECONDARY_DEFENSE_NAME: {
                let defenseRollQuality: number = ModAutomationCalculator.getSecondaryValueFromMod(mod, StatIds.Defense) / ModAutomationCalculator.MAX_SECONDARY_DEFENSE;
                let defensePercentRollQuality: number = ModAutomationCalculator.getSecondaryValueFromMod(mod, StatIds.DefensePct) / ModAutomationCalculator.MAX_SECONDARY_DEFENSE_PERCENT;
                let defenseOverallQuality: number = defenseRollQuality + defensePercentRollQuality;
                return defenseOverallQuality;
            }
            case ModAutomationCalculator.MOD_SECONDARY_CRIT_CHANCE_NAME: {
                let critChanceRollQuality: number = ModAutomationCalculator.getSecondaryValueFromMod(mod, StatIds.CriticalChance) / ModAutomationCalculator.MAX_SECONDARY_CRIT_CHANCE;
                return critChanceRollQuality;
            }
            case ModAutomationCalculator.MOD_SECONDARY_POTENCY_NAME: {
                let potencyRollQuality: number = ModAutomationCalculator.getSecondaryValueFromMod(mod, StatIds.Potency) / ModAutomationCalculator.MAX_SECONDARY_POTENCY;
                return potencyRollQuality;
            }
            case ModAutomationCalculator.MOD_SECONDARY_SPEED_NAME: {
                let speedRollQuality: number = ModAutomationCalculator.getSecondaryValueFromMod(mod, StatIds.Speed) / ModAutomationCalculator.MAX_SECONDARY_SPEED;
                return speedRollQuality;
            }
            case ModAutomationCalculator.MOD_SECONDARY_TENACITY_NAME: {
                let tenacityRollQuality: number = ModAutomationCalculator.getSecondaryValueFromMod(mod, StatIds.Tenacity) / ModAutomationCalculator.MAX_SECONDARY_TENACITY;
                return tenacityRollQuality;
            }
        }
        throw new Error("Did not match secondary type: " + secondary);
    }


    public static getSecondaryValueFromMod(mod: ModData, secondary: number): number
    {
        let retVal: number = 0;

        mod.secondaries.forEach(secondaryStat =>
        {
            if (secondaryStat.statId === secondary)
            {

                switch (secondary)
                {
                    case StatIds.Health: {
                        retVal = secondaryStat.statValueDecimal * 0.0001;
                        break;
                    }
                    case StatIds.HealthPct: {
                        retVal = secondaryStat.statValueDecimal * 0.01;
                        break;
                    }
                    case StatIds.Protection: {
                        retVal = secondaryStat.statValueDecimal * 0.0001;
                        break;
                    }
                    case StatIds.ProtectionPct: {
                        retVal = secondaryStat.statValueDecimal * 0.01;
                        break;
                    }
                    case StatIds.Offense: {
                        retVal = secondaryStat.statValueDecimal * 0.0001;
                        break;
                    }
                    case StatIds.OffensePct: {
                        retVal = secondaryStat.statValueDecimal * 0.01;
                        break;
                    }
                    case StatIds.Defense: {
                        retVal = secondaryStat.statValueDecimal * 0.0001;
                        break;
                    }
                    case StatIds.DefensePct: {
                        retVal = secondaryStat.statValueDecimal * 0.01;
                        break;
                    }
                    case StatIds.CriticalChance: {
                        retVal = secondaryStat.statValueDecimal * 0.01;
                        break;
                    }
                    case StatIds.Potency: {
                        retVal = secondaryStat.statValueDecimal * 0.01;
                        break;
                    }
                    case StatIds.Speed: {
                        retVal = secondaryStat.statValueDecimal * 0.0001;
                        break;
                    }
                    case StatIds.Tenacity: {
                        retVal = secondaryStat.statValueDecimal * 0.01;
                        break;
                    }
                }
            }
        });
        return retVal;
    }
}