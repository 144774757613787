import React from "react";
import IPageProps from "../../IPageProps";
import {observer} from "mobx-react";
import {Popconfirm, Button, Radio, Tooltip, Input} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDownAZ, faArrowDownShortWide, faArrowUpAZ, faArrowUpShortWide, faCompressArrowsAlt, faExpandArrowsAlt, faMapMarkerAlt, faPaperPlane, faSensorAlert, faSignature, faUserBountyHunter, faUserCheck, faUserGroup, faUsers, faUsersClass, faUsersSlash,
} from "@fortawesome/pro-duotone-svg-icons";
import {action, observable, runInAction} from "mobx";
import TableTitle from "../../../components/TableTitle/TableTitle";
import {getDuplicateUnits, getExiledPlayers, ITerritoryWarsArray, ITerritoryWarsData, ITWZonesInGame, squadsTW, tooManySquadsPerZone, zonesTW} from "../../../model/TWPlanning";
import {IGuildPlayer} from "../../../model/GuildData";
import styles from "./styles/Templates.module.scss";
import moment from "moment";
import TWZones from "./components/TWZones";
import TWPlayers from "./components/TWPlayers";
import PatreonLevel from "../../../model/PatreonLevel";
import TWReminderSendingDMModal from "./Modal/TWReminderSendingDM";

interface ITWTemplateZonesProps extends IPageProps {
    template: ITerritoryWarsData;
    templateId: string;
    planId: string | undefined | null;
    category: typeof ITerritoryWarsArray;
    members: IGuildPlayer[];
    guildName: string;
    guildId: string;
    isAdmin: boolean;
    onDelete: (zoneId: number, squadId: string) => void;
    onSaveSquad: (squadTW: squadsTW) => void;
    onSaveAllSquads: (squadsTW: squadsTW[]) => void;
    onSaveSquads: (squadLeader: string, zoneId: number, newZoneId: number) => void;
    onMoveSquads: (zoneId: number, newZoneId: number, switchUnits: boolean, moveUnits: boolean) => void;
    assignSquads: (zone: zonesTW) => void;
    showZoneModal: () => void;
    showSendDMModal: () => void;
    setActiveTWTemplate: () => void;
    isCompactMode: boolean;
    compactMode: (isCompactMode: boolean) => void;
    zoneTW: (zone: zonesTW) => void;
    filledSquadsInTW: ITWZonesInGame[];
    showSendReminderBtn: boolean;
    onPlacingSquads: () => void;
}

@observer
export default class TWTemplateZones extends React.PureComponent<ITWTemplateZonesProps> {
    @observable showUnitNames: boolean = false;
    @observable isCompactMode: boolean = this.props.isCompactMode;
    @observable isPlayerMode: boolean = false;
    @observable showAllSquads: boolean = false;
    @observable showRemainingSquads: boolean = false;
    @observable showSendReminderModal: boolean = false;
    @observable sorting: number = 0;
    @observable private filter = "";

    @action
    private cancel() {
        return "";
    }

    @action.bound
    handleFilter(event: React.ChangeEvent<HTMLInputElement>) {
        this.filter = event.target.value;
    }

    private getDiscordDM(reminder: boolean){
        const dupes = getDuplicateUnits(this.props.template);
        const exiledPlayers = getExiledPlayers(this.props.template, this.props.members);
        const tooManySquads = tooManySquadsPerZone(this.props.template);
        const unassignedSquads = (this.props.template.squadsPerZone * 10) - this.props.template.squads.length;

        return <Popconfirm
            placement="bottom"
            title={
                unassignedSquads > 0 ? `You still have ${unassignedSquads} unassigned squads/fleets. Are you sure you want to send it?` :
                exiledPlayers.length > 0 ? 'You have squads from members that are no longer in your guild' :
                dupes.length > 0 ? 'There are players  with overlapping squads/units' : tooManySquads ?
                `Some zones have more squads than ${this.props.template.squadsPerZone}` : ''
            }
            onConfirm={() => {
                if(reminder){
                    runInAction(() => this.showSendReminderModal = true);
                } else {
                    this.props.showSendDMModal();
                }
            }}
            onCancel={() => this.cancel()}
            disabled={tooManySquads || exiledPlayers.length > 0 || dupes.length > 0}
            okText="Yes"
            cancelText="No"
        >
            <Tooltip placement="topLeft" title={exiledPlayers.length > 0 ? 'You have squads from members that are no longer in your guild' :
                    dupes.length > 0  ? 'There are players  with overlapping squads/units' : tooManySquads ?
                        `Some zones have more squads than ${this.props.template.squadsPerZone}` : unassignedSquads > 0 ? `You still have ${unassignedSquads} unassigned squads/fleets. Are you sure you want to send it?` : ''}>
                <Button
                    title={reminder ? `Send reminders to players who haven't placed yet` : ''}
                    type={'primary'}
                    disabled={tooManySquads || exiledPlayers.length > 0 || dupes.length > 0}
                    size={'middle'}
                    danger={true}
                    className={styles.btn}
                >
                    <FontAwesomeIcon icon={faPaperPlane} className={styles.icon}/>
                    <span>{reminder ? 'Send Reminder DMs' : 'Send Discord DMs'}</span>
                </Button>
            </Tooltip>
        </Popconfirm>
    }

    private sendDiscordDM(reminder: boolean){
        const dupes = getDuplicateUnits(this.props.template);
        const exiledPlayers = getExiledPlayers(this.props.template, this.props.members);
        const tooManySquads = tooManySquadsPerZone(this.props.template);
        const unassignedSquads = (this.props.template.squadsPerZone * 10) - this.props.template.squads.length;
        if(
            exiledPlayers.length > 0 ||
            unassignedSquads > 0 ||
            dupes.length > 0 ||
            tooManySquads ||
            this.props.planId !== this.props.templateId
        ){
            if(this.props.planId !== this.props.templateId){
                return <Popconfirm
                    placement="bottom"
                    title={'You need to set this plan as active before sending DMs. Do you want to set it as active?'}
                    onConfirm={() => {
                        this.props.setActiveTWTemplate();
                        if(reminder){
                            runInAction(() => this.showSendReminderModal = true);
                        } else {
                            this.props.showSendDMModal();
                        }
                    }}
                    onCancel={() => {
                        if(reminder){
                            runInAction(() => this.showSendReminderModal = true);
                        } else {
                            this.props.showSendDMModal();
                        }
                    }}
                    disabled={tooManySquads || exiledPlayers.length > 0 || dupes.length > 0}
                    okText="Yes"
                    cancelText="No"
                >
                    <Tooltip placement="bottom" title={exiledPlayers.length > 0 ? 'You have squads from members that are no longer in your guild' :
                            dupes.length > 0  ? 'There are players  with overlapping squads/units' : tooManySquads ?
                                `Some zones have more squads than ${this.props.template.squadsPerZone}` : unassignedSquads > 0 ? `You still have ${unassignedSquads} unassigned squads/fleets. Are you sure you want to send it?` : ''}>
                        <Button
                            type={'primary'}
                            title={reminder ? `Send reminders to players who haven't placed yet` : ''}
                            disabled={tooManySquads || exiledPlayers.length > 0 || dupes.length > 0}
                            size={'middle'}
                            danger={true}
                            className={styles.btn}
                        >
                            <FontAwesomeIcon icon={faPaperPlane} className={styles.icon}/>
                            <span>{reminder ? 'Send Reminder DMs' : 'Send Discord DMs'}</span>
                        </Button>
                    </Tooltip>
                </Popconfirm>
            } else {
                return this.getDiscordDM(reminder);
            }
        } else {
            return <Button
                title={reminder ? 'Send Reminder DMs to guild members' : 'Send Discord DMs to guild members'}
                type={'primary'}
                size={'middle'}
                className={styles.btn}
                onClick={() => {
                    if(reminder){
                        runInAction(() => this.showSendReminderModal = true);
                    } else {
                        this.props.showSendDMModal();
                    }
                }}
            >
                <FontAwesomeIcon icon={faPaperPlane} className={styles.icon}/>
                <span>{reminder ? 'Send Reminder DMs' : 'Send Discord DMs'}</span>
            </Button>;
        }
    }

    private renderSendReminderDMModal() {
        if (!this.showSendReminderModal) {
            return null;
        }

        return (
            <TWReminderSendingDMModal
                {...this.props}
                showModal={this.showSendReminderModal}
                onClose={() => runInAction(() => {
                    this.showSendReminderModal = false;
                })}
                template={this.props.template}
                members={this.props.members}
                guildName={this.props.guildName}
                templateId={this.props.templateId}
                filledSquads={this.props.filledSquadsInTW}
            />
        );
    }

    private clearAllExiledPlayers() {
        let squadsTW = this.props.template.squads;
        const exiledPlayers = getExiledPlayers(this.props.template, this.props.members);
        for (let i = 0; i < exiledPlayers.length; i++) {
            const allyCode = exiledPlayers[i];

            squadsTW = squadsTW.filter(x => x.allyCode !== allyCode);
        }

        return this.props.onSaveAllSquads(squadsTW);
    }

    render() {
        const exiledPlayers = getExiledPlayers(this.props.template, this.props.members);
        const unassignedSquads = (this.props.template.squadsPerZone * 10) - this.props.template.squads.length;
        const assignedPlayers = new Set<number>();

        for (let i = 0; i < this.props.template.squads.length; i++) {
            const squad =  this.props.template.squads[i];

            const player = this.props.members.find(x => x.allyCode === squad.allyCode);

            if(player){
                assignedPlayers.add(player.allyCode)
            }
        }

        return (
            <div className={styles.body}>
                <div className={styles.row}>
                    <TableTitle
                        compareUnits={false}
                        guildName={this.props.guildName}
                        members={this.props.members.length}
                        templateCategory={this.props.category}
                        assignedMembers={assignedPlayers.size}
                        isTW={true}
                        assignedSquads={this.props.template.squads.length}
                        unassignedCharacters={unassignedSquads}
                        description={this.props.template.description}
                        createdBy={this.props.template.creator.playerName}
                        dateCreated={moment.utc(this.props.template.creator.createdUTC).format("DD/MM/YY - HH:MM")}
                        lastEditedBy={this.props.template.updator.playerName}
                        dateEdited={this.props.template.updator.createdUTC !== "" ? moment.utc(this.props.template.updator.createdUTC).format("DD/MM/YY - HH:MM") : undefined}
                    />
                </div>
                <div className={`${styles.row} ${styles.column}`}>
                    <div className={styles.GroupBtn}>
                        {this.props.isAdmin && !this.showAllSquads && !this.props.showSendReminderBtn && this.sendDiscordDM(false)}
                        {this.props.isAdmin && !this.showAllSquads && this.props.showSendReminderBtn && this.sendDiscordDM(true)}

                        {/*{this.props.showSendReminderBtn && <Button title={`Send reminders to players who haven't placed yet`} type={'primary'}*/}
						{/*		size={'middle'} className={styles.btn}*/}
						{/*		onClick={() => runInAction(() => this.showSendReminderModal = true)}>*/}
						{/*	<FontAwesomeIcon icon={faPaperPlane} className={styles.icon}/>*/}
						{/*	<span>Send Reminder DMs</span>*/}
						{/*</Button>}*/}

                        {this.props.user.patreonLevel !== PatreonLevel.None && this.props.user.currentPlayer?.hasConnection && (this.showAllSquads || !this.props.isAdmin) && <Button
                            title={this.props.planId !== this.props.templateId ? 'This is not an active planning template for the current TW' : 'This will break the game connection!'}
                            type={'primary'}
                            danger={true}
                            disabled={this.props.planId !== this.props.templateId}
                            size={'middle'}
                            className={styles.btn}
                            onClick={() => this.props.onPlacingSquads()}
                        >
							<FontAwesomeIcon icon={faSensorAlert} className={styles.icon}/>
							<span>Place my squads</span>
						</Button>}
						<Button
                            title={`${!this.showUnitNames ? 'Hide unit avatar and show the names' : 'Show unit avatar and hide the names'}`}
                            type={this.showUnitNames ? 'default' : 'default'}
                            size={'middle'}
                            className={styles.btn}
                            onClick={() => runInAction(() => this.showUnitNames = !this.showUnitNames)}
                        >
							<FontAwesomeIcon icon={!this.showUnitNames ? faSignature : faUserBountyHunter } className={styles.icon}/>
							<span>{this.showUnitNames ? 'Hide' : 'Show'} unit names</span>
						</Button>
                        {this.props.isAdmin && <Button
                            title={`${!this.isCompactMode ? 'Compact' : 'Normal'} mode`}
                            type={this.isCompactMode ? 'primary' : 'default'}
                            size={'middle'}
                            className={styles.btn}
                            onClick={() => runInAction(() => {
                                this.isCompactMode = !this.isCompactMode;
                                this.props.compactMode(this.isCompactMode);
                            })}
                        >
                            <FontAwesomeIcon icon={this.showUnitNames ? faExpandArrowsAlt : faCompressArrowsAlt} className={styles.icon}/>
                            <span>{!this.isCompactMode ? 'Compact' : 'Normal'} mode</span>
                        </Button>}
                        {this.props.isAdmin && <Button
                            title={`Show the ${this.isPlayerMode ? 'zones' : 'players'}`}
                            type={this.isPlayerMode ? 'primary' : 'default'}
                            size={'middle'}
                            className={styles.btn}
                            onClick={() => runInAction(() => this.isPlayerMode = !this.isPlayerMode)}
                        >
							<FontAwesomeIcon icon={this.isPlayerMode ? faMapMarkerAlt : faUsersClass} className={styles.icon}/>
							<span>Show the {this.isPlayerMode ? 'zones' : 'players'}</span>
						</Button>}
                        {this.props.isAdmin && <Button
                            title={`Show ${this.showAllSquads ? 'all' : 'my'} squads`}
                            type={this.showAllSquads ? 'primary' : 'default'}
                            size={'middle'}
                            className={styles.btn}
                            onClick={() => runInAction(() => this.showAllSquads = !this.showAllSquads)}
                        >
							<FontAwesomeIcon icon={this.showAllSquads ? faUsers : faUserBountyHunter} className={styles.icon}/>
							<span>Show {this.showAllSquads ? 'all' : 'my'} squads</span>
						</Button>}
                        {this.props.isAdmin && this.props.showSendReminderBtn && <Button
                            title={`Show ${this.showRemainingSquads ? 'all' : 'remaining'} squads`}
                            type={this.showRemainingSquads ? 'primary' : 'default'}
                            size={'middle'}
                            className={styles.btn}
                            onClick={() => runInAction(() => this.showRemainingSquads = !this.showRemainingSquads)}
                        >
							<FontAwesomeIcon icon={this.showRemainingSquads ? faUserGroup : faUserCheck} className={styles.icon}/>
							<span>Show {this.showRemainingSquads ? 'all' : 'remaining'} squads</span>
						</Button>}
                        {this.props.isAdmin && (exiledPlayers.length > 0) && <Button
                            title={`Clear all the players that are no longer in the guild`}
                            type={'default'}
                            size={'middle'}
                            className={styles.btn}
                            onClick={() => this.clearAllExiledPlayers()}
                        >
							<FontAwesomeIcon icon={faUsersSlash} className={styles.icon}/>
							<span>Clear exiled players</span>
						</Button>}
                    </div>
                    {this.props.isAdmin && <div className={styles['bottom-row']}>
                        <div className={styles.search}>
                            <Input
                                className={styles.search}
                                placeholder={`Search for a player`}
                                allowClear
                                defaultValue={this.filter}
                                onChange={this.handleFilter}
                                autoFocus={true}
                            />
                        </div>
                        <div className={styles.sorting}>
                            <Radio.Group defaultValue={this.sorting} buttonStyle="solid">
                                <Radio.Button value={0} onChange={() => runInAction(() => this.sorting = 0)}><FontAwesomeIcon icon={faArrowDownAZ} /></Radio.Button>
                                <Radio.Button value={1} onChange={() => runInAction(() => this.sorting = 1)}><FontAwesomeIcon icon={faArrowUpAZ} /></Radio.Button>
                                <Radio.Button value={2} onChange={() => runInAction(() => this.sorting = 2)}><FontAwesomeIcon icon={faArrowDownShortWide} /></Radio.Button>
                                <Radio.Button value={3} onChange={() => runInAction(() => this.sorting = 3)}><FontAwesomeIcon icon={faArrowUpShortWide} /></Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>}
                </div>
                {!this.isPlayerMode && <TWZones
                    {...this.props}
					sortOrder={this.sorting}
                    filterPlayers={this.filter.slice()}
                    duplicateUnits={getDuplicateUnits(this.props.template)}
					showUnitNames={this.showUnitNames}
					showAllSquads={this.props.isAdmin ? this.showAllSquads : true}
					showRemainingSquads={this.props.isAdmin ? this.showRemainingSquads : false}
					isCompactMode={this.props.isAdmin ? this.isCompactMode : false}
                />}
                {this.isPlayerMode && <TWPlayers
                    {...this.props}
                />}
                {this.renderSendReminderDMModal()}
            </div>
        );
    }
}
