import React from "react";
import {observer} from "mobx-react";
import IPageProps from "../../../IPageProps";
import {action, observable, runInAction} from "mobx";
import {Button, Divider, InputNumber, Modal, Radio, Select, Switch} from "antd";
import styles from "../../../../styles/components/Modal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrashAlt} from "@fortawesome/pro-duotone-svg-icons";
import {IGuildSquadSubUnits, IGuildSquadUnits} from "../../../../model/Squads";
import {gear, relic, stars} from "../../../../model/Level";
import {searchRoles} from "../../../../utils/searchFactionRoles";

interface IAddUnitModalProps extends IPageProps{
    showModal: boolean;
    unitNumber: number;
    filterTypeCombat: number;
    minGP: number;
    minStars: number;
    minGearLevel: number;
    minRelicLevel: number;
    character: IGuildSquadUnits;
    mainCharacterHasZeta: boolean;
    mainCharacterHasUltimate: boolean;
    otherSelectedCharacters: string[];
    subs: IGuildSquadSubUnits[];
    subsPriority: 'order' | 'gear';
    onClose: () => void;
    onDelete: () => void;
    onSave: (character: IGuildSquadUnits) => void;
}

@observer
export class AddUnitModal extends React.PureComponent<IAddUnitModalProps> {
    @observable showModal: boolean = false;
    @observable characterId = this.props.character.characterId || "Select a unit";
    @observable characterName = this.props.character.characterName || "Select a unit";
    @observable minGP = this.props.minGP;
    @observable minStars = this.props.minStars;
    @observable minGearLevel: number = this.props.minGearLevel;
    @observable minRelicLevel: number = this.props.minRelicLevel;
    @observable mainCharacterHasZeta: boolean = this.props.character.requirements?.hasZeta || false;
    @observable mainCharacterHasOmicron: boolean = this.props.character.requirements?.hasOmicron || false;
    @observable mainCharacterHasUltimate: boolean = this.props.character.requirements?.hasUltimate || false;
    @observable.ref subCharacters: IGuildSquadSubUnits[] = this.props.subs;
    @observable subsPriority: 'order' | 'gear' = this.props.character.requirements?.subsPriority;


    private save() {
        this.props.onSave({
            ...this.props.character,
            characterId: this.characterId,
            characterName: this.characterName,
            requirements: {
                hasOmicron: this.mainCharacterHasOmicron,
                hasZeta: this.mainCharacterHasZeta,
                hasUltimate: this.mainCharacterHasUltimate,
                subsPriority: this.subsPriority,
                filters: {
                    minGP: this.minGP,
                    gear: this.minGearLevel,
                    relic: this.minRelicLevel,
                    stars: this.minStars
                },
                subs: this.subCharacters.slice()
            }
        });
    }

    @action
    private cancel() {
        return "";
    }

    private renderSub(subUnit?: IGuildSquadSubUnits) {
        const units = this.props.gameData.units || [];
        const selectedUnit = units.find(u => u.baseId === this.characterId)?.combatType === 1
        const combatType = this.getCombatType();

        return (
            <div key={subUnit?.id || 0} className={`${styles.row} ${styles['no-offset']}`}>
                <div className={`${styles.flexRow} ${styles.noWrap} ${styles.row} ${styles['no-offset']}`}>
                    <div className={`${styles.row} ${styles.offset}`}>
                        <div className={styles.label}>{subUnit ? 'Substitution #' + subUnit.id : '+Add Substitution (optional)'}</div>
                        <Select
                            className={styles.select}
                            showSearch={true}
                            filterOption={(input, option) =>
                                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={subUnit ? subUnit.characterName : "Select a unit"}
                            onSelect={(input, option) => {
                                runInAction(() => {
                                    const newUnits = [...this.subCharacters];
                                    const idx = newUnits.findIndex(x => x.id === subUnit?.id);

                                    if (option?.value === '-') {
                                        if (idx !== -1) {
                                            newUnits.splice(idx, 1);
                                        }
                                    } else {
                                        if (idx !== -1) {
                                            newUnits[idx] = {
                                                ...newUnits[idx],
                                                characterId: option?.props.key,
                                                characterName: option?.props.children
                                            }
                                        } else {
                                            newUnits.push({
                                                id: Math.max(0, ...newUnits.map(x => x.id)) + 1,
                                                characterId: option?.props.key,
                                                characterName: option?.props.children,
                                                filters: {
                                                    minGP: 1000,
                                                    gear: 1,
                                                    relic: 0,
                                                    stars: 2
                                                },
                                                hasZeta: false
                                            })
                                        }
                                    }
                                    this.subCharacters = newUnits;
                                })
                            }}
                            showArrow={true}
                        >
                            <Select.Option key={'None'} value={'-'}>None</Select.Option>
                            <Select.OptGroup label="Select a substitution:">
                                {units.filter(x => {
                                    if(
                                        this.props.filterTypeCombat === 2 &&
                                        (this.props.character.id === 0 ||
                                            (this.props.unitNumber === 1 && this.props.character.id === -1) ||
                                            (this.props.unitNumber > 1 && this.props.character.id === 0)
                                        )
                                    ) {
                                        return (this.characterId !== x.baseId) &&
                                        !this.subCharacters.find(z => z.characterId === x.baseId) &&
                                        x.combatType === combatType &&
                                        x.combatType === 2 && x.role?.find(s => s.key === 'role_capital') &&
                                        !this.props.otherSelectedCharacters.includes(x.baseId)
                                    } else {
                                        return (this.characterId !== x.baseId) &&
                                        !this.subCharacters.find(z => z.characterId === x.baseId) &&
                                        x.role?.find(s => s.key !== 'role_capital') &&
                                        x.combatType === combatType &&
                                        !this.props.otherSelectedCharacters.includes(x.baseId)
                                    }
                                }).map(unit => {
                                    return(
                                        <Select.Option key={unit.baseId} value={searchRoles(unit).join(', ')}>{unit.name}</Select.Option>
                                    );
                                })}
                            </Select.OptGroup>
                        </Select>
                    </div>

                    {selectedUnit && this.props.filterTypeCombat !== 2 && <div className={styles.column}>
                        <div className={styles.row}>
                            <div className={styles.label}>Min Gear:</div>
                            <Select
                                showSearch={false}
                                className={`${styles.select}`}
                                value={subUnit ? subUnit.filters.gear : 1}
                                onSelect={value => {
                                    runInAction(() => {
                                        const newUnits = [...this.subCharacters];
                                        const idx = newUnits.findIndex(x => x.id === subUnit?.id);

                                        if (idx !== -1) {
                                            newUnits[idx] = {
                                                ...newUnits[idx],
                                                filters: {
                                                    minGP: 1000,
                                                    gear: value,
                                                    relic: 0,
                                                    stars: 2
                                                }
                                            }
                                            this.subCharacters = newUnits;
                                        }
                                    })
                                }}
                                showArrow={true}
                            >
                                {gear.map(i =>
                                    <Select.Option key={i} value={i}>{i}</Select.Option>
                                )}
                            </Select>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.label}>Min Relic:</div>
                            <Select
                                showSearch={false}
                                className={`${styles.select}`}
                                value={subUnit ? subUnit.filters.relic : 0}
                                disabled={(subUnit && subUnit?.filters.gear < 13) || subUnit === undefined}
                                onSelect={value => {
                                    runInAction(() => {
                                        const newUnits = [...this.subCharacters];
                                        const idx = newUnits.findIndex(x => x.id === subUnit?.id);

                                        if (idx !== -1) {
                                            newUnits[idx] = {
                                                ...newUnits[idx],
                                                filters: {
                                                    minGP: 1000,
                                                    gear: 13,
                                                    relic: value,
                                                    stars: 7
                                                }
                                            }
                                            this.subCharacters = newUnits;
                                        }
                                    })
                                }}
                                showArrow={true}
                            >
                                {relic.map(i =>
                                    <Select.Option key={i} value={i}>{i}</Select.Option>
                                )}
                            </Select>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }

    private getCombatType() {
        const unit = this.props.gameData.units?.find(x => x.baseId === this.characterId);

        return unit?.combatType || 0;
    }

    render() {
        const units = this.props.gameData.units || [];
        const firstUnitAvailable = this.props.otherSelectedCharacters[0] ? this.props.otherSelectedCharacters[0] : this.characterId;
        const firstUnitCombatType = units.find(u => u.baseId === firstUnitAvailable)?.combatType === 1;
        const selectedUnit = units.find(u => u.baseId === this.characterId)?.combatType === 1
        return (
            <Modal
                title={`${this.props.character.id >= 0 ? "Edit" : "Add a "} unit #${this.props.character.id >= 0 ? this.props.character.id + 1 : this.props.unitNumber}`}
                visible={this.props.showModal}
                onCancel={this.props.onClose}
                footer={[
                    (this.props.character.id > 0 &&
                        <Button
                            key="deleteUnit"
                            title="Are you sure to delete this unit?"
                            type="link"
                            disabled={this.props.character.id === 1 && !selectedUnit && this.props.otherSelectedCharacters.length >= 1}
                            className={styles.delete}
                            onClick={() => this.props.onDelete()}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} className={styles.icon} />
                            Delete
                        </Button>),
                    <Button key="saveSettings" type="primary" onClick={() => this.save()} disabled={this.characterId === "Select a unit"}>
                        <FontAwesomeIcon icon={faSave} className={styles.icon} />
                        Save
                    </Button>
                ]}
            >
                <div className={styles.modal}>
                    <div className={`${styles.row} ${styles['no-offset']}`}>
                        <div className={styles.label}>Search by unit name, faction or role</div>
                        <div className={`${styles.flexRow} ${styles.row} ${styles['no-offset']}`}>
                            <Select
                                className={`${styles.select} ${ styles.full}`}
                                placeholder={'Select a unit'}
                                autoFocus={true}
                                showSearch={true}
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                defaultValue={this.props.gameData.getUnit(this.characterId)?.name ?? this.characterName}
                                onSelect={(value, option) => {runInAction(() => {
                                    this.characterId = option.key ? option.key : option.props.key;
                                    this.characterName = option.children;
                                    this.mainCharacterHasOmicron = false;
                                    this.mainCharacterHasUltimate = false;
                                    this.mainCharacterHasZeta = false;
                                })}}
                                showArrow={true}
                            >
                                {units.filter(unit => {
                                    if(this.props.character.id === 0 || (this.props.unitNumber > 1 && this.props.character.id === 0) || (this.props.unitNumber === 1 && this.props.character.id === -1)) {
                                        if(this.props.filterTypeCombat === 2){
                                            return unit.combatType === 2 && unit.role?.find(s => s.key === 'role_capital');
                                        } else {
                                            return unit.combatType === 1
                                        }
                                    } else {
                                        if(this.props.filterTypeCombat === 2){
                                            // console.log(true)
                                            return unit.combatType === 2 && unit.role?.find(s => s.key !== 'role_capital') && !this.props.otherSelectedCharacters.includes(unit.baseId);
                                        }
                                    }
                                    if(this.props.filterTypeCombat === 2) {
                                        return unit.combatType === 2 && !this.props.otherSelectedCharacters.includes(unit.baseId);
                                    } else if (this.props.filterTypeCombat === 1){
                                        return unit.combatType === 1 && !this.props.otherSelectedCharacters.includes(unit.baseId);
                                    }
                                    return !this.props.otherSelectedCharacters.includes(unit.baseId)

                                }).map(unit => {
                                    return(
                                        <Select.Option key={unit.baseId} value={searchRoles(unit).join(', ')}>{unit.name}</Select.Option>
                                    );
                                })}
                            </Select>

                            {this.characterId !== "Select a unit" && selectedUnit && !!this.props.gameData.getUnit(this.characterId)?.zeta?.length && <div className={styles.switch}>
								<Switch
									checked={this.mainCharacterHasZeta}
									onChange={ () => runInAction(() => this.mainCharacterHasZeta = !this.mainCharacterHasZeta)}
									className={styles.item}
								/> 1+ Zeta
							</div>}

                            {this.characterId !== "Select a unit" && selectedUnit && !!this.props.gameData.getUnit(this.characterId)?.omicron?.length && <div className={styles.switch}>
								<Switch
									checked={this.mainCharacterHasOmicron}
									onChange={ () => runInAction(() => this.mainCharacterHasOmicron = !this.mainCharacterHasOmicron)}
									className={styles.item}
								/> 1+ Omicron
							</div>}

                            {!!units.find(x => x.baseId === this.characterId)?.galacticLegend && selectedUnit && <div className={styles.switch}>
								<Switch
									checked={this.mainCharacterHasUltimate}
									onChange={ () => runInAction(() => this.mainCharacterHasUltimate = !this.mainCharacterHasUltimate)}
									className={styles.item}
								/> Has Ultimate
							</div>}
                        </div>
                    </div>
                    {firstUnitCombatType && this.props.filterTypeCombat !== 2 && <div className={styles.column}>
                        <div className={`${styles.row} `}>
                            <div className={styles.label}>Min Gear:</div>
                            <Select
                                showSearch={false}
                                className={`${styles.select}`}
                                value={this.minGearLevel}
                                onSelect={value => {
                                    runInAction(() => {
                                        this.minGearLevel = value;
                                        if(this.minGearLevel !== 13) {
                                            this.minRelicLevel = 0
                                        }
                                    })
                                }}
                                showArrow={true}
                            >
                                {gear.map(i =>
                                    <Select.Option key={i} value={i}>{i}</Select.Option>
                                )}
                            </Select>
                        </div>
                        <div className={`${styles.row}`}>
                            <div className={styles.label}>Min Relic:</div>
                            <Select
                                showSearch={false}
                                className={`${styles.select}`}
                                value={this.minRelicLevel}
                                disabled={this.minGearLevel < 13}
                                onSelect={value => {
                                    runInAction(() => this.minRelicLevel = value)
                                }}
                                showArrow={true}
                            >
                                {relic.map(i =>
                                    <Select.Option key={i} value={i}>{i}</Select.Option>
                                )}
                            </Select>
                        </div>
                    </div>}

                    <div className={styles.column}>
                        <div className={`${styles.row} ${styles['no-offset']}`}>
                            <div className={styles.label}>The minimum GP:</div>
                            <InputNumber
                                className={`${styles.input} ${styles.green}`}
                                value={this.minGP}
                                onChange={value => {
                                    runInAction(() => this.minGP = value)
                                }}
                                step="100"
                                max={150000}
                                min={100}
                            />
                        </div>
                        <div className={`${styles.row} ${styles['no-offset']}`}>
                            <div className={styles.label}>Min Stars:</div>
                            <Select
                                showSearch={false}
                                className={`${styles.select}`}
                                value={this.minStars}
                                onSelect={value => {
                                    runInAction(() => this.minStars = value)
                                }}
                                showArrow={true}
                            >
                                {stars.map(i =>
                                    <Select.Option key={i} value={i}>{i}</Select.Option>
                                )}
                            </Select>
                        </div>
                    </div>
                    <Divider type={'horizontal'} />
                    {this.characterId !== "Select a unit" && <div className={`${styles.row} highlighted`}>
                        <div className={`${styles.label}`}>How the substitute should be chosen:</div>
                        <Radio.Group
                            defaultValue={this.props.character.requirements?.subsPriority}
                            buttonStyle={"solid"}
                            optionType="button"
                        >
                            <Radio.Button
                                value={'order'}
                                onClick={(e) => runInAction(() => this.subsPriority = "order")}
                            >
                                {"In order: #1 > #2 > #3"}
                            </Radio.Button>
                            <Radio.Button
                                value={'gear'}
                                onClick={(e) => runInAction(() => this.subsPriority = "gear")}
                            >
                                By highest gear level
                            </Radio.Button>
                        </Radio.Group>
                    </div>}
                    {this.characterId !== "Select a unit" && this.subCharacters.map(x => this.renderSub(x))}
                    {this.characterId !== "Select a unit" && this.subCharacters.length < 5 && this.renderSub()}
                </div>

            </Modal>
        );
    }
}
