import React from "react";
import {observer} from "mobx-react";
import IPageProps from "../../../IPageProps";
import {action, observable, runInAction} from "mobx";
import {Button, Input, InputNumber, Modal, Select} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRepeat, faSave} from "@fortawesome/pro-duotone-svg-icons";
import {ICombatType, zonesTW} from "../../../../model/TWPlanning";
import styles from "../../../../styles/components/Modal.module.scss";

interface ITWZoneModalProps extends IPageProps{
    showModal: boolean;
    onClose: () => void;
    onSave: (zoneId: string | undefined, index: number, id: number, newZoneId: number, zoneName: string, combatType: string) => void;
    zone: zonesTW;
}

@observer
export class TWZoneModal extends React.PureComponent<ITWZoneModalProps> {
    @observable name = this.props.zone.name;
    @observable combatType = this.props.zone.combatType;
    @observable zoneId = this.props.zone.id;

    @action private cancel() {
        return "";
    }

    render() {
        return (
            <Modal
                title={`Edit zone ${this.props.zone.name}`}
                visible={this.props.showModal}
                onCancel={this.props.onClose}
                footer={[
                    <Button key="saveTWZone" type="primary" onClick={() => {
                        this.props.onSave(this.props.zone.zoneId, this.props.zone.index, this.props.zone.id, this.zoneId, this.name, this.combatType);
                    }} disabled={this.name.length < 1}>
                        <FontAwesomeIcon icon={faSave} className={styles.icon} />
                        Save
                    </Button>
                ]}
            >
                <div className={styles.modal}>
                    <div className={styles.row}>
                        <div className={styles.label}>Zone title:</div>
                        <Input
                            className={`${styles.input}`}
                            title={'You can add a maximum 8 characters'}
                            value={this.name.substring(0, 8)}
                            allowClear={true}
                            onChange={event => {runInAction(() => {this.name = event.target.value;})}}
                            type={"text"}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>Change combat type:</div>
                        <Select
                            className={`${styles.select}`}
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={this.combatType}
                            onChange={value => {
                                runInAction(() => {
                                    this.combatType = value;
                                })
                            }}
                            showArrow={true}
                        >
                            {(Object.getOwnPropertyNames(ICombatType) as (keyof typeof ICombatType)[]).map(i =>
                                <Select.Option key={i} value={ICombatType[i]}>{i}</Select.Option>
                            )}
                        </Select>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>Switch the zone <strong>#{this.props.zone.id}</strong> with zone:</div>
                        <InputNumber
                            className={`${styles.input}`}
                            style={{width: '100%'}}
                            title={'You can switch this zone with another zone that has the same combat type'}
                            value={this.zoneId}
                            max={10}
                            min={1}
                            onChange={value => {
                                runInAction(() => {
                                    this.zoneId = value;
                                })
                            }}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.note}>
                            By switching the zones, it will only re-arrange the bord. If you want to switch the units between the zones use the switch units button: <FontAwesomeIcon icon={faArrowsRepeat} />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}