import {Badge, Button, Card} from 'antd';
import React from 'react';
import styles from "./styles/StylesLayout.module.scss";
import IPageProps from "../../IPageProps";
import roteTB from "../media/img/rote.jpg";
import geosTB from "../media/img/geos-ls.jpg";
import TBPlayers from "../TBPlayers/tb-players";
import { BasicLayout } from '../../../layouts/BasicLayout';
import {observer} from "mobx-react";
import {observable, runInAction} from "mobx";
import CommandCenter from "./CommandCenter";
import CommandCenterROTE from "./CommandCenterROTE";

const { Meta } = Card;

interface ICommandCenterStyleProps extends IPageProps {
	tb: string;
}

@observer
class CCChoosePanel extends React.Component<ICommandCenterStyleProps> {
	@observable setRoteURL: string | undefined = undefined;

	render() {
		if (this.setRoteURL === 'new') {
			return <CommandCenterROTE {...this.props} back={() => runInAction(() => this.setRoteURL = undefined)} />;
		} else if (this.setRoteURL === 'old') {
			return <CommandCenter {...this.props} back={() => runInAction(() => this.setRoteURL = undefined)} />;
		}

		return (
			<BasicLayout {...this.props} requiredApiRights={[TBPlayers.PERMISSION_KEY]}>
				<div className={styles.container}>
					<div className={styles.header}>
						Select one of the Command Center Styles...
					</div>
					<div className={styles.cards}>
						<Button type="ghost" className={`${styles.btn} ${styles.none}`}
								onClick={() => runInAction(() => this.setRoteURL = 'old')}>
							<Card
								hoverable
								style={{width: 240}}
								cover={<img alt="rote" src={geosTB}/>}
							>
								<Meta title="OLD Command Center with Phases" description="HOTH/GEOS"/>
							</Card>
						</Button>
						<Button type="ghost" className={`${styles.btn} ${styles.none}`}
								onClick={() => runInAction(() => this.setRoteURL = 'new')}>
							<Badge.Ribbon text="Planets planning" color="red">
								<Card
									hoverable
									style={{width: 240}}
									cover={<img alt="rote" src={roteTB}/>}
								>
									<Meta title="NEW Command Center with Planets" description="Rise of the Empire"/>
								</Card>
							</Badge.Ribbon>
						</Button>
					</div>
				</div>
			</BasicLayout>
		);
	}
}

export default CCChoosePanel;