import { ITWAtackLogEntry } from "../model/TWData";
import UserData from "../model/UserData";
import BaseAPI from "./BaseAPI";

interface TwFilter
{
    team: number;
    zoneId: number[];
}

export interface ITwPlacement
{
    zoneId: string; // this is the zoneId from TWData/TWZoneData::zoneId
    unitBaseId: string[]; // this is the baseIds of a single team
}

class TWAPI
{
    public static async get(user: UserData, refresh: boolean, includePlayers: boolean, filterSide?: "Home" | "Away", filterZone?: number, instanceId?: string | null): Promise<any>
    {
        let filter: TwFilter | null = null;
        if (filterSide && filterZone !== undefined)
        {
            filter = {
                team: filterSide === "Home" ? 1 : 2,
                zoneId: [filterZone]
            };
        }
        return await BaseAPI.postToApi("tw/get", {
            sessionId: user.sessionId,
            refresh,
            includePlayers,
            filter: filter,
            instanceId: instanceId !== undefined ? instanceId : null
        });
    }

    public static async list(user: UserData): Promise<any>
    {
        return await BaseAPI.postToApi("tw/list", {
            sessionId: user.sessionId,
        });
    }

    public static async teamAdd(user: UserData, name: string, description: string, baseIds: string[]): Promise<any>
    {
        return await BaseAPI.postToApi("team/add", {
            sessionId: user.sessionId,
            name: name,
            description: description,
            unitBaseId: baseIds
        });
    }

    public static async teamDelete(user: UserData, name: string): Promise<any>
    {
        return await BaseAPI.postToApi("team/delete", {
            sessionId: user.sessionId,
            name: name
        });
    }

    public static async teamList(user: UserData): Promise<any>
    {
        if (user.currentPlayer !== null)
        {
            let response = await BaseAPI.postToApi("team/list", { sessionId: user.sessionId });
            user.currentPlayer.twTeamsFromJson(response);
        }
    }

    public static async placeTeams(user: UserData, placements: ITwPlacement[]): Promise<any>
    {
        return await BaseAPI.postToApi("tw/place", {
            sessionId: user.sessionId,
            placements: placements
        });
    }

    public static async setCurrentTWPlan(user: UserData, planId: string): Promise<any>
    {
        return await BaseAPI.postToApi("tw/upsert", {
            sessionId: user.sessionId,
            instance: {
                planId: planId
            }
        });
    }

    public static async analyzeZone(user: UserData, side: "Home" | "Away", zoneId: number) : Promise<any>
    {
        return await BaseAPI.postToApi("tw/zone/analyze", {
            sessionId: user.sessionId,
            filter: {
                team: side === "Home" ? 1 : 2,
                zoneId: [zoneId]
            }
        });
    }

    public static async getTWAttacks(user: UserData, warCount: number = 1, allyCode: number = 0, teamLeaderId: string = "", defenderLeaderId: string = ""): Promise<ITWAtackLogEntry[]>
    {
        let result = await BaseAPI.postToApi("tw/attacks/get", {
            sessionId: user.sessionId,
            guildId: allyCode !== 0 ? null : user.currentPlayer!.playerGuildId,
            allyCode: allyCode !== 0 ? allyCode : null,
            warCount,
            teamLeaderId: teamLeaderId ? teamLeaderId : null,
            defendTeamLeaderId: defenderLeaderId ? defenderLeaderId : null
        });

        return result.attacks.attacks
    }
}

export default TWAPI;