import React from 'react';
import {Spin} from 'antd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons";

import styles from './styles/LoadingSpinner.module.scss';
import {faExclamation} from "@fortawesome/pro-duotone-svg-icons";

interface ILoadingSpinnerProps {
	size: "small" | "large" | "default";
	spinning: boolean;
	success?: boolean;
	warning?: boolean;
	error?: boolean;
	text?: string;
	textWhite?: boolean;
}

export default class LoadingSpinner extends React.Component<ILoadingSpinnerProps>{
	render() {
		return (
			<div className={styles['loading-spinner']}>
				<div className={`${styles.loading} ${this.props.text ? styles.hasText : ''}`}>
					{(this.props.success && <FontAwesomeIcon icon={faCheck} className={`${styles.success} ${styles.icon}`} size={"5x"}/>) ||
					(this.props.warning && <FontAwesomeIcon icon={faExclamation} className={`${styles.warning} ${styles.icon}`} size={"3x"}/>) ||
					(this.props.error && <FontAwesomeIcon icon={faExclamationTriangle} className={`${styles.error} ${styles.icon}`} size={"3x"}/>) || (
						<div style={{fontSize: 60 + 'px'}}>
							<Spin size={this.props.size} spinning={this.props.spinning} />
						</div>
					)}
					{this.props.text && <div className={`${styles.text} ${this.props.textWhite ? styles.white : ''}`}>{this.props.text}</div>}
				</div>
			</div>
		);
	}
}


