import ModData from "../../../../model/ModData";
import PlayerCharacterData from "../../../../model/PlayerCharacterData";
import UnitData from "../../../../model/UnitData";

export class ModAssignmentConfiguration
{
    mod: ModData;
    slice: boolean;

    get sliceTarget()
    {
        return this.slice && this.mod.slicedMod !== null ? this.mod.slicedMod : this.mod;
    }

    constructor(mod: ModData, slice: boolean)
    {
        this.mod = mod;
        this.slice = slice;
    }

    static deriveVisibleMods(lockedMods: ModAssignmentConfiguration[], loadoutMods: ModAssignmentConfiguration[],
        gameMods: ModAssignmentConfiguration[], allLockedMods: ModData[], allLoadoutMods: ModData[],
        allWorkingsModsAssignments: ModAssignmentConfiguration[] = []): ModAssignmentConfiguration[]
    {

        let visibleMods = allWorkingsModsAssignments;
        let allWorkingsMods = allWorkingsModsAssignments.map(ma => ma.mod);

        // add locked mods if they are not working on others
        visibleMods = visibleMods.concat(
            ModAssignmentConfiguration.getVisibleMods(visibleMods, lockedMods, allWorkingsMods)
        );

        // add loadout mods if they are not working or locked
        visibleMods = visibleMods.concat(
            ModAssignmentConfiguration.getVisibleMods(visibleMods, loadoutMods, allWorkingsMods.concat(allLockedMods))
        );

        // add in game mods if they are not working or locked or in loadout
        visibleMods = visibleMods.concat(
            ModAssignmentConfiguration.getVisibleMods(visibleMods, gameMods, allWorkingsMods.concat(allLockedMods).concat(allLoadoutMods))
        );

        return visibleMods;
    }

    static getVisibleMods(chosenVisible: ModAssignmentConfiguration[], listToAdd: ModAssignmentConfiguration[],
        onOtherCharacters: ModData[]): ModAssignmentConfiguration[]
    {
        return listToAdd.filter(tam => chosenVisible.find(cv => cv.mod.slot === tam.mod.slot) === undefined).filter(
            tam => onOtherCharacters.find(oocm => oocm.id === tam.mod.id) === undefined);
    }
}

export interface IAvailableModAssignment
{
    currentAssignedCharacter: PlayerCharacterData;
    lockedToCharacter: boolean;
}

export class AvailableModAssignments
{
    assignedMods: Map<string, IAvailableModAssignment> = new Map();

    // get a list of mods that can be used 
    getUnlockedMods(allMods: ModData[], includeUnitsLockedMods: boolean, unit?: UnitData, priorityRankings?: string[], includedUnits?: string[]): string[]
    {
        let retVal: string[] = [];

        let unitRanking = unit === undefined || priorityRankings === undefined ? undefined : priorityRankings.indexOf(unit.baseId);

        this.assignedMods.forEach((value: IAvailableModAssignment, key: string) =>
        {
            let currentAssignedCharacter = value.currentAssignedCharacter.baseId;
            let currentRanking = priorityRankings === undefined ? undefined : priorityRankings.indexOf(currentAssignedCharacter);
            let higherCharacter = unitRanking === undefined || currentRanking === undefined || unitRanking <= currentRanking;

            let currentlyAssignedToExcludedCharacter = includedUnits !== undefined && includedUnits.indexOf(currentAssignedCharacter) === -1;


            if (currentlyAssignedToExcludedCharacter ||
                (higherCharacter && (value.lockedToCharacter === false || (includeUnitsLockedMods && unit !== undefined && unit.baseId === value.currentAssignedCharacter.baseId))))
            {
                retVal.push(key);
            }
        });
        retVal = retVal.concat(allMods.filter(m => this.assignedMods.has(m.id) === false).map(m => m.id));
        return retVal;
    }

    static modAvailableToUnit(unitBaseId: string, assignedMods: Map<string, IAvailableModAssignment>, priorityOrder: string[], includedUnits: string[], modId: string): boolean
    {

        let modAvailability = assignedMods.get(modId);


        if (modAvailability !== undefined)
        {
            let currentPriority = priorityOrder.indexOf(modAvailability.currentAssignedCharacter.baseId);
            let unitPriority = priorityOrder.indexOf(unitBaseId);
            let assignedToHigherCharacter = unitPriority > currentPriority && includedUnits.indexOf(modAvailability.currentAssignedCharacter.baseId) !== -1;
            let locked = modAvailability.lockedToCharacter && includedUnits.indexOf(modAvailability.currentAssignedCharacter.baseId) !== -1;
            if (modAvailability.currentAssignedCharacter.baseId !== unitBaseId && (assignedToHigherCharacter || locked))
            {
                return false;
            }

        }
        return true;
    }
}
