import { observable } from "mobx";

export const MediaSizes = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop: 2560
}

export const MediaDevice = {
    mobileS: `(min-width: ${MediaSizes.mobileS}px)`,
    mobileM: `(min-width: ${MediaSizes.mobileM}px)`,
    mobileL: `(min-width: ${MediaSizes.mobileL}px)`,
    tablet: `(min-width: ${MediaSizes.tablet}px)`,
    laptop: `(min-width: ${MediaSizes.laptop}px)`,
    laptopL: `(min-width: ${MediaSizes.laptopL}px)`,
    desktop: `(min-width: ${MediaSizes.desktop}px)`,
    desktopL: `(min-width: ${MediaSizes.desktop}px)`
};


export enum ScreenOrientationEnum
{
    Portrait = 1,
    Landscape
}

export class DisplaySettings
{
    @observable tinyScreen: boolean = false;
    @observable screenOrientation: ScreenOrientationEnum = ScreenOrientationEnum.Landscape;
}