import React from "react";
import styles from "./styles/Cards.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle,
    faEye,
    faLayerPlus,
    faTrashAlt,
    faUserCog,
} from "@fortawesome/pro-duotone-svg-icons";
import {Button, Card, Popconfirm} from "antd";
import Meta from "antd/lib/card/Meta";
import {Link} from "react-router-dom";
import {action} from "mobx";
import {ITerritoryWarsArray} from "../../model/TWPlanning";

export interface IGenericTemplate {
    id: number;
    name: string;
}

export interface ITWTemplate {
    id: string;
    data: {
        name: string;
        squadsPerZone: number;
    };
    categories: typeof ITerritoryWarsArray;
}

interface ICardsProps {
    cardLink: string;
    cardsTemplate: IGenericTemplate[] | undefined;
    twCardsTemplate: ITWTemplate[] | undefined;
    editTemplate: (idx: number | string) => void;
    deleteTemplate: (idx: number | string) => void;
    addImage: string;
    viewImage: string;
    viewImage2?: string;
    isAdmin: boolean;
    isDisabled?: boolean;
    toggleModal: () => void;
}

export class Cards extends React.Component<ICardsProps> {


    constructor(props: ICardsProps) {
        super(props);

        this.getCardTemplate = this.getCardTemplate.bind(this);
    }

    @action private cancel() {
        return "";
    }

    private getCardProps(){
        if(this.props.cardsTemplate === undefined){
            return this.props.twCardsTemplate;
        } else {
            return this.props.cardsTemplate;
        }
    }

    private getCardTemplate(name: string, templateId: any, squadsPerZone?: number, type?: string){

        return <Card
            key={`template_${templateId}`}
            className={styles.card}
            cover={
                <img
                    width={300}
                    height={'100%'}
                    alt="view_image"
                    src={this.props.twCardsTemplate !== undefined ? (type === 'Defence' ? this.props.viewImage : this.props.viewImage2) : this.props.viewImage}
                />
            }
            actions={[
                <Popconfirm
                    title="Are you sure to delete this template?"
                    key={'popCardsTemplate'}
                    onConfirm={() => this.props.deleteTemplate(templateId)}
                    onCancel={() => this.cancel()}
                    okText="Yes"
                    cancelText="No"
                    disabled={!this.props.isAdmin}
                >
                    <Button type={"text"} disabled={!this.props.isAdmin} className={styles.btn} danger={true}>
                        <FontAwesomeIcon icon={faTrashAlt} className={styles.icon} />
                        Delete
                    </Button>
                </Popconfirm>,
                <Button type={"text"} disabled={!this.props.isAdmin} className={styles.btn} onClick={() => this.props.editTemplate(templateId)}>
                    <FontAwesomeIcon icon={faUserCog} className={styles.icon} />
                    Settings
                </Button>,
                <Link className={`ant-btn ant-btn-text ${styles.btn}`} to={`${this.props.cardLink}/${templateId}`}>
                    <FontAwesomeIcon icon={faEye} className={styles.icon} />
                    View
                </Link>
            ]}
        >
            <Meta
                className={styles.meta}
                title={this.props.twCardsTemplate !== undefined ? (name + ' | ' + type + ' | ' + squadsPerZone) : name}
            />
        </Card>;
    }

    render() {
        return (
            <div className={styles.cards}>
                <div className={`${styles.note} ${this.getCardProps.length > 30 ? '' : styles.hidden }`}>
                    <FontAwesomeIcon icon={faExclamationTriangle} className={`${styles['icon-exclamation']}`} />
                    You can have a maximum of 30 templates per guild!</div>
                {this.props.isAdmin && <Card
                    className={styles.card}
                    cover={
                        <img
                            width={300}
                            height={'100%'}
                            alt="add_image"
                            src={this.props.addImage}
                        />
                    }
                    actions={[
                        <Button
                            key="Add"
                            type={"text"}
                            className={styles.btn}
                            disabled={this.getCardProps.length >= 30 || this.props.isDisabled}
                            onClick={() => this.props.toggleModal()}
                        >
                            <FontAwesomeIcon icon={faLayerPlus} className={styles.icon} />
                            Add
                        </Button>
                    ]}
                >
                    <Meta
                        className={styles.meta}
                        title="Create a new template"
                    />
                </Card>}
                {this.props.cardsTemplate !== undefined && this.props.cardsTemplate.map(template => this.getCardTemplate(template.name, template.id))}
                {this.props.twCardsTemplate !== undefined && this.props.twCardsTemplate.map(template => this.getCardTemplate(template.data.name, template.id, template.data.squadsPerZone, template.categories[0]))}
            </div>
        );
    }
}