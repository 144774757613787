import React from "react";
import { observer } from "mobx-react";
import UserData from './../../../model/UserData';
import { Select, Spin } from 'antd';
import styled from 'styled-components';
import { SelectValue } from 'antd/lib/select';
import { action, observable, runInAction } from "mobx";
import BaseAPI from "../../../service/BaseAPI";
import TerritoryBattleData from './../../../model/TerritoryBattleData';
import GameData from './../../../model/GameData';

const { Option } = Select;

export interface ITBHistoryInstance
{
    instanceId: string;
    phase: number;
}

interface ITBHistorySelectorProps
{
    user: UserData;
    gameData: GameData;
    fullPhaseSelector?: boolean;
    onSelectHistory: (tbh: ITBHistoryInstance | null) => void;
}

const TbPhaseSelect = styled(Select)`
    width: 340px;
`;

@observer
class TBHistorySelector extends React.Component<ITBHistorySelectorProps>
{
    @observable selectedPhase: string = "";
    @observable readingCurrentData: boolean = true;
    @observable readingSpecificHistory: boolean = false;


    static LIVE_DATA_KEY = "LIVE_DATA";

    @action
    async componentDidMount()
    {
        try
        {
            await BaseAPI.fetchTbHistory(this.props.user);
        } catch (e)
        {
            console.log("error: " + e);
        }
        runInAction(() =>
        {
            this.readingCurrentData = false;
        });
        let tbData = this.props.user.currentPlayer!.territoryBattleData;
        if (tbData !== null && tbData.currentRound !== null && this.props.fullPhaseSelector !== true)
        {
            runInAction(() =>
            {
                this.selectedPhase = this.tbDataKey(tbData!);
            });
            this.props.onSelectHistory({
                instanceId: tbData.instanceId,
                phase: tbData.currentRound
            });
        }
    }

    tbDataKey(tbData: TerritoryBattleData): string
    {
        return this.tbHistoryKey(tbData.instanceId, tbData.currentRound!);
    }

    tbHistoryKey(instanceId: string, phase: number): string
    {
        return instanceId + "#" + phase;
    }

    getInstanceFromKey(key: string): string
    {
        let pieces = key.split("#");
        if (pieces.length === 2)
        {
            return pieces[0];
        }
        throw new Error("Invalid key: " + key);
    }
    getPhaseFromKey(key: string): number
    {
        let pieces = key.split("#");
        if (pieces.length === 2)
        {
            return Number(pieces[1]);
        }
        throw new Error("Invalid key: " + key);
    }

    @action
    handlePhaseSelection(value: SelectValue)
    {
        if (value !== undefined)
        {
            this.props.onSelectHistory(null);

            runInAction(() =>
            {
                this.selectedPhase = value.toString();
            });
            this.props.onSelectHistory({
                instanceId: this.getInstanceFromKey(this.selectedPhase),
                phase: this.getPhaseFromKey(this.selectedPhase)
            });
        }
    }

    getInstanceTitle(instanceId: string): string
    {
        if (instanceId.includes("HOTH_REBEL"))
        {
            return "LSTB - Hoth";
        } else if (instanceId.includes("HOTH_EMPIRE"))
        {
            return "DSTB - Hoth";
        } else if (instanceId.includes("GEONOSIS_REPUBLIC"))
        {
            return "LSTB - Geo";
        } else if (instanceId.includes("GEONOSIS_SEPARATIST"))
        {
            return "DSTB - Geo";
        } else
        {
            return "???";
        }
    }

    render()
    {
        if (this.props.user.currentPlayer === null || this.props.user.currentPlayer.territoryBattleHistory === null)
        {
            return <React.Fragment>
                <Spin size="small" />
            </React.Fragment>;
        }

        let historyOptions: any[] = [];

        let currentTbData = this.props.user.currentPlayer.territoryBattleData;

        let loadedInstances: string[] = [];

        if (currentTbData !== null && currentTbData.currentRound !== null)
        {
            let title = this.getInstanceTitle(currentTbData.instanceId) + " (current)";
            if (this.props.fullPhaseSelector !== true)
                title = title + " P: " + currentTbData.currentRound;

            loadedInstances.push(currentTbData.instanceId);

            historyOptions.push(
                <Option
                    key={this.tbDataKey(currentTbData)}
                    value={this.tbDataKey(currentTbData)}>
                    {title}
                </Option>
            );
        }

        if (this.props.user.currentPlayer!.territoryBattleHistory !== null) 
        {
            this.props.user.currentPlayer!.territoryBattleHistory.forEach(tbHistory =>
            {
                tbHistory.phase.forEach(phase =>
                {
                    if (phase.updated !== null && (currentTbData === null || currentTbData.instanceId !== tbHistory.instanceId || currentTbData.currentRound !== phase.phase) &&
                        (this.props.fullPhaseSelector !== true || loadedInstances.indexOf(tbHistory.instanceId) === -1))
                    {
                        loadedInstances.push(tbHistory.instanceId);

                        let key = this.tbHistoryKey(tbHistory.instanceId, phase.phase);
                        let tbTitle = this.getInstanceTitle(tbHistory.instanceId);
                        if (currentTbData === null || currentTbData.instanceId !== tbHistory.instanceId)
                        {
                            tbTitle = tbTitle + " (" + tbHistory.date.toLocaleDateString() + ")";
                        }
                        let title = tbTitle;
                        if (this.props.fullPhaseSelector !== true)
                            title = title + " P: " + phase.phase;

                        if (tbHistory.dataCollected !== true)
                            title = title + " (not event collected) ";



                        historyOptions.push(<Option key={key} value={key}>{title}</Option>);
                    }
                });
            });
        }



        return <React.Fragment>
            <TbPhaseSelect placeholder="select phase" filterOption={false} value={this.selectedPhase}
                onChange={(value: SelectValue) => this.handlePhaseSelection(value)}
                dropdownRender={menu => (
                    <div>
                        {menu}
                    </div>
                )}>
                {historyOptions}
            </TbPhaseSelect>

            {(this.readingCurrentData || this.readingSpecificHistory) && <Spin size="small" />}
        </React.Fragment>;
    }
}

export default TBHistorySelector;
