import React from "react";
import {observer} from "mobx-react";
import IPageProps from "../../../IPageProps";
import {observable, runInAction} from "mobx";
import {Alert, Button, Modal, Select, Switch} from "antd";
import styles from "../../../../styles/components/Modal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faSyncAlt} from "@fortawesome/pro-duotone-svg-icons";
import modal from "../../../../styles/components/Modal.module.scss";
import {IGuildPlayer} from "../../../../model/GuildData";
import BaseAPI from "../../../../service/BaseAPI";
import stylesCC from "../../../ContentCreators/styles/CC.module.scss";
import TextArea from "antd/lib/input/TextArea";
import {getDuplicateUnits, getExiledPlayers, ITerritoryWarsData, IUserDetails, tooManySquadsPerZone} from "../../../../model/TWPlanning";
import {TotalSquadsAssigned} from "../components/TotalSquadsAssigned";
import twMapDef from "../../img/tw-map-def_guetzli.jpg";
import {ButtonStyle, IDiscordDM, MessageButton} from "../../../../model/DiscordMessage";
import { waitForTask } from "../../../../ui/waitForTask";
import { affixKeyToShortName } from "../../../../model/Datacron";

const Canvas = require('canvas');

interface ITWSendingDMModalProps extends IPageProps{
    template: ITerritoryWarsData;
    templateId: string;
    members: IGuildPlayer[];
    unassignedSquads: number;
    guildName: string;
    guildId: string;
    showModal: boolean;
    onClose: () => void;
}

@observer
export default class TWSendingDMModal extends React.PureComponent<ITWSendingDMModalProps> {
    @observable sendingDiscordMessages: boolean = false;
    @observable sendingToPlayer: string = "";
    @observable numberOfPlayers: number = 0;
    @observable description: string = "";
    @observable sendingPlayerCounting: number = 0;
    @observable sendToSpecificPlayers: boolean = false;
    @observable preferredPlayers: number[] = [];

    private getDiscordMessageToPlayer(player: IUserDetails, imageUrl: string): IDiscordDM {
        const squadsTW = this.props.template.squads.filter(x => x.allyCode === player.allyCode);
        const zone = this.props.template.zones;
        const playerData = this.props.members.find(x => x.allyCode === player.allyCode)!;

        let info = "";
        let squadsInfo = "";
        const totalShips = TotalSquadsAssigned(playerData, "ship", this.props.template);
        const totalSquads = TotalSquadsAssigned(playerData, "squad", this.props.template);

        squadsTW.sort((a, b) => a.zoneId - b.zoneId).map(squad => {
            const squadsPlayerPerZone = squadsTW.filter(x => x.zoneId === squad.zoneId);
            const squadIdx = squadsPlayerPerZone.findIndex(x => x.squadId === squad.squadId);
            let dcText = "";
            if (squad.datacronAffixes && squad.datacronAffixes.length > 0)
            {
                dcText = (squad.datacronRequired === true ? "\n**Required** Datacron bonuses: " : "\nRecommended Datacron bonuses: ") +
                    squad.datacronAffixes.map(a => affixKeyToShortName(a)).join(", ");
            }
            return squadsInfo += `\n\n* **${zone.find(x => x.id === squad.zoneId)?.name}**\n${squadsTW
                .filter(x => x.zoneId === squad.zoneId)[squadIdx]
                .units.map((unit, index) => {
                    if(unit.preferredUnit){
                        const unitName = this.props.gameData.getUnit(unit.preferredUnit)?.name;
                        if(index === 0){
                            return '  * **' + unitName + `**`;
                        }
                        return '  * ' + unitName;
                    } else {
                        if(index === 0){
                            return '  * **' + unit.characterName + `**`;
                        }
                        return '  * ' + unit.characterName;
                    }
                        
                }
            ).join('\n')}` + dcText;
        })

        info = `\n\n**${this.props.template.name}**\n\nHello ${player.playerName}:\n\n`

        if(this.description.length > 1){
            info += `${this.description}\n\n`
        }
        if (totalShips > 0 || totalSquads > 0) {
            info += `You need to deploy ${totalSquads > 0 ? `**${totalSquads}** squad${totalSquads > 1 ? 's' : ''}` : ''}${totalSquads > 0 && totalShips > 0 ? ' & ' : ''}${totalShips > 0 ? `**${totalShips}** fleet${totalShips > 1 ? 's' : ''}` : ''} accordingly:${squadsInfo}\n\n`;
        }

        info +=  `For more details you can check your TW assignments on the website by clicking this url: <https://${window.location.host}/tw/planning/${this.props.templateId}> !`
        
        let buttons: MessageButton[] | undefined = undefined;
        if (player.subscriptionLevelName && player.subscriptionLevelName !== "None")
        {
            info += "\nTo place these teams, click the button below which will interrupt your game connection";
            buttons = [{
                label: "🛑 Start Placement",
                buttonStyle: ButtonStyle.Danger,
                customId: "TW:beginPlacement:" + player.allyCode
            }];
        }
        else
        {
            info += "\nIf you are a Cherry level or above subscriber, you would be able to place these teams automatically with the `/tw place` command";
        }
        info += "\n\n" + imageUrl;
        return {
            message: info, 
            allyCode: [player.allyCode],
            buttons
        };
    }

    private async getTWMap() {
        const canvas = Canvas.createCanvas(700, 450);
        const context = canvas.getContext('2d');
        const background = await Canvas.loadImage(twMapDef);

        context.drawImage(background, 0, 0, canvas.width, canvas.height);

        this.props.template.zones.forEach(zone => {

            this.mapTextToImage(context, zone);
        });

        return canvas.toDataURL('image/png');
    }

    private mapTextToImage(context: any, zone: any) {
        context.strokeRect(0, 0, 700, 450);
        context.font = 'bold 24px sans-serif';
        context.fillStyle = '#ffffff';

        // since we need to manually place the text onto the canvas easier to throw this all into a switch
        // and key off the id of each zone. This is similar to the CSS used to generate the map on the previous page.
        // This could maybe be handled via a service along with the TWMap if offense needs to be done in the same way.
        switch (zone.index) {
            case 1: {
                context.fillText(zone.name, 510, 120);
                break;
            }
            case 2: {
                context.fillText(zone.name, 540, 250);
                break;
            }
            case 3: {
                context.fillText(zone.name, 350, 100);
                break;
            }
            case 4: {
                context.fillText(zone.name, 400, 290);
                break;
            }
            case 5: {
                context.fillText(zone.name, 240, 80);
                break;
            }
            case 6: {
                context.fillText(zone.name, 235, 180);
                break;
            }
            case 7: {
                context.fillText(zone.name, 250, 320);
                break;
            }
            case 8: {
                context.fillText(zone.name, 125, 80);
                break;
            }
            case 9: {
                context.fillText(zone.name, 100, 190);
                break;
            }
            case 10: {
                context.fillText(zone.name, 100, 300);
                break;
            }
            default: {
                context.fillText(zone.name ?? zone.id, 0, 0);
            }
        }
    }

    private async clickSendDiscordMessage() {
        const squadsTW = this.props.template.squads;
        const errors = [] as string[];
        const playerSquads = Array.from(new Set<IUserDetails>());

        for (let i = 0; i < squadsTW.length; i++) {
            const squad = squadsTW[i];

            const player = this.props.members.find(x => x.allyCode === squad.allyCode)

            if (!player) {
                return;
            }

            if(squad.allyCode && player.discordTag){
                playerSquads.push({
                    playerName: player.name,
                    allyCode: player.allyCode,
                    discordTag: player.discordTag,
                    discordId: player.discordId,
                    guildRank: player.guildRank,
                    subscriptionLevelName: player.subscriptionLevelName,
                    createdUTC: undefined
                })
            }
        }

        const playersDiscordTag = playerSquads.filter(p => p.discordTag === undefined);

        if(playersDiscordTag.length > 0) {
            return Modal.error({
                title: <span>These players: <strong>{playersDiscordTag.map(player => player.playerName).join(', ')}</strong> are not registered with HotBot!</span>,
                content: <span>{'\n\n'}You need to register everyone in the guild before sending discord DM.{'\n\n'}</span>,
                maskClosable: true
            });
        }
        const messagedPlayersDiscordId = [] as number[];
        let toMessage = [] as IUserDetails[];

        for (let player of playerSquads) {
            if (!player.discordTag || messagedPlayersDiscordId.includes(player.allyCode)) {
                continue;
            }

            messagedPlayersDiscordId.push(player.allyCode);
            toMessage.push(player);
        }

        runInAction(() => {
            this.numberOfPlayers = toMessage.length;
            this.sendingDiscordMessages = true;
        });

        let mapUrl = "";
        try
        {
            const fileContent = await this.getTWMap();
            const fileName = `${this.props.guildId}_${new Date().getTime()}_tw.png`;
            mapUrl = await BaseAPI.uploadFile(this.props.user, fileName, fileContent);
        }
        catch (err: any)
        {
            errors.push(err.errorMessage);
        }

        if(this.sendToSpecificPlayers && this.preferredPlayers.length > 0){
            toMessage = toMessage.filter(x => this.preferredPlayers.includes(x.allyCode));

            for (let i = 0; i < toMessage.length; i++) {
                const player = toMessage[i];

                runInAction(() => {
                    this.sendingToPlayer = player.playerName;
                    this.sendingPlayerCounting = i + 1;
                });

                const message = this.getDiscordMessageToPlayer(player, mapUrl);

                try {
                    const response = await BaseAPI.sendDiscordMessage(this.props.user, [player.allyCode], message.message, undefined, message.buttons);

                    if (response.failed !== undefined && response.length > 0) {
                        let errorMessage = response.failed.map((fm: any) => fm.error).join("\n");

                        errors.push(errorMessage);
                    }
                } catch (err: any) {
                    errors.push(err.errorMessage);
                }
            }
        } else {
            //const fileContent = await this.getTWMap();
            //const fileName = `${this.props.guildId}_${new Date().getTime()}_tw.jpeg`;
            const messagePlayers: IDiscordDM[] = [];

            for (let i = 0; i < toMessage.length; i++) {
                const player = toMessage[i];
                messagePlayers.push(this.getDiscordMessageToPlayer(player, mapUrl));
            }

            runInAction(() => {
                this.sendingToPlayer = 'All';
                this.sendingPlayerCounting = messagePlayers.length;
            });

            // console.log('DM: ', messagePlayers);

            try {
                const dmTask = await BaseAPI.sendMassDiscordMessageTask(this.props.user, messagePlayers, () => {}, (response: any) =>
                {
                    if (response.progress.result)
                    {
                        errors.push(response.progress.result);
                    }
                });
                await waitForTask(dmTask, "Queuing messages to send");

            } catch (err: any) {
                errors.push(err.errorMessage);
            }
        }

        if (errors.length > 0) {
            Modal.error({
                title: "Error sending messages",
                content:  <span>{errors.map(s => <React.Fragment>{s}<br/></React.Fragment>)}</span>,
                maskClosable: false
            });
            runInAction(() => this.sendingDiscordMessages = false);
            this.props.onClose();
        } else {
            Modal.info({
                title: "Message Confirmation",
                content: <span>Messages sent from HotBot</span>,
                maskClosable: false
            });

            runInAction(() => this.sendingDiscordMessages = false);
            this.props.onClose();
        }
    }

    render() {
        const allycodeTWPlayers = this.props.template.squads.map(x => x.allyCode);
        let alert = "";
        const dupes = getDuplicateUnits(this.props.template);
        const exiledPlayers = getExiledPlayers(this.props.template, this.props.members);
        const tooManySquads = tooManySquadsPerZone(this.props.template);
        const unassignedSquads = (this.props.template.squadsPerZone * 10) - this.props.template.squads.length;

        alert += unassignedSquads > 0 ? `You still have ${unassignedSquads} unassigned squads/fleets.` : '';
        alert += exiledPlayers.length > 0 ? 'You have squads from members that are no longer in your guild' : '';
        alert += dupes.length > 0  ? 'There are players  with overlapping squads/units' : '';
        alert += tooManySquads ? `Some zones have more squads than ${this.props.template.squadsPerZone}` : '';

        return (
            <Modal
                title={`Send Discord DMs to all players of ${this.props.guildName}`}
                visible={this.props.showModal}
                onCancel={this.props.onClose}
                key={this.props.template.name}
                footer={
                    <Button
                        key={'send'}
                        type={'primary'}
                        size={'middle'}
                        danger={this.props.unassignedSquads > 0}
                        className={styles['btn-send']}
                        disabled={this.sendingDiscordMessages}
                        onClick={() => this.clickSendDiscordMessage()}
                    >
                        <FontAwesomeIcon
                            icon={this.sendingDiscordMessages ? faSyncAlt : faPaperPlane}
                            spin={this.sendingDiscordMessages && true}
                            className={styles.icon}
                        />
                        {this.sendingDiscordMessages ?
                            <span>Sending Discord DMs to: {this.sendingToPlayer} ({this.sendToSpecificPlayers ? (this.sendingPlayerCounting + ' / ') : ''}{this.sendToSpecificPlayers ? this.preferredPlayers.length : this.numberOfPlayers})</span> :
                            'Send Discord DMs'
                        }
                    </Button>
                }
            >
                {alert && <Alert
					message={alert}
					type="warning"
					showIcon={true}
					className={styles.alert}
					closable={true}
				/>}
                <div className={modal.modal}>
                    <div className={modal.row}>
                        <div className={modal.label}>Add TW instructions:</div>
                        <TextArea
                            className={`${stylesCC.input}`}
                            placeholder={'Add extra information in the DM before showing the assignments per zone'}
                            value={this.description}
                            onChange={event => {
                                runInAction(() => {
                                    this.description = event.target.value;
                                })
                            }}
                            rows={3}
                        />
                    </div>
                    <div className={modal.row}>
                        <div className={styles.switch}>
                            <Switch
                                checked={this.sendToSpecificPlayers}
                                onChange={() => runInAction(() => this.sendToSpecificPlayers = !this.sendToSpecificPlayers)}
                                className={styles.item}
                            /> Send the assignments only to specific players?
                        </div>
                    </div>
                    {this.sendToSpecificPlayers && <div className={modal.row}>
                        <div className={modal.label}>Add the players you want to send the instructions:</div>
                        <Select
                            className={`${styles.select}`}
                            placeholder={'Select at least one player'}
                            autoFocus={true}
                            showSearch={true}
                            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            mode={"multiple"}
                            value={Array.isArray(this.preferredPlayers) ? this.preferredPlayers : [this.preferredPlayers]}
                            onChange={value => {
                                runInAction(() => {
                                    this.preferredPlayers = value;
                                })
                            }}
                            showArrow={true}
                        >
                            {this.props.members
                                .filter(x => allycodeTWPlayers.includes(x.allyCode))
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(member => {
                                return (<Select.Option key={member.allyCode} value={member.allyCode}>{member.name}</Select.Option>);
                            })}
                        </Select>
                    </div>}
                </div>
            </Modal>
        );
    }
}
