import ModData from "../model/ModData";
import UserData from "../model/UserData";
import { APITask } from "./APITask";
import BaseAPI from "./BaseAPI";
import { runInAction } from 'mobx';
import { LoadoutDefinition, LoadoutDefinitionTarget, LoadoutDefintionGroup, LoadoutDefintionSummary } from "../model/LoadoutDefinition";
import { IShare } from "../model/IShare";
import ModFilterGroup from "../model/ModFilterGroup";
import StatIds from "../model/StatIds";


export enum ModLevelUp
{
    Expose = 0,
    OneTier = 1,
    To12 = 2,
    ToMax = 3,
    SingleLevel = 4
}

class ModAPI
{
    public static async sell(user: UserData, mods: ModData[]): Promise<any>
    {
        return await BaseAPI.postToApi("mods/sell", {
            sessionId: user.sessionId,
            modIds: mods.map((m) => m.id),
            getAllData: true,
            simulation: false
        });
    }

    public static async levelUp(user: UserData, mods: ModData[], level: ModLevelUp): Promise<any>
    {
        return await BaseAPI.postToApi("mods/level", {
            sessionId: user.sessionId,
            modIds: mods.map((m) => m.id),
            getAllData: true,
            requestType: level,
            simulation: false
        });
    }

    public static async levelTask(user: UserData, mods: ModData[], level: ModLevelUp, updateCallback: (response: any) => void, doneCallback: (response: any) => void): Promise<APITask>
    {
        let response = await BaseAPI.postToApi("mods/task/level", {
            sessionId: user.sessionId,
            modIds: mods.map((m) => m.id),
            requestType: level,
            simulation: false
        });
        return new APITask(response.taskId, user, true, 1000, updateCallback, doneCallback);
    }

    public static async lock(user: UserData, mods: ModData[]): Promise<any>
    {
        return await BaseAPI.postToApi("mods/lock", {
            sessionId: user.sessionId,
            modIds: mods.map((m) => m.id),
            getAllData: false,
            simulation: false
        });
    }

    public static async lockTask(user: UserData, mods: ModData[], updateCallback: (response: any) => void, doneCallback: (response: any) => void): Promise<APITask>
    {
        let response = await BaseAPI.postToApi("mods/task/lock", {
            sessionId: user.sessionId,
            modIds: mods.map((m) => m.id),
            simulation: false
        });
        return new APITask(response.taskId, user, false, 1000, updateCallback, doneCallback);
    }

    public static async unlock(user: UserData, mods: ModData[]): Promise<any>
    {
        return await BaseAPI.postToApi("mods/unlock", {
            sessionId: user.sessionId,
            modIds: mods.map((m) => m.id),
            getAllData: false,
            simulation: false
        });
    }

    public static async unlockTask(user: UserData, mods: ModData[], updateCallback: (response: any) => void, doneCallback: (response: any) => void): Promise<APITask>
    {
        let response = await BaseAPI.postToApi("mods/task/unlock", {
            sessionId: user.sessionId,
            modIds: mods.map((m) => m.id),
            simulation: false
        });
        return new APITask(response.taskId, user, false, 1000, updateCallback, doneCallback);
    }

    public static async slice(user: UserData, mods: ModData[]): Promise<any>
    {
        return await BaseAPI.postToApi("mods/tier", {
            sessionId: user.sessionId,
            modIds: mods.map((m) => m.id),
            getAllData: true,
            simulation: false
        });
    }

    public static async applyModsTask(user: UserData, units: any, updateCallback: (response: any) => void, doneCallback: (response: any) => void): Promise<APITask>
    {
        let response = await BaseAPI.postToApi("mods/task/equip", {
            sessionId: user.sessionId,
            units: units,
            getAllData: true,
            simulation: false
        });

        if (response.taskId === 0)
        {
            if (response.responseMessage === "TASK SKIPPED")
            {
                await BaseAPI.fetchAllCurrentPlayerData(user, undefined, true);
                throw new Error("The requested mod configuration matches what is already in game, the equip has been skipped.");
            }
            throw new Error("Unable to create task (consider refreshing): " + response.responseMessage);
        }
        return new APITask(response.taskId, user, true, 1000, updateCallback, doneCallback);
    }

    public static async applyMods(user: UserData, units: any): Promise<any>
    {
        return await BaseAPI.postToApi("mods/equip", {
            sessionId: user.sessionId,
            units: units,
            getAllData: true,
            simulation: false
        });
    }

    public static async applyLoadout(user: UserData, name: string): Promise<any>
    {
        return await BaseAPI.postToApi("mods/equip", { sessionId: user.sessionId, setName: [name] });
    }

    public static async applyBaselineTask(user: UserData, baseLineIndex: number, updateCallback: (response: any) => void, doneCallback: (response: any) => void): Promise<APITask>
    {
        let response = await BaseAPI.postToApi("mods/task/equip", {
            sessionId: user.sessionId,
            baseLineIndex,
            getAllData: true,
            simulation: false
        });

        if (response.taskId === 0)
        {
            if (response.responseMessage === "TASK SKIPPED")
            {
                await BaseAPI.fetchAllCurrentPlayerData(user, undefined, true);
                throw new Error("The requested mod configuration matches what is already in game, the equip has been skipped.");
            }
            throw new Error("Unable to create task (consider refreshing): " + response.responseMessage);
        }
        return new APITask(response.taskId, user, true, 1000, updateCallback, doneCallback);
    }

    public static async applyLoadoutTask(user: UserData, name: string, updateCallback: (response: any) => void, doneCallback: (response: any) => void): Promise<APITask>
    {
        let response = await BaseAPI.postToApi("mods/task/equip", {
            sessionId: user.sessionId,
            setName: [name],
            getAllData: true,
            simulation: false
        });

        if (response.taskId === 0)
        {
            if (response.responseMessage === "TASK SKIPPED")
            {
                await BaseAPI.fetchAllCurrentPlayerData(user, undefined, true);
                throw new Error("The requested mod configuration matches what is already in game, the equip has been skipped.");
            }
            throw new Error("Unable to create task (consider refreshing): " + response.responseMessage);
        }
        return new APITask(response.taskId, user, true, 1000, updateCallback, doneCallback);
    }

    public static async unequip(user: UserData, mods: ModData[]): Promise<any>
    {
        return await BaseAPI.postToApi("mods/unequip", {
            sessionId: user.sessionId,
            modIds: mods.map((m) => m.id),
            getAllData: true,
            simulation: false
        });
    }

    public static async unequipTask(user: UserData, mods: ModData[], updateCallback: (response: any) => void, doneCallback: (response: any) => void): Promise<APITask>
    {
        let response = await BaseAPI.postToApi("mods/task/unequip", {
            sessionId: user.sessionId,
            modIds: mods.map((m) => m.id),
            simulation: false
        });
        return new APITask(response.taskId, user, true, 1000, updateCallback, doneCallback);
    }

    public static async exportTargetLoadoutDefinition(user: UserData, createNew: boolean, definitionTitle: string, target: LoadoutDefinitionTarget): Promise<LoadoutDefinition>
    {
        let definition = new LoadoutDefinition({});
        definition.title = definitionTitle;
        let share: IShare = {
            allianceId: [],
            allyCode: [],
            global: false,
            guildId: []
        };

        if (createNew === false)
        {
            definition = await ModAPI.loadLoadoutDefinition(user, definitionTitle, undefined, true);
            share = await ModAPI.loadLoadoutDefinitionSharing(user, definitionTitle);

        } else
        {
            if (user.currentPlayer!.loadedDefinitions.find(d =>
                d.discordTag === user.currentPlayer!.discordTag && d.title.toUpperCase().trim() === definitionTitle.trim().toUpperCase()) !== undefined)
            {
                throw Error("Cannot create new definition if already exists");
            }
        }

        target = new LoadoutDefinitionTarget(target);
        target.definitionModified = false;
        target.definitionTitle = definitionTitle;
        target.definitionDiscordId = user.currentPlayer!.discordTag;
        // cant set version, this will be set when loading definition

        definition.targets = definition.targets.filter(t => t.unitBaseId !== target.unitBaseId).concat(target);

        let squadDefinition = definition.isSquadLoadoutDefinitionSize();

        let definitionClone = JSON.parse(JSON.stringify(definition));
        definitionClone.squadUnits = squadDefinition === false ? null : definition.targets.map(t => LoadoutDefintionSummary.unitIdToSquadId(t.unitBaseId, t.notRequired));

        let response = await BaseAPI.postToApi("stats/definition/upsert", {
            sessionId: user.sessionId,
            definition: definitionClone,
            sharing: share
        });

        await ModAPI.fetchLoadoutDefintions(user, true);

        let retVal = new LoadoutDefinition(response.definition);
        runInAction(() =>
        {
            user.currentPlayer!.loadedDefinitions = user.currentPlayer!.loadedDefinitions.filter(ld =>
                ld.title !== definitionClone.title || ld.discordId !== definitionClone.discordId).concat(retVal);
        });

        return retVal;
    }

    public static async saveLoadoutDefinition(user: UserData, definition: LoadoutDefinition, sharing: IShare): Promise<LoadoutDefinition>
    {
        let squadDefinition = definition.isSquadLoadoutDefinitionSize();

        let definitionClone = JSON.parse(JSON.stringify(definition));
        definitionClone.squadUnits = squadDefinition === false ? null : definition.targets.map(t => LoadoutDefintionSummary.unitIdToSquadId(t.unitBaseId, t.notRequired));

        let response = await BaseAPI.postToApi("stats/definition/upsert", {
            sessionId: user.sessionId,
            definition: definitionClone,
            sharing
        });

        await ModAPI.fetchLoadoutDefintions(user, true);

        let retVal = new LoadoutDefinition(response.definition);
        runInAction(() =>
        {
            user.currentPlayer!.loadedDefinitions = user.currentPlayer!.loadedDefinitions.filter(ld =>
                ld.title !== definitionClone.title || ld.discordId !== definitionClone.discordId).concat(retVal);
        });

        return retVal;
    }

    public static async loadLoadoutDefinition(user: UserData, title: string, discordId: string | undefined, forceRefresh: boolean = true): Promise<LoadoutDefinition>
    {
        let retVal = user.currentPlayer!.loadedDefinitions.find(ld => ld.title === title && ld.discordId === discordId);

        if (forceRefresh || retVal === undefined)
        {
            let loadoutJson = await BaseAPI.postToApi("stats/definition/get", {
                sessionId: user.sessionId,
                title,
                discordId
            });
            retVal = new LoadoutDefinition(loadoutJson.definition);
            retVal.targets.forEach(t =>
            {
                t.definitionTitle = retVal!.title;
                t.definitionVersion = retVal!.version;
                t.definitionModified = false;
                t.definitionDiscordId = retVal!.discordTag;
            })
            runInAction(() =>
            {
                user.currentPlayer!.loadedDefinitions = user.currentPlayer!.loadedDefinitions.filter(ld => ld.title !== title || ld.discordId !== discordId).concat(retVal!);
            });
        }

        return retVal;
    }

    public static async loadLoadoutDefinitionSharing(user: UserData, title: string): Promise<IShare>
    {
        let loadoutJson = await BaseAPI.postToApi("stats/definition/get", {
            sessionId: user.sessionId,
            title
        });
        return loadoutJson.sharing;
    }

    public static async fetchLoadoutDefintionByKey(user: UserData, key: string): Promise<LoadoutDefinition | undefined>
    {
        const lds: LoadoutDefintionSummary | undefined =
            LoadoutDefintionGroup.getLoadoutDefinitionSumary(user.currentPlayer!.loadoutDefinitionGroups, key);

        if (lds)
        {
            return await ModAPI.loadLoadoutDefinition(user, lds.title, lds.discordId);
        }
    }



    public static async fetchLoadoutDefintions(user: UserData, forceUpdate: boolean = false): Promise<any>
    {
        if (user.currentPlayer!.unitPriorization === null || forceUpdate)
        {
            let response = await BaseAPI.postToApi("stats/definition/list", {
                sessionId: user.sessionId
            });
            runInAction(() =>
            {
                user.currentPlayer!.loadoutDefintionGroupsFromJson(response);
            });
        }
    }

    public static async deleteLoadoutDefintion(user: UserData, title: string): Promise<any>
    {

        await BaseAPI.postToApi("stats/definition/delete", {
            sessionId: user.sessionId,
            title
        });
        let response = await BaseAPI.postToApi("stats/definition/list", {
            sessionId: user.sessionId
        });
        runInAction(() =>
        {
            user.currentPlayer!.loadoutDefintionGroupsFromJson(response);
            user.currentPlayer!.loadedDefinitions = user.currentPlayer!.loadedDefinitions.filter(ld =>
                ld.title !== title || ld.discordTag !== user.currentPlayer!.discordTag);
        });
    }

    public static async saveFilterGroup(user: UserData, group: ModFilterGroup): Promise<void>
    {
        if (group.isPublic && !group.copiedFromPublicId)
        {
            // if it is our group, save it in both places
            await BaseAPI.uploadData(user, false, group.toJSON(), group.id, "modFilters");
        }
        await BaseAPI.uploadData(user, group.isPublic, group.toJSON(), group.id, "modFilters");
    }


    public static async saveFilterList(user: UserData): Promise<void>
    {
        await BaseAPI.uploadData(user, false, {
            publicFilters: [],
            privateFilters: user.modFilterGroups ? user.modFilterGroups.map((g) => g.id) : []
        }, "0", "modFilterList");

    }

    public static async fetchFilterGroup(user: UserData, key: string, isPublic: boolean): Promise<ModFilterGroup | null>
    {
        let response = await BaseAPI.downloadData(user, isPublic, key, "modFilters");
        if (response.documents.length === 0)
        {
            return null;
        }

        let ret = new ModFilterGroup(response.documents[0]);
        return ret;
    }

    public static async fetchModFilters(user: UserData): Promise<void>
    {
        let response = await BaseAPI.downloadData(user, false, "0", "modFilterList");
        if (response.responseMessage === "ERROR" && response.errorMessage === "Invalid data store specified")
        {
            // this is OK, we just don't have any mod filters
            runInAction(() => user.modFilterGroups = []);
            return;
        }
        else if (response.responseMessage === "ERROR")
        {
            console.log(response);
        }
        else
        {
            let allGroups: ModFilterGroup[] = [];
            let needToSave: boolean = false;
            if (response.documents.length > 0)
            {
                let filterList = JSON.parse(response.documents[0].data);

                for (let priv of filterList.privateFilters)
                {
                    let group = await ModAPI.fetchFilterGroup(user, priv, false);
                    if (group)
                        allGroups.push(group);
                }
                // there shouldn't be anything here once people update, but lets handle people who had public filters added
                for (let pub of filterList.publicFilters)
                {
                    needToSave = true;
                    let group = await ModAPI.fetchFilterGroup(user, pub, true);
                    if (group)
                    {
                        // convert to the new style, unless they are the author...
                        if (group.author === user.currentPlayer!.name)
                        {
                            // we need to save it in private as well as public...
                            await ModAPI.saveFilterGroup(user, group);
                        }
                        else
                        {
                            group = group.clonePublicGroup();
                        }
                        allGroups.push(group);
                    }
                }
            }
            runInAction(() =>
            {
                user.modFilterGroups = allGroups;
            });
            if (needToSave)
            {
                await ModAPI.saveFilterList(user);
            }
        }
    }

    public static async fetchLoadoutDefinitions(user: UserData, updateCurrentCallBack: (loadoutName: string) => void)
    {

        if (user.currentPlayer!.loadoutDefinitionGroups === null)
        {
            await ModAPI.fetchLoadoutDefintions(user);
        }

        let summaries = LoadoutDefintionGroup.getLoadoutDefinitions(user.currentPlayer!.loadoutDefinitionGroups);
        for (var x = 0; x < summaries.length; x++)
        {
            const lds = summaries[x];
            let key = lds.getKey();
            try
            {
                updateCurrentCallBack(lds.title);
                await ModAPI.loadLoadoutDefinition(user, lds.title, lds.discordId, false);
            }
            catch (response)
            {
                console.error("Unable to load definition: " + key);
            }
        }
    }

    public static async fetchLoadoutDefinitionForSquadList(user: UserData, updateCurrentCallBack: (loadoutName: string) => void)
    {

        if (user.currentPlayer!.loadoutDefinitionGroups === null)
        {
            await ModAPI.fetchLoadoutDefintions(user);
        }

        let summaries = LoadoutDefintionGroup.getLoadoutDefinitions(user.currentPlayer!.loadoutDefinitionGroups);
        for (var x = 0; x < summaries.length; x++)
        {
            const lds = summaries[x];
            let key = lds.getKey();

            if (lds.squadUnits === null)
            {
                try
                {
                    updateCurrentCallBack(lds.title);
                    await ModAPI.loadLoadoutDefinition(user, lds.title, lds.discordId, false);
                }
                catch (response)
                {
                    console.error("Unable to load definition: " + key);
                }
            }
        }
    }

    public static async calibrateMod(user: UserData, mod: ModData, secondary: StatIds): Promise<any>
    {
        return await BaseAPI.postToApi("mods/reroll", {
            sessionId: user.sessionId,
            modId: mod.id,
            stat: secondary,
            simulation: false
        });
    }

    public static async resolveCalibration(user: UserData, accept: boolean): Promise<any>
    {
        let response = await BaseAPI.postToApi("mods/acceptreroll", {
            sessionId: user.sessionId,
            keepMod: accept
        });

        if (user.currentPlayer)
        {
            user.currentPlayer.fromAllJSON(response);
        }

        return response;
    }

    public static async revealSecondaries(user: UserData, mods: ModData[]): Promise<any>
    {
        return await BaseAPI.postToApi("mods/reveal", {
            sessionId: user.sessionId,
            modIds: mods.map((m) => m.id),
            getAllData: true
        });
    }

    public static async batchLevel(user: UserData, mods: ModData[], level: number): Promise<any>
    {
        return await BaseAPI.postToApi("mods/batch", {
            sessionId: user.sessionId,
            modIds: mods.map((m) => m.id),
            level,
            getAllData: true
        });
    }
}

export default ModAPI;
