enum StatIds
{
    None = 0,
    Health = 1,
    Strength = 2,
    Agility = 3,
    Intelligence = 4,
    Speed = 5,
    PhysicalDamage = 6,
    SpecialDamage = 7,
    UnitDefense = 8,
    UnitResistance = 9,
    ArmorPenetration = 10,
    ResistancePenetration = 11,
    DodgeRating = 12,
    DeflectionRating = 13,
    UnitPhysicalCriticalChance = 14,
    UnitSpecialCriticalChance = 15,
    CriticalDamage = 16,
    Potency = 17,
    Tenacity = 18,
    Dodgepercentadditive = 19,
    Deflectionpercentadditive = 20,
    Attackcriticalpercentadditive = 21,
    Abilitycriticalpercentadditive = 22,
    Armorpercentadditive = 23,
    Suppressionpercentadditive = 24,
    ArmorPenetrationPct = 25,
    ResistancePenetrationPct = 26,
    HealthSteal = 27,
    Protection = 28,
    Shieldpenetration = 29,
    Healthregen = 30,
    PhysicalDamagePct = 31,
    SpecialDamagePct = 32,
    Dodgenegatepercentadditive = 33,
    Deflectionnegatepercentadditive = 34,
    Attackcriticalnegatepercentadditive = 35,
    Abilitycriticalnegatepercentadditive = 36,
    UnitPhysicalAccuracy = 37,
    UnitSpecialAccuracy = 38,
    UnitPhysicalCriticalAvoidance = 39,
    UnitSpecialCriticalAvoidance = 40,
    Offense = 41,
    Defense = 42,
    DefensePenetration = 43,
    Evasionrating = 44,
    Criticalrating = 45,
    Evasionnegaterating = 46,
    Criticalnegaterating = 47,
    OffensePct = 48,
    DefensePct = 49,
    Defensepenetrationpercentadditive = 50,
    Evasionpercentadditive = 51,
    Accuracy = 52,
    CriticalChance = 53,
    CriticalAvoidance = 54,
    HealthPct = 55,
    ProtectionPct = 56,
    Speedpercentadditive = 57,
    Counterattackrating = 58,
    Taunt = 59,
    Defensepenetrationtargetpercentadditive = 60,
    Mastery = 61,
}


export function statIdIsFlat(stat: StatIds): boolean
{
    switch (stat)
    {
        case StatIds.Health:
        case StatIds.Speed:
        case StatIds.PhysicalDamage:
        case StatIds.SpecialDamage:
        case StatIds.UnitDefense:
        case StatIds.UnitResistance:
        case StatIds.Protection:
        case StatIds.Offense:
        case StatIds.Defense:
        case StatIds.Strength:
        case StatIds.Agility:
        case StatIds.Intelligence:
        case StatIds.Shieldpenetration:
        case StatIds.Healthregen:
        case StatIds.Evasionrating:
        case StatIds.Criticalrating:
        case StatIds.Evasionnegaterating:
        case StatIds.Criticalnegaterating:
        case StatIds.Counterattackrating:
        case StatIds.Taunt:
        case StatIds.Mastery:
            return true;

        case StatIds.ArmorPenetration:
        case StatIds.ResistancePenetration:
        case StatIds.DodgeRating:
        case StatIds.DeflectionRating:
        case StatIds.UnitPhysicalCriticalChance:
        case StatIds.UnitSpecialCriticalChance:
        case StatIds.CriticalDamage:
        case StatIds.Potency:
        case StatIds.Tenacity:
        case StatIds.ArmorPenetrationPct:
        case StatIds.ResistancePenetrationPct:
        case StatIds.HealthSteal:
        case StatIds.PhysicalDamagePct:
        case StatIds.SpecialDamagePct:
        case StatIds.UnitPhysicalAccuracy:
        case StatIds.UnitSpecialAccuracy:
        case StatIds.UnitPhysicalCriticalAvoidance:
        case StatIds.UnitSpecialCriticalAvoidance:
        case StatIds.DefensePenetration:
        case StatIds.OffensePct:
        case StatIds.DefensePct:
        case StatIds.Accuracy:
        case StatIds.CriticalChance:
        case StatIds.CriticalAvoidance:
        case StatIds.HealthPct:
        case StatIds.ProtectionPct:
        case StatIds.Armorpercentadditive:
        case StatIds.Suppressionpercentadditive:
        case StatIds.Attackcriticalpercentadditive:
        case StatIds.Abilitycriticalpercentadditive:
        case StatIds.Dodgepercentadditive:
        case StatIds.Deflectionpercentadditive:
        case StatIds.Dodgenegatepercentadditive:
        case StatIds.Deflectionnegatepercentadditive:
        case StatIds.Attackcriticalnegatepercentadditive:
        case StatIds.Abilitycriticalnegatepercentadditive:
        case StatIds.Defensepenetrationpercentadditive:
        case StatIds.Evasionpercentadditive:
        case StatIds.Speedpercentadditive:
        case StatIds.Defensepenetrationtargetpercentadditive:
            return false;
        default:
            return false;
    }
}

export function statIdToString(stat: StatIds, showPcts?: boolean): string 
{
    switch (stat)
    {
        case StatIds.None:
            return "";
        case StatIds.Health:
            return "Health";
        case StatIds.UnitDefense:
            return "Defense";
        case StatIds.UnitResistance:
            return "Resistance";
        case StatIds.UnitPhysicalCriticalChance:
            return "Physical Crit Chance";
        case StatIds.UnitSpecialCriticalChance:
            return "Special Crit Chance";
        case StatIds.UnitPhysicalCriticalAvoidance:
            return "Crit Avoidance";
        case StatIds.UnitSpecialCriticalAvoidance:
            return "Special Crit Avoidance";
        case StatIds.UnitPhysicalAccuracy:
            return "Physical Accuracy";
        case StatIds.UnitSpecialAccuracy:
            return "Special Accuracy";
        case StatIds.HealthPct:
            return showPcts ? "Health %" : "Health";
        case StatIds.Speed:
            return "Speed";
        case StatIds.PhysicalDamage:
            return "Physical Damage";
        case StatIds.SpecialDamage:
            return "Special Damage";
        case StatIds.CriticalDamage:
            return "Crit Dmg";
        case StatIds.Potency:
            return "Potency";
        case StatIds.Tenacity:
            return "Tenacity";
        case StatIds.Protection:
            return "Protection";
        case StatIds.ProtectionPct:
            return showPcts ? "Protection %" : "Protection";
        case StatIds.Offense:
            return "Offense";
        case StatIds.OffensePct:
            return showPcts ? "Offense %" : "Offense";
        case StatIds.Defense:
            return "Defense";
        case StatIds.DefensePct:
            return showPcts ? "Defense %" : "Defense";
        case StatIds.Accuracy:
            return "Accuracy";
        case StatIds.CriticalChance:
            return "Crit Chance";
        case StatIds.CriticalAvoidance:
            return "Crit Avoid";
        case StatIds.ArmorPenetration:
            return "Armor Penetration";
        case StatIds.DodgeRating:
            return "Dodge";
        case StatIds.HealthSteal:
            return "Health Steal";
        case StatIds.DefensePenetration:
            return "Defense Penetration";
        case StatIds.ResistancePenetration:
            return "Resistance Penetration";
        case StatIds.DeflectionRating:
            return "Deflection";
        case StatIds.ArmorPenetrationPct:
            return "Armor Penetration";
        case StatIds.ResistancePenetrationPct:
            return "Resistance Penetration";
        case StatIds.PhysicalDamagePct:
            return "Physical Damage";
        case StatIds.SpecialDamagePct:
            return "Special Damage";
        
        case StatIds.Armorpercentadditive: return "Defense";
        case StatIds.Suppressionpercentadditive: return "Resistance";
        case StatIds.Attackcriticalpercentadditive: return "Crit Chance";
        case StatIds.Abilitycriticalpercentadditive: return "Special Crit Chance";
        case StatIds.Strength: return "Strength";
        case StatIds.Agility: return "Agility";
        case StatIds.Intelligence: return "Intelligence";
        case StatIds.Dodgepercentadditive: return "Dodge";
        case StatIds.Deflectionpercentadditive: return "Deflection";
        case StatIds.Shieldpenetration: return "Resistance Penetration";
        case StatIds.Healthregen: return "Health Regen";
        case StatIds.Dodgenegatepercentadditive: return "Accuracy";
        case StatIds.Deflectionnegatepercentadditive: return "Special Accuracy";
        case StatIds.Attackcriticalnegatepercentadditive: return "Crit Avoid";
        case StatIds.Abilitycriticalnegatepercentadditive: return "Special Crit Avoid";
        case StatIds.Evasionrating: return "Evade";
        case StatIds.Criticalrating: return "Crit";
        case StatIds.Evasionnegaterating: return "Accuracy";
        case StatIds.Criticalnegaterating: return "Crit Avoid";
        case StatIds.Defensepenetrationpercentadditive: return "Defense Penetration";
        case StatIds.Evasionpercentadditive: return "Evade";
        case StatIds.Speedpercentadditive: return "Speed %";
        case StatIds.Counterattackrating: return "Counter Chance";
        case StatIds.Taunt: return "Taunt";
        case StatIds.Defensepenetrationtargetpercentadditive: return "Defense Penetration";
        case StatIds.Mastery: return "Mastery";
    }
}

export function statIdToShortString(stat: StatIds): string 
{
    switch (stat)
    {
        case StatIds.None: return "";
        case StatIds.Health: return "Health";
        case StatIds.Speed: return "Speed";
        case StatIds.PhysicalDamage: return "Dmg";
        case StatIds.SpecialDamage: return "SDmg";
        case StatIds.UnitDefense: return "Def";
        case StatIds.UnitResistance: return "Res";
        case StatIds.ArmorPenetration: return "APen";
        case StatIds.ResistancePenetration: return "RPen";
        case StatIds.DodgeRating: return "Dodge";
        case StatIds.DeflectionRating: return "Defl";
        case StatIds.UnitPhysicalCriticalChance: return "Crit";
        case StatIds.UnitSpecialCriticalChance: return "SCrit";
        case StatIds.CriticalDamage: return "CritD";
        case StatIds.Potency: return "Pot";
        case StatIds.Tenacity: return "Ten";
        case StatIds.ArmorPenetrationPct: return "APen";
        case StatIds.ResistancePenetrationPct: return "RPen";
        case StatIds.HealthSteal: return "Steal";
        case StatIds.Protection: return "Prot";
        case StatIds.PhysicalDamagePct: return "Dmg";
        case StatIds.SpecialDamagePct: return "SDmg";
        case StatIds.UnitPhysicalAccuracy: return "Acc";
        case StatIds.UnitSpecialAccuracy: return "SAcc";
        case StatIds.UnitPhysicalCriticalAvoidance: return "PCAvoid";
        case StatIds.UnitSpecialCriticalAvoidance: return "SCAvoid";
        case StatIds.Offense: return "Off";
        case StatIds.Defense: return "Def";
        case StatIds.DefensePenetration: return "DPen";
        case StatIds.OffensePct: return "Off";
        case StatIds.DefensePct: return "Def";
        case StatIds.Accuracy: return "Acc";
        case StatIds.CriticalChance: return "CChance";
        case StatIds.CriticalAvoidance: return "CAvoid";
        case StatIds.HealthPct: return "Health";
        case StatIds.ProtectionPct: return "Prot";
        case StatIds.Armorpercentadditive: return "Def";
        case StatIds.Suppressionpercentadditive: return "Res";
        case StatIds.Attackcriticalpercentadditive: return "Crit";
        case StatIds.Abilitycriticalpercentadditive: return "SCrit";
        case StatIds.Strength: return "Str";
        case StatIds.Agility: return "Agi";
        case StatIds.Intelligence: return "Int";
        case StatIds.Dodgepercentadditive: return "Dodge";
        case StatIds.Deflectionpercentadditive: return "Defl";
        case StatIds.Shieldpenetration: return "SPen";
        case StatIds.Healthregen: return "HRegen";
        case StatIds.Dodgenegatepercentadditive: return "Acc";
        case StatIds.Deflectionnegatepercentadditive: return "SAcc";
        case StatIds.Attackcriticalnegatepercentadditive: return "CAvoid";
        case StatIds.Abilitycriticalnegatepercentadditive: return "SCAvoid";
        case StatIds.Evasionrating: return "Evade";
        case StatIds.Criticalrating: return "Crit";
        case StatIds.Evasionnegaterating: return "Acc";
        case StatIds.Criticalnegaterating: return "CAvoid";
        case StatIds.Defensepenetrationpercentadditive: return "DPen";
        case StatIds.Evasionpercentadditive: return "Evade";
        case StatIds.Speedpercentadditive: return "Spd%";
        case StatIds.Counterattackrating: return "Counter";
        case StatIds.Taunt: return "Taunt";
        case StatIds.Defensepenetrationtargetpercentadditive: return "Dpen";
        case StatIds.Mastery: return "Mastery";
    } 
}

export function statIdToDisplayValue(stat: StatIds, value: number): string
{
    value = value / 100000000;
    switch (stat)
    {
        case StatIds.Health: return (value / 1000).toFixed(0) + "K";
        case StatIds.Speed: return value.toFixed(0);
        case StatIds.PhysicalDamage: return (value / 1000).toFixed(0) + "K";
        case StatIds.SpecialDamage: return (value / 1000).toFixed(0) + "K";
        case StatIds.UnitDefense: return value.toFixed(0);
        case StatIds.UnitResistance: return value.toFixed(0);
        case StatIds.ArmorPenetration: return (value * 100).toFixed(0) + "%";
        case StatIds.ResistancePenetration: return (value * 100).toFixed(0) + "%";
        case StatIds.DodgeRating: return (value * 100).toFixed(0) + "%";
        case StatIds.DeflectionRating: return (value * 100).toFixed(0) + "%";
        case StatIds.UnitPhysicalCriticalChance: return (value * 100).toFixed(0) + "%";
        case StatIds.UnitSpecialCriticalChance: return (value * 100).toFixed(0) + "%";
        case StatIds.CriticalDamage: return (value * 100).toFixed(0) + "%";
        case StatIds.Potency: return (value * 100).toFixed(0) + "%";
        case StatIds.Tenacity: return (value * 100).toFixed(0) + "%";
        case StatIds.ArmorPenetrationPct: return (value * 100).toFixed(0) + "%";
        case StatIds.ResistancePenetrationPct: return (value * 100).toFixed(0) + "%";
        case StatIds.HealthSteal: return (value * 100).toFixed(0) + "%";
        case StatIds.Protection: return (value / 1000).toFixed(0) + "K";
        case StatIds.PhysicalDamagePct: return (value * 100).toFixed(0) + "%";
        case StatIds.SpecialDamagePct: return (value * 100).toFixed(0) + "%";
        case StatIds.UnitPhysicalAccuracy: return (value * 100).toFixed(0) + "%";
        case StatIds.UnitSpecialAccuracy: return (value * 100).toFixed(0) + "%";
        case StatIds.UnitPhysicalCriticalAvoidance: return (value * 100).toFixed(0) + "%";
        case StatIds.UnitSpecialCriticalAvoidance: return (value * 100).toFixed(0) + "%";
        case StatIds.Offense: return (value * 100).toFixed(0) + "%";
        case StatIds.Defense: return (value * 100).toFixed(0) + "%";
        case StatIds.DefensePenetration: return (value * 100).toFixed(0) + "%";
        case StatIds.OffensePct: return (value * 100).toFixed(0) + "%";
        case StatIds.DefensePct: return (value * 100).toFixed(0) + "%";
        case StatIds.Accuracy: return (value * 100).toFixed(0) + "%";
        case StatIds.CriticalChance: return (value * 100).toFixed(0) + "%";
        case StatIds.CriticalAvoidance: return (value * 100).toFixed(0) + "%";
        case StatIds.HealthPct: return (value * 100).toFixed(0) + "%";
        case StatIds.ProtectionPct: return (value * 100).toFixed(0) + "%";

        case StatIds.Armorpercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Suppressionpercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Attackcriticalpercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Abilitycriticalpercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Strength: return value.toFixed(0);
        case StatIds.Agility: return value.toFixed(0);
        case StatIds.Intelligence: return value.toFixed(0);
        case StatIds.Dodgepercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Deflectionpercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Shieldpenetration: return value.toFixed(0);
        case StatIds.Healthregen: return value.toFixed(0);
        case StatIds.Dodgenegatepercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Deflectionnegatepercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Attackcriticalnegatepercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Abilitycriticalnegatepercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Evasionrating: return value.toFixed(0);
        case StatIds.Criticalrating: return value.toFixed(0);
        case StatIds.Evasionnegaterating: return value.toFixed(0);
        case StatIds.Criticalnegaterating: return value.toFixed(0);
        case StatIds.Defensepenetrationpercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Evasionpercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Speedpercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Counterattackrating: return value.toFixed(0);
        case StatIds.Taunt: return value.toFixed(0);
        case StatIds.Defensepenetrationtargetpercentadditive: return (value * 100).toFixed(0) + "%";
        case StatIds.Mastery: return value.toFixed(0);

        default: return value.toString();
    }
}

export default StatIds;
