import React from "react";
import { Button, Checkbox } from "antd";
import BlankLayout from "../../layouts/BlankLayout";
import { ButtonColumn, ButtonRow } from '../../ui/buttonRow';
import i18next from "i18next";
import { GoogleButton } from '../../ui/blockButton';
import { Cookies } from "react-cookie";
import UserData from "../../model/UserData";
import styles from "./styles/Login.module.scss";

function generateLink()
{
    return "https://discord.com/api/oauth2/authorize?client_id=470635832462540800&redirect_uri=" +
        encodeURIComponent(window.location.origin + "/callback") + "&response_type=code&scope=identify&prompt=none&state=12345";
}

export function generateGoogleLink(): string
{
    if (window.location.host === "development.hotutils.com" || window.location.host.startsWith("localhost"))
        return "https://accounts.google.com/o/oauth2/auth?scope=openid%20profile%20email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgames%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&client_id=558841659459-0km1uo81utshopddm8ks35u5t20pmsif.apps.googleusercontent.com&response_type=code&access_type=offline&include_granted_scopes=true&redirect_uri=" +
        encodeURIComponent(window.location.origin + "/googlecallback");
    else
        return "https://accounts.google.com/o/oauth2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgames&client_id=322550169421-057b185rm5be4p5k9869bgucsgerrbbr.apps.googleusercontent.com&response_type=code&access_type=offline&redirect_uri=" +
            encodeURIComponent(window.location.origin + "/googlecallback");
}


interface LoginPageProps
{
    cookies: Cookies;
    user: UserData;
}

const getRememberMeStatus = (localStorage: string | null) =>
{
    return localStorage === "1";
}

function iOS(): boolean
{
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}

function isBadIOS()
{
    return iOS() && window.matchMedia("(orientation: portrait)").matches && false;
}

const GoogleLanding = (props: LoginPageProps) =>
{
    if (props.cookies.get("hotUtilsSession"))
        props.cookies.remove("hotUtilsSession");
    if (props.cookies.get("hotUtilsAllyCode"))
        props.cookies.remove("hotUtilsAllyCode");
    if (props.user.sessionId)
    {
        props.user.logout();
    }
    return (
        <BlankLayout>
            <div className={styles.container}>

                <div className={styles.top}>
                    <img className={styles.logo} src={"hotsauce-logo.png"} alt={""} />
                    <h2>Welcome to HotUtils, your personal and guild tool management system</h2>
                    <div className={styles.middle}>
                        <div>HotUtils enables players to manage both their personal game as well as their guild, with features like mod management, territory battle platoons, and so much more</div>
                        <div>Use our tools to orgainze your game as well as help your guild reach the next level</div>
                        <div>You can find a great library of tutorial videos on our <a href="https://www.youtube.com/svirve">YouTube Channel</a></div>
                        <div>Please ensure you have already registered your game account with HotBot on discord, you can find assistance with that if needed in the channel #how-to-sign-up</div>
                        <div>Once registered you can use your google linked game account, EA Connect or discord to login</div>
                        <div>We only use Discord/Google/EA login links to verify your identify and link to your in game ally codes. We never retain any information from these accounts themselves</div>
                    </div>
                    <div className={styles.buttons}>
                        <ButtonColumn>
                            <Button type="primary" size="large"
                                    block>{i18next.t("common:login.login_with_discord")}</Button>
                            {!isBadIOS() && <a href={String(generateLink())}>
                                <Button type="primary" size="large"
                                    block>{i18next.t("common:login.login_with_discord")}</Button>
                            </a>}
                            <a href={generateGoogleLink()}>
                                <GoogleButton type="primary" size="large"
                                    block>{i18next.t("common:login.login_with_google")}</GoogleButton>
                            </a>
                            <a href={"/eaconnect"}>
                                <GoogleButton type="primary" size="large"
                                    block>{i18next.t("common:login.login_with_eaconnect")}</GoogleButton>
                            </a>
                            <a href="https://www.patreon.com/hotutils/membership">
                                <Button type="link" size="large" className={styles.white} block>Become a Patron</Button>
                            </a>
                            
                        </ButtonColumn>
                        <Checkbox
                            className={styles.rememberMe}
                            defaultChecked={getRememberMeStatus(localStorage.getItem("rememberMe"))}
                            onChange={(event) =>
                            {
                                localStorage.setItem("rememberMe", event.target.checked ? "1" : "0");
                            }}
                        >
                            Remember me
                        </Checkbox>
                    </div>
                </div>
                
                <div className={styles.bottom}>
                    <ButtonRow>
                        <a href="https://discord.me/hotutils"> Discord Links</a> | <a
                            href="mailto:hotsauce@hotutils.com"> Site Admin</a> | <a href="/privacy.html"> Privacy Policy</a>
                    </ButtonRow>
                    <ButtonRow>
                        <div>This website is not affiliated with or sponsored by Electronic Arts Inc. or its licensors.</div>
                    </ButtonRow>
                </div>
            </div>
        </BlankLayout>
    );
};

export default GoogleLanding;
