
class PlayerStat
{

    key: string;
    name: string;
    value: number;
    valueStr: string;

    constructor(key: string, name: string, value: number, valueStr: string)
    {
        this.key = key;
        this.name = name;
        this.value = value;
        this.valueStr = valueStr;
    }
}

export default PlayerStat;
