
export enum Stats {
    gearLevel = 'Gear',
    relicLevel = 'Relic',
    omicron = 'Omicron',
    speed = 'Speed',
    tenacity = 'Tenacity',
    health = 'Health',
    protection = 'Protection',
    damage = 'Physical Damage',
    critAvoidance = 'Critical Avoidance',
    critDamage = 'Critical Damage',
    critChance = 'Critical Chance',
    potency = 'Potency',
    armor = 'Armor',
    resistance = 'Resistance'
}
export enum ShipStats {
    totalPower = 'Total Power',
    speed = 'Speed',
    tenacity = 'Tenacity',
    health = 'Health',
    protection = 'Protection',
    damage = 'Physical Damage',
    critAvoidance = 'Critical Avoidance',
    critDamage = 'Critical Damage',
    critChance = 'Critical Chance',
    potency = 'Potency',
    armor = 'Armor',
    resistance = 'Resistance'
}
