import { Card, Table } from "antd";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import UserData from "../../model/UserData";

interface WhatsNewProps
{
    user: UserData;
}

interface WhatsNewItemData
{
    date: Date;
    msg: React.ReactNode;
    section: string;
}

const newItems: WhatsNewItemData[] = [
    // add new items to the top here
    {
        date: new Date(2024, 7, 12),
        msg: <>Added some new mod management filter options (exposed, equipped)
        </>,
        section: "Mod Management"
    },
    {
        date: new Date(2023, 7, 12),
        msg: <>Added a new ROTE TB planning tool. This will help guilds plan their ROTE TBs easier.
        </>,
        section: "TB"
    },
    {
        date: new Date(2023, 4, 17),
        msg: <>Added ability to disable locker unit prompt in Playground
        </>,
        section: "Playground"
    },
    {
        date: new Date(2023, 4, 16),
        msg: <>Added ability to move locker units to bottom of priority list.  Toolbar-Priorities-Locker Units to Bottom
        </>,
        section: "Playground"
    },
    {
        date: new Date(2023, 3, 20),
        msg: <>Added Speed Floor to Mod Depth export
        </>,
        section: "Mod Wizard"
    },
    {
        date: new Date(2023, 3, 18),
        msg: <>Added ROTE to Mod Wizard
        </>,
        section: "Mod Wizard"
    },
    {
        date: new Date(2023, 3, 18),
        msg: <>Re-designed loadout definition import screen, allowing for importing of specific modding aspects.
        </>,
        section: "Playground"
    },
    {
        date: new Date(2023, 3, 18),
        msg: <>Fixed automation bug where automation would fail if a unit higher in priorty order had dependency that could not be meet and requirement wouldn't be lessened.
        </>,
        section: "Playground"
    },
    {
        date: new Date(2023, 2, 12),
        msg: <>Added storage locker support to playground
        </>,
        section: "Playground"
    },
    {
        date: new Date(2023, 1, 26),
        msg: <>Fixed bug where updating loadout would reset locked status
        </>,
        section: "Loadouts"
    },
    {
        date: new Date(2022, 11, 17),
        msg: <>Added Summary Stats, Player Table
        </>,
        section: "TB Manage"
    },
    {
        date: new Date(2022, 11, 6),
        msg: <>Added filter for locker units in unit table <br />
        Added ability to sort by mod quality.
        </>,
        section: "Mod Maintenance"
    },
    {
        date: new Date(2022, 11, 6),
        msg: <>Added ability to lock units during automation
        </>,
        section: "Mod Wizard"
    },
    {
        date: new Date(2022, 11, 6),
        msg: <>Added warning if short on mods of specific slot
        </>,
        section: "Playground"
    },
    {
        date: new Date(2022, 10, 9),
        msg: <>Add TW reporting for guilds using advanced TW tracking
        </>,
        section: "TW/Reporting"
    },
    {
        date: new Date(2022, 9, 4),
        msg: <>Locking directly from stats.
        </>,
        section: "Playground"
    },
    {
        date: new Date(2022, 9, 4),
        msg: <>Datacrons added to UI.
        </>,
        section: "Templates"
    },
    {
        date: new Date(2022, 8, 22),
        msg: <>New component Template \ Squad Editor now public - simplified UI for template editing.
        </>,
        section: "Template Squad Editor"
    },
    {
        date: new Date(2022, 8, 1),
        msg: <>New component Mod Maintenance now public - will replace Mod Swap.
        </>,
        section: "Mod Maintenance"
    },
    {
        date: new Date(2022, 7, 26),
        msg: <>Moved Mod Depth to its own page.  Save results so it doesnt need to be run every time.  Added export ability.
        </>,
        section: "Mod Depth"
    },
    {
        date: new Date(2022, 5, 27),
        msg: <>Added basic datacron view
        </>,
        section: "Player/Datacrons"
    },
    {
        date: new Date(2022, 5, 1),
        msg: <>Added Personal Events section. For now, you can disable your events when you have changed guilds, but more coming soon
        </>,
        section: "Player/Events"
    },
    {
        date: new Date(2022, 4, 18),
        msg: <>Fixed bug when loading small loadout (more than 5 units) causes page freeze.<br />
            Added error tolerance into mod task progress monitor (help with 502 errors).<br />
            Fixed bug where archived loadouts could not be un-archived
        </>,
        section: "Loadouts"
    },
    {
        date: new Date(2022, 4, 18),
        msg: <>Added max unit reorder cap, increase this setting if you are willing to run long automations.
        </>,
        section: "Playground"
    },
    {
        date: new Date(2022, 3, 29),
        msg: <>Added Salvage and gear crafting (under Player/Salvage).
        </>,
        section: "Salvage"
    },
    {
        date: new Date(2022, 1, 15),
        msg: <>Added TW planning page. This will help the guilds to plan their defense more easily.
        </>,
        section: "TW Planning"
    },
    {
        date: new Date(2021, 11, 30),
        msg: <>Added ability to save locked status in loadouts.  Added new editor window in basic view when editing on large screen.
        </>,
        section: "Playground"
    },
    {
        date: new Date(2021, 10, 26),
        msg: <>Added Mod Wizard component for simple modding.
        </>,
        section: "Mod Wizard"
    },
    {
        date: new Date(2021, 10, 8),
        msg: <>Added a new section called: Video Content where you can check content added by content creators or by your own guild.
            Also every subscriber can add their own videos and share with other players or with their guild if they are admins/officers.
        </>,
        section: "Video Content"
    },
    {
        date: new Date(2021, 10, 4),
        msg: <>Added auto assign players
        </>,
        section: "TB planning"
    },
    {
        date: new Date(2021, 10, 1),
        msg: <>New improvements to squads: manually sorting, send sort order of the squads in the game,
            share squads tab, guild squad management, etc.
        </>,
        section: "Squads"
    },
    {
        date: new Date(2021, 9, 15),
        msg: <>Added Fill capability to help with pushing large roster remods.
        </>,
        section: "Loadouts"
    },
    {
        date: new Date(2021, 9, 10),
        msg: <>Added new component TB Data to export entire TB (no longer have to do all 4 phases)
        </>,
        section: "TB Data"
    },
    {
        date: new Date(2021, 9, 10),
        msg: <>Fix for import excluding units not in compliance template even if exclude not included is selected.
            Added faster optimizer (beta) version, can be toggled in automation options - will make default in one week.
            Added ability to merge stat targets when importing loadout definition
            Added filter for 6E targets
        </>,
        section: "Playground"
    },
    {
        date: new Date(2021, 9, 10),
        msg: <>Check for existing loadout definition on save in Mod Swap
        </>,
        section: "Mod Swap"
    },
    {
        date: new Date(2021, 8, 21),
        msg: <>Added a BETA version of the new GAC tools, including creating plans and placing them. Please be careful using them, and let us know if you have any problems
        </>,
        section: "GAC"
    },
    {
        date: new Date(2021, 8, 20),
        msg: <>Added Squad Sync to push recommended squads into your in game squad list.
        </>,
        section: "Conquest"
    },
    {
        date: new Date(2021, 8, 14),
        msg: <>Added push to game in review tab. Added filter unavailable to mod editor. Fixed bug related to updating
            game data mid session.
        </>,
        section: "Playground"
    },
    {
        date: new Date(2021, 8, 11),
        msg: <>Playground Beta replaces Playground. Added copy target to unit target editor.
        </>,
        section: "Playground"
    },
    {
        date: new Date(2021, 8, 10), msg: <>Add split loadout capability for large remods
        </>, section: "Loadouts"
    },
    {
        date: new Date(2021, 8, 10), msg: <>Added auto prioritization to Playground Beta from Stack Rank tool.
            Added image URL to generation screen for users where tab did not open
        </>, section: "Playground"
    },
    {
        date: new Date(2021, 8, 8), msg: <>Added optional unit support to Playground Beta squads.
        </>, section: "Playground"
    },
    {
        date: new Date(2021, 8, 6),
        msg: <>Released Playground Beta, will monitor for 1-2 weeks then replace existing Playground.
        </>,
        section: "Playground"
    },
    {
        date: new Date(2021, 7, 27), msg: <>Rearranged left side menu, and added a new Squad system.
            Existing squads should be automatically moved.
            The new Squad system will be super important for some upcoming features, so please check it out.
            It supports importing from your already existing in game squads.
        </>, section: "All"
    },
    {
        date: new Date(2021, 7, 18),
        msg: <>Added Gear Progress for a couple types of key gear to the bottom of Personal Farming
        </>,
        section: "Farming"
    },
    {
        date: new Date(2021, 6, 29), msg: <>Added Relic Salvage Farm time to Personal Farming page (not guild)
        </>, section: "Farming"
    },
    {
        date: new Date(2021, 6, 12), msg: <>Added Guild Squads page
        </>, section: "Guild Squads"
    },
    {
        date: new Date(2021, 6, 12), msg: <>Added option to disable progress on TB Status when results look odd
        </>, section: "TB Status"
    },
    {
        date: new Date(2021, 6, 9), msg: <>Added TB platoons
        </>, section: "TB Planning"
    },
    {
        date: new Date(2021, 6, 7),
        msg: <>Take into account gear and shard inventory when showing personal farming times
        </>,
        section: "Farming"
    },
    {
        date: new Date(2021, 5, 30), msg: <>Add Personal and Guild Farming trackers
        </>, section: "Farming"
    },
    {
        date: new Date(2021, 5, 24), msg: <>Add Alliance Management page
        </>, section: "Alliances"
    },
    {
        date: new Date(2021, 5, 18), msg: <>Add share alliance selector in templates<br />
            Fixed bug in wizard for gear and star selector.
        </>, section: "Templates"
    },
    {
        date: new Date(2021, 5, 17), msg: <>Fixed sorting bug for results table<br />
            Added all column on category results table<br />
            Increased font size for editor tree view
        </>, section: "Templates"
    },
    {
        date: new Date(2021, 5, 16), msg: <>Added Dark Mode toggle in user menu (top right)
        </>, section: "All"
    },
    {
        date: new Date(2021, 5, 16), msg: <>Added Template Edit and Execute
        </>, section: "Templates"
    },
    {
        date: new Date(2021, 5, 16), msg: <>Adding Admin Role Status
        </>, section: "Guild Overview"
    },
    {
        date: new Date(2021, 4, 24), msg: <>Adding Guild Tickets page
        </>, section: "Guild Tickets"
    },
    {
        date: new Date(2021, 4, 20), msg: <>Adding HotUtils sharing settings
        </>, section: "Settings"
    },
    {
        date: new Date(2021, 4, 20), msg: <>Added mark to sell/slice to mod management, even for shared accounts
        </>, section: "Mod Management"
    },
    {
        date: new Date(2021, 4, 14), msg: <>Added Guild Scouting
        </>, section: "Guild Compare"
    },
    {
        date: new Date(2021, 4, 4), msg: <>New Event system!
        </>, section: "Events"
    },
    {
        date: new Date(2021, 4, 4), msg: <>Added incomplete filter
        </>, section: "Playground"
    },
    {
        date: new Date(2021, 4, 4), msg: <>Added Support for TB History
        </>, section: "TB Players"
    },
    {
        date: new Date(2021, 4, 4), msg: <>Added Compare option for Player | Guilds | Units
        </>, section: "Compare Page"
    },
    {
        date: new Date(2021, 4, 4), msg: <>Added Search Page to see other Guild Overview + Players Profile
        </>, section: "Guild Page"
    },
    {
        date: new Date(2021, 4, 4), msg: <>Added Remember Me option
        </>, section: "Login Page"
    },
    {
        date: new Date(2021, 3, 19), msg: <>Added Guild Unit Compare
        </>, section: "Guild Unit"
    },
    {
        date: new Date(2021, 3, 13), msg: <>Added Player Profile in guild overview for each member
        </>, section: "Guild Overview"
    },
    {
        date: new Date(2021, 3, 12),
        msg: <>Kept chart visible after completing mission goals or when they are out of reach.
        </>,
        section: "TB Status"
    },
    {
        date: new Date(2021, 3, 12), msg: <>Added Need Leveling filter to Playground
        </>, section: "Playground"
    },
    {
        date: new Date(2021, 3, 11), msg: <>Added Player Profile<br />
            New unit avatar images
        </>, section: "Player Profile"
    },
    {
        date: new Date(2021, 3, 10), msg: <>Added TW History<br />
            Added score overview to TW, to match the bot $twanalyze command
        </>, section: "TW"
    },
    {
        date: new Date(2021, 3, 8), msg: <>Added image generation button for loadouts with 5 or less units.
        </>, section: "Loadouts"
    },
    {
        date: new Date(2021, 3, 8),
        msg: <>Added ability to view and remod ally roster. (Note: this cannot affect their account, but you can share
            your remod with them, then they can push)<br />
            Added image generation
        </>,
        section: "Playground"
    },
    {
        date: new Date(2021, 3, 6), msg: <>Changed the Guild Overview Page
        </>, section: "Guild Overview"
    },
    {
        date: new Date(2021, 3, 6), msg: <>Moved the Bronzium section to it's own page
        </>, section: "Dashboard"
    },
    {
        date: new Date(2021, 2, 26), msg: <>Added Mod Swap
        </>, section: "Mod Swap"
    },
    {
        date: new Date(2021, 2, 25), msg: <>Added Conquest Status
        </>, section: "Conquest Status"
    },
    {
        date: new Date(2021, 2, 18), msg: <>Added Conquest Data Disk Management
        </>, section: "Data Disks"
    },
    {
        date: new Date(2021, 1, 17), msg: <>Added guild data download (Jalapeno+ required)
        </>, section: "Guild Members"
    },
    {
        date: new Date(2021, 1, 15),
        msg: <>Added warning if players apply a loadout and current configuration is not saved.
        </>,
        section: "Loadouts"
    },
    {
        date: new Date(2021, 1, 14), msg: <>Added workaround for when player leaves guild to specify no sandbagging
        </>, section: "TB Status"
    },
    {
        date: new Date(2021, 1, 13), msg: <>Add ability to slice from Playground<br />
            Add ability to change mod view from Playground editor.
        </>, section: "Playground"
    },
    {
        date: new Date(2021, 1, 13), msg: <>Sort categories in loadout category filter
        </>, section: "Loadouts"
    },
    {
        date: new Date(2021, 1, 11), msg: <>Fixed bug when trying to add players manually in GAC compare
        </>, section: "GAC Compare"
    },
    {
        date: new Date(2021, 1, 11), msg: <>Corrected starting point of chart lines when sandbagging<br />
            Changed references from combat to character where appropriate.<br />
            Add ability to toggle chart
        </>, section: "TB Status"
    },
    {
        date: new Date(2021, 1, 11),
        msg: <>Add fleet and character (and unknown) deployment remaining GP as column - click configure to add<br />
            Fixed bug where the deployment and combat pre-created filters did not work
        </>,
        section: "TB Players"
    },
    {
        date: new Date(2021, 1, 11),
        msg: <>Add guild member list, showing all member's stats, including their HotUtils Patreon status
        </>,
        section: "Guild Members"
    },
    {
        date: new Date(2021, 1, 11), msg: <>List all mission possibilities when multiple exists, highlight name yellow
        </>, section: "TB Players"
    },
    {
        date: new Date(2021, 1, 11), msg: <>Show mission progress chart<br />
            Include gp of star earned in hover help on stars.<br />
            Additional warnings for sandbagging
        </>, section: "TB Status"
    },
    {
        date: new Date(2021, 1, 10),
        msg: <>First cut of TW. Just shows the most recent (or current) TW map, more coming soon<br />
            Fixed bug when mass unlocking mods didn't actually unlock them
        </>,
        section: "TW"
    },
    {
        date: new Date(2021, 1, 5), msg: <>Added ability to include modless units when creating loadout.
        </>, section: "Loadouts"
    },
    {
        date: new Date(2021, 1, 5),
        msg: <>Added Unequip button to unequip mods. Don't forget to put mods back on your characters!<br />
            Added warning when locking and unlocking mods<br />
            Use task system when locking or unlocking large number of mods, so less people get stuck in a mod
            action<br />
            Sort filter groups by name within each type<br />
            Move tooltip to the bottom for the first row of mods in each section so they don't overlap the buttons
        </>,
        section: "Mod Management"
    },
    {
        date: new Date(2021, 1, 4), msg: <>Renamed Unequipped Mods to Mod Management
        </>, section: "Mod Management"
    },
    {
        date: new Date(2021, 1, 4),
        msg: <>When loading a loadout with missing mods, user is prompted if they want to remove missing mods<br />
            This can be done without pushing loadout to game by clicking Edit- Save As- Update Loadout
        </>,
        section: "Loadouts"
    },
    {
        date: new Date(2021, 1, 3), msg: <>Add less than versions of percent of max possible filter options<br />
            Add warning when selling a mod used in a hotutils loadout
        </>, section: "Mod Management"
    },
    {
        date: new Date(2021, 1, 3), msg: <>Fix blank tooltip on Incomplete and Garbage mods<br />
            Sort filter groups by type<br />
            Add expose all button to incomplete mods section header
        </>, section: "Mod Management"
    },
    {
        date: new Date(2021, 1, 3), msg: <>Remember sort selection for loadouts
        </>, section: "Loadouts"
    },
    {
        date: new Date(2021, 1, 3), msg: <>Added checkbox to playground to select included units<br />
            Fixed problem where quicksave deleted description<br />
            Added a 'changed' filter to see what units would be changed by loadout
        </>, section: "Playground"
    },
    {
        date: new Date(2021, 0, 31),
        msg: <>Add tooltip when hovering over a mod telling you which filters matched it<br />
            Fix selection and redrawing after some mod operations
        </>,
        section: "Mod Management"
    },
    {
        date: new Date(2021, 0, 30), msg: <>Change to a much faster UI for the mod list<br />
            Add jump to mod filter sections
        </>, section: "Mod Management"
    },
    {
        date: new Date(2021, 0, 30),
        msg: "Don't ask for Discord permissions every login if you already OKd them",
        section: "All"
    },
    {
        date: new Date(2021, 0, 30), msg: <>Make top cards collapsable<br />
            Add quick filtering back<br />
            Add colorblind mode which shows mod tier as a letter on the mod
        </>, section: "Mod Management"
    },
    {
        date: new Date(2021, 0, 29), msg: <>Add warning when leveling more than 100,000 credits worth of mods<br />
            Add type filter dropdown to Add Public Filter Dialog<br />
            Allow filters to match against Percent of max possible by tier<br />
            Add Incomplete filters<br />
            Add help to View Mode to explain the options
        </>, section: "Mod Management"
    },
    { date: new Date(2021, 0, 29), msg: "Updated Dashboard, added What's New section", section: "Dashboard" },
    { date: new Date(2021, 0, 29), msg: "Added credits and mod inventory to the top banner", section: "All" },
    { date: new Date(2021, 0, 29), msg: "Added Alerting players on discord to the TB pages", section: "TB" },
    {
        date: new Date(2021, 0, 29),
        msg: <>1) TB Players table will now identify players "uncertain" if they have deployed enough for ship and
            character gp but not both. It previously only marked uncertain if the fleet zone was also completed. Users
            can "confirm" which zone was deployed by checking the correct box. (Remember to hit the save button after
            you have done this). Uncertain users have a yellow background and can be viewed by clicking the Uncertain
            filter in the deployments dropdown.<br />
            2) The TB Status page now displays overall combat mission points remaining to hit all goals including zone
            minimums (zone minimums are listed in parenthesis next to that). It used to display the additional combat
            points over the minimums.<br />
            3) The tool now lists the % of mission points remaining to hit that goal.<br />
            4) Once required combat mission points have been reached, the combat overview now notes that you are within
            deployment range of goals, and by how much.<br />
            5) Added some fixes for sandbagging, which you should probably not see unless you sandbagged a zone last
            round</>,
        section: "TB"
    },
    {
        date: new Date(2021, 0, 27), msg: <>1) Update size of tb players table to fit more of screen<br />
            2) Highlighted tb players cell if they have completed assignments to it stands out<br />
            3) Added mission waves to tb players table<br />
            4) Fixed bug where refreshing on loadouts component caused spinner to never end<br />
            5) Added sort by options to loadout cards (date, category, name, desciption)<br />
            6) hide ships columns on players table if there was no open ship zone<br />
            7) added new field to show how much a zone was overshot by in tb status<br />
            8) added uncertain to players table filter for deployments</>, section: "TB"
    },
];

const WhatsNewCard = styled(Card)`
  height: 540px;
  overflow: auto;
  width: 100%;
`;


@observer
class WhatsNew extends React.Component<WhatsNewProps> {

    render()
    {
        const cols = [
            {
                title: "Date",
                dataIndex: "date",
                key: "date",
                render: (date: Date) =>
                {
                    return date.toLocaleDateString();
                }
            },
            {
                title: "Section",
                dataIndex: "section",
                key: "section"
            },
            {
                title: "Message",
                dataIndex: "msg",
                key: "msg"
            },
        ];

        return (<>
            <WhatsNewCard title="Whats New">
                <Table
                    dataSource={newItems}
                    columns={cols}
                    pagination={{ defaultPageSize: 5 }}
                    rowKey={record => (record.section + record.date)}
                />
            </WhatsNewCard>
        </>
        );
    }
}

export default WhatsNew;