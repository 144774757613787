import UserData from '../../../model/UserData';
import BaseAPI from '../../../service/BaseAPI';
import ConfigurableSetting from '../../../model/ConfigurableSetting';

export class ModWizardController
{
    static LOCKED_UNITS = "locked_units";

    static async onSetLockedUnits(user: UserData, lockedUnits: string[]): Promise<void>
    {
        try
        {
            BaseAPI.setPlayerSetting(user, ModWizardController.LOCKED_UNITS,
                JSON.stringify(lockedUnits), true);
        }
        catch (response)
        {
            console.log(response)
            return;
        }
    }

    public static async getLockedUnits(user: UserData): Promise<string[] | undefined>
    {
        let retVal: string[] | undefined | null = undefined;

        let settings: ConfigurableSetting[] = await BaseAPI.getPlayerSetting(user, ModWizardController.LOCKED_UNITS);
        if (settings.length > 0)
        {
            retVal = JSON.parse(settings[0].value);
            retVal = retVal === null ? undefined : retVal;
        }

        return retVal;
    }

}