import React from "react";
import IPageProps from "../../IPageProps";
import { observer } from "mobx-react";
import { Alert, Button, Col, Modal, Row, Select, Switch as UiSwitch } from 'antd';
import { action, observable, runInAction } from "mobx";
import styles from "./styles/ModWizard.module.scss";
import { LD_CATEGORY_TIERS, LoadoutDefintionGroup, LoadoutDefintionSummary } from "../../../model/LoadoutDefinition";
import { SquadSelection } from "./ModWizard";
import { ColumnsType } from "antd/lib/table";
import UnitAvatar from "../../../components/swgoh/Character/UnitAvatar";
import { Divider } from "antd";
import { IGuildSquadTemplate } from "../../../model/Squads";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faTrash } from "@fortawesome/pro-solid-svg-icons";
import confirmationDialog from "../../../ui/confirmationDialog";
const { Option } = Select;

interface IModWizardSquadManagerProps extends IPageProps
{
    squadSelections: SquadSelection[];
    loadoutDefinitionCategoriesFilter: string[];
    squadsCategoryFilter: string[];
    existingLoadoutDefinitionGroups: LoadoutDefintionGroup[];
    onAddSquadSelection: (ss: SquadSelection) => void;
    maxSquadSize: number | undefined;
    fullRoster: boolean;
    maxSize: number;
    unitAvailable: (unitId: string) => boolean;
    onClickUnit: (ss: SquadSelection, unitId: string) => void;
    deleteSquadSelection: (index: number) => void;
    playerSquadTemplates: IGuildSquadTemplate[] | null;
    onMoveSquadUp: (index: number) => void;
    onMoveSquadDown: (index: number) => void;
    removeAllSquads: () => void;
    showSquadSelector: boolean;
    setShowSquadSelector: (showSquadSelector: boolean) => void;
    loadoutDefinitionSummaryBase: LoadoutDefintionSummary | undefined;
    setLoadoutDefinitionBase: (lds: LoadoutDefintionSummary | undefined) => void;
    setLdSummary: (lds: LoadoutDefintionSummary | undefined, index: number) => void;
}


export enum SquadSelectionTypeEnum
{
    Squad = 1,
    LoadoutDefinition
}


interface ISquadSelectionRow
{
    selectionType: SquadSelectionTypeEnum;
    loadoutDefinitionSummary: LoadoutDefintionSummary | undefined;
    loadoutDefinitionGroup: LoadoutDefintionGroup | undefined;
    validRow: boolean;
    availableUnits: string[];
}

@observer
class ModWizardSquadManager extends React.Component<IModWizardSquadManagerProps>
{

    @observable showSearchLibraryModal: boolean = false;
    @observable showSelectOptionalModal: boolean = false;
    @observable hideUnavailable: boolean = true;

    @observable searchText: string = "";
    @observable tierCategories: string[] = [];
    @observable charactersId: string[] = [];
    @observable ldsOptional: LoadoutDefintionSummary | undefined = undefined;
    @observable optionalUnitsSelected: string[] = [];

    squadSelectionColumns: ColumnsType<SquadSelection> = [
        {
            title: 'Units',
            dataIndex: 'units',
            key: 'selectionType',
            render: (text: string, record: SquadSelection) => <React.Fragment>
                {this.renderSelectionUnits(record)
                }
            </React.Fragment>
        },
        {
            title: 'Definition',
            dataIndex: 'definition',
            key: 'selectionType',
            render: (text: string, record: SquadSelection, index) =>
            {
                return this.renderLoadoutDefinitionSelection(record, index);

            }
        },
        {
            title: 'Move',
            dataIndex: 'move',
            key: 'move',
            render: (text: string, record: SquadSelection, index) => <React.Fragment>
                {
                    <Button
                        disabled={index === 0}
                        onClick={() => this.props.onMoveSquadUp(index)}
                        icon={<FontAwesomeIcon icon={faCaretUp} className={styles.icon} />} />
                }
                {

                    <Button
                        disabled={index >= this.props.squadSelections.length - 1}
                        onClick={() => this.props.onMoveSquadDown(index)}
                        icon={<FontAwesomeIcon icon={faCaretDown} className={styles.icon} />} />

                }
            </React.Fragment>
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text: string, record: SquadSelection, index) => <React.Fragment>
                <FontAwesomeIcon onClick={() => this.props.deleteSquadSelection(index)} icon={faTrash} className={styles.icon} />
            </React.Fragment>
        }];

    searchColumns: ColumnsType<ISquadSelectionRow> = [
        {
            title: 'Source',
            dataIndex: 'selectionType',
            key: 'selectionType',
            render: (text: string, record: ISquadSelectionRow) => <React.Fragment>
                {record.selectionType === SquadSelectionTypeEnum.LoadoutDefinition &&
                    <React.Fragment>
                        <div>
                            {record.loadoutDefinitionSummary!.discordTag}
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        },
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
            render: (text: string, record: ISquadSelectionRow) => <React.Fragment>
                {record.selectionType === SquadSelectionTypeEnum.LoadoutDefinition &&
                    this.renderLoadoutDefinitionRow(record)
                }
            </React.Fragment>
        },
        {
            title: 'Add',
            dataIndex: 'add',
            key: 'add',
            render: (text: string, record: ISquadSelectionRow) => <React.Fragment>
                {record.validRow && <Button onClick={() => this.selectSquadSelectionRow(record)}>Select</Button>}
            </React.Fragment>
        }
    ];

    @action
    selectSquadSelectionRow(record: ISquadSelectionRow)
    {
        this.optionalUnitsSelected = [];
        this.ldsOptional = undefined;

        if (record.loadoutDefinitionSummary !== undefined && record.loadoutDefinitionSummary.squadUnits !== null &&
            record.loadoutDefinitionSummary.squadUnits.find(su => LoadoutDefintionSummary.isSquadUnitOptions(su)) !== undefined)
        {
            this.ldsOptional = record.loadoutDefinitionSummary;
            this.showSearchLibraryModal = false;
            this.showSelectOptionalModal = true;

        } else if (record.loadoutDefinitionSummary !== undefined)
        {
            this.addSquadSelectionRow(record.loadoutDefinitionSummary);
        }
    }

    ldSummaryKey(lds: LoadoutDefintionSummary): string
    {
        return lds.discordTag + "!" + lds.title;
    }

    renderLoadoutDefinitionSelection(record: SquadSelection, index: number)
    {
        let loadoutDefinitions = this.getLoadoutDefinitionsForSquadSelection(record.requiredUnits.concat(record.selectedOptionalUnits), true);
        let currentSelection: string | undefined = record.loadoutDefinitionSummary === undefined ? undefined : this.ldSummaryKey(record.loadoutDefinitionSummary);
        if (loadoutDefinitions.length > 0)
        {
            return <div>
                <Select
                    defaultValue={currentSelection === undefined ? -1 : currentSelection}
                    value={currentSelection === undefined ? -1 : currentSelection}
                    className={`${styles.select}`}
                    onChange={value =>
                    {
                        let loadoutDefinition = loadoutDefinitions.find(ld => this.ldSummaryKey(ld) === value);
                        this.props.setLdSummary(loadoutDefinition, index);
                    }}>
                    <Option value={-1}>None</Option>
                    {loadoutDefinitions.map(ld => <Option value={this.ldSummaryKey(ld)}>{ld.discordTag} - {ld.title}</Option>)};
                </Select>
            </div>
        } else
        {
            let currentBase: string | undefined = this.props.loadoutDefinitionSummaryBase === undefined ? undefined : this.props.loadoutDefinitionSummaryBase.title;



            return <div>No loadout definitions.{currentBase === undefined ? '  Hotutils stat defaults will be used' : '  ' + currentBase + ' will be used.'} </div>
        }
    }

    getLoadoutDefinitionsForSquadSelection(selectedUnits: string[], mustHaveAllRequired: boolean = false)
    {

        let loadoutDefinitions = this.getValidLoadoutDefinitions().filter(lds =>
        {
            let allRequiredUnitsIncluded = mustHaveAllRequired === false || (lds.squadUnits !== null &&
                lds.squadUnits.find(su => LoadoutDefintionSummary.isSquadUnitOptions(su) === false &&
                    selectedUnits.indexOf(LoadoutDefintionSummary.squadIdToUnitId(su)) === -1) === undefined);

            return allRequiredUnitsIncluded && lds.squadUnits !== null && selectedUnits.find(ru =>
                lds.squadUnits!.find(su => LoadoutDefintionSummary.squadIdToUnitId(su) === ru) === undefined) === undefined;
        });
        return loadoutDefinitions;
    }


    onCreateNoDefitionSquad()
    {
        let squadSelection = {
            requiredUnits: this.charactersId,
            optionalUnits: [],
            selectedOptionalUnits: [],
            loadoutDefinitionSummary: undefined,
            unavailableUnits: []
        };

        this.props.onAddSquadSelection(squadSelection);
        runInAction(() =>
        {
            this.showSearchLibraryModal = false;
        });
    }

    async addSquadSelectionRow(loadoutDefinitionSummary: LoadoutDefintionSummary)
    {
        if (loadoutDefinitionSummary !== undefined)
        {
            let units = loadoutDefinitionSummary.squadUnits!.filter(su =>
                LoadoutDefintionSummary.isSquadUnitOptions(su) === false).map(su => LoadoutDefintionSummary.squadIdToUnitId(su));

            if (this.optionalUnitsSelected.length > 0)
            {
                units = units.concat(this.optionalUnitsSelected);
            }
            let squadSelection = {
                requiredUnits: units,
                optionalUnits: [],
                selectedOptionalUnits: [],
                loadoutDefinitionSummary: loadoutDefinitionSummary,
                unavailableUnits: []
            };

            this.props.onAddSquadSelection(squadSelection);
            runInAction(() =>
            {
                this.showSearchLibraryModal = false;
            });
        }
        this.forceUpdate();
    }

    renderSelectionUnits(ss: SquadSelection)
    {
        let requiredUnits = ss.requiredUnits;
        let optionUnits = ss.optionalUnits;

        let gameUnits = this.props.gameData.units!;
        let squadUnitLines: JSX.Element[] = [];

        let requiredUnitNotAvailable: boolean = false;
        let selectedRequiredUnitCount: number = 0;
        let selectedOptionalUnitCount: number = 0;
        let availableToSelectCount: number = 0;

        let requiredUnitLines = requiredUnits.map(sq =>
        {
            let gameUnit = gameUnits.find(u => u.baseId === sq)!;
            let available = ss.unavailableUnits.indexOf(sq) === -1;

            if (available === false)
            {
                requiredUnitNotAvailable = false;
            } else
            {
                selectedRequiredUnitCount = selectedRequiredUnitCount + 1;
            }

            let style = available === false ? styles.unavailablecharacter : styles.selectedcharacterportrait;

            return <UnitAvatar key={sq} className={style}
                unitData={gameUnit} size={"small"} onClick={() => null} />;
        })

        let optionalUnitLines = optionUnits.map(sq =>
        {
            let gameUnit = gameUnits.find(u => u.baseId === sq)!;
            let available = ss.unavailableUnits.indexOf(sq) === -1;

            let included = ss.selectedOptionalUnits.indexOf(sq) !== -1;

            if (available)
            {
                if (included)
                {
                    selectedOptionalUnitCount = selectedOptionalUnitCount + 1;
                } else
                {
                    availableToSelectCount = availableToSelectCount + 1;
                }
            }

            let style = available === false ? styles.unavailablecharacter : included ?
                styles.selectedcharacterportraitoptional : styles.characterportraitoptional;

            return <UnitAvatar key={sq} className={style}
                unitData={gameUnit} size={"small"} onClick={() => available ? this.props.onClickUnit(ss, sq) : null} />;
        });
        if (optionalUnitLines.length > 0)
        {
            squadUnitLines.push(<Row>
                {requiredUnitLines.length > 0 &&
                    <Col>
                        <div>
                            Core
                        </div>
                        <div className={styles.characterrow}>
                            {requiredUnitLines}
                        </div>
                    </Col>
                }
                <Col>
                    <Divider className={styles.optionaldivider} type="vertical" />
                </Col>
                <Col>
                    <div>
                        Flex
                    </div>
                    <div className={styles.characterrow}>
                        {optionalUnitLines.length > 0 && <React.Fragment>{optionalUnitLines}</React.Fragment>}
                    </div>
                </Col>
            </Row>);
        } else
        {
            squadUnitLines.push(<div className={styles.characterrow}>
                {requiredUnitLines}
                {
                    optionalUnitLines.length > 0 &&
                    <React.Fragment>
                        <Divider className={styles.optionaldivider} type="vertical" />
                        {optionalUnitLines}
                    </React.Fragment>
                }
            </div>);
        }

        let selectedUnitCount = selectedRequiredUnitCount + selectedOptionalUnitCount;

        return <div>
            {squadUnitLines}
            {
                requiredUnitNotAvailable &&
                <div className={styles.characterrow}>
                    <Alert message="Required unit not available - included in higher priority squad." type="warning" showIcon />
                </div>
            }
            {
                (selectedUnitCount < this.props.maxSize && availableToSelectCount > 0) &&
                <div className={styles.characterrow}>
                    <Alert message={'Only ' + selectedUnitCount + ' units selected.  Click optional unit to add.'} type="warning" showIcon />
                </div>
            }
            {
                (selectedUnitCount > this.props.maxSize && selectedOptionalUnitCount > 0) &&
                <div className={styles.characterrow}>
                    <Alert message={'More then ' + this.props.maxSize + ' units selected.  Click optional unit to remove.'} type="warning" showIcon />
                </div>
            }
        </div>;
    }

    renderLoadoutDefinitionRow(record: ISquadSelectionRow)
    {
        return <div>
            <div>
                <h3>{record.loadoutDefinitionSummary!.discordTag} - {record.loadoutDefinitionSummary!.title}</h3>
            </div>
            <div>
                {this.renderUnits(record.loadoutDefinitionSummary!)}
            </div>
        </div>;
    }

    renderUnits(ld: LoadoutDefintionSummary)
    {
        let gameUnits = this.props.gameData.units!;
        let squadUnitLines: JSX.Element[] = [];
        if (ld.squadUnits !== null)
        {
            let requiredUnitLines = ld.squadUnits.filter(su => LoadoutDefintionSummary.isSquadUnitOptions(su) === false).map(sq =>
            {
                let gameUnit = gameUnits.find(u => u.baseId === sq)!;
                let available = this.props.unitAvailable(sq);

                let style = available === false ? styles.unavailablecharacter : styles.selectedcharacterportrait;

                return <UnitAvatar key={sq} className={style}
                    unitData={gameUnit} size={"small"} onClick={() => null} />;
            })
            let optionalUnitLines = ld.squadUnits.filter(su => LoadoutDefintionSummary.isSquadUnitOptions(su)).map(sq =>
            {

                let unitId = LoadoutDefintionSummary.squadIdToUnitId(sq);

                let gameUnit = gameUnits.find(u => u.baseId === unitId)!;

                let available = this.props.unitAvailable(sq);

                let style = available === false ? styles.unavailablecharacter : styles.selectedcharacterportraitoptional;

                return <UnitAvatar key={unitId} className={style}
                    unitData={gameUnit} size={"small"} onClick={() => null} />;
            });

            if (optionalUnitLines.length > 0)
            {
                squadUnitLines.push(<Row>
                    {requiredUnitLines.length > 0 &&
                        <Col>
                            <div>
                                Core
                            </div>
                            <div className={styles.characterrow}>
                                {requiredUnitLines}
                            </div>
                        </Col>
                    }
                    <Col>
                        <Divider className={styles.optionaldivider} type="vertical" />
                    </Col>
                    <Col>
                        <div>
                            Flex
                        </div>
                        <div className={styles.characterrow}>
                            {optionalUnitLines.length > 0 && <React.Fragment>{optionalUnitLines}</React.Fragment>}
                        </div>
                    </Col>
                </Row>);
            } else
            {
                squadUnitLines.push(<div className={styles.characterrow}>
                    {requiredUnitLines}  {optionalUnitLines.length > 0 && <React.Fragment><Divider className={styles.optionaldivider} type="vertical" /> {optionalUnitLines}</React.Fragment>}
                </div>);
            }

        }

        return <React.Fragment>
            {ld.squadUnits !== null &&
                squadUnitLines
            }
        </React.Fragment>;
    }

    getTableData(): ISquadSelectionRow[]
    {

        let retVal: ISquadSelectionRow[] = [];

        let loadoutDefinitions = this.getLoadoutDefinitions();

        return retVal.concat(loadoutDefinitions);
    }

    getValidLoadoutDefinitions()
    {
        let retVal: LoadoutDefintionSummary[] = [];

        this.props.existingLoadoutDefinitionGroups.forEach(eg =>
        {
            eg.definitions.filter(d =>
            {
                let hasCategory = this.props.loadoutDefinitionCategoriesFilter.length === 0 ||
                    d.categories.find(c => this.props.loadoutDefinitionCategoriesFilter.indexOf(c) !== -1) !== undefined;

                return hasCategory;
            }).forEach(d =>
            {
                let existingLoadoutDefintions = retVal.filter(ld => ld.title === d.title
                    && ld.discordId === d.discordId);

                if (existingLoadoutDefintions.length === 0)
                {
                    retVal.push(d);
                }
            });
        });

        return retVal;
    }

    getLoadoutDefintionsWithUnits()
    {
        let retVal: ISquadSelectionRow[] = [];

        let usedUnits: string[] = [];
        this.props.squadSelections.forEach(ss =>
        {
            ss.requiredUnits.filter(u => usedUnits.indexOf(u) === -1).forEach(u =>
            {
                usedUnits.push(u);
            });
            ss.selectedOptionalUnits.filter(u => usedUnits.indexOf(u) === -1).forEach(u =>
            {
                usedUnits.push(u);
            });
        });

        this.props.existingLoadoutDefinitionGroups.forEach(eg =>
        {
            eg.definitions.filter(d =>
            {
                let hasCategory = this.props.loadoutDefinitionCategoriesFilter.length === 0 ||
                    d.categories.find(c => this.props.loadoutDefinitionCategoriesFilter.indexOf(c) !== -1) !== undefined;

                return this.definitionHasSelectedUnits(d) && hasCategory;
            }).forEach(d =>
            {
                let existingLoadoutDefintions = retVal.filter(ld => ld.loadoutDefinitionSummary!.title === d.title
                    && ld.loadoutDefinitionSummary!.discordId === d.discordId);

                if (existingLoadoutDefintions.length === 0)
                {
                    let requiredUnits: string[] = d.squadUnits!.filter(su => LoadoutDefintionSummary.isSquadUnitOptions(su) === false).map(su => LoadoutDefintionSummary.squadIdToUnitId(su));
                    let optionalUnits: string[] = d.squadUnits!.filter(su => LoadoutDefintionSummary.isSquadUnitOptions(su)).map(su => LoadoutDefintionSummary.squadIdToUnitId(su));
                    let fullUnitList = requiredUnits.concat(optionalUnits);


                    retVal.push({
                        selectionType: SquadSelectionTypeEnum.LoadoutDefinition,
                        loadoutDefinitionSummary: d,
                        loadoutDefinitionGroup: eg,
                        validRow: requiredUnits.find(ru => usedUnits.indexOf(ru) !== -1) === undefined,
                        availableUnits: fullUnitList.filter(u => usedUnits.indexOf(u) === -1)
                    });
                }
            });
        });

        return retVal;
    }

    private definitionHasSelectedUnits(d: LoadoutDefintionSummary): unknown
    {
        return d.squadUnits !== null && this.charactersId.find(unitId => d.squadUnits!.map(suId => LoadoutDefintionSummary.squadIdToUnitId(suId)).indexOf(unitId) === -1) === undefined;
    }

    getLoadoutDefinitions()
    {
        let retVal: ISquadSelectionRow[] = [];

        let stCaps = this.searchText.toUpperCase();

        let usedUnits: string[] = [];
        this.props.squadSelections.forEach(ss =>
        {
            ss.requiredUnits.filter(u => usedUnits.indexOf(u) === -1).forEach(u =>
            {
                usedUnits.push(u);
            });
            ss.selectedOptionalUnits.filter(u => usedUnits.indexOf(u) === -1).forEach(u =>
            {
                usedUnits.push(u);
            });
        });

        this.props.existingLoadoutDefinitionGroups.forEach(eg =>
        {
            eg.definitions.filter(d =>
            {
                // let userLoadoutInSharedSection = d.discordTag === this.props.user.currentPlayer!.discordTag &&
                //     eg.shareType !== 0;
                let definitionHasSelected = this.definitionHasSelectedUnits(d);
                let ldCats = this.props.loadoutDefinitionCategoriesFilter.slice(0).concat(this.tierCategories);

                let hasCategory = this.props.loadoutDefinitionCategoriesFilter.length === 0 ||
                    d.categories.find(c => this.props.loadoutDefinitionCategoriesFilter.indexOf(c) !== -1) !== undefined;

                let hasTierCategory = ldCats.find(c => LD_CATEGORY_TIERS.indexOf(c) !== -1) !== undefined;
                let matchedTierCategory = d.categories.find(c => LD_CATEGORY_TIERS.indexOf(c) !== -1 && ldCats.indexOf(c) !== -1) !== undefined;

                let matchTier = hasTierCategory === false || matchedTierCategory;

                let tooManyUnits = d.squadUnits === null;

                let titleMatchSearch = d.title.toUpperCase().indexOf(stCaps) !== -1;
                let descriptionMatchSearch = (d.description !== undefined && d.description.toUpperCase().indexOf(stCaps) !== -1);
                let groupNameMatchSearch = eg.groupName.toUpperCase().indexOf(stCaps) !== -1;
                let discordMatchSearch = d.discordTag.toUpperCase().indexOf(stCaps) !== -1;

                let unitMatchSearch = false;
                if (d.squadUnits !== null)
                {
                    d.squadUnits.forEach(suId =>
                    {
                        let unitId = LoadoutDefintionSummary.squadIdToUnitId(suId);
                        let gameUnit = this.props.gameData.units!.find(gu => gu.baseId === unitId);
                        if (gameUnit !== undefined)
                        {
                            unitMatchSearch = unitMatchSearch || gameUnit.name.toUpperCase().indexOf(stCaps) !== -1;
                            if (gameUnit.abbreviations !== null)
                            {
                                unitMatchSearch = unitMatchSearch || gameUnit.abbreviations.find(abb => abb.toUpperCase().indexOf(stCaps) !== -1) !== undefined;
                            }
                        }
                    });
                }

                return definitionHasSelected && matchTier && hasCategory && tooManyUnits === false &&
                    (titleMatchSearch || descriptionMatchSearch || groupNameMatchSearch || discordMatchSearch || unitMatchSearch);

            }).forEach(d =>
            {
                let existingLoadoutDefintions = retVal.filter(ld => ld.loadoutDefinitionSummary!.title === d.title
                    && ld.loadoutDefinitionSummary!.discordId === d.discordId);

                let requiredUnits: string[] = d.squadUnits!.filter(su => LoadoutDefintionSummary.isSquadUnitOptions(su) === false).map(su => LoadoutDefintionSummary.squadIdToUnitId(su));
                let optionalUnits: string[] = d.squadUnits!.filter(su => LoadoutDefintionSummary.isSquadUnitOptions(su)).map(su => LoadoutDefintionSummary.squadIdToUnitId(su));
                let fullUnitList = requiredUnits.concat(optionalUnits);


                if (existingLoadoutDefintions.length === 0)
                {
                    retVal.push({
                        selectionType: SquadSelectionTypeEnum.LoadoutDefinition,
                        loadoutDefinitionSummary: d,
                        loadoutDefinitionGroup: eg,
                        validRow: requiredUnits.find(ru => usedUnits.indexOf(ru) !== -1) === undefined,
                        availableUnits: fullUnitList.filter(u => usedUnits.indexOf(u) === -1)
                    });
                }
            });
        })

        return retVal;
    }
    @action
    clickCategory(category: string)
    {
        if (this.tierCategories.indexOf(category) === -1)
        {
            this.tierCategories.push(category);
        } else
        {
            this.tierCategories = this.tierCategories.filter(c => c !== category);
        }
    }


    renderRequiredUnits(ss: LoadoutDefintionSummary)
    {
        let requiredUnits = ss.squadUnits!.filter(su => LoadoutDefintionSummary.isSquadUnitOptions(su) === false).map(su => LoadoutDefintionSummary.squadIdToUnitId(su));

        let gameUnits = this.props.gameData.units!;
        let squadUnitLines: JSX.Element[] = [];
        let selectedRequiredUnitCount: number = 0;

        let requiredUnitLines = requiredUnits.map(sq =>
        {
            let gameUnit = gameUnits.find(u => u.baseId === sq)!;
            selectedRequiredUnitCount = selectedRequiredUnitCount + 1;
            let style = styles.selectedcharacterportrait;
            return <UnitAvatar key={sq} className={style}
                unitData={gameUnit} size={"small"} onClick={() => null} />;
        })

        squadUnitLines.push(<div className={styles.characterrow}>
            {requiredUnitLines}
        </div>);

        return <div>
            {squadUnitLines}
        </div>;
    }

    @action
    clickOptionalUnits(unitId: string)
    {
        let included = this.optionalUnitsSelected.indexOf(unitId) !== -1;
        if (included)
        {
            this.optionalUnitsSelected = this.optionalUnitsSelected.filter(uid => uid !== unitId);
        } else
        {
            this.optionalUnitsSelected.push(unitId);
        }
    }

    renderOptionalUnits(ss: LoadoutDefintionSummary)
    {
        let optionUnits = ss.squadUnits!.filter(su => LoadoutDefintionSummary.isSquadUnitOptions(su)).map(su => LoadoutDefintionSummary.squadIdToUnitId(su));
        let requiredUnits = ss.squadUnits!.filter(su => LoadoutDefintionSummary.isSquadUnitOptions(su) === false).map(su => LoadoutDefintionSummary.squadIdToUnitId(su));

        let gameUnits = this.props.gameData.units!;
        let squadUnitLines: JSX.Element[] = [];

        let selectedRequiredUnitCount: number = requiredUnits.length;
        let selectedOptionalUnitCount: number = 0;
        let availableToSelectCount: number = 0;

        let usedUnits: string[] = [];

        this.props.squadSelections.forEach(ss =>
        {
            usedUnits = usedUnits.concat(ss.requiredUnits).concat(ss.selectedOptionalUnits);
        })

        let optionalUnitLines = optionUnits.map(sq =>
        {
            let gameUnit = gameUnits.find(u => u.baseId === sq)!;
            let available = usedUnits.indexOf(sq) === -1;

            let included = this.optionalUnitsSelected.indexOf(sq) !== -1;

            if (available)
            {
                if (included)
                {
                    selectedOptionalUnitCount = selectedOptionalUnitCount + 1;
                } else
                {
                    availableToSelectCount = availableToSelectCount + 1;
                }
            }

            let style = available === false ? styles.unavailablecharacter : included ?
                styles.selectedcharacterportraitoptional : styles.characterportraitoptional;

            return <UnitAvatar key={sq} className={style}
                unitData={gameUnit} size={"small"} onClick={() => available ? this.clickOptionalUnits(sq) : null} />;
        });

        squadUnitLines.push(<Row>
            <div className={styles.characterrow}>
                {optionalUnitLines.length > 0 && <React.Fragment>{optionalUnitLines}</React.Fragment>}
            </div>
        </Row>);

        let selectedUnitCount = selectedRequiredUnitCount + selectedOptionalUnitCount;

        return <div>
            {squadUnitLines}
            {
                (selectedUnitCount < this.props.maxSize && availableToSelectCount > 0) &&
                <div className={styles.characterrow}>
                    <Alert message={'Only ' + selectedUnitCount + ' units selected.  Click optional unit to add.'} type="warning" showIcon />
                </div>
            }
            {
                (selectedUnitCount > this.props.maxSize && selectedOptionalUnitCount > 0) &&
                <div className={styles.characterrow}>
                    <Alert message={'More then ' + this.props.maxSize + ' units selected.  Click optional unit to remove.'} type="warning" showIcon />
                </div>
            }
        </div>;
    }

    renderSelectOptionalModal()
    {
        return <Modal title="Select Optional" visible={this.showSelectOptionalModal && this.ldsOptional !== undefined}
            maskClosable={false}
            width={1000}
            footer={[
                <Button key="1" onClick={action(() => this.showSelectOptionalModal = false)}>Cancel</Button>,
                <Button key="2" onClick={action(() => { this.addSquadSelectionRow(this.ldsOptional!); this.showSelectOptionalModal = false })}>Create Squad</Button>
            ]}
            onCancel={action(() => this.showSelectOptionalModal = false)}
            okButtonProps={{ disabled: true, style: { display: 'none' } }}>

            {this.ldsOptional !== undefined && <React.Fragment>
                <h2>Select Optional Units</h2>

                {this.ldsOptional.description !== undefined && this.ldsOptional.description.trim().length > 0 &&
                    <div className={styles.paddedrow}>
                        {this.ldsOptional.description.replaceAll("<br>", "\n")}
                    </div>
                }

                <div className={styles.paddedrow}>
                    Required Units:
                </div>

                <div className={styles.paddedunitrow}>
                    {this.renderRequiredUnits(this.ldsOptional!)}
                </div>

                <div className={styles.paddedrow}>
                    Optional Units (click to add\remove):
                </div>
                <div className={styles.paddedunitrow}>
                    {this.renderOptionalUnits(this.ldsOptional!)}
                </div>
            </React.Fragment>}
        </Modal>;
    }

    renderSearchLibraryModal()
    {
        const units = this.props.gameData.units || [];

        const catlist = LD_CATEGORY_TIERS.slice(0);
        const tableData = this.getTableData().filter(sr => this.hideUnavailable === false || sr.validRow);
        const loadoutDefinitionMatchingUnits = this.getLoadoutDefintionsWithUnits().filter(sr => this.hideUnavailable === false || sr.validRow);

        const squadRows = tableData.map(record =>

            <div className={record.validRow ? '' : styles.tablerowdark}>
                <div>

                    {this.renderLoadoutDefinitionRow(record)}

                </div>
                {record.validRow &&
                    <div className={styles.paddedrow}>
                        <Button onClick={() => this.selectSquadSelectionRow(record)}>Select</Button>
                    </div>
                }
                <Divider type="horizontal" />
            </div>);

        const categories = catlist.map(category =>
        {
            return <Button className={styles.categorybutton} type={this.tierCategories.indexOf(category) !== -1 ? "primary" : undefined}
                size={"small"} key={category} onClick={() => this.clickCategory(category)} >{category.replaceAll("Tier", "")}</Button>;
        });

        return <Modal title="Add Squad" visible={this.showSearchLibraryModal}
            maskClosable={false}
            width={1000}
            footer={[
                <Button key="1" onClick={action(() => this.showSearchLibraryModal = false)}>Cancel</Button>,
                <Button key="2" disabled={this.charactersId.length === 0} onClick={() => this.onCreateNoDefitionSquad()}>Add Squad</Button>
            ]}
            onCancel={action(() => this.showSearchLibraryModal = false)}
            okButtonProps={{ disabled: true, style: { display: 'none' } }}>
            <div className={styles.modalcontent}>
                <h3>Add Units</h3>
                <Select
                    className={`${styles.select}`}
                    placeholder={'Select a unit'}
                    autoFocus={true}
                    showSearch={true}
                    filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    mode={"multiple"}
                    value={Array.isArray(this.charactersId) ? this.charactersId : [this.charactersId]}
                    onChange={value =>
                    {
                        runInAction(() =>
                        {
                            this.charactersId = value;
                        })
                    }}
                    showArrow={true}>
                    {units.filter(unit =>
                    {
                        return unit.combatType === 1
                    }).map(unit =>
                    {
                        return (
                            <Select.Option key={unit.baseId} value={unit.baseId}>{unit.name}</Select.Option>
                        );
                    })}
                </Select>


                <div className={styles.paddedrow}>
                    <UiSwitch defaultChecked={this.hideUnavailable}
                        onChange={action((checked) => this.hideUnavailable = checked)} /> Hide unavailable (required units already added in another squad)
                </div>

                {loadoutDefinitionMatchingUnits.length > 0 ?
                    <React.Fragment>
                        <div className={styles.paddedrow}>
                            <h3>Select Loadout Defintion for better results</h3>
                        </div>

                        <div className={styles.paddedrow}>
                            {categories}
                        </div>

                        <div className={styles.addsquadcontainer}>
                            {/* <Table
                            pagination={{ pageSize: 5 }}
                            rowClassName={(record, index) => record.validRow ? '' : styles.tablerowdark}
                            columns={this.searchColumns}
                            dataSource={tableData} /> */}
                            <div>
                                {squadRows}
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className={styles.paddedrow}>
                            No loadout definitions current exist for this team.  Default optimization will be used.
                        </div>
                    </React.Fragment>
                }
            </div>
        </Modal>
    }

    onAddPlayerSquad(squadId: any)
    {
        if (squadId === -1 && this.props.playerSquadTemplates !== null)
        {
            this.getApplicableSquads().forEach(pst =>
            {
                let loadoutDefinitions = this.getLoadoutDefinitionsForSquadSelection(pst.units.map(u => u.characterId), true);

                let squadSelection = {
                    requiredUnits: pst.units.filter(u =>
                    {
                        let gameUnit = this.props.gameData.units!.find(gu => gu.baseId === u.characterId);
                        return gameUnit !== undefined && gameUnit.combatType === 1;
                    }).map(u => u.characterId),
                    optionalUnits: [],
                    selectedOptionalUnits: [],
                    loadoutDefinitionSummary: loadoutDefinitions.length > 0 ? loadoutDefinitions[0] : undefined,
                    unavailableUnits: []
                };

                this.props.onAddSquadSelection(squadSelection);
            });
        }
        if (squadId !== undefined)
        {
            let squad = this.props.playerSquadTemplates === null ? undefined : this.props.playerSquadTemplates.find(pst => pst.id === squadId);

            if (squad !== undefined)
            {
                let loadoutDefinitions = this.getLoadoutDefinitionsForSquadSelection(squad.units.map(u => u.characterId), true);

                let squadSelection = {
                    requiredUnits: squad.units.filter(u =>
                    {
                        let gameUnit = this.props.gameData.units!.find(gu => gu.baseId === u.characterId);
                        return gameUnit !== undefined && gameUnit.combatType === 1;
                    }).map(u => u.characterId),
                    optionalUnits: [],
                    selectedOptionalUnits: [],
                    loadoutDefinitionSummary: loadoutDefinitions.length > 0 ? loadoutDefinitions[0] : undefined,
                    unavailableUnits: []
                };

                this.props.onAddSquadSelection(squadSelection);
            }
        }
    }

    getApplicableSquads()
    {
        return this.props.playerSquadTemplates === null ? [] : this.props.playerSquadTemplates.filter(squad =>
        {
            // let loadoutDefinitions = this.getLoadoutDefinitionsForSquadSelection(squad.units.map(u => u.characterId));
            // let meetsLd = this.props.fullRoster === false || loadoutDefinitions.length > 0;
            let correctSize = squad.units.length <= this.props.maxSize;
            let hasCategory = this.props.squadsCategoryFilter.find(filter => squad.category.indexOf(filter) !== -1) !== undefined;

            return hasCategory && correctSize;

        });
    }

    async onClickRemoveAll()
    {
        let confirm = await confirmationDialog("Confirm remove all", "Are you sure you want to remove all squads?");
        if (!confirm)
            return;

        this.props.removeAllSquads();
    }

    missingDefintions()
    {
        return false;
    }

    renderSelectedSquads()
    {
        const squadSelections = this.props.squadSelections.slice().map((ss, index) =>
        {
            return <div key={index} className={styles.selectedsquadrow}>
                <Row className={styles.unitsrow}>
                    {this.renderSelectionUnits(ss)}
                </Row>

                <Row className={styles.unitsrow}>
                    {this.renderLoadoutDefinitionSelection(ss, index)}
                </Row>

                <Row className={styles.unitsrow}>

                    <Button
                        className={styles.squadbutton}
                        disabled={index === 0}
                        onClick={() => this.props.onMoveSquadUp(index)}
                        icon={<FontAwesomeIcon icon={faCaretUp} className={styles.icon} />} />
                    <Button
                        className={styles.squadbutton}
                        disabled={index >= this.props.squadSelections.length - 1}
                        onClick={() => this.props.onMoveSquadDown(index)}
                        icon={<FontAwesomeIcon icon={faCaretDown} className={styles.icon} />} />

                    <Button
                        className={styles.squadbutton}
                        onClick={() => this.props.deleteSquadSelection(index)}
                        icon={<FontAwesomeIcon icon={faTrash} className={styles.icon} />} />
                </Row>
                <Divider type="horizontal" />
            </div>;
        });
        return squadSelections;
    }


    render()
    {
        const squads = this.getApplicableSquads();

        let baseLoadoutDefinitions: LoadoutDefintionSummary[] = [];
        let currentBase: string | undefined = this.props.loadoutDefinitionSummaryBase === undefined ? undefined : this.ldSummaryKey(this.props.loadoutDefinitionSummaryBase);

        this.props.existingLoadoutDefinitionGroups.forEach(eg =>
        {
            eg.definitions.filter(d =>
            {
                let hasCategory = this.props.loadoutDefinitionCategoriesFilter.length === 0 ||
                    d.categories.find(c => this.props.loadoutDefinitionCategoriesFilter.indexOf(c) !== -1) !== undefined ||
                    d.categories.length === 0;
                return d.targetCount > 80 && hasCategory;
            }).forEach(lds =>
            {
                if (baseLoadoutDefinitions.find(e => e.discordTag === lds.discordTag && e.title === lds.title) === undefined)
                    baseLoadoutDefinitions.push(lds);
            });
        });

        return (
            <React.Fragment>
                {this.renderSearchLibraryModal()}

                {this.renderSelectOptionalModal()}


                <div className={styles.paddedrow}>
                    <h4>
                        Select base automation settings for units not with loadout definitions. Not required (HotUtils base stat weights will be used).
                    </h4>
                </div>

                <div className={styles.paddedrow}>
                    <div>
                        <Select
                            className={`${styles.selectsquad}`}
                            placeholder={'select base'}
                            autoFocus={true}
                            value={currentBase === undefined ? "-1" : currentBase}
                            onChange={value =>
                            {
                                let lds: LoadoutDefintionSummary | undefined = undefined;
                                if (value !== "-1")
                                {
                                    lds = baseLoadoutDefinitions.find(d => value === this.ldSummaryKey(d));
                                }
                                this.props.setLoadoutDefinitionBase(lds);
                            }}
                            showArrow={true}>
                            <Select.Option key={"-1"} value={"-1"}>No base</Select.Option>
                            {baseLoadoutDefinitions.map(lds =>
                            {
                                return (
                                    <Select.Option key={this.ldSummaryKey(lds)} value={this.ldSummaryKey(lds)}>{lds.discordTag} - {lds.title}</Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>



                {
                    this.props.fullRoster &&
                    <div className={styles.paddedrow}>
                        <UiSwitch defaultChecked={this.props.showSquadSelector}
                            onChange={action((checked) => this.props.setShowSquadSelector(checked))} /> Add specific squad templates
                    </div>
                }

                {
                    this.props.showSquadSelector &&
                    <React.Fragment>

                        <div className={styles.paddedrow}>
                            <ul>
                                <li>Create your squads ahead of time in the Squads component and add categories to make the wizard easier to use.</li>
                                <li>Choose loadout definitions if they exist, they can improve results by using specific modding targets for that squad (turn order, etc).  Other squads will use unit defaults.</li>
                            </ul>
                        </div>

                        <React.Fragment>
                            {squads.length > 0 &&
                                <div className={styles.paddedrow}>
                                    <Select
                                        className={`${styles.selectsquad}`}
                                        placeholder={'add my pre-existing squads'}
                                        autoFocus={true}
                                        showSearch={true}
                                        filterOption={(input, option) =>
                                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={undefined}
                                        onChange={value =>
                                        {
                                            this.onAddPlayerSquad(value);
                                        }}
                                        showArrow={true}>
                                        <Select.Option key={-1} value={-1}>All Add Squads</Select.Option>
                                        {squads.map(squad =>
                                        {
                                            let loadoutDefinitions = this.getLoadoutDefinitionsForSquadSelection(squad.units.map(u => u.characterId));

                                            if (loadoutDefinitions.length > 0)
                                            {
                                                return (
                                                    <Select.Option key={squad.id} value={squad.id}>* {squad.name}</Select.Option>
                                                );
                                            } else
                                            {
                                                return (
                                                    <Select.Option key={squad.id} value={squad.id}>{squad.name}</Select.Option>
                                                );
                                            }
                                        })}
                                    </Select>
                                </div>
                            }
                            <div className={styles.paddedrow}>
                                <Button className={styles.squadbutton} onClick={action(() =>
                                {
                                    this.charactersId = [];
                                    this.showSearchLibraryModal = true;
                                })}>Add Squad</Button>

                                <Button className={styles.squadbutton} onClick={() => this.onClickRemoveAll()}>Remove All</Button>
                            </div>
                        </React.Fragment>

                        <div className={styles.selectedsquadscontainer}>

                            {this.renderSelectedSquads()}

                        </div>
                    </React.Fragment>
                }

            </React.Fragment >
        );
    }

}

export default ModWizardSquadManager;
