import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

interface IProps
{
    wikiId: string;
    showHelpText: boolean;
}

export const WIKI_PLAYGROUND = "playground";
export const WIKI_PLAYGROUND_SETS_ID = "playground/ld-sets";
export const WIKI_PLAYGROUND_FAQ = "playground/playground-faq";
export const WIKI_PLAYGROUND_LOADOUT_DEFINITIONS = "playground/loadout-definitions";
export const WIKI_PLAYGROUND_MAIN = "playground/playground-main";
export const WIKI_PLAYGROUND_UNIT_FILTERING = "playground/unit-filtering";
export const WIKI_PLAYGROUND_SAVE = "playground/playground-save";
export const WIKI_PLAYGROUND_LOAD = "playground/playground-load";
export const WIKI_PLAYGROUND_IMAGE = "playground/playground-image";
export const WIKI_PLAYGROUND_MOD_UNITS = "playground/mod-units";
export const WIKI_PLAYGROUND_SAVE_LOADOUT_DEFINITION = "playground/saving-loadout-definitions";
export const WIKI_PLAYGROUND_SHARE_LOADOUT_DEFINITION = "playground/sharing-loadout-definitions";
export const WIKI_PLAYGROUND_IMPORT_LOADOUT_DEFINITION = "playground/importing-loadout-definitions";
export const WIKI_PLAYGROUND_STAT_WEIGHTS = "playground/ld-stat-weights";
export const WIKI_PLAYGROUND_STAT_TARGETS = "playground/ld-stat-targets";
export const WIKI_PLAYGROUND_PRIMARIES = "playground/ld-primaries";
export const WIKI_PLAYGROUND_SETS = "playground/ld-sets";
export const WIKI_PLAYGROUND_PRIORITIZATION = "playground/unit-prioritization";
export const WIKI_PLAYGROUND_AUTOMATION_SETTINGS = "playground/automation-settings-2";
export const WIKI_PLAYGROUND_MANUAL_MODING = "playground/manual-unit-moding";
export const WIKI_PLAYGROUND_CONVERT_TEMPLATE = "playground/convert-template";

@observer
class WikiHelp extends React.Component<IProps>
{
    render()
    {
        let url = "https://help.hotutils.com/support/?docs=" + this.props.wikiId;
        return (
            <Button>
                <a href={url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faQuestionCircle} />
                    {this.props.showHelpText && <React.Fragment>Help</React.Fragment>}
                </a>
            </Button>
        );
    }
}

export default WikiHelp;
