

export class GuildWatchGuild
{
    id: string;
    name: string;
    galacticPower: number;
    leaderAllyCode: number;
    leaderName: string;
    leaderDiscordTag: string | null = null;
    canRemove: boolean;

    constructor(json: any)
    {
        this.id = json.id;
        this.name = json.name;
        this.galacticPower = json.galacticPower;
        this.leaderAllyCode = json.leaderAllyCode;
        this.leaderName = json.leaderName;
        if (json.leaderDiscordTag) this.leaderDiscordTag = json.leaderDiscordTag;
        this.canRemove = json.canRemove;
    }
}

export class GuildWatch
{
    enabled: boolean;
    guilds: GuildWatchGuild[] | null = null;

    constructor(json: any)
    {
        this.enabled = json.enabled;
        if (json.guilds) this.guilds = json.guilds.map((g: any) => new GuildWatchGuild(g));
    }
}