import React from "react";
import IPageProps from "../../../IPageProps";
import {observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faRoute, faSpinner,
} from "@fortawesome/pro-duotone-svg-icons";
import {ITerritoryWarsData, zonesTW} from "../../../../model/TWPlanning";
import {IGuildPlayer} from "../../../../model/GuildData";
import styles from "../styles/Templates.module.scss";
import UnitAvatar from "../../../../components/swgoh/Character/UnitAvatar";
import UnitData from "../../../../model/UnitData";
import { Button } from "antd";

interface ITWCardCompactRowProps extends IPageProps {
    template: ITerritoryWarsData;
    members: IGuildPlayer[];
    showSquadModal: (squadLeader: string) => void;
    isAdmin: boolean;
    zone: zonesTW;
}

@observer
export default class TWCardCompactRow extends React.PureComponent<ITWCardCompactRowProps> {

    render() {
        let unitLeader : string[] = [];
        const squads = this.props.template.squads.filter(x => x.zoneId === this.props.zone.id);
        for (let i = 0; i < squads.length; i++) {
            const squad = squads[i];
            if(squad.units[0].preferredUnit !== undefined){
                unitLeader.push(squad.units[0].preferredUnit)
            } else {
                unitLeader.push(squad.units[0].characterId);
            }
        }

        const uniqueUnitLeader = Array.from(new Set(unitLeader));
        let unitsData: UnitData[] = [];

        uniqueUnitLeader.forEach(unit => {
            const unitData = this.props.gameData.getUnit(unit);

            if (unitData) {
                return unitsData.push(unitData);
            }
        });

        return unitsData.map(unit => {
            if (unitsData.length === 0) {
                return <FontAwesomeIcon
                    key={unit.baseId}
                    icon={faSpinner} pulse={true}
                    spin={true}
                />;
            }

            return <div key={unit.baseId + '-' + this.props.zone.id} className={`tb-card-border ${styles['card-row']}`}>
                <div className={`${styles.units} ${styles.rows} ${styles.leader}`}>
                    <div key={unit.baseId} className={`${styles.unit} ${unit.combatType === 2 ? styles.shipAvatar : styles.avatar}`}>
                        <UnitAvatar
                            size={"tiny"}
                            displayName={false}
                            unitData={unit}
                            key={unit.baseId}
                            className={`${styles['unitAvatar-player']} ${this.props.zone.combatType === 'Ship' ? styles.ship : ''}`}
                        />
                    </div>
                    <div className={styles.name}>
                        {unit.name} x{unitLeader.filter(x => x === unit.baseId).length}
                    </div>
                    <div className={styles.btn}>
                        {this.props.isAdmin && <Button
							type={"text"}
							title={'Change zone for this squad'}
							className={`${styles['player-edit']} `}
							onClick={() => this.props.showSquadModal(unit.baseId)}
						>
							<FontAwesomeIcon icon={faRoute}/>
						</Button>}
                    </div>
                </div>
            </div>;
        })
    }
}