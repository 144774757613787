import React from "react";
import IPageProps from "../../../IPageProps";
import {observer} from "mobx-react";
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPersonToPortal,
    faSpinner, faTrashAlt,
} from "@fortawesome/pro-duotone-svg-icons";
import {observable} from "mobx";
import {IDuplicateUnits, ITerritoryWarsData, ITWFilledSquadsInGame, ITWZonesInGame, squadsTW, zonesTW} from "../../../../model/TWPlanning";
import {IGuildPlayer, IGuildPlayerRosterUnit} from "../../../../model/GuildData";
import styles from "../styles/Templates.module.scss";
import UnitAvatar from "../../../../components/swgoh/Character/UnitAvatar";
import {IBasicUnitData} from "../../../../model/UnitData";
import {readableNumber} from "../../../../utils/readableTime";

interface ITWCardRowProps extends IPageProps {
    template: ITerritoryWarsData;
    members: IGuildPlayer[];
    isAdmin: boolean;
    onDelete: (squad: squadsTW) => void;
    showSquadModal: (squadTW: squadsTW) => void;
    zone: zonesTW;
    showUnitNames: boolean;
    showAllSquads: boolean;
    filterPlayers: string;
    showRemainingSquads: boolean;
    filledSquadsInTW: ITWZonesInGame[];
    duplicateUnits: IDuplicateUnits[];
    sortOrder: number;
}

@observer
export default class TWCardRow extends React.PureComponent<ITWCardRowProps> {
    @observable unassignedCharacters: number = 0;

    private getPlayerUnitAvatar(playerUnit: IGuildPlayerRosterUnit): IBasicUnitData {
        return {
            baseId: playerUnit.baseId,
            gearLevel: playerUnit.gear.level,
            level: playerUnit.level,
            rarity: playerUnit.stars,
            relicLevel: playerUnit.relicLevel,
            ultimate: playerUnit.ultimate,
            zetaCount: playerUnit.zetaCount,
            zetaLead: playerUnit.zetaLead,
            omiCount: playerUnit.omiCount
        }
    }

    private getPowerGPSquad(squad: squadsTW, player: IGuildPlayer | undefined){
        let powerGPSquad = 0;

        if(player) {
            for (let i = 0; i < squad.units.length; i++) {
                const unit = squad.units[i];
                const unitData = unit.preferredUnit ? unit.preferredUnit : unit.characterId;

                powerGPSquad += player.roster?.find(x => x.baseId === unitData)?.power.total ?? 0;
            }
        }

        return powerGPSquad;
    }

    private getRowTopCard(squad: squadsTW, player: IGuildPlayer | undefined, isUndefined: boolean) {

        return <div className={`tb-card-bottom-border ${styles.top}`}>
            <div className={`${isUndefined ? styles.undefined : ''} ${styles.player} ${this.props.template.preferredPlayers?.includes(squad.allyCode) ? styles.preferred : ''}`}>
                {this.props.isAdmin && !isUndefined && <Button
					type={"text"}
					title={'Move this squad in another zone'}
					className={`${styles['player-edit']} `}
					onClick={() => this.props.showSquadModal(squad)}
				>
					<FontAwesomeIcon icon={faPersonToPortal}/>
				</Button>}
                {squad.playerName} - {readableNumber(this.getPowerGPSquad(squad, player))}
            </div>
            <div className={`tb-card-left-border ${styles.actions}`}>
                {this.props.isAdmin && <Button
					type={"text"}
					title={'Delete squad'}
					className={`${styles.delete}`}
					danger={true}
					onClick={() => {
                        this.props.onDelete(squad);
                    }}
				>
					<FontAwesomeIcon icon={faTrashAlt} className={styles.icon}/>
				</Button>}
            </div>
        </div>
    }

    private getSortOrder(recordA: squadsTW, recordB: squadsTW) {
        const playerA = this.props.members.find(x => x.allyCode === recordA.allyCode);
        const playerB = this.props.members.find(x => x.allyCode === recordB.allyCode);

        switch (this.props.sortOrder) {
            case 1:
                return recordB.playerName.localeCompare(recordA.playerName);
            case 2:
                return this.getPowerGPSquad(recordA, playerA) - this.getPowerGPSquad(recordB, playerB);
            case 3:
                return this.getPowerGPSquad(recordB, playerB) - this.getPowerGPSquad(recordA, playerA);
            case 0:
            default:
                return recordA.playerName.localeCompare(recordB.playerName);
        }
    }

    private getFilledZones(zoneId: number) {
        const filledZone = this.props.filledSquadsInTW;

        if (zoneId === 5 || zoneId === 8) {
            return filledZone.find(x => x.index === zoneId && x.combatType === 2);
        } else {
            return filledZone.find(x => x.index === zoneId && x.combatType === 1);
        }
    }

    private getNewFilledUnits(playerUnit: IGuildPlayerRosterUnit, newUnitsFilled: ITWFilledSquadsInGame, squad: squadsTW) {
        const filledUnits = newUnitsFilled.units.map(x => x.baseId);
        const requiredUnits = squad.units.map(x => x.preferredUnit ? x.preferredUnit : x.characterId);
        const filledNewUnits = filledUnits.filter(val => !requiredUnits.includes(val));
        const filledOldUnits = requiredUnits.filter(val => !filledUnits.includes(val));

        const newUnit = filledNewUnits[filledOldUnits.indexOf(playerUnit.baseId)];
        const player = this.props.members.find(x => x.allyCode === squad.allyCode)?.roster?.find(x => x.baseId === newUnit);
        const unitData = this.props.gameData.getUnit(newUnit);

        if (unitData && playerUnit && player) {
            return <div key={playerUnit.baseId + newUnit} className={`${styles.unit} ${styles['filled-new-unit']}`}>
                <UnitAvatar
                    size={"small"}
                    displayName={false}
                    playerUnit={this.getPlayerUnitAvatar(player)}
                    unitData={unitData}
                    key={unitData.baseId}
                    className={`${styles['unitAvatar-player']} ${this.props.zone.combatType === 'Ship' ? styles.ship : ''}`}
                />
            </div>
        }
    }

    render() {
        return (this.props.template.squads
            .filter(x => x.zoneId === this.props.zone.id && (!this.props.showAllSquads || x.allyCode === Number(this.props.user.currentPlayer?.allyCode)) && (this.props.showAllSquads || x.playerName.toLocaleLowerCase().includes(this.props.filterPlayers.toLocaleLowerCase())))
            .sort((a, b) => this.getSortOrder(a, b))
            .map(squad => {
                if (squad.units.length === 0) {
                    return null;
                }
                const unitLeader = squad.units[0].preferredUnit ? squad.units[0].preferredUnit : squad.units[0].characterId;
                const filledZonePlayer = this.getFilledZones(this.props.zone.index)?.squads.filter(x => x.playerAllyCode === squad.allyCode);
                const filledZoneSquad = filledZonePlayer?.find(x => x.units[0].baseId === unitLeader);
                const player = this.props.members.find(x => x.allyCode === squad.allyCode);

                if (!player) {
                    return <div key={squad.allyCode + '-' + this.props.zone.id + '-' + squad.squadTypeId} className={`tb-card-border ${styles['card-row']}`}>
                        {this.getRowTopCard(squad, player, true)}
                        <div className={`${styles.undefined} ${styles.units}`}>
                            {squad.units.map(unit => {
                                const unitData = this.props.gameData.getUnit(unit.characterId);

                                if (unitData) {
                                    return <div key={unitData.baseId} className={`${styles.undefined} ${styles.unit}`}>
                                        <UnitAvatar
                                            size={"small"}
                                            displayName={false}
                                            unitData={unitData}
                                            key={unitData.baseId}
                                            className={styles['unitAvatar-player']}
                                        />
                                    </div>
                                }
                                return null;
                            })}
                        </div>
                    </div>
                }

                return <div key={player.allyCode + '-' + this.props.zone.id + '-' + squad.squadTypeId}
                            className={`tb-card-border ${styles['card-row']} ${filledZoneSquad ? styles['filled-player'] : ''} ${(filledZoneSquad && this.props.showRemainingSquads) ? styles.hide : ''} ${this.props.template.excludedPlayers.includes(squad.allyCode) ? styles.excludedPlayer : ''}`}>
                    {this.getRowTopCard(squad, player, false)}

                    {this.props.showUnitNames ? <div className={`${styles.units} ${styles.rows}`}>
                        {squad.units.map(unit => {
                            const unitData = this.props.gameData.getUnit(unit.characterId);
                            const squadUnit = unit.preferredUnit ? unit.preferredUnit : unit.characterId;
                            const squadFilled = filledZonePlayer?.find(x => x.units[0].baseId === unitLeader);
                            const unitFilled = filledZoneSquad ? squadFilled?.units.find(x => x.baseId === squadUnit) : undefined;

                            if (!unitData) {
                                return <FontAwesomeIcon
                                    key={player.allyCode + unit.characterId}
                                    icon={faSpinner} pulse={true}
                                    spin={true}
                                />;
                            }
                            return <div key={unitData.baseId} className={`${styles.unitName} ${filledZoneSquad ? (unitFilled ? styles['filled-unit'] : styles['wrong-filled-unit']) : ''}`}>{unitData.name}</div>
                        })}
                    </div> : <div className={styles.units}>
                        {squad.units.map((unit, index) => {
                            const squadFilled = filledZonePlayer?.find(x => x.units[0].baseId === unitLeader);
                            const squadUnit = unit.preferredUnit ? unit.preferredUnit : unit.characterId;
                            const playerUnit = player.roster?.find(x => x.baseId === squadUnit);
                            const unitData = this.props.gameData.getUnit(squadUnit);
                            const unitFilled = filledZoneSquad ? squadFilled?.units.find(x => x.baseId === squadUnit) : undefined;

                            if (!unitData) {
                                return <FontAwesomeIcon
                                    key={player.allyCode + unit.characterId}
                                    icon={faSpinner} pulse={true}
                                    spin={true}
                                />;
                            }
                            if (!playerUnit) {
                                return <div key={unitData.baseId} className={`${styles.undefined} ${styles.unit} ${filledZoneSquad ? (unitFilled ? styles['filled-unit'] : styles['wrong-filled-unit']) : ''}`}>
                                    <UnitAvatar
                                        size={"tiny"}
                                        displayName={false}
                                        unitData={unitData}
                                        key={unitData.baseId}
                                        className={`${styles['unitAvatar-player']} ${this.props.zone.combatType === 'Ship' ? styles.ship : ''}`}
                                    />
                                </div>;
                            }

                            if (unitData && playerUnit) {

                                if (unit.preferredUnit) {
                                    const unitDataPreferredUnit = this.props.gameData.getUnit(unit.preferredUnit);

                                    if (!unitDataPreferredUnit) {
                                        return <FontAwesomeIcon
                                            key={player.allyCode + unit.characterId}
                                            icon={faSpinner} pulse={true}
                                            spin={true}
                                        />;
                                    }
                                    return <div
                                        key={unitDataPreferredUnit.baseId}
                                        className={`${styles.unit} ${filledZoneSquad ? (unitFilled ? styles['filled-unit'] : styles['wrong-filled-unit']) : ''} ${this.props.duplicateUnits.find(x => x.allyCode === squad.allyCode && x.units.includes(unitDataPreferredUnit.baseId)) ? styles.duplicate : ''}`}
                                    >
                                        <UnitAvatar
                                            size={"small"}
                                            displayName={false}
                                            playerUnit={this.getPlayerUnitAvatar(playerUnit)}
                                            unitData={unitDataPreferredUnit}
                                            key={unitDataPreferredUnit.baseId}
                                            className={`${styles['unitAvatar-player']} ${this.props.zone.combatType === 'Ship' ? styles.ship : ''}`}
                                        />
                                    </div>

                                } else {
                                    return [<div
                                        key={unitData.baseId}
                                        className={`${styles.unit} ${filledZoneSquad ? (unitFilled ? styles['filled-unit'] : styles['wrong-filled-unit']) : ''} ${this.props.duplicateUnits.find(x => x.allyCode === squad.allyCode && x.units.includes(unitData.baseId)) ? styles.duplicate : ''}`}>
                                        <UnitAvatar
                                            size={"small"}
                                            displayName={false}
                                            playerUnit={this.getPlayerUnitAvatar(playerUnit)}
                                            unitData={unitData}
                                            key={unitData.baseId}
                                            className={`${styles['unitAvatar-player']} ${this.props.zone.combatType === 'Ship' ? styles.ship : ''}`}
                                        />
                                    </div>,
                                    filledZoneSquad && !unitFilled && this.getNewFilledUnits(playerUnit, filledZoneSquad, squad)
                                    ]
                                }
                            }
                            return null;
                        })}
                    </div>}
                </div>
            }));
    }
}