import moment from 'moment';
import { Moment } from 'moment';

export enum HotAccountShareLevel
{
    None = 0,
    FullAccess = 1,
    ReadOnly = 2
}

export class ShareAccount
{
    discordTag: string;
    level: HotAccountShareLevel;
    createdUTC: Moment;

    constructor(json: any)
    {
        this.discordTag = json.discordTag;
        this.level = json.level;
        this.createdUTC = moment.utc(json.createdUTC);
    }
}