import React from "react";
import {observer} from "mobx-react";
import IPageProps from "../../../IPageProps";
import {action, observable, runInAction} from "mobx";
import {Button, Input, InputNumber, Modal, Popconfirm, Radio, Switch, Select} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faShareSquare, faTrashAlt} from "@fortawesome/pro-duotone-svg-icons";
import {ITerritoryWarsArray, ITerritoryWarsData} from "../../../../model/TWPlanning";
import TextArea from "antd/lib/input/TextArea";
import styles from "../../../../styles/components/Modal.module.scss";
import {IGuildPlayer} from "../../../../model/GuildData";

interface ITWModalProps extends IPageProps {
    showModal: boolean;
    onClose: () => void;
    onDelete: () => void;
    onSave: (name: string, twType: typeof ITerritoryWarsArray, allianceShared: boolean, squadsPerZone: number, description: string, excludedPlayersAllyCode: number[], highlightedPlayers: number[], duplicate: boolean) => void;
    category: typeof ITerritoryWarsArray;
    twTemplateData: ITerritoryWarsData | undefined;
    members: IGuildPlayer[];
    saveHistoryTemplate: boolean;
}

@observer
export class TWModal extends React.PureComponent<ITWModalProps> {
    @observable name = this.props.twTemplateData?.name || "";
    @observable twType: typeof ITerritoryWarsArray = this.props.category;
    @observable allianceShared: boolean = this.props.twTemplateData?.allianceShared || false;
    @observable squadsPerZone: number = this.props.twTemplateData?.squadsPerZone || 50;
    @observable description: string = this.props.twTemplateData?.description || "";
    @observable excludedPlayersAllyCode: number[] = this.props.twTemplateData?.excludedPlayers || [];
    @observable preferredPlayers: number[] = this.props.twTemplateData?.preferredPlayers || [];

    @action
    private cancel() {
        return "";
    }

    render() {
        return (
            <Modal
                title={`${this.props.twTemplateData?.name ? "Edit the" : "Create a new"} TW template`}
                visible={this.props.showModal}
                onCancel={this.props.onClose}
                footer={[
                    (this.props.twTemplateData && <Popconfirm
						title="Are you sure to delete this template?"
						onConfirm={() => {
                            this.props.onDelete();
                            runInAction(() => window.history.back());
                        }}
						key={'popconfirmTWTemplate'}
						onCancel={() => this.cancel()}
						okText="Yes"
						cancelText="No"
					>
						<Button key="deleteTWTemplate" type="link" className={styles.delete}>
							<FontAwesomeIcon icon={faTrashAlt} className={styles.icon}/>
							Delete
						</Button>
					</Popconfirm>),
                    (this.props.twTemplateData && !this.props.saveHistoryTemplate && <Button
                        key="duplicateTWTemplate"
                        type="primary"
                        onClick={() => {
                            this.props.onSave(this.name, this.twType, this.allianceShared, this.squadsPerZone, this.description, this.excludedPlayersAllyCode, this.preferredPlayers, true);
                        }}
                        disabled={this.name.length < 1}>
                        <FontAwesomeIcon icon={faShareSquare} className={styles.icon}/>
                        Save as new
                    </Button>),
                    <Button key="saveTWTemplate" type="primary" onClick={() => {
                        this.props.onSave(this.name, this.twType, this.allianceShared, this.squadsPerZone, this.description, this.excludedPlayersAllyCode, this.preferredPlayers, false);
                    }} disabled={this.name.length < 1}>
                        <FontAwesomeIcon icon={faSave} className={styles.icon}/>
                        Save
                    </Button>
                ]}
            >
                <div className={styles.modal}>
                    <div className={styles.row}>
                        <div className={styles.label}>Title:</div>
                        <Input
                            className={`${styles.input}`}
                            placeholder={'Add a title'}
                            value={this.name}
                            allowClear={true}
                            onChange={event => {
                                runInAction(() => {
                                    this.name = event.target.value;
                                })
                            }}
                            type={"text"}
                        />
                        <div className={styles.switch}>
                            <Switch
                                checked={this.allianceShared}
                                onChange={() => runInAction(() => this.allianceShared = !this.allianceShared)}
                                className={styles.item}
                                checkedChildren={"Yes"}
                                unCheckedChildren={"No"}
                            /> Share with alliance?
                        </div>
                    </div>
                    {!this.props.saveHistoryTemplate && <div className={styles.row}>
                        <div className={styles.label}>Type:</div>
                        <div className={styles.filter}>
                            <Radio.Group
                                defaultValue={this.twType[0]}
                                value={this.twType[0]}
                                buttonStyle={"solid"}
                                optionType="button"
                            >
                                {ITerritoryWarsArray.map(i => <Radio.Button
                                    key={i}
                                    value={i}
                                    disabled={i === 'Offence'}
                                    onClick={() => runInAction(() => this.twType.splice(0, 1, i))}
                                >
                                    {i}
                                </Radio.Button>)}
                            </Radio.Group>
                        </div>
                    </div>}
                    {!this.props.saveHistoryTemplate && <div className={styles.row}>
                        <div className={styles.label}>Squads per Zone:</div>
                        <InputNumber
                            className={`${styles.input}`}
                            style={{width: '100%'}}
                            value={this.squadsPerZone}
                            max={50}
                            min={12}
                            onChange={value => {
                                runInAction(() => {
                                    this.squadsPerZone = value;
                                })
                            }}
                        />
                    </div>}
                    <div className={styles.row}>
                        <div className={styles.label}>Description:</div>
                        <TextArea
                            className={`${styles.input}`}
                            placeholder={'Add a small description'}
                            value={this.description}
                            onChange={event => {
                                runInAction(() => {
                                    this.description = event.target.value;
                                })
                            }}
                            rows={3}
                        />
                    </div>
                    {!this.props.saveHistoryTemplate && <div className={styles.row}>
                        <div className={styles.label}>Excluded Players:</div>
                        <Select
                            className={`${styles.select}`}
                            placeholder={'Select a player'}
                            autoFocus={true}
                            showSearch={true}
                            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            mode={"multiple"}
                            value={Array.isArray(this.excludedPlayersAllyCode) ? this.excludedPlayersAllyCode : [this.excludedPlayersAllyCode]}
                            onChange={value => {
                                runInAction(() => {
                                    this.excludedPlayersAllyCode = value;
                                })
                            }}
                            showArrow={true}
                        >
                            {this.props.members.sort((a, b) => a.name.localeCompare(b.name)).map(member => {
                                return (<Select.Option key={member.allyCode} value={member.allyCode}>{member.name}</Select.Option>);
                            })}
                        </Select>
                    </div>}
                    {!this.props.saveHistoryTemplate && <div className={styles.row}>
                        <div className={styles.label}>Preferred Players:</div>
                        <Select
                            className={`${styles.select}`}
                            placeholder={'Select a player'}
                            autoFocus={true}
                            showSearch={true}
                            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            mode={"multiple"}
                            value={Array.isArray(this.preferredPlayers) ? this.preferredPlayers : [this.preferredPlayers]}
                            onChange={value => {
                                runInAction(() => {
                                    this.preferredPlayers = value;
                                })
                            }}
                            showArrow={true}
                        >
                            {this.props.members.sort((a, b) => a.name.localeCompare(b.name)).map(member => {
                                return (<Select.Option key={member.allyCode} value={member.allyCode}>{member.name}</Select.Option>);
                            })}
                        </Select>
                    </div>}
                </div>
            </Modal>
        );
    }
}