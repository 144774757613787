import React from "react";
import styled from 'styled-components';

import arrowImg from './img/tex.statmodslot_arrow.png';
import circleImg from './img/tex.statmodslot_circle.png';
import crossImg from './img/tex.statmodslot_cross.png';
import diamondImg from './img/tex.statmodslot_diamond.png';
import squareImg from './img/tex.statmodslot_square.png';
import triangleImg from './img/tex.statmodslot_triangle.png';
import emptyCircle from './img/Empty_Circle.png';
import emptyDiamond from './img/Empty_Diamond.png';
import emptyTriangle from './img/Empty_Triangle.png';
import emptyArrow from './img/Empty_Arrow.png';
import emptyCross from './img/Empty_Cross.png';
import emptySquare from './img/Empty_Square.png';

import ModSlots from "../../../model/ModSlots";


const SlotButton = styled.div`
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
`;

const ArrowButton = styled(SlotButton)`
    background-image: url(${arrowImg});
`;
const CircleButton = styled(SlotButton)`
    background-image: url(${circleImg});
`;
const CrossButton = styled(SlotButton)`
    background-image: url(${crossImg});
`;
const DiamondButton = styled(SlotButton)`
    background-image: url(${diamondImg});
`;
const SquareButton = styled(SlotButton)`
    background-image: url(${squareImg});
`;
const TriangleButton = styled(SlotButton)`
    background-image: url(${triangleImg});
`;

const EmptyCircleButton = styled(SlotButton)`
    background-image: url(${emptyCircle});
`;

const EmptyDiamondButton = styled(SlotButton)`
    background-image: url(${emptyDiamond});
`;

const EmptyTriangleButton = styled(SlotButton)`
    background-image: url(${emptyTriangle});
`;

const EmptyArrowButton = styled(SlotButton)`
    background-image: url(${emptyArrow});
`;

const EmptySquareButton = styled(SlotButton)`
    background-image: url(${emptySquare});
`;

const EmptyCrossButton = styled(SlotButton)`
    background-image: url(${emptyCross});
`;

interface IProps
{
    slot: ModSlots;
    empty?: boolean;
    className?: string;
    onClick?: () => void;
}

const ModSlotIcon = ({ slot, empty, onClick, className }: IProps) =>
{
    switch (slot)
    {
        case (ModSlots.Arrow):
            return empty !== true ? <ArrowButton className={className} onClick={() => { if (onClick != null) onClick(); }} /> :
                <EmptyArrowButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
        case (ModSlots.Circle):
            return empty !== true ? <CircleButton className={className} onClick={() => { if (onClick != null) onClick(); }} /> :
                <EmptyCircleButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
        case (ModSlots.Cross):
            return empty !== true ? <CrossButton className={className} onClick={() => { if (onClick != null) onClick(); }} /> :
                <EmptyCrossButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
        case (ModSlots.Diamond):
            return empty !== true ? <DiamondButton className={className} onClick={() => { if (onClick != null) onClick(); }} /> :
                <EmptyDiamondButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
        case (ModSlots.Square):
            return empty !== true ? <SquareButton className={className} onClick={() => { if (onClick != null) onClick(); }} /> :
                <EmptySquareButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
        case (ModSlots.Triangle):
            return empty !== true ? <TriangleButton className={className} onClick={() => { if (onClick != null) onClick(); }} /> :
                <EmptyTriangleButton className={className} onClick={() => { if (onClick != null) onClick(); }} />;
    }

    return null;
};


export default ModSlotIcon;