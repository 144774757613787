import {observer} from "mobx-react";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IPageProps from "../../IPageProps";
import header from "../../../components/Player/styles/Header.module.scss";
import {Button, Checkbox, Input, Space, Table} from "antd";
import {faArrowAltLeft} from "@fortawesome/pro-solid-svg-icons";
import TableTitle from "../../../components/TableTitle/TableTitle";
import UnitAvatar from "../../../components/swgoh/Character/UnitAvatar";
import {ColumnType} from "antd/es/table";
import {faFilter, faLayerPlus, faUserCog} from "@fortawesome/pro-duotone-svg-icons";
import {IGuildData, IGuildPlayer} from "../../../model/GuildData";
import PlayerCharacterData from "../../../model/PlayerCharacterData";

import styles from "../styles/GuildSquads.module.scss";
import table from "../../../styles/components/Table.module.scss";
import {action, observable, runInAction} from "mobx";
import {
    IGuildSquadTemplate,
    IFilterUnitStatsGuildSquad,
    IFilterSpecialUnitGuildSquad, IPlayerSquadUnits, IGuildSquadUnits
} from "../../../model/Squads";
import GroupUnitAvatar from "../../../components/swgoh/Character/UnitAvatarGroup";
import moment from "moment";
import BaseAPI from "../../../service/BaseAPI";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import UnitData from "../../../model/UnitData";
import {FilterSquadModal} from "./Modal/FilterSquadModal";
import {Stats} from "../../../model/Stats";
import {ITerritoryWarsData, squadsTW, zonesTW} from "../../../model/TWPlanning";
import {Key} from "antd/lib/table/interface";
import uuid from "../../../utils/uuid";
import {sortNumber} from "../../../utils/sorting";
import {TotalSquadsAssigned} from "../../TW/TWPlanning/components/TotalSquadsAssigned";
import UnitAvatarUndefined from "../../../components/swgoh/Character/UnitAvatarUndefined";

export interface IGuildPlayerUnit {
    player: IGuildPlayer;
    units: PlayerCharacterData[];
    poolUnits: PlayerCharacterData[];
    includedUnit?: PlayerCharacterData;
    validUnits: boolean[];
}

export interface IGuildPoolSquadTemplateProps extends IPageProps {
    isAdmin: boolean;
    template: IGuildSquadTemplate;
    onEditSettings: () => void;
    editedBy: string;
    createdBy: string;
    createdTime: string;
    editedTime: string;
    onBack: () => void;
    zoneTW?: zonesTW;
    squadsTW?: squadsTW[];
    saveAssignedSquads?: (squadsTW: squadsTW[]) => void;
    squadsPerZoneAvailable?: number;
    squadsPerZone?: number;
    selectedPlayersAllyCode?: number[];
    excludedPlayers?: number[];
    preferredPlayers?: number[];
    squadsUsedThisZone?: squadsTW[];
    templateTW?: ITerritoryWarsData;
}

interface IVisibleColumnType<T> extends ColumnType<T> {
    visible: boolean;
}

@observer
export class GuildPoolSquadTemplate extends React.PureComponent<IGuildPoolSquadTemplateProps> {
    @observable showModal: boolean = false;
    @observable showFilterModal: boolean = false;
    @observable showTableColumn: boolean = false;
    @observable.ref playersUnit: IGuildPlayerUnit[] = [];
    @observable.ref players: IGuildData | null = null;
    @observable.ref completedSquads: number = 0;
    @observable showIncludedUnit: boolean = false;
    @observable includedCharacter: IFilterSpecialUnitGuildSquad = {
        characterId: "Select a unit",
        minStars: 1,
        minGear: 1
    };
    @observable showExcludedUnit: boolean = false;
    @observable excludedCharacter: IFilterSpecialUnitGuildSquad = {
        characterId: "Select a unit",
        minStars: 1,
        minGear: 1
    };
    @observable showSpecificUnitStats: boolean = false;
    @observable specificUnitStats: IFilterUnitStatsGuildSquad = {characterId: "Select a unit", stats: Stats.speed, statsName: "Speed"};
    @observable private filter = "";
    @observable squadsTW: squadsTW[] = this.props.squadsTW?.filter(x => x.squadTypeId === this.props.template.id && x.zoneId === this.props.zoneTW?.id) || [];
    @observable.ref selectedPlayersAllyCode = this.props.selectedPlayersAllyCode ?? [] as Key[];
    @observable.ref playersDisabled: number[] = [];
    @observable playersAllycodeDisabled: number[] = [];
    @observable selectedFilteredAllyCodes = [] as Key[];
    @observable showPlayersForThisZone: boolean = false;

    constructor(props: IGuildPoolSquadTemplateProps) {
        super(props);

        this.getRowClassName = this.getRowClassName.bind(this);
        this.filterPlayers = this.filterPlayers.bind(this);
    }

    componentDidMount() {
        this.fetchPlayers();
        runInAction(() => {
                this.playersAllycodeDisabled = this.getPlayersUnitsUsed();
            }
        )
    }

    componentDidUpdate(prevProps: Readonly<IGuildPoolSquadTemplateProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.template !== this.props.template) {
            this.updatePlayerUnits()
        }
    }

    componentWillUnmount() {
        runInAction(() => {
            this.showIncludedUnit = false;
            this.includedCharacter = {characterId: "Select a unit", minStars: 1, minGear: 1};
            this.showExcludedUnit = false;
            this.excludedCharacter = {characterId: "Select a unit", minStars: 1, minGear: 1};
            this.showSpecificUnitStats = false;
            this.specificUnitStats = {characterId: "Select a unit", stats: Stats.speed, statsName: "Speed"};
        })
    }

    calculateTotalSquads() {
        let completedSquads = 0;

        for (let i = 0; i < this.playersUnit.length; i++) {
            completedSquads += this.getCompletedSquads(this.playersUnit[i]) ? 1 : 0;
        }

        if (this.playersUnit.length < completedSquads) {
            runInAction(() => {
                this.completedSquads = this.playersUnit.length;
            });
        } else {
            runInAction(() => {
                this.completedSquads = completedSquads;
            });
        }
    }

    private async fetchPlayers(): Promise<void> {
        const response = await BaseAPI.getGuildList(this.props.user, true, true, undefined, parseInt(this.props.user.currentPlayer!.allyCode), 24);

        runInAction(() => {
            this.players = response;
        });

        this.updatePlayerUnits();
    }

    updatePlayerUnits() {
        const playerUnit: IGuildPlayerUnit[] = [];
        const guildPlayers = this.players?.players || [];

        for (let i = 0; i < guildPlayers.length; i++) {
            const player = guildPlayers[i];
            const units = [] as PlayerCharacterData[];
            const poolUnits = [] as PlayerCharacterData[];
            const validUnits = [] as boolean[];
            let playerIncludedUnit = player.roster?.find(x => x.baseId === this.includedCharacter.characterId);

            if (this.showIncludedUnit) {
                if (!playerIncludedUnit) {
                    continue;
                }
                if (this.includedCharacter.characterId === 'Select a unit') {
                    playerIncludedUnit = undefined;
                } else if (playerIncludedUnit.stars < this.includedCharacter.minStars) {
                    playerIncludedUnit = undefined;
                } else if (playerIncludedUnit.gear.level < this.includedCharacter.minGear) {
                    playerIncludedUnit = undefined;
                }
            }

            this.props.template.poolUnits?.forEach(poolUnit => {
                const playerPoolUnit = player.roster?.find(x => x.baseId === poolUnit);

                if(playerPoolUnit){
                    poolUnits.push(new PlayerCharacterData(playerPoolUnit));
                }
            });

            let poolIdx: number = 0;

            this.props.template.units.forEach(unitData => {
                const playerUnit = player.roster?.find(x => x.baseId === unitData.characterId);

                if(unitData.poolUnit){
                    const unitPoolIdx = poolIdx++;
                    const allAvailablePoolUnits = poolUnits.sort((a, b) => b.powerTotal - a.powerTotal);
                    const availablePoolUnit = allAvailablePoolUnits[unitPoolIdx];

                    const playerPoolUnit = player.roster?.find(x => x.baseId === availablePoolUnit?.baseId);

                    if(playerPoolUnit){
                        if(this.props.squadsTW){
                            const availablePoolUnits = [];

                            if(allAvailablePoolUnits) {
                                for (let i = 0; i < allAvailablePoolUnits.length; i++) {
                                    const poolUnit = allAvailablePoolUnits[i];

                                    if(!this.getUsedUnit(player.allyCode, poolUnit.baseId)){
                                        availablePoolUnits.push(poolUnit);
                                    }
                                }

                                const allSquadAvailablePoolUnits = availablePoolUnits.sort((a, b) => b.powerTotal - a.powerTotal);
                                const playerPoolUnitData = allSquadAvailablePoolUnits[unitPoolIdx];

                                units.push(playerPoolUnitData);
                                validUnits.push(this.isValidPlayerUnit(playerPoolUnitData, unitData));
                            }
                        } else {
                            const playerPoolUnitData = new PlayerCharacterData(playerPoolUnit);

                            units.push(playerPoolUnitData);
                            validUnits.push(this.isValidPlayerUnit(playerPoolUnitData, unitData));
                        }
                    }
                }
                if (playerUnit) {
                    const playerUnitData = new PlayerCharacterData(playerUnit);

                    units.push(playerUnitData);
                    validUnits.push(this.isValidPlayerUnit(playerUnitData, unitData));
                }

            });

            playerUnit.push({
                player,
                units,
                poolUnits,
                validUnits,
                includedUnit: playerIncludedUnit ? new PlayerCharacterData(playerIncludedUnit) : undefined,
            });
        }

        if (this.includedCharacter.characterId !== 'Select a unit' && this.showIncludedUnit) {
            runInAction(() => {
                this.playersUnit = playerUnit.filter(x => x.includedUnit !== undefined);
            })
        } else if (this.excludedCharacter.characterId !== 'Select a unit') {
            let validPlayerUnits: IGuildPlayerUnit[] = playerUnit.slice();

            if(this.excludedCharacter.minGear >= 1){
                validPlayerUnits = validPlayerUnits
                    .filter(x => {
                        const unit = x.player.roster?.find(y => y.baseId === this.excludedCharacter.characterId);

                        return !unit ||  unit.gear.level <= this.excludedCharacter.minGear;
                    });
            }
            if(this.excludedCharacter.minStars >= 1){
                validPlayerUnits = validPlayerUnits
                    .filter(x => {
                        const unit = x.player.roster?.find(y => y.baseId === this.excludedCharacter.characterId);

                        return !unit || unit.stars <= this.excludedCharacter.minStars;
                    });
            }

            runInAction(() => {
                this.playersUnit = validPlayerUnits;
            });
        } else {
            runInAction(() => {
                this.playersUnit = playerUnit;
            });
        }

        this.calculateTotalSquads();
    }

    filterPlayers(record: IGuildPlayerUnit) {
        return record.player.name.toLowerCase().includes(this.filter.toLowerCase());
    }

    getAllPlayersAssignedForCurrentZone(record: IGuildPlayerUnit) {
        if(!this.props.squadsUsedThisZone){
            return false;
        }
        const allyCodeAssignedCurrentZone: number[] = this.props.squadsUsedThisZone.map(x => x.allyCode);

        if(this.showPlayersForThisZone){
            if(allyCodeAssignedCurrentZone.includes(record.player.allyCode)){
                return true;
            }
        }
        return false;
    }

    getRowClassName(record: IGuildPlayerUnit) {
        if(this.getAllPlayersAssignedForCurrentZone(record)){
            return styles['cell-highlight-name'];
        }
        if (this.props.user.currentPlayer?.allyCode === record.player.allyCode.toString() &&
            !this.props.preferredPlayers?.includes(record.player.allyCode) &&
            !this.playersDisabled.includes(record.player.allyCode)) {
            return styles['cell-highlighted'];
        }
        if(this.props.preferredPlayers?.includes(record.player.allyCode) &&
            !this.props.excludedPlayers?.includes(record.player.allyCode) &&
            !this.playersDisabled.includes(record.player.allyCode)) {
            return styles['cell-preferred-highlighted']
        }
        if (this.playersDisabled.includes(record.player.allyCode)) {
            return styles['cell-disabled-highlighted'];
        }
        return "";
    }

    @action.bound
    handleFilter(event: React.ChangeEvent<HTMLInputElement>) {
        this.filter = event.target.value;
        const array = this.selectedPlayersAllyCode.concat(this.selectedFilteredAllyCodes);

        if(this.players !== null){
           this.selectedFilteredAllyCodes = this.players.players.filter(x => array.includes(x.allyCode)).map(y => y.allyCode)
        }
    }

    getSquadGP(record: IGuildPlayerUnit) {
        let playerUnitGP = 0;

        for (let i = 0; i < this.props.template.units.length; i++) {
            const unit = this.props.template.units[i];
            const playerUnit = this.getPlayerUnit(record, unit);

            playerUnitGP += playerUnit?.powerTotal || 0;

        }
        return playerUnitGP;
    }

    private checkMinimumGPUnit(record: IGuildPlayerUnit) {

        for (let i = 0; i < this.props.template.units.length; i++) {
            const unit = this.props.template.units[i];
            const playerUnit = this.getPlayerUnit(record, unit);

            if ((playerUnit && playerUnit.powerTotal <= 6000) || playerUnit === undefined) {
                return true
            }
        }

        return false;
    }

    public static statsDisplayValue(value: number, decimals: number): string {
        if (value >= 1000000) {
            return (value / 1000000).toFixed(1) + "M";
        } else {
            return (value / 1000).toFixed(decimals) + "K";
        }
    }

    private isValidSquad(squad: IGuildPlayerUnit): boolean {
        const squadSize = this.props.zoneTW!.combatType === "Character" ? 5 : 8;
        if(squad) {
            return (squad.units.length === this.props.template.units.length && squad.units.length <= squadSize) &&
                (!this.props.excludedPlayers?.includes(squad.player.allyCode) ?? true);
        }
        return false;
    }

    private getPlayersUnitsUsed(): number[] {
        let usedUnitsOfPlayers = new Set<number>();

        if (!this.props.squadsTW) {
            return [];
        }

        for (let i = 0; i < this.props.squadsTW.length; i++) {
            const squadTW = this.props.squadsTW[i];

            if (squadTW.zoneId === this.props.zoneTW?.id && squadTW.squadTypeId === this.props.template.id) {
                continue;
            }

            for (let unit of squadTW.units) {
                for (let j = 0; j < this.props.template.units.length; j++) {
                    const unitDataTemplate = this.props.template.units[j];
                    const characterId = unit.characterId;

                    if(unitDataTemplate.poolUnit){
                        for (let k = 0; k < this.playersUnit.length; k++) {
                            const player = this.playersUnit[k];

                            if(this.isUnitAlreadyUsed(player)){
                                usedUnitsOfPlayers.add(player.player.allyCode);
                            }
                        }
                    } else {
                        if(unitDataTemplate.characterId === characterId) {
                            usedUnitsOfPlayers.add(squadTW.allyCode);
                        }
                    }
                }
            }
        }
        return Array.from(usedUnitsOfPlayers);
    }

    private onSelectedSquads(validRows: IGuildPlayerUnit[]): void {
        const squadsTW: squadsTW[] = this.props.squadsUsedThisZone?.filter(x => x.squadTypeId !== this.props.template.id) ?? [];
        const newArray: React.Key[] = Array.from(new Set(validRows.filter(this.filterPlayers).map(x => x.player.allyCode)));
        const filteredSelection = this.playersUnit.filter(x => this.selectedFilteredAllyCodes.includes(x.player.allyCode));
        const filteredValidRows = validRows.concat(filteredSelection);

        runInAction(() => {
            if(validRows.length === 0){
                this.selectedFilteredAllyCodes = [];
            }

            this.selectedPlayersAllyCode = newArray.concat(this.selectedFilteredAllyCodes);
            this.squadsTW = squadsTW.concat(filteredValidRows.map(squad => this.getSquadTW(squad, undefined)));
        });
    }

    private getCompletedSquads(record: IGuildPlayerUnit) {
        const numberOfUnits = this.props.template.units.length;

        let pU: number = 0;

        this.props.template.units.forEach(unitData => {
            let playerUnit = this.getPlayerUnit(record, unitData);

            if (playerUnit) {
                return pU++;
            }
        });

        return numberOfUnits === pU;
    }

    private getUnitRoles(unit: UnitData, playerUnit: PlayerCharacterData) {

        if (unit.role?.find(x => x.display.toLowerCase() === 'attacker')) {
            return <div className={styles.stats}>
                <div className={styles.item}>Spd: &nbsp;<strong>{playerUnit.statsSpeed}</strong></div>
                <div className={styles.item}>H+P: &nbsp;
                    <strong>{GuildPoolSquadTemplate.statsDisplayValue(playerUnit.statsProtection + playerUnit.statsHealth, 0)}</strong>
                </div>
                <div
                    className={styles.item}>Dmg: &nbsp;{GuildPoolSquadTemplate.statsDisplayValue(playerUnit.statsDamage, 1)} / {GuildPoolSquadTemplate.statsDisplayValue(playerUnit.statsSpecialDamage, 1)}</div>
                <div className={styles.item}>CD: &nbsp;{Math.round(playerUnit.statsCritDamage * 100) / 100}</div>
                <div className={styles.item}>CC: &nbsp;{Math.round(playerUnit.statsCritChance * 100) / 100}</div>
            </div>
        } else if (unit.role?.find(x => x.display.toLowerCase() === 'tank')) {
            return <div className={styles.stats}>
                <div className={styles.item}>Spd:&nbsp;<strong>{playerUnit.statsSpeed}</strong></div>
                <div className={styles.item}>H+P:&nbsp;
                    <strong>{GuildPoolSquadTemplate.statsDisplayValue(playerUnit.statsProtection + playerUnit.statsHealth, 0)}</strong>
                </div>
                <div className={styles.item}>Arm: &nbsp;{Math.round(playerUnit.statsArmor * 100) / 100}</div>
                <div className={styles.item}>Ten: &nbsp;{Math.round(playerUnit.statsTenacity * 100) / 100}</div>
            </div>
        } else if (unit.role?.find(x => x.display.toLowerCase() === 'support' || x.display.toLowerCase() === 'healer')) {
            return <div className={styles.stats}>
                <div className={styles.item}>Spd: &nbsp;<strong>{playerUnit.statsSpeed}</strong></div>
                <div className={styles.item}>H+P: &nbsp;
                    <strong>{GuildPoolSquadTemplate.statsDisplayValue(playerUnit.statsProtection + playerUnit.statsHealth, 0)}</strong>
                </div>
                <div className={styles.item}>Pot: &nbsp;{Math.round(playerUnit.statsPotency * 100) / 100}</div>
                <div className={styles.item}>Ten: &nbsp;{Math.round(playerUnit.statsTenacity * 100) / 100}</div>
            </div>
        }
    }

    getAvgSpeed(record: IGuildPlayerUnit) {
        let playerUnitSpeed = 0;

        for (let i = 0; i < this.props.template.units.length; i++) {
            const unit = this.props.template.units[i];
            const playerUnit = this.getPlayerUnit(record, unit);

            playerUnitSpeed += playerUnit?.statsSpeed || 0;

        }
        return Math.round(playerUnitSpeed / record.units.length);
    }

    private getCompleteSquads() {
        return (`${this.completedSquads} / ${this.playersUnit.length}`);
    }

    private getSpecificUnitStats(record: IGuildPlayerUnit) {
        const player = record.player.roster;
        if (player) {
            const specificUnit = player.find(x => x.baseId === this.specificUnitStats.characterId);
            const specificUnitStats = specificUnit?.stats[this.specificUnitStats.stats.toLowerCase()];
            const specificUnitGear = player.find(x => x.baseId === this.specificUnitStats.characterId)?.gear.level;
            const specificUnitOmicron = player.find(x => x.baseId === this.specificUnitStats.characterId)?.omiCount;
            const specificUnitShipPower = player.find(x => x.baseId === this.specificUnitStats.characterId)?.power.crew;
            const specificUnitRelic = player.find(x => x.baseId === this.specificUnitStats.characterId)?.relicLevel;

            if(specificUnit?.combatType === 2){

                if (this.specificUnitStats.stats === "totalPower") {
                    return specificUnitShipPower;
                }
            } else {
                if (this.specificUnitStats.stats === "gearLevel") {
                    return specificUnitGear;
                }
                if(this.specificUnitStats.stats === "omicron") {
                    return specificUnitOmicron;
                }
                if (this.specificUnitStats.stats === "relicLevel") {
                    return specificUnitRelic;
                }
            }
            if (player.find(x => x.baseId === this.specificUnitStats.characterId) !== undefined) {
                return specificUnitStats;
            }
        } else {
            return 0;
        }
    }

    private renderFilterSquadModal() {
        if (!this.showFilterModal) {
            return null;
        }

        return (
            <FilterSquadModal
                {...this.props}
                showModal={this.showFilterModal}
                onClose={() => runInAction(() => {
                    this.showFilterModal = false;
                })}
                template={this.props.template}
                showIncludedUnit={this.showIncludedUnit}
                includedCharacter={this.includedCharacter}
                showExcludedUnit={this.showExcludedUnit}
                excludedCharacter={this.excludedCharacter}
                specificUnitStats={this.specificUnitStats}
                showSpecificUnitStats={this.showSpecificUnitStats}
                onSaveIncludedUnit={(showIncludedUnit, includedCharacter) => {
                    runInAction(() => {
                        this.showIncludedUnit = showIncludedUnit;
                        this.includedCharacter = includedCharacter;
                        this.showFilterModal = false;

                    });
                    this.updatePlayerUnits()
                }}
                onSaveExcludedUnit={(showExcludedUnit, excludedCharacter) => {
                    runInAction(() => {
                        this.showExcludedUnit = showExcludedUnit;
                        this.excludedCharacter = excludedCharacter;
                        this.showFilterModal = false;
                    });
                    this.updatePlayerUnits();
                }}
                onSaveSpecificUnitStats={(showSpecificUnitStats, specificUnitStats) =>
                    runInAction(() => {
                        this.showSpecificUnitStats = showSpecificUnitStats;
                        this.specificUnitStats = specificUnitStats;
                        this.showFilterModal = false;
                    })
                }
            />
        );
    }

    private getSquadTW(squad: IGuildPlayerUnit, newSelectedUnit: string | undefined, unitDataCharacterOld?: string): squadsTW {
        let units: IPlayerSquadUnits[] = []

        this.props.template.units.forEach(unitData => {
            const squadUnit = this.getPlayerUnit(squad, unitData) ?? squad.units.find(x => x.baseId === unitData.characterId);

            if (squadUnit) {
                const squadTWPreferredUnit = this.squadsTW.find(x => x.allyCode === squad.player.allyCode && x.squadTypeId === this.props.template.id)?.units.find(x =>
                    x.characterId === unitData.preferredUnit ||
                    x.preferredUnit === unitData.characterId ||
                    x.characterId === this.props.gameData.getUnit(squadUnit.baseId)?.baseId
                )?.preferredUnit
                const unitName = this.props.gameData.getUnit(squadUnit.baseId);

                if(unitName) {
                    if(
                        unitDataCharacterOld === unitData.characterId ||
                        unitDataCharacterOld === squadUnit.baseId ||
                        unitDataCharacterOld === this.getPlayerUnit(squad, unitData)?.baseId
                    ){
                        units.push({
                            id: unitData.id,
                            characterId: squadUnit.baseId,
                            characterName: unitName?.name,
                            preferredUnit: newSelectedUnit
                        })
                    } else {
                        units.push({
                            id: unitData.id,
                            characterId: squadUnit.baseId,
                            characterName: unitName?.name,
                            preferredUnit: squadTWPreferredUnit || undefined
                        })
                    }
                }
            }
        })

        return {
            zoneId: this.props.zoneTW!.id,
            squadTypeId: this.props.template.id,
            squadId: uuid(),
            allyCode: squad.player.allyCode,
            playerName: squad.player.name,
            units: units
        };
    }

    private getUsedUnit(allyCode: number, unit: string) {
        if(this.props.squadsTW) {
            const squads = this.props.squadsTW.filter(x => x.allyCode === allyCode && x.squadTypeId !== this.props.template.id);
            const unitUsed = squads?.flatMap(x => x.units.map(y => y.preferredUnit ? y.preferredUnit : y.characterId));

            return unitUsed?.includes(unit);
        }
    }

    private isValidPlayerUnit(playerUnit: PlayerCharacterData | undefined, unitData: IGuildSquadUnits) {
        if(!playerUnit){
            return false;
        }
        if (playerUnit.combatType === 1) {
            const reqMinGP = unitData.requirements.filters.minGP === undefined ? 100 : unitData.requirements.filters.minGP;
            const reqStars = unitData.requirements.filters.stars;
            const reqGearLevel = unitData.requirements.filters.gear;
            const reqRelicLevel = unitData.requirements.filters.relic;

            const hasPlayerUnitLevel = (playerUnit?.powerTotal ?? 0) >= reqMinGP && (playerUnit?.stars ?? 0) >= reqStars && (reqGearLevel === 13 ? (playerUnit?.gearLevel ?? 0) >= reqGearLevel && (playerUnit?.relicLevel ?? 0) >= reqRelicLevel : (playerUnit?.gearLevel ?? 0) >= reqGearLevel);
            if (
                (unitData.requirements.hasUltimate && !playerUnit.ultimate) ||
                (unitData.requirements.hasZeta && (playerUnit.zetaCount === 0 && !playerUnit.zetaLead)) ||
                (unitData.requirements.hasOmicron && playerUnit.omiCount === 0) || !hasPlayerUnitLevel
            ) {
                return false;
            }

        } else if (playerUnit.combatType === 2) {
            const reqMinGP = unitData.requirements.filters.minGP === undefined ? 100 : unitData.requirements.filters.minGP;
            const reqStars = unitData.requirements.filters.stars;

            const hasPlayerUnitLevel = (playerUnit?.powerTotal ?? 0) >= reqMinGP && (playerUnit?.stars ?? 0) >= reqStars;
            if (!hasPlayerUnitLevel) {
                return false;
            }
        }
        return true;
    }

    private getPlayerUnit(record: IGuildPlayerUnit, unitData: IGuildSquadUnits){
        const unit = this.props.gameData.units?.find(u => u.baseId === unitData.characterId);
        let playerUnit = record.units.find(x => x?.baseId === unitData.characterId);

        if (!unit && !unitData.poolUnit) {
            return null;
        }

        if(unitData.poolUnit) {
            const playerPoolUnit = record.units[unitData.id];

            if(playerPoolUnit){
                if (playerPoolUnit.combatType === 1) {
                    if(playerPoolUnit.gearLevel >= unitData.requirements.filters.gear && playerPoolUnit.relicLevel >= unitData.requirements.filters.relic){
                        playerUnit = playerPoolUnit;
                    }
                }
            }
        }
        if (this.props.squadsTW) {
            if(playerUnit !== undefined){
                if(this.getUsedUnit(record.player.allyCode, playerUnit.baseId)){
                    playerUnit = undefined;
                }
            }
            // if(playerUnit === undefined){
            //     const allPoolUnits = this.props.template.poolUnits;
            //     const availablePoolUnits = [];
            //
            //     if(allPoolUnits) {
            //         for (let i = 0; i < allPoolUnits.length; i++) {
            //             const poolUnit = allPoolUnits[i];
            //
            //             if(!this.getUsedUnit(record.player.allyCode, poolUnit)){
            //                 const playerNewUnit = record.player.roster?.find(x => x.baseId === poolUnit);
            //
            //                 if(playerNewUnit && !record.units.find(x => x.baseId === poolUnit))
            //                 availablePoolUnits.push(new PlayerCharacterData(playerNewUnit));
            //             }
            //         }
            //
            //         const allAvailablePoolUnits = availablePoolUnits.sort((a, b) => b.powerTotal - a.powerTotal);
            //         playerUnit = allAvailablePoolUnits[unitData.id - 1];
            //     }
            // }
        }

        return this.isValidPlayerUnit(playerUnit, unitData) ? playerUnit : undefined;
    }

    private isUnitAlreadyUsed(record: IGuildPlayerUnit) {
        let isUnitUsed: boolean = false;
        for (let i = 0; i < record.units.length; i++) {
            const unit = record.units[i];

            if(unit !== undefined)
            if(this.getUsedUnit(record.player.allyCode, unit.baseId)){
                isUnitUsed = true
            }
        }
        return isUnitUsed;
    }

    private getUnitRequirements(record: IGuildPlayerUnit) {

        for (let i = 0; i < record.units.length; i++) {
            const unit = record.units[i];
            const playerUnit = this.props.template.units.find(x => x.characterId === unit.baseId);

            if(playerUnit){
                if(playerUnit.requirements.hasOmicron && unit.omiCount === 0){
                    return false;
                }
                if(playerUnit.requirements.hasZeta && unit.zetaCount === 0){
                    return false;
                }
                if(playerUnit.requirements.hasUltimate && !unit.ultimate){
                    return false;
                }
                if(playerUnit.requirements.filters.relic > unit.relicLevel && unit.combatType !== 2){
                    return false;
                }
                if(playerUnit.requirements.filters.gear > unit.gearLevel && unit.combatType !== 2){
                    return false;
                }
                if(playerUnit.requirements.filters.stars > unit.stars){
                    return false;
                }
                if(playerUnit.requirements.filters.minGP > unit.powerTotal){
                    return false;
                }
            }

        }

        return true;
    }

    private getSelectAll(data: IGuildPlayerUnit[], isInverted: boolean, sliced: number, keys: Key[]){
        if(keys.length > 0){
            data = data.sort((a, b) => keys.indexOf(a.player.allyCode) - keys.indexOf(b.player.allyCode));
        }

        const squadsTW: number = this.props.squadsUsedThisZone?.filter(x => x.squadTypeId !== this.props.template.id).length ?? 0;
        let validRows = !isInverted ?
            data.filter(x => this.isValidSquad(x) && this.getUnitRequirements(x) && (x.validUnits.filter(x => x).length === this.props.template.units.length) && !this.playersAllycodeDisabled.includes(x.player.allyCode)) :
            this.playersUnit.filter(x => this.isValidSquad(x) && this.getUnitRequirements(x) && (x.validUnits.filter(x => x).length === this.props.template.units.length) && !this.playersAllycodeDisabled.includes(x.player.allyCode) && !this.selectedPlayersAllyCode.includes(x.player.allyCode));

        let squadAvailable = 0

        if (squadsTW > 0) {
            squadAvailable = (this.props.squadsPerZoneAvailable ?? 0) - this.selectedFilteredAllyCodes.length;
        } else {
            squadAvailable = (this.props.squadsPerZone ?? 0) - this.selectedFilteredAllyCodes.length;
        }
        if(sliced > 0){
            if(squadAvailable > sliced){
                squadAvailable = sliced;
            }
        }

        validRows = validRows.slice(0, squadAvailable)
        return this.onSelectedSquads(validRows);
    }

    render() {
        if (!this.players) {
            return <LoadingSpinner size={"large"} spinning={true} text={'Loading the guild data...'}/>;
        }
        const includedUnit = this.props.gameData.units?.find(u => u.baseId === this.includedCharacter.characterId);
        const specificUnit = this.props.gameData.getUnit(this.specificUnitStats.characterId);

        const columns: IVisibleColumnType<IGuildPlayerUnit>[] = [
            {
                title: '#',
                render: (record: IGuildPlayerUnit, item, index) => index + 1,
                key: 'index',
                align: "center",
                width: 50,
                visible: true,
            },
            {
                title: 'Name',
                key: 'name',
                width: 150,
                align: "left",
                visible: true,
                render: (record: IGuildPlayerUnit) => {
                    return (
                        <Space size="middle" className={`${table.name} ${this.props.preferredPlayers?.includes(record.player.allyCode) ? table.preferred : ''}`}>
                            {record.player.name.toLocaleString()}
                        </Space>
                    );
                },
                sorter: (a, b) => a.player.name.localeCompare(b.player.name),
            },
            {
                title: 'Squads',
                key: 'squadsAssigned',
                width: 60,
                align: "center",
                visible: !!this.props.zoneTW && this.props.template.combatType === 1,
                render: (record: IGuildPlayerUnit) => {
                    const total = TotalSquadsAssigned(record.player, "squad", this.props.templateTW);
                    return total;
                },
                sorter: (a, b) => TotalSquadsAssigned(a.player, "squad", this.props.templateTW) - TotalSquadsAssigned(b.player, "squad", this.props.templateTW),
            },
            {
                title: 'Fleets',
                key: 'fleetAssigned',
                width: 60,
                align: "center",
                visible: !!this.props.zoneTW && this.props.template.combatType === 2,
                render: (record: IGuildPlayerUnit) => {
                    const total = TotalSquadsAssigned(record.player, "ship", this.props.templateTW);
                    return total;
                },
                sorter: (a, b) => TotalSquadsAssigned(a.player, "ship", this.props.templateTW) - TotalSquadsAssigned(b.player, "ship", this.props.templateTW),
            },
            {
                title: specificUnit?.name + ' ' + this.specificUnitStats.statsName,
                render: (record: IGuildPlayerUnit) => this.getSpecificUnitStats(record) ?? 0,
                key: 'includedUnit',
                width: 130,
                visible: this.showSpecificUnitStats,
                sorter: (a, b) => (this.getSpecificUnitStats(a) ?? 0) - (this.getSpecificUnitStats(b) ?? 0)
            },
            {
                title: 'Has Unit',
                render: (record: IGuildPlayerUnit) => {
                    if (includedUnit !== undefined) {
                        return <UnitAvatar size={"medium"} key={includedUnit.baseId} displayName={false}
                                           playerUnit={record.includedUnit} unitData={includedUnit}/>
                    }
                    return null;
                },
                key: 'includedUnit',
                width: 120,
                visible: this.showIncludedUnit,
                sorter: (a, b) => sortNumber(a.includedUnit?.statsSpeed, b.includedUnit?.statsSpeed)
            },
            {
                title: 'Squad GP',
                render: (record: IGuildPlayerUnit) => this.getSquadGP(record),
                key: 'powerTotal',
                width: 80,
                visible: true,
                sorter: (a, b) => this.getSquadGP(a) - this.getSquadGP(b),
            },
            {
                title: 'Avg Speed',
                render: (record: IGuildPlayerUnit) => this.getAvgSpeed(record),
                key: 'statsSpeed',
                width: 100,
                visible: !this.props.zoneTW,
                sorter: (a, b) => this.getAvgSpeed(a) - this.getAvgSpeed(b),
            },
        ]

        this.props.template.units.forEach(unitData => {
            const unit = this.props.gameData.units?.find(u => u.baseId === unitData.characterId);

            if (!unit && !unitData.poolUnit){
                return null;
            }

            columns.push(
                {
                    title: unitData.poolUnit ?
                        <UnitAvatarUndefined
                            name={"Pool Unit"}
                            baseImage={"blank-character"}
                            combatType={1}
                            alignment={3}
                            stars={unitData.requirements.filters.stars}
                            key={unitData.id}
                            gear={unitData.requirements?.filters.gear}
                            relic={unitData.requirements?.filters.relic}
                            showName={false}
                            className={styles.unit}
                        />
                        : <GroupUnitAvatar
                            key={unitData.id}
                            className={styles['unit-avatar']}
                            unitData={unit!}
                            stars={unitData.requirements.filters.stars}
                            gear={unitData.requirements.filters.gear}
                            relic={unitData.requirements.filters.relic}
                            ultimate={unitData.requirements.hasUltimate}
                            zeta={unitData.requirements.hasZeta}
                            omi={unitData.requirements.hasOmicron}
                            subsPriority={"gear"}
                            subsUnit={undefined}
                            subs={undefined}
                            showName={false}
                        />,
                    key: unitData.characterId,
                    width: 210,
                    visible: true,
                    render: (record: IGuildPlayerUnit) => {
                        const playerUnit = this.getPlayerUnit(record, unitData);
                        const unitDataPlayer = this.props.gameData.units?.find(u => u.baseId === playerUnit?.baseId);

                        let missingPlayerUnit = false;

                        if(!playerUnit) {
                            missingPlayerUnit = true;
                        }

                        if (!playerUnit && missingPlayerUnit && unit) {
                            // player doesn't have this unlocked
                            return <div className={`${styles.unit} ${styles.center}`}>
                                <UnitAvatar
                                    size={this.playersDisabled.includes(record.player.allyCode) ? "small" : "medium"}
                                    key={unitData.characterId + record.player.allyCode + record.units.length}
                                    displayName={false}
                                    unitData={unit}
                                    className={`${styles['unit-avatar-player']} ${styles.unitMissing}`}
                                />
                            </div>;
                        }

                        if (!playerUnit  || !unitDataPlayer) {
                            return null;
                        }
                        const unitOfPlayer = this.props.gameData.units?.find(u => u.baseId === playerUnit.baseId);

                        if (!unitOfPlayer) {
                            return null;
                        }

                        if (playerUnit.combatType === 1) {
                            return <div className={styles.group}>
                                <UnitAvatar
                                    size={this.playersDisabled.includes(record.player.allyCode) ? "small" : "medium"}
                                    key={unitData.characterId + record.player.allyCode}
                                    displayName={false}
                                    playerUnit={playerUnit}
                                    unitData={unitOfPlayer}
                                    className={styles['unit-avatar-player']}
                                />
                                {!this.playersDisabled.includes(record.player.allyCode) && this.getUnitRoles(unitDataPlayer, playerUnit)}
                            </div>
                        } else {
                            return <div className={styles.group}>
                                <UnitAvatar
                                    size={this.playersDisabled.includes(record.player.allyCode) ? "small" : "medium"}
                                    key={unitData.characterId + record.player.allyCode}
                                    displayName={false}
                                    playerUnit={playerUnit}
                                    unitData={unitOfPlayer}
                                    className={styles['unit-avatar-player']}
                                />
                            </div>;
                        }
                    },
                    sorter: (a, b) => {
                        const playerUnitA = a.units.find(x => x.baseId === unitData.characterId);
                        const playerUnitB = b.units.find(x => x.baseId === unitData.characterId);

                        return sortNumber(playerUnitA?.statsSpeed, playerUnitB?.statsSpeed);
                    }
                });
        });

        const spaz = this.props.squadsPerZoneAvailable ?? 0;
        const spac = this.props.selectedPlayersAllyCode?.length ?? 0;
        const unnasignedSquads = spaz - spac;

        // console.log('template: ', this.props.squadsTW?.filter(x => x.allyCode === 385876833));
        // console.log('squadsUsed this zone: ', this.props.squadsUsedThisZone);
        console.log('zoneTW this zone: ', this.props.zoneTW);

        return (
            <div className={styles.container}>
                <div className={header.header}>
                    <div className={header.left}>
                        <h1 className={header.title}>
                            {this.props.template.name}
                        </h1>
                    </div>
                    <div className={header.right}>
                        <Button type={"default"} className={styles.btn} onClick={this.props.onBack}>
                            <FontAwesomeIcon icon={faArrowAltLeft} className={styles.icon}/>
                            Back to Templates
                        </Button>
                        {this.props.zoneTW &&
							<div className={header.unique} title={'Show the players that have assigned squads in this'}>
								<Checkbox
                                    onChange={() => runInAction(() => this.showPlayersForThisZone = !this.showPlayersForThisZone)}
                                    checked={this.showPlayersForThisZone}
                                >
                                    Show the assigned players in this zone
                                </Checkbox>
							</div>
                        }
                            <Button type={"default"} className={styles.btn} onClick={() => this.props.onEditSettings()}
                                disabled={!this.props.isAdmin}>
                            <FontAwesomeIcon icon={faUserCog} className={styles.icon}/>
                            Edit Guild Squad
                        </Button>
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.row}>
                        <TableTitle
                            compareUnits={false}
                            members={this.players.players.length}
                            templateCategory={this.props.template.category}
                            createdBy={this.props.createdBy}
                            lastEditedBy={this.props.editedBy}
                            completedSquads={this.getCompleteSquads()}
                            dateCreated={moment.utc(this.props.createdTime).format("MMMM Do YYYY, HH:mm")}
                            dateEdited={moment.utc(this.props.editedTime).format("MMMM Do YYYY, HH:mm")}
                            unassignedCharacters={unnasignedSquads}
                            assignedSquads={this.props.squadsUsedThisZone?.length}
                            isTW={!!this.props.zoneTW}
                        />
                    </div>
                    {this.props.zoneTW && <div className={`${styles.center} ${styles.space} ${styles.row}`}>
						<div className={styles.GroupBtn}>
							<Button
								title={`Assign ${this.props.zoneTW?.combatType === "Character" ? 'Squads' : 'Fleets'} to Zone #${this.props.zoneTW?.id}`}
								type={'primary'}
								size={'middle'}
								className={styles.btn}
								disabled={this.squadsTW.length === 0 || this.squadsTW.length > (this.props.templateTW ? this.props.templateTW.squadsPerZone : 0)}
								onClick={() => {
                                    let squadsTW = this.squadsTW;
                                    const squadsZoneTW: squadsTW[] = this.props.squadsUsedThisZone?.filter(x => x.squadTypeId !== this.props.template.id) ?? [];
                                    const checkSquadsZoneTWDupes = squadsZoneTW.map(x => x.squadId);

                                    squadsTW = squadsTW.concat(squadsZoneTW);
                                    const uniqueSquadsTW = squadsTW.filter(val => !checkSquadsZoneTWDupes.includes(val.squadId)).concat(squadsZoneTW);

                                    this.props.saveAssignedSquads!(uniqueSquadsTW);
                                }}
							>
								<FontAwesomeIcon icon={faLayerPlus} className={styles.icon}/>
								<span>Assign {this.props.zoneTW?.combatType === "Character" ? 'Squads' : 'Fleets'} to Zone #{this.props.zoneTW?.id}</span>
							</Button>
						</div>
					</div>}
                    <div className={styles.row}>
                        <Input
                            className={styles.search}
                            placeholder={`Search for a player`}
                            allowClear
                            onChange={this.handleFilter}
                            autoFocus={true}
                        />
                        <Button
                            onClick={action(() => this.showFilterModal = true)}
                            type={'primary'}
                            className={`${styles.filter} ${styles.btn}`}
                            title={'Filter the guild squad units'}
                        >
                            <FontAwesomeIcon icon={faFilter} className={styles.icon}/>
                            Filter the guild squad units
                        </Button>
                    </div>
                    <div className={styles.row}>
                        <Table
                            dataSource={this.playersUnit.filter(this.filterPlayers).sort((a, b) => {
                                const playerA = a.player.name;
                                const playerB = b.player.name;

                                if(this.selectedPlayersAllyCode.includes(a.player.allyCode) && !this.props.preferredPlayers?.includes(a.player.allyCode) && !this.props.excludedPlayers?.includes(b.player.allyCode) && this.selectedPlayersAllyCode.includes(b.player.allyCode) && !this.selectedPlayersAllyCode.includes(b.player.allyCode)) {
                                    return 1;
                                } else if(this.selectedPlayersAllyCode.includes(b.player.allyCode) && !this.props.preferredPlayers?.includes(b.player.allyCode) && !this.props.excludedPlayers?.includes(a.player.allyCode) && this.selectedPlayersAllyCode.includes(a.player.allyCode) && !this.selectedPlayersAllyCode.includes(a.player.allyCode)) {
                                    return -1;
                                } else if(playerA && this.playersAllycodeDisabled.includes(b.player.allyCode)) {
                                        return -1;
                                } else if(this.playersAllycodeDisabled.includes(a.player.allyCode) && playerB) {
                                        return 1;
                                } else if(this.selectedPlayersAllyCode.includes(a.player.allyCode) && this.playersAllycodeDisabled.includes(b.player.allyCode)) {
                                    return 1;
                                } else if(this.playersAllycodeDisabled.includes(a.player.allyCode) && this.selectedPlayersAllyCode.includes(b.player.allyCode)) {
                                    return -1;
                                } else if(this.props.preferredPlayers?.includes(a.player.allyCode) && this.props.excludedPlayers?.includes(b.player.allyCode)){
                                    return 1;
                                } else if(this.props.excludedPlayers?.includes(a.player.allyCode) && this.props.preferredPlayers?.includes(b.player.allyCode)){
                                    return 1;
                                } else if(this.props.preferredPlayers?.includes(a.player.allyCode) && playerB){
                                    return -1;
                                } else if(playerA && this.props.preferredPlayers?.includes(b.player.allyCode)) {
                                    return 1;
                                } else if(this.playersAllycodeDisabled.includes(a.player.allyCode) && playerB){
                                    return -1;
                                } else if(playerA && this.playersAllycodeDisabled.includes(b.player.allyCode)) {
                                    return 1;
                                } else if(this.props.excludedPlayers?.includes(a.player.allyCode) && playerB){
                                    return 1;
                                } else if(playerA && this.props.excludedPlayers?.includes(b.player.allyCode)){
                                    return -1;
                                } else if(playerA && a.units.length !== this.props.template.units.length){
                                    return 1;
                                } else if(playerB && b.units.length !== this.props.template.units.length){
                                    return -1;
                                } else {
                                    return playerA.localeCompare(playerB);
                                }
                            })}
                            style={{minWidth: '100%'}}
                            scroll={{x: "auto", y: '50vh'}}
                            bordered={false}
                            pagination={false}
                            size={'small'}
                            rowClassName={this.getRowClassName}
                            className={`${table.table} ${table['custom-minHeight']} ${table.guildSquad} table-squad`}
                            rowKey={record => record.player.allyCode}
                            columns={columns.filter(x => x.visible)}
                            rowSelection={this.props.zoneTW && {
                                fixed: true,
                                selectedRowKeys: this.selectedPlayersAllyCode,
                                getCheckboxProps: (record: IGuildPlayerUnit) => {
                                    const squadSize = this.props.zoneTW?.combatType === "Character" ? 5 : 8;
                                    const firstUnitData = this.props.gameData.units?.find(x => x.baseId === record.units[0]?.baseId);
                                    let disabled = false;
                                    const spz = this.props.squadsPerZone  ?? 0;
                                    const squadsTW: number = this.props.squadsUsedThisZone?.filter(x => x.squadTypeId !== this.props.template.id).length ?? 0;

                                    if (firstUnitData) {
                                        if (
                                            this.playersAllycodeDisabled.includes(record.player.allyCode) ||
                                            this.isUnitAlreadyUsed(record) ||
                                            (record.validUnits.filter(x => x).length !== this.props.template.units.length || record.units.length > squadSize) ||
                                            this.checkMinimumGPUnit(record) ||
                                            (record.units[0].combatType === 2 && !firstUnitData.role?.find(s => s.key === 'role_capital')) ||
                                            this.props.excludedPlayers?.includes(record.player.allyCode) ||
                                            (this.selectedPlayersAllyCode.length + squadsTW  >= spz &&
                                                !this.selectedPlayersAllyCode.includes(record.player.allyCode))
                                        ) {
                                            disabled = true;
                                        }
                                    }
                                    if (this.props.zoneTW) {
                                        const playersDisabled = new Set<number>(this.playersDisabled);

                                        if (disabled) {
                                            playersDisabled.add(record.player.allyCode);
                                        } else {
                                            playersDisabled.delete(record.player.allyCode);
                                        }
                                        runInAction(() => {
                                            this.playersDisabled =  Array.from(new Set(playersDisabled));
                                        })
                                    }

                                    return {
                                        disabled
                                    }
                                },
                                onChange: (selectedRowKeys, selectedRows) => {
                                    const suz = this.props.squadsUsedThisZone?.length ?? 0;
                                    const spz = this.props.squadsPerZoneAvailable ?? 0;

                                    if (selectedRowKeys.length > (suz + spz)) {
                                        return;
                                    }

                                    const validRows = selectedRows.filter(x => this.isValidSquad(x) && this.filterPlayers).slice(0, (spz ?? 0));

                                    if(validRows.length === 0){
                                        runInAction(() => {
                                            this.selectedPlayersAllyCode = [];
                                        })
                                    }

                                    this.onSelectedSquads(validRows);
                                },
                                onSelectAll: (selected, selectedRowKeys, selectedRows) => this.getSelectAll(selectedRowKeys, false, 0, selectedRowKeys.map(x => x.player.allyCode)),
                                selections: [
                                    {
                                        key: 'SELECTION_ALL',
                                        text: 'Select all possible rows',
                                        onSelect: currentRowKeys => this.getSelectAll(this.playersUnit, false, 0, currentRowKeys)
                                    },
                                    {
                                        key: 'SELECTION_TEN',
                                        text: 'Select 10 possible rows',
                                        onSelect: currentRowKeys => this.getSelectAll(this.playersUnit, false, 10, currentRowKeys)
                                    },
                                    {
                                        key: 'SELECTION_FIFTEEN',
                                        text: 'Select 15 possible rows',
                                        onSelect: currentRowKeys => this.getSelectAll(this.playersUnit, false, 15, currentRowKeys)
                                    },
                                    {
                                        key: 'SELECTION_TWENTY',
                                        text: 'Select 20 possible rows',
                                        onSelect: currentRowKeys => this.getSelectAll(this.playersUnit, false, 20, currentRowKeys)
                                    },
                                    {
                                        key: 'SELECTION_TWENTYFIVE',
                                        text: 'Select 25 possible rows',
                                        onSelect: currentRowKeys => this.getSelectAll(this.playersUnit, false, 25, currentRowKeys)
                                    },
                                    {
                                        key: 'SELECTION_THIRTY',
                                        text: 'Select 30 possible rows',
                                        onSelect: currentRowKeys => this.getSelectAll(this.playersUnit, false, 30, currentRowKeys)
                                    },
                                    {
                                        key: 'SELECTION_INVERT',
                                        text: 'Invert your selection',
                                        onSelect: currentRowKeys => this.getSelectAll(this.playersUnit, true, 0, currentRowKeys)
                                    },
                                    Table.SELECTION_NONE,
                                ],
                            }}
                        />
                    </div>
                </div>
                {this.renderFilterSquadModal()}
            </div>
        );
    }
}