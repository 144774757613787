import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Menu } from "antd";
import UserData from "../../model/UserData";
import styles from "./styles/GlobalMenu.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
{
    faAnalytics,
    faBoxOpen,
    faConciergeBell,
    faFileChartPie,
    faHeadVr,
    faIdBadge,
    faJournalWhills,
    faMicroscope,
    faPlayCircle,
    faRetweetAlt,
    faRing,
    faSave,
    faSpaceStationMoonAlt,
    faTasks,
    faTasksAlt,
    faUserCog,
    faUserEdit,
    faHistory,
    faCalendarDay,
    faHeartBroken,
    faHelmetBattle,
    faAbacus,
    faSwordsLaser,
    faStarfighter,
    faTools,
    faInfoCircle,
    faSearch,
    faTicketAlt,
    faMapMarkedAlt,
    faShareAlt,
    faStopwatch,
    faFarm,
    faEngineWarning,
    faUserAstronaut,
    faMap,
    faHome,
    faDatabase,
    faHeadSideBrain,
    faHatWizard,
    faShieldAlt,
    faRobotAstromech, faPresentationScreen, faHammerCrash, faBoxDollar,
    faUserPolice,
    faPersonDigging, faRulerVertical, faBarsProgress, faNewspaper
} from "@fortawesome/pro-duotone-svg-icons";
import
{
    faGalacticRepublic,
    faGuilded, faOldRepublic,
    faPhoenixSquadron,
    faRebel, faYoutubeSquare
} from "@fortawesome/free-brands-svg-icons";
import SubMenu from "antd/lib/menu/SubMenu";

interface IProps extends RouteComponentProps<any>
{
    showEventsLink?: boolean;
    user?: UserData;
    isCollapsed: boolean;
    isMobile: boolean;
}

class GlobalMenu extends React.Component<IProps> {
    getPathName()
    {
        if (
            (this.props.location.pathname.split("/")[1] === "guild") &&
            (this.props.location.pathname.split("/")[2] === "compare") &&
            (this.props.location.pathname.split("/")[3] === "scouting")
        )
        {
            return "/tw/search";
        } else if (
            (this.props.location.pathname.split("/")[2] === "compare") ||
            (this.props.location.pathname.split("/")[2] === "members" && !!this.props.location.pathname.split("/")[3]?.match(/^-?\d+$/))
        )
        {
            return "/guild/search";
        } else if (this.props.location.pathname.startsWith("/tb/planning"))
        {
            return "/tb/planning";
        } else if (this.props.location.pathname.startsWith("/gac/view"))
        {
            return "/gac/history";
        }
        return this.props.location.pathname;
    }

    render()
    {
        let showFullEventsLink = this.props.showEventsLink && this.props.user && this.props.user.currentPlayer;
        let isReadOnly = !this.props.user || this.props.user.readOnly;
        let allyCode = "";
        if (this.props.user && this.props.user.currentPlayer)
            allyCode = this.props.user.currentPlayer.allyCode;

        const inGuild = this.props.user && this.props.user.currentPlayer && this.props.user.currentPlayer.guildName !== null;


        return (
            <Menu
                theme="light"
                mode="inline"
                selectedKeys={[this.getPathName()]}
                className={`${styles.menu} ${this.props.isCollapsed ? (this.props.isMobile ? '' : styles.collapsed) : styles.expanded} ${this.props.isMobile ? styles.mobile : ''}`}
                defaultOpenKeys={["/" + this.getPathName().split("/")[1]]}
            >
                <Menu.ItemGroup key="dashboard" className={styles.category}>
                    <Menu.Item key="/" icon={<FontAwesomeIcon icon={faHome} className={styles.icon} />} className={`${styles.item} ${styles.pad0}`}>
                        <Link to="/" className={styles.link}>Home</Link>
                    </Menu.Item>
                    <Menu.Item key="/squads" icon={<FontAwesomeIcon icon={faPhoenixSquadron} className={styles.icon} />} className={`${styles.item} ${styles.pad0}`}>
                        <Link to="/squads" className={styles.link}>Squads</Link>
                    </Menu.Item>
                    {window.location.host === "localhost:3010" && <Menu.Item key={"/developer"} icon={<FontAwesomeIcon icon={faEngineWarning} className={styles.icon} />} className={`${styles.item} ${styles.pad0}`}>
                        <Link to={"/developer"} className={styles.link}>Developer Tools</Link>
                    </Menu.Item>}
                    <Menu.Item key="/contentcreators" icon={<FontAwesomeIcon icon={faYoutubeSquare} className={styles.icon} />} className={`${styles.item} ${styles.pad0}`}>
                        <Link to="/contentcreators" className={styles.link}>Video Content</Link>
                    </Menu.Item>
                </Menu.ItemGroup>

                <SubMenu key="/player" icon={<FontAwesomeIcon icon={faUserAstronaut} className={styles.icon} />} title="Player" className={styles.category}>
                    <Menu.Item key="/player/profile" icon={<FontAwesomeIcon icon={faIdBadge} className={styles.icon} />} className={`${styles.item}`}>
                        <Link to="/player/profile" className={styles.link}>Profile</Link>
                    </Menu.Item>
                    <Menu.Item key="/player/bronzium" icon={<FontAwesomeIcon icon={faBoxOpen} className={styles.icon} />} className={`${styles.item} `}>
                        <Link to="/player/bronzium" className={styles.link}>Bronziums</Link>
                    </Menu.Item>
                    <Menu.Item key={"/player/farming/" + allyCode} icon={<FontAwesomeIcon icon={faFarm} className={styles.icon} />} className={`${styles.item}`}>
                        <Link to={"/player/farming/" + allyCode} className={styles.link}>Farming</Link>
                    </Menu.Item>
                    <Menu.Item key="/player/salvage/" icon={<FontAwesomeIcon icon={faHammerCrash} className={styles.icon} />} className={`${styles.item}`}>
                        <Link to="/player/salvage/" className={styles.link}>Salvage</Link>
                    </Menu.Item>
                    <Menu.Item key="/player/datacrons/" icon={<FontAwesomeIcon icon={faBoxDollar} className={styles.icon} />} className={`${styles.item}`}>
                        <Link to="/player/datacrons/" className={styles.link}>Datacrons</Link>
                    </Menu.Item>
                    <Menu.Item key={"/player/events"} icon={<FontAwesomeIcon icon={faCalendarDay} className={styles.icon} />} className={`${styles.item}`}>
                        <Link to={"/player/events"} className={styles.link}>Events</Link>
                    </Menu.Item>
                    {!isReadOnly && <Menu.Item key="/settings/share" icon={<FontAwesomeIcon icon={faShareAlt} className={styles.icon} />} className={styles.item}>
                        <Link to="/settings/share" className={styles.link}>Sharing</Link>
                    </Menu.Item>}

                    {window.location.host !== "hotutils.com" && <Menu.Item key="/player/strangetracker" icon={<FontAwesomeIcon icon={faFarm} className={styles.icon} />} className={styles.item}>
                        <Link to="/player/strangetracker" className={styles.link}>Tracker For Strange</Link>
                    </Menu.Item>}
                </SubMenu>

                <SubMenu key="/gac" icon={<FontAwesomeIcon icon={faShieldAlt} className={styles.icon} />} title="GAC" className={styles.category}>
                    <Menu.Item key="/gac/current" icon={<FontAwesomeIcon icon={faFileChartPie} className={styles.icon} />} className={styles.item}>
                        <Link to="/gac/current" className={styles.link}>Current</Link>
                    </Menu.Item>
                    <Menu.Item key="/gac/compare" icon={<FontAwesomeIcon icon={faFileChartPie} className={styles.icon} />} className={styles.item}>
                        <Link to="/gac/compare" className={styles.link}>GAC Compare</Link>
                    </Menu.Item>
                    <Menu.Item key="/gac/history" icon={<FontAwesomeIcon icon={faHistory} className={styles.icon} />} className={styles.item}>
                        <Link to="/gac/history" className={styles.link}>History</Link>
                    </Menu.Item>
                    <Menu.Item key="/gac/planning" icon={<FontAwesomeIcon icon={faMap} className={styles.icon} />} className={styles.item}>
                        <Link to="/gac/planning" className={styles.link}>Planning</Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu key="/mods" icon={<FontAwesomeIcon icon={faAbacus} className={styles.icon} />} title="Mods" className={styles.category}>
                    <Menu.Item key="/mods/loadouts" icon={<FontAwesomeIcon icon={faTasksAlt} className={styles.icon} />} className={styles.item}>
                        <Link to="/mods/loadouts" className={styles.link}>Loadouts</Link>
                    </Menu.Item>
                    <Menu.Item key="/mods/management" icon={<FontAwesomeIcon icon={faTasks} className={styles.icon} />} className={styles.item}>
                        <Link to="/mods/management" className={styles.link}>Management</Link>
                    </Menu.Item>
                    <Menu.Item key="/mods/optimizer"
                        icon={<FontAwesomeIcon icon={faMicroscope} className={styles.icon} />} className={styles.item}>
                        <Link to="/mods/optimizer" className={styles.link}>GI's Optimizer</Link>
                    </Menu.Item>
                    <Menu.Item key="/mods/wizard"
                        icon={<FontAwesomeIcon icon={faHatWizard} className={styles.icon} />} className={styles.item}>
                        <Link to="/mods/wizard" className={styles.link}>Wizard</Link>
                    </Menu.Item>

                    <Menu.Item key="/mods/playground" icon={<FontAwesomeIcon icon={faRing} className={styles.icon} />} className={styles.item}>
                        <Link to="/mods/playground" className={styles.link}>Playground</Link>
                    </Menu.Item>
                    <Menu.Item key="/mods/analysis"
                        icon={<FontAwesomeIcon icon={faAnalytics} className={styles.icon} />} className={styles.item}>
                        <Link to="/mods/analysis" className={styles.link}>Analysis</Link>
                    </Menu.Item>

                    <Menu.Item key="/mods/depth"
                        icon={<FontAwesomeIcon icon={faRulerVertical} className={styles.icon} />} className={styles.item}>
                        <Link to="/mods/depth" className={styles.link}>Depth</Link>
                    </Menu.Item>


                    <Menu.Item key="/mods/maintenance"
                        icon={<FontAwesomeIcon icon={faPersonDigging} className={styles.icon} />} className={styles.item}>
                        <Link to="/mods/maintenance" className={styles.link}>Maintenance</Link>
                    </Menu.Item>

                    <Menu.Item key="/mods/swap" icon={<FontAwesomeIcon icon={faRetweetAlt} className={styles.icon} />} className={styles.item}>
                        <Link to="/mods/swap" className={styles.link}>Swap</Link>
                    </Menu.Item>

                    <Menu.Item key="/mods/services"
                        icon={<FontAwesomeIcon icon={faConciergeBell} className={styles.icon} />} className={styles.item}>
                        <Link to="/mods/services" className={styles.link}>Services</Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu key="/conquest" icon={<FontAwesomeIcon icon={faSwordsLaser} className={styles.icon} />} title="Conquest" className={styles.category}>
                    <Menu.Item key="/conquest/data_disks"
                        icon={<FontAwesomeIcon icon={faSave} className={styles.icon} />} className={styles.item}>
                        <Link to="/conquest/data_disks" className={styles.link}>Data Disks</Link>
                    </Menu.Item>
                    <Menu.Item key="/conquest/status"
                        icon={<FontAwesomeIcon icon={faRetweetAlt} className={styles.icon} />} className={styles.item}>
                        <Link to="/conquest/status" className={styles.link}>Status</Link>
                    </Menu.Item>
                </SubMenu>

                {inGuild &&
                    <React.Fragment>
                        <SubMenu key="/tb" icon={<FontAwesomeIcon icon={faSpaceStationMoonAlt} className={styles.icon} />} title="TB" className={styles.category}>
                            <Menu.Item key="/tb/status" icon={<FontAwesomeIcon icon={faInfoCircle} className={styles.icon} />} className={styles.item}>
                                <Link to="/tb/status" className={styles.link}>Status</Link>
                            </Menu.Item>
                            <Menu.Item key="/tb/players" icon={<FontAwesomeIcon icon={faHeadVr} className={styles.icon} />} className={styles.item}>
                                <Link to="/tb/players" className={styles.link}>Players</Link>
                            </Menu.Item>
                            <Menu.Item key="/tb/planning" icon={<FontAwesomeIcon icon={faMapMarkedAlt} className={styles.icon} />} className={styles.item}>
                                <Link to="/tb/planning" className={styles.link}>Planning</Link>
                            </Menu.Item>
                            <Menu.Item key="/tb/command" icon={<FontAwesomeIcon icon={faOldRepublic} className={styles.icon} />} className={styles.item}>
                                <Link to="/tb/command" className={styles.link}>Command</Link>
                            </Menu.Item>
                            <Menu.Item key="/tb/data" icon={<FontAwesomeIcon icon={faDatabase} className={styles.icon} />} className={styles.item}>
                                <Link to="/tb/data" className={styles.link}>Data</Link>
                            </Menu.Item>


                            <Menu.Item key="/tb/strategy" icon={<FontAwesomeIcon icon={faHeadSideBrain} className={styles.icon} />} className={styles.item}>
                                <Link to="/tb/strategy" className={styles.link}>Manage</Link>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu key="/tw" title="TW" icon={<FontAwesomeIcon icon={faStarfighter} className={styles.icon} />} className={styles.category}>
                            <Menu.Item key="/tw/current"
                                icon={<FontAwesomeIcon icon={faJournalWhills} className={styles.icon} />} className={styles.item}>
                                <Link to="/tw/current" className={styles.link}>Current</Link>
                            </Menu.Item>
                            <Menu.Item key="/tw/search" icon={<FontAwesomeIcon icon={faSearch} className={styles.icon} />} className={styles.item}>
                                <Link to="/tw/search" className={styles.link}>Scouting</Link>
                            </Menu.Item>
                            <Menu.Item key="/tw/history" icon={<FontAwesomeIcon icon={faHistory} className={styles.icon} />} className={styles.item}>
                                <Link to="/tw/history" className={styles.link}>History</Link>
                            </Menu.Item>
                            <Menu.Item key="/tw/scoreboard" icon={<FontAwesomeIcon icon={faPresentationScreen} className={styles.icon} />} className={styles.item}>
                                <Link to="/tw/scoreboard" className={styles.link}>Scoreboard</Link>
                            </Menu.Item>
							<Menu.Item key="/tw/command" icon={<FontAwesomeIcon icon={faOldRepublic} className={styles.icon} />} className={styles.item}>
								<Link to="/tw/command" className={styles.link}>Command</Link>
							</Menu.Item>
                            <Menu.Item key="/tw/planning" icon={<FontAwesomeIcon icon={faRobotAstromech} className={styles.icon} />} className={styles.item}>
                                <Link to="/tw/planning" className={styles.link}>Planning</Link>
                            </Menu.Item>
                            <Menu.Item key="/tw/reporting" icon={<FontAwesomeIcon icon={faNewspaper} className={styles.icon} />} className={styles.item}>
                                <Link to="/tw/reporting" className={styles.link}>Reporting</Link>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu key="/guild" title="Guild" icon={<FontAwesomeIcon icon={faGalacticRepublic} className={styles.icon} />} className={styles.category}>
                            <Menu.Item key="/guild/members" icon={<FontAwesomeIcon icon={faGuilded} className={styles.icon} />} className={styles.item}>
                                <Link to="/guild/members" className={styles.link}>Overview</Link>
                            </Menu.Item>
                            <Menu.Item key="/guild/units" icon={<FontAwesomeIcon icon={faHelmetBattle} className={styles.icon} />} className={styles.item}>
                                <Link to="/guild/units" className={styles.link}>Units</Link>
                            </Menu.Item>
                            <Menu.Item key="/guild/tickets" icon={<FontAwesomeIcon icon={faTicketAlt} className={styles.icon} />} className={styles.item}>
                                <Link to="/guild/tickets" className={styles.link}>Tickets</Link>
                            </Menu.Item>
                            <Menu.Item key="/guild/events" icon={<FontAwesomeIcon icon={faCalendarDay} className={styles.icon} />} className={styles.item}>
                                <Link to="/guild/events" className={styles.link}>Events</Link>
                            </Menu.Item>
                            <Menu.Item key="/guild/violations" icon={<FontAwesomeIcon icon={faHeartBroken} className={styles.icon} />} className={styles.item}>
                                <Link to="/guild/violations" className={styles.link}>Violations</Link>
                            </Menu.Item>
                            <Menu.Item key="/guild/search" icon={<FontAwesomeIcon icon={faSearch} className={styles.icon} />} className={styles.item}>
                                <Link to="/guild/search" className={styles.link}>Search</Link>
                            </Menu.Item>
                            {window.location.host !== "hotutils.com" && <Menu.Item key="/guild/watch" icon={<FontAwesomeIcon icon={faStopwatch} className={styles.icon} />} className={styles.item}>
                                <Link to="/guild/watch" className={styles.link}>Watch</Link>
                            </Menu.Item>}
                            <Menu.Item key="/guild/farming" icon={<FontAwesomeIcon icon={faFarm} className={styles.icon} />} className={styles.item}>
                                <Link to="/guild/farming" className={styles.link}>Farming</Link>
                            </Menu.Item>
                            <Menu.Item key="/guild/glProgress" icon={<FontAwesomeIcon icon={faBarsProgress} className={styles.icon} />} className={styles.item}>
                                <Link to="/guild/glProgress" className={styles.link}>GL Progress</Link>
                            </Menu.Item>
                            <Menu.Item key="/guild/alliances" icon={<FontAwesomeIcon icon={faRebel} className={styles.icon} />} className={styles.item}>
                                <Link to="/guild/alliances" className={styles.link}>Alliances</Link>
                            </Menu.Item>
                        </SubMenu>
                    </React.Fragment>
                }


                <SubMenu key="/template" title="Template" icon={<FontAwesomeIcon icon={faUserEdit} className={styles.icon} />} className={styles.category}>
                    <Menu.Item key="/template/editor"
                        icon={<FontAwesomeIcon icon={faUserEdit} className={styles.icon} />} className={styles.item}>
                        <Link to="/template/editor" className={styles.link}>Editor</Link>
                    </Menu.Item>


                    <Menu.Item key="/template/squadcompliance"
                        icon={<FontAwesomeIcon icon={faUserPolice} className={styles.icon} />} className={styles.item}>
                        <Link to="/template/squadcompliance" className={styles.link}>Squad Editor</Link>
                    </Menu.Item>

                    <Menu.Item key="/template/execute"
                        icon={<FontAwesomeIcon icon={faPlayCircle} className={styles.icon} />} className={styles.item}>
                        <Link to="/template/execute" className={styles.link}>Execute</Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu key="/other" title="Other" icon={<FontAwesomeIcon icon={faTools} className={styles.icon} />} className={styles.category}>
                    <Menu.Item key="/gear" icon={<FontAwesomeIcon icon={faUserCog} className={styles.icon} />} className={styles.item}>
                        {showFullEventsLink ?
                            <a href={'https://gear.swgohevents.com/?HotAllyCode=' + this.props.user!.currentPlayer!.allyCode}
                                target="_blank" rel="noopener noreferrer">SE: Gear</a>
                            :
                            <a href={'https://gear.swgohevents.com'} target="_blank" rel="noopener noreferrer"> SE:
                                Gear</a>
                        }
                    </Menu.Item>
                </SubMenu>

            </Menu>
        );
    }
}

export default withRouter(GlobalMenu);
