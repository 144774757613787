import moment from "moment";

let tbDates = ["2024-03-04", "2024-03-05", "2024-03-06", "2024-03-07", "2024-03-08", "2024-03-09",];


export function isTBPlanningBlackout(): boolean
{
    // no blackout on planning!
    return false;
}

export function isDMBlackout(): boolean
{
    if (!tbDates.includes(moment().format("YYYY-MM-DD")))
        return false;

    let now = moment.utc();
    if (now.hour() === 17 && now.minute() >= 30)
        return true;
    if (now.hour() === 18 || now.hour() === 19)
        return true;

    return false;
}

