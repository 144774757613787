import {IGuildPlayer} from "../../../../model/GuildData";
import styles from "../styles/Templates.module.scss";
import React from "react";
import IPageProps from "../../../IPageProps";
import LoadingSpinner from "../../../../components/Loading/LoadingSpinner";
import {Space, Table} from "antd";
import {TotalSquadsAssigned, TotalZonesAssigned} from "./TotalSquadsAssigned";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserHardHat} from "@fortawesome/pro-duotone-svg-icons";
import table from "../../../../styles/components/Table.module.scss";
import {ColumnType} from "antd/es/table";
import {ITerritoryWarsData} from "../../../../model/TWPlanning";

interface ITWPlayersProps extends IPageProps{
    template: ITerritoryWarsData;
    members: IGuildPlayer[];
}

export default class TWPlayers extends React.PureComponent<ITWPlayersProps> {
    render() {

        if (!this.props.members) {
            return <LoadingSpinner size={"large"} spinning={true} text={'Loading the guild data...'}/>;
        }

        const columns: ColumnType<IGuildPlayer>[] = [
            {
                title: '#',
                render: (record: IGuildPlayer, item, index) => index + 1,
                key: 'index',
                fixed: 'left',
                width: 50,
            },
            {
                title: 'Name',
                key: 'name',
                fixed: 'left',
                width: 250,
                render: (record: IGuildPlayer) => {
                    return (
                        <Space size="middle" className={`${table.name}`}>
                            <FontAwesomeIcon icon={faUserHardHat} />
                            {record.name.toLocaleString()}
                        </Space>
                    );
                },
                sorter: (a: IGuildPlayer, b: IGuildPlayer) => a.name.localeCompare(b.name),
            },
            {
                title: 'GP',
                render: (record: IGuildPlayer) => {
                    return record.galacticPower
                },
                key: 'powerTotal',
                sorter: (a: IGuildPlayer, b: IGuildPlayer) => a.galacticPower - b.galacticPower,
            },
            {
                title: 'Assigned Zones',
                render: (record: IGuildPlayer) => {

                    return <div className={styles.zones} title={TotalZonesAssigned(record, this.props.template).join(', ')}>
                        {TotalZonesAssigned(record, this.props.template).join(' | ')}
                    </div>;
                },
                key: 'zones',
            },
            {
                title: '# of Squads',
                render: (record: IGuildPlayer) => {
                    const total = TotalSquadsAssigned(record, "squad", this.props.template);

                    return total === 0 ?
                        <div className={styles['cell-highlighted']}>
                            {total}
                        </div> : total;
                },
                key: 'squads',
                defaultSortOrder: 'ascend',
                sorter: (a: IGuildPlayer, b: IGuildPlayer) => TotalSquadsAssigned(a, "squad", this.props.template, ) - TotalSquadsAssigned(b, "squad", this.props.template),
            },
            {
                title: '# of Fleets',
                render: (record: IGuildPlayer) => {
                    const total = TotalSquadsAssigned(record, "ship", this.props.template);

                    return total === 0 ?
                        <div className={styles['cell-highlighted']}>
                            {total}
                        </div> : total;
                },
                key: 'fleets',
                defaultSortOrder: 'ascend',
                sorter: (a: IGuildPlayer, b: IGuildPlayer) => TotalSquadsAssigned(a, "ship", this.props.template) - TotalSquadsAssigned(b, "ship", this.props.template),
            },
        ]

        return (
            <Table
                dataSource={this.props.members.filter(x => x.guildRank >= 2)}
                style={{minWidth: '100%'}}
                scroll={{x: 800, y: 500}}
                bordered={false}
                pagination={false}
                size={'small'}
                className={`${table.table} ${table['custom-minHeight']}`}
                rowKey={record => record.allyCode}
                columns={columns}
            />
        );
    }
}