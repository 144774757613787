import React from "react";
import {observer} from "mobx-react";
import IPageProps from "../../../IPageProps";
import {action, observable, runInAction} from "mobx";
import {Button, Checkbox, Input, Modal, Popconfirm, Radio, Select} from "antd";
import styles from "../../../../styles/components/Modal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faShareSquare, faTrashAlt} from "@fortawesome/pro-duotone-svg-icons";
import {IGuildSquadTemplate, IGuildSquadUnits, squadCategories} from "../../../../model/Squads";
import GroupUnitAvatar from "../../../../components/swgoh/Character/UnitAvatarGroup";
import {faPlus} from "@fortawesome/pro-solid-svg-icons";
import {AddPoolUnitModal} from "./AddPoolUnitModal";
import UnitAvatarUndefined from "../../../../components/swgoh/Character/UnitAvatarUndefined";
import DatacronAffixPicker from "../../../../components/swgoh/Datacron/DatacronAffixPicker";

interface IAddPoolSquadModalProps extends IPageProps{
    showModal: boolean;
    onClose: () => void;
    onDelete?: () => void;
    template: IGuildSquadTemplate | undefined;
    onSave: (title: string, category: string[], selectedCharacters: IGuildSquadUnits[], poolCharactersId: string[], combatType: number, isDuplicate: boolean, datacronAffixes: string[], datacronRequired: boolean) => void;
}

@observer
export class AddPoolSquadModal extends React.PureComponent<IAddPoolSquadModalProps> {
    @observable showModal: boolean = false;
    @observable title: string = this.props.template?.name || '';
    @observable category: string[] = this.props.template?.category || [];
    @observable unitModalCharacter?: IGuildSquadUnits = undefined;
    @observable selectedCharacters: IGuildSquadUnits[] = this.props.template?.units || [];
    @observable filterUnitType: number = this.props.template?.combatType || 1;
    @observable poolCharactersId: string[] = this.props.template?.poolUnits || [];
    @observable poolUnits: number = 0;
    @observable hasUltimate: boolean = false;
    @observable minGP: number = 100;
    @observable minStars: number = 2;
    @observable minGearLevel: number = 1;
    @observable minRelicLevel: number = 0;
    @observable datacronAffixes: string[] = this.props.template?.datacronAffixes || [];
    @observable datacronRequired: boolean = this.props.template?.datacronRequired ?? false;

    componentDidMount() {
        const template = this.props.template;
        let poolUnits = 0;

        if(template) {
            for (let i = 0; i < template.units.length; i++) {
                const unit = template.units[i];

                if(unit.poolUnit){
                    poolUnits++
                }
            }
            runInAction(() => this.poolUnits = poolUnits);
        }
    }

    @action private cancel() {
        return "";
    }
    @action
    private addUnit() {
        this.unitModalCharacter = {
            id: -1,
            characterId: "",
            characterName: "",
            requirements: {
                hasOmicron: false,
                hasZeta: false,
                hasUltimate: false,
                subsPriority: "gear",
                filters: {
                    minGP: this.minGP,
                    gear: this.minGearLevel,
                    relic: this.minRelicLevel,
                    stars: this.minStars
                },
                subs: []
            }
        };
        this.showModal = true;
    }

    @action
    private saveUnit(character: IGuildSquadUnits) {
        this.unitModalCharacter = undefined;
        const idx = this.selectedCharacters.findIndex(x => x.id === character.id);

        if (idx !== -1) {
            this.selectedCharacters[idx] = character;
        } else {
            character.id = Math.max(-1, ...this.selectedCharacters.map(x => x.id)) + 1;
            this.selectedCharacters.push(character);
        }

        this.showModal = false;
    }

    @action
    private deleteUnit() {
        const character = this.unitModalCharacter;

        if (!this.showModal || !character) {
            return null;
        }

        const idx = this.selectedCharacters.findIndex(x => x.id === character.id);

        if (idx !== -1) {
            const selectedCharacters = this.selectedCharacters.slice();
            selectedCharacters.splice(idx, 1);

            this.selectedCharacters = selectedCharacters;
        }
        this.unitModalCharacter = undefined;
        this.showModal = false;
    }

    private renderAddUnitModal() {
        const character = this.unitModalCharacter;
        const unit = this.props.gameData.units;

        if (!this.showModal || !character || unit === undefined) {
            return null;
        }
        const otherSelectedCharacters = (this.selectedCharacters.filter(x => x.characterId !== character.characterId));

        return (
            <AddPoolUnitModal
                {...this.props}
                character={character}
                filterTypeCombat={this.filterUnitType}
                minGP={this.minGP}
                minStars={this.minStars}
                minGearLevel={this.minGearLevel}
                minRelicLevel={this.minRelicLevel}
                otherSelectedCharacters={otherSelectedCharacters.map(x => x.characterId).concat(this.poolCharactersId)}
                showModal={this.showModal}
                mainCharacterHasZeta={character.requirements?.hasZeta}
                mainCharacterHasUltimate={character.requirements?.hasUltimate}
                unitNumber={this.selectedCharacters.length + 1}
                onClose={() => runInAction(() => {
                    if(character.poolUnit){
                        this.poolUnits++;
                    }
                    this.showModal = false;
                })}
                onSave={(characterUnit) => {
                    this.saveUnit(characterUnit);
                    runInAction(() => {
                        this.hasUltimate = characterUnit.requirements.hasUltimate;
                        this.minGP = characterUnit.requirements.filters.minGP;
                        this.minGearLevel = characterUnit.requirements.filters.gear;
                        this.minRelicLevel = characterUnit.requirements.filters.relic;
                        this.minStars = character.requirements.filters.stars;

                        if(characterUnit.characterId !== 'pool' && this.filterUnitType !== unit?.find(u => u.baseId === characterUnit.characterId)!.combatType){
                            this.filterUnitType = 1;
                        }
                        if(characterUnit.poolUnit ){
                            runInAction(() => this.poolUnits++);
                        }
                    })
                }}
                onDelete={() => this.deleteUnit()}
            />
        );
    }

    private renderAddPoolUnits() {
        const units = this.props.gameData.units || [];

        return (
            <div className={`${styles.row} ${styles['no-offset']}`}>
                <div className={styles.label}>Select the pool units:</div>
                <div className={`${styles.flexRow} ${styles.row} ${styles['no-offset']}`}>
                    <Select
                        className={`${styles.select}`}
                        placeholder={'Select at least one unit for the pool list'}
                        autoFocus={true}
                        showSearch={true}
                        filterOption={(input, option) =>
                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={this.poolUnits === 0}
                        mode={"multiple"}
                        value={Array.isArray(this.poolCharactersId) ? this.poolCharactersId : [this.poolCharactersId]}
                        onChange={value => {
                            runInAction(() => {
                                this.poolCharactersId = value;
                            })
                        }}
                        showArrow={true}
                    >
                        {units.filter(unit => {
                            if (this.selectedCharacters.find(x => x.characterId === unit.baseId)) {
                                return false;
                            }
                            if(this.filterUnitType === 2) {
                                return (unit.combatType === 2 && unit.role?.find(s => s.key !== 'role_capital'));
                            } else if (this.filterUnitType === 1) {
                                return unit.combatType === 1
                            }

                            return unit;

                        }).map(unit => {
                            return(
                                <Select.Option
                                    disabled={this.poolCharactersId.length === 10 && !this.poolCharactersId.includes(unit.baseId)}
                                    key={unit.baseId}
                                    value={unit.baseId}
                                >
                                    {unit.name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Modal
                title={`${this.props.template?.name ? "Edit the" : "Create a new"} Pool Guild Squad template`}
                visible={this.props.showModal}
                onCancel={this.props.onClose}
                maskClosable={false}
                width={680}
                footer={[
                    (this.props.template?.name && this.props.onDelete !== undefined && <Popconfirm
						title="Are you sure to delete this template?"
						onConfirm={() => this.props.onDelete && this.props.onDelete()}
						key={'popconfirmPoolSquadTemplate'}
						onCancel={() => this.cancel()}
						okText="Yes"
						cancelText="No"
					>
						<Button key="deletePoolSquadGuild" type="link" className={styles.delete}>
							<FontAwesomeIcon icon={faTrashAlt} className={styles.icon} />
							Delete
						</Button>
					</Popconfirm>),
                    this.props.template?.name && this.props.onDelete !== undefined && <Button
						key="savePoolGuildSquadAsNew"
						type="primary"
						onClick={() => {
                            this.props.onSave(this.title, this.category, this.selectedCharacters, this.poolCharactersId, this.filterUnitType, true, this.datacronAffixes, this.datacronRequired);
                        }}
						disabled={
                            this.poolUnits > this.poolCharactersId.length ||
                            this.title.length < 1 ||
                            this.category.length < 1
                        }
					>
						<FontAwesomeIcon icon={faShareSquare} className={styles.icon}/>
						Save as new
					</Button>,
                    <Button
                        key="savePoolGuildSquad"
                        type="primary"
                        onClick={() => {
                            this.props.onSave(this.title, this.category, this.selectedCharacters, this.poolCharactersId, this.filterUnitType, false, this.datacronAffixes, this.datacronRequired);
                        }}
                        disabled={
                            this.poolUnits > this.poolCharactersId.length ||
                            this.title.length < 1 ||
                            this.category.length < 1
                    }
                    >
                        <FontAwesomeIcon icon={faSave} className={styles.icon} />
                        Save
                    </Button>
                ]}
            >
                <div className={styles.modal}>
                    <div className={styles.row}>
                        <div className={`${styles.label} ${styles.required}`}>Title:</div>
                        <Input
                            className={`${styles.input}`}
                            value={this.title}
                            onChange={event => {runInAction(() => {this.title = event.target.value;})}}
                            type={"text"}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={`${styles.label} ${styles.required}`}>Category:</div>
                        <Select
                            showSearch={true}
                            mode={"multiple"}
                            placeholder={"Select a category"}
                            allowClear={true}
                            className={`${styles.select}`}
                            value={Array.isArray(this.category) ? this.category : [this.category]}
                            onChange={value => {
                                runInAction(() => this.category = value)
                            }}
                            showArrow={true}
                        >
                            {squadCategories.map(i =>
                                <Select.Option key={i} value={i}>{i}</Select.Option>
                            )}
                        </Select>
                    </div>
                    <div className={styles.row}>
                        <div className={`${styles.label}`}>Unit type:</div>
                        <div className={styles.filter}>
                            <Radio.Group
                                defaultValue={this.props.template === undefined ? 1 : this.props.template.combatType === this.filterUnitType ? this.props.template.combatType : this.filterUnitType}
                                value={this.filterUnitType}
                                buttonStyle={"solid"}
                                optionType="button"
                            >
                                <Radio.Button
                                    value={1}
                                    onClick={(e) => runInAction(() => {
                                        if(this.filterUnitType === 2) {
                                            this.selectedCharacters = [];
                                        }
                                        this.filterUnitType = 1;
                                    })}
                                >
                                    Only characters
                                </Radio.Button>
                                <Radio.Button
                                    value={2}
                                    onClick={(e) => runInAction(() => {
                                        if(this.filterUnitType !== 2){
                                            this.selectedCharacters = [];
                                        }
                                        this.filterUnitType = 2;
                                    })}
                                >
                                    Only ships
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    {this.filterUnitType === 1 && <div className={styles.row}>
                        <div className={`${styles.label}`}>Datacrons:</div>
                        <DatacronAffixPicker values={this.datacronAffixes} 
                            onChange={(value) => runInAction(() => this.datacronAffixes = value)}/>
                        <Checkbox checked={this.datacronRequired} 
                            onChange={(e) => runInAction(() => this.datacronRequired = e.target.checked)}>
                                Require Datacron
                        </Checkbox>
                    </div>
                    }
                    <div className={`${styles.units} ${this.filterUnitType === 2 ? styles.ship : ''}`}>
                        {this.selectedCharacters.map(squadUnit => {
                            let unitData = this.props.gameData.units?.find(x => x.baseId === squadUnit.characterId);
                            const firstUnit = this.props.gameData.units?.find(x => x.baseId === this.selectedCharacters[0].characterId);
                            const index = squadUnit.id

                            if(firstUnit && squadUnit.characterId === 'pool') {
                                return <UnitAvatarUndefined
                                    name={"Pool Unit"}
                                    baseImage={"blank-character"}
                                    combatType={firstUnit.combatType}
                                    alignment={3}
                                    stars={7}
                                    key={index}
                                    gear={squadUnit.requirements?.filters.gear}
                                    relic={squadUnit.requirements?.filters.relic}
                                    showName={this.filterUnitType !== 2}
                                    className={`${styles.unit} ${index === 1 && firstUnit.combatType === 2 ? styles['ship-lineup1'] : ''} ${index === 2 && firstUnit.combatType === 2 ? styles['ship-lineup2'] : ''} ${index === 3 && firstUnit.combatType === 2 ? styles['ship-lineup3'] : ''}`}
                                    onClick={() => runInAction(() => {
                                        this.poolUnits = this.poolUnits - 1
                                        this.unitModalCharacter = squadUnit;
                                        this.hasUltimate = this.unitModalCharacter.requirements?.hasUltimate;
                                        this.minGP = this.unitModalCharacter.requirements?.filters.minGP;
                                        this.minGearLevel = this.unitModalCharacter.requirements?.filters.gear;
                                        this.minRelicLevel = this.unitModalCharacter.requirements?.filters.relic;
                                        this.showModal = true;
                                    })}
                                />
                            }

                            if(this.filterUnitType !== 0){
                                unitData = this.props.gameData.units?.filter(f => f.combatType === this.filterUnitType).find(x => x.baseId === squadUnit.characterId);
                            }

                            if(!unitData) {
                                return null;
                            }

                            return (
                                <GroupUnitAvatar
                                    key={index}
                                    unitData={unitData}
                                    stars={7}
                                    gear={squadUnit.requirements?.filters.gear}
                                    relic={squadUnit.requirements?.filters.relic}
                                    ultimate={squadUnit.requirements?.hasUltimate}
                                    subsPriority={undefined}
                                    subsUnit={undefined}
                                    subs={undefined}
                                    showName={this.filterUnitType !== 2}
                                    zeta={squadUnit.requirements?.hasZeta}
                                    omi={squadUnit.requirements?.hasOmicron}
                                    className={`${styles.unit} ${this.filterUnitType !== 0 && index === 0 && styles.leader} ${unitData.combatType === 1 ? styles.unitLeader : styles.shipCapital} ${index === 1 && unitData.combatType === 2 ? styles['ship-lineup1'] : ''} ${index === 2 && unitData.combatType === 2 ? styles['ship-lineup2'] : ''} ${index === 3 && unitData.combatType === 2 ? styles['ship-lineup3'] : ''}`}
                                    onClick={() => runInAction(() => {
                                        this.unitModalCharacter = squadUnit;
                                        this.hasUltimate = this.unitModalCharacter.requirements?.hasUltimate;
                                        this.minGP = this.unitModalCharacter.requirements?.filters.minGP;
                                        this.minGearLevel = this.unitModalCharacter.requirements?.filters.gear;
                                        this.minRelicLevel = this.unitModalCharacter.requirements?.filters.relic;
                                        this.showModal = true;
                                    })}
                                />);
                            }
                        )}

                        <div className={styles.btn}>
                            <Button
                                className={styles.add}
                                disabled={
                                    (this.filterUnitType === 2 && this.selectedCharacters.length === 8) ||
                                    (this.filterUnitType === 1 && this.selectedCharacters.length === 5)
                                }
                                type="primary"
                                shape="circle"
                                icon={<FontAwesomeIcon icon={faPlus} />}
                                onClick={() => this.addUnit()} size={"middle"}
                            />
                        </div>
                    </div>

                    {this.poolUnits > 0 && <div className={styles.units}>
                        {this.renderAddPoolUnits()}
                        {this.poolCharactersId.map((baseId, index) => {
                                let unitData = this.props.gameData.getUnit(baseId);

                                if (!unitData) {
                                    return null;
                                }

                                return (
                                    <GroupUnitAvatar
                                        key={index}
                                        unitData={unitData}
                                        stars={7}
                                        gear={1}
                                        relic={0}
                                        className={styles.unit}
                                        showName={false}
                                    />);
                            }
                        )}
                    </div>}
                    {this.poolUnits === 0 &&<div className={styles.note}>You need to add at least one pool unit to be able to select units for the pool unit!</div>}
                </div>
                {this.renderAddUnitModal()}
            </Modal>
        );
    }
}
