import React from "react";
import { observer } from "mobx-react";
import { roundUnitStat, UnitStats, UNIT_STAT_LIST } from '../PlaygroundData';
import styles from "./../styles/Playground.module.scss";
import { getUnitStat } from '../PlaygroundData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretCircleUp, faCaretCircleDown, faLock } from "@fortawesome/pro-duotone-svg-icons";
import { ITargetStatRangeResult, LoadoutDefinitionStatIds, loadoutDefinitionStatIdToString, LoadoutDefinitionTarget, RequirementsResults } from "../../../../model/LoadoutDefinition";
import { Alert, Tooltip } from 'antd';
import { action, observable } from "mobx";
import Modal from "antd/lib/modal/Modal";
import UnitData from "../../../../model/UnitData";
import { Tabs } from 'antd';

const { TabPane } = Tabs;

interface IProps
{
    baseStats: UnitStats;
    gearStats: UnitStats;
    originalStats: UnitStats;
    currentStats?: UnitStats;
    strengthBreakdown?: UnitStats;
    strength?: number;

    target?: LoadoutDefinitionTarget;
    gameUnits: UnitData[];

    hideTargetLink?: boolean;

    addRequirement?: (statId: LoadoutDefinitionStatIds, value: number) => void;

    requirementsResult?: RequirementsResults;
    desiredRequirementsResult?: RequirementsResults;
}

// Replace this eventually with DefinitionAppliedStats

@observer
class PlaygroundStats extends React.Component<IProps>
{
    @observable showTargets: boolean = false;

    renderStatRow(fieldName: string, oldValue: number, newValue: number | undefined, baseStat: number, statId: LoadoutDefinitionStatIds, statBreakdown: number | undefined,
        statTarget: ITargetStatRangeResult | undefined, desiredStatTarget: ITargetStatRangeResult | undefined)
    {
        let deltaValue = newValue === undefined ? 0 : newValue - oldValue;

        let overBaseStat = newValue === undefined ? 0 : Math.round(newValue - baseStat);

        let overBaseStatOld = newValue !== undefined ? '' : '(' + Math.round((oldValue - baseStat)).toLocaleString() + ')';

        let useDesired = statTarget === undefined || statTarget.passed;
        let evaluateTarget = useDesired ? desiredStatTarget : statTarget;

        let failedStatRow = evaluateTarget !== undefined && evaluateTarget.passed === false;

        let rangeDescription = (statTarget === undefined && desiredStatTarget === undefined) ? undefined : <React.Fragment>
            {(statTarget !== undefined && (statTarget.minValue !== undefined || statTarget.maxValue !== undefined))
                && <div>
                    required {statTarget.minValue !== undefined && <React.Fragment>min={statTarget.minValue}</React.Fragment>}
                    {statTarget.maxValue !== undefined && <React.Fragment> max={statTarget.maxValue}</React.Fragment>}
                </div>
            }
            {(desiredStatTarget !== undefined && (desiredStatTarget.minValue !== undefined || desiredStatTarget.maxValue !== undefined))
                && <div>
                    desired {desiredStatTarget.minValue !== undefined && <React.Fragment>min= {desiredStatTarget.minValue}</React.Fragment>}
                    {desiredStatTarget.maxValue !== undefined && <React.Fragment> max= {desiredStatTarget.maxValue}</React.Fragment>}
                </div>
            }
        </React.Fragment>;

        let rowClassName = styles.modstatrow;
        if (failedStatRow && useDesired)
        {
            rowClassName = styles.faileddesiredmodstatrow;
        } else if (failedStatRow)
        {
            rowClassName = styles.failedmodstatrow;
        } else if (statTarget !== undefined && (statTarget.minValue !== undefined || statTarget.maxValue !== undefined))
        {
            rowClassName = styles.passedmodstatrow;
        }
        return <tr key={fieldName} className={rowClassName}>
            <td className={styles.statnamecell}>
                <Tooltip title={rangeDescription}>
                    <span>{fieldName}</span>
                </Tooltip>
            </td>
            <td className={styles.oldcellvalue}>
                <Tooltip title={rangeDescription}>
                    <span>{oldValue.toLocaleString()} {overBaseStatOld}</span>
                </Tooltip>
            </td>
            {newValue !== undefined &&
                <React.Fragment>
                    <td className={styles.newvaluecell}>
                        <Tooltip title={rangeDescription}>
                            <span>{newValue.toLocaleString()} ({overBaseStat.toLocaleString()})</span>
                        </Tooltip>
                    </td>
                    <td className={styles.deltavaluecell}>
                        <span>
                            {deltaValue !== 0 && (newValue - oldValue).toLocaleString()}

                        </span>
                    </td>
                    <td>
                        {deltaValue > 0 && <FontAwesomeIcon icon={faCaretCircleUp} className={styles.improveicon} />}
                        {deltaValue < 0 && <FontAwesomeIcon icon={faCaretCircleDown} className={styles.degradeicon} />}
                    </td>
                </React.Fragment>
            }

            {this.props.addRequirement !== undefined &&
                <td>
                    <FontAwesomeIcon icon={faLock} className={styles.lockicon} onClick={() => this.props.addRequirement!(statId,
                        (statTarget !== undefined && statTarget.minValue !== undefined) ? statTarget.minValue :
                            newValue === undefined ? oldValue : newValue)} />
                </td>
            }
            {/* {statBreakdown !== undefined &&
                <td className={styles.statnamecell}>
                    {statBreakdown}
                </td>
            } */}
        </tr>
    }


    renderTargetStatRow(fieldName: string, newValue: number | undefined, statTarget: ITargetStatRangeResult | undefined, desiredStatTarget: ITargetStatRangeResult | undefined)
    {

        let targetMin = statTarget === undefined || statTarget.minValue === undefined ? undefined : Math.ceil(statTarget.minValue).toLocaleString();
        let desiredMin = desiredStatTarget === undefined || desiredStatTarget.minValue === undefined ? undefined : Math.ceil(desiredStatTarget.minValue).toLocaleString();

        let targetMax = statTarget === undefined || statTarget.maxValue === undefined ? undefined : Math.ceil(statTarget.maxValue).toLocaleString();
        let desiredMax = desiredStatTarget === undefined || desiredStatTarget.maxValue === undefined ? undefined : Math.ceil(desiredStatTarget.maxValue).toLocaleString();


        return <tr key={fieldName} className={styles.modstatrow}>
            <td className={styles.statnamecell}>
                <span>{fieldName}</span>
            </td>

            <td className={styles.statnamecell}>
                <span>{desiredMin}</span>
            </td>
            <td className={styles.statnamecell}>
                <span>{targetMin}</span>
            </td>
            <td className={styles.statnamecell}>
                <span>{newValue === undefined ? "?" : Math.ceil(newValue).toLocaleString()}</span>
            </td>
            <td className={styles.statnamecell}>
                <span>{targetMax}</span>
            </td>
            <td className={styles.statnamecell}>
                <span>{desiredMax}</span>
            </td>

        </tr>
    }

    hasRequirement(requirement: RequirementsResults | undefined): boolean
    {
        return requirement !== undefined && requirement.targetStatRanges !== null &&
            requirement.targetStatRanges.find(tsr => tsr.minValue !== undefined || tsr.maxValue !== undefined) !== undefined;
    }

    getStatRange(rr: RequirementsResults | undefined, statId: LoadoutDefinitionStatIds): ITargetStatRangeResult | undefined
    {
        if (rr !== undefined && rr.targetStatRanges !== null)
        {
            return rr.targetStatRanges.find(tsr => tsr.statId === statId);
        }
    }

    renderTargets()
    {

        let reportTargets = this.props.target === undefined || this.props.target.getEffectiveOptimizeTargets() === undefined ? undefined :
            this.props.target.getEffectiveOptimizeTargets()!.map((rt, index) =>
            {
                return <div key={index}>{rt.description(this.props.target!.unitBaseId, this.props.gameUnits)}</div>
            });

        let desiredTargets = this.props.target === undefined || this.props.target.getEffectiveReportTargets() === undefined ? undefined :
            this.props.target.getEffectiveReportTargets()!.map((rt, index) =>
            {
                return <div key={index}>{rt.description(this.props.target!.unitBaseId, this.props.gameUnits)}</div>
            });


        let statComponents = UNIT_STAT_LIST.filter(s => this.getStatRange(this.props.requirementsResult, s) ||
            this.getStatRange(this.props.desiredRequirementsResult, s)).map(stat =>
            {
                let statTarget = this.props.requirementsResult === undefined || this.props.requirementsResult.targetStatRanges === null ? undefined :
                    this.props.requirementsResult.targetStatRanges.find(st => st.statId === stat);
                let desiredStatTarget = this.props.desiredRequirementsResult === undefined || this.props.desiredRequirementsResult.targetStatRanges === null ? undefined :
                    this.props.desiredRequirementsResult.targetStatRanges.find(st => st.statId === stat);

                return this.renderTargetStatRow(loadoutDefinitionStatIdToString(stat),
                    this.props.currentStats === undefined ? getUnitStat(this.props.originalStats, stat) : getUnitStat(this.props.currentStats, stat),
                    statTarget, desiredStatTarget);
            });

        return <Modal title="Unit Requirements" visible={this.showTargets}
            maskClosable={false}
            onCancel={action(() => this.showTargets = false)}
            okButtonProps={{ disabled: true, style: { display: 'none' } }}>

            <div className={styles.paddedrow}>
                <Alert message="Edit targets by clicking unit portrait." type="info" />
            </div>

            <Tabs defaultActiveKey="1">
                <TabPane tab="Summary" key="1">
                    {reportTargets !== undefined &&
                        <React.Fragment>
                            <div className={styles.paddedrow}>
                                Requirements:
                            </div>
                            <div className={styles.paddedrow}>
                                {reportTargets}
                            </div>
                        </React.Fragment>}


                    {desiredTargets !== undefined &&
                        <React.Fragment>
                            <div className={styles.paddedrow}>
                                Desired:
                            </div>
                            <div className={styles.paddedrow}>
                                {desiredTargets}
                            </div>
                        </React.Fragment>}
                </TabPane>
                <TabPane tab="Resolved Table" key="2">
                    <table>
                        <tbody>
                            <tr>
                                <th>Stat</th>
                                <th>Desired Min</th>
                                <th>Target Min</th>
                                <th>Actual</th>
                                <th>Target Max</th>
                                <th>Desired Max</th>
                            </tr>
                            {statComponents}
                        </tbody>
                    </table>
                </TabPane>
            </Tabs>
        </Modal>

    }

    render()
    {

        // TODO Health plus protection
        // TODO show deltas

        let statComponents = UNIT_STAT_LIST.map(stat =>
        {
            let statTarget = this.props.requirementsResult === undefined || this.props.requirementsResult.targetStatRanges === null ? undefined :
                this.props.requirementsResult.targetStatRanges.find(st => st.statId === stat);
            let desiredStatTarget = this.props.desiredRequirementsResult === undefined || this.props.desiredRequirementsResult.targetStatRanges === null ? undefined :
                this.props.desiredRequirementsResult.targetStatRanges.find(st => st.statId === stat);

            return this.renderStatRow(loadoutDefinitionStatIdToString(stat),
                roundUnitStat(getUnitStat(this.props.originalStats, stat), stat),
                this.props.currentStats === undefined ? undefined : roundUnitStat(getUnitStat(this.props.currentStats, stat), stat),
                roundUnitStat(getUnitStat(this.props.baseStats, stat) + getUnitStat(this.props.gearStats, stat), stat),
                stat,
                this.props.strengthBreakdown === undefined ? undefined : getUnitStat(this.props.strengthBreakdown, stat),
                statTarget, desiredStatTarget);
        });

        return <React.Fragment>
            {this.renderTargets()}
            {this.props.strength !== undefined &&
                <div>Strength: {Math.round(this.props.strength)}</div>
            }
            <table>
                <tbody>
                    {statComponents}
                </tbody>
            </table>
            {this.props.hideTargetLink !== true && (this.hasRequirement(this.props.requirementsResult) || this.hasRequirement(this.props.desiredRequirementsResult)) &&
                <div className={styles.targetsrow} onClick={action(() => this.showTargets = true)}>
                    Targets
                </div>
            }
        </React.Fragment>;
    }

}

export default PlaygroundStats;
