export interface IAllianceShare {
    id: number;
    name: string;
}

export interface IGuildShare {
    id: string;
    name: string;
}

export interface IShare {
    guildId: string[];
    allianceId: number[];
    global: boolean;
    allyCode: string[];
}

export enum ShareType {
    Personal = 0,
    Ally = 1,
    Guild = 2,
    Alliance = 3,
    Global = 4
}

export const shareTypeArray: ShareType[] = [ShareType.Personal , ShareType.Guild, ShareType.Alliance]
export const copyTypeArray: ShareType[] = [ShareType.Personal , ShareType.Guild]
