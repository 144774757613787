
import PlayerData from './../../../model/PlayerData';
import { UnitData } from './../../../model/UnitData';
import { ModData } from './../../../model/ModData';
import PlayerCharacterData from './../../../model/PlayerCharacterData';
import { ModLoadoutData } from './../../../model/ModLoadoutData';
import GameData from './../../../model/GameData';
import ModSlots from '../../../model/ModSlots';
import { FilterConfig } from './../../../components/swgoh/ModFilter/ModFilter';
import { ModsSortEnum } from './PlaygroundCalculator/ModSort';
import { LoadoutDefinition, LoadoutDefinitionCalculator, LoadoutDefinitionSetBonusRestrictionType, LoadoutDefinitionStatIds, LoadoutDefinitionTarget } from '../../../model/LoadoutDefinition';
import { StatCalculatorUnitOutput } from './PlaygroundCalculator/ModStats';
import { ModAssignmentConfiguration, AvailableModAssignments } from './PlaygroundCalculator/ModAssignment';
import ModSets from '../../../model/ModSets';
import { ModAutomationSettings } from './PlaygroundCalculator/ModAutomation';
import { UnitStats as OgUnitStats } from '../../../utils/mod-calculator';
import ModFilterGroup from '../../../model/ModFilterGroup';
import { filterMods, ModFilterResults } from '../../../utils/modFilter';

export class UnitStats
{
    health: number = 0;
    protection: number = 0;
    speed: number = 0;
    criticalDamage: number = 0;
    potency: number = 0;
    tenacity: number = 0;
    physicalDamage: number = 0;
    criticalChance: number = 0;
    armor: number = 0;
    specialDamage: number = 0;
    specialCriticalChance: number = 0;
    resistance: number = 0;
    accuracy: number = 0;
    criticalAvoidance: number = 0;

    totalHealth: number = 0;
    effectiveHealth: number = 0;
    effectiveTotalHealth: number = 0;

    static fromOgStats(inStats: OgUnitStats)
    {

        let retVal = new UnitStats();
        retVal.health = inStats.health;
        retVal.protection = inStats.protection;
        retVal.speed = inStats.speed;
        retVal.criticalDamage = inStats.critDamage;
        retVal.potency = inStats.potency;
        retVal.tenacity = inStats.tenacity;
        retVal.physicalDamage = inStats.damage;
        retVal.specialDamage = inStats.specialDamage;
        retVal.criticalChance = inStats.critChance;
        retVal.specialCriticalChance = inStats.specialCritChance;
        retVal.armor = inStats.armor;
        retVal.resistance = inStats.resistance;
        retVal.accuracy = inStats.accuracy;
        retVal.criticalAvoidance = inStats.critAvoidance;

        retVal.totalHealth = inStats.health + inStats.protection;

        retVal.effectiveHealth = inStats.effectiveHealth;
        retVal.effectiveTotalHealth = inStats.effectiveHealthAndProtection;

        return retVal;
    }
}

export const UNIT_STAT_MAP = {
    health: LoadoutDefinitionStatIds.Health,
    protection: LoadoutDefinitionStatIds.Protection,
    speed: LoadoutDefinitionStatIds.Speed,
    criticalDamage: LoadoutDefinitionStatIds.CriticalDamage,
    potency: LoadoutDefinitionStatIds.Potency,
    tenacity: LoadoutDefinitionStatIds.Tenacity,
    physicalDamage: LoadoutDefinitionStatIds.PhysicalDamage,
    specialDamage: LoadoutDefinitionStatIds.SpecialDamage,
    criticalChance: LoadoutDefinitionStatIds.UnitPhysicalCriticalChance,
    specialCriticalChance: LoadoutDefinitionStatIds.UnitSpecialCriticalChance,
    armor: LoadoutDefinitionStatIds.UnitDefense,
    resistance: LoadoutDefinitionStatIds.UnitResistance,
    accuracy: LoadoutDefinitionStatIds.UnitPhysicalAccuracy,
    criticalAvoidance: LoadoutDefinitionStatIds.CriticalAvoidance,
    totalHealth: LoadoutDefinitionStatIds.TotalHealth,
    effectiveHealth: LoadoutDefinitionStatIds.EffectiveHealth,
    effectiveTotalHealth: LoadoutDefinitionStatIds.EffectiveTotalHealth
}

export const UNIT_STAT_LIST = [UNIT_STAT_MAP.health, UNIT_STAT_MAP.effectiveHealth, UNIT_STAT_MAP.protection, LoadoutDefinitionStatIds.TotalHealth,
UNIT_STAT_MAP.effectiveTotalHealth, UNIT_STAT_MAP.speed, UNIT_STAT_MAP.criticalDamage, UNIT_STAT_MAP.potency, UNIT_STAT_MAP.tenacity,
UNIT_STAT_MAP.physicalDamage, UNIT_STAT_MAP.criticalChance, UNIT_STAT_MAP.armor, UNIT_STAT_MAP.specialDamage, UNIT_STAT_MAP.specialCriticalChance,
UNIT_STAT_MAP.resistance, UNIT_STAT_MAP.accuracy, UNIT_STAT_MAP.criticalAvoidance];

interface IUnitStatFormatter
{
    statId: LoadoutDefinitionStatIds,
    formatter: (val: number, level: number) => number
}

const UNIT_STAT_FORMATTERS: IUnitStatFormatter[] = [
    { statId: UNIT_STAT_MAP.health, formatter: (val) => val },
    { statId: UNIT_STAT_MAP.protection, formatter: (val) => val },
    { statId: UNIT_STAT_MAP.totalHealth, formatter: (val) => val },
    { statId: UNIT_STAT_MAP.effectiveHealth, formatter: (val) => val },
    { statId: UNIT_STAT_MAP.effectiveTotalHealth, formatter: (val) => val },
    { statId: UNIT_STAT_MAP.speed, formatter: (val) => val },
    { statId: UNIT_STAT_MAP.physicalDamage, formatter: (val) => val },
    { statId: UNIT_STAT_MAP.specialDamage, formatter: (val) => val },

    { statId: UNIT_STAT_MAP.criticalDamage, formatter: (val) => val * 100 },
    { statId: UNIT_STAT_MAP.potency, formatter: (val) => val * 100 },
    { statId: UNIT_STAT_MAP.tenacity, formatter: (val) => val * 100 },
    { statId: UNIT_STAT_MAP.criticalChance, formatter: (val) => (val / 2400 + 0.1) * 100 },
    { statId: UNIT_STAT_MAP.specialCriticalChance, formatter: (val) => (val / 2400 + 0.1) * 100 },
    {
        statId: UNIT_STAT_MAP.armor, formatter: (val, level) =>
        {
            var level_effect = (level * 7.5);
            return (val / (level_effect + val)) * 100;
        }
    },
    {
        statId: UNIT_STAT_MAP.resistance, formatter: (val, level) =>
        {
            var level_effect = (level * 7.5);
            return (val / (level_effect + val)) * 100;
        }
    },
    { statId: UNIT_STAT_MAP.accuracy, formatter: (val) => (val / 1200) * 100 },
    { statId: UNIT_STAT_MAP.criticalAvoidance, formatter: (val) => (val / 2400) * 100 }
];


export function isModable(baseId: string, unitSettings: Map<string, PlaygroundUnitSettings>): boolean
{
    return unitSettings.has(baseId) && unitSettings.get(baseId)!.playerCharacterData.level >= 50;
}


export function roundUnitStat(value: number, statId: LoadoutDefinitionStatIds): number
{
    switch (statId)
    {
        case UNIT_STAT_MAP.health:
            return Math.floor(value);
        case UNIT_STAT_MAP.protection:
            return Math.floor(value);
        case UNIT_STAT_MAP.speed:
            return Math.floor(value);
        case UNIT_STAT_MAP.criticalDamage:
            return Math.floor(value);
        case UNIT_STAT_MAP.potency:
            return Math.floor(value * 100) / 100;
        case UNIT_STAT_MAP.tenacity:
            return Math.floor(value * 100) / 100;
        case UNIT_STAT_MAP.physicalDamage:
            return Math.floor(value);
        case UNIT_STAT_MAP.specialDamage:
            return Math.floor(value);
        case UNIT_STAT_MAP.criticalChance:
            return Math.floor(value * 100) / 100;
        case UNIT_STAT_MAP.armor:
            return Math.floor(value * 10) / 10;
        case UNIT_STAT_MAP.resistance:
            return Math.floor(value * 10) / 10;
        case UNIT_STAT_MAP.accuracy:
            return Math.floor(value);
        case UNIT_STAT_MAP.criticalAvoidance:
            return Math.floor(value);
        case UNIT_STAT_MAP.specialCriticalChance:
            return Math.floor(value * 100) / 100;
        case UNIT_STAT_MAP.totalHealth:
            return Math.floor(value);
        case UNIT_STAT_MAP.effectiveHealth:
            return Math.floor(value);
        case UNIT_STAT_MAP.effectiveTotalHealth:
            return Math.floor(value);
    }
    return -1;
}

export function getUnitStat(unitStats: UnitStats, statId: LoadoutDefinitionStatIds): number
{
    switch (statId)
    {
        case UNIT_STAT_MAP.health:
            return unitStats.health;
        case UNIT_STAT_MAP.protection:
            return unitStats.protection;
        case UNIT_STAT_MAP.speed:
            return unitStats.speed;
        case UNIT_STAT_MAP.criticalDamage:
            return unitStats.criticalDamage;
        case UNIT_STAT_MAP.potency:
            return unitStats.potency;
        case UNIT_STAT_MAP.tenacity:
            return unitStats.tenacity;
        case UNIT_STAT_MAP.physicalDamage:
            return unitStats.physicalDamage;
        case UNIT_STAT_MAP.specialDamage:
            return unitStats.specialDamage;
        case UNIT_STAT_MAP.criticalChance:
            return unitStats.criticalChance;
        case UNIT_STAT_MAP.armor:
            return unitStats.armor;
        case UNIT_STAT_MAP.resistance:
            return unitStats.resistance;
        case UNIT_STAT_MAP.accuracy:
            return unitStats.accuracy;
        case UNIT_STAT_MAP.criticalAvoidance:
            return unitStats.criticalAvoidance;
        case UNIT_STAT_MAP.specialCriticalChance:
            return unitStats.specialCriticalChance;
        case UNIT_STAT_MAP.totalHealth:
            return unitStats.totalHealth;
        case UNIT_STAT_MAP.effectiveHealth:
            return unitStats.effectiveHealth;
        case UNIT_STAT_MAP.effectiveTotalHealth:
            return unitStats.effectiveTotalHealth;
    }
    return -1;
}


export function setUnitStat(unitStats: UnitStats, statId: LoadoutDefinitionStatIds, value: number): number
{
    switch (statId)
    {
        case UNIT_STAT_MAP.health:
            unitStats.health = value;
            break;
        case UNIT_STAT_MAP.protection:
            unitStats.protection = value;
            break;
        case UNIT_STAT_MAP.speed:
            unitStats.speed = value;
            break;
        case UNIT_STAT_MAP.criticalDamage:
            unitStats.criticalDamage = value;
            break;
        case UNIT_STAT_MAP.potency:
            unitStats.potency = value;
            break;
        case UNIT_STAT_MAP.tenacity:
            unitStats.tenacity = value;
            break;
        case UNIT_STAT_MAP.physicalDamage:
            unitStats.physicalDamage = value;
            break;
        case UNIT_STAT_MAP.specialDamage:
            unitStats.specialDamage = value;
            break;
        case UNIT_STAT_MAP.criticalChance:
            unitStats.criticalChance = value;
            break;
        case UNIT_STAT_MAP.specialCriticalChance:
            unitStats.specialCriticalChance = value;
            break;
        case UNIT_STAT_MAP.armor:
            unitStats.armor = value;
            break;
        case UNIT_STAT_MAP.resistance:
            unitStats.resistance = value;
            break;
        case UNIT_STAT_MAP.accuracy:
            unitStats.accuracy = value;
            break;
        case UNIT_STAT_MAP.criticalAvoidance:
            unitStats.criticalAvoidance = value;
            break;
        case UNIT_STAT_MAP.totalHealth:
            unitStats.totalHealth = value;
            break;
        case UNIT_STAT_MAP.effectiveHealth:
            unitStats.effectiveHealth = value;
            break;
        case UNIT_STAT_MAP.effectiveTotalHealth:
            unitStats.effectiveTotalHealth = value;
            break;
    }
    return -1;
}


export function convertToReadableStats(input: UnitStats, playerCharacterData: PlayerCharacterData): UnitStats
{
    let retVal: UnitStats = new UnitStats();

    if (input !== null)
    {
        UNIT_STAT_LIST.forEach(stat =>
        {
            let sf = UNIT_STAT_FORMATTERS.find(usf => usf.statId === stat)!;
            let readableStat = sf.formatter(getUnitStat(input!, stat), playerCharacterData.level);
            setUnitStat(retVal, stat, readableStat);
        });

        retVal.effectiveHealth = (retVal.health) / (1 - retVal.armor / 100);
        retVal.effectiveTotalHealth = (retVal.health + retVal.protection) / (1 - retVal.armor / 100);

    }

    return retVal;
}

export interface IPlaygroundUnitCache
{
    unitBaseId: string;
    lockedMods: string[];
    loadoutMods: string[];
    locked: boolean;
}

export class PlaygroundUnitSettings
{

    playerCharacterData: PlayerCharacterData;
    gameUnit: UnitData;
    baseStats: UnitStats;
    gearStats: UnitStats;
    baseStatsReadable: UnitStats;
    gearStatsReadable: UnitStats;
    inGameStats: UnitStats;

    lockedMods: ModAssignmentConfiguration[] = [];
    loadoutMods: ModAssignmentConfiguration[] = [];
    deletedMods: boolean = false;
    gameMods: ModAssignmentConfiguration[] = [];
    previousSets: string[][] = [];

    statCalculatorOutput: StatCalculatorUnitOutput | null = null;

    noUpdatesFromGame: boolean = true;
    allModsLeveled: boolean = true;

    lockBroken: boolean = false;
    locked: boolean = false;

    _visibleConfirmedMods: ModAssignmentConfiguration[] = []; // working mods in the editor they have not "checked"
    get visibleConfirmedMods(): ModAssignmentConfiguration[]
    {
        return this._visibleConfirmedMods;
    }

    set visibleConfirmedMods(vcm: ModAssignmentConfiguration[])
    {
        this._visibleConfirmedMods = vcm;
        this.noUpdatesFromGame = this.visibleSameAsInGame();
        this.allModsLeveled = this.getAllModsLeveled();
    }

    getGearDescription()
    {
        if (this.playerCharacterData.gearLevel === 13)
        {
            return "R" + this.playerCharacterData.relicLevel;
        } else
        {
            return "G" + this.playerCharacterData.gearLevel;
        }
    }

    addPreviousSet(mods: string[])
    {

        let existingPs = this.previousSets.find(ps =>
        {
            return ps.length === mods.length && (mods.find(mod => ps.indexOf(mod) === -1) === undefined)
        });
        if (existingPs === undefined)
        {
            this.previousSets.push(mods);
        }
    }

    createCache(): IPlaygroundUnitCache
    {
        return {
            unitBaseId: this.gameUnit.baseId,
            lockedMods: this.lockedMods.map(lm => lm.mod.id),
            loadoutMods: this.loadoutMods.map(lm => lm.mod.id),
            locked: this.locked
        }
    }

    loadCache(u: IPlaygroundUnitCache, mods: Map<string, ModData>)
    {
        this.locked = u.locked;
        this.lockBroken = false;
        u.lockedMods.forEach(lm =>
        {
            if (mods.has(lm))
            {
                this.lockedMods.push(new ModAssignmentConfiguration(mods.get(lm)!, false))
            } else
            {
                this.lockBroken = true;
            }
        });
        u.loadoutMods.forEach(lm =>
        {
            if (mods.has(lm))
            {
                this.loadoutMods.push(new ModAssignmentConfiguration(mods.get(lm)!, false))
            }
        });
    }

    constructor(playerCharacterData: PlayerCharacterData, gameUnit: UnitData)
    {
        this.playerCharacterData = playerCharacterData;
        this.gameUnit = gameUnit;
        this.baseStats = this.getBaseStats();
        this.gearStats = this.getGearStats();
        this.baseStatsReadable = convertToReadableStats(this.baseStats, this.playerCharacterData);
        this.gearStatsReadable = convertToReadableStats(this.gearStats, this.playerCharacterData);
        this.inGameStats = this.getInGameStats();
    }
    refreshData(playerCharacterData: PlayerCharacterData, mods: Map<string, ModData>)
    {
        this.playerCharacterData = playerCharacterData;
        this.baseStats = this.getBaseStats();
        this.gearStats = this.getGearStats();
        this.baseStatsReadable = convertToReadableStats(this.baseStats, this.playerCharacterData);
        this.gearStatsReadable = convertToReadableStats(this.gearStats, this.playerCharacterData);
        this.inGameStats = this.getInGameStats();

        this.gameMods = playerCharacterData.mods.map(mod => new ModAssignmentConfiguration(mod, false));
        this.lockedMods = this.refreshModList(this.lockedMods, mods);
        this.loadoutMods = this.refreshModList(this.loadoutMods, mods);
    }

    private visibleSameAsInGame(): boolean
    {
        return this.gameMods.length === this.visibleConfirmedMods.length && this.gameMods.find(wm =>
            this.visibleConfirmedMods.find(vcm => vcm.mod.id === wm.mod.id && vcm.slice === false) === undefined) === undefined;
    }

    private getAllModsLeveled(): boolean
    {
        return this.visibleConfirmedMods.find(m => m.mod.level < 15) === undefined;
    }

    sameAsVisible(workingMods: ModAssignmentConfiguration[]): boolean
    {
        return this.visibleConfirmedMods.length !== workingMods.length || workingMods.find(wm =>
            this.visibleConfirmedMods.find(vcm => vcm.mod.id === wm.mod.id && vcm.slice === wm.slice) === undefined) !== undefined;
    }

    slotUpdated(slot: ModSlots, workingMods: ModAssignmentConfiguration[]): boolean
    {

        let visibleMod = workingMods.find(mod => mod.mod.slot === slot);
        let existingMod = this.visibleConfirmedMods.find(mod => mod.mod.slot === slot);

        let visibleModId = visibleMod === undefined ? null : visibleMod.mod.id;
        let existingModId = existingMod === undefined ? null : existingMod.mod.id;

        let visibleRarity = visibleMod === undefined ? null : visibleMod.mod.rarity;
        let existingRarity = existingMod === undefined ? null : existingMod.mod.rarity;

        return visibleModId !== existingModId || visibleRarity !== existingRarity;
    }

    private getInGameStats(): UnitStats
    {
        let retVal: UnitStats = new UnitStats();

        retVal.health = this.playerCharacterData.statsHealth;
        retVal.protection = this.playerCharacterData.statsProtection;
        retVal.speed = this.playerCharacterData.statsSpeed;
        retVal.criticalDamage = this.playerCharacterData.statsCritDamage;
        retVal.potency = this.playerCharacterData.statsPotency;
        retVal.tenacity = this.playerCharacterData.statsTenacity;
        retVal.physicalDamage = this.playerCharacterData.statsDamage;
        retVal.specialDamage = this.playerCharacterData.statsSpecialDamage;
        retVal.criticalChance = this.playerCharacterData.statsCritChance;
        retVal.specialCriticalChance = this.playerCharacterData.statsSpecialCritChance;
        retVal.armor = this.playerCharacterData.statsArmor;
        retVal.resistance = this.playerCharacterData.statsResistance;
        retVal.accuracy = this.playerCharacterData.statsAccuracy;
        retVal.criticalAvoidance = this.playerCharacterData.statsCritAvoidance;

        retVal.totalHealth = retVal.health + retVal.protection;

        retVal.effectiveHealth = (retVal.health) / (1 - retVal.armor / 100);
        retVal.effectiveTotalHealth = (retVal.health + retVal.protection) / (1 - retVal.armor / 100);

        return retVal;
    }

    private getBaseStats(): UnitStats
    {
        return PlaygroundUnitSettings.getBaseUnitStats(this.playerCharacterData);
    }

    static getBaseUnitStats(pcd: PlayerCharacterData): UnitStats
    {
        let retVal: UnitStats = new UnitStats();
        UNIT_STAT_LIST.forEach(stat =>
        {
            if (stat > 0)
            {
                setUnitStat(retVal, stat, pcd.baseStats.getRawStatValue(stat));
            }
        });
        // got crit avoid stat wrong - dont want to change or will break all existing templates 
        // CriticalAvoidance = 54, but  "39": "Physical Critical Avoidance",
        retVal.criticalAvoidance = pcd.baseStats.getRawStatValue(39);
        retVal.totalHealth = retVal.health + retVal.protection;

        return retVal;
    }

    static getGearUnitStats(pcd: PlayerCharacterData): UnitStats
    {
        let retVal: UnitStats = new UnitStats();
        UNIT_STAT_LIST.forEach(stat =>
        {
            if (stat > 0)
            {
                let statEffects = pcd.gearEffects === null ? 0 : pcd.gearEffects.find(x => x.stat === stat)?.amount || 0;
                setUnitStat(retVal, stat, statEffects);
            }
        });

        let caGearStat = pcd.gearEffects === null ? 0 : pcd.gearEffects.find(x => x.stat === 39)?.amount || 0;
        retVal.criticalAvoidance = caGearStat;
        retVal.totalHealth = retVal.health + retVal.protection;

        return retVal;
    }

    private getGearStats(): UnitStats
    {
        return PlaygroundUnitSettings.getGearUnitStats(this.playerCharacterData);
    }


    private refreshModList(currentList: ModAssignmentConfiguration[], allNewMods: Map<string, ModData>): ModAssignmentConfiguration[]
    {
        let retVal: ModAssignmentConfiguration[] = currentList.filter(mac => allNewMods.has(mac.mod.id));
        retVal.forEach(mac =>
        {
            mac.mod = allNewMods.get(mac.mod.id)!;
            mac.slice = mac.slice && mac.mod.rarity !== 6;
        })
        return retVal;
    }
}
export interface IPlaygroundPlayerSettingsCache
{
    allyCode: string;
    includedUnits: string[];
    priorityOrder: string[];
    units: IPlaygroundUnitCache[];
}

export class PlaygroundPlayerSettings
{
    player: PlayerData;
    mods: Map<string, ModData> = new Map();
    unitSettings: Map<string, PlaygroundUnitSettings> = new Map();
    modified: boolean = false;
    modFilterResults: ModFilterResults = new ModFilterResults();
    garbageMods: string[] = [];

    _includedUnits: string[] = [];
    get includedUnits(): string[]
    {
        return this._includedUnits;
    }
    set includedUnits(value: string[])
    {
        this._includedUnits = value;
        this.calculateVisibleMods();
    }

    allUnits: string[] = [];

    _priorityOrder: string[] = [];
    get priorityOrder(): string[]
    {
        return this._priorityOrder;
    }
    set priorityOrder(value: string[])
    {
        this._priorityOrder = value;
    }

    availableModAssignments: AvailableModAssignments = new AvailableModAssignments();

    constructor(player: PlayerData, gameData: GameData, filterGroups: ModFilterGroup[])
    {
        this.player = player;
        this.reset(gameData, filterGroups);
    }

    reset(gameData: GameData, filterGroups: ModFilterGroup[]): void
    {
        Array.from(this.player.characters.values()).filter(u => u.combatType === 1).forEach(character =>
        {
            let gameUnit = gameData.units?.find(u => u.baseId === character.baseId)!;
            this.unitSettings.set(character.baseId, new PlaygroundUnitSettings(character, gameUnit));
        });
        this.allUnits = gameData.units!.filter(u => u.combatType === 1).map(u => u.baseId);
        this._includedUnits = this.allUnits.slice(0);

        this.refreshData(this.player, filterGroups);
        this.setPriorityOrderToPower();
    }

    createCache(): IPlaygroundPlayerSettingsCache
    {
        return {
            allyCode: this.player.allyCode,
            includedUnits: this.includedUnits,
            priorityOrder: this.priorityOrder,
            units: Array.from(this.unitSettings.values()).map(us => us.createCache())
        }
    }

    loadCache(p: IPlaygroundPlayerSettingsCache)
    {
        this.includedUnits = p.includedUnits.slice(0);
        this.setPriorityOrderToList(p.priorityOrder);
        p.units.forEach(u =>
        {
            if (this.unitSettings.has(u.unitBaseId))
            {
                this.unitSettings.get(u.unitBaseId)!.loadCache(u, this.mods);
            }
        });
        this.calculateVisibleMods();
        this.modified = true;
    }

    prioritizeOnLockerUnit(lockerUnits: string[])
    {
        const nonLockerUnitsPriorityList = this.priorityOrder.filter(unit => !lockerUnits.includes(unit));
        const lockerUnitsPriorityList = this.priorityOrder.filter(unit => lockerUnits.includes(unit));
        this._priorityOrder = nonLockerUnitsPriorityList.concat(lockerUnitsPriorityList);
        this.enforcePriorityOrder();
    }

    prioritizeOnSpeed(ld: LoadoutDefinition)
    {
        // let basicSpeed = deriveBasicRequiredSpeed(ld);

        let derivedRequirements = LoadoutDefinitionCalculator.deriveHardRanges(Array.from(ld.targets.values()));
        let dt = derivedRequirements.rangeResults;

        // let statRequirementCounts = deriveStatRequirementCounts(ld);

        let speedPriorityOrder = this.priorityOrder.filter(u => dt.has(u) && this.unitSettings.has(u) &&
            dt.get(u)!.find(ldst => ldst.stat === LoadoutDefinitionStatIds.Speed) !== undefined &&
            dt.get(u)!.find(ldst => ldst.stat === LoadoutDefinitionStatIds.Speed)!.minValue !== undefined &&
            this.unitSettings.get(u)!.baseStats !== undefined).sort((a, b) =>
            {
                let unitA: number = dt.get(a)!.find(ldst => ldst.stat === LoadoutDefinitionStatIds.Speed)!.minValue! - this.unitSettings.get(a)!.baseStats.speed;
                let targetA = ld.targets.find(u => u.unitBaseId === a);
                if (targetA !== undefined)
                {
                    if (targetA.canUseSpeedArrow())
                    {
                        unitA = unitA - 30;
                    }
                    if (targetA.canUseSpeedSet())
                    {
                        let baseSpeedA = this.unitSettings.get(a)!.baseStats.speed;
                        unitA = unitA - (baseSpeedA * .1);
                    }
                }

                let unitB: number = dt.get(b)!.find(ldst => ldst.stat === LoadoutDefinitionStatIds.Speed)!.minValue! - this.unitSettings.get(b)!.baseStats.speed;
                let targetB = ld.targets.find(u => u.unitBaseId === b);
                if (targetB !== undefined)
                {
                    if (targetB.canUseSpeedArrow())
                    {
                        unitB = unitB - 30;
                    }
                    if (targetB.canUseSpeedSet())
                    {
                        let baseSpeedB = this.unitSettings.get(b)!.baseStats.speed;
                        unitB = unitB - (baseSpeedB * .1);
                    }
                }

                return unitB - unitA;
            });

        let includedPriorityOrder = this.priorityOrder.filter(u =>
        {
            let target = ld.targets.find(ldt => ldt.unitBaseId === u);
            return speedPriorityOrder.indexOf(u) === -1 && target !== undefined && target.getEffectiveOptimizeTargets() !== undefined && target.getEffectiveReportTargets() !== undefined;
        });
        let everythingElse = this.priorityOrder.filter(u =>
        {
            return speedPriorityOrder.indexOf(u) === -1 && includedPriorityOrder.indexOf(u) === -1;
        });
        this._priorityOrder = speedPriorityOrder.concat(includedPriorityOrder).concat(everythingElse);
        this.enforcePriorityOrder();
    }

    getAllDependencies(unitId: string, targets: LoadoutDefinitionTarget[], dependencies: string[])
    {
        let target = targets.find(t => t.unitBaseId === unitId);
        if (target !== undefined)
        {
            if (target.getEffectiveOptimizeTargets() !== undefined)
            {
                target.getEffectiveOptimizeTargets()!.filter(ot => ot.compareUnitBaseId !== undefined).forEach(ot =>
                {
                    if (dependencies.indexOf(ot.compareUnitBaseId!) === -1)
                    {
                        dependencies.push(ot.compareUnitBaseId!);
                        this.getAllDependencies(ot.compareUnitBaseId!, targets, dependencies);
                    }
                });
            }
            if (target.getEffectiveReportTargets() !== undefined)
            {
                target.getEffectiveReportTargets()!.filter(rt => rt.compareUnitBaseId !== undefined).forEach(rt =>
                {
                    if (dependencies.indexOf(rt.compareUnitBaseId!) === -1)
                    {
                        dependencies.push(rt.compareUnitBaseId!);
                        this.getAllDependencies(rt.compareUnitBaseId!, targets, dependencies);
                    }
                });
            }
        }
    }

    enforcePriorityOrder()
    {
        let newPriorityOrder = this.priorityOrder.slice();
        this._priorityOrder = newPriorityOrder;
    }

    get unitSettingsList(): PlaygroundUnitSettings[]
    {
        return Array.from(this.unitSettings.values());
    }

    loadLoadout(loadout: ModLoadoutData | null, markComplete: boolean, convertAllMods6: boolean, replaceModAssignments: boolean)
    {
        this.modified = false;
        this._includedUnits = loadout === null ? this.allUnits.slice(0) : loadout.modLoadoutUnits.filter(mlu => mlu.unit !== null).map(mlu => mlu.unit!.baseId);
        if (loadout !== null && loadout.statDefinition !== undefined)
        {
            loadout.statDefinition.targets.forEach(t =>
            {
                if (this._includedUnits.indexOf(t.unitBaseId) === -1)
                {
                    this._includedUnits.push(t.unitBaseId);
                }
            });
        }
        if (loadout !== null && loadout.statDefinition !== undefined)
        {
            this.setPriorityOrderToLoadout(loadout.statDefinition);

        } else
        {
            this.setPriorityOrderToPower();
        }
        this.unitSettingsList.forEach(us =>
        {
            let baseId = us.gameUnit.baseId;
            let pus = this.unitSettings.get(us.gameUnit.baseId)!;
            if (loadout !== null)
            {
                let loadoutUnit = loadout.modLoadoutUnits.find(mlu => mlu.unit !== null && mlu.unit.baseId === baseId);
                let loadoutMods = loadoutUnit === undefined ? [] : loadoutUnit.mods.map(lum => new ModAssignmentConfiguration(lum, convertAllMods6));
                pus.loadoutMods = loadoutMods;
                pus.deletedMods = loadoutUnit === undefined ? false : loadoutUnit.missingMod;

                // if we are loading a newly saved loadout, we dont want to reset all the "slice as sixe"
                if (replaceModAssignments)
                {
                    if (loadout.lockedUnits.indexOf(pus.gameUnit.baseId) === -1)
                    {
                        pus.lockedMods = [];
                        pus.locked = false;
                    } else
                    {
                        pus.lockedMods = loadoutMods.slice(0);
                        pus.locked = true;
                    }
                    pus.lockBroken = false;

                    if (markComplete && loadoutUnit)
                    {
                        pus.lockedMods = loadoutMods;
                        pus.locked = true;
                    }
                }
            } else
            {
                pus.loadoutMods = [];
                pus.lockedMods = [];
                pus.locked = false;
                pus.lockBroken = false;
            }
        });
        this.calculateVisibleMods();
    }

    setPriorityOrderToLoadout(statDefinition: LoadoutDefinition)
    {
        this.setPriorityOrderToList(statDefinition.targets.map(t => t.unitBaseId));
    }

    reorderUnits(startIndex: number, newPriorityList: string[])
    {

        let newPriorityOrder = this._priorityOrder.filter(unitId => newPriorityList.indexOf(unitId) === -1);
        newPriorityOrder.splice(startIndex, 0, ...newPriorityList);
        this.priorityOrder = newPriorityOrder;
    }


    setPriorityOrderToList(priorityList: string[])
    {
        this._priorityOrder = this.allUnits.sort((p1UnitId: string, p2UnitId: string) =>
        {

            let p1 = this.unitSettings.get(p1UnitId);
            let p2 = this.unitSettings.get(p2UnitId);

            let p1Val: number = priorityList.indexOf(p1UnitId);
            let p2Val: number = priorityList.indexOf(p2UnitId);

            p1Val = p1Val === -1 ? p1 !== undefined ? 10000 + (1 / p1.playerCharacterData.powerTotal) : 10001 : p1Val;
            p2Val = p2Val === -1 ? p2 !== undefined ? 10000 + (1 / p2.playerCharacterData.powerTotal) : 10001 : p2Val;

            return p1Val - p2Val;
        }).map(pcd => pcd);
    }

    setPriorityOrderToPower()
    {
        this._priorityOrder = this.allUnits.sort((p1UnitId: string, p2UnitId: string) =>
        {

            let p1 = this.unitSettings.get(p1UnitId);
            let p2 = this.unitSettings.get(p2UnitId);

            let p1Val = p1 !== undefined ? 10000 + (1 / p1.playerCharacterData.powerTotal) : 10001;
            let p2Val = p2 !== undefined ? 10000 + (1 / p2.playerCharacterData.powerTotal) : 10001;

            return p1Val - p2Val;
        }).map(pcd => pcd);
    }

    refreshData(player: PlayerData, filterGroups: ModFilterGroup[])
    {

        this.player = player;
        this.mods = new Map();

        this.modFilterResults = filterMods(this.player.mods, filterGroups, player);
        this.garbageMods = this.modFilterResults.garbageMods.map(m => m.id);

        this.player.mods.forEach(m => this.mods.set(m.id, m));

        this.unitSettingsList.forEach(us =>
        {
            us.refreshData(player.characters.get(us.playerCharacterData.baseId)!, this.mods);
        })
        this.calculateVisibleMods();

    }

    get allLockedMods(): ModData[]
    {
        let retVal: ModData[] = [];

        this.unitSettingsList.forEach(us =>
        {
            retVal = retVal.concat(us.lockedMods.map(mas => mas.mod));
        })
        return retVal;
    }

    get allLoadoutMods(): ModData[]
    {
        let retVal: ModData[] = [];

        this.unitSettingsList.forEach(us =>
        {
            retVal = retVal.concat(us.loadoutMods.map(mas => mas.mod));
        })
        return retVal;
    }

    calculateVisibleMods()
    {
        this.availableModAssignments.assignedMods = new Map();
        let allLockedMods: ModData[] = this.allLockedMods;
        let allLoadoutMods: ModData[] = this.allLoadoutMods;

        this.unitSettingsList.forEach(us =>
        {
            // always add working mods
            us.visibleConfirmedMods = ModAssignmentConfiguration.deriveVisibleMods(us.lockedMods, us.loadoutMods, us.gameMods, allLockedMods, allLoadoutMods);

            us.visibleConfirmedMods.forEach(ma =>
            {
                this.availableModAssignments.assignedMods.set(ma.mod.id, {
                    currentAssignedCharacter: us.playerCharacterData,
                    lockedToCharacter: this.includedUnits.indexOf(us.gameUnit.baseId) !== -1 && us.lockedMods.find(lm => lm.mod.id === ma.mod.id) !== undefined
                });
            });
        })
    }

    unlockAllNonIncludedMods()
    {
        this.unitSettingsList.filter(u => this.includedUnits.indexOf(u.gameUnit.baseId) === -1).forEach(u =>
        {
            u.lockedMods = [];
            u.locked = false;
            u.lockBroken = false;
        });

        this.calculateVisibleMods();
        this.modified = true;
    }


    unlockAllMods()
    {
        this.unitSettingsList.forEach(u =>
        {
            u.lockedMods = [];
            u.locked = false;
            u.lockBroken = false;
        });

        this.calculateVisibleMods();
        this.modified = true;
    }

    replaceLoadoutMods(unitId: string, mods: ModAssignmentConfiguration[] | null, recalculateVisible: boolean = true)
    {
        if (mods !== null)
        {
            this.unitSettingsList.forEach(us =>
            {
                us.loadoutMods = us.loadoutMods.filter(lm => mods.find(m => m.mod.id === lm.mod.id) === undefined)
            });
        }

        // set character to locked
        if (this.unitSettings.has(unitId))
        {
            this.unitSettings.get(unitId)!.loadoutMods = mods === null ? [] : mods;
        }
        if (recalculateVisible) this.calculateVisibleMods();
        this.modified = true;
    }

    lockMods(unitId: string, mods: ModAssignmentConfiguration[] | null)
    {
        // remove these mods from other characters
        if (mods !== null)
        {
            this.unitSettingsList.forEach(us =>
            {
                let previousSize = us.lockedMods.length;
                us.lockedMods = us.lockedMods.filter(lm => mods.find(m => m.mod.id === lm.mod.id) === undefined)
                us.lockBroken = (previousSize === us.loadoutMods.length) && us.locked;
            });
        }

        // set character to locked
        if (this.unitSettings.has(unitId))
        {
            this.unitSettings.get(unitId)!.lockedMods = mods === null ? [] : mods;
            this.unitSettings.get(unitId)!.locked = mods !== null;
            this.unitSettings.get(unitId)!.lockBroken = false;
        }

        // add unit to included list
        if (this.includedUnits.indexOf(unitId) === -1)
        {
            this.includedUnits.push(unitId);
        }

        this.calculateVisibleMods();
        this.modified = true;
    }
}



export interface IPlaygroundDataCache
{
    players: IPlaygroundPlayerSettingsCache[];
}

export class PlaygroundData
{
    players: Map<string, PlaygroundPlayerSettings> = new Map();
    currentPlayer: string | null = null;
    gameData: GameData;
    unitFilters: Map<string, FilterConfig> = new Map();
    unitSorts: Map<string, ModsSortEnum> = new Map();
    filterGroups: ModFilterGroup[] = [];


    constructor(gameData: GameData)
    {

        this.gameData = gameData;
        this.resetUnitFilters();
        this.resetSortFilters();
    }

    reset()
    {
        Array.from(this.players.values()).forEach(pps => pps.reset(this.gameData, this.filterGroups));
    }

    createCache(): IPlaygroundDataCache
    {
        return {
            players: Array.from(this.players.values()).map(pps => pps.createCache())
        };
    }

    loadCache(pc: IPlaygroundDataCache)
    {
        pc.players.forEach(p =>
        {
            if (this.players.has(p.allyCode))
            {
                this.players.get(p.allyCode)!.loadCache(p);
            }
        });
    }

    syncFilter(t: LoadoutDefinitionTarget, mas: ModAutomationSettings)
    {
        let filterConfig = new FilterConfig();
        if (this.unitFilters.has(t.unitBaseId))
        {
            filterConfig = this.unitFilters.get(t.unitBaseId)!;
        }

        if (t.primaryBonusRestrictions !== undefined)
        {
            t.primaryBonusRestrictions.forEach(pbr =>
            {
                switch (pbr.slot)
                {
                    case ModSlots.Arrow:
                        filterConfig.arrow = pbr.possibleStats.slice(0);
                        break;
                    case ModSlots.Circle:
                        filterConfig.circle = pbr.possibleStats.slice(0);
                        break;
                    case ModSlots.Cross:
                        filterConfig.cross = pbr.possibleStats.slice(0);
                        break;
                    case ModSlots.Triangle:
                        filterConfig.triangle = pbr.possibleStats.slice(0);
                        break;
                }
            });
        }

        if (t.setBonusRestrictions !== undefined)
        {
            let mua = t.setBonusRestrictions.find(sbr => sbr.restrictionType === LoadoutDefinitionSetBonusRestrictionType.MustUseAll);
            let nos = t.setBonusRestrictions.find(sbr => sbr.restrictionType === LoadoutDefinitionSetBonusRestrictionType.NoOutsideSets);

            let count: number = 0;
            if (mua !== undefined)
            {
                mua.setBonuses.forEach(sb =>
                {
                    if (sb === ModSets.Offense || sb === ModSets.Speed || sb === ModSets.CriticalDamage)
                    {
                        count = count + 4;
                    } else
                    {
                        count = count + 2;
                    }
                })

                if (count === 6)
                {
                    filterConfig.sets = mua.setBonuses;
                }
            }
            if (count !== 6 && mas.dontBreakSets && nos !== undefined)
            {
                filterConfig.sets = nos.setBonuses;
            }
        }
        this.unitFilters.set(t.unitBaseId, filterConfig);
    }

    syncFilters(targets: LoadoutDefinitionTarget[], mas: ModAutomationSettings)
    {
        targets.forEach(t => this.syncFilter(t, mas));
    }


    resetSortFilters()
    {
        this.unitSorts = new Map();
        this.gameData.units!.forEach(u => this.unitSorts.set(u.baseId, ModsSortEnum.Match));
    }

    resetUnitFilters()
    {
        this.unitFilters = new Map();
        this.gameData.units!.forEach(u => this.unitFilters.set(u.baseId, new FilterConfig()));
    }

    get playersList(): PlaygroundPlayerSettings[]
    {
        return Array.from(this.players.values());
    }

    addNewPlayer(player: PlayerData)
    {
        if (this.players.has(player.allyCode) === false)
        {
            this.players.set(player.allyCode, new PlaygroundPlayerSettings(player, this.gameData, this.filterGroups));
        }
    }

    refreshData(player: PlayerData)
    {
        if (this.players.has(player.allyCode))
        {
            this.players.get(player.allyCode)!.refreshData(player, this.filterGroups);
        }
    }

    get currentPlayerSettings(): PlaygroundPlayerSettings | undefined
    {
        if (this.currentPlayer === null) return undefined;
        return this.players.get(this.currentPlayer)!;
    }

}