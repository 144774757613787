import IPageProps from "../../IPageProps";
import React from 'react';
import { useState } from 'react';
import { ModAutomationCalculatorInput, ModAutomationCalculatorOutput, ModAutomationSettings } from "../PlaygroundBeta/PlaygroundCalculator/ModAutomation";
import { Button, Checkbox, Modal, Progress } from "antd";
import PlayerData from "../../../model/PlayerData";
import { LoadoutDefinition, LoadoutDefinitionTarget } from "../../../model/LoadoutDefinition";
import { PlaygroundPlayerSettings } from "../PlaygroundBeta/PlaygroundData";
import ModWizardAutomationResults from "./ModWizardAutomationResults";
import styles from "./styles/ModWizard.module.scss";
import { ReviewViewModeEnum } from "./ModWizard";
import AutomationSettingsWorkspace from "../PlaygroundBeta/PlaygroundModUnitsWorkspace/AutomationSettingsWorkspace";

interface IModWizardAutomateProps extends IPageProps
{
    player: PlayerData;
    mas: ModAutomationSettings;
    loadoutDefinition: LoadoutDefinition;
    defaultTargets: Map<string, LoadoutDefinitionTarget>;
    pps: PlaygroundPlayerSettings;
    calculatorOutput: ModAutomationCalculatorOutput | null;
    onCompleteAutomation: (calculatorOutput: ModAutomationCalculatorOutput | null, mci: ModAutomationCalculatorInput) => void;
    tinyScreen: boolean;
    reviewViewModeEnum: ReviewViewModeEnum;
    showStatDetails: boolean;
    mci: ModAutomationCalculatorInput;
    setReviewViewMode: (mode: ReviewViewModeEnum) => void;
    setModAutomationSettings: (mas: ModAutomationSettings) => void;
    includeUnitsNotInBase: boolean;
    setIncludeUnitsNotInBase: (value: boolean) => void;
    fullRoster: boolean;
}


class ProgressStatus
{
    completed: number = 0;
    total: number = 0;
    current: string = "";
    unitPercentComplete: number = 0;
    foundMatch: boolean = false;
}

const ModWizardAutomate = (props: IModWizardAutomateProps) =>
{
    const [automationWorker, setAutomationWorker] = useState<Worker | null>(null);
    const [automatingUnits, setAutomatingUnits] = useState<boolean>(false);
    const [progressStatus, setProgressStatus] = useState<ProgressStatus>(new ProgressStatus());

    const [displayAutomationPrompt, setDisplayAutomationPrompt] = useState<boolean>(false);
    const [settingsFavorites, setSettingsFavorites] = useState<string[]>([AutomationSettingsWorkspace.PROMPT_EVERY_RUN, AutomationSettingsWorkspace.DONT_BREAK_SETS, AutomationSettingsWorkspace.AUTO_REORDER, AutomationSettingsWorkspace.ATTEMPT_DESIRED]);


    const handleAutomationWebWorker = React.useCallback(($event: MessageEvent) =>
    {
        if ($event && $event.data && $event.data.statCalculations !== null)
        {
            if ($event.data.complete === false)
            {
                let updatedProgress = new ProgressStatus();
                updatedProgress.completed = $event.data.completed;
                updatedProgress.total = $event.data.total;
                updatedProgress.current = $event.data.current;
                updatedProgress.unitPercentComplete = Math.round($event.data.unitPercentComplete);
                updatedProgress.foundMatch = $event.data.foundMatch;


                setProgressStatus(updatedProgress);
            } else
            {
                props.onCompleteAutomation($event.data.statCalculations, props.mci)
                setAutomatingUnits(false);
                setAutomationWorker(null);
            }
        }
    }, [props]);

    const calculateConfirmedAutomationUnit = React.useCallback(() =>
    {
        setAutomatingUnits(true);
        let customWorker = new Worker('./../../../../workers/worker-mod-automation-calculator.js');
        customWorker.onmessage = ($event: MessageEvent) =>
        {
            handleAutomationWebWorker($event);
        };
        customWorker.postMessage(removeCircularDependencies(props.mci));
        setAutomationWorker(customWorker);
    }, [handleAutomationWebWorker, props.mci]);


    function cancelAutomation()
    {
        if (automationWorker !== null)
        {
            automationWorker.terminate();
            setAutomationWorker(null);
        }
        setAutomatingUnits(false);
    }

    /*
       * Remove circular dependencies from mod object - this is required because data sent to workers is stringified and will fail if
       * there are circular dependencies.  Right now equipped characters is on all mod objects and is fairly specific to mods.
       */
    function removeCircularDependencies(inputData: any)
    {
        // remove circular structure from objects
        let retVal = JSON.parse(JSON.stringify(inputData, (key, value) =>
        {
            if (key === "equippedCharacter") return undefined;
            else return value;
        }));
        return retVal;
    }

    function renderCurrentAvatar()
    {

        if (progressStatus.current !== "")
        {
            let gameUnit = props.gameData.units!.find(u => u.baseId === progressStatus.current);

            return <span>{gameUnit === undefined ? "unknown" : gameUnit.name}</span>;
        }
    }


    function onClickSettingFavorite(key: string, value: boolean)
    {
        let newSettingsFavorites = settingsFavorites.filter(f => key !== f);

        if (value)
        {
            newSettingsFavorites.push(key);
        }
        setSettingsFavorites(newSettingsFavorites);
    }

    function renderAutoPromptModal()
    {
        return <Modal title="Automation Settings" visible={displayAutomationPrompt}
            maskClosable={false}
            cancelButtonProps={{ disabled: true, style: { display: 'none' } }}
            onCancel={() => setDisplayAutomationPrompt(false)}
            onOk={() => setDisplayAutomationPrompt(false)}>
            <div>
                <AutomationSettingsWorkspace
                    priorityOrder={props.loadoutDefinition.targets.map(t => t.unitBaseId)}
                    gameData={props.gameData}
                    onResetCachedSets={() => null}
                    modAutomationSettings={props.mas}
                    settingsFavorites={settingsFavorites}
                    onClickSettingFavorite={(key, value) => onClickSettingFavorite(key, value)}
                    onUpdateModAutomationSettings={(mas: ModAutomationSettings) => props.setModAutomationSettings(mas)} />
            </div>
        </Modal>;
    }


    function renderResults()
    {
        return <React.Fragment>
            <div>
                Locked units not shown
            </div>
            <ModWizardAutomationResults
                {...props} />
        </React.Fragment>
    }

    return <div>
        {renderAutoPromptModal()}
        <div className={styles.paddedrow}>
            <Button onClick={() => calculateConfirmedAutomationUnit()} disabled={automatingUnits} >Start Automation</Button>
            <Button onClick={() => cancelAutomation()} disabled={automatingUnits === false}>Stop Automation</Button>
            <Button onClick={() => setDisplayAutomationPrompt(true)} disabled={automatingUnits}>Settings</Button>
        </div>

        {props.fullRoster &&
            <div className={styles.paddedrow}>
                <Checkbox defaultChecked={props.includeUnitsNotInBase} checked={props.includeUnitsNotInBase}
                    onChange={(checked) => { props.setIncludeUnitsNotInBase(checked.target.checked) }} >
                    Automate all units (even those not in base)
                </Checkbox>
            </div>
        }
        {automatingUnits &&
            <div className={styles.paddedrow}>
                <div>
                    {renderCurrentAvatar()}
                </div>
                <div>
                    Progress on all units:
                </div>
                <div>
                    <Progress percent={progressStatus.total === 0 ? 0 : Math.round(progressStatus.completed / progressStatus.total * 100)} />
                </div>
                <div>
                    Progress on current unit:
                </div>
                <div>
                    {
                        progressStatus.foundMatch ? <React.Fragment>Found match, looking for improvement</React.Fragment> : <React.Fragment>Looking for match</React.Fragment>
                    }
                </div>
                <div>
                    <Progress percent={Math.round(progressStatus.unitPercentComplete)} />
                </div>
            </div>
        }
        {
            automatingUnits === false && props.calculatorOutput !== null &&
            <div className={styles.paddedrow}>
                {renderResults()}
            </div>
        }
    </div>;
}

export default ModWizardAutomate;

