import React from "react";
import { observer } from "mobx-react";
import { observable, action, runInAction } from "mobx";
import TerritoryBattleData from "../../../model/TerritoryBattleData";
import { Menu, Tooltip } from 'antd';
import { TerritoryBattlePlayerCalc } from "../tb-player-calcs";
import { Select } from 'antd';
import { Modal } from 'antd';
import UserData from './../../../model/UserData';
import { TerritoryBattlePlayer } from '../../../model/TerritoryBattleData';
import BaseAPI from "../../../service/BaseAPI";
import styled from 'styled-components';
import { Dropdown } from 'antd';
import { Button } from 'antd';
import { NotificationOutlined, CloseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;


const InstructionsContainer = styled.div` 
    padding: 10px;
`;

const PlayerTypeContainer = styled.div` 
    padding-bottom: 10px;
`;

const PlayersMenuContainer = styled.div` 
    max-height: 200px;
    overflow: scroll;
`;

const MessageAreaContainer = styled.div` 
    padding: 10px;
`;

const AlertMessageTextArea = styled.textarea` 
    width: 100%;
`;

const ToolbarButton = styled(Button)` 
    margin: 5px;
`;

interface IProps
{
    tbData: TerritoryBattleData;
    players: TerritoryBattlePlayerCalc[];
    user: UserData;
}

@observer
class TBPlayersMessage extends React.Component<IProps>
{
    @observable selectedAlertPlayers: string[] = [];
    @observable showSendMessageDialog: boolean = false;
    @observable alertText: string = "";

    @action
    clickCancelSendMessage()
    {
        this.showSendMessageDialog = false;
    }

    @action
    alertPlayers()
    {

        this.showSendMessageDialog = true;
    }

    @action
    private async clickSendMessage(players: TerritoryBattlePlayer[])
    {
        try
        {
            let playerAllyCodes = players.filter(p => this.selectedAlertPlayers.find(sap => sap === p.name)).map(p => p.allyCode);

            let message = this.props.user.currentPlayer?.name + " (do not reply in this chat, message user):\n" + this.alertText;

            let response = await BaseAPI.sendDiscordMessage(this.props.user, playerAllyCodes, message);
            if (response.failed !== undefined && response.length > 0)
            {
                let errorMessage = response.failed.map((fm: any) =>
                {

                    let allyCode = fm.id;
                    let playerError = players.find(p => p.allyCode === allyCode);
                    let name = playerError === undefined ? allyCode : playerError.name;

                    return name + ": " + fm.error;
                }).join("\n");
                Modal.error({ title: "Error sending messages", content: <span>{errorMessage}</span>, maskClosable: false });
            } else
            {
                Modal.info({ title: "Message Confirmation", content: <span>Messages sent from HotBot</span>, maskClosable: false })
            }
        }
        catch (err: any)
        {
            Modal.error({ title: "Error sending messages", content: <span>{err.errorMessage}</span>, maskClosable: false });
        }
        runInAction(() =>
        {
            this.selectedAlertPlayers = [];
            this.alertText = "";
            this.showSendMessageDialog = false;
        });
    }

    render()
    {
        const playerType = (
            <Menu>
                <Menu.Item onClick={action(() => { this.selectedAlertPlayers = this.props.tbData!.players.map(player => player.name) })}>
                    All
            </Menu.Item>
                <Menu.Item onClick={action(() =>
                {
                    this.selectedAlertPlayers = this.props.players.filter(p =>
                        p.deployedShips === false || this.selectedAlertPlayers.find(sap => sap === p.name) !== undefined).map(p => p.name)
                })}>
                    No Ships Deployment
            </Menu.Item>
                <Menu.Item onClick={action(() =>
                {
                    this.selectedAlertPlayers = this.props.players.filter(p =>
                        p.deployedCharacters === false || this.selectedAlertPlayers.find(sap => sap === p.name) !== undefined).map(p => p.name)
                })}>
                    No Character Deployment
            </Menu.Item>
                <Menu.Item onClick={action(() =>
                {
                    this.selectedAlertPlayers = this.props.players.filter(p =>
                        p.missionsShips === false || this.selectedAlertPlayers.find(sap => sap === p.name) !== undefined).map(p => p.name)
                })}>
                    Missing ship missions
            </Menu.Item>
                <Menu.Item onClick={action(() =>
                {
                    this.selectedAlertPlayers = this.props.players.filter(p =>
                        p.missionsCharacters === false || this.selectedAlertPlayers.find(sap => sap === p.name) !== undefined).map(p => p.name)
                })}>
                    Missing character missions
            </Menu.Item>

                <Menu.Item onClick={action(() =>
                {
                    this.selectedAlertPlayers = this.props.players.filter(p =>
                        p.specialMissions === false || this.selectedAlertPlayers.find(sap => sap === p.name) !== undefined).map(p => p.name)
                })}>
                    Missing special missions
            </Menu.Item>
            </Menu>
        );

        const playersMenu = (

            <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={[]}
                value={this.selectedAlertPlayers}
                onChange={action((values: string[]) => { this.selectedAlertPlayers = values; })}
            >
                {this.props.tbData.players.map(player =>
                    <Option key={player.name} value={player.name}> {player.name}</Option>
                )}
            </Select>
        )

        return <React.Fragment>
            <Modal
                title="Send Message"
                okButtonProps={{ disabled: this.alertText.trim().length === 0 }}
                visible={this.showSendMessageDialog}
                onOk={() => this.clickSendMessage(this.props.tbData!.players)}
                onCancel={() => this.clickCancelSendMessage()}>

                <InstructionsContainer>Ensure all players have run the $register command with hotbot to determine who to send messages to.</InstructionsContainer>

                <PlayerTypeContainer>
                    <Dropdown overlay={playerType} placement="bottomLeft" >
                        <Button>Add Player Group</Button>
                    </Dropdown>
                    <Tooltip title="Clear users">
                        <Button onClick={action(() => this.selectedAlertPlayers = [])}><CloseCircleOutlined /></Button>
                    </Tooltip>
                </PlayerTypeContainer>
                <PlayersMenuContainer>
                    {playersMenu}
                </PlayersMenuContainer>
                <MessageAreaContainer>
                    <div>
                        Message
                </div>
                    <AlertMessageTextArea rows={6} maxLength={500} value={this.alertText} onChange={action((e) => this.alertText = e.target.value)} />
                </MessageAreaContainer>
            </Modal>


            <Tooltip placement="bottomLeft" title="Alert players">
                <ToolbarButton onClick={() => this.alertPlayers()}><NotificationOutlined /></ToolbarButton>
            </Tooltip>
        </React.Fragment>;
    }
}

export default TBPlayersMessage;
