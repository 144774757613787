export enum MessageType {
    Text = 0, File = 1, Embed = 2
}

export enum ButtonStyle {
    //
    // Summary:
    //     A Blurple button
    Primary = 1, //
    // Summary:
    //     A Grey (or gray) button
    Secondary = 2, //
    // Summary:
    //     A Green button
    Success = 3, //
    // Summary:
    //     A Red button
    Danger = 4, //
    // Summary:
    //     A Discord.ButtonStyle.Secondary button with a little popup box indicating that
    //     this button is a link.
    Link = 5
}

export interface MessageFileContent {
    name: string;
    //value: byte[];
}

export interface MessageButton {
    label: string;
    customId: string;
    buttonStyle: ButtonStyle;
}

export interface MessageContent {
    message: string;
    file: MessageFileContent;
    buttons: MessageButton[];
}

export interface OutboundMessage {
    queueTries: number;
    queueError: string;
    id: number;
    discordId: number | null;
    discordGuildId: number | null;
    discordChannelId: number | null;
    discordRoleId: number | null;
    tagDiscordId: number | null;
    type: MessageType;
    message: MessageContent;
}

export interface IDiscordDM {
    allyCode: number[];
    message: string;
    buttons?: MessageButton[];
}

export interface IErrorsBroadcasting {
    id: number;
    error: string;
}