import React from "react";
import {observer} from "mobx-react";
import IPageProps from "../../../IPageProps";
import {action, observable, runInAction} from "mobx";
import {Button, Checkbox, Input, Modal, Popconfirm, Radio, Select} from "antd";
import styles from "../../../../styles/components/Modal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faShareSquare, faTrashAlt} from "@fortawesome/pro-duotone-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { AddUnitModal } from "./AddUnitModal";
import {
    IGuildSquadTemplate,
    IGuildSquadUnits,
    squadCategories,
} from "../../../../model/Squads";
import GroupUnitAvatar from "../../../../components/swgoh/Character/UnitAvatarGroup";
import DatacronAffixPicker from "../../../../components/swgoh/Datacron/DatacronAffixPicker";

interface IAddSquadModalProps extends IPageProps{
    showModal: boolean;
    onClose: () => void;
    onDelete?: () => void;
    template: IGuildSquadTemplate | undefined;
    onSave: (name: string, category: string[], selectedCharacters: IGuildSquadUnits[], combatType: number, isDuplicate: boolean, datacronAffixes: string[], datacronRequired: boolean) => void;
}

@observer
export class AddSquadModal extends React.PureComponent<IAddSquadModalProps> {
    @observable showModal: boolean = false;
    @observable title = this.props.template?.name || "";
    @observable category: string[] = this.props.template?.category || [];
    @observable unitModalCharacter?: IGuildSquadUnits = undefined;
    @observable selectedCharacters: IGuildSquadUnits[] = this.props.template?.units || [];
    @observable hasUltimate: boolean = false;
    @observable minGP: number = 100;
    @observable minStars: number = 2;
    @observable minGearLevel: number = 1;
    @observable minRelicLevel: number = 0;
    @observable filterUnitType: number = 1;
    @observable datacronAffixes: string[] = this.props.template?.datacronAffixes || [];
    @observable datacronRequired: boolean = this.props.template?.datacronRequired ?? false;

    componentDidMount() {
        const template = this.props.template;

        if(template) {
            runInAction(() => this.filterUnitType = template.combatType);
        }
    }

    @action private cancel() {
        return "";
    }

    @action
    private addUnit() {
        this.unitModalCharacter = {
            id: -1,
            characterId: "",
            characterName: "",
            requirements: {
                hasOmicron: false,
                hasZeta: false,
                hasUltimate: false,
                subsPriority: "gear",
                filters: {
                    minGP: this.minGP,
                    gear: this.minGearLevel,
                    relic: this.minRelicLevel,
                    stars: this.minStars
                },
                subs: []
            }
        };
        this.showModal = true;
    }

    @action
    private saveUnit(character: IGuildSquadUnits) {
        this.unitModalCharacter = undefined;
        const idx = this.selectedCharacters.findIndex(x => x.id === character.id);

        if (idx !== -1) {
            this.selectedCharacters[idx] = character;
        } else {
            character.id = Math.max(-1, ...this.selectedCharacters.map(x => x.id)) + 1;
            this.selectedCharacters.push(character);
        }

        for (let i = 0; i < this.selectedCharacters.length; i++) {
            const unit = this.selectedCharacters[i];

            if (!unit || !unit.requirements?.subs || unit.requirements?.subs?.length === 0) {
                continue;
            }

            const existingSubIdx = unit.requirements?.subs.findIndex(x => x.characterId === character.characterId);

            if (existingSubIdx !== -1){
                unit.requirements.subs.splice(existingSubIdx, 1);
            }
        }

        this.showModal = false;
    }

    @action
    private deleteUnit() {
        const character = this.unitModalCharacter;

        if (!this.showModal || !character) {
            return null;
        }

        const idx = this.selectedCharacters.findIndex(x => x.id === character.id);

        if (idx !== -1) {
            const selectedCharacters = this.selectedCharacters.slice();
            selectedCharacters.splice(idx, 1);

            this.selectedCharacters = selectedCharacters;
        }
        this.unitModalCharacter = undefined;
        this.showModal = false;
    }

    private renderAddUnitModal() {
        const character = this.unitModalCharacter;
        const unit = this.props.gameData.units;

        if (!this.showModal || !character || unit === undefined) {
            return null;
        }
        const otherSelectedCharacters = (this.selectedCharacters.filter(x => x.characterId !== character.characterId));

        return (
            <AddUnitModal
                {...this.props}
                character={character}
                filterTypeCombat={this.filterUnitType}
                minGP={this.minGP}
                minStars={this.minStars}
                minGearLevel={this.minGearLevel}
                minRelicLevel={this.minRelicLevel}
                otherSelectedCharacters={otherSelectedCharacters.flatMap(x => [...x.requirements?.subs?.map(x => x.characterId) ?? [], x.characterId])}
                subs={character.requirements?.subs?.slice() || []}
                subsPriority={character.requirements?.subsPriority}
                showModal={this.showModal}
                mainCharacterHasZeta={character.requirements?.hasZeta}
                mainCharacterHasUltimate={character.requirements?.hasUltimate}
                unitNumber={this.selectedCharacters.length + 1}
                onClose={() => runInAction(() => {
                    this.showModal = false;
                })}
                onSave={(character) => {
                    this.saveUnit(character);
                    runInAction(() => {
                        this.hasUltimate = character.requirements.hasUltimate;
                        this.minGP = character.requirements.filters.minGP;
                        this.minGearLevel = character.requirements.filters.gear;
                        this.minRelicLevel = character.requirements.filters.relic;
                        this.minStars = character.requirements.filters.stars;

                        if(this.filterUnitType !== unit?.find(u => u.baseId === character.characterId)!.combatType){
                            this.filterUnitType = 0;
                        }
                    })
                }}
                onDelete={() => this.deleteUnit()}
            />
        );
    }

    render() {
        const unit = this.props.gameData.units;

        return (
            <Modal
                title={`${this.props.template?.name ? "Edit the" : "Create a new"} Guild Squad template`}
                visible={this.props.showModal}
                onCancel={this.props.onClose}
                maskClosable={false}
                width={680}
                footer={[
                    (this.props.template?.name && this.props.onDelete !== undefined && <Popconfirm
                        title="Are you sure to delete this template?"
                        onConfirm={() => this.props.onDelete && this.props.onDelete()}
                        key={'popconfirmSquadTemplate'}
                        onCancel={() => this.cancel()}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button key="deleteSettings" type="link" className={styles.delete}>
                            <FontAwesomeIcon icon={faTrashAlt} className={styles.icon} />
                            Delete
                        </Button>
                    </Popconfirm>),
                    this.props.template?.name && this.props.onDelete !== undefined && <Button
                        key="saveGuildSquadAsNew"
                        type="primary"
                        onClick={() => {
                            if (this.filterUnitType !== 0) {
                                this.props.onSave(this.title, this.category, this.selectedCharacters.filter(f => unit!.find(u => u.baseId === f.characterId)!.combatType === this.filterUnitType), this.filterUnitType, true, this.datacronAffixes, this.datacronRequired);
                            } else {
                                this.props.onSave(this.title, this.category, this.selectedCharacters, this.filterUnitType, true, this.datacronAffixes, this.datacronRequired);
                            }
                        }}
                        disabled={
                            this.title.length < 1 ||
                            this.category.length < 1 ||
                            this.selectedCharacters.length === 0 ||
                            (this.filterUnitType !== 0 && this.selectedCharacters.filter(f => unit!.find(u => u.baseId === f.characterId)!.combatType === this.filterUnitType).length === 0)
                        }
                    >
                        <FontAwesomeIcon icon={faShareSquare} className={styles.icon}/>
                        Save as new
                    </Button>,
                    <Button
                        key="saveGuildSquad"
                        type="primary"
                        onClick={() => {
                            if(this.filterUnitType !== 0) {
                                this.props.onSave(this.title, this.category, this.selectedCharacters.filter(f => unit!.find(u => u.baseId === f.characterId)!.combatType === this.filterUnitType), this.filterUnitType, false, this.datacronAffixes, this.datacronRequired);
                            } else {
                                this.props.onSave(this.title, this.category, this.selectedCharacters, this.filterUnitType, false, this.datacronAffixes, this.datacronRequired);
                            }
                        }}
                        disabled={
                            this.title.length < 1 ||
                            this.category.length < 1 ||
                            this.selectedCharacters.length === 0 ||
                            (this.filterUnitType !== 0 && this.selectedCharacters.filter(f => unit!.find(u => u.baseId === f.characterId)!.combatType === this.filterUnitType).length === 0)
                        }
                    >
                        <FontAwesomeIcon icon={faSave} className={styles.icon} />
                        Save
                    </Button>
                ]}
            >
                <div className={styles.modal}>
                    <div className={styles.row}>
                        <div className={`${styles.label} ${styles.required}`}>Title:</div>
                        <Input
                            className={`${styles.input}`}
                            value={this.title}
                            onChange={event => {runInAction(() => {this.title = event.target.value;})}}
                            type={"text"}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={`${styles.label} ${styles.required}`}>Category:</div>
                        <Select
                            showSearch={true}
                            mode={"multiple"}
                            placeholder={"Select a category"}
                            allowClear={true}
                            className={`${styles.select}`}
                            value={Array.isArray(this.category) ? this.category : [this.category]}
                            onChange={value => {
                                runInAction(() => this.category = value)
                            }}
                            showArrow={true}
                        >
                            {squadCategories.map(i =>
                                <Select.Option key={i} value={i}>{i}</Select.Option>
                            )}
                        </Select>
                    </div>
                    <div className={styles.row}>
                        <div className={`${styles.label}`}>Unit type:</div>
                        <div className={styles.filter}>
                            <Radio.Group
                                defaultValue={this.props.template === undefined ? 0 : this.props.template.combatType === this.filterUnitType ? this.props.template.combatType : this.filterUnitType}
                                value={this.filterUnitType}
                                buttonStyle={"solid"}
                                optionType="button"
                            >
                                <Radio.Button
                                    value={0}
                                    onClick={(e) => runInAction(() => {
                                        this.filterUnitType = 0;
                                    })}
                                >
                                    All units
                                </Radio.Button>
                                <Radio.Button
                                    value={1}
                                    onClick={(e) => runInAction(() => {
                                        if(this.filterUnitType === 2) {
                                            this.selectedCharacters = [];
                                        }
                                        this.filterUnitType = 1;
                                    })}
                                >
                                    Only characters
                                </Radio.Button>
                                <Radio.Button
                                    value={2}
                                    onClick={(e) => runInAction(() => {
                                        if(this.filterUnitType !== 2){
                                            this.selectedCharacters = [];
                                        }
                                        this.filterUnitType = 2;
                                    })}
                                >
                                    Only ships
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    {this.filterUnitType === 1 && <div className={styles.row}>
                        <div className={`${styles.label}`}>Datacrons:</div>
                        <DatacronAffixPicker values={this.datacronAffixes} 
                            onChange={(value) => runInAction(() => this.datacronAffixes = value)}/>
                        <Checkbox checked={this.datacronRequired} 
                            onChange={(e) => runInAction(() => this.datacronRequired = e.target.checked)}>
                                Require Datacron
                        </Checkbox>
                    </div>
                    }

                    <div className={`${styles.units} ${this.filterUnitType === 2 ? styles.ship : ''}`}>
                        {this.selectedCharacters.map(squadUnit => {
                                let unitData = this.props.gameData.units?.find(x => x.baseId === squadUnit.characterId);

                                if(this.filterUnitType !== 0){
                                    unitData = this.props.gameData.units?.filter(f => f.combatType === this.filterUnitType).find(x => x.baseId === squadUnit.characterId);
                                }

                                if(!unitData) {
                                    return null;
                                }

                                const subs = squadUnit.requirements?.subs || [];
                                const subsCharacters = subs.map(y => y.characterId);
                                const subsUnitData = this.props.gameData.units?.filter(x => subsCharacters.includes(x.baseId)) ?? [];
                                const index = squadUnit.id

                                return (
                                    <GroupUnitAvatar
                                        key={index}
                                        unitData={unitData}
                                        stars={7}
                                        gear={squadUnit.requirements?.filters.gear}
                                        relic={squadUnit.requirements?.filters.relic}
                                        ultimate={squadUnit.requirements?.hasUltimate}
                                        subsPriority={squadUnit.requirements?.subsPriority}
                                        subsUnit={subsUnitData}
                                        subs={subs}
                                        showName={this.filterUnitType !== 2}
                                        zeta={squadUnit.requirements?.hasZeta}
                                        omi={squadUnit.requirements?.hasOmicron}
                                        className={`${styles.unit} ${this.filterUnitType !== 0 && index === 0 && styles.leader} ${unitData.combatType === 1 ? styles.unitLeader : styles.shipCapital} ${index === 1 && unitData.combatType === 2 ? styles['ship-lineup1'] : ''} ${index === 2 && unitData.combatType === 2 ? styles['ship-lineup2'] : ''} ${index === 3 && unitData.combatType === 2 ? styles['ship-lineup3'] : ''}`}
                                        onClick={() => runInAction(() => {
                                            this.unitModalCharacter = squadUnit;
                                            this.hasUltimate = this.unitModalCharacter.requirements?.hasUltimate;
                                            this.minGP = this.unitModalCharacter.requirements?.filters.minGP;
                                            this.minGearLevel = this.unitModalCharacter.requirements?.filters.gear;
                                            this.minRelicLevel = this.unitModalCharacter.requirements?.filters.relic;
                                            this.minStars = this.unitModalCharacter.requirements?.filters.stars;
                                            this.showModal = true;
                                        })}
                                    />);
                            }
                        )}

                        <div className={styles.btn}>
                            <Button
                                className={styles.add}
                                disabled={
                                    (this.filterUnitType === 2 && this.selectedCharacters.length === 8) ||
                                    (this.filterUnitType === 1 && this.selectedCharacters.length === 5)
                                }
                                type="primary"
                                shape="circle"
                                icon={<FontAwesomeIcon icon={faPlus} />}
                                onClick={() => this.addUnit()} size={"middle"}
                            />
                        </div>
                    </div>
                </div>

                {this.renderAddUnitModal()}
            </Modal>
        );
    }
}
