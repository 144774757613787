import { observable } from "mobx";

/**
 * @deprecated This should no longer be used
 */
export class TWZeta
{
    key: string;
    display: string;
    leader: boolean;

    constructor(json: any)
    {
        this.key = json.key;
        this.display = json.display;
        this.leader = json.leader;
    }
}

/**
 * @deprecated This should no longer be used
 */
export class TWUnit
{
    baseId: string;
    name: string;
    thumbnailName: string;
    alignment: number;
    combatType: number;
    abbreviation: string;
    abbreviations: string[] = [];

    galacticLedgend: boolean;
    fleetCommander: boolean;
    hasLeaderAbility: boolean;
    hasZetaLead: boolean;
    zetas: TWZeta[] = [];


    constructor(json: any)
    {
        this.baseId = json.baseId;
        this.name = json.name;
        this.thumbnailName = json.thumbnailName;
        this.alignment = json.alignment;
        this.combatType = json.combatType;
        this.abbreviation = json.abbreviation;
        if (json.abbreviations)
        {
            json.abbreviations.forEach((a: any) => this.abbreviations.push(a));
        }
        this.galacticLedgend = json.galacticLedgend;
        this.fleetCommander = json.fleetCommander;
        this.hasLeaderAbility = json.hasLeaderAbility;
        this.hasZetaLead = json.hasZetaLead;
        if (json.zetas)
        {
            json.zetas.forEach((z: any) => this.zetas.push(new TWZeta(z)));
        }
    }
}

/**
 * @deprecated This should no longer be used
 */
class TWTeam
{
    @observable name: string;
    @observable description: string;
    @observable fleet: boolean;
    @observable unit: TWUnit[] = [];

    constructor(json: any)
    {
        this.name = json.name;
        this.description = json.description;
        this.fleet = json.fleet;
        if (json.unit)
        {
            json.unit.forEach((u: any) => this.unit.push(new TWUnit(u)))
        }
    }

    public static nameSorter(a: TWTeam, b: TWTeam): number
    {

        let aVal = a.name === null ? "" : a.name;
        let bVal = b.name === null ? "" : b.name;

        return aVal.localeCompare(bVal);
    }


    public static descriptionSorter(a: TWTeam, b: TWTeam): number
    {

        let aVal = a.description === null ? "" : a.description;
        let bVal = b.description === null ? "" : b.description;

        if (aVal === bVal)
        {
            aVal = a.name;
            bVal = b.name;
        }
        return aVal.localeCompare(bVal);
    }
}

export default TWTeam;
