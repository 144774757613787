export enum PrioritizationSortTypeEnum
{
    GAC,
    LSTB,
    DSTB,
    ARENA
}

export enum PrioritizationAlignmentEnum
{
    ALL,
    LIGHT,
    DARK
}

export interface IPrioritizationInput
{
    allyCode: string;
    sortType: PrioritizationSortTypeEnum;
    alignment: PrioritizationAlignmentEnum;
    minGear?: number;
    gearThreshold?: number;
    ignoreArena: boolean;
    limitTop?: number;
    omicronGac: boolean;
    omicronTw: boolean;
    omicronTb: boolean;
    omicronRaids: boolean;
    omicronConquest: boolean;
}

class PrioritizationAPI
{
    public static async getApi(endpoint: string): Promise<any>
    {
        //  let prioritizationUrl = window.location.host === "hotutils.com" ? '/Prioritization/api/stackrank/' : '/PrioritizationDev/api/stackrank/';
        let prioritizationUrl = '/Prioritization/api/stackrank/';

        let rawResp = await fetch(prioritizationUrl + endpoint, {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
            }
        });
        let ret = await rawResp.json();
        if (ret.errorMessage)
        {
            throw ret;
        }
        return ret;
    }

    public static async prioritizeRoster(prioritizationInput: IPrioritizationInput): Promise<any>
    {
        let endPoint = prioritizationInput.allyCode;

        switch (prioritizationInput.sortType)
        {
            case PrioritizationSortTypeEnum.LSTB:
                endPoint = endPoint + '/1'
                break;
            case PrioritizationSortTypeEnum.DSTB:
                endPoint = endPoint + '/2'
                break;
            case PrioritizationSortTypeEnum.ARENA:
                endPoint = endPoint + '/3'
                break;
        }
        let parameters = [];
        if (prioritizationInput.alignment === PrioritizationAlignmentEnum.LIGHT)
        {
            parameters.push('alignmentFilter=1');
        }
        if (prioritizationInput.alignment === PrioritizationAlignmentEnum.DARK)
        {
            parameters.push('alignmentFilter=2');
        }
        if (prioritizationInput.minGear !== undefined)
        {
            parameters.push('minimumGearLevel=' + prioritizationInput.minGear);
        }
        if (prioritizationInput.gearThreshold !== undefined)
        {
            parameters.push('gearLevelThreshold=' + prioritizationInput.gearThreshold);
        }
        if (prioritizationInput.ignoreArena !== false)
        {
            parameters.push('ignoreArena=true');
        }
        if (prioritizationInput.limitTop !== undefined)
        {
            parameters.push('top=' + prioritizationInput.limitTop);
        }
        if (prioritizationInput.omicronConquest !== undefined)
        {
            parameters.push('omicronConquest=' + prioritizationInput.omicronConquest);
        }
        if (prioritizationInput.omicronGac !== undefined)
        {
            parameters.push('omicronGac=' + prioritizationInput.omicronGac);
        }
        if (prioritizationInput.omicronRaids !== undefined)
        {
            parameters.push('omicronRaids=' + prioritizationInput.omicronRaids);
        }
        if (prioritizationInput.omicronTb !== undefined)
        {
            parameters.push('omicronTb=' + prioritizationInput.omicronTb);
        }
        if (prioritizationInput.omicronTw !== undefined)
        {
            parameters.push('omicronTw=' + prioritizationInput.omicronTw);
        }
        endPoint = parameters.length === 0 ? endPoint : endPoint + '?' + parameters.join("&");

        return await PrioritizationAPI.getApi(endPoint);
    }

}

export default PrioritizationAPI;
