import { Modal, Spin } from "antd";
import GameData from "../../../model/GameData";
import { ImageOverride } from "../../../model/ImageOverride";
import { LoadoutDefinition } from "../../../model/LoadoutDefinition";
import UserData from "../../../model/UserData";
import BaseAPI from "../../../service/BaseAPI";
import ModAPI from "../../../service/ModAPI";
import { waitForTask } from "../../../ui/waitForTask";

export class ModAssignment
{
    modIds: string[] = [];
    id: string;

    constructor(id: string)
    {
        this.id = id;
    }
}

export class ModEquip
{
    public static async backupCurrentBaseline(user: UserData, name: string, category: string, description: string, skipRefresh: boolean = false)
    {
        let apiDto: any = {
            name: name,
            category: category,
            description: description,
            statDefinition: undefined
        }
        apiDto.units = [];

        Array.from(user.currentPlayer!.characters.values()).filter(u => u.combatType === 1 && u.level >= 50 && u.mods.length !== 0).forEach(u =>
        {
            apiDto.units.push({
                id: u.id,
                modIds: u.mods.map(mod => mod.id)
            });
        });

        await ModEquip.createLoadout(user, apiDto, skipRefresh);
    }


    public static async createNewLoadout(user: UserData, name: string, description: string, category: string, loadoutDefinition: LoadoutDefinition,
        units: any, skipRefresh: boolean = false)
    {

        let apiDto: any = {
            name: name,
            category: category,
            description: description,
            statDefinition: loadoutDefinition,
            units: units
        }
        await ModEquip.createLoadout(user, apiDto, skipRefresh);
    }

    public static async generateImage(user: UserData, allyCode: number, unitBaseIds: string[], imageOverrides: ImageOverride[]): Promise<string | undefined>
    {
        let retVal: string | undefined = undefined;
        let modal = Modal.info({ maskClosable: false, okButtonProps: { disabled: true, style: { display: 'none' } }, title: "Please wait, creating image", content: <Spin size="large" /> })
        try
        {
            retVal = await BaseAPI.generateImage(user, [allyCode], unitBaseIds, imageOverrides)
        }
        catch (response: any)
        {
            modal.update({ content: "Error: " + response.errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
            return undefined;
        }
        modal.destroy();
        return retVal;
    }

    public static async createLoadout(user: UserData, apiDto: any, skipRefresh: boolean = false)
    {

        let modal = Modal.info({ maskClosable: false, okButtonProps: { disabled: true, style: { display: 'none' } }, title: "Please wait, creating loadout: " + apiDto.name, content: <Spin size="large" /> })
        try
        {
            await BaseAPI.addLoadout(user, apiDto, false);
            if (skipRefresh !== false)
                await BaseAPI.fetchFullModSetData(user, true);
        }
        catch (response: any)
        {
            modal.update({ content: "Error: " + response.errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
            return;
        }
        modal.destroy();
    }

    public static async createBaselineSnapshot(user: UserData, gameData: GameData)
    {
        await BaseAPI.postToApi("mods/set/savebaseline", { sessionId: user.sessionId });
        await BaseAPI.fetchAllCurrentPlayerData(user, gameData, true, true);
    }


    public static async refreshFromPlayerData(user: UserData, fullGameData: any)
    {
        let loadoutsResponse = await BaseAPI.postToApi("mods/set/get", { sessionId: user.sessionId, name: "", test: true });
        user.currentPlayer!.fromAllJSON(fullGameData, loadoutsResponse);
    }

    public static async pushModsToGame(user: UserData, gameData: GameData, units: ModAssignment[])
    {
        let accountResponse: any = null;

        if (units.length > 5)
        {
            let creatingTaskModal = Modal.info({
                maskClosable: false,
                okButtonProps: { disabled: true, style: { display: 'none' } },
                title: "Please wait, creating mod task",
                content: <Spin size="large" />
            });

            try
            {
                let task = await ModAPI.applyModsTask(user, units, () => { }, (response: any) =>
                {
                    if (task.responseCode !== 1)
                    {
                        console.error(response);
                        let errorMessage = task.errorMessage;
                        Modal.error({ title: "Error applying loadout", content: <span>{errorMessage}</span>, maskClosable: false });
                    } else
                    {
                        accountResponse = response;
                    }
                });

                creatingTaskModal.destroy();
                await waitForTask(task, "Please wait, applying mods");

                if (accountResponse === null)
                {
                    return false;
                }
            }
            catch (err: any)
            {
                creatingTaskModal.destroy();
                console.error(err);
                let errorMessage = err.errorMessage !== undefined ? err.errorMessage : err.message !== undefined ? err.message : err;
                Modal.error({ title: "Error applying loadout", content: <span>{errorMessage}</span>, maskClosable: false });
                return false;
            }

            let loadoutStatusModal = Modal.info({
                maskClosable: false,
                okButtonProps: { disabled: true, style: { display: 'none' } },
                title: "Please wait, refreshing loadout status",
                content: <Spin size="large" />
            });

            try
            {
                await ModEquip.refreshFromPlayerData(user, accountResponse);
            }
            catch (err: any)
            {
                loadoutStatusModal.destroy();
                console.error(err);
                let errorMessage = err.errorMessage !== undefined ? err.errorMessage : err.message !== undefined ? err.message : err;
                Modal.error({ title: "Error applying loadout", content: <span>{errorMessage}</span>, maskClosable: false });
                return false;
            }
            loadoutStatusModal.destroy();
        }
        else
        {
            let modal = Modal.info({
                maskClosable: false,
                okButtonProps: { disabled: true, style: { display: 'none' } },
                title: "Please wait, applying mods",
                content: <Spin size="large" />
            });

            try
            {
                let response = await ModAPI.applyMods(user, units);
                accountResponse = response;

                if (accountResponse === null)
                {
                    return false;
                }
            }
            catch (response: any)
            {
                modal.destroy();

                console.error(response);
                let errorMessage = response.errorMessage !== undefined ? response.errorMessage : response.message !== undefined ? response.message : response;
                Modal.error({ title: "Error applying loadout", content: <span>{errorMessage}</span>, maskClosable: false });
                return false;
            }

            try
            {
                // shouldn't need a full refresh because we did one when we applied the mods
                await BaseAPI.fetchAllCurrentPlayerData(user, gameData, true, false);
            }
            catch (response: any)
            {
                modal.destroy();

                console.error(response);
                let errorMessage = response.errorMessage !== undefined ? response.errorMessage : response.message !== undefined ? response.message : response;
                Modal.error({ title: "Error applying loadout", content: <span>{errorMessage}</span>, maskClosable: false });
                return false;
            }

            modal.destroy();
        }
        return true;
    }
}