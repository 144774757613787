import { GuildEventData, IExcusedPlayer, IExcuseInstance, IPlayerViolation, IScheduledEvent } from "../model/EventData";
import UserData from "../model/UserData";
import BaseAPI from "./BaseAPI";

class EventAPI
{
    public static async getPlayerEvents(user: UserData): Promise<IScheduledEvent[]>
    {
        let resp = await BaseAPI.postToApi("personal/events/get", {
            sessionId: user.sessionId,
        });
        
        return resp.events;
    }

    public static async disablePlayerEvent(user: UserData, event: IScheduledEvent): Promise<any>
    {
        return await BaseAPI.postToApi("personal/events/disable", {
            sessionId: user.sessionId,
            guildId: event.guildId,
            eventType: event.eventType
        });
    }

    public static async getGuildEvents(user: UserData): Promise<GuildEventData>
    {
        let resp = await BaseAPI.postToApi("guild/events/get", {
            sessionId: user.sessionId,
        });
        
        return new GuildEventData(resp);
    }

    public static async setGuildEvents(user: UserData, event: GuildEventData): Promise<any>
    {
        return await BaseAPI.postToApi("guild/events/upsert", {
            sessionId: user.sessionId,
            events: event.toJSON()
        });
    }

    public static async cancelEvent(user: UserData, event: IScheduledEvent): Promise<any>
    {
        return await BaseAPI.postToApi("guild/events/cancel", {
            sessionId: user.sessionId,
            guildId: event.guildId,
            eventType: event.eventType,
            time: event.time
        });
    }

    public static async restoreEvent(user: UserData, event: IScheduledEvent): Promise<any>
    {
        return await BaseAPI.postToApi("guild/events/restore", {
            sessionId: user.sessionId,
            guildId: event.guildId,
            eventType: event.eventType,
            time: event.time
        });
    }

    public static async runEvent(user: UserData, event: "TICKET_CHECK" | "TICKET_RECORD" | "DATA_PULL" | "TW" | "RAID" | "TB_SPECIAL"): Promise<any>
    {
        let eventNum: number = 1;
        switch (event)
        {
            case "TICKET_CHECK":
                eventNum = 1;
                break;
            case "TICKET_RECORD":
                eventNum = 2;
                break;
            case "DATA_PULL":
                eventNum = 3;
                break;
            case "TW":
                eventNum = 4;
                break;
            case "RAID":
                eventNum = 5;
                break;
            case "TB_SPECIAL":
                eventNum = 7;
                break;
        }
        return await BaseAPI.postToApi("guild/events/run", {
            sessionId: user.sessionId,
            type: eventNum
        });
    }

    public static async getViolations(user: UserData): Promise<IPlayerViolation[]>
    {
        let resp = await BaseAPI.postToApi("guild/events/violations/get", {
            sessionId: user.sessionId,
        });
        
        return resp.players;
    }

    public static async deleteViolations(user: UserData, ids: number[]): Promise<IPlayerViolation[]>
    {
        let resp = await BaseAPI.postToApi("guild/events/violations/delete", {
            sessionId: user.sessionId,
            violationId: ids
        });

        return resp.players;
    }

    public static async getExcusedPlayers(user: UserData): Promise<IExcusedPlayer[]>
    {
        let resp = await BaseAPI.postToApi("guild/events/excused/get", {
            sessionId: user.sessionId,
        });
        
        return resp.players;
    }

    public static async deleteExcuse(user: UserData, id: number): Promise<IExcusedPlayer[]>
    {
        let resp = await BaseAPI.postToApi("guild/events/excused/upsert", {
            sessionId: user.sessionId,
            id: id,
            void: true
        });

        return resp.players;
    }

    public static async updateExcuse(user: UserData, allyCode: number, excuse: IExcuseInstance): Promise<IExcusedPlayer[]>
    {
        let resp = await BaseAPI.postToApi("guild/events/excused/upsert", {
            sessionId: user.sessionId,
            id: excuse.id,
            record: {...excuse, allyCode: allyCode},
            void: null
        });

        return resp.players;
    }
    
    public static async addExcuse(user: UserData, allyCode: number, excuse: IExcuseInstance): Promise<IExcusedPlayer[]>
    {
        let resp = await BaseAPI.postToApi("guild/events/excused/upsert", {
            sessionId: user.sessionId,
            id: null,
            record: {...excuse, allyCode: allyCode},
            void: null
        });

        return resp.players;
    }
}

export default EventAPI;
