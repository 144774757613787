import React from 'react';
import {
    Layout,
} from 'antd';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import styles from  "./styles/SelectorLayout.module.scss";
import Title from 'antd/lib/typography/Title';

interface IProps {
    children: React.ReactNode;
}

@observer
export class SelectorLayout extends React.Component<IProps> {
    @observable collapsed: boolean = false;

    toggle = action(() => {
        this.collapsed = !this.collapsed;
    });

    render() {
        return (
            <Layout className={`${styles.container} {"site-layout"}`}>
                <div className={styles.top}>
                    <img src={"hotsauce-logo.png"} alt={"Hot Sauce Logo"} title={"Hot Sauce"} />
                    <Title level={3}>Select Account</Title>
                </div>
                <div className={styles.bottom}>
                    {this.props.children}
                </div>
            </Layout>
        );
    }
}

