import { InputNumber, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { getAffixesForSet } from "../../../model/Datacron";
import datacronSet from "../../../model/gen_datacron_set.json";
import { statIdToString } from "../../../model/StatIds";
import styles from "./styles/Datacron.module.scss";

interface DatacronAffixPickerProps
{
    values: string[];
    onChange: (values: string[]) => void;

    secondaryValues?: Map<number, number>;
    onSetSecondaryValue?: (statId: number, value: number | null) => void;
}

interface SecondaryRow
{
    label: string;
    statType: number;
    value: number | undefined;
}

@observer
export default class DatacronAffixPicker extends React.Component<DatacronAffixPickerProps>
{
    @observable datacronSet: string = "ALL";

    columns: ColumnsType<SecondaryRow> = [
        {
            title: 'Stat',
            dataIndex: 'label',
            key: 'label'
        }, {
            title: 'Min',
            dataIndex: 'value',
            key: 'value',
            render: (text: string, record: SecondaryRow) =>
            {
                return <span>
                    <InputNumber value={record.value} onChange={(val: number | undefined | null) =>
                    {
                        this.props.onSetSecondaryValue!(record.statType, val === undefined ? null : val);
                    }} />
                </span>
            }
        }];

    renderSecondaryTable()
    {

        let affixSecondaries = getAffixesForSet(this.datacronSet === "ALL" ? undefined : parseInt(this.datacronSet))
            .filter(a => a.statType !== 0).map(a => a.statType);
        let selectedSecondaries = Array.from(this.props.secondaryValues!.keys());
        let uniqueSecondary = [...new Set(affixSecondaries.concat(selectedSecondaries))];
        let affixSecondaryOptions: SecondaryRow[] = uniqueSecondary.map(statType =>
        {
            return {
                label: statIdToString(statType, true),
                statType: statType,
                value: this.props.secondaryValues!.get(statType)
            };
        });

        return <div>
            <h3>Secondaries</h3>
            <Table
                pagination={false}
                size="small"
                columns={this.columns}
                dataSource={affixSecondaryOptions} />
        </div>;
    }

    render()
    {
        let setOptions = [{ label: "All Sets", value: "ALL" }];
        setOptions = setOptions.concat(Object.values(datacronSet).map(s => { return { label: s.displayName, value: s.id.toString() } }));

        const showSecondaries = this.props.secondaryValues && this.props.onSetSecondaryValue;

        let affixOptions = getAffixesForSet(this.datacronSet === "ALL" ? undefined : parseInt(this.datacronSet))
            .filter(a => a.shortText !== "")
            .map(a =>
            {
                return { label: a.shortText, value: a.abilityId + "/" + a.targetRule };
            });


        return <div className={styles.datacronAffixPicker}>
            <Select value={this.datacronSet} options={setOptions} onChange={(value) => this.onSetChanged(value)} />


            {showSecondaries && <h3>Primary</h3>}
            <Select value={this.props.values} mode="multiple" options={affixOptions} onChange={(value) => this.props.onChange(value)}
                placeholder="Choose bonuses" optionFilterProp="label" />

            {showSecondaries &&
                this.renderSecondaryTable()
            }

        </div>
    }

    @action
    private onSetChanged(set: string): void
    {
        this.datacronSet = set;
    }
}
