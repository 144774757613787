import React from "react";
import IPageProps from "../../IPageProps";
import { observer } from "mobx-react";
import { Button, Space } from 'antd';
import { Radio } from 'antd';
import styles from "./styles/ModWizard.module.scss";
import { GoalAccountEnum } from "./ModWizard";
import PlayerData from "../../../model/PlayerData";

interface IModWizardAutomationResultsProps extends IPageProps
{
    allyCode: string;
    loadedAllies: Map<number, PlayerData>;
    setAllyCode: (allyCode: string) => void;
    onLoadAlly: () => void;
    setGoalAccount: (target: number) => void;
    goalAccount: GoalAccountEnum;
    singleEventRemod: boolean;
    allyIsLoaded: boolean;
    validAllyCode: boolean;
    trimmedAllyCode: string;
}

@observer
class ModWizardTarget extends React.Component<IModWizardAutomationResultsProps>
{



    render()
    {

        return <div>
            {this.props.singleEventRemod ?
                <div>
                    <div className={styles.paddedrow}>
                        <h2>Select Account:</h2>
                    </div>
                    <div className={styles.paddedrow}>
                        <Radio.Group onChange={(e) => this.props.setGoalAccount(e.target.value)} value={this.props.goalAccount}>
                            <Space direction="vertical">
                                <Radio value={GoalAccountEnum.Self}>Self - can create loadout or push to game</Radio>
                                <Radio value={GoalAccountEnum.Ally}>Ally - will generate sharable image</Radio>
                            </Space>
                        </Radio.Group>
                    </div>

                    {
                        this.props.goalAccount === GoalAccountEnum.Ally &&
                        <React.Fragment>
                            <div className={styles.paddedrow}>
                                <div>
                                    Ally Code:
                                </div>
                                <div>
                                    <input type="text" value={this.props.allyCode} onChange={(e) => this.props.setAllyCode(e.target.value)} />
                                </div>
                            </div>
                            {this.props.allyIsLoaded === false ?
                                <Button className={styles.button}
                                    onClick={
                                        () =>
                                            this.props.onLoadAlly()}
                                    disabled={this.props.validAllyCode === false}>
                                    Load
                                </Button> :
                                <React.Fragment>
                                    <div className={styles.paddedrow}>
                                        {this.props.loadedAllies.get(Number(this.props.trimmedAllyCode))!.name}
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </div>
                :
                <div>
                    Target goal can only be used on own account.
                </div>
            }
        </div>;
    }
}

export default ModWizardTarget;
