import React from "react";

import { observer } from "mobx-react";
import { PlaygroundUnitSettings } from '../PlaygroundData';
import UnitAvatar from '../../../../components/swgoh/Character/UnitAvatar';
import styles from "./../styles/Playground.module.scss";
import ModSet from "../../../../components/swgoh/ModSet";
import { Divider } from 'antd';
import { SlotStyle } from '../../../../components/swgoh/ModSet/index';
import ModSlots from "../../../../model/ModSlots";
import PlaygroundStats from '../PlaygroundComponents/PlaygroundStats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/pro-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { convertToReadableStats } from '../PlaygroundData';
import { faStepForward, faForward } from "@fortawesome/pro-regular-svg-icons";
import { StatCalculatorUnitOutput } from '../PlaygroundCalculator/ModStats';
import { ModAssignmentConfiguration } from "../PlaygroundCalculator/ModAssignment";
import { LoadoutDefinitionTarget } from "../../../../model/LoadoutDefinition";
import UnitData from "../../../../model/UnitData";

interface IProps
{
    unitSettings: PlaygroundUnitSettings;
    target?: LoadoutDefinitionTarget;
    gameUnits: UnitData[];
    included: boolean;
    showStatDetails: boolean;
    onInclude: (include: boolean) => void;
    onClickUnitMods: () => void;
    onClickUnitPortrait: () => void;
    onLockUnit: () => void;
    onUnlockUnit: () => void;
    onClickAutomateUnit: () => void;
    onClickAutomateUnitContinue: () => void;
}

@observer class PlaygroundUnit extends React.Component<IProps> {

    static slotUpdated(slot: ModSlots, visibleMods: ModAssignmentConfiguration[], loadoutMods: ModAssignmentConfiguration[], inGameMods: ModAssignmentConfiguration[]): boolean
    {
        let visibleMod = visibleMods.find(mod => mod.mod.slot === slot);
        // let existingList = loadoutMods.length === 0 ? inGameMods : loadoutMods;
        let existingList = inGameMods;
        let existingMod = existingList.find(mod => mod.mod.slot === slot);

        let visibleModId = visibleMod === undefined ? null : visibleMod.mod.id;
        let existingModId = existingMod === undefined ? null : existingMod.mod.id;

        return visibleModId !== existingModId;
    }

    static deriveSlotStyles(visibleMods: ModAssignmentConfiguration[], loadoutMods: ModAssignmentConfiguration[], inGameMods: ModAssignmentConfiguration[],
        statCalculatorOutput: StatCalculatorUnitOutput | null): SlotStyle[]
    {

        let retVal: SlotStyle[] = [ModSlots.Square, ModSlots.Arrow, ModSlots.Diamond, ModSlots.Triangle, ModSlots.Circle, ModSlots.Cross].map(slot =>
        {
            let currentMod: ModAssignmentConfiguration | undefined = visibleMods.find(mod => mod.mod.slot === slot);
            let upgradedMod = false;

            if (currentMod !== undefined)
            {
                upgradedMod = currentMod.slice;
            }

            let wrongPrimary = statCalculatorOutput !== null && statCalculatorOutput.requirementsResult.primarySlotRequirementsFailed !== null &&
                statCalculatorOutput.requirementsResult.primarySlotRequirementsFailed.indexOf(slot) !== -1;


            let showBorder = upgradedMod || wrongPrimary;
            let borderColor = wrongPrimary ? "red" : upgradedMod ? "gold" : "white";

            return {
                slot: slot,
                backgroundColor: PlaygroundUnit.slotUpdated(slot, visibleMods, loadoutMods, inGameMods) === true ? "#eed2024a" : null,
                border: showBorder,
                borderColor: borderColor
            }
        });

        return retVal;
    }

    renderActionButtons(us: PlaygroundUnitSettings)
    {
        return <div className={styles.lockcontainer}>
            <div>
                <div className={styles.category}>
                    <FontAwesomeIcon icon={this.props.included ? faCheckSquare : faSquare}
                        className={styles.unitbuttonicon} onClick={() => { this.props.onInclude(!this.props.included); }} />
                    <span className={styles.title}>{this.props.included ? 'Remove' : 'Include'}</span>
                </div>
            </div>
            <div>
                {us.locked ?

                    <div className={styles.category}>
                        <FontAwesomeIcon icon={faLock} className={styles.unitbuttonicon} onClick={() => { this.props.onUnlockUnit(); }} />
                        <span className={styles.title}>Unlock</span>
                    </div>
                    :
                    <div className={styles.category}>
                        <FontAwesomeIcon icon={faLockOpen} className={styles.unitbuttonicon} onClick={() => { this.props.onLockUnit(); }} />
                        <span className={styles.title}>Lock</span>
                    </div>

                }
            </div>
            <div>

                <div className={styles.category}>
                    <FontAwesomeIcon icon={faStepForward} className={styles.unitbuttonicon} onClick={() => { this.props.onClickAutomateUnit(); }} />
                    <span className={styles.title}>Automate</span>
                </div>

            </div>
            <div>

                <div className={styles.category}>
                    <FontAwesomeIcon icon={faForward} className={styles.unitbuttonicon} onClick={() => { this.props.onClickAutomateUnitContinue(); }} />
                    <span className={styles.title}>Continue</span>
                </div>

            </div>
        </div>;
    }

    render()
    {
        const us = this.props.unitSettings;
        let playerUnit = us.playerCharacterData;
        let unitData = us.gameUnit;

        let playerUnitClassName = styles.playerunit;
        if (this.props.included) playerUnitClassName = playerUnitClassName + ' ' + styles.includedunit;
        if (us.lockBroken) playerUnitClassName = playerUnitClassName + ' ' + styles.brokenunit;
        if (us.locked) playerUnitClassName = playerUnitClassName + ' ' + styles.lockedunit;

        const modSetSyles = PlaygroundUnit.deriveSlotStyles(us.visibleConfirmedMods, us.loadoutMods, us.gameMods, us.statCalculatorOutput);

        let visibleStats = us.statCalculatorOutput === null || us.noUpdatesFromGame ? undefined : us.statCalculatorOutput.equippedStats;
        let readableVisibleStats = visibleStats === undefined ? undefined : convertToReadableStats(visibleStats, us.playerCharacterData);

        let modSetClassName = us.statCalculatorOutput !== null && (us.statCalculatorOutput.requirementsResult.setRequirementsIndexFailed !== null ||
            us.statCalculatorOutput.requirementsResult.brokenSetsPassed === false) ?
            styles.failedsetsummarycontainer : styles.summarycontainer;

        return <div className={this.props.included ? undefined : styles.notincluded}>
            <div className={styles.unitsummarycontainer}>
                {this.renderActionButtons(us)}
                <div className={styles.portraitsummarycontainer}>
                    <div className={styles.clickable}>
                        <UnitAvatar className={playerUnitClassName}
                            playerUnit={playerUnit}
                            unitData={unitData}
                            size={"large"}
                            displayName={true}
                            onClick={() => this.props.onClickUnitPortrait()} />
                    </div>
                </div>

                <div className={modSetClassName} >
                    <div onClick={() => this.props.onClickUnitMods()}>
                        <ModSet modSetStyles={modSetSyles}
                            mods={this.props.unitSettings.visibleConfirmedMods.map(mas => mas.sliceTarget)} />
                    </div>
                </div>

                {this.props.showStatDetails &&
                    <div className={styles.summarycontainer}>
                        <PlaygroundStats
                            baseStats={us.baseStatsReadable}
                            originalStats={us.inGameStats}
                            gameUnits={this.props.gameUnits}
                            gearStats={us.gearStatsReadable}
                            target={this.props.target}
                            requirementsResult={us.statCalculatorOutput === null ? undefined : us.statCalculatorOutput.requirementsResult}
                            desiredRequirementsResult={us.statCalculatorOutput === null ? undefined : us.statCalculatorOutput.desiredRequirementsResult}
                            currentStats={readableVisibleStats} />
                    </div>
                }
            </div>
            <Divider></Divider>
        </div>;
    }

}

export default PlaygroundUnit;