import { observable, action } from "mobx";

export class GrandArenaOpponent
{
    @observable allyCode: number;
    @observable name: string;
    @observable guildName: string | null;

    constructor(json: any)
    {
        this.allyCode = json.allyCode;
        this.name = json.name;
        this.guildName = json.guildName;
    }
}

export class GrandArena
{
    @observable opponents: Map<string, GrandArenaOpponent> = new Map();
    @observable opponent: GrandArenaOpponent | null = null;


    @action
    public grandArenaFromJSON(json: any): void
    {
        if (json.opponents)
        {
            for (let u of json.opponents)
            {
                let c = this.opponents.get(u.allyCode);
                if (!c)
                {
                    c = new GrandArenaOpponent(u);
                    this.opponents.set(u.allyCode, c);
                }
            }
        }
        if (json.opponent != null)
        {
            this.opponent = new GrandArenaOpponent(json.opponent);
        }
    }
}

export default GrandArena;
