import UserData from "../model/UserData";
import { getAPIenv, getAPIkey } from "../utils/api-key";
import { runInAction } from "mobx";
import PlayerData from "../model/PlayerData";
import GameData from './../model/GameData';
import { Cookies } from "react-cookie";
import { APITask } from "./APITask";
import ConfigurableSetting from './../model/ConfigurableSetting';
import { UnitModPreferences, UnitPriorization } from "../model/UnitModPreferences";
import { ImageOverride } from '../model/ImageOverride';
import { APILoadout } from "../model/ModLoadoutData";
import { IGuildData, IMinimalGuildData } from "../model/GuildData";
import TerritoryBattleData, { TBZoneCommands } from './../model/TerritoryBattleData';
import { ShareAccount } from '../model/AccountShare';
import { GuildWatch } from "../model/GuildWatch";
import { IGuildSquadTemplate } from "../model/Squads";
import { IInGameSquadTab, ISetInGameSquadTab } from "../model/IInGameSquads";
import { IContentCreator, IContentCreatorData } from "../model/ContentCreators";
import { IShare, ShareType } from "../model/IShare";
import { ITerritoryWarsData, ITerritoryWarsPlanning } from "../model/TWPlanning";
import { IDiscordDM, MessageButton, OutboundMessage } from "../model/DiscordMessage";
import { IUnitAbbreviation, IUnitAbbreviationRecord } from "../model/UnitData";
import moment from "moment";
import {TWZoneCommands} from "../model/TWData";
import TWTemplatesOverview from "../pages/TW/TWPlanning/TWTemplatesOverview";

interface ICache
{
    expireTimestamp: number;
    data: any;
}

interface ISquadDefinition 
{
    id: number;
    name: string;
    description: string;
    size: number;
    combatType: number;
    creator: IUserDetailsSquads;
    updator: IUserDetailsSquads;
    hasBeenShared: boolean;
    category: string[];
    contents: string;
    concrete: boolean;
    sortOrder: number;
    void: boolean;
    poolUnits: string[] | undefined;
    type: string | undefined;
    version: number;
    datacronAffixes?: string[];
    datacronRequired?: boolean;
}

interface IUserDetailsSquads
{
    playerName: string;
    allyCode: number;
    discordTag: string;
    guildName: string;
    createdUTC: string;
}

interface ISquads
{
    alliances: {
        id: number;
        name: string;
    }[];
    guilds: {
        id: string;
        name: string;
    }[];
    groupings: {
        definitions: ISquadDefinition[];
        groupName: string;
        shareType: number;
    }[];
}

interface IQueueStats
{
    queueClaimed: number;
    queueUnClaimed: number;
    outboundUnsent: number;
    queueErrors: number;
}

export class HTTPError extends Error
{
    public status: number;
    public constructor(stat: number)
    {
        super();
        this.status = stat;
    }
}

class BaseAPI
{
    private static cache = {} as { [key: string]: ICache };
    private static activeCacheCalls = new Set<string>();

    private static getCache(key: string)
    {
        const cache = this.cache[key];

        if (!cache || cache.expireTimestamp <= Date.now())
        {
            return;
        }

        return cache.data;
    }

    public static clearCache()
    {
        for (let key in this.cache)
        {
            delete this.cache[key];
        }
    }

    private static setCache(key: string, data: any, cacheSeconds: number)
    {
        if (cacheSeconds <= 0)
        {
            delete this.cache[key];

            return;
        }

        this.cache[key] = {
            expireTimestamp: Date.now() + cacheSeconds * 1000,
            data
        };
    }

    private static async usingCache(key: string, cacheSeconds: number, retrieveCall: () => Promise<any>, similarCacheKeys: string[] = [])
    {
        while (this.activeCacheCalls.has(key))
        {
            await new Promise(resolve => setTimeout(resolve, 300));
        }

        const cache = this.getCache(key);

        if (cache)
        {
            return cache;
        }

        this.activeCacheCalls.add(key);

        for (let i = 0; i < similarCacheKeys.length; i++)
        {
            const similarCache = this.getCache(similarCacheKeys[i]);

            if (similarCache)
            {
                return similarCache;
            }
        }

        try
        {
            let response = await retrieveCall();

            this.setCache(key, response, cacheSeconds);

            return response;
        } finally
        {
            this.activeCacheCalls.delete(key);
        }
    }

    public static async postToApi(endpoint: string, body: any): Promise<any>
    {
        let rawResp = await fetch(getAPIenv() + endpoint, {
            "method": "POST",
            "headers": {
                "APIUserId": String(getAPIkey()),
                "content-type": "application/json",
            },
            "body": JSON.stringify(body)
        });
        if (!rawResp.ok)
        {
            throw new HTTPError(rawResp.status);
        }
        let ret = await rawResp.json();
        if (ret.errorMessage)
        {
            throw ret;
        }
        return ret;
    }

    public static async getFromApi(endpoint: string): Promise<any>
    {
        let rawResp = await fetch(getAPIenv() + endpoint, {
            "method": "GET",
            "headers": {
                "APIUserId": String(getAPIkey()),
                "content-type": "application/json",
            }
        });
        let ret = await rawResp.json();
        if (ret.errorMessage)
        {
            throw ret;
        }
        return ret;
    }

    public static async downloadFromApi(endpoint: string, filename: string): Promise<void>
    {
        let rawResp = await fetch(getAPIenv() + endpoint,
            {
                method: "GET",
                "headers": {
                    "APIUserId": String(getAPIkey()),
                    "content-type": "application/json",
                }
            });
        let b = await rawResp.blob();
        let f = window.URL.createObjectURL(b);
        let link = document.createElement('a');
        link.href = f;
        link.download = filename;
        link.click();
        window.setTimeout(() => { window.URL.revokeObjectURL(f); }, 250);
    }

    private static async registerSession(user: UserData, cookies?: Cookies): Promise<void>
    {
        let response = await this.postToApi("auth/session/register", { languageId: 0, sessionId: null });
        runInAction(() => user.sessionId = response.sessionId);
        if (cookies)
        {
            cookies.set("hotUtilsSession", response.sessionId, { path: "/" });
        }
    }

    public static async discordLogin(user: UserData, accessToken: string, redirectPath: string, cookies?: Cookies): Promise<void>
    {
        if (!user.sessionId)
            await this.registerSession(user, cookies);

        await this.postToApi("auth/discord/login", {
            sessionId: user.sessionId,
            accessToken: accessToken,
            redirectUri: window.location.origin + redirectPath
        });
    }

    public static async huLogin(user: UserData, accessToken: string, redirectPath: string, cookies?: Cookies): Promise<void>
    {
        if (!user.sessionId)
            await this.registerSession(user, cookies);

        await this.postToApi("auth/link/login", {
            sessionId: user.sessionId,
            accessToken: accessToken,
            redirectUri: window.location.origin + redirectPath
        });
    }

    public static async discordChoosePlayer(user: UserData, gameData: GameData, player: PlayerData, cookies?: Cookies): Promise<void>
    {
        let response = await this.postToApi("auth/player/login", {
            sessionId: user.sessionId,
            allyCode: Number.parseInt(player.allyCode)
        });
        if (response.responseMessage !== "PLAYER LOGGED IN")
        {
            throw response;
        }
        runInAction(() =>
        {
            user.currentPlayer = player;
            user.currentPlayer.summaryFromJSON(response.player);
        });
        await this.updateSubscription(user);
        if (cookies)
        {
            cookies.set("hotUtilsAllyCode", player.allyCode, { path: "/" });
        }
        await this.fetchAllCurrentPlayerData(user, gameData);
    }

    public static async discordSupportChoosePlayer(user: UserData, gameData: GameData, supportRequest: string): Promise<void>
    {
        let response = await this.postToApi("auth/player/login", {
            sessionId: user.sessionId,
            allyCode: 111111111,
            supportRequest: supportRequest
        });
        if (response.responseMessage !== "PLAYER LOGGED IN")
        {
            throw response;
        }
        runInAction(() =>
        {
            user.currentPlayer = new PlayerData(response.player);
        });
        await this.updateSubscription(user);
        await this.fetchAllCurrentPlayerData(user, gameData);
    }

    public static async discordGetPlayers(user: UserData)
    {
        let response = await this.postToApi("auth/discord/getplayers", { sessionId: user.sessionId });
        const playerAccounts = response.playerAccounts.map((a: any) => new PlayerData(a));

        runInAction(() =>
        {
            user.players = playerAccounts;
            if (response.supportStaff)
            {
                user.isSupportStaff = response.supportStaff;
            }
        });

        return playerAccounts;
    }

    public static async discordRegisterAllyCode(user: UserData, allyCode: number): Promise<any>
    {
        return await this.postToApi("auth/discord/register", {
            sessionId: user.sessionId,
            allyCode: allyCode
        });
    }

    public static async reloginUser(user: UserData, gameData: GameData, allyCode: string): Promise<void>
    {
        let response = await this.postToApi("auth/player/login", {
            sessionId: user.sessionId,
            allyCode: Number.parseInt(allyCode),
        });
        if (response.responseMessage !== "PLAYER LOGGED IN")
        {
            throw response;
        }
        runInAction(() =>
        {
            user.currentPlayer = new PlayerData(response.player);
            user.currentPlayer.summaryFromJSON(response.player);
            if (response.player.supportStaff && !response.player.isSupportLogin)
            {
                user.isSupportStaff = true;
            }
        });
        await this.updateSubscription(user);
        await this.fetchAllCurrentPlayerData(user, gameData);
    }

    public static async googleLogin(user: UserData, gameData: GameData, accessCode: string, redirectPath: string, cookies?: Cookies, accessToken?: string): Promise<any>
    {
        if (!user.sessionId)
            await this.registerSession(user, cookies);

        let response = await this.postToApi("auth/player/googlelogin", {
            sessionId: user.sessionId,
            accessCode: accessCode,
            accessToken: accessToken || "",
            redirectUri: window.location.origin + redirectPath
        });

        runInAction(() =>
        {
            user.currentPlayer = new PlayerData(response.player);
            user.currentPlayer.summaryFromJSON(response.player);
        });
        await this.updateSubscription(user);

        if (cookies)
        {
            cookies.set("hotUtilsAllyCode", user.currentPlayer!.allyCode, { path: "/" });
        }

        await this.fetchAllCurrentPlayerData(user, gameData);
        return response;
    }

    public static async eaConnectEmail(user: UserData, email: string, cookies?: Cookies): Promise<{ authId: string, cookie: string[] }>
    {
        if (!user.sessionId)
            await this.registerSession(user, cookies);

        let response = await this.postToApi("auth/player/eaconnectemail", {
            sessionId: user.sessionId,
            email
        });
        return response;
    }

    public static async eaConnectCode(user: UserData, gameData: GameData, email: string, code: string, authId: string, cookies: string[], localCookies?: Cookies): Promise<any>
    {
        if (!user.sessionId)
            await this.registerSession(user, localCookies);

        let response = await this.postToApi("auth/player/eaconnectcode", {
            sessionId: user.sessionId,
            authId,
            cookie: cookies,
            email,
            code
        });

        runInAction(() =>
        {
            user.currentPlayer = new PlayerData(response.player);
            user.currentPlayer.summaryFromJSON(response.player);
        });
        await this.updateSubscription(user);

        if (localCookies)
        {
            localCookies.set("hotUtilsAllyCode", user.currentPlayer!.allyCode, { path: "/" });
        }

        await this.fetchAllCurrentPlayerData(user, gameData);
        return response;
    }

    public static async updateSubscription(user: UserData): Promise<void>
    {
        let response = await this.postToApi("account/subscription", { sessionId: user.sessionId });
        user.fromSubscriptionJSON(response);
        // and get their theme choice
        try
        {
            let theme = await this.getPlayerSetting(user, "theme");
            if (theme && theme.length > 0)
            {
                runInAction(() => user.darkModeEnabled = theme[0].value === "darkTheme");
            }
            else
            {
                runInAction(() => user.darkModeEnabled = false);
            }
        }
        catch
        {
            runInAction(() => user.darkModeEnabled = false);
        }
    }

    public static async updateGI(user: UserData): Promise<void>
    {
        let response = await this.postToApi("account/subscription", { sessionId: user.sessionId, resetGrandIvoryLink: true });
        user.fromSubscriptionJSON(response);
    }

    public static async fetchAllGameData(user: UserData, gameData: GameData): Promise<void>
    {
        let response = await this.postToApi("gamedata/units", { sessionId: user.sessionId, name: "" });
        gameData.fromJSON(response);
    }

    public static async fetchAllCurrentPlayerData(user: UserData, gameData: GameData | undefined, testLoadout: boolean = false, doFullRefresh: boolean = false): Promise<void>
    {
        if (doFullRefresh && !user.readOnly)
        {
            await this.postToApi("account/refresh", { sessionId: user.sessionId });
        }
        let response = await this.postToApi("account/data/all", { sessionId: user.sessionId });
        if (!user.currentPlayer)
        {
            // shouldn't happen...
            runInAction(() =>
            {
                user.currentPlayer = new PlayerData();
            });
        }
        let loadoutsResponse = null;

        if (doFullRefresh)
        {
            loadoutsResponse = await this.postToApi("mods/set/get", { sessionId: user.sessionId, name: "", archive: false, test: testLoadout });
        }

        if (!user.currentPlayer)
        {
            // shouldn't happen...
            runInAction(() =>
            {
                user.currentPlayer = new PlayerData();
            });
        }
        user.currentPlayer!.fromAllJSON(response, loadoutsResponse);
        if (gameData !== undefined && gameData.units == null)
            await this.fetchAllGameData(user, gameData);
    }

    public static async fetchCompareData(user: UserData): Promise<void>
    {
        let response = await this.postToApi("imagecompare/meta/get", { sessionId: user.sessionId });
        if (!user.currentPlayer)
        {
            // shouldn't happen...
            runInAction(() =>
            {
                user.currentPlayer = new PlayerData();
            });
        }

        user.currentPlayer!.compareGroupsFromJson(response);
    }


    public static async fetchModSetList(user: UserData, setList: string[]): Promise<void>
    {

        if (user.currentPlayer !== null)
        {
            let loadoutsResponse = await this.postToApi("mods/set/get", { sessionId: user.sessionId, name: "", test: true, archived: false, setList });
            user.currentPlayer!.loadoutsFromJSON(loadoutsResponse.sets, false);
        }
    }

    public static async fetchFullModSetData(user: UserData, forceRefresh: boolean = false, archived: boolean = false, forceRefetch: boolean = false): Promise<void>
    {

        if (user.currentPlayer !== null && ((user.currentPlayer.modLoadouts.length > 0 && user.currentPlayer.modLoadouts[0].testResult === null) ||
            forceRefresh || user.currentPlayer.loadoutsInitialized === false || archived !== user.currentPlayer!.archivedLoaded || forceRefetch))
        {
            const displayArchived: undefined | boolean = archived ? undefined : archived;

            let loadoutsResponse = await this.postToApi("mods/set/get", { sessionId: user.sessionId, name: "", test: true, archived: displayArchived });
            user.currentPlayer!.loadoutsFromJSON(loadoutsResponse.sets, archived);
        }
    }

    public static async fetchGuildModSetData(user: UserData, forceRefresh: boolean = false): Promise<void>
    {

        if (user.currentPlayer !== null && (user.currentPlayer.guildModLoadouts === undefined || forceRefresh))
        {
            let guildSettings: ConfigurableSetting[] = await BaseAPI.getGuildSetting(user,
                APILoadout.ALLY_KEY + user.currentPlayer.allyCode);
            if (guildSettings.length > 0)
            {
                let guildLoadoutsJson = JSON.parse(guildSettings[0].value);
                user.currentPlayer.guildLoadoutsFromJSON(guildLoadoutsJson);
            } else
            {
                runInAction(() => user.currentPlayer!.guildModLoadouts = []);
            }
        }
    }

    public static async fetchTbData(user: UserData, refresh: boolean): Promise<void>
    {

        if (user.currentPlayer !== null && user.currentPlayer.territoryBattleData === null)
        {
            let response = await this.postToApi("tb/get", { sessionId: user.sessionId, refresh: refresh });

            if (response.data.conflictZoneStatus !== null && response.data.conflictZoneStatus !== undefined)
            {
                user.currentPlayer.tbFromJSON(response);
            }
        }
    }

    public static async fetchHistoricalTbData(user: UserData, instanceId: string, phase: number): Promise<TerritoryBattleData>
    {

        let response = await this.postToApi("tb/history/get", { sessionId: user.sessionId, instanceId: instanceId, phase: phase });
        if (response.data.conflictZoneStatus !== null && response.data.conflictZoneStatus !== undefined)
        {
            return TerritoryBattleData.fromJSON(response);
        }
        throw new Error("unable to retrieve tb history data");
    }

    public static async fetchTbHistory(user: UserData): Promise<void>
    {

        if (user.currentPlayer !== null && user.currentPlayer.territoryBattleHistory === null)
        {
            let response = await this.postToApi("tb/history/list", { sessionId: user.sessionId });
            if (response.instance !== null && response.instance !== undefined)
            {
                user.currentPlayer.tbHistoryFromJSON(response);
            }
        }
    }


    public static async fetchTbGameData(user: UserData, gameData: GameData): Promise<any>
    {

        if (user.currentPlayer !== null)
        {
            let response = await this.postToApi("gamedata/tb", { sessionId: user.sessionId });

            return response;
        }
    }

    public static async fetchAllyPlayerData(user: UserData, allyCodes: number[]): Promise<any>
    {
        let filteredAllyCodes = allyCodes.filter(allyCode => user.otherPlayers.find(u => u.allyCode === allyCode.toString()) === undefined);

        return await this.usingCache(
            `${user.sessionId}|${filteredAllyCodes.join("|")}`,
            2400,
            async () =>
            {
                const response = await this.postToApi("profile/player/getdata", { sessionId: user.sessionId, allyCode: filteredAllyCodes });

                return response.data;
            }
        );
    }

    public static async uploadData(user: UserData, isPublic: boolean, data: any, key: string, type: string, perPlayer?: boolean): Promise<void>
    {
        await this.postToApi("datastore/" + (perPlayer ? "player" : "discord") + "/upload", {
            sessionId: user.sessionId,
            document: {
                data: JSON.stringify(data),
                store: isPublic ? "Public" : "Personal",
                type: type,
                key: key
            }
        });
    }

    public static async downloadData(user: UserData, isPublic: boolean, key: string, type: string, perPlayer?: boolean): Promise<any>
    {
        return await this.postToApi("datastore/" + (perPlayer ? "player" : "discord") + "/download", {
            sessionId: user.sessionId,
            query: {
                store: isPublic ? "Public" : "Personal",
                type: type,
                key: key
            }
        });
    }

    public static async downloadAllData(user: UserData, isPublic: boolean, type: string, perPlayer?: boolean): Promise<any>
    {
        return await this.postToApi("datastore/" + (perPlayer ? "player" : "discord") + "/download", {
            sessionId: user.sessionId,
            query: {
                store: isPublic ? "Public" : "Personal",
                type: type
            }
        });
    }

    public static async deleteData(user: UserData, isPublic: boolean, key: string, type: string, perPlayer?: boolean): Promise<any>
    {
        return await this.postToApi("datastore/" + (perPlayer ? "player" : "discord") + "/delete", {
            sessionId: user.sessionId,
            query: {
                store: isPublic ? "Public" : "Personal",
                type: type,
                key: key
            }
        });
    }

    public static async likeData(user: UserData, key: string, type: string): Promise<any>
    {
        return await this.postToApi("datastore/like", {
            sessionId: user.sessionId,
            type: type,
            key: key
        });
    }

    public static async unlikeData(user: UserData, key: string, type: string): Promise<any>
    {
        return await this.postToApi("datastore/unlike", {
            sessionId: user.sessionId,
            type: type,
            key: key
        });
    }

    public static async addLoadout(user: UserData, set: any, rememberEmptyUnits: boolean): Promise<any>
    {
        const response = await this.postToApi("mods/set/add", { sessionId: user.sessionId, set: set, rememberEmptyUnits: rememberEmptyUnits });

        if (response.set)
        {
            runInAction(() =>
            {
                user.currentPlayer!.loadoutFromJSON(response.set);
            });
        }
        return response;
    }

    public static async getAllPlayerSettings(user: UserData): Promise<any>
    {
        let response = await this.postToApi("setting/player/get", {
            sessionId: user.sessionId,
            settingId: null
        });
        return response.setting;
    }

    public static async getAllGuildSettings(user: UserData): Promise<any>
    {
        let response = await this.postToApi("setting/guild/get", {
            sessionId: user.sessionId,
            settingId: null
        });
        return response.setting;
    }

    public static async fetchUnitModPreferences(user: UserData, gameData: GameData): Promise<void>
    {
        if (user.currentPlayer!.unitModPreferences === null)
        {
            let umps: UnitModPreferences[] = [];
            let settings: ConfigurableSetting[] = await BaseAPI.getPlayerSetting(user, UnitModPreferences.SETTINGS_KEY);
            if (settings.length > 0)
            {
                let umpsJson = JSON.parse(settings[0].value);
                if (umpsJson !== undefined)
                {
                    umps = umpsJson.map((uj: any) => UnitModPreferences.fromJson(uj));
                }
            }
            UnitModPreferences.initializeUnits(umps, gameData.units!, user.currentPlayer!.characters!);
            runInAction(() => user.currentPlayer!.unitModPreferences = umps);
        }
    }

    public static async saveUnitModPreferences(user: UserData): Promise<void>
    {
        if (user.currentPlayer!.unitModPreferences !== null)
        {
            BaseAPI.setPlayerSetting(user, UnitModPreferences.SETTINGS_KEY,
                JSON.stringify(user.currentPlayer!.unitModPreferences));
        }
    }


    public static async fetchUnitPrioritizations(user: UserData, gameData: GameData): Promise<void>
    {
        if (user.currentPlayer!.unitPriorization === null)
        {
            let unitPriorization = new UnitPriorization();

            let settings: ConfigurableSetting[] = await BaseAPI.getPlayerSetting(user, "unit_prioritizations");
            if (settings.length > 0)
            {
                unitPriorization = new UnitPriorization(JSON.parse(settings[0].value));
            }
            unitPriorization.initializeUnits(user.currentPlayer!.characters!, gameData.units!);
            runInAction(() => user.currentPlayer!.unitPriorization = unitPriorization);
        }
    }

    public static async saveUnitPriorizations(user: UserData): Promise<void>
    {
        if (user.currentPlayer!.unitPriorization !== null)
        {
            BaseAPI.setPlayerSetting(user, "unit_prioritizations",
                JSON.stringify(user.currentPlayer!.unitPriorization));
        }
    }

    public static async getPlayerSetting(user: UserData, settingId: string): Promise<any>
    {
        let response = await this.postToApi("setting/player/get", {
            sessionId: user.sessionId,
            settingId: settingId
        });
        return response.setting;
    }

    public static async getGuildSetting(user: UserData, settingId: string): Promise<any>
    {
        let response = await this.postToApi("setting/guild/get", {
            sessionId: user.sessionId,
            settingId: settingId
        });
        return response.setting;
    }


    public static async getSystemSetting(user: UserData, settingId: string): Promise<any>
    {
        let response = await this.postToApi("setting/system/get", {
            sessionId: user.sessionId,
            settingId: settingId
        });
        return response.setting;
    }

    public static async setSystemSetting(user: UserData, settingId: string, value: any): Promise<any>
    {
        return await this.postToApi("setting/system/set", {
            sessionId: user.sessionId,
            settingId: settingId,
            value: value
        });
    }


    public static async getGuildTickets(user: UserData): Promise<any>
    {
        return await this.postToApi("guild/events/tickets", {
            sessionId: user.sessionId,
        });
    }

    public static async setPlayerSetting(user: UserData, settingId: string, value: any, validateValue: boolean = false): Promise<any>
    {
        return await this.postToApi("setting/player/set", {
            sessionId: user.sessionId,
            settingId: settingId,
            value: value,
            validateValueAsJson: validateValue
        });
    }

    public static async setGuildSetting(user: UserData, settingId: string, value: any, validateValue: boolean = false): Promise<any>
    {
        return await this.postToApi("setting/guild/set", {
            sessionId: user.sessionId,
            settingId: settingId,
            value: value,
            validateValueAsJson: validateValue
        });
    }

    public static async setTBCommands(user: UserData, data: TBZoneCommands[]): Promise<any>
    {
        return await this.postToApi("tb/command", {
            sessionId: user.sessionId,
            Commands: data
        });
    }

    public static async setTWCommands(user: UserData, data: TWZoneCommands[]): Promise<any>
    {
        return await this.postToApi("tw/command", {
            sessionId: user.sessionId,
            Commands: data
        });
    }

    public static convertSquadToTemplate(def: ISquadDefinition, shareType?: ShareType, sharing?: IShare): IGuildSquadTemplate
    {
        let units = [];

        try
        {
            units = JSON.parse(def.contents);
            for (let i = 0; i < units.length; i++)
            {
                const unit = units[i];
                if (unit.requirements.filters.stars === undefined)
                {
                    unit.requirements.filters.stars = 2;
                }
            }
        } catch (e)
        {
            console.error(`Failed decoding units for template #${def.id}`, def.contents);
        }
        const templateSquad: IGuildSquadTemplate = {
            id: def.id,
            name: def.name,
            category: def.category,
            description: def.description,
            editedBy: {
                playerName: def.updator?.playerName,
                allyCode: def.updator?.allyCode,
                guildName: def.updator?.guildName,
                discordTag: def.updator?.discordTag,
                createdUTC: def.updator?.createdUTC
            },
            createdBy: {
                playerName: def.creator?.playerName,
                allyCode: def.creator?.allyCode,
                guildName: def.creator?.guildName,
                discordTag: def.creator?.discordTag,
                createdUTC: def.creator?.createdUTC
            },
            hasBeenShared: def.hasBeenShared,
            sortOrder: def.sortOrder,
            shareType: shareType !== undefined ? shareType : 0,
            combatType: def.combatType,
            units,
            poolUnits: def.poolUnits,
            type: def.type,
            version: def.version,
            datacronAffixes: def.datacronAffixes,
            datacronRequired: def.datacronRequired
        };

        const sharedTemplateSquad: IGuildSquadTemplate = {
            ...templateSquad,
            sharing: sharing
        }

        if (sharing !== undefined)
        {
            return sharedTemplateSquad;
        } else
        {
            return templateSquad;
        }

    }

    public static async getSquads(user: UserData): Promise<IGuildSquadTemplate[]>
    {
        const response = await this.postToApi("squads/list", {
            sessionId: user.sessionId
        }) as ISquads;

        return response.groupings.flatMap(group =>
            group.definitions.map((x, index) =>
            {
                return this.convertSquadToTemplate(x, group.shareType);
            })
        );
    }

    public static async getSquadInfo(user: UserData, templateId: number): Promise<IGuildSquadTemplate>
    {
        const response = await this.postToApi("squads/get", {
            sessionId: user.sessionId,
            id: templateId
        });

        return response.squad;

    }

    public static async upsertSquad(user: UserData, template: IGuildSquadTemplate, shareType: ShareType | null, sharing: IShare | null): Promise<IGuildSquadTemplate>
    {
        let resp = await this.postToApi("squads/upsert", {
            id: template.id,
            creator: {
                discordTag: template.createdBy.discordTag,
                allyCode: template.createdBy.allyCode,
                playerName: template.createdBy.playerName,
                guildName: template.createdBy.guildName,
                createdUTC: template.createdBy.createdUTC
            },
            updator: {
                discordTag: template.editedBy.discordTag,
                allyCode: template.editedBy.allyCode,
                playerName: template.editedBy.playerName,
                guildName: template.editedBy.guildName,
                createdUTC: template.editedBy.createdUTC
            },
            definition: {
                name: template.name,
                description: template.description,
                size: template.units.length,
                combatType: template.combatType,
                category: template.category,
                contents: JSON.stringify(template.units),
                poolUnits: template.poolUnits,
                type: template.type,
                concrete: false,
                sortOrder: template.sortOrder,
                version: 1,
                datacronAffixes: template.datacronAffixes,
                datacronRequired: template.datacronRequired
            },
            void: false,
            sharing: sharing,
            sessionId: user.sessionId
        });
        return this.convertSquadToTemplate(resp.squad, shareType === ShareType.Personal ? 0 : shareType === ShareType.Guild ? 2 : shareType === ShareType.Alliance ? 3 : undefined, sharing ? sharing : undefined);
    }

    public static async upsertGuildSquad(user: UserData, template: IGuildSquadTemplate, shareType: ShareType): Promise<IGuildSquadTemplate>
    {
        let resp = await this.postToApi("squads/upsert/publish/guild", {
            id: template.id,
            creator: {
                discordTag: template.createdBy.discordTag,
                allyCode: template.createdBy.allyCode,
                playerName: template.createdBy.playerName,
                guildName: template.createdBy.guildName,
                createdUTC: template.createdBy.createdUTC
            },
            updator: {
                discordTag: template.editedBy.discordTag,
                allyCode: template.editedBy.allyCode,
                playerName: template.editedBy.playerName,
                guildName: template.editedBy.guildName,
                createdUTC: template.editedBy.createdUTC
            },
            definition: {
                name: template.name,
                description: template.description,
                size: template.units.length,
                combatType: template.combatType,
                category: template.category,
                contents: JSON.stringify(template.units),
                concrete: false,
                sortOrder: template.sortOrder,
                poolUnits: template.poolUnits,
                type: template.type,
                version: 1,
                datacronAffixes: template.datacronAffixes,
                datacronRequired: template.datacronRequired
            },
            sessionId: user.sessionId
        });
        return this.convertSquadToTemplate(resp.squad, shareType === ShareType.Personal ? 0 : shareType === ShareType.Guild ? 2 : 3);
    }

    public static async deleteSquad(user: UserData, templateId: number): Promise<any>
    {
        return await this.postToApi("squads/upsert", {
            "id": templateId,
            "void": true,
            "sessionId": user.sessionId
        });
    }

    public static async updateSquadOrder(user: UserData, squads: IGuildSquadTemplate[]): Promise<any>
    {
        return await this.postToApi("squads/upsert/order", {
            "squadOrder": squads.map(squad =>
            {
                return {
                    "id": squad.id,
                    "sortOrder": squad.sortOrder
                };
            }),
            "sessionId": user.sessionId
        });
    }

    public static async setContentData(user: UserData, data: IContentCreatorData, category: string[], sharing: IShare, contentId?: string): Promise<any>
    {
        return await this.postToApi("data/upsert/1", {
            sessionId: user.sessionId,
            id: contentId,
            categories: category,
            data: JSON.stringify(data),
            sharing
        });
    }

    public static async getContentData(user: UserData, category?: string[]): Promise<IContentCreator[]>
    {
        let response = await this.postToApi("data/get/1", {
            sessionId: user.sessionId,
            categories: category
        });

        return response.document.map((raw: any) =>
        {
            let data: IContentCreatorData;

            try
            {
                data = JSON.parse(raw.data);

                if (!data.counters)
                {
                    data.counters = [];
                }
                if (!data.opponentCharactersId)
                {
                    data.opponentCharactersId = [];
                }
            }
            catch (err: any)
            {
                return { ...raw, data: undefined };
            }

            return { ...raw, data } as IContentCreator;
        }).filter((c: IContentCreator) => c.data !== undefined);
    }

    public static async deleteContentData(user: UserData, contentId: string): Promise<any>
    {
        return await this.postToApi("data/delete/1", {
            sessionId: user.sessionId,
            id: contentId
        });
    }

    public static async likeContentData(user: UserData, contentId: string): Promise<any>
    {
        return await this.postToApi("data/like/1", {
            sessionId: user.sessionId,
            id: contentId
        });
    }

    public static async unlikeContentData(user: UserData, contentId: string): Promise<any>
    {
        return await this.postToApi("data/unlike/1", {
            sessionId: user.sessionId,
            id: contentId
        });
    }


    public static async getTWDataTemplates(user: UserData, category?: string[]): Promise<ITerritoryWarsPlanning[]>
    {
        let response = await this.postToApi("data/get/3", {
            sessionId: user.sessionId,
            categories: category
        });
        // console.log('Data squads defined');

        return response.document.map((raw: any) =>
        {
            let data: ITerritoryWarsData;

            try
            {
                data = JSON.parse(raw.data);

                if (!data.squads)
                {
                    // console.log('Data squads undefined');
                    data.squads = [];
                }
                if (!data.zones)
                {
                    // console.log('Data zones undefined');
                    data.zones = [];
                }
                let wasMissingZone = false;
                data.zones = data.zones.map((z, index) => {
                    if (z == null)
                    {
                        wasMissingZone = true;
                        return TWTemplatesOverview.getZones()[index];
                    }
                    else
                    {
                        return z;
                    }
                });
                if (wasMissingZone)
                {
                    data.zones = data.zones.map((z, index) => {
                        z.index = index + 1;
                        z.id = index + 1;
                        return z;
                    });
                }

                // console.log(`Data unprocessed: ${data.name} `, data.squads.length);
                data.squads = data.squads.filter(x => x.zoneId);
            }
            catch (err: any)
            {
                return { ...raw, data: undefined };
            }
            // console.log(`Data processed: ${data.name} `, data.squads.length);

            return { ...raw, data } as ITerritoryWarsData;
        }).filter((c: ITerritoryWarsPlanning) => c.data !== undefined);
    }

    public static async setTWDataTemplates(user: UserData, category: string[], data: ITerritoryWarsData, twTemplateId?: string): Promise<ITerritoryWarsPlanning>
    {
        let response = await this.postToApi("data/upsert/3", {
            sessionId: user.sessionId,
            categories: category,
            data: JSON.stringify(data),
            id: twTemplateId,
        });

        return {
            id: response.id,
            data: data,
            categories: category,
            updatedUtc: response.updatedUtc,
            shareType: response.shareType,
            sharing: response.sharing
        };
    }

    public static async deleteTWDataTemplate(user: UserData, contentId: string): Promise<any>
    {
        return await this.postToApi("data/delete/3", {
            sessionId: user.sessionId,
            id: contentId
        });
    }

    public static async updateLoadout(user: UserData, currentLoadoutName: string, newLoadoutName: string, newCategory: string, newDescription: string, newArchived: boolean): Promise<any>
    {
        return await this.postToApi("mods/set/update", {
            sessionId: user.sessionId,
            name: currentLoadoutName,
            newName: newLoadoutName,
            newCategory: newCategory,
            newDescription: newDescription,
            newArchived
        });
    }

    public static async removeLoadout(user: UserData, name: string): Promise<any>
    {
        return await this.postToApi("mods/set/delete", { sessionId: user.sessionId, name: name });
    }

    public static async openBronziumsTask(user: UserData, minimumCurrency: number, maximumTimes: number, inboxReceipt: boolean, updateCallback: (response: any) => void, doneCallback: (response: any) => void): Promise<APITask>
    {
        let response = await BaseAPI.postToApi("account/task/bronzium", {
            sessionId: user.sessionId,
            minimumCurrency,
            maximumTimes,
            inboxReceipt,
            simulation: false
        });
        return new APITask(response.taskId, user, true, 1000, updateCallback, doneCallback);
    }

    public static async sendDiscordMessage(user: UserData, allyCode: number[], message: string,
        attachment?: { fileContent: string, fileName: string }, buttons?: MessageButton[]): Promise<any>
    {
        let response = await BaseAPI.postToApi("discord/dm", {
            sessionId: user.sessionId,
            allyCode: allyCode,
            message: message,
            fileContent: attachment?.fileContent,
            fileName: attachment?.fileName,
            buttons
        });
        return response;
    }

    public static async sendMassDiscordMessage(user: UserData, list: IDiscordDM[], attachment?: { fileContent: string, fileName: string }): Promise<any>
    {
        let response = await BaseAPI.postToApi("discord/broadcast", {
            sessionId: user.sessionId,
            dm: list,
            fileContent: attachment?.fileContent,
            fileName: attachment?.fileName
        });
        return response;
    }

    public static async sendMassDiscordMessageTask(user: UserData, list: IDiscordDM[],
        updateCallback: (response: any) => void,
        doneCallback: (response: any) => void,
        attachment?: { fileContent: string, fileName: string }): Promise<APITask>
    {
        let response = await BaseAPI.postToApi("discord/task/broadcast", {
            sessionId: user.sessionId,
            dm: list,
            fileContent: attachment?.fileContent,
            fileName: attachment?.fileName,
            simulation: false
        });

        if (response.taskId === 0)
        {
            throw new Error("Unable to create task (consider refreshing): " + response.responseMessage);
        }
        return new APITask(response.taskId, user, true, 500, updateCallback, doneCallback);
    }

    public static async sendV2DiscordMessages(user: UserData, messages: OutboundMessage): Promise<any>
    {
        let response = await BaseAPI.postToApi("hotbot/services/outbound/create", {
            sessionId: user.sessionId,
            messages
        });
        return response;
    }



    public static async getGuildList(user: UserData, includeRoster: boolean, includeBaseStats: boolean, guildId?: string, allyCode?: number, hoursBeforeStale?: number): Promise<IGuildData>
    {
        const cacheSeconds = hoursBeforeStale ? hoursBeforeStale * 3600 : 3600;
        const similarCacheKeys = [] as string[];

        if (!includeRoster)
        {
            // check cache with roster
            similarCacheKeys.push(`${user.sessionId}|1|${guildId || 0}|${allyCode || 0}`);
        }

        if (guildId && allyCode)
        {
            // check cache for guild
            similarCacheKeys.push(`${user.sessionId}|${includeRoster ? 1 : 0}|${guildId || 0}|0`);

            if (!includeRoster)
            {
                // check cache for guild with roster
                similarCacheKeys.push(`${user.sessionId}|1|${guildId || 0}|0`);
            }
        }

        let resp = await this.usingCache(
            `${user.sessionId}|${includeRoster ? 1 : 0}|${guildId || 0}|${allyCode || 0}`,
            cacheSeconds,
            () => this.postToApi("profile/guild/get", {
                sessionId: user.sessionId,
                includeRoster,
                guildId: guildId !== undefined ? guildId : null,
                allyCode: allyCode !== undefined ? allyCode : 0,
                includeBaseStats,
                hoursBeforeStale
            }),
            similarCacheKeys
        );

        if (resp.data?.guild?.id)
        {
            // store cache for guild
            this.setCache(`${user.sessionId}|${includeRoster ? 1 : 0}|${resp.data.guild.id}|0`, resp, cacheSeconds);
        }

        if (resp.data?.players instanceof Array)
        {
            // store cache for other guild members
            for (let i = 0; i < resp.data.players.length; i++)
            {
                const player = resp.data.players[i];

                this.setCache(`${user.sessionId}|${includeRoster ? 1 : 0}|${guildId || 0}|${player.allyCode}`, resp, cacheSeconds);
            }
        }

        return resp.data;
    }

    public static async getMinimalGuildList(user: UserData, includeRoster: boolean, includeDatacrons: boolean, guildId?: string, allyCode?: number, hoursBeforeStale?: number): Promise<IMinimalGuildData>
    {
        const cacheSeconds = hoursBeforeStale ? hoursBeforeStale * 3600 : 3600;
        const similarCacheKeys = [] as string[];

        if (!includeRoster)
        {
            // check cache with roster
            similarCacheKeys.push(`minimal|${user.sessionId}|1|${guildId || 0}|${allyCode || 0}`);
        }

        if (guildId && allyCode)
        {
            // check cache for guild
            similarCacheKeys.push(`minimal|${user.sessionId}|${includeRoster ? 1 : 0}|${guildId || 0}|0`);

            if (!includeRoster)
            {
                // check cache for guild with roster
                similarCacheKeys.push(`minimal|${user.sessionId}|1|${guildId || 0}|0`);
            }
        }

        let resp = await this.usingCache(
            `minimal|${user.sessionId}|${includeRoster ? 1 : 0}|${guildId || 0}|${allyCode || 0}`,
            cacheSeconds,
            () => this.postToApi("profile/guild/get/minimal", {
                sessionId: user.sessionId,
                includeRoster,
                guildId: guildId !== undefined ? guildId : null,
                allyCode: allyCode !== undefined ? allyCode : 0,
                includeDatacrons,
                hoursBeforeStale
            }),
            similarCacheKeys
        );

        if (resp.data?.guild?.id)
        {
            // store cache for guild
            this.setCache(`minimal|${user.sessionId}|${includeRoster ? 1 : 0}|${resp.data.guild.id}|0`, resp, cacheSeconds);
        }

        if (resp.data?.players instanceof Array)
        {
            // store cache for other guild members
            for (let i = 0; i < resp.data.players.length; i++)
            {
                const player = resp.data.players[i];

                this.setCache(`minimal|${user.sessionId}|${includeRoster ? 1 : 0}|${guildId || 0}|${player.allyCode}`, resp, cacheSeconds);
            }
        }

        return resp.data;
    }

    public static async getGuildTbData(user: UserData): Promise<IGuildData>
    {
        const allyCode = parseInt(user.currentPlayer!.allyCode);

        let resp = await BaseAPI.postToApi("profile/guild/get", {
            sessionId: user.sessionId,
            includeRoster: true,
            allyCode,
            includeBaseStats: false
        });

        return resp.data;
    }

    public static async doGuildDownload(user: UserData, includeRoster: boolean, allyCode?: number): Promise<void>
    {
        let url = "profile/guild/download/" + user.sessionId + "/" + (includeRoster ? "true" : "false") + (allyCode !== undefined ? "/" + allyCode : "");
        let filename = "";
        if (allyCode !== undefined)
            filename = allyCode.toString();
        else if (user.currentPlayer)
            filename = user.currentPlayer.guildName;
        else
            filename = "guild";
        if (includeRoster)
            filename += "Full";
        filename += ".csv";
        await this.downloadFromApi(url, filename);
    }

    public static async generateImage(user: UserData, allyCodes: number[], unitBaseIds: string[], imageOverrides: ImageOverride[]): Promise<string>
    {
        let imageResponse = await this.postToApi("toon/get/url", {
            sessionId: user.sessionId,
            allyCode: allyCodes,
            unitBaseId: unitBaseIds,
            override: imageOverrides,
            portraitOnly: false,
            applySort: false,
            refresh: false
        });
        return imageResponse.url;
    }

    public static async accountShareList(user: UserData): Promise<ShareAccount[]>
    {
        let response = await BaseAPI.postToApi("account/share/list", {
            sessionId: user.sessionId
        });
        return response.sharedTo.map((json: any) => new ShareAccount(json));
    }

    public static async accountShareSet(user: UserData, discord: string, level: number): Promise<void>
    {
        await BaseAPI.postToApi("account/share/upsert", {
            sessionId: user.sessionId,
            discord,
            level
        });
    }

    public static async unlinkSharedAccount(user: UserData, allyCode: string): Promise<void>
    {
        await BaseAPI.postToApi("auth/unlink", {
            sessionId: user.sessionId,
            allyCode: parseInt(allyCode)
        });
    }

    public static async guildWatchList(user: UserData): Promise<GuildWatch>
    {
        let response = await BaseAPI.postToApi("guild/watch/list", {
            sessionId: user.sessionId
        });
        return new GuildWatch(response);
    }

    public static async guildWatchState(user: UserData, enabled: boolean): Promise<void>
    {
        await BaseAPI.postToApi("guild/watch/state", {
            sessionId: user.sessionId,
            enabled
        });
    }

    public static async guildWatchAdd(user: UserData, allyCode: string): Promise<void>
    {
        await BaseAPI.postToApi("guild/watch/add", {
            sessionId: user.sessionId,
            allyCode
        });
    }

    public static async guildWatchDelete(user: UserData, guildId: string): Promise<void>
    {
        await BaseAPI.postToApi("guild/watch/delete", {
            sessionId: user.sessionId,
            guildId
        });
    }


    public static async setAdminStatus(user: UserData, allyCode: number, guildAdminType: number): Promise<any>
    {
        let response = await BaseAPI.postToApi("guild/admin/update", {
            sessionId: user.sessionId,
            allyCode: allyCode,
            guildAdminType: guildAdminType
        });
        return response;
    }

    public static async getGamedata(user: UserData, type: string): Promise<any>
    {
        let response = await BaseAPI.postToApi("gamedata/get", {
            sessionId: user.sessionId,
            type: type
        });
        return response;
    }

    public static async getInventoryForAllyCode(user: UserData, allyCode: string): Promise<any>
    {
        let response = await BaseAPI.getFromApi("account/data/inventory/" + allyCode);
        return response;
    }

    public static async getInGameSquads(user: UserData, refresh: boolean, detailedUnits: boolean): Promise<IInGameSquadTab[]>
    {
        let response = await BaseAPI.postToApi("squads/game/get", {
            sessionId: user.sessionId,
            refresh,
            detailedUnits
        });
        return response.tabs;
    }

    public static async setInGameSquads(user: UserData, tabs: ISetInGameSquadTab[]): Promise<IInGameSquadTab[]>
    {
        let response = await BaseAPI.postToApi("squads/game/set", {
            sessionId: user.sessionId,
            tabs
        });
        return response.tabs;
    }

    public static async getTWScoreboardData(user: UserData): Promise<any>
    {
        return await this.postToApi("tw/scoreboard", {
            sessionId: user.sessionId
        });
    }

    public static async getTWGuildMatchHistoryData(user: UserData, guildId: string): Promise<any>
    {
        return await this.postToApi("tw/matchhistory", {
            sessionId: user.sessionId,
            guildId: guildId
        });
    }

    public static async getUnitAbbreviations(user: UserData): Promise<IUnitAbbreviation[]>
    {
        let response = await BaseAPI.postToApi("gamedata/abbrev/list", {
            sessionId: user.sessionId
        });
        return response.records;
    }

    public static async upsertUnitAbbreviations(user: UserData, abbrevs: IUnitAbbreviationRecord[]): Promise<IUnitAbbreviation[]>
    {
        let response = await BaseAPI.postToApi("gamedata/abbrev/upsert", {
            sessionId: user.sessionId,
            records: abbrevs
        });
        return response.records;
    }

    public static async uploadFile(user: UserData, fileName: string, fileContent: string, fileExtension?: string): Promise<string>
    {
        let response = await BaseAPI.postToApi("file/upload", {
            sessionId: user.sessionId,
            fileContent,
            fileName,
            fileExtension: fileExtension ? fileExtension : null
        });
        return response.fileUrl;
    }

    public static async getCompareQueueStats(): Promise<IQueueStats>
    {
        return await BaseAPI.postToApi("hotbot/stats", {
            sinceUtc: moment.utc().add(-24, "months").toJSON()
        })
    }

    public static async upgradeDatacron(user: UserData, datacronId: string, targetTier: number): Promise<any>
    {
        return await this.postToApi("datacron/upgrade", {
            sessionId: user.sessionId,
            id: datacronId,
            targetTier
        });
    }

    public static async rerollDatacron(user: UserData, datacronId: string, tier: number): Promise<any>
    {
        return await this.postToApi("datacron/reroll", {
            sessionId: user.sessionId,
            id: datacronId,
            tier
        });
    }

    public static async chooseDatacronReroll(user: UserData, datacronId: string, index: number): Promise<any>
    {
        return await this.postToApi("datacron/choose", {
            sessionId: user.sessionId,
            id: datacronId,
            index
        });
    }

    public static async dismantleDatacrons(user: UserData, datacronIds: string[]): Promise<any>
    {
        return await this.postToApi("datacron/delete", {
            sessionId: user.sessionId,
            ids: datacronIds
        });
    }

    public static async getDMQueueLength(): Promise<number>
    {
        let result = await this.getFromApi("discord/queuesize");
        return result.size;
    }
}

export default BaseAPI;
