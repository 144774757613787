

export class CurrencyQuantity
{
    currencyType: Currency;
    quantity: number;

    constructor(json: any)
    {
        this.currencyType = json.currency;
        this.quantity = json.quantity;

    }
}

enum Currency
{
    Default = 0,
    Grind = 1,
    Premium = 2,
    Realmoney = 3,
    Social = 4,
    Igceventkeycard = 8,
    Xpeventkeycard = 9,
    Pvpcurrency = 10,
    Forcepoint = 11,
    Pvpshipcurrency = 12,
    Free = 13,
    Warshipcurrency = 14,
    Grindticket = 15,
    Shardcurrency = 16,
    Guildcurrency = 17,
    Guildbankcurrency = 18,
    Shipgrind = 19,
    Guildraidcurrency01 = 20,
    Guildraidcurrency02 = 21,
    Guildraidcurrency03 = 22,
    Guildraidcurrency04 = 23,
    Guildraidcurrency05 = 24,
    Guildraidcurrency06 = 25,
    Guildraidcurrency07 = 26,
    Guildraidcurrency08 = 27,
    Guildraidcurrency09 = 28,
    Guildraidcurrency10 = 29,
    Guildraidcurrency11 = 30,
    Guildraidcurrency12 = 31,
    Territorybattlecurrency = 32,
    Seasonscurrency = 33,
    Territorybattlecurrency02 = 34,
}

export default Currency;
