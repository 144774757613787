enum ModSets
{
    Health = 1,
    Offense = 2,
    Defense = 3,
    Speed = 4,
    CriticalChance = 5,
    CriticalDamage = 6,
    Potency = 7,
    Tenacity = 8
}

export function modSetToString(modSet: ModSets): string 
{
    switch (modSet)
    {
        case ModSets.Health:
            return "Health";
        case ModSets.Offense:
            return "Offense";
        case ModSets.Defense:
            return "Defense";
        case ModSets.Speed:
            return "Speed";
        case ModSets.CriticalChance:
            return "CriticalChance";
        case ModSets.CriticalDamage:
            return "CriticalDamage";
        case ModSets.Potency:
            return "Potency";
        case ModSets.Tenacity:
            return "Tenacity";
    }
}

export function getSetBonusCount(modSet: ModSets): number
{
    switch (modSet)
    {
        case ModSets.Health:
            return 2;
        case ModSets.Offense:
            return 4;
        case ModSets.Defense:
            return 2;
        case ModSets.Speed:
            return 4;
        case ModSets.CriticalChance:
            return 2;
        case ModSets.CriticalDamage:
            return 4;
        case ModSets.Potency:
            return 2;
        case ModSets.Tenacity:
            return 2;
    }
}

export default ModSets;
