import { observable } from "mobx";
import ModData from "./ModData";
import { UnitStats } from "../utils/mod-calculator";
import { IBasicUnitData } from "./UnitData";
import { IPlayerRoster } from "../pages/Guild/Farming/farmingCosts";

class PlayerCharacterData implements IBasicUnitData, IPlayerRoster
{
    @observable id: string = "";
    @observable baseId: string = "";
    @observable combatType: number = 1; // 1 is char, 2 is ship
    @observable level: number = 1;
    @observable stars: number = 1;
    @observable rarity: number = 1; // same as stars, but here to match TW units
    @observable omiCount: number = 0;
    @observable zetaCount: number = 0;
    @observable zetaLead: boolean = false;
    @observable ultimate: boolean = false;
    @observable alignment: number = 2; // 2 Dark side, 3 Light
    @observable gearLevel: number = 1;
    @observable relicLevel: number = 0;
    @observable equippedGearSlots: number[];
    @observable modCount: number;
    @observable modSpeed10: number;
    @observable modSpeed15: number;
    @observable modSpeed20: number;
    @observable modSpeed25: number;
    @observable modOff100: number;
    @observable modOff150: number;
    @observable modSixCount: number;
    @observable modPlusSpeed: number;
    @observable mods: ModData[] = [];
    @observable skills: {
        id: string;
        tier: number;
        tiers: number;
    }[] = [];

    @observable powerMax: number;
    @observable powerTotal: number;
    @observable powerLevel: number;
    @observable powerStars: number;
    @observable powerAbilityLevels: number;
    @observable powerGearPieces: number;
    @observable powerMods: number;
    @observable powerRelicModifier: number;
    @observable powerRelicTier: number;
    @observable powerCrew: number;
    @observable powerReinforcement: number;
    @observable powerPctComplete: number;

    @observable statsHealth: number;
    @observable statsProtection: number;
    @observable statsSpeed: number;
    @observable statsCritDamage: number;
    @observable statsPotency: number;
    @observable statsTenacity: number;
    @observable statsHealthSteal: number;
    @observable statsDefensePen: number;
    @observable statsDamage: number;
    @observable statsCritChance: number;
    @observable statsArmorPen: number;
    @observable statsAccuracy: number;
    @observable statsArmor: number;
    @observable statsDodgeChance: number;
    @observable statsCritAvoidance: number;
    @observable statsSpecialDamage: number;
    @observable statsSpecialCritChance: number;
    @observable statsResistancePen: number;
    @observable statsSpecialAccuracy: number;
    @observable statsResistance: number;
    @observable statsDeflectionChance: number;
    @observable statsSpecialCritAvoidance: number;

    @observable baseStats: UnitStats = new UnitStats();

    @observable gearEffects: {
        stat: number,
        amount: number
    }[] = [];

    @observable image: string;
    @observable thumbnailName: string;

    @observable gear:  {
        count: number;
        level: number;
        slot0: number;
        slot1: number;
        slot2: number;
        slot3: number;
        slot4: number;
        slot5: number;
    };

    constructor(json: any)
    {
        this.id = json.unitId;
        this.baseId = json.baseId;
        this.combatType = json.combatType;
        this.level = json.level;
        this.stars = json.stars;
        this.rarity = this.stars;
        this.omiCount = json.omiCount;
        this.zetaCount = json.zetaCount;
        this.zetaLead = json.zetaLead;
        this.ultimate = json.ultimate;
        this.alignment = json.alignment;
        this.gearLevel = json.gear.level;
        this.relicLevel = json.relicLevel;
        this.gear = json.gear;
        this.equippedGearSlots = [];
        if (json.gear.slot0)
            this.equippedGearSlots.push(0);
        if (json.gear.slot1)
            this.equippedGearSlots.push(1);
        if (json.gear.slot2)
            this.equippedGearSlots.push(2);
        if (json.gear.slot3)
            this.equippedGearSlots.push(3);
        if (json.gear.slot4)
            this.equippedGearSlots.push(4);
        if (json.gear.slot5)
            this.equippedGearSlots.push(5);
        this.modCount = json.mods.count;
        this.modSpeed10 = json.mods.speed10;
        this.modSpeed15 = json.mods.speed15;
        this.modSpeed20 = json.mods.speed20;
        this.modSpeed25 = json.mods.speed25;
        this.modOff100 = json.mods.offense100;
        this.modOff150 = json.mods.offense150;
        this.modSixCount = json.mods.sixCount;
        this.modPlusSpeed = json.mods.plusSpeed;
        this.powerMax = json.power.max;
        this.powerTotal = json.power.total;
        this.powerLevel = json.power.level;
        this.powerStars = json.power.stars;
        this.powerAbilityLevels = json.power.abilityLevels;
        this.powerGearPieces = json.power.gearPieces;
        this.powerMods = json.power.mods;
        this.powerRelicModifier = json.power.relicModifier;
        this.powerRelicTier = json.power.relicTier;
        this.powerCrew = json.power.crew;
        this.powerReinforcement = json.power.reinforcement;
        this.powerPctComplete = json.power.percentComplete;
        this.statsHealth = json.stats.health;
        this.statsProtection = json.stats.protection;
        this.statsSpeed = json.stats.speed;
        this.statsCritDamage = json.stats.critDamage;
        this.statsPotency = json.stats.potency;
        this.statsTenacity = json.stats.tenacity;
        this.statsHealthSteal = json.stats.healthSteal;
        this.statsDefensePen = json.stats.defensePen;
        this.statsDamage = json.stats.damage;
        this.statsCritChance = json.stats.critChance;
        this.statsArmorPen = json.stats.armorPen;
        this.statsAccuracy = json.stats.accuracy;
        this.statsArmor = json.stats.armor;
        this.statsDodgeChance = json.stats.dodgeChance;
        this.statsCritAvoidance = json.stats.critAvoidance;
        this.statsSpecialDamage = json.stats.specialDamage;
        this.statsSpecialCritChance = json.stats.specialCritChance;
        this.statsResistancePen = json.stats.resistancePen;
        this.statsSpecialAccuracy = json.stats.specialAccuracy;
        this.statsResistance = json.stats.resistance;
        this.statsDeflectionChance = json.stats.deflectionChance;
        this.statsSpecialCritAvoidance = json.stats.specialCritAvoidance;
        this.image = json.image;
        this.thumbnailName = json.thumbnail;
        this.baseStats.rawStats = json.baseStats || [];
        this.gearEffects = json.gearEffects;
        this.skills = json.skills;
    }
}

export default PlayerCharacterData;