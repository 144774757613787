
import { observable } from "mobx";
import ConfigurableSetting from './ConfigurableSetting';
import BaseAPI from './../service/BaseAPI';
import UserData from './UserData';
import TerritoryBattleData, { TBMissionTracker } from './TerritoryBattleData';

export class TerritoryBattlePlayerStatusSetting
{
    @observable allyCode: number;
    @observable deployedShipGp?: boolean | undefined = undefined
    @observable deployedCombatGp?: boolean | undefined = undefined;
    @observable completedShipMissions?: boolean | undefined = undefined;
    @observable completedCharacterMissions?: boolean | undefined = undefined;
    @observable completedSpecialMissions?: boolean | undefined = undefined;

    constructor(allyCode: number)
    {
        this.allyCode = allyCode;
    }
}

export class SandBagZone
{
    @observable zoneId: string;
    @observable platoonsCompleted: number = 0;
    @observable sandBagged: boolean = false;

    constructor(zoneId: string, sandBagged: boolean = false)
    {
        this.zoneId = zoneId;
        this.sandBagged = sandBagged;
    }

}


class TerritoryBattleStatusPreferences
{
    public static SETTING_KEY = "tb_status";

    @observable instanceId: string;
    @observable currentRound: number;

    @observable players: TerritoryBattlePlayerStatusSetting[] = [];

    @observable sandBagZones: SandBagZone[] | undefined = [];

    @observable noSandbagOverride: boolean | undefined = false;

    constructor(instanceId: string, currentRound: number)
    {
        this.instanceId = instanceId;
        this.currentRound = currentRound;
    }

    public static async fetchStatusPreferences(user: UserData, instanceId: string, currentRound: number): Promise<TerritoryBattleStatusPreferences>
    {
        let tbsp: TerritoryBattleStatusPreferences | null = null

        let settings: ConfigurableSetting[] = await BaseAPI.getGuildSetting(user,
            TerritoryBattleStatusPreferences.SETTING_KEY + "_" + instanceId + "_" + currentRound);
        if (settings.length > 0)
        {
            tbsp = JSON.parse(settings[0].value);
        }
        if (tbsp === null)
        {
            tbsp = new TerritoryBattleStatusPreferences(instanceId, currentRound);
        }
        return tbsp;
    }
}


export class TBHistorySelection
{
    tbData: TerritoryBattleData;
    tbsp: TerritoryBattleStatusPreferences;
    unitMissionTrackingData: TBMissionTracker[];
    fleetMissionTrackingData: TBMissionTracker[];

    constructor(tbData: TerritoryBattleData,
        tbsp: TerritoryBattleStatusPreferences,
        unitMissionTrackingData: TBMissionTracker[], fleetMissionTrackingData: TBMissionTracker[])
    {
        this.tbData = tbData;
        this.tbsp = tbsp;
        this.unitMissionTrackingData = unitMissionTrackingData;
        this.fleetMissionTrackingData = fleetMissionTrackingData;
    }
}


export default TerritoryBattleStatusPreferences;



