import { observable, toJS, action } from "mobx";
import uuidv4 from "../utils/uuid";
import ModSingleFilterData from "./ModSingleFilterData";

export type ModFilterGroupType = "Desired" | "Garbage" | "To Slice" | "Incomplete" | "To Calibrate";

class ModFilterGroup
{
    id: string = "";
    @observable name: string = "";
    @observable type: ModFilterGroupType = "Desired";
    @observable filters: ModSingleFilterData[] = [];
    @observable isPublic: boolean = false;
    @observable author: string = "";
    @observable description: string = "";
    @observable likes: number = 0;
    @observable liked: boolean = false;
    @observable copiedFromPublicId: string = "";

    public get typeAbbrev(): string
    {
        if (this.type === "Desired")
            return "D";
        else if (this.type === "Garbage")
            return "G";
        else if (this.type === "Incomplete")
            return "I";
        else if (this.type === "To Calibrate")
            return "C";
        else
            return "S";
    }

    @action
    public fromJSON(json: any): void
    {
        let data = JSON.parse(json.data);

        if (!this.id)
            this.id = data.id;

        this.name = data.name;
        this.type = data.type;
        this.isPublic = data.isPublic;
        this.author = data.author;
        this.description = data.description;
        this.likes = json.likes;
        this.liked = json.liked;
        if (data.copiedFromPublicId)
            this.copiedFromPublicId = data.copiedFromPublicId;
        
        if (data.filters)
        {
            this.filters = data.filters.map((f: any) =>
            {
                let ret = new ModSingleFilterData();
                ret.fromJSON(f);
                return ret;
            });
        }
    }

    public toJSON()
    {
        return toJS(this);
    }

    public constructor(json?: any)
    {
        if (json)
        {
            this.fromJSON(json);
        }
    }

    @action
    public clonePublicGroup(): ModFilterGroup
    {
        let ret = new ModFilterGroup({likes: 0, liked: false, data: JSON.stringify(this.toJSON())});
        ret.isPublic = false;
        ret.copiedFromPublicId = this.id;
        ret.id = uuidv4();
        return ret;
    }
}

export default ModFilterGroup;
