import { IGuildPlayer } from "./GuildData";
import {IShare, ShareType} from "./IShare";
import {IPlayerSquadUnits} from "./Squads";

export interface ITerritoryWarsPlanning {
    id: string;
    data: ITerritoryWarsData;
    categories: typeof ITerritoryWarsArray;
    updatedUtc: string;
    shareType: ShareType;
    sharing: IShare;
}

export interface ITerritoryWarsData {
    name: string;
    isCompactMode: boolean;
    creator: IUserDetails;
    updator: IUserDetails;
    allianceShared: boolean;
    squadsPerZone: number;
    excludedPlayers: number[];
    preferredPlayers: number[];
    zones: zonesTW[];
    squads: squadsTW[];
    description: string;
}

export const ITerritoryWarsArray = ['Defence', 'Offence'];
export type combatType = "global" | "guild"
export enum ICombatType {
    Character = "Character",
    Ship = "Ship"
}

export interface IDuplicateUnits {
    allyCode: number;
    units: string[];
}

export interface zonesTW {
    zoneId?: string;
    index: number;
    id: number;
    name: string;
    combatType: string;
}

export interface squadsTW {
    zoneId: number;
    squadTypeId: number;
    squadId: string;
    discordTag?: string;
    allyCode: number;
    playerName: string;
    units: IPlayerSquadUnits[];
    datacronAffixes?: string[];
    datacronRequired?: boolean;
}

export interface IUserDetails {
    playerName: string;
    allyCode: number;
    discordTag: string;
    discordId?: number;
    guildRank?: number;
    subscriptionLevelName?: string;
    squadId?: string[];
    createdUTC: any;
}

export interface ITWZonesInGame {
    zoneId: string;
    title: string;
    index: number;
    combatType: number;
    squadCapacity: number;
    squadCount: number;
    squads: ITWFilledSquadsInGame[];
}

export interface ITWFilledSquadsInGame {
    squadId: number;
    playerName: string;
    playerAllyCode?: number;
    units: ITWUnitsInGame[];
}

export interface ITWUnitsInGame {
    id: number;
    baseId: string;
    name: string;
    omiCount: number;
    relicLevel: number;
    gearLevel: number;
    ultimate: boolean;
    zetaCount: number;
    zetaLead: boolean;
}

function findDuplicates(arr: string[]) {
    return arr.filter((item, index) => arr.indexOf(item) !== index);
}

export function getDuplicateUnits(template: ITerritoryWarsData): IDuplicateUnits[]
{
    const squadsPlayers = Array.from(new Set(template.squads.map(x => x.allyCode)));
    let duplicateUnits: IDuplicateUnits[] = [];

    for (let i = 0; i < squadsPlayers.length; i++) {
        let squadsPlayer: string[] = [];
        const player = squadsPlayers[i];

        for (let j = 0; j < template.squads.length; j++) {
            const squads = template.squads[j];

            if(squads.allyCode === player){
                const units = squads.units.map(unit => unit.preferredUnit ? unit.preferredUnit : unit.characterId);
                squadsPlayer = units.concat(squadsPlayer);
            }
        }
        let dupes = findDuplicates(squadsPlayer);
        if (dupes.length > 0)
        {
            duplicateUnits.push({
                allyCode: player,
                units: dupes
            });
        }
    }
    return duplicateUnits;
}

export function getExiledPlayers(template: ITerritoryWarsData, players: IGuildPlayer[]): number[]
{
    let templateAllyCodes = new Set(template.squads.map(x => x.allyCode));
    for (let p of players)
    {
        templateAllyCodes.delete(p.allyCode);
    }
    return Array.from(templateAllyCodes);
}

export function tooManySquadsPerZone(template: ITerritoryWarsData): boolean
{
    for (let i = 0; i < template.zones.length; i++) {
        const zone = template.zones[i];
        const squadsZone = template.squads.filter(x => x.zoneId === zone.id);
        if(squadsZone.length > template.squadsPerZone){
            return true;
        }
    }
    return false;
}