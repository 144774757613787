export function readableTime(ms: number, truncate = false, showSeconds = false, padStart = false) {
    const time = new Date(ms),
        days = Math.floor(ms / 86400000),
        hours = time.getUTCHours(),
        minutes = time.getUTCMinutes(),
        seconds = time.getUTCSeconds();

    return `${days ? `${String(days).padStart(padStart ? 2 : 1, '0')}${truncate ? 'd' : ' day'}${days > 1 && !truncate ? 's' : ''} ` : ''}${
        hours ? `${String(hours).padStart(padStart ? 2 : 1, '0')}${truncate ? 'h' : ' hour'}${hours > 1 && !truncate ? 's' : ''} ` : ''
    }${
        minutes ? `${String(minutes).padStart(padStart ? 2 : 1, '0')}${truncate ? 'm' : ' minute'}${minutes > 1 && !truncate ? 's' : ''} ` : ''
    }${
        (showSeconds || ms < 60000) && seconds
            ? `${String(seconds).padStart(padStart ? 2 : 1, '0')}${truncate ? 's' : ' second'}${seconds > 1 && !truncate ? 's' : ''} `
            : ''
    }${ms < 1000 ? `${String(ms)}${truncate ? 'ms' : ' millisecond'}${ms > 1 && !truncate ? 's' : ''} ` : ''}`.trim();
}

export function readableNumber(number: number, symbol = ',') {
    return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${symbol}`);
}

