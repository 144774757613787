export const optimizationData: any = `
{
   "characterResults":[
      {
         "name":"ZEBS3",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.08108108,
               "49":0.027027028,
               "52":0.027027028,
               "5":1.0,
               "54":0.08108108,
               "55":0.054054055,
               "56":0.08108108
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.2777778,
               "49":0.3888889,
               "53":0.11111111,
               "55":0.2777778,
               "56":0.7222222
            },
            "5":{
               "55":0.47058824,
               "56":1.0
            },
            "6":{
               "48":0.5,
               "17":0.5714286,
               "49":0.5,
               "18":0.42857143,
               "55":0.5714286,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":3,
               "49":1,
               "52":1,
               "5":37,
               "54":3,
               "55":2,
               "56":3
            },
            "3":{
               "49":50
            },
            "4":{
               "16":18,
               "48":5,
               "49":7,
               "53":2,
               "55":5,
               "56":13
            },
            "5":{
               "55":16,
               "56":34
            },
            "6":{
               "48":7,
               "17":8,
               "49":7,
               "18":6,
               "55":8,
               "56":14
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.78571427,
         "setCounts":{
            "1":34,
            "2":2,
            "3":4,
            "4":13,
            "5":9,
            "6":13,
            "7":17,
            "8":5
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.15384616,
            "3":0.11764706,
            "4":1.0,
            "5":0.2647059,
            "6":1.0,
            "7":0.5,
            "8":0.14705883
         },
         "twoSetMax":73.666664,
         "twoSetCounts":{
            "1":34,
            "3":4,
            "5":9,
            "7":17,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":34.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":22.666666
            },
            {
               "set":7,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":15.111111
            },
            {
               "set":7,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":5,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":5,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "49":5,
            "17":1,
            "1":8,
            "18":5,
            "5":11,
            "53":9,
            "55":4,
            "56":4,
            "41":4,
            "42":2,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.36363637,
            "49":0.45454547,
            "17":0.09090909,
            "1":0.72727275,
            "18":0.45454547,
            "5":1.0,
            "53":0.8181818,
            "55":0.36363637,
            "56":0.36363637,
            "41":0.36363637,
            "42":0.18181819,
            "28":0.27272728
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":9,
            "Health":12,
            "Potency":1,
            "Offense":8,
            "Tenacity":5,
            "Protection":7,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":0.9166667,
            "Critical Chance":0.75,
            "Health":1.0,
            "Potency":0.083333336,
            "Offense":0.6666667,
            "Tenacity":0.41666666,
            "Protection":0.5833333,
            "Defense":0.5833333
         }
      },
      {
         "name":"CT210408",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":1.0,
               "5":0.9166667,
               "54":0.16666667
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.5483871,
               "53":0.032258064
            },
            "5":{
               "55":0.88461536,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.53125,
               "56":0.03125
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":24,
               "5":22,
               "54":4
            },
            "3":{
               "49":50
            },
            "4":{
               "16":31,
               "48":17,
               "53":1
            },
            "5":{
               "55":23,
               "56":26
            },
            "6":{
               "48":32,
               "17":17,
               "56":1
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.06382979,
         "setCounts":{
            "1":6,
            "2":18,
            "3":0,
            "5":23,
            "6":29,
            "7":22,
            "8":0
         },
         "fourSetMultipliers":{
            "1":0.26086956,
            "2":0.62068963,
            "3":0.0,
            "5":1.0,
            "6":1.0,
            "7":0.95652175,
            "8":0.0
         },
         "twoSetMax":60.333332,
         "twoSetCounts":{
            "1":6,
            "3":0,
            "5":23,
            "7":22,
            "8":0
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":23.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":22.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":15.333333
            },
            {
               "set":7,
               "occurrence":2,
               "count":14.666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":10.222222
            },
            {
               "set":7,
               "occurrence":3,
               "count":9.777778
            },
            {
               "set":1,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":3,
            "17":6,
            "49":2,
            "18":3,
            "5":14,
            "53":9,
            "55":3,
            "56":5,
            "41":7,
            "42":3,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.14285715,
            "1":0.21428572,
            "17":0.42857143,
            "49":0.14285715,
            "18":0.21428572,
            "5":1.0,
            "53":0.64285713,
            "55":0.21428572,
            "56":0.35714287,
            "41":0.5,
            "42":0.21428572,
            "28":0.21428572
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":9,
            "Health":6,
            "Potency":6,
            "Offense":9,
            "Tenacity":3,
            "Protection":8,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.64285713,
            "Health":0.42857143,
            "Potency":0.42857143,
            "Offense":0.64285713,
            "Tenacity":0.21428572,
            "Protection":0.5714286,
            "Defense":0.35714287
         }
      },
      {
         "name":"GENERALKENOBI",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.02173913,
               "55":0.06521739
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.022222223,
               "16":0.022222223,
               "49":0.022222223,
               "55":1.0,
               "56":0.044444446
            },
            "5":{
               "55":1.0,
               "56":0.11111111
            },
            "6":{
               "49":0.023809524,
               "18":0.023809524,
               "55":1.0,
               "56":0.14285715
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":46,
               "54":1,
               "55":3
            },
            "3":{
               "49":50
            },
            "4":{
               "48":1,
               "16":1,
               "49":1,
               "55":45,
               "56":2
            },
            "5":{
               "55":45,
               "56":5
            },
            "6":{
               "49":1,
               "18":1,
               "55":42,
               "56":6
            }
         },
         "fourSetsMultiplier":0.11111111,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":125,
            "3":13,
            "4":5,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.104,
            "4":1.0,
            "8":0.016
         },
         "twoSetMax":263.88892,
         "twoSetCounts":{
            "1":125,
            "3":13,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":125.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":83.333336
            },
            {
               "set":1,
               "occurrence":3,
               "count":55.555557
            },
            {
               "set":3,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":5,
            "1":6,
            "49":4,
            "17":3,
            "18":1,
            "5":15,
            "53":2,
            "55":2,
            "56":3,
            "41":5,
            "42":2,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.33333334,
            "1":0.4,
            "49":0.26666668,
            "17":0.2,
            "18":0.06666667,
            "5":1.0,
            "53":0.13333334,
            "55":0.13333334,
            "56":0.2,
            "41":0.33333334,
            "42":0.13333334,
            "28":0.33333334
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":2,
            "Health":8,
            "Potency":3,
            "Offense":10,
            "Tenacity":1,
            "Protection":8,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.13333334,
            "Health":0.53333336,
            "Potency":0.2,
            "Offense":0.6666667,
            "Tenacity":0.06666667,
            "Protection":0.53333336,
            "Defense":0.4
         }
      },
      {
         "name":"DROIDEKA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":1.0,
               "5":0.27027026,
               "56":0.08108108
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":1.0,
               "16":0.11627907,
               "53":0.023255814
            },
            "5":{
               "55":0.47058824,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.04255319,
               "56":0.021276595
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":37,
               "5":10,
               "56":3
            },
            "3":{
               "49":50
            },
            "4":{
               "48":43,
               "16":5,
               "53":1
            },
            "5":{
               "55":16,
               "56":34
            },
            "6":{
               "48":47,
               "17":2,
               "56":1
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.041666668,
         "setCounts":{
            "1":21,
            "2":48,
            "3":3,
            "4":0,
            "5":12,
            "6":0,
            "7":3,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0,
            "3":0.14285715,
            "4":0.0,
            "5":0.5714286,
            "6":0.0,
            "7":0.14285715,
            "8":0.0952381
         },
         "twoSetMax":47.0,
         "twoSetCounts":{
            "1":21,
            "3":3,
            "5":12,
            "7":3,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":5,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "17":1,
            "49":1,
            "1":9,
            "18":3,
            "5":3,
            "53":2,
            "55":1,
            "56":4,
            "41":21,
            "42":1,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.0952381,
            "17":0.04761905,
            "49":0.04761905,
            "1":0.42857143,
            "18":0.14285715,
            "5":0.14285715,
            "53":0.0952381,
            "55":0.04761905,
            "56":0.1904762,
            "41":1.0,
            "42":0.04761905,
            "28":0.04761905
         },
         "secondaryTypeCounts":{
            "Speed":3,
            "Critical Chance":2,
            "Health":10,
            "Potency":1,
            "Offense":23,
            "Tenacity":3,
            "Protection":5,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":0.13043478,
            "Critical Chance":0.08695652,
            "Health":0.4347826,
            "Potency":0.04347826,
            "Offense":1.0,
            "Tenacity":0.13043478,
            "Protection":0.2173913,
            "Defense":0.08695652
         }
      },
      {
         "name":"K2SO",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.045454547,
               "49":0.22727273,
               "5":1.0,
               "54":0.36363637,
               "55":0.045454547,
               "56":0.54545456
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.17391305,
               "16":0.17391305,
               "49":0.3478261,
               "53":0.04347826,
               "55":0.39130434,
               "56":1.0
            },
            "5":{
               "55":0.25,
               "56":1.0
            },
            "6":{
               "48":0.11764706,
               "17":0.29411766,
               "49":0.4117647,
               "18":0.9411765,
               "55":0.1764706,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "49":5,
               "5":22,
               "54":8,
               "55":1,
               "56":12
            },
            "3":{
               "49":50
            },
            "4":{
               "48":4,
               "16":4,
               "49":8,
               "53":1,
               "55":9,
               "56":23
            },
            "5":{
               "55":10,
               "56":40
            },
            "6":{
               "48":2,
               "17":5,
               "49":7,
               "18":16,
               "55":3,
               "56":17
            }
         },
         "fourSetsMultiplier":0.041666668,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":61,
            "2":0,
            "3":23,
            "4":1,
            "5":0,
            "6":1,
            "7":7,
            "8":37
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.37704918,
            "4":1.0,
            "5":0.0,
            "6":1.0,
            "7":0.114754096,
            "8":0.60655737
         },
         "twoSetMax":138.66667,
         "twoSetCounts":{
            "1":61,
            "3":23,
            "5":0,
            "7":7,
            "8":37
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":61.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":40.666668
            },
            {
               "set":8,
               "occurrence":1,
               "count":37.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":27.111113
            },
            {
               "set":8,
               "occurrence":2,
               "count":24.666666
            },
            {
               "set":3,
               "occurrence":1,
               "count":23.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":16.444445
            },
            {
               "set":3,
               "occurrence":2,
               "count":15.333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":10.222222
            },
            {
               "set":7,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"tenacity",
         "secondaryCounts":{
            "48":2,
            "49":3,
            "17":2,
            "1":3,
            "18":9,
            "5":7,
            "53":1,
            "55":1,
            "56":1,
            "41":7,
            "42":9,
            "28":12
         },
         "secondaryMultipliers":{
            "48":0.16666667,
            "49":0.25,
            "17":0.16666667,
            "1":0.25,
            "18":0.75,
            "5":0.5833333,
            "53":0.083333336,
            "55":0.083333336,
            "56":0.083333336,
            "41":0.5833333,
            "42":0.75,
            "28":1.0
         },
         "secondaryTypeCounts":{
            "Speed":7,
            "Critical Chance":1,
            "Health":4,
            "Potency":2,
            "Offense":9,
            "Tenacity":9,
            "Protection":13,
            "Defense":12
         },
         "secondaryTypeMultipliers":{
            "Speed":0.53846157,
            "Critical Chance":0.07692308,
            "Health":0.30769232,
            "Potency":0.15384616,
            "Offense":0.6923077,
            "Tenacity":0.6923077,
            "Protection":1.0,
            "Defense":0.9230769
         }
      },
      {
         "name":"C3POCHEWBACCA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.13636364,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":1.0,
               "16":0.6666667
            },
            "5":{
               "55":0.9230769,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.14285715,
               "18":0.04761905
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":6,
               "5":44
            },
            "3":{
               "49":50
            },
            "4":{
               "48":30,
               "16":20
            },
            "5":{
               "55":24,
               "56":26
            },
            "6":{
               "48":42,
               "17":6,
               "18":2
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.020408163,
         "setCounts":{
            "1":2,
            "2":38,
            "4":1,
            "5":15,
            "6":10,
            "7":25,
            "8":7
         },
         "fourSetMultipliers":{
            "1":0.08,
            "2":1.0,
            "4":0.02631579,
            "5":0.6,
            "6":0.2631579,
            "7":1.0,
            "8":0.28
         },
         "twoSetMax":56.666664,
         "twoSetCounts":{
            "1":2,
            "5":15,
            "7":25,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":25.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":16.666666
            },
            {
               "set":5,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":11.111111
            },
            {
               "set":5,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":1,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "17":4,
            "49":6,
            "1":1,
            "18":6,
            "5":16,
            "53":2,
            "55":3,
            "56":1,
            "41":5,
            "42":3,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.25,
            "17":0.25,
            "49":0.375,
            "1":0.0625,
            "18":0.375,
            "5":1.0,
            "53":0.125,
            "55":0.1875,
            "56":0.0625,
            "41":0.3125,
            "42":0.1875,
            "28":0.1875
         },
         "secondaryTypeCounts":{
            "Speed":16,
            "Critical Chance":2,
            "Health":4,
            "Potency":4,
            "Offense":9,
            "Tenacity":6,
            "Protection":4,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.125,
            "Health":0.25,
            "Potency":0.25,
            "Offense":0.5625,
            "Tenacity":0.375,
            "Protection":0.25,
            "Defense":0.5625
         }
      },
      {
         "name":"QIRA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "55":0.020833334,
               "56":0.020833334
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.31578946,
               "16":0.6315789,
               "49":0.21052632,
               "53":1.0,
               "55":0.15789473,
               "56":0.31578946
            },
            "5":{
               "55":0.42857143,
               "56":1.0
            },
            "6":{
               "48":0.6666667,
               "49":0.2,
               "17":0.73333335,
               "18":0.4,
               "55":0.26666668,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":48,
               "55":1,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "48":6,
               "16":12,
               "49":4,
               "53":19,
               "55":3,
               "56":6
            },
            "5":{
               "55":15,
               "56":35
            },
            "6":{
               "48":10,
               "49":3,
               "17":11,
               "18":6,
               "55":4,
               "56":15
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.11111111,
         "setCounts":{
            "1":11,
            "2":2,
            "3":2,
            "4":42,
            "5":25,
            "6":1,
            "7":5,
            "8":3
         },
         "fourSetMultipliers":{
            "1":0.44,
            "2":0.04761905,
            "3":0.08,
            "4":1.0,
            "5":1.0,
            "6":0.023809524,
            "7":0.2,
            "8":0.12
         },
         "twoSetMax":52.666664,
         "twoSetCounts":{
            "1":11,
            "3":2,
            "5":25,
            "7":5,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":25.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":16.666666
            },
            {
               "set":5,
               "occurrence":3,
               "count":11.111111
            },
            {
               "set":1,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "1":1,
            "49":3,
            "17":4,
            "18":4,
            "5":15,
            "53":4,
            "55":4,
            "56":3,
            "41":5,
            "42":4,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.06666667,
            "1":0.06666667,
            "49":0.2,
            "17":0.26666668,
            "18":0.26666668,
            "5":1.0,
            "53":0.26666668,
            "55":0.26666668,
            "56":0.2,
            "41":0.33333334,
            "42":0.26666668,
            "28":0.26666668
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":4,
            "Health":5,
            "Potency":4,
            "Offense":6,
            "Tenacity":4,
            "Protection":7,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.26666668,
            "Health":0.33333334,
            "Potency":0.26666668,
            "Offense":0.4,
            "Tenacity":0.26666668,
            "Protection":0.46666667,
            "Defense":0.46666667
         }
      },
      {
         "name":"GRANDADMIRALTHRAWN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.020408163
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.029411765,
               "16":0.05882353,
               "49":0.14705883,
               "53":0.11764706,
               "55":0.11764706,
               "56":1.0
            },
            "5":{
               "55":0.13636364,
               "56":1.0
            },
            "6":{
               "48":0.078947365,
               "49":0.05263158,
               "17":0.02631579,
               "18":0.078947365,
               "55":0.078947365,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":49,
               "54":1
            },
            "3":{
               "49":50
            },
            "4":{
               "48":1,
               "16":2,
               "49":5,
               "53":4,
               "55":4,
               "56":34
            },
            "5":{
               "55":6,
               "56":44
            },
            "6":{
               "48":3,
               "49":2,
               "17":1,
               "18":3,
               "55":3,
               "56":38
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":24,
            "2":0,
            "3":6,
            "4":50,
            "5":3,
            "6":0,
            "7":0,
            "8":4
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.25,
            "4":1.0,
            "5":0.125,
            "6":0.0,
            "7":0.0,
            "8":0.16666667
         },
         "twoSetMax":50.666668,
         "twoSetCounts":{
            "1":24,
            "3":6,
            "5":3,
            "7":0,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":24.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":16.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":10.666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "1":1,
            "49":1,
            "17":3,
            "18":4,
            "53":1,
            "5":20,
            "55":2,
            "56":6,
            "41":3,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.15,
            "1":0.05,
            "49":0.05,
            "17":0.15,
            "18":0.2,
            "53":0.05,
            "5":1.0,
            "55":0.1,
            "56":0.3,
            "41":0.15,
            "42":0.1,
            "28":0.1
         },
         "secondaryTypeCounts":{
            "Speed":20,
            "Critical Chance":1,
            "Health":3,
            "Potency":3,
            "Offense":6,
            "Tenacity":4,
            "Protection":8,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.05,
            "Health":0.15,
            "Potency":0.15,
            "Offense":0.3,
            "Tenacity":0.2,
            "Protection":0.4,
            "Defense":0.15
         }
      },
      {
         "name":"PAO",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.13953489,
               "49":0.023255814,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.4814815,
               "49":0.11111111,
               "53":0.037037037,
               "55":0.11111111,
               "56":0.11111111
            },
            "5":{
               "55":0.9230769,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.23076923,
               "49":0.03846154,
               "18":0.15384616,
               "55":0.07692308,
               "56":0.42307693
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":6,
               "49":1,
               "5":43
            },
            "3":{
               "49":50
            },
            "4":{
               "16":27,
               "48":13,
               "49":3,
               "53":1,
               "55":3,
               "56":3
            },
            "5":{
               "55":24,
               "56":26
            },
            "6":{
               "48":26,
               "17":6,
               "49":1,
               "18":4,
               "55":2,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.041666668,
         "setCounts":{
            "1":19,
            "2":33,
            "3":3,
            "4":8,
            "5":14,
            "6":7,
            "7":4,
            "8":7
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0,
            "3":0.15789473,
            "4":0.24242425,
            "5":0.7368421,
            "6":0.21212122,
            "7":0.21052632,
            "8":0.36842105
         },
         "twoSetMax":45.666668,
         "twoSetCounts":{
            "1":19,
            "3":3,
            "5":14,
            "7":4,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":5,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":7,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "1":3,
            "49":5,
            "17":3,
            "18":4,
            "5":11,
            "53":5,
            "55":1,
            "56":3,
            "41":10,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.27272728,
            "1":0.27272728,
            "49":0.45454547,
            "17":0.27272728,
            "18":0.36363637,
            "5":1.0,
            "53":0.45454547,
            "55":0.09090909,
            "56":0.27272728,
            "41":0.90909094,
            "42":0.18181819,
            "28":0.18181819
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":5,
            "Health":4,
            "Potency":3,
            "Offense":13,
            "Tenacity":4,
            "Protection":5,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":0.84615386,
            "Critical Chance":0.3846154,
            "Health":0.30769232,
            "Potency":0.23076923,
            "Offense":1.0,
            "Tenacity":0.30769232,
            "Protection":0.3846154,
            "Defense":0.53846157
         }
      },
      {
         "name":"DAKA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.6,
               "55":0.4
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.020833334,
               "49":0.020833334,
               "55":1.0
            },
            "5":{
               "55":1.0
            },
            "6":{
               "48":0.022727273,
               "17":0.09090909,
               "49":0.022727273,
               "55":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":25,
               "54":15,
               "55":10
            },
            "3":{
               "49":50
            },
            "4":{
               "48":1,
               "49":1,
               "55":48
            },
            "5":{
               "55":50
            },
            "6":{
               "48":1,
               "17":4,
               "49":1,
               "55":44
            }
         },
         "fourSetsMultiplier":0.3888889,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":116,
            "2":0,
            "3":2,
            "4":14,
            "5":1,
            "7":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.01724138,
            "4":1.0,
            "5":0.00862069,
            "7":0.01724138
         },
         "twoSetMax":244.8889,
         "twoSetCounts":{
            "1":116,
            "3":2,
            "5":1,
            "7":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":116.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":77.333336
            },
            {
               "set":1,
               "occurrence":3,
               "count":51.555557
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":3,
            "17":7,
            "49":5,
            "1":3,
            "18":2,
            "5":15,
            "53":2,
            "55":3,
            "56":2,
            "41":1,
            "42":5,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.2,
            "17":0.46666667,
            "49":0.33333334,
            "1":0.2,
            "18":0.13333334,
            "5":1.0,
            "53":0.13333334,
            "55":0.2,
            "56":0.13333334,
            "41":0.06666667,
            "42":0.33333334,
            "28":0.2
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":2,
            "Health":6,
            "Potency":7,
            "Offense":4,
            "Tenacity":2,
            "Protection":5,
            "Defense":10
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.13333334,
            "Health":0.4,
            "Potency":0.46666667,
            "Offense":0.26666668,
            "Tenacity":0.13333334,
            "Protection":0.33333334,
            "Defense":0.6666667
         }
      },
      {
         "name":"GEONOSIANSOLDIER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.09090909,
               "5":1.0,
               "54":0.022727273,
               "56":0.022727273
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.25925925,
               "16":1.0,
               "53":0.5555556,
               "56":0.037037037
            },
            "5":{
               "55":0.47058824,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.03448276,
               "18":0.13793103,
               "55":0.20689656,
               "56":0.3448276
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":4,
               "5":44,
               "54":1,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "48":7,
               "16":27,
               "53":15,
               "56":1
            },
            "5":{
               "55":16,
               "56":34
            },
            "6":{
               "48":29,
               "17":1,
               "18":4,
               "55":6,
               "56":10
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.5625,
         "setCounts":{
            "1":8,
            "2":8,
            "3":1,
            "4":7,
            "5":70,
            "6":17,
            "7":2,
            "8":5
         },
         "fourSetMultipliers":{
            "1":0.114285715,
            "2":0.47058824,
            "3":0.014285714,
            "4":0.4117647,
            "5":1.0,
            "6":1.0,
            "7":0.028571429,
            "8":0.071428575
         },
         "twoSetMax":147.77779,
         "twoSetCounts":{
            "1":8,
            "3":1,
            "5":70,
            "7":2,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":70.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":46.666668
            },
            {
               "set":5,
               "occurrence":3,
               "count":31.111113
            },
            {
               "set":1,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":5,
            "17":4,
            "1":2,
            "18":5,
            "5":13,
            "53":9,
            "55":5,
            "56":2,
            "41":3,
            "42":1,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.15384616,
            "49":0.3846154,
            "17":0.30769232,
            "1":0.15384616,
            "18":0.3846154,
            "5":1.0,
            "53":0.6923077,
            "55":0.3846154,
            "56":0.15384616,
            "41":0.23076923,
            "42":0.07692308,
            "28":0.15384616
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":9,
            "Health":7,
            "Potency":4,
            "Offense":5,
            "Tenacity":5,
            "Protection":4,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.6923077,
            "Health":0.53846157,
            "Potency":0.30769232,
            "Offense":0.3846154,
            "Tenacity":0.3846154,
            "Protection":0.30769232,
            "Defense":0.46153846
         }
      },
      {
         "name":"DARTHTRAYA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.041666668
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.10344828,
               "48":0.2413793,
               "49":0.10344828,
               "53":0.10344828,
               "55":0.1724138,
               "56":1.0
            },
            "5":{
               "55":0.25,
               "56":1.0
            },
            "6":{
               "48":0.029411765,
               "49":0.0882353,
               "17":0.0882353,
               "18":0.0882353,
               "55":0.1764706,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":48,
               "54":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":3,
               "48":7,
               "49":3,
               "53":3,
               "55":5,
               "56":29
            },
            "5":{
               "55":10,
               "56":40
            },
            "6":{
               "48":1,
               "49":3,
               "17":3,
               "18":3,
               "55":6,
               "56":34
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.08695652,
         "setCounts":{
            "1":30,
            "2":0,
            "3":4,
            "4":46,
            "5":4,
            "6":0,
            "7":8,
            "8":7
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.13333334,
            "4":1.0,
            "5":0.13333334,
            "6":0.0,
            "7":0.26666668,
            "8":0.23333333
         },
         "twoSetMax":63.333332,
         "twoSetCounts":{
            "1":30,
            "3":4,
            "5":4,
            "7":8,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":30.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":20.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":13.333333
            },
            {
               "set":7,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":2,
            "49":1,
            "17":5,
            "18":3,
            "5":17,
            "53":8,
            "55":1,
            "56":4,
            "41":1,
            "42":1,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.23529412,
            "1":0.11764706,
            "49":0.05882353,
            "17":0.29411766,
            "18":0.1764706,
            "5":1.0,
            "53":0.47058824,
            "55":0.05882353,
            "56":0.23529412,
            "41":0.05882353,
            "42":0.05882353,
            "28":0.11764706
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":8,
            "Health":3,
            "Potency":5,
            "Offense":5,
            "Tenacity":3,
            "Protection":6,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.47058824,
            "Health":0.1764706,
            "Potency":0.29411766,
            "Offense":0.29411766,
            "Tenacity":0.1764706,
            "Protection":0.3529412,
            "Defense":0.11764706
         }
      },
      {
         "name":"HANSOLO",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.06382979,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.02173913,
               "53":0.04347826,
               "55":0.02173913
            },
            "5":{
               "55":0.9230769,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "18":0.020408163
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":3,
               "5":47
            },
            "3":{
               "49":50
            },
            "4":{
               "16":46,
               "48":1,
               "53":2,
               "55":1
            },
            "5":{
               "55":24,
               "56":26
            },
            "6":{
               "48":49,
               "18":1
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":21,
            "2":3,
            "3":3,
            "4":3,
            "5":19,
            "6":44,
            "7":6
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.06818182,
            "3":0.14285715,
            "4":0.06818182,
            "5":0.9047619,
            "6":1.0,
            "7":0.2857143
         },
         "twoSetMax":54.0,
         "twoSetCounts":{
            "1":21,
            "3":3,
            "5":19,
            "7":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":5,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":7,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "1":2,
            "17":4,
            "49":1,
            "18":2,
            "5":20,
            "53":2,
            "55":1,
            "56":1,
            "41":4,
            "42":1,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.25,
            "1":0.1,
            "17":0.2,
            "49":0.05,
            "18":0.1,
            "5":1.0,
            "53":0.1,
            "55":0.05,
            "56":0.05,
            "41":0.2,
            "42":0.05,
            "28":0.25
         },
         "secondaryTypeCounts":{
            "Speed":20,
            "Critical Chance":2,
            "Health":3,
            "Potency":4,
            "Offense":9,
            "Tenacity":2,
            "Protection":6,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.1,
            "Health":0.15,
            "Potency":0.2,
            "Offense":0.45,
            "Tenacity":0.1,
            "Protection":0.3,
            "Defense":0.1
         }
      },
      {
         "name":"R2D2_LEGENDARY",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.08888889,
               "5":1.0,
               "55":0.022222223
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":1.0,
               "16":0.08108108,
               "53":0.13513513,
               "55":0.10810811,
               "56":0.027027028
            },
            "5":{
               "55":1.0,
               "56":0.5625
            },
            "6":{
               "48":1.0,
               "17":0.048780486,
               "18":0.024390243,
               "55":0.12195122,
               "56":0.024390243
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":4,
               "5":45,
               "55":1
            },
            "3":{
               "49":50
            },
            "4":{
               "48":37,
               "16":3,
               "53":5,
               "55":4,
               "56":1
            },
            "5":{
               "55":32,
               "56":18
            },
            "6":{
               "48":41,
               "17":2,
               "18":1,
               "55":5,
               "56":1
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.041666668,
         "setCounts":{
            "1":25,
            "2":38,
            "3":3,
            "4":10,
            "5":12,
            "7":7,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0,
            "3":0.12,
            "4":0.2631579,
            "5":0.48,
            "7":0.28,
            "8":0.08
         },
         "twoSetMax":53.666664,
         "twoSetCounts":{
            "1":25,
            "3":3,
            "5":12,
            "7":7,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":25.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":16.666666
            },
            {
               "set":5,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":11.111111
            },
            {
               "set":5,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":1,
            "17":3,
            "49":4,
            "18":1,
            "5":18,
            "53":1,
            "55":4,
            "56":2,
            "41":5,
            "42":4,
            "28":7
         },
         "secondaryMultipliers":{
            "48":0.11111111,
            "1":0.055555556,
            "17":0.16666667,
            "49":0.22222222,
            "18":0.055555556,
            "5":1.0,
            "53":0.055555556,
            "55":0.22222222,
            "56":0.11111111,
            "41":0.2777778,
            "42":0.22222222,
            "28":0.3888889
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":1,
            "Health":5,
            "Potency":3,
            "Offense":7,
            "Tenacity":1,
            "Protection":9,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.055555556,
            "Health":0.2777778,
            "Potency":0.16666667,
            "Offense":0.3888889,
            "Tenacity":0.055555556,
            "Protection":0.5,
            "Defense":0.44444445
         }
      },
      {
         "name":"CT7567",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.7,
               "48":1.0,
               "49":0.7,
               "53":0.7,
               "55":1.0,
               "56":0.9
            },
            "5":{
               "55":1.0,
               "56":0.7241379
            },
            "6":{
               "48":0.2857143,
               "17":0.2857143,
               "49":0.42857143,
               "18":0.9285714,
               "55":1.0,
               "56":0.64285713
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "16":7,
               "48":10,
               "49":7,
               "53":7,
               "55":10,
               "56":9
            },
            "5":{
               "55":29,
               "56":21
            },
            "6":{
               "48":4,
               "17":4,
               "49":6,
               "18":13,
               "55":14,
               "56":9
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":22,
            "2":0,
            "3":1,
            "4":50,
            "5":2,
            "6":0,
            "7":1,
            "8":4
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.045454547,
            "4":1.0,
            "5":0.09090909,
            "6":0.0,
            "7":0.045454547,
            "8":0.18181819
         },
         "twoSetMax":46.444447,
         "twoSetCounts":{
            "1":22,
            "3":1,
            "5":2,
            "7":1,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":22.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.666667
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.777778
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":5,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":5,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "49":2,
            "17":2,
            "1":1,
            "18":2,
            "5":19,
            "53":4,
            "55":5,
            "56":2,
            "41":1,
            "42":1,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.15789473,
            "49":0.10526316,
            "17":0.10526316,
            "1":0.05263158,
            "18":0.10526316,
            "5":1.0,
            "53":0.21052632,
            "55":0.2631579,
            "56":0.10526316,
            "41":0.05263158,
            "42":0.05263158,
            "28":0.31578946
         },
         "secondaryTypeCounts":{
            "Speed":19,
            "Critical Chance":4,
            "Health":6,
            "Potency":2,
            "Offense":4,
            "Tenacity":2,
            "Protection":8,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.21052632,
            "Health":0.31578946,
            "Potency":0.10526316,
            "Offense":0.21052632,
            "Tenacity":0.10526316,
            "Protection":0.42105263,
            "Defense":0.15789473
         }
      },
      {
         "name":"GRIEVOUS",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":0.35135135,
               "55":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "55":0.9230769
            },
            "5":{
               "55":1.0
            },
            "6":{
               "17":0.13636364,
               "55":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":13,
               "55":37
            },
            "3":{
               "49":50
            },
            "4":{
               "16":26,
               "55":24
            },
            "5":{
               "55":50
            },
            "6":{
               "17":6,
               "55":44
            }
         },
         "fourSetsMultiplier":0.1627907,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":133,
            "4":1,
            "6":6,
            "7":1,
            "8":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "4":0.16666667,
            "6":1.0,
            "7":0.007518797,
            "8":0.007518797
         },
         "twoSetMax":280.77777,
         "twoSetCounts":{
            "1":133,
            "7":1,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":133.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":88.666664
            },
            {
               "set":1,
               "occurrence":3,
               "count":59.11111
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":3,
            "49":3,
            "17":2,
            "1":3,
            "18":2,
            "5":23,
            "53":6,
            "55":3,
            "56":2,
            "41":2,
            "42":1,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.13043478,
            "49":0.13043478,
            "17":0.08695652,
            "1":0.13043478,
            "18":0.08695652,
            "5":1.0,
            "53":0.26086956,
            "55":0.13043478,
            "56":0.08695652,
            "41":0.08695652,
            "42":0.04347826,
            "28":0.13043478
         },
         "secondaryTypeCounts":{
            "Speed":23,
            "Critical Chance":6,
            "Health":6,
            "Potency":2,
            "Offense":5,
            "Tenacity":2,
            "Protection":5,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.26086956,
            "Health":0.26086956,
            "Potency":0.08695652,
            "Offense":0.2173913,
            "Tenacity":0.08695652,
            "Protection":0.2173913,
            "Defense":0.17391305
         }
      },
      {
         "name":"KANANJARRUSS3",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.04,
               "49":0.16,
               "5":1.0,
               "54":0.52,
               "55":0.16,
               "56":0.12
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.47058824,
               "48":0.11764706,
               "49":0.29411766,
               "53":0.29411766,
               "55":0.7647059,
               "56":1.0
            },
            "5":{
               "55":0.5151515,
               "56":1.0
            },
            "6":{
               "48":0.2,
               "49":0.6666667,
               "17":0.06666667,
               "18":0.8666667,
               "55":0.53333336,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "49":4,
               "5":25,
               "54":13,
               "55":4,
               "56":3
            },
            "3":{
               "49":50
            },
            "4":{
               "16":8,
               "48":2,
               "49":5,
               "53":5,
               "55":13,
               "56":17
            },
            "5":{
               "55":17,
               "56":33
            },
            "6":{
               "48":3,
               "49":10,
               "17":1,
               "18":13,
               "55":8,
               "56":15
            }
         },
         "fourSetsMultiplier":0.13636364,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":66,
            "2":1,
            "3":22,
            "4":5,
            "5":8,
            "6":0,
            "7":4,
            "8":20
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.2,
            "3":0.33333334,
            "4":1.0,
            "5":0.121212125,
            "6":0.0,
            "7":0.060606062,
            "8":0.3030303
         },
         "twoSetMax":139.33333,
         "twoSetCounts":{
            "1":66,
            "3":22,
            "5":8,
            "7":4,
            "8":20
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":66.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":44.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":29.333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":22.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":20.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":14.666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":13.333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":9.777778
            },
            {
               "set":8,
               "occurrence":3,
               "count":8.888888
            },
            {
               "set":5,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":4,
            "17":1,
            "1":10,
            "49":2,
            "18":6,
            "53":4,
            "5":8,
            "55":5,
            "56":2,
            "41":3,
            "42":4,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.4,
            "17":0.1,
            "1":1.0,
            "49":0.2,
            "18":0.6,
            "53":0.4,
            "5":0.8,
            "55":0.5,
            "56":0.2,
            "41":0.3,
            "42":0.4,
            "28":0.6
         },
         "secondaryTypeCounts":{
            "Speed":8,
            "Critical Chance":4,
            "Health":15,
            "Potency":1,
            "Offense":7,
            "Tenacity":6,
            "Protection":8,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":0.53333336,
            "Critical Chance":0.26666668,
            "Health":1.0,
            "Potency":0.06666667,
            "Offense":0.46666667,
            "Tenacity":0.4,
            "Protection":0.53333336,
            "Defense":0.4
         }
      },
      {
         "name":"GEONOSIANBROODALPHA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "55":0.020833334,
               "56":0.020833334
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.05263158,
               "48":0.02631579,
               "49":0.02631579,
               "53":0.078947365,
               "55":1.0,
               "56":0.13157895
            },
            "5":{
               "55":1.0,
               "56":0.25
            },
            "6":{
               "18":0.3,
               "55":1.0,
               "56":0.36666667
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":48,
               "55":1,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":2,
               "48":1,
               "49":1,
               "53":3,
               "55":38,
               "56":5
            },
            "5":{
               "55":40,
               "56":10
            },
            "6":{
               "18":9,
               "55":30,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.61290324,
         "setCounts":{
            "1":72,
            "3":2,
            "4":31,
            "5":1,
            "8":12
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.027777778,
            "4":1.0,
            "5":0.013888889,
            "8":0.16666667
         },
         "twoSetMax":152.0,
         "twoSetCounts":{
            "1":72,
            "3":2,
            "5":1,
            "8":12
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":72.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":48.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":32.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":2,
            "1":5,
            "17":2,
            "18":2,
            "5":20,
            "53":3,
            "55":2,
            "56":4,
            "41":4,
            "42":4,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.1,
            "49":0.1,
            "1":0.25,
            "17":0.1,
            "18":0.1,
            "5":1.0,
            "53":0.15,
            "55":0.1,
            "56":0.2,
            "41":0.2,
            "42":0.2,
            "28":0.1
         },
         "secondaryTypeCounts":{
            "Speed":20,
            "Critical Chance":3,
            "Health":7,
            "Potency":2,
            "Offense":6,
            "Tenacity":2,
            "Protection":6,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.15,
            "Health":0.35,
            "Potency":0.1,
            "Offense":0.3,
            "Tenacity":0.1,
            "Protection":0.3,
            "Defense":0.3
         }
      },
      {
         "name":"JOLEEBINDO",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":0.36363637,
               "54":1.0,
               "55":0.121212125,
               "56":0.030303031
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.04347826,
               "48":0.08695652,
               "49":0.13043478,
               "53":0.04347826,
               "55":0.8695652,
               "56":1.0
            },
            "5":{
               "55":1.0,
               "56":0.5151515
            },
            "6":{
               "18":1.0,
               "55":0.24242425,
               "56":0.27272728
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":12,
               "54":33,
               "55":4,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":1,
               "48":2,
               "49":3,
               "53":1,
               "55":20,
               "56":23
            },
            "5":{
               "55":33,
               "56":17
            },
            "6":{
               "18":33,
               "55":8,
               "56":9
            }
         },
         "fourSetsMultiplier":0.06382979,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":38,
            "3":5,
            "4":3,
            "5":0,
            "8":99
         },
         "fourSetMultipliers":{
            "1":0.3838384,
            "3":0.05050505,
            "4":1.0,
            "5":0.0,
            "8":1.0
         },
         "twoSetMax":209.0,
         "twoSetCounts":{
            "1":38,
            "3":5,
            "5":0,
            "8":99
         },
         "twoSetOccurrenceCounts":[
            {
               "set":8,
               "occurrence":1,
               "count":99.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":66.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":44.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":38.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":25.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":16.88889
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"tenacity",
         "commonSet2Name":"tenacity",
         "commonSet3Name":"tenacity",
         "secondaryCounts":{
            "48":2,
            "1":2,
            "17":2,
            "49":1,
            "18":13,
            "5":8,
            "53":3,
            "55":2,
            "56":1,
            "41":3,
            "42":3,
            "28":8
         },
         "secondaryMultipliers":{
            "48":0.15384616,
            "1":0.15384616,
            "17":0.15384616,
            "49":0.07692308,
            "18":1.0,
            "5":0.61538464,
            "53":0.23076923,
            "55":0.15384616,
            "56":0.07692308,
            "41":0.23076923,
            "42":0.23076923,
            "28":0.61538464
         },
         "secondaryTypeCounts":{
            "Speed":8,
            "Critical Chance":3,
            "Health":4,
            "Potency":2,
            "Offense":5,
            "Tenacity":13,
            "Protection":9,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":0.61538464,
            "Critical Chance":0.23076923,
            "Health":0.30769232,
            "Potency":0.15384616,
            "Offense":0.3846154,
            "Tenacity":1.0,
            "Protection":0.6923077,
            "Defense":0.30769232
         }
      },
      {
         "name":"ARCTROOPER501ST",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.33333334,
               "5":1.0,
               "54":0.055555556
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.42857143,
               "48":1.0
            },
            "5":{
               "55":1.0,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.020833334,
               "18":0.020833334
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":12,
               "5":36,
               "54":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":15,
               "48":35
            },
            "5":{
               "55":25,
               "56":25
            },
            "6":{
               "48":48,
               "17":1,
               "18":1
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":18,
            "2":49,
            "3":0,
            "4":0,
            "5":23,
            "6":1,
            "7":2,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.7826087,
            "2":1.0,
            "3":0.0,
            "4":0.0,
            "5":1.0,
            "6":0.020408163,
            "7":0.08695652,
            "8":0.04347826
         },
         "twoSetMax":56.333332,
         "twoSetCounts":{
            "1":18,
            "3":0,
            "5":23,
            "7":2,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":23.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":15.333333
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":10.222222
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "17":4,
            "49":1,
            "1":2,
            "18":7,
            "53":3,
            "5":18,
            "55":3,
            "56":1,
            "41":5,
            "42":1,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.055555556,
            "17":0.22222222,
            "49":0.055555556,
            "1":0.11111111,
            "18":0.3888889,
            "53":0.16666667,
            "5":1.0,
            "55":0.16666667,
            "56":0.055555556,
            "41":0.2777778,
            "42":0.055555556,
            "28":0.11111111
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":3,
            "Health":5,
            "Potency":4,
            "Offense":6,
            "Tenacity":7,
            "Protection":3,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.16666667,
            "Health":0.2777778,
            "Potency":0.22222222,
            "Offense":0.33333334,
            "Tenacity":0.3888889,
            "Protection":0.16666667,
            "Defense":0.11111111
         }
      },
      {
         "name":"JYNERSO",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.024390243,
               "52":0.048780486,
               "5":1.0,
               "54":0.07317073,
               "55":0.07317073
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.5217391,
               "48":0.3478261,
               "49":0.04347826,
               "53":1.0,
               "55":0.08695652,
               "56":0.17391305
            },
            "5":{
               "55":0.61290324,
               "56":1.0
            },
            "6":{
               "48":0.2777778,
               "17":1.0,
               "49":0.22222222,
               "18":0.33333334,
               "55":0.33333334,
               "56":0.6111111
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "52":2,
               "5":41,
               "54":3,
               "55":3
            },
            "3":{
               "49":50
            },
            "4":{
               "16":12,
               "48":8,
               "49":1,
               "53":23,
               "55":2,
               "56":4
            },
            "5":{
               "55":19,
               "56":31
            },
            "6":{
               "48":5,
               "17":18,
               "49":4,
               "18":6,
               "55":6,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.31578946,
         "setCounts":{
            "1":11,
            "2":1,
            "3":4,
            "4":35,
            "5":24,
            "6":2,
            "7":19,
            "8":5
         },
         "fourSetMultipliers":{
            "1":0.45833334,
            "2":0.028571429,
            "3":0.16666667,
            "4":1.0,
            "5":1.0,
            "6":0.057142857,
            "7":0.7916667,
            "8":0.20833333
         },
         "twoSetMax":59.0,
         "twoSetCounts":{
            "1":11,
            "3":4,
            "5":24,
            "7":19,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":24.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":16.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":1,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":10.666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":1,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":2,
            "49":4,
            "17":3,
            "18":3,
            "53":6,
            "5":15,
            "55":2,
            "56":2,
            "41":5,
            "42":1,
            "28":8
         },
         "secondaryMultipliers":{
            "48":0.13333334,
            "1":0.13333334,
            "49":0.26666668,
            "17":0.2,
            "18":0.2,
            "53":0.4,
            "5":1.0,
            "55":0.13333334,
            "56":0.13333334,
            "41":0.33333334,
            "42":0.06666667,
            "28":0.53333336
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":6,
            "Health":4,
            "Potency":3,
            "Offense":7,
            "Tenacity":3,
            "Protection":10,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.4,
            "Health":0.26666668,
            "Potency":0.2,
            "Offense":0.46666667,
            "Tenacity":0.2,
            "Protection":0.6666667,
            "Defense":0.33333334
         }
      },
      {
         "name":"COLONELSTARCK",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.020408163,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.34615386,
               "49":0.115384616,
               "53":0.26923078,
               "55":0.15384616,
               "56":0.03846154
            },
            "5":{
               "55":0.47058824,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.6111111,
               "49":0.3888889,
               "18":0.22222222,
               "55":0.16666667,
               "56":0.3888889
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "5":49
            },
            "3":{
               "49":50
            },
            "4":{
               "16":26,
               "48":9,
               "49":3,
               "53":7,
               "55":4,
               "56":1
            },
            "5":{
               "55":16,
               "56":34
            },
            "6":{
               "48":18,
               "17":11,
               "49":7,
               "18":4,
               "55":3,
               "56":7
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.06382979,
         "setCounts":{
            "1":12,
            "2":1,
            "3":3,
            "4":44,
            "5":16,
            "6":2,
            "7":5,
            "8":3
         },
         "fourSetMultipliers":{
            "1":0.75,
            "2":0.022727273,
            "3":0.1875,
            "4":1.0,
            "5":1.0,
            "6":0.045454547,
            "7":0.3125,
            "8":0.1875
         },
         "twoSetMax":38.666668,
         "twoSetCounts":{
            "1":12,
            "3":3,
            "5":16,
            "7":5,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":16.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":10.666667
            },
            {
               "set":1,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":7.111111
            },
            {
               "set":1,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "1":4,
            "17":1,
            "49":1,
            "18":2,
            "5":18,
            "53":5,
            "55":3,
            "56":2,
            "41":3,
            "42":4,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.16666667,
            "1":0.22222222,
            "17":0.055555556,
            "49":0.055555556,
            "18":0.11111111,
            "5":1.0,
            "53":0.2777778,
            "55":0.16666667,
            "56":0.11111111,
            "41":0.16666667,
            "42":0.22222222,
            "28":0.11111111
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":5,
            "Health":7,
            "Potency":1,
            "Offense":6,
            "Tenacity":2,
            "Protection":4,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.2777778,
            "Health":0.3888889,
            "Potency":0.055555556,
            "Offense":0.33333334,
            "Tenacity":0.11111111,
            "Protection":0.22222222,
            "Defense":0.2777778
         }
      },
      {
         "name":"FIRSTORDERSPECIALFORCESPILOT",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.22222222,
               "52":0.027777778,
               "5":1.0,
               "54":0.055555556,
               "56":0.083333336
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.24242425,
               "49":0.030303031,
               "53":0.15151516,
               "55":0.060606062,
               "56":0.030303031
            },
            "5":{
               "55":0.7241379,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "49":0.125,
               "17":0.375,
               "18":0.125,
               "55":0.20833333,
               "56":0.25
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":8,
               "52":1,
               "5":36,
               "54":2,
               "56":3
            },
            "3":{
               "49":50
            },
            "4":{
               "16":33,
               "48":8,
               "49":1,
               "53":5,
               "55":2,
               "56":1
            },
            "5":{
               "55":21,
               "56":29
            },
            "6":{
               "48":24,
               "49":3,
               "17":9,
               "18":3,
               "55":5,
               "56":6
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.2820513,
         "setCounts":{
            "1":14,
            "2":4,
            "3":2,
            "4":18,
            "5":24,
            "6":17,
            "7":5,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.5833333,
            "2":0.22222222,
            "3":0.083333336,
            "4":1.0,
            "5":1.0,
            "6":0.9444444,
            "7":0.20833333,
            "8":0.041666668
         },
         "twoSetMax":54.0,
         "twoSetCounts":{
            "1":14,
            "3":2,
            "5":24,
            "7":5,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":24.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":16.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":10.666667
            },
            {
               "set":1,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":4,
            "17":1,
            "49":1,
            "18":7,
            "5":12,
            "53":9,
            "55":7,
            "56":1,
            "41":4,
            "42":4,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.16666667,
            "1":0.33333334,
            "17":0.083333336,
            "49":0.083333336,
            "18":0.5833333,
            "5":1.0,
            "53":0.75,
            "55":0.5833333,
            "56":0.083333336,
            "41":0.33333334,
            "42":0.33333334,
            "28":0.41666666
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":9,
            "Health":11,
            "Potency":1,
            "Offense":6,
            "Tenacity":7,
            "Protection":6,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.75,
            "Health":0.9166667,
            "Potency":0.083333336,
            "Offense":0.5,
            "Tenacity":0.5833333,
            "Protection":0.5,
            "Defense":0.41666666
         }
      },
      {
         "name":"JAWASCAVENGER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.05882353,
               "49":0.05882353,
               "5":1.0,
               "54":0.11764706,
               "55":0.0882353,
               "56":0.11764706
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.71428573,
               "48":0.5,
               "49":0.14285715,
               "53":1.0,
               "55":0.35714287,
               "56":0.78571427
            },
            "5":{
               "55":0.5151515,
               "56":1.0
            },
            "6":{
               "48":0.1875,
               "17":1.0,
               "49":0.3125,
               "18":0.25,
               "55":0.375,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":2,
               "49":2,
               "5":34,
               "54":4,
               "55":3,
               "56":4
            },
            "3":{
               "49":50
            },
            "4":{
               "16":10,
               "48":7,
               "49":2,
               "53":14,
               "55":5,
               "56":11
            },
            "5":{
               "55":17,
               "56":33
            },
            "6":{
               "48":3,
               "17":16,
               "49":5,
               "18":4,
               "55":6,
               "56":16
            }
         },
         "fourSetsMultiplier":0.5625,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":55,
            "2":2,
            "3":6,
            "4":11,
            "5":15,
            "6":5,
            "7":19,
            "8":5
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.18181819,
            "3":0.10909091,
            "4":1.0,
            "5":0.27272728,
            "6":0.45454547,
            "7":0.34545454,
            "8":0.09090909
         },
         "twoSetMax":116.111115,
         "twoSetCounts":{
            "1":55,
            "3":6,
            "5":15,
            "7":19,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":55.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":36.666668
            },
            {
               "set":1,
               "occurrence":3,
               "count":24.444445
            },
            {
               "set":7,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":5,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":5,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":3,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":3,
            "49":3,
            "1":2,
            "17":6,
            "18":1,
            "53":1,
            "5":14,
            "55":2,
            "56":3,
            "41":6,
            "42":3,
            "28":7
         },
         "secondaryMultipliers":{
            "48":0.21428572,
            "49":0.21428572,
            "1":0.14285715,
            "17":0.42857143,
            "18":0.071428575,
            "53":0.071428575,
            "5":1.0,
            "55":0.14285715,
            "56":0.21428572,
            "41":0.42857143,
            "42":0.21428572,
            "28":0.5
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":1,
            "Health":4,
            "Potency":6,
            "Offense":9,
            "Tenacity":1,
            "Protection":10,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.071428575,
            "Health":0.2857143,
            "Potency":0.42857143,
            "Offense":0.64285713,
            "Tenacity":0.071428575,
            "Protection":0.71428573,
            "Defense":0.42857143
         }
      },
      {
         "name":"EWOKSCOUT",
         "count":47,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.023255814,
               "49":0.023255814,
               "5":1.0,
               "56":0.023255814
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.625,
               "49":0.0625,
               "53":0.4375,
               "55":0.5,
               "56":0.25
            },
            "5":{
               "55":0.8076923,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.8333333,
               "49":0.5833333,
               "18":0.25,
               "55":0.5,
               "56":0.6666667
            }
         },
         "primaryCounts":{
            "1":{
               "48":47
            },
            "2":{
               "48":1,
               "49":1,
               "5":43,
               "56":1
            },
            "3":{
               "49":46
            },
            "4":{
               "16":16,
               "48":10,
               "49":1,
               "53":7,
               "55":8,
               "56":4
            },
            "5":{
               "55":21,
               "56":26
            },
            "6":{
               "48":12,
               "17":10,
               "49":7,
               "18":3,
               "55":6,
               "56":8
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.516129,
         "setCounts":{
            "1":15,
            "2":2,
            "3":4,
            "4":7,
            "5":21,
            "6":22,
            "7":1,
            "8":6
         },
         "fourSetMultipliers":{
            "1":0.71428573,
            "2":0.09090909,
            "3":0.1904762,
            "4":0.3181818,
            "5":1.0,
            "6":1.0,
            "7":0.04761905,
            "8":0.2857143
         },
         "twoSetMax":50.0,
         "twoSetCounts":{
            "1":15,
            "3":4,
            "5":21,
            "7":1,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":3,
            "1":3,
            "17":3,
            "18":7,
            "5":12,
            "53":5,
            "55":1,
            "56":1,
            "41":11,
            "42":5,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.16666667,
            "49":0.25,
            "1":0.25,
            "17":0.25,
            "18":0.5833333,
            "5":1.0,
            "53":0.41666666,
            "55":0.083333336,
            "56":0.083333336,
            "41":0.9166667,
            "42":0.41666666,
            "28":0.41666666
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":5,
            "Health":4,
            "Potency":3,
            "Offense":13,
            "Tenacity":7,
            "Protection":6,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":0.9230769,
            "Critical Chance":0.3846154,
            "Health":0.30769232,
            "Potency":0.23076923,
            "Offense":1.0,
            "Tenacity":0.53846157,
            "Protection":0.46153846,
            "Defense":0.61538464
         }
      },
      {
         "name":"NIGHTSISTERACOLYTE",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.048780486,
               "49":0.024390243,
               "5":1.0,
               "54":0.024390243,
               "56":0.07317073
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.42105263,
               "53":0.84210527,
               "55":0.10526316,
               "56":0.10526316
            },
            "5":{
               "55":1.0,
               "56":0.9583333
            },
            "6":{
               "48":1.0,
               "17":0.2173913,
               "49":0.08695652,
               "18":0.13043478,
               "55":0.39130434,
               "56":0.2173913
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":2,
               "49":1,
               "5":41,
               "54":1,
               "56":3
            },
            "3":{
               "49":48
            },
            "4":{
               "16":19,
               "48":8,
               "53":16,
               "55":2,
               "56":2
            },
            "5":{
               "55":24,
               "56":23
            },
            "6":{
               "48":23,
               "17":5,
               "49":2,
               "18":3,
               "55":9,
               "56":5
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.3611111,
         "setCounts":{
            "1":16,
            "2":7,
            "3":1,
            "4":12,
            "5":29,
            "6":17,
            "7":2,
            "8":3
         },
         "fourSetMultipliers":{
            "1":0.55172414,
            "2":0.4117647,
            "3":0.03448276,
            "4":0.7058824,
            "5":1.0,
            "6":1.0,
            "7":0.06896552,
            "8":0.10344828
         },
         "twoSetMax":64.333336,
         "twoSetCounts":{
            "1":16,
            "3":1,
            "5":29,
            "7":2,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":29.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":19.333334
            },
            {
               "set":1,
               "occurrence":1,
               "count":16.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":12.888889
            },
            {
               "set":1,
               "occurrence":2,
               "count":10.666667
            },
            {
               "set":1,
               "occurrence":3,
               "count":7.111111
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":1,
            "49":1,
            "17":3,
            "18":8,
            "53":11,
            "5":13,
            "55":2,
            "56":1,
            "41":1,
            "42":2,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.15384616,
            "1":0.07692308,
            "49":0.07692308,
            "17":0.23076923,
            "18":0.61538464,
            "53":0.84615386,
            "5":1.0,
            "55":0.15384616,
            "56":0.07692308,
            "41":0.07692308,
            "42":0.15384616,
            "28":0.30769232
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":11,
            "Health":3,
            "Potency":3,
            "Offense":3,
            "Tenacity":8,
            "Protection":5,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.84615386,
            "Health":0.23076923,
            "Potency":0.23076923,
            "Offense":0.23076923,
            "Tenacity":0.61538464,
            "Protection":0.3846154,
            "Defense":0.23076923
         }
      },
      {
         "name":"REYJEDITRAINING",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.025,
               "5":1.0,
               "54":0.05,
               "55":0.175
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.15,
               "53":0.05,
               "55":1.0,
               "56":0.05
            },
            "5":{
               "55":1.0,
               "56":0.1904762
            },
            "6":{
               "17":0.075,
               "55":1.0,
               "56":0.175
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "5":40,
               "54":2,
               "55":7
            },
            "3":{
               "49":50
            },
            "4":{
               "16":6,
               "53":2,
               "55":40,
               "56":2
            },
            "5":{
               "55":42,
               "56":8
            },
            "6":{
               "17":3,
               "55":40,
               "56":7
            }
         },
         "fourSetsMultiplier":0.31578946,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":105,
            "2":1,
            "3":14,
            "4":8,
            "5":1,
            "6":3,
            "7":5,
            "8":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.125,
            "3":0.13333334,
            "4":1.0,
            "5":0.00952381,
            "6":0.375,
            "7":0.04761905,
            "8":0.00952381
         },
         "twoSetMax":221.66667,
         "twoSetCounts":{
            "1":105,
            "3":14,
            "5":1,
            "7":5,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":105.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":70.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":46.666668
            },
            {
               "set":3,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":1,
            "17":7,
            "49":4,
            "1":7,
            "18":1,
            "5":14,
            "53":1,
            "55":9,
            "56":3,
            "41":2,
            "42":3,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.071428575,
            "17":0.5,
            "49":0.2857143,
            "1":0.5,
            "18":0.071428575,
            "5":1.0,
            "53":0.071428575,
            "55":0.64285713,
            "56":0.21428572,
            "41":0.14285715,
            "42":0.21428572,
            "28":0.21428572
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":1,
            "Health":16,
            "Potency":7,
            "Offense":3,
            "Tenacity":1,
            "Protection":6,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":0.875,
            "Critical Chance":0.0625,
            "Health":1.0,
            "Potency":0.4375,
            "Offense":0.1875,
            "Tenacity":0.0625,
            "Protection":0.375,
            "Defense":0.4375
         }
      },
      {
         "name":"MAGNAGUARD",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":0.8333333,
               "54":0.8333333,
               "55":0.11111111,
               "56":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "55":0.041666668,
               "56":1.0
            },
            "5":{
               "55":0.1627907,
               "56":1.0
            },
            "6":{
               "49":0.11111111,
               "17":0.027777778,
               "18":0.1388889,
               "55":0.11111111,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":15,
               "54":15,
               "55":2,
               "56":18
            },
            "3":{
               "49":50
            },
            "4":{
               "55":2,
               "56":48
            },
            "5":{
               "55":7,
               "56":43
            },
            "6":{
               "49":4,
               "17":1,
               "18":5,
               "55":4,
               "56":36
            }
         },
         "fourSetsMultiplier":0.08695652,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":60,
            "2":0,
            "3":20,
            "4":4,
            "7":1,
            "8":56
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.33333334,
            "4":1.0,
            "7":0.016666668,
            "8":0.93333334
         },
         "twoSetMax":156.0,
         "twoSetCounts":{
            "1":60,
            "3":20,
            "7":1,
            "8":56
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":60.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":56.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":40.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":37.333332
            },
            {
               "set":1,
               "occurrence":3,
               "count":26.666666
            },
            {
               "set":8,
               "occurrence":3,
               "count":24.888887
            },
            {
               "set":3,
               "occurrence":1,
               "count":20.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":13.333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":8.888888
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"tenacity",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":4,
            "17":2,
            "1":2,
            "49":2,
            "18":6,
            "5":22,
            "53":6,
            "55":5,
            "56":1,
            "41":3,
            "42":2,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.18181819,
            "17":0.09090909,
            "1":0.09090909,
            "49":0.09090909,
            "18":0.27272728,
            "5":1.0,
            "53":0.27272728,
            "55":0.22727273,
            "56":0.045454547,
            "41":0.13636364,
            "42":0.09090909,
            "28":0.045454547
         },
         "secondaryTypeCounts":{
            "Speed":22,
            "Critical Chance":6,
            "Health":7,
            "Potency":2,
            "Offense":7,
            "Tenacity":6,
            "Protection":2,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.27272728,
            "Health":0.3181818,
            "Potency":0.09090909,
            "Offense":0.3181818,
            "Tenacity":0.27272728,
            "Protection":0.09090909,
            "Defense":0.18181819
         }
      },
      {
         "name":"ROYALGUARD",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "49":0.05,
               "52":0.05,
               "5":1.0,
               "54":0.75,
               "55":0.2,
               "56":0.45
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.1,
               "48":0.1,
               "49":0.16666667,
               "53":0.13333334,
               "55":0.16666667,
               "56":1.0
            },
            "5":{
               "55":0.3888889,
               "56":1.0
            },
            "6":{
               "17":0.39130434,
               "49":0.08695652,
               "18":0.26086956,
               "55":0.39130434,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "49":1,
               "52":1,
               "5":20,
               "54":15,
               "55":4,
               "56":9
            },
            "3":{
               "49":50
            },
            "4":{
               "16":3,
               "48":3,
               "49":5,
               "53":4,
               "55":5,
               "56":30
            },
            "5":{
               "55":14,
               "56":36
            },
            "6":{
               "17":9,
               "49":2,
               "18":6,
               "55":9,
               "56":23
            }
         },
         "fourSetsMultiplier":0.020408163,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":73,
            "3":33,
            "4":1,
            "5":2,
            "6":0,
            "7":14,
            "8":12
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.4520548,
            "4":1.0,
            "5":0.02739726,
            "6":0.0,
            "7":0.19178082,
            "8":0.16438356
         },
         "twoSetMax":154.66667,
         "twoSetCounts":{
            "1":73,
            "3":33,
            "5":2,
            "7":14,
            "8":12
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":73.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":48.666668
            },
            {
               "set":3,
               "occurrence":1,
               "count":33.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":32.444447
            },
            {
               "set":3,
               "occurrence":2,
               "count":22.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":14.666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":8,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":8,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":5,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"defense",
         "secondaryCounts":{
            "48":1,
            "1":16,
            "49":4,
            "17":2,
            "18":2,
            "5":9,
            "53":6,
            "55":7,
            "56":1,
            "41":3,
            "42":2,
            "28":8
         },
         "secondaryMultipliers":{
            "48":0.0625,
            "1":1.0,
            "49":0.25,
            "17":0.125,
            "18":0.125,
            "5":0.5625,
            "53":0.375,
            "55":0.4375,
            "56":0.0625,
            "41":0.1875,
            "42":0.125,
            "28":0.5
         },
         "secondaryTypeCounts":{
            "Speed":9,
            "Critical Chance":6,
            "Health":23,
            "Potency":2,
            "Offense":4,
            "Tenacity":2,
            "Protection":9,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":0.39130434,
            "Critical Chance":0.26086956,
            "Health":1.0,
            "Potency":0.08695652,
            "Offense":0.17391305,
            "Tenacity":0.08695652,
            "Protection":0.39130434,
            "Defense":0.26086956
         }
      },
      {
         "name":"JAWA",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "49":0.06666667,
               "52":0.033333335,
               "5":1.0,
               "54":0.2,
               "55":0.1,
               "56":0.23333333
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.3529412,
               "16":0.64705884,
               "49":0.11764706,
               "53":0.47058824,
               "55":0.29411766,
               "56":1.0
            },
            "5":{
               "55":0.75,
               "56":1.0
            },
            "6":{
               "48":0.73333335,
               "17":0.33333334,
               "49":0.26666668,
               "18":0.46666667,
               "55":0.46666667,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "49":2,
               "52":1,
               "5":30,
               "54":6,
               "55":3,
               "56":7
            },
            "3":{
               "49":49
            },
            "4":{
               "48":6,
               "16":11,
               "49":2,
               "53":8,
               "55":5,
               "56":17
            },
            "5":{
               "55":21,
               "56":28
            },
            "6":{
               "48":11,
               "17":5,
               "49":4,
               "18":7,
               "55":7,
               "56":15
            }
         },
         "fourSetsMultiplier":0.8148148,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":48,
            "2":8,
            "3":7,
            "4":7,
            "5":11,
            "6":7,
            "7":14,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0,
            "3":0.14583333,
            "4":0.875,
            "5":0.22916667,
            "6":0.875,
            "7":0.29166666,
            "8":0.041666668
         },
         "twoSetMax":101.333336,
         "twoSetCounts":{
            "1":48,
            "3":7,
            "5":11,
            "7":14,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":48.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":32.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":21.333334
            },
            {
               "set":7,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":5,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":3,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":3,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":3,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":2,
            "1":3,
            "49":5,
            "17":2,
            "18":4,
            "5":14,
            "53":2,
            "55":1,
            "56":2,
            "41":8,
            "42":1,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.14285715,
            "1":0.21428572,
            "49":0.35714287,
            "17":0.14285715,
            "18":0.2857143,
            "5":1.0,
            "53":0.14285715,
            "55":0.071428575,
            "56":0.14285715,
            "41":0.5714286,
            "42":0.071428575,
            "28":0.2857143
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":2,
            "Health":4,
            "Potency":2,
            "Offense":10,
            "Tenacity":4,
            "Protection":6,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.14285715,
            "Health":0.2857143,
            "Potency":0.14285715,
            "Offense":0.71428573,
            "Tenacity":0.2857143,
            "Protection":0.42857143,
            "Defense":0.42857143
         }
      },
      {
         "name":"POE",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.06666667,
               "56":0.044444446
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.90909094,
               "48":0.8181818,
               "49":0.90909094,
               "53":0.54545456,
               "55":0.36363637,
               "56":1.0
            },
            "5":{
               "55":0.47058824,
               "56":1.0
            },
            "6":{
               "48":0.14814815,
               "17":1.0,
               "49":0.11111111,
               "18":0.25925925,
               "55":0.037037037,
               "56":0.2962963
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":45,
               "54":3,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":10,
               "48":9,
               "49":10,
               "53":6,
               "55":4,
               "56":11
            },
            "5":{
               "55":16,
               "56":34
            },
            "6":{
               "48":4,
               "17":27,
               "49":3,
               "18":7,
               "55":1,
               "56":8
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.47058824,
         "setCounts":{
            "1":18,
            "2":1,
            "3":5,
            "4":32,
            "5":4,
            "6":1,
            "7":23,
            "8":7
         },
         "fourSetMultipliers":{
            "1":0.7826087,
            "2":0.03125,
            "3":0.2173913,
            "4":1.0,
            "5":0.17391305,
            "6":0.03125,
            "7":1.0,
            "8":0.3043478
         },
         "twoSetMax":56.333332,
         "twoSetCounts":{
            "1":18,
            "3":5,
            "5":4,
            "7":23,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":23.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":15.333333
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":10.222222
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":5,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "17":3,
            "49":2,
            "1":2,
            "18":7,
            "5":12,
            "53":1,
            "55":4,
            "56":2,
            "41":3,
            "42":7,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.33333334,
            "17":0.25,
            "49":0.16666667,
            "1":0.16666667,
            "18":0.5833333,
            "5":1.0,
            "53":0.083333336,
            "55":0.33333334,
            "56":0.16666667,
            "41":0.25,
            "42":0.5833333,
            "28":0.083333336
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":1,
            "Health":6,
            "Potency":3,
            "Offense":7,
            "Tenacity":7,
            "Protection":3,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.083333336,
            "Health":0.5,
            "Potency":0.25,
            "Offense":0.5833333,
            "Tenacity":0.5833333,
            "Protection":0.25,
            "Defense":0.75
         }
      },
      {
         "name":"IMPERIALPROBEDROID",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "52":0.022727273,
               "5":1.0,
               "54":0.045454547,
               "55":0.045454547,
               "56":0.022727273
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.47058824,
               "16":0.29411766,
               "49":0.23529412,
               "53":0.3529412,
               "55":0.5882353,
               "56":1.0
            },
            "5":{
               "55":0.7241379,
               "56":1.0
            },
            "6":{
               "48":0.05882353,
               "17":1.0,
               "49":0.0882353,
               "18":0.05882353,
               "55":0.11764706,
               "56":0.14705883
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "52":1,
               "5":44,
               "54":2,
               "55":2,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "48":8,
               "16":5,
               "49":4,
               "53":6,
               "55":10,
               "56":17
            },
            "5":{
               "55":21,
               "56":29
            },
            "6":{
               "48":2,
               "17":34,
               "49":3,
               "18":2,
               "55":4,
               "56":5
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.2195122,
         "setCounts":{
            "1":21,
            "2":1,
            "3":2,
            "4":40,
            "5":2,
            "6":0,
            "7":39
         },
         "fourSetMultipliers":{
            "1":0.53846157,
            "2":0.025,
            "3":0.051282052,
            "4":1.0,
            "5":0.051282052,
            "6":0.0,
            "7":1.0
         },
         "twoSetMax":86.0,
         "twoSetCounts":{
            "1":21,
            "3":2,
            "5":2,
            "7":39
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":39.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":26.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":17.333334
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "17":8,
            "1":2,
            "49":1,
            "18":5,
            "5":15,
            "53":2,
            "55":3,
            "56":2,
            "41":4,
            "42":2,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.2,
            "17":0.53333336,
            "1":0.13333334,
            "49":0.06666667,
            "18":0.33333334,
            "5":1.0,
            "53":0.13333334,
            "55":0.2,
            "56":0.13333334,
            "41":0.26666668,
            "42":0.13333334,
            "28":0.26666668
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":2,
            "Health":5,
            "Potency":8,
            "Offense":7,
            "Tenacity":5,
            "Protection":6,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.13333334,
            "Health":0.33333334,
            "Potency":0.53333336,
            "Offense":0.46666667,
            "Tenacity":0.33333334,
            "Protection":0.4,
            "Defense":0.2
         }
      },
      {
         "name":"CHIEFCHIRPA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.02173913,
               "49":0.02173913,
               "5":1.0,
               "54":0.02173913,
               "55":0.02173913
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.3125,
               "49":0.125,
               "53":1.0,
               "55":0.375,
               "56":0.3125
            },
            "5":{
               "55":0.7241379,
               "56":1.0
            },
            "6":{
               "48":0.5833333,
               "17":0.25,
               "49":1.0,
               "18":0.6666667,
               "55":0.41666666,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "49":1,
               "5":46,
               "54":1,
               "55":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":16,
               "48":5,
               "49":2,
               "53":16,
               "55":6,
               "56":5
            },
            "5":{
               "55":21,
               "56":29
            },
            "6":{
               "48":7,
               "17":3,
               "49":12,
               "18":8,
               "55":5,
               "56":12
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.25,
         "setCounts":{
            "1":24,
            "2":2,
            "3":6,
            "4":31,
            "5":21,
            "6":7,
            "7":3,
            "8":3
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.06451613,
            "3":0.25,
            "4":1.0,
            "5":0.875,
            "6":0.22580644,
            "7":0.125,
            "8":0.125
         },
         "twoSetMax":61.0,
         "twoSetCounts":{
            "1":24,
            "3":6,
            "5":21,
            "7":3,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":24.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":16.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":10.666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":3,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "1":2,
            "49":1,
            "17":7,
            "18":2,
            "53":8,
            "5":12,
            "55":4,
            "56":3,
            "41":4,
            "42":8,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.083333336,
            "1":0.16666667,
            "49":0.083333336,
            "17":0.5833333,
            "18":0.16666667,
            "53":0.6666667,
            "5":1.0,
            "55":0.33333334,
            "56":0.25,
            "41":0.33333334,
            "42":0.6666667,
            "28":0.41666666
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":8,
            "Health":6,
            "Potency":7,
            "Offense":5,
            "Tenacity":2,
            "Protection":8,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.6666667,
            "Health":0.5,
            "Potency":0.5833333,
            "Offense":0.41666666,
            "Tenacity":0.16666667,
            "Protection":0.6666667,
            "Defense":0.75
         }
      },
      {
         "name":"VISASMARR",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.102564104,
               "5":1.0,
               "54":0.051282052,
               "55":0.051282052,
               "56":0.051282052
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.72727275,
               "16":1.0,
               "49":0.36363637,
               "53":0.90909094,
               "55":0.72727275,
               "56":0.54545456
            },
            "5":{
               "55":1.0,
               "56":0.96
            },
            "6":{
               "48":1.0,
               "17":0.5,
               "49":0.083333336,
               "18":1.0,
               "55":0.6666667,
               "56":0.75
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":4,
               "5":39,
               "54":2,
               "55":2,
               "56":2
            },
            "3":{
               "49":48
            },
            "4":{
               "48":8,
               "16":11,
               "49":4,
               "53":10,
               "55":8,
               "56":6
            },
            "5":{
               "55":25,
               "56":24
            },
            "6":{
               "48":12,
               "17":6,
               "49":1,
               "18":12,
               "55":8,
               "56":9
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.8148148,
         "setCounts":{
            "1":34,
            "2":3,
            "3":6,
            "4":15,
            "5":9,
            "6":9,
            "7":5,
            "8":14
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.2,
            "3":0.1764706,
            "4":1.0,
            "5":0.2647059,
            "6":0.6,
            "7":0.14705883,
            "8":0.4117647
         },
         "twoSetMax":71.77777,
         "twoSetCounts":{
            "1":34,
            "3":6,
            "5":9,
            "7":5,
            "8":14
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":34.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":22.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":15.111111
            },
            {
               "set":8,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":5,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "49":1,
            "1":6,
            "17":4,
            "18":5,
            "5":14,
            "53":4,
            "55":5,
            "56":1,
            "41":9,
            "42":1,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.071428575,
            "49":0.071428575,
            "1":0.42857143,
            "17":0.2857143,
            "18":0.35714287,
            "5":1.0,
            "53":0.2857143,
            "55":0.35714287,
            "56":0.071428575,
            "41":0.64285713,
            "42":0.071428575,
            "28":0.21428572
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":4,
            "Health":11,
            "Potency":4,
            "Offense":10,
            "Tenacity":5,
            "Protection":4,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.2857143,
            "Health":0.78571427,
            "Potency":0.2857143,
            "Offense":0.71428573,
            "Tenacity":0.35714287,
            "Protection":0.2857143,
            "Defense":0.14285715
         }
      },
      {
         "name":"LUKESKYWALKER",
         "count":46,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.05263158,
               "49":0.02631579,
               "5":1.0,
               "54":0.02631579,
               "55":0.05263158,
               "56":0.02631579
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.75,
               "49":0.16666667,
               "53":0.9166667,
               "55":0.41666666,
               "56":0.41666666
            },
            "5":{
               "55":0.9130435,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.53333336,
               "49":0.2,
               "18":0.4,
               "55":0.53333336,
               "56":0.26666668
            }
         },
         "primaryCounts":{
            "1":{
               "48":45
            },
            "2":{
               "48":2,
               "49":1,
               "5":38,
               "54":1,
               "55":2,
               "56":1
            },
            "3":{
               "49":44
            },
            "4":{
               "16":12,
               "48":9,
               "49":2,
               "53":11,
               "55":5,
               "56":5
            },
            "5":{
               "55":21,
               "56":23
            },
            "6":{
               "48":15,
               "17":8,
               "49":3,
               "18":6,
               "55":8,
               "56":4
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.64285713,
         "setCounts":{
            "1":27,
            "2":4,
            "3":2,
            "4":8,
            "5":18,
            "6":16,
            "7":6,
            "8":4
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.25,
            "3":0.074074075,
            "4":0.5,
            "5":0.6666667,
            "6":1.0,
            "7":0.22222222,
            "8":0.14814815
         },
         "twoSetMax":63.0,
         "twoSetCounts":{
            "1":27,
            "3":2,
            "5":18,
            "7":6,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":27.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":18.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "17":3,
            "1":4,
            "49":4,
            "18":4,
            "5":11,
            "53":2,
            "55":2,
            "56":2,
            "41":7,
            "42":3,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.27272728,
            "17":0.27272728,
            "1":0.36363637,
            "49":0.36363637,
            "18":0.36363637,
            "5":1.0,
            "53":0.18181819,
            "55":0.18181819,
            "56":0.18181819,
            "41":0.6363636,
            "42":0.27272728,
            "28":0.45454547
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":2,
            "Health":6,
            "Potency":3,
            "Offense":10,
            "Tenacity":4,
            "Protection":7,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.18181819,
            "Health":0.54545456,
            "Potency":0.27272728,
            "Offense":0.90909094,
            "Tenacity":0.36363637,
            "Protection":0.6363636,
            "Defense":0.6363636
         }
      },
      {
         "name":"GAMORREANGUARD",
         "count":45,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.07692308,
               "52":0.07692308,
               "5":1.0,
               "54":0.26923078,
               "55":0.07692308,
               "56":0.1923077
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.9,
               "48":0.5,
               "49":0.4,
               "53":1.0,
               "55":0.5,
               "56":1.0
            },
            "5":{
               "55":1.0,
               "56":0.9130435
            },
            "6":{
               "48":0.16666667,
               "17":1.0,
               "49":0.5,
               "18":0.6666667,
               "55":0.5,
               "56":0.5833333
            }
         },
         "primaryCounts":{
            "1":{
               "48":44
            },
            "2":{
               "48":2,
               "52":2,
               "5":26,
               "54":7,
               "55":2,
               "56":5
            },
            "3":{
               "49":44
            },
            "4":{
               "16":9,
               "48":5,
               "49":4,
               "53":10,
               "55":5,
               "56":10
            },
            "5":{
               "55":23,
               "56":21
            },
            "6":{
               "48":2,
               "17":12,
               "49":6,
               "18":8,
               "55":6,
               "56":7
            }
         },
         "fourSetsMultiplier":0.125,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":43,
            "2":2,
            "3":10,
            "4":2,
            "5":3,
            "6":1,
            "7":19,
            "8":15
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0,
            "3":0.23255815,
            "4":1.0,
            "5":0.069767445,
            "6":0.5,
            "7":0.44186047,
            "8":0.3488372
         },
         "twoSetMax":90.77777,
         "twoSetCounts":{
            "1":43,
            "3":10,
            "5":3,
            "7":19,
            "8":15
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":43.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":28.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":19.11111
            },
            {
               "set":7,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":3,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":8,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":3,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":5,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":4,
            "1":5,
            "49":4,
            "17":7,
            "18":5,
            "5":8,
            "53":3,
            "55":6,
            "56":1,
            "41":1,
            "42":5,
            "28":10
         },
         "secondaryMultipliers":{
            "48":0.4,
            "1":0.5,
            "49":0.4,
            "17":0.7,
            "18":0.5,
            "5":0.8,
            "53":0.3,
            "55":0.6,
            "56":0.1,
            "41":0.1,
            "42":0.5,
            "28":1.0
         },
         "secondaryTypeCounts":{
            "Speed":8,
            "Critical Chance":3,
            "Health":11,
            "Potency":7,
            "Offense":5,
            "Tenacity":5,
            "Protection":11,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":0.72727275,
            "Critical Chance":0.27272728,
            "Health":1.0,
            "Potency":0.6363636,
            "Offense":0.45454547,
            "Tenacity":0.45454547,
            "Protection":1.0,
            "Defense":0.8181818
         }
      },
      {
         "name":"KITFISTO",
         "count":46,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.11111111,
               "49":0.027777778,
               "5":1.0,
               "54":0.083333336,
               "55":0.027777778,
               "56":0.027777778
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.4375,
               "49":0.1875,
               "53":0.5625,
               "55":0.375,
               "56":0.25
            },
            "5":{
               "55":0.8,
               "56":1.0
            },
            "6":{
               "48":0.44444445,
               "49":0.5555556,
               "17":0.8888889,
               "18":0.8888889,
               "55":1.0,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":46
            },
            "2":{
               "48":4,
               "49":1,
               "5":36,
               "54":3,
               "55":1,
               "56":1
            },
            "3":{
               "49":44
            },
            "4":{
               "16":16,
               "48":7,
               "49":3,
               "53":9,
               "55":6,
               "56":4
            },
            "5":{
               "55":20,
               "56":25
            },
            "6":{
               "48":4,
               "49":5,
               "17":8,
               "18":8,
               "55":9,
               "56":9
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.9166667,
         "setCounts":{
            "1":37,
            "2":4,
            "3":1,
            "4":7,
            "5":12,
            "6":13,
            "7":7,
            "8":8
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.30769232,
            "3":0.027027028,
            "4":0.53846157,
            "5":0.3243243,
            "6":1.0,
            "7":0.1891892,
            "8":0.21621622
         },
         "twoSetMax":78.11111,
         "twoSetCounts":{
            "1":37,
            "3":1,
            "5":12,
            "7":7,
            "8":8
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":37.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":24.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":16.444445
            },
            {
               "set":5,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "49":3,
            "17":1,
            "1":4,
            "18":6,
            "5":11,
            "53":8,
            "55":4,
            "56":3,
            "41":1,
            "42":3,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.27272728,
            "49":0.27272728,
            "17":0.09090909,
            "1":0.36363637,
            "18":0.54545456,
            "5":1.0,
            "53":0.72727275,
            "55":0.36363637,
            "56":0.27272728,
            "41":0.09090909,
            "42":0.27272728,
            "28":0.18181819
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":8,
            "Health":8,
            "Potency":1,
            "Offense":4,
            "Tenacity":6,
            "Protection":5,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.72727275,
            "Health":0.72727275,
            "Potency":0.09090909,
            "Offense":0.36363637,
            "Tenacity":0.54545456,
            "Protection":0.45454547,
            "Defense":0.54545456
         }
      },
      {
         "name":"ADMINISTRATORLANDO",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.1,
               "5":1.0,
               "54":0.025,
               "55":0.025,
               "56":0.075
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.8888889,
               "48":0.44444445,
               "49":0.055555556,
               "53":1.0,
               "55":0.055555556,
               "56":0.2777778
            },
            "5":{
               "55":0.44117647,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "49":0.26666668,
               "17":0.6,
               "18":0.53333336,
               "55":0.26666668,
               "56":0.53333336
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":4,
               "5":40,
               "54":1,
               "55":1,
               "56":3
            },
            "3":{
               "49":49
            },
            "4":{
               "16":16,
               "48":8,
               "49":1,
               "53":18,
               "55":1,
               "56":5
            },
            "5":{
               "55":15,
               "56":34
            },
            "6":{
               "48":15,
               "49":4,
               "17":9,
               "18":8,
               "55":4,
               "56":8
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.44117647,
         "setCounts":{
            "1":10,
            "2":7,
            "3":2,
            "4":8,
            "5":44,
            "6":19,
            "8":4
         },
         "fourSetMultipliers":{
            "1":0.22727273,
            "2":0.36842105,
            "3":0.045454547,
            "4":0.42105263,
            "5":1.0,
            "6":1.0,
            "8":0.09090909
         },
         "twoSetMax":92.88889,
         "twoSetCounts":{
            "1":10,
            "3":2,
            "5":44,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":44.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":29.333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":19.555555
            },
            {
               "set":1,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":3,
            "17":4,
            "49":2,
            "18":5,
            "5":9,
            "53":1,
            "55":8,
            "56":4,
            "41":1,
            "42":4,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.22222222,
            "1":0.33333334,
            "17":0.44444445,
            "49":0.22222222,
            "18":0.5555556,
            "5":1.0,
            "53":0.11111111,
            "55":0.8888889,
            "56":0.44444445,
            "41":0.11111111,
            "42":0.44444445,
            "28":0.5555556
         },
         "secondaryTypeCounts":{
            "Speed":9,
            "Critical Chance":1,
            "Health":11,
            "Potency":4,
            "Offense":3,
            "Tenacity":5,
            "Protection":9,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":0.8181818,
            "Critical Chance":0.09090909,
            "Health":1.0,
            "Potency":0.36363637,
            "Offense":0.27272728,
            "Tenacity":0.45454547,
            "Protection":0.8181818,
            "Defense":0.54545456
         }
      },
      {
         "name":"ADMIRALPIETT",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.8125,
               "48":1.0,
               "49":0.25,
               "53":0.5625,
               "55":0.3125,
               "56":0.1875
            },
            "5":{
               "55":1.0,
               "56":0.6666667
            },
            "6":{
               "48":1.0,
               "17":1.0,
               "49":0.2,
               "18":0.6,
               "55":0.33333334,
               "56":0.2
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "16":13,
               "48":16,
               "49":4,
               "53":9,
               "55":5,
               "56":3
            },
            "5":{
               "55":30,
               "56":20
            },
            "6":{
               "48":15,
               "17":15,
               "49":3,
               "18":9,
               "55":5,
               "56":3
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":15,
            "2":0,
            "3":3,
            "4":49,
            "5":1,
            "6":1,
            "7":18,
            "8":7
         },
         "fourSetMultipliers":{
            "1":0.8333333,
            "2":0.0,
            "3":0.16666667,
            "4":1.0,
            "5":0.055555556,
            "6":0.020408163,
            "7":1.0,
            "8":0.3888889
         },
         "twoSetMax":45.0,
         "twoSetCounts":{
            "1":15,
            "3":3,
            "5":1,
            "7":18,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":2,
            "17":3,
            "49":2,
            "18":2,
            "5":18,
            "53":4,
            "55":4,
            "56":2,
            "41":5,
            "42":4,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.11111111,
            "1":0.11111111,
            "17":0.16666667,
            "49":0.11111111,
            "18":0.11111111,
            "5":1.0,
            "53":0.22222222,
            "55":0.22222222,
            "56":0.11111111,
            "41":0.2777778,
            "42":0.22222222,
            "28":0.11111111
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":4,
            "Health":6,
            "Potency":3,
            "Offense":7,
            "Tenacity":2,
            "Protection":4,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.22222222,
            "Health":0.33333334,
            "Potency":0.16666667,
            "Offense":0.3888889,
            "Tenacity":0.11111111,
            "Protection":0.22222222,
            "Defense":0.33333334
         }
      },
      {
         "name":"NIGHTSISTERSPIRIT",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.020408163,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.40625,
               "53":0.125,
               "55":0.03125
            },
            "5":{
               "55":1.0,
               "56":0.78571427
            },
            "6":{
               "48":1.0,
               "17":0.25714287,
               "18":0.08571429,
               "55":0.08571429
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "5":49
            },
            "3":{
               "49":50
            },
            "4":{
               "16":32,
               "48":13,
               "53":4,
               "55":1
            },
            "5":{
               "55":28,
               "56":22
            },
            "6":{
               "48":35,
               "17":9,
               "18":3,
               "55":3
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.041666668,
         "setCounts":{
            "1":9,
            "2":22,
            "3":1,
            "4":22,
            "5":22,
            "6":4,
            "7":11,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.4090909,
            "2":1.0,
            "3":0.045454547,
            "4":1.0,
            "5":1.0,
            "6":0.18181819,
            "7":0.5,
            "8":0.045454547
         },
         "twoSetMax":47.666668,
         "twoSetCounts":{
            "1":9,
            "3":1,
            "5":22,
            "7":11,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":22.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.777778
            },
            {
               "set":1,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":1,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":6,
            "17":1,
            "49":3,
            "18":2,
            "5":19,
            "53":5,
            "55":1,
            "56":2,
            "41":2,
            "42":3,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.21052632,
            "1":0.31578946,
            "17":0.05263158,
            "49":0.15789473,
            "18":0.10526316,
            "5":1.0,
            "53":0.2631579,
            "55":0.05263158,
            "56":0.10526316,
            "41":0.10526316,
            "42":0.15789473,
            "28":0.10526316
         },
         "secondaryTypeCounts":{
            "Speed":19,
            "Critical Chance":5,
            "Health":7,
            "Potency":1,
            "Offense":6,
            "Tenacity":2,
            "Protection":4,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.2631579,
            "Health":0.36842105,
            "Potency":0.05263158,
            "Offense":0.31578946,
            "Tenacity":0.10526316,
            "Protection":0.21052632,
            "Defense":0.31578946
         }
      },
      {
         "name":"CLONESERGEANTPHASEI",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.15789473,
               "5":1.0,
               "54":0.05263158,
               "55":0.05263158,
               "56":0.05263158
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.15151516,
               "49":0.030303031,
               "53":0.24242425,
               "55":0.060606062,
               "56":0.030303031
            },
            "5":{
               "55":0.6666667,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.3181818,
               "49":0.045454547,
               "18":0.27272728,
               "55":0.22727273,
               "56":0.4090909
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":6,
               "5":38,
               "54":2,
               "55":2,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":33,
               "48":5,
               "49":1,
               "53":8,
               "55":2,
               "56":1
            },
            "5":{
               "55":20,
               "56":30
            },
            "6":{
               "48":22,
               "17":7,
               "49":1,
               "18":6,
               "55":5,
               "56":9
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.42857143,
         "setCounts":{
            "1":13,
            "2":14,
            "3":1,
            "4":1,
            "5":20,
            "6":20,
            "7":12,
            "8":6
         },
         "fourSetMultipliers":{
            "1":0.65,
            "2":0.7,
            "3":0.05,
            "4":0.05,
            "5":1.0,
            "6":1.0,
            "7":0.6,
            "8":0.3
         },
         "twoSetMax":46.333332,
         "twoSetCounts":{
            "1":13,
            "3":1,
            "5":20,
            "7":12,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":20.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":13.333333
            },
            {
               "set":1,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":8.888888
            },
            {
               "set":1,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":7,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":7,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "1":4,
            "17":1,
            "49":3,
            "18":4,
            "5":17,
            "53":1,
            "55":3,
            "56":8,
            "41":6,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.05882353,
            "1":0.23529412,
            "17":0.05882353,
            "49":0.1764706,
            "18":0.23529412,
            "5":1.0,
            "53":0.05882353,
            "55":0.1764706,
            "56":0.47058824,
            "41":0.3529412,
            "42":0.11764706,
            "28":0.11764706
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":1,
            "Health":7,
            "Potency":1,
            "Offense":7,
            "Tenacity":4,
            "Protection":10,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.05882353,
            "Health":0.4117647,
            "Potency":0.05882353,
            "Offense":0.4117647,
            "Tenacity":0.23529412,
            "Protection":0.5882353,
            "Defense":0.29411766
         }
      },
      {
         "name":"FULCRUMAHSOKA",
         "count":48,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.045454547,
               "49":0.022727273,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.23076923,
               "49":0.07692308,
               "53":0.1923077,
               "55":0.07692308,
               "56":0.1923077
            },
            "5":{
               "55":0.56666666,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "49":0.6923077,
               "17":0.07692308,
               "18":0.7692308,
               "55":0.3846154,
               "56":0.7692308
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "48":2,
               "49":1,
               "5":44
            },
            "3":{
               "49":48
            },
            "4":{
               "16":26,
               "48":6,
               "49":2,
               "53":5,
               "55":2,
               "56":5
            },
            "5":{
               "55":17,
               "56":30
            },
            "6":{
               "48":13,
               "49":9,
               "17":1,
               "18":10,
               "55":5,
               "56":10
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.2631579,
         "setCounts":{
            "1":25,
            "2":7,
            "3":3,
            "4":7,
            "5":22,
            "6":24,
            "7":0,
            "8":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.29166666,
            "3":0.12,
            "4":0.29166666,
            "5":0.88,
            "6":1.0,
            "7":0.0,
            "8":0.04
         },
         "twoSetMax":63.666664,
         "twoSetCounts":{
            "1":25,
            "3":3,
            "5":22,
            "7":0,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":25.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":22.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":16.666666
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.666667
            },
            {
               "set":1,
               "occurrence":3,
               "count":11.111111
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.777778
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":7,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":4,
            "49":3,
            "17":8,
            "18":3,
            "5":13,
            "53":2,
            "55":6,
            "56":4,
            "41":2,
            "42":3,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.30769232,
            "1":0.30769232,
            "49":0.23076923,
            "17":0.61538464,
            "18":0.23076923,
            "5":1.0,
            "53":0.15384616,
            "55":0.46153846,
            "56":0.30769232,
            "41":0.15384616,
            "42":0.23076923,
            "28":0.15384616
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":2,
            "Health":10,
            "Potency":8,
            "Offense":6,
            "Tenacity":3,
            "Protection":6,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.15384616,
            "Health":0.7692308,
            "Potency":0.61538464,
            "Offense":0.46153846,
            "Tenacity":0.23076923,
            "Protection":0.46153846,
            "Defense":0.46153846
         }
      },
      {
         "name":"JANGOFETT",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.61290324,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.3888889
            },
            "5":{
               "55":0.78571427,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.06382979
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":19,
               "5":31
            },
            "3":{
               "49":50
            },
            "4":{
               "16":36,
               "48":14
            },
            "5":{
               "55":22,
               "56":28
            },
            "6":{
               "48":47,
               "17":3
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.06382979,
         "setCounts":{
            "1":8,
            "2":17,
            "4":1,
            "5":31,
            "6":29,
            "7":7
         },
         "fourSetMultipliers":{
            "1":0.2580645,
            "2":0.5862069,
            "4":0.03448276,
            "5":1.0,
            "6":1.0,
            "7":0.22580644
         },
         "twoSetMax":65.44444,
         "twoSetCounts":{
            "1":8,
            "5":31,
            "7":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":31.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":20.666666
            },
            {
               "set":5,
               "occurrence":3,
               "count":13.777778
            },
            {
               "set":1,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":1,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.111111
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "17":3,
            "49":1,
            "1":1,
            "18":1,
            "5":18,
            "53":6,
            "55":4,
            "56":1,
            "41":5,
            "42":2,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.16666667,
            "17":0.16666667,
            "49":0.055555556,
            "1":0.055555556,
            "18":0.055555556,
            "5":1.0,
            "53":0.33333334,
            "55":0.22222222,
            "56":0.055555556,
            "41":0.2777778,
            "42":0.11111111,
            "28":0.16666667
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":6,
            "Health":5,
            "Potency":3,
            "Offense":8,
            "Tenacity":1,
            "Protection":4,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.33333334,
            "Health":0.2777778,
            "Potency":0.16666667,
            "Offense":0.44444445,
            "Tenacity":0.055555556,
            "Protection":0.22222222,
            "Defense":0.16666667
         }
      },
      {
         "name":"IG88",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.0952381,
               "5":1.0,
               "54":0.071428575,
               "55":0.023809524
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.68421054,
               "49":0.10526316,
               "53":0.5263158,
               "55":0.10526316,
               "56":0.21052632
            },
            "5":{
               "55":0.78571427,
               "56":1.0
            },
            "6":{
               "48":0.75,
               "17":1.0,
               "49":0.0625,
               "18":0.4375,
               "55":0.25,
               "56":0.625
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":4,
               "5":42,
               "54":3,
               "55":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":19,
               "48":13,
               "49":2,
               "53":10,
               "55":2,
               "56":4
            },
            "5":{
               "55":22,
               "56":28
            },
            "6":{
               "48":12,
               "17":16,
               "49":1,
               "18":7,
               "55":4,
               "56":10
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.47058824,
         "setCounts":{
            "1":17,
            "2":3,
            "3":1,
            "4":11,
            "5":21,
            "6":20,
            "7":7,
            "8":3
         },
         "fourSetMultipliers":{
            "1":0.8095238,
            "2":0.15,
            "3":0.04761905,
            "4":0.55,
            "5":1.0,
            "6":1.0,
            "7":0.33333334,
            "8":0.14285715
         },
         "twoSetMax":52.0,
         "twoSetCounts":{
            "1":17,
            "3":1,
            "5":21,
            "7":7,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":7,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "17":2,
            "49":9,
            "1":1,
            "18":5,
            "5":14,
            "53":3,
            "55":2,
            "56":2,
            "41":3,
            "42":5,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.071428575,
            "17":0.14285715,
            "49":0.64285713,
            "1":0.071428575,
            "18":0.35714287,
            "5":1.0,
            "53":0.21428572,
            "55":0.14285715,
            "56":0.14285715,
            "41":0.21428572,
            "42":0.35714287,
            "28":0.14285715
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":3,
            "Health":3,
            "Potency":2,
            "Offense":4,
            "Tenacity":5,
            "Protection":4,
            "Defense":14
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.21428572,
            "Health":0.21428572,
            "Potency":0.14285715,
            "Offense":0.2857143,
            "Tenacity":0.35714287,
            "Protection":0.2857143,
            "Defense":1.0
         }
      },
      {
         "name":"REY",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.14285715,
               "5":1.0,
               "54":0.04761905
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.28125,
               "53":0.09375,
               "55":0.09375,
               "56":0.09375
            },
            "5":{
               "55":0.7241379,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.14814815,
               "49":0.11111111,
               "18":0.25925925,
               "55":0.14814815,
               "56":0.18518518
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":6,
               "5":42,
               "54":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":32,
               "48":9,
               "53":3,
               "55":3,
               "56":3
            },
            "5":{
               "55":21,
               "56":29
            },
            "6":{
               "48":27,
               "17":4,
               "49":3,
               "18":7,
               "55":4,
               "56":5
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.08695652,
         "setCounts":{
            "1":13,
            "2":13,
            "3":2,
            "4":4,
            "5":25,
            "6":29,
            "7":2,
            "8":8
         },
         "fourSetMultipliers":{
            "1":0.52,
            "2":0.44827586,
            "3":0.08,
            "4":0.13793103,
            "5":1.0,
            "6":1.0,
            "7":0.08,
            "8":0.32
         },
         "twoSetMax":54.666664,
         "twoSetCounts":{
            "1":13,
            "3":2,
            "5":25,
            "7":2,
            "8":8
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":25.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":16.666666
            },
            {
               "set":1,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":11.111111
            },
            {
               "set":1,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":8,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":8,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":1,
            "1":5,
            "17":4,
            "18":6,
            "5":12,
            "53":1,
            "55":2,
            "56":6,
            "41":10,
            "42":3,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.16666667,
            "49":0.083333336,
            "1":0.41666666,
            "17":0.33333334,
            "18":0.5,
            "5":1.0,
            "53":0.083333336,
            "55":0.16666667,
            "56":0.5,
            "41":0.8333333,
            "42":0.25,
            "28":0.25
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":1,
            "Health":7,
            "Potency":4,
            "Offense":12,
            "Tenacity":6,
            "Protection":9,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.083333336,
            "Health":0.5833333,
            "Potency":0.33333334,
            "Offense":1.0,
            "Tenacity":0.5,
            "Protection":0.75,
            "Defense":0.33333334
         }
      },
      {
         "name":"DARTHNIHILUS",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "55":0.021276595,
               "56":0.04255319
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.07692308,
               "49":0.115384616,
               "53":0.07692308,
               "55":1.0,
               "56":0.65384614
            },
            "5":{
               "55":1.0,
               "56":0.61290324
            },
            "6":{
               "48":0.05,
               "17":0.5,
               "49":0.05,
               "18":0.35,
               "55":0.55,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "5":47,
               "55":1,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":2,
               "49":3,
               "53":2,
               "55":26,
               "56":17
            },
            "5":{
               "55":31,
               "56":19
            },
            "6":{
               "48":1,
               "17":10,
               "49":1,
               "18":7,
               "55":11,
               "56":20
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.35135135,
         "setCounts":{
            "1":47,
            "3":4,
            "4":37,
            "5":1,
            "6":0,
            "7":14,
            "8":4
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.08510638,
            "4":1.0,
            "5":0.021276595,
            "6":0.0,
            "7":0.29787233,
            "8":0.08510638
         },
         "twoSetMax":99.22223,
         "twoSetCounts":{
            "1":47,
            "3":4,
            "5":1,
            "7":14,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":47.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":31.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":20.88889
            },
            {
               "set":7,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":7,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "1":2,
            "17":2,
            "49":4,
            "18":4,
            "53":6,
            "5":15,
            "55":10,
            "56":3,
            "41":4,
            "42":2,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.06666667,
            "1":0.13333334,
            "17":0.13333334,
            "49":0.26666668,
            "18":0.26666668,
            "53":0.4,
            "5":1.0,
            "55":0.6666667,
            "56":0.2,
            "41":0.26666668,
            "42":0.13333334,
            "28":0.33333334
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":6,
            "Health":12,
            "Potency":2,
            "Offense":5,
            "Tenacity":4,
            "Protection":8,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.4,
            "Health":0.8,
            "Potency":0.13333334,
            "Offense":0.33333334,
            "Tenacity":0.26666668,
            "Protection":0.53333336,
            "Defense":0.4
         }
      },
      {
         "name":"EMPERORPALPATINE",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.47619048,
               "48":0.3809524,
               "49":0.14285715,
               "53":0.23809524,
               "55":0.14285715,
               "56":1.0
            },
            "5":{
               "55":0.3888889,
               "56":1.0
            },
            "6":{
               "17":1.0,
               "56":0.06382979
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "16":10,
               "48":8,
               "49":3,
               "53":5,
               "55":3,
               "56":21
            },
            "5":{
               "55":14,
               "56":36
            },
            "6":{
               "17":47,
               "56":3
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.2820513,
         "setCounts":{
            "1":4,
            "2":4,
            "3":0,
            "4":35,
            "5":5,
            "7":62
         },
         "fourSetMultipliers":{
            "1":0.06451613,
            "2":0.114285715,
            "3":0.0,
            "4":1.0,
            "5":0.08064516,
            "7":1.0
         },
         "twoSetMax":130.88889,
         "twoSetCounts":{
            "1":4,
            "3":0,
            "5":5,
            "7":62
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":62.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":41.333332
            },
            {
               "set":7,
               "occurrence":3,
               "count":27.555555
            },
            {
               "set":5,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":1,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":1,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "17":5,
            "1":4,
            "49":1,
            "18":3,
            "5":19,
            "53":4,
            "55":2,
            "56":1,
            "41":3,
            "42":4,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.15789473,
            "17":0.2631579,
            "1":0.21052632,
            "49":0.05263158,
            "18":0.15789473,
            "5":1.0,
            "53":0.21052632,
            "55":0.10526316,
            "56":0.05263158,
            "41":0.15789473,
            "42":0.21052632,
            "28":0.15789473
         },
         "secondaryTypeCounts":{
            "Speed":19,
            "Critical Chance":4,
            "Health":6,
            "Potency":5,
            "Offense":6,
            "Tenacity":3,
            "Protection":4,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.21052632,
            "Health":0.31578946,
            "Potency":0.2631579,
            "Offense":0.31578946,
            "Tenacity":0.15789473,
            "Protection":0.21052632,
            "Defense":0.2631579
         }
      },
      {
         "name":"HOTHHAN",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.04761905,
               "49":0.023809524,
               "5":1.0,
               "54":0.023809524,
               "55":0.023809524,
               "56":0.023809524
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.5833333,
               "48":0.25,
               "49":0.75,
               "53":0.33333334,
               "55":1.0,
               "56":0.9166667
            },
            "5":{
               "55":1.0,
               "56":0.88
            },
            "6":{
               "48":0.07692308,
               "17":0.6923077,
               "49":0.3846154,
               "18":0.6923077,
               "55":1.0,
               "56":0.7692308
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":2,
               "49":1,
               "5":42,
               "54":1,
               "55":1,
               "56":1
            },
            "3":{
               "49":48
            },
            "4":{
               "16":7,
               "48":3,
               "49":9,
               "53":4,
               "55":12,
               "56":11
            },
            "5":{
               "55":25,
               "56":22
            },
            "6":{
               "48":1,
               "17":9,
               "49":5,
               "18":9,
               "55":13,
               "56":10
            }
         },
         "fourSetsMultiplier":0.88461536,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":67,
            "2":0,
            "3":8,
            "4":22,
            "5":1,
            "6":1,
            "7":4,
            "8":4
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.11940298,
            "4":1.0,
            "5":0.014925373,
            "6":0.045454547,
            "7":0.05970149,
            "8":0.05970149
         },
         "twoSetMax":141.44446,
         "twoSetCounts":{
            "1":67,
            "3":8,
            "5":1,
            "7":4,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":67.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":44.666668
            },
            {
               "set":1,
               "occurrence":3,
               "count":29.777779
            },
            {
               "set":3,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":4,
            "49":4,
            "17":6,
            "1":5,
            "18":4,
            "5":14,
            "53":2,
            "55":2,
            "56":2,
            "41":1,
            "42":6,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.2857143,
            "49":0.2857143,
            "17":0.42857143,
            "1":0.35714287,
            "18":0.2857143,
            "5":1.0,
            "53":0.14285715,
            "55":0.14285715,
            "56":0.14285715,
            "41":0.071428575,
            "42":0.42857143,
            "28":0.2857143
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":2,
            "Health":7,
            "Potency":6,
            "Offense":5,
            "Tenacity":4,
            "Protection":6,
            "Defense":10
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.14285715,
            "Health":0.5,
            "Potency":0.42857143,
            "Offense":0.35714287,
            "Tenacity":0.2857143,
            "Protection":0.42857143,
            "Defense":0.71428573
         }
      },
      {
         "name":"PLOKOON",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.07692308,
               "49":0.07692308,
               "52":0.03846154,
               "5":1.0,
               "54":0.46153846,
               "55":0.15384616,
               "56":0.115384616
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.6666667,
               "16":0.9166667,
               "49":0.5833333,
               "53":0.6666667,
               "55":0.33333334,
               "56":1.0
            },
            "5":{
               "55":0.8148148,
               "56":1.0
            },
            "6":{
               "48":0.54545456,
               "17":1.0,
               "49":0.27272728,
               "18":1.0,
               "55":0.8181818,
               "56":0.90909094
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":2,
               "49":2,
               "52":1,
               "5":26,
               "54":12,
               "55":4,
               "56":3
            },
            "3":{
               "49":50
            },
            "4":{
               "48":8,
               "16":11,
               "49":7,
               "53":8,
               "55":4,
               "56":12
            },
            "5":{
               "55":22,
               "56":27
            },
            "6":{
               "48":6,
               "17":11,
               "49":3,
               "18":11,
               "55":9,
               "56":10
            }
         },
         "fourSetsMultiplier":0.9230769,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":40,
            "2":1,
            "3":7,
            "4":20,
            "5":4,
            "6":3,
            "7":5,
            "8":8
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.05,
            "3":0.175,
            "4":1.0,
            "5":0.1,
            "6":0.15,
            "7":0.125,
            "8":0.2
         },
         "twoSetMax":84.44444,
         "twoSetCounts":{
            "1":40,
            "3":7,
            "5":4,
            "7":5,
            "8":8
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":40.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":26.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":17.777777
            },
            {
               "set":8,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":5,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":5,
            "17":2,
            "49":1,
            "1":2,
            "18":1,
            "5":11,
            "53":2,
            "55":8,
            "56":3,
            "41":6,
            "42":8,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.45454547,
            "17":0.18181819,
            "49":0.09090909,
            "1":0.18181819,
            "18":0.09090909,
            "5":1.0,
            "53":0.18181819,
            "55":0.72727275,
            "56":0.27272728,
            "41":0.54545456,
            "42":0.72727275,
            "28":0.27272728
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":2,
            "Health":10,
            "Potency":2,
            "Offense":11,
            "Tenacity":1,
            "Protection":6,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.18181819,
            "Health":0.90909094,
            "Potency":0.18181819,
            "Offense":1.0,
            "Tenacity":0.09090909,
            "Protection":0.54545456,
            "Defense":0.8181818
         }
      },
      {
         "name":"LOGRAY",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.045454547,
               "5":1.0,
               "56":0.06818182
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.46666667,
               "49":0.53333336,
               "53":0.4,
               "55":0.6,
               "56":0.26666668
            },
            "5":{
               "55":0.88461536,
               "56":1.0
            },
            "6":{
               "48":0.1764706,
               "17":1.0,
               "49":0.3529412,
               "18":0.3529412,
               "55":0.29411766,
               "56":0.64705884
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "48":2,
               "5":44,
               "56":3
            },
            "3":{
               "49":49
            },
            "4":{
               "16":15,
               "48":7,
               "49":8,
               "53":6,
               "55":9,
               "56":4
            },
            "5":{
               "55":23,
               "56":26
            },
            "6":{
               "48":3,
               "17":17,
               "49":6,
               "18":6,
               "55":5,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.58064514,
         "setCounts":{
            "1":13,
            "2":0,
            "3":5,
            "4":30,
            "5":15,
            "6":1,
            "7":33,
            "8":4
         },
         "fourSetMultipliers":{
            "1":0.3939394,
            "2":0.0,
            "3":0.15151516,
            "4":1.0,
            "5":0.45454547,
            "6":0.033333335,
            "7":1.0,
            "8":0.121212125
         },
         "twoSetMax":70.0,
         "twoSetCounts":{
            "1":13,
            "3":5,
            "5":15,
            "7":33,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":33.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":22.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":14.666667
            },
            {
               "set":1,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":1,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "49":1,
            "17":7,
            "1":9,
            "18":3,
            "53":3,
            "5":14,
            "55":2,
            "56":5,
            "41":1,
            "42":2,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.35714287,
            "49":0.071428575,
            "17":0.5,
            "1":0.64285713,
            "18":0.21428572,
            "53":0.21428572,
            "5":1.0,
            "55":0.14285715,
            "56":0.35714287,
            "41":0.071428575,
            "42":0.14285715,
            "28":0.21428572
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":3,
            "Health":11,
            "Potency":7,
            "Offense":6,
            "Tenacity":3,
            "Protection":8,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.21428572,
            "Health":0.78571427,
            "Potency":0.5,
            "Offense":0.42857143,
            "Tenacity":0.21428572,
            "Protection":0.5714286,
            "Defense":0.21428572
         }
      },
      {
         "name":"HK47",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.8518519,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.6896552,
               "48":1.0,
               "49":0.03448276
            },
            "5":{
               "55":0.5151515,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "56":0.06382979
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":23,
               "5":27
            },
            "3":{
               "49":50
            },
            "4":{
               "16":20,
               "48":29,
               "49":1
            },
            "5":{
               "55":17,
               "56":33
            },
            "6":{
               "48":47,
               "56":3
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.020408163,
         "setCounts":{
            "1":10,
            "2":49,
            "3":0,
            "4":0,
            "5":32,
            "6":0,
            "7":2,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.3125,
            "2":1.0,
            "3":0.0,
            "4":0.0,
            "5":1.0,
            "6":0.0,
            "7":0.0625,
            "8":0.03125
         },
         "twoSetMax":67.55556,
         "twoSetCounts":{
            "1":10,
            "3":0,
            "5":32,
            "7":2,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":32.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":21.333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":14.222222
            },
            {
               "set":1,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "17":1,
            "1":7,
            "49":1,
            "18":2,
            "5":18,
            "53":4,
            "55":6,
            "56":2,
            "41":5,
            "42":1,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.055555556,
            "17":0.055555556,
            "1":0.3888889,
            "49":0.055555556,
            "18":0.11111111,
            "5":1.0,
            "53":0.22222222,
            "55":0.33333334,
            "56":0.11111111,
            "41":0.2777778,
            "42":0.055555556,
            "28":0.33333334
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":4,
            "Health":13,
            "Potency":1,
            "Offense":6,
            "Tenacity":2,
            "Protection":8,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.22222222,
            "Health":0.7222222,
            "Potency":0.055555556,
            "Offense":0.33333334,
            "Tenacity":0.11111111,
            "Protection":0.44444445,
            "Defense":0.11111111
         }
      },
      {
         "name":"BAZEMALBUS",
         "count":48,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "49":0.09090909,
               "52":0.045454547,
               "5":1.0,
               "54":0.3181818,
               "55":0.13636364,
               "56":0.59090906
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.1,
               "49":0.2,
               "55":0.3,
               "56":1.0
            },
            "5":{
               "55":0.71428573,
               "56":1.0
            },
            "6":{
               "48":0.045454547,
               "17":0.045454547,
               "49":0.4090909,
               "18":0.22727273,
               "55":0.45454547,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "49":2,
               "52":1,
               "5":22,
               "54":7,
               "55":3,
               "56":13
            },
            "3":{
               "49":48
            },
            "4":{
               "16":3,
               "49":6,
               "55":9,
               "56":30
            },
            "5":{
               "55":20,
               "56":28
            },
            "6":{
               "48":1,
               "17":1,
               "49":9,
               "18":5,
               "55":10,
               "56":22
            }
         },
         "fourSetsMultiplier":0.06666667,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":100,
            "2":0,
            "3":17,
            "4":3,
            "5":0,
            "6":0,
            "7":4,
            "8":10
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.17,
            "4":1.0,
            "5":0.0,
            "6":0.0,
            "7":0.04,
            "8":0.1
         },
         "twoSetMax":211.1111,
         "twoSetCounts":{
            "1":100,
            "3":17,
            "5":0,
            "7":4,
            "8":10
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":100.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":66.666664
            },
            {
               "set":1,
               "occurrence":3,
               "count":44.444443
            },
            {
               "set":3,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":8,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":8,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":8,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":7,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":4,
            "17":5,
            "1":12,
            "49":1,
            "18":3,
            "5":9,
            "53":6,
            "55":4,
            "56":1,
            "41":1,
            "42":1,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.33333334,
            "17":0.41666666,
            "1":1.0,
            "49":0.083333336,
            "18":0.25,
            "5":0.75,
            "53":0.5,
            "55":0.33333334,
            "56":0.083333336,
            "41":0.083333336,
            "42":0.083333336,
            "28":0.5
         },
         "secondaryTypeCounts":{
            "Speed":9,
            "Critical Chance":6,
            "Health":16,
            "Potency":5,
            "Offense":5,
            "Tenacity":3,
            "Protection":7,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":0.5625,
            "Critical Chance":0.375,
            "Health":1.0,
            "Potency":0.3125,
            "Offense":0.3125,
            "Tenacity":0.1875,
            "Protection":0.4375,
            "Defense":0.125
         }
      },
      {
         "name":"CLONEWARSCHEWBACCA",
         "count":47,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.045454547,
               "5":1.0,
               "54":0.36363637,
               "55":0.22727273,
               "56":0.4090909
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.14285715,
               "48":0.14285715,
               "49":0.3809524,
               "53":0.1904762,
               "55":0.2857143,
               "56":1.0
            },
            "5":{
               "55":0.875,
               "56":1.0
            },
            "6":{
               "48":0.10526316,
               "49":0.31578946,
               "17":0.21052632,
               "18":0.42105263,
               "55":0.36842105,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":44
            },
            "2":{
               "48":1,
               "5":22,
               "54":8,
               "55":5,
               "56":9
            },
            "3":{
               "49":47
            },
            "4":{
               "16":3,
               "48":3,
               "49":8,
               "53":4,
               "55":6,
               "56":21
            },
            "5":{
               "55":21,
               "56":24
            },
            "6":{
               "48":2,
               "49":6,
               "17":4,
               "18":8,
               "55":7,
               "56":19
            }
         },
         "fourSetsMultiplier":0.06818182,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":68,
            "3":28,
            "4":2,
            "5":2,
            "6":1,
            "7":5,
            "8":13
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.4117647,
            "4":1.0,
            "5":0.029411765,
            "6":0.5,
            "7":0.073529415,
            "8":0.19117647
         },
         "twoSetMax":143.55554,
         "twoSetCounts":{
            "1":68,
            "3":28,
            "5":2,
            "7":5,
            "8":13
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":68.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":45.333332
            },
            {
               "set":1,
               "occurrence":3,
               "count":30.222221
            },
            {
               "set":3,
               "occurrence":1,
               "count":28.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":18.666666
            },
            {
               "set":8,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":12.444444
            },
            {
               "set":8,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":5,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":3,
            "49":1,
            "1":9,
            "17":4,
            "18":3,
            "5":3,
            "53":1,
            "55":2,
            "56":8,
            "41":7,
            "42":6,
            "28":12
         },
         "secondaryMultipliers":{
            "48":0.25,
            "49":0.083333336,
            "1":0.75,
            "17":0.33333334,
            "18":0.25,
            "5":0.25,
            "53":0.083333336,
            "55":0.16666667,
            "56":0.6666667,
            "41":0.5833333,
            "42":0.5,
            "28":1.0
         },
         "secondaryTypeCounts":{
            "Speed":3,
            "Critical Chance":1,
            "Health":11,
            "Potency":4,
            "Offense":10,
            "Tenacity":3,
            "Protection":20,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":0.15,
            "Critical Chance":0.05,
            "Health":0.55,
            "Potency":0.2,
            "Offense":0.5,
            "Tenacity":0.15,
            "Protection":1.0,
            "Defense":0.35
         }
      },
      {
         "name":"KYLORENUNMASKED",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":0.15151516,
               "54":0.36363637,
               "55":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "55":1.0,
               "56":0.020408163
            },
            "5":{
               "55":1.0
            },
            "6":{
               "55":1.0,
               "56":0.020833334
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":5,
               "54":12,
               "55":33
            },
            "3":{
               "49":50
            },
            "4":{
               "55":49,
               "56":1
            },
            "5":{
               "55":50
            },
            "6":{
               "55":48,
               "56":1
            }
         },
         "fourSetsMultiplier":0.020408163,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":116,
            "3":31,
            "4":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.2672414,
            "4":1.0
         },
         "twoSetMax":244.8889,
         "twoSetCounts":{
            "1":116,
            "3":31
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":116.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":77.333336
            },
            {
               "set":1,
               "occurrence":3,
               "count":51.555557
            },
            {
               "set":3,
               "occurrence":1,
               "count":31.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":20.666666
            },
            {
               "set":3,
               "occurrence":3,
               "count":13.777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":3,
            "49":4,
            "1":10,
            "17":2,
            "18":2,
            "5":7,
            "53":3,
            "55":8,
            "56":1,
            "41":4,
            "42":4,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.3,
            "49":0.4,
            "1":1.0,
            "17":0.2,
            "18":0.2,
            "5":0.7,
            "53":0.3,
            "55":0.8,
            "56":0.1,
            "41":0.4,
            "42":0.4,
            "28":0.3
         },
         "secondaryTypeCounts":{
            "Speed":7,
            "Critical Chance":3,
            "Health":18,
            "Potency":2,
            "Offense":7,
            "Tenacity":2,
            "Protection":4,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":0.3888889,
            "Critical Chance":0.16666667,
            "Health":1.0,
            "Potency":0.11111111,
            "Offense":0.3888889,
            "Tenacity":0.11111111,
            "Protection":0.22222222,
            "Defense":0.44444445
         }
      },
      {
         "name":"YOUNGLANDO",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.13513513,
               "49":0.027027028,
               "5":1.0,
               "54":0.13513513,
               "56":0.027027028
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.39130434,
               "49":0.17391305,
               "53":0.3478261,
               "55":0.13043478,
               "56":0.13043478
            },
            "5":{
               "55":0.75,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.85714287,
               "49":0.42857143,
               "18":0.35714287,
               "55":0.35714287,
               "56":0.5714286
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":5,
               "49":1,
               "5":37,
               "54":5,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":23,
               "48":9,
               "49":4,
               "53":8,
               "55":3,
               "56":3
            },
            "5":{
               "55":21,
               "56":28
            },
            "6":{
               "48":14,
               "17":12,
               "49":6,
               "18":5,
               "55":5,
               "56":8
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.6666667,
         "setCounts":{
            "1":18,
            "2":11,
            "3":2,
            "4":9,
            "5":21,
            "6":10,
            "7":6,
            "8":6
         },
         "fourSetMultipliers":{
            "1":0.85714287,
            "2":1.0,
            "3":0.0952381,
            "4":0.8181818,
            "5":1.0,
            "6":0.90909094,
            "7":0.2857143,
            "8":0.2857143
         },
         "twoSetMax":53.0,
         "twoSetCounts":{
            "1":18,
            "3":2,
            "5":21,
            "7":6,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "1":1,
            "17":2,
            "49":2,
            "18":4,
            "5":12,
            "53":5,
            "55":3,
            "56":7,
            "41":7,
            "42":4,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.41666666,
            "1":0.083333336,
            "17":0.16666667,
            "49":0.16666667,
            "18":0.33333334,
            "5":1.0,
            "53":0.41666666,
            "55":0.25,
            "56":0.5833333,
            "41":0.5833333,
            "42":0.33333334,
            "28":0.083333336
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":5,
            "Health":4,
            "Potency":2,
            "Offense":12,
            "Tenacity":4,
            "Protection":8,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.41666666,
            "Health":0.33333334,
            "Potency":0.16666667,
            "Offense":1.0,
            "Tenacity":0.33333334,
            "Protection":0.6666667,
            "Defense":0.5
         }
      },
      {
         "name":"SMUGGLERHAN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.09090909,
               "5":1.0,
               "54":0.045454547
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.19354838,
               "53":0.16129032,
               "55":0.12903225,
               "56":0.12903225
            },
            "5":{
               "55":0.5151515,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.8333333,
               "49":0.055555556,
               "18":0.16666667,
               "55":0.22222222,
               "56":0.5
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":4,
               "5":44,
               "54":2
            },
            "3":{
               "49":49
            },
            "4":{
               "16":31,
               "48":6,
               "53":5,
               "55":4,
               "56":4
            },
            "5":{
               "55":17,
               "56":33
            },
            "6":{
               "48":18,
               "17":15,
               "49":1,
               "18":3,
               "55":4,
               "56":9
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.1627907,
         "setCounts":{
            "1":11,
            "2":10,
            "3":4,
            "4":12,
            "5":18,
            "6":21,
            "7":11,
            "8":4
         },
         "fourSetMultipliers":{
            "1":0.6111111,
            "2":0.47619048,
            "3":0.22222222,
            "4":0.5714286,
            "5":1.0,
            "6":1.0,
            "7":0.6111111,
            "8":0.22222222
         },
         "twoSetMax":41.0,
         "twoSetCounts":{
            "1":11,
            "3":4,
            "5":18,
            "7":11,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":7,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":1,
            "17":3,
            "49":6,
            "18":9,
            "5":9,
            "53":5,
            "55":2,
            "56":4,
            "41":4,
            "42":1,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.44444445,
            "1":0.11111111,
            "17":0.33333334,
            "49":0.6666667,
            "18":1.0,
            "5":1.0,
            "53":0.5555556,
            "55":0.22222222,
            "56":0.44444445,
            "41":0.44444445,
            "42":0.11111111,
            "28":0.5555556
         },
         "secondaryTypeCounts":{
            "Speed":9,
            "Critical Chance":5,
            "Health":3,
            "Potency":3,
            "Offense":8,
            "Tenacity":9,
            "Protection":9,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.5555556,
            "Health":0.33333334,
            "Potency":0.33333334,
            "Offense":0.8888889,
            "Tenacity":1.0,
            "Protection":1.0,
            "Defense":0.7777778
         }
      },
      {
         "name":"THEMANDALORIAN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.2820513,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.09756097,
               "53":0.12195122
            },
            "5":{
               "55":0.61290324,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.022727273,
               "18":0.06818182,
               "55":0.022727273,
               "56":0.022727273
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":11,
               "5":39
            },
            "3":{
               "49":50
            },
            "4":{
               "16":41,
               "48":4,
               "53":5
            },
            "5":{
               "55":19,
               "56":31
            },
            "6":{
               "48":44,
               "17":1,
               "18":3,
               "55":1,
               "56":1
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.08695652,
         "setCounts":{
            "1":9,
            "2":9,
            "4":2,
            "5":45,
            "6":35,
            "7":1
         },
         "fourSetMultipliers":{
            "1":0.2,
            "2":0.25714287,
            "4":0.057142857,
            "5":1.0,
            "6":1.0,
            "7":0.022222223
         },
         "twoSetMax":95.0,
         "twoSetCounts":{
            "1":9,
            "5":45,
            "7":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":45.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":30.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":20.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "49":5,
            "1":3,
            "17":2,
            "18":2,
            "5":19,
            "53":2,
            "55":3,
            "56":3,
            "41":4,
            "42":5,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.2631579,
            "49":0.2631579,
            "1":0.15789473,
            "17":0.10526316,
            "18":0.10526316,
            "5":1.0,
            "53":0.10526316,
            "55":0.15789473,
            "56":0.15789473,
            "41":0.21052632,
            "42":0.2631579,
            "28":0.15789473
         },
         "secondaryTypeCounts":{
            "Speed":19,
            "Critical Chance":2,
            "Health":6,
            "Potency":2,
            "Offense":9,
            "Tenacity":2,
            "Protection":6,
            "Defense":10
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.10526316,
            "Health":0.31578946,
            "Potency":0.10526316,
            "Offense":0.47368422,
            "Tenacity":0.10526316,
            "Protection":0.31578946,
            "Defense":0.5263158
         }
      },
      {
         "name":"UGNAUGHT",
         "count":46,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.0625,
               "49":0.03125,
               "52":0.0625,
               "5":1.0,
               "54":0.03125,
               "55":0.0625,
               "56":0.125
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.53846157,
               "49":0.30769232,
               "53":0.7692308,
               "55":0.3846154,
               "56":0.46153846
            },
            "5":{
               "55":0.6923077,
               "56":1.0
            },
            "6":{
               "48":0.5,
               "17":1.0,
               "49":0.35714287,
               "18":0.5,
               "55":0.42857143,
               "56":0.42857143
            }
         },
         "primaryCounts":{
            "1":{
               "48":44
            },
            "2":{
               "48":2,
               "49":1,
               "52":2,
               "5":32,
               "54":1,
               "55":2,
               "56":4
            },
            "3":{
               "49":45
            },
            "4":{
               "16":13,
               "48":7,
               "49":4,
               "53":10,
               "55":5,
               "56":6
            },
            "5":{
               "55":18,
               "56":26
            },
            "6":{
               "48":7,
               "17":14,
               "49":5,
               "18":7,
               "55":6,
               "56":6
            }
         },
         "fourSetsMultiplier":0.9166667,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":22,
            "2":2,
            "3":7,
            "4":15,
            "5":8,
            "6":5,
            "7":14,
            "8":3
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.13333334,
            "3":0.3181818,
            "4":1.0,
            "5":0.36363637,
            "6":0.33333334,
            "7":0.6363636,
            "8":0.13636364
         },
         "twoSetMax":50.666668,
         "twoSetCounts":{
            "1":22,
            "3":7,
            "5":8,
            "7":14,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":22.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.777778
            },
            {
               "set":7,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":5,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":5,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":3,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"potency",
         "secondaryCounts":{
            "48":2,
            "49":4,
            "1":1,
            "17":1,
            "18":3,
            "5":15,
            "53":5,
            "55":4,
            "56":4,
            "41":2,
            "42":3,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.13333334,
            "49":0.26666668,
            "1":0.06666667,
            "17":0.06666667,
            "18":0.2,
            "5":1.0,
            "53":0.33333334,
            "55":0.26666668,
            "56":0.26666668,
            "41":0.13333334,
            "42":0.2,
            "28":0.4
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":5,
            "Health":5,
            "Potency":1,
            "Offense":4,
            "Tenacity":3,
            "Protection":10,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.33333334,
            "Health":0.33333334,
            "Potency":0.06666667,
            "Offense":0.26666668,
            "Tenacity":0.2,
            "Protection":0.6666667,
            "Defense":0.46666667
         }
      },
      {
         "name":"SMUGGLERCHEWBACCA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.15384616,
               "49":0.025641026,
               "5":1.0,
               "55":0.025641026,
               "56":0.051282052
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.5217391,
               "16":1.0,
               "49":0.04347826,
               "53":0.2173913,
               "55":0.13043478,
               "56":0.17391305
            },
            "5":{
               "55":0.8518519,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.64285713,
               "49":0.35714287,
               "18":0.5714286,
               "55":0.2857143,
               "56":0.5714286
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "48":6,
               "49":1,
               "5":39,
               "55":1,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "48":12,
               "16":23,
               "49":1,
               "53":5,
               "55":3,
               "56":4
            },
            "5":{
               "55":23,
               "56":27
            },
            "6":{
               "48":14,
               "17":9,
               "49":5,
               "18":8,
               "55":4,
               "56":8
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.47058824,
         "setCounts":{
            "1":20,
            "2":20,
            "3":1,
            "4":4,
            "5":21,
            "6":10,
            "7":11,
            "8":4
         },
         "fourSetMultipliers":{
            "1":0.95238096,
            "2":1.0,
            "3":0.04761905,
            "4":0.2,
            "5":1.0,
            "6":0.5,
            "7":0.52380955,
            "8":0.1904762
         },
         "twoSetMax":55.0,
         "twoSetCounts":{
            "1":20,
            "3":1,
            "5":21,
            "7":11,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":20.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":13.333333
            },
            {
               "set":7,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.888888
            },
            {
               "set":7,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":9,
            "17":1,
            "49":2,
            "18":5,
            "5":11,
            "53":4,
            "55":3,
            "56":2,
            "41":6,
            "42":3,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.18181819,
            "1":0.8181818,
            "17":0.09090909,
            "49":0.18181819,
            "18":0.45454547,
            "5":1.0,
            "53":0.36363637,
            "55":0.27272728,
            "56":0.18181819,
            "41":0.54545456,
            "42":0.27272728,
            "28":0.27272728
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":4,
            "Health":12,
            "Potency":1,
            "Offense":8,
            "Tenacity":5,
            "Protection":5,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":0.9166667,
            "Critical Chance":0.33333334,
            "Health":1.0,
            "Potency":0.083333336,
            "Offense":0.6666667,
            "Tenacity":0.41666666,
            "Protection":0.41666666,
            "Defense":0.41666666
         }
      },
      {
         "name":"HUMANTHUG",
         "count":44,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.030303031,
               "49":0.030303031,
               "5":1.0,
               "54":0.121212125,
               "55":0.09090909,
               "56":0.060606062
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.4,
               "49":0.6,
               "53":1.0,
               "55":0.4,
               "56":0.8
            },
            "5":{
               "55":0.8695652,
               "56":1.0
            },
            "6":{
               "48":0.09090909,
               "49":0.36363637,
               "17":0.72727275,
               "18":1.0,
               "55":0.90909094,
               "56":0.8181818
            }
         },
         "primaryCounts":{
            "1":{
               "48":43
            },
            "2":{
               "48":1,
               "49":1,
               "5":33,
               "54":4,
               "55":3,
               "56":2
            },
            "3":{
               "49":43
            },
            "4":{
               "16":10,
               "48":4,
               "49":6,
               "53":10,
               "55":4,
               "56":8
            },
            "5":{
               "55":20,
               "56":23
            },
            "6":{
               "48":1,
               "49":4,
               "17":8,
               "18":11,
               "55":10,
               "56":9
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.76,
         "setCounts":{
            "1":21,
            "2":2,
            "3":9,
            "4":17,
            "5":2,
            "6":6,
            "7":17,
            "8":5
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.11764706,
            "3":0.42857143,
            "4":1.0,
            "5":0.0952381,
            "6":0.3529412,
            "7":0.8095238,
            "8":0.23809524
         },
         "twoSetMax":52.0,
         "twoSetCounts":{
            "1":21,
            "3":9,
            "5":2,
            "7":17,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":3,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":3,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":5,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "1":8,
            "17":5,
            "49":1,
            "18":2,
            "5":14,
            "53":1,
            "55":2,
            "56":5,
            "41":6,
            "42":1,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.21428572,
            "1":0.5714286,
            "17":0.35714287,
            "49":0.071428575,
            "18":0.14285715,
            "5":1.0,
            "53":0.071428575,
            "55":0.14285715,
            "56":0.35714287,
            "41":0.42857143,
            "42":0.071428575,
            "28":0.14285715
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":1,
            "Health":10,
            "Potency":5,
            "Offense":9,
            "Tenacity":2,
            "Protection":7,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.071428575,
            "Health":0.71428573,
            "Potency":0.35714287,
            "Offense":0.64285713,
            "Tenacity":0.14285715,
            "Protection":0.5,
            "Defense":0.14285715
         }
      },
      {
         "name":"CORUSCANTUNDERWORLDPOLICE",
         "count":46,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.08571429,
               "49":0.028571429,
               "5":1.0,
               "54":0.114285715,
               "55":0.057142857
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":1.0,
               "16":0.45454547,
               "49":0.8181818,
               "53":0.8181818,
               "55":0.36363637,
               "56":0.45454547
            },
            "5":{
               "55":0.9130435,
               "56":1.0
            },
            "6":{
               "48":0.35714287,
               "17":1.0,
               "49":0.071428575,
               "18":0.21428572,
               "55":0.9285714,
               "56":0.5714286
            }
         },
         "primaryCounts":{
            "1":{
               "48":46
            },
            "2":{
               "48":3,
               "49":1,
               "5":35,
               "54":4,
               "55":2
            },
            "3":{
               "49":46
            },
            "4":{
               "48":11,
               "16":5,
               "49":9,
               "53":9,
               "55":4,
               "56":5
            },
            "5":{
               "55":21,
               "56":23
            },
            "6":{
               "48":5,
               "17":14,
               "49":1,
               "18":3,
               "55":13,
               "56":8
            }
         },
         "fourSetsMultiplier":0.5862069,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":30,
            "2":3,
            "3":5,
            "4":13,
            "5":12,
            "6":1,
            "7":20,
            "8":4
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.23076923,
            "3":0.16666667,
            "4":1.0,
            "5":0.4,
            "6":0.07692308,
            "7":0.6666667,
            "8":0.13333334
         },
         "twoSetMax":70.0,
         "twoSetCounts":{
            "1":30,
            "3":5,
            "5":12,
            "7":20,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":30.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":20.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":20.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":13.333333
            },
            {
               "set":7,
               "occurrence":2,
               "count":13.333333
            },
            {
               "set":5,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":8.888888
            },
            {
               "set":5,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"potency",
         "secondaryCounts":{
            "48":2,
            "49":3,
            "1":1,
            "17":3,
            "18":1,
            "5":10,
            "53":5,
            "55":8,
            "56":1,
            "41":7,
            "42":8,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.2,
            "49":0.3,
            "1":0.1,
            "17":0.3,
            "18":0.1,
            "5":1.0,
            "53":0.5,
            "55":0.8,
            "56":0.1,
            "41":0.7,
            "42":0.8,
            "28":0.2
         },
         "secondaryTypeCounts":{
            "Speed":10,
            "Critical Chance":5,
            "Health":9,
            "Potency":3,
            "Offense":9,
            "Tenacity":1,
            "Protection":3,
            "Defense":11
         },
         "secondaryTypeMultipliers":{
            "Speed":0.90909094,
            "Critical Chance":0.45454547,
            "Health":0.8181818,
            "Potency":0.27272728,
            "Offense":0.8181818,
            "Tenacity":0.09090909,
            "Protection":0.27272728,
            "Defense":1.0
         }
      },
      {
         "name":"MOTHERTALZIN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.020408163,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.8181818,
               "49":0.045454547,
               "53":0.22727273,
               "55":0.13636364,
               "56":0.045454547
            },
            "5":{
               "55":1.0,
               "56":0.6666667
            },
            "6":{
               "48":1.0,
               "17":1.0,
               "18":0.045454547,
               "55":0.13636364,
               "56":0.09090909
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "5":49
            },
            "3":{
               "49":50
            },
            "4":{
               "16":22,
               "48":18,
               "49":1,
               "53":5,
               "55":3,
               "56":1
            },
            "5":{
               "55":30,
               "56":20
            },
            "6":{
               "48":22,
               "17":22,
               "18":1,
               "55":3,
               "56":2
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.041666668,
         "setCounts":{
            "1":9,
            "2":37,
            "4":10,
            "5":14,
            "6":1,
            "7":27,
            "8":0
         },
         "fourSetMultipliers":{
            "1":0.33333334,
            "2":1.0,
            "4":0.27027026,
            "5":0.5185185,
            "6":0.027027028,
            "7":1.0,
            "8":0.0
         },
         "twoSetMax":59.0,
         "twoSetCounts":{
            "1":9,
            "5":14,
            "7":27,
            "8":0
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":27.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":18.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":1,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "1":1,
            "49":6,
            "17":4,
            "18":3,
            "5":17,
            "53":3,
            "55":2,
            "56":2,
            "41":3,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.1764706,
            "1":0.05882353,
            "49":0.3529412,
            "17":0.23529412,
            "18":0.1764706,
            "5":1.0,
            "53":0.1764706,
            "55":0.11764706,
            "56":0.11764706,
            "41":0.1764706,
            "42":0.11764706,
            "28":0.11764706
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":3,
            "Health":3,
            "Potency":4,
            "Offense":6,
            "Tenacity":3,
            "Protection":4,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.1764706,
            "Health":0.1764706,
            "Potency":0.23529412,
            "Offense":0.3529412,
            "Tenacity":0.1764706,
            "Protection":0.23529412,
            "Defense":0.47058824
         }
      },
      {
         "name":"BIGGSDARKLIGHTER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.26666668,
               "49":0.033333335,
               "52":0.033333335,
               "5":1.0,
               "54":0.23333333,
               "55":0.033333335,
               "56":0.06666667
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.16666667,
               "49":0.16666667,
               "53":0.13333334,
               "55":0.06666667,
               "56":0.13333334
            },
            "5":{
               "55":0.6666667,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "49":0.23529412,
               "17":0.1764706,
               "18":0.7058824,
               "55":0.29411766,
               "56":0.5294118
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":8,
               "49":1,
               "52":1,
               "5":30,
               "54":7,
               "55":1,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":30,
               "48":5,
               "49":5,
               "53":4,
               "55":2,
               "56":4
            },
            "5":{
               "55":20,
               "56":30
            },
            "6":{
               "48":17,
               "49":4,
               "17":3,
               "18":12,
               "55":5,
               "56":9
            }
         },
         "fourSetsMultiplier":0.6666667,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":22,
            "2":1,
            "3":6,
            "4":4,
            "5":29,
            "6":15,
            "7":3,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.7586207,
            "2":0.06666667,
            "3":0.20689656,
            "4":0.26666668,
            "5":1.0,
            "6":1.0,
            "7":0.10344828,
            "8":0.03448276
         },
         "twoSetMax":70.333336,
         "twoSetCounts":{
            "1":22,
            "3":6,
            "5":29,
            "7":3,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":29.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":22.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":19.333334
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":12.888889
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.777778
            },
            {
               "set":3,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critChance",
         "commonSet2Name":"health",
         "commonSet3Name":"critChance",
         "secondaryCounts":{
            "48":4,
            "1":4,
            "49":3,
            "17":7,
            "18":4,
            "5":10,
            "53":8,
            "55":2,
            "56":2,
            "41":1,
            "42":1,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.4,
            "1":0.4,
            "49":0.3,
            "17":0.7,
            "18":0.4,
            "5":1.0,
            "53":0.8,
            "55":0.2,
            "56":0.2,
            "41":0.1,
            "42":0.1,
            "28":0.4
         },
         "secondaryTypeCounts":{
            "Speed":10,
            "Critical Chance":8,
            "Health":6,
            "Potency":7,
            "Offense":5,
            "Tenacity":4,
            "Protection":6,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.8,
            "Health":0.6,
            "Potency":0.7,
            "Offense":0.5,
            "Tenacity":0.4,
            "Protection":0.6,
            "Defense":0.4
         }
      },
      {
         "name":"CC2224",
         "count":48,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.02173913,
               "55":0.02173913
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.33333334,
               "49":0.16666667,
               "53":0.7222222,
               "55":0.16666667,
               "56":0.22222222
            },
            "5":{
               "55":0.71428573,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.85714287,
               "49":0.5714286,
               "18":0.21428572,
               "55":0.21428572,
               "56":0.5714286
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "5":46,
               "54":1,
               "55":1
            },
            "3":{
               "49":47
            },
            "4":{
               "16":18,
               "48":6,
               "49":3,
               "53":13,
               "55":3,
               "56":4
            },
            "5":{
               "55":20,
               "56":28
            },
            "6":{
               "48":14,
               "17":12,
               "49":8,
               "18":3,
               "55":3,
               "56":8
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.33333334,
         "setCounts":{
            "1":15,
            "2":6,
            "3":4,
            "4":23,
            "5":19,
            "6":7,
            "7":11,
            "8":5
         },
         "fourSetMultipliers":{
            "1":0.7894737,
            "2":0.26086956,
            "3":0.21052632,
            "4":1.0,
            "5":1.0,
            "6":0.3043478,
            "7":0.57894737,
            "8":0.2631579
         },
         "twoSetMax":46.666668,
         "twoSetCounts":{
            "1":15,
            "3":4,
            "5":19,
            "7":11,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":7,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":1,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":7,
            "17":2,
            "49":4,
            "1":1,
            "18":4,
            "5":12,
            "53":9,
            "55":2,
            "56":1,
            "41":1,
            "42":5,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.5833333,
            "17":0.16666667,
            "49":0.33333334,
            "1":0.083333336,
            "18":0.33333334,
            "5":1.0,
            "53":0.75,
            "55":0.16666667,
            "56":0.083333336,
            "41":0.083333336,
            "42":0.41666666,
            "28":0.25
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":9,
            "Health":3,
            "Potency":2,
            "Offense":8,
            "Tenacity":4,
            "Protection":4,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.75,
            "Health":0.25,
            "Potency":0.16666667,
            "Offense":0.6666667,
            "Tenacity":0.33333334,
            "Protection":0.33333334,
            "Defense":0.75
         }
      },
      {
         "name":"ZAMWESELL",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.04255319,
               "5":1.0,
               "54":0.021276595
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.5555556,
               "49":0.16666667,
               "53":0.5,
               "55":0.16666667,
               "56":0.33333334
            },
            "5":{
               "55":0.75,
               "56":1.0
            },
            "6":{
               "48":0.44,
               "17":1.0,
               "49":0.08,
               "18":0.12,
               "55":0.28,
               "56":0.08
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":2,
               "5":47,
               "54":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":18,
               "48":10,
               "49":3,
               "53":9,
               "55":3,
               "56":6
            },
            "5":{
               "55":21,
               "56":28
            },
            "6":{
               "48":11,
               "17":25,
               "49":2,
               "18":3,
               "55":7,
               "56":2
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.1904762,
         "setCounts":{
            "1":8,
            "3":0,
            "4":41,
            "5":9,
            "6":1,
            "7":35,
            "8":3
         },
         "fourSetMultipliers":{
            "1":0.22857143,
            "3":0.0,
            "4":1.0,
            "5":0.25714287,
            "6":0.024390243,
            "7":1.0,
            "8":0.08571429
         },
         "twoSetMax":73.88889,
         "twoSetCounts":{
            "1":8,
            "3":0,
            "5":9,
            "7":35,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":35.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":23.333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":15.555556
            },
            {
               "set":5,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "49":1,
            "1":3,
            "17":9,
            "18":3,
            "53":5,
            "5":9,
            "55":2,
            "56":1,
            "41":7,
            "42":1,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.5555556,
            "49":0.11111111,
            "1":0.33333334,
            "17":1.0,
            "18":0.33333334,
            "53":0.5555556,
            "5":1.0,
            "55":0.22222222,
            "56":0.11111111,
            "41":0.7777778,
            "42":0.11111111,
            "28":0.33333334
         },
         "secondaryTypeCounts":{
            "Speed":9,
            "Critical Chance":5,
            "Health":5,
            "Potency":9,
            "Offense":12,
            "Tenacity":3,
            "Protection":4,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":0.75,
            "Critical Chance":0.41666666,
            "Health":0.41666666,
            "Potency":0.75,
            "Offense":1.0,
            "Tenacity":0.25,
            "Protection":0.33333334,
            "Defense":0.16666667
         }
      },
      {
         "name":"JAWAENGINEER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.12195122,
               "55":0.024390243,
               "56":0.07317073
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.4,
               "48":0.13333334,
               "49":1.0,
               "53":0.26666668,
               "55":0.53333336,
               "56":1.0
            },
            "5":{
               "55":0.9230769,
               "56":1.0
            },
            "6":{
               "48":0.2,
               "17":0.93333334,
               "49":0.33333334,
               "18":0.4,
               "55":0.46666667,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":41,
               "54":5,
               "55":1,
               "56":3
            },
            "3":{
               "49":50
            },
            "4":{
               "16":6,
               "48":2,
               "49":15,
               "53":4,
               "55":8,
               "56":15
            },
            "5":{
               "55":24,
               "56":26
            },
            "6":{
               "48":3,
               "17":14,
               "49":5,
               "18":6,
               "55":7,
               "56":15
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.25,
         "setCounts":{
            "1":38,
            "2":1,
            "3":5,
            "4":38,
            "5":1,
            "6":1,
            "7":12,
            "8":4
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.02631579,
            "3":0.13157895,
            "4":1.0,
            "5":0.02631579,
            "6":0.02631579,
            "7":0.31578946,
            "8":0.10526316
         },
         "twoSetMax":80.22223,
         "twoSetCounts":{
            "1":38,
            "3":5,
            "5":1,
            "7":12,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":38.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":25.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":16.88889
            },
            {
               "set":7,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "1":7,
            "49":4,
            "17":1,
            "18":1,
            "5":15,
            "53":8,
            "55":2,
            "56":5,
            "41":3,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.33333334,
            "1":0.46666667,
            "49":0.26666668,
            "17":0.06666667,
            "18":0.06666667,
            "5":1.0,
            "53":0.53333336,
            "55":0.13333334,
            "56":0.33333334,
            "41":0.2,
            "42":0.13333334,
            "28":0.13333334
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":8,
            "Health":9,
            "Potency":1,
            "Offense":8,
            "Tenacity":1,
            "Protection":7,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.53333336,
            "Health":0.6,
            "Potency":0.06666667,
            "Offense":0.53333336,
            "Tenacity":0.06666667,
            "Protection":0.46666667,
            "Defense":0.4
         }
      },
      {
         "name":"LOBOT",
         "count":46,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.060606062,
               "49":0.121212125,
               "52":0.030303031,
               "5":1.0,
               "54":0.030303031,
               "55":0.09090909,
               "56":0.030303031
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.875,
               "48":1.0,
               "49":1.0,
               "53":1.0,
               "55":0.875,
               "56":0.875
            },
            "5":{
               "55":0.9130435,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.7,
               "49":0.4,
               "18":0.9,
               "55":0.6,
               "56":0.8
            }
         },
         "primaryCounts":{
            "1":{
               "48":43
            },
            "2":{
               "48":2,
               "49":4,
               "52":1,
               "5":33,
               "54":1,
               "55":3,
               "56":1
            },
            "3":{
               "49":43
            },
            "4":{
               "16":7,
               "48":8,
               "49":8,
               "53":8,
               "55":7,
               "56":7
            },
            "5":{
               "55":21,
               "56":23
            },
            "6":{
               "48":10,
               "17":7,
               "49":4,
               "18":9,
               "55":6,
               "56":8
            }
         },
         "fourSetsMultiplier":0.9166667,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":22,
            "2":0,
            "3":10,
            "4":19,
            "5":2,
            "6":3,
            "7":9,
            "8":7
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.45454547,
            "4":1.0,
            "5":0.09090909,
            "6":0.15789473,
            "7":0.4090909,
            "8":0.3181818
         },
         "twoSetMax":46.666668,
         "twoSetCounts":{
            "1":22,
            "3":10,
            "5":2,
            "7":9,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":22.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.777778
            },
            {
               "set":7,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":7,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":3,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":5,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"defense",
         "secondaryCounts":{
            "48":2,
            "49":2,
            "17":7,
            "1":8,
            "18":1,
            "5":13,
            "53":7,
            "55":1,
            "56":1,
            "41":2,
            "42":5,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.15384616,
            "49":0.15384616,
            "17":0.53846157,
            "1":0.61538464,
            "18":0.07692308,
            "5":1.0,
            "53":0.53846157,
            "55":0.07692308,
            "56":0.07692308,
            "41":0.15384616,
            "42":0.3846154,
            "28":0.15384616
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":7,
            "Health":9,
            "Potency":7,
            "Offense":4,
            "Tenacity":1,
            "Protection":3,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.53846157,
            "Health":0.6923077,
            "Potency":0.53846157,
            "Offense":0.30769232,
            "Tenacity":0.07692308,
            "Protection":0.23076923,
            "Defense":0.53846157
         }
      },
      {
         "name":"EMBO",
         "count":47,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.048780486,
               "52":0.024390243,
               "5":1.0,
               "55":0.024390243,
               "56":0.048780486
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.33333334,
               "49":0.16666667,
               "53":0.29166666,
               "56":0.16666667
            },
            "5":{
               "55":0.38235295,
               "56":1.0
            },
            "6":{
               "48":0.94736844,
               "17":0.10526316,
               "18":0.2631579,
               "55":0.15789473,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":47
            },
            "2":{
               "48":2,
               "52":1,
               "5":41,
               "55":1,
               "56":2
            },
            "3":{
               "49":47
            },
            "4":{
               "16":24,
               "48":8,
               "49":4,
               "53":7,
               "56":4
            },
            "5":{
               "55":13,
               "56":34
            },
            "6":{
               "48":18,
               "17":2,
               "18":5,
               "55":3,
               "56":19
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.14634146,
         "setCounts":{
            "1":18,
            "2":4,
            "3":3,
            "4":17,
            "5":22,
            "6":20,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.8181818,
            "2":0.2,
            "3":0.13636364,
            "4":0.85,
            "5":1.0,
            "6":1.0,
            "8":0.045454547
         },
         "twoSetMax":54.666668,
         "twoSetCounts":{
            "1":18,
            "3":3,
            "5":22,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":22.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.666667
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.777778
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "1":4,
            "17":1,
            "49":4,
            "18":2,
            "5":15,
            "53":4,
            "55":3,
            "56":3,
            "41":4,
            "42":4,
            "28":7
         },
         "secondaryMultipliers":{
            "48":0.06666667,
            "1":0.26666668,
            "17":0.06666667,
            "49":0.26666668,
            "18":0.13333334,
            "5":1.0,
            "53":0.26666668,
            "55":0.2,
            "56":0.2,
            "41":0.26666668,
            "42":0.26666668,
            "28":0.46666667
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":4,
            "Health":7,
            "Potency":1,
            "Offense":5,
            "Tenacity":2,
            "Protection":10,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.26666668,
            "Health":0.46666667,
            "Potency":0.06666667,
            "Offense":0.33333334,
            "Tenacity":0.13333334,
            "Protection":0.6666667,
            "Defense":0.53333336
         }
      },
      {
         "name":"IMAGUNDI",
         "count":48,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.1724138,
               "52":0.06896552,
               "5":1.0,
               "54":0.1724138,
               "55":0.20689656,
               "56":0.03448276
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.75,
               "49":0.125,
               "53":0.125,
               "55":0.6875,
               "56":0.25
            },
            "5":{
               "55":0.92,
               "56":1.0
            },
            "6":{
               "48":0.42857143,
               "17":1.0,
               "49":0.21428572,
               "18":0.64285713,
               "55":0.64285713,
               "56":0.5
            }
         },
         "primaryCounts":{
            "1":{
               "48":47
            },
            "2":{
               "48":5,
               "52":2,
               "5":29,
               "54":5,
               "55":6,
               "56":1
            },
            "3":{
               "49":48
            },
            "4":{
               "16":16,
               "48":12,
               "49":2,
               "53":2,
               "55":11,
               "56":4
            },
            "5":{
               "55":23,
               "56":25
            },
            "6":{
               "48":6,
               "17":14,
               "49":3,
               "18":9,
               "55":9,
               "56":7
            }
         },
         "fourSetsMultiplier":0.92,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":56,
            "2":2,
            "3":3,
            "4":12,
            "5":7,
            "6":9,
            "7":5,
            "8":3
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.16666667,
            "3":0.05357143,
            "4":1.0,
            "5":0.125,
            "6":0.75,
            "7":0.08928572,
            "8":0.05357143
         },
         "twoSetMax":118.22221,
         "twoSetCounts":{
            "1":56,
            "3":3,
            "5":7,
            "7":5,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":56.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":37.333332
            },
            {
               "set":1,
               "occurrence":3,
               "count":24.888887
            },
            {
               "set":5,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":3,
            "17":1,
            "49":2,
            "1":9,
            "18":3,
            "5":5,
            "53":3,
            "55":3,
            "56":1,
            "41":6,
            "42":3,
            "28":10
         },
         "secondaryMultipliers":{
            "48":0.3,
            "17":0.1,
            "49":0.2,
            "1":0.9,
            "18":0.3,
            "5":0.5,
            "53":0.3,
            "55":0.3,
            "56":0.1,
            "41":0.6,
            "42":0.3,
            "28":1.0
         },
         "secondaryTypeCounts":{
            "Speed":5,
            "Critical Chance":3,
            "Health":12,
            "Potency":1,
            "Offense":9,
            "Tenacity":3,
            "Protection":11,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":0.41666666,
            "Critical Chance":0.25,
            "Health":1.0,
            "Potency":0.083333336,
            "Offense":0.75,
            "Tenacity":0.25,
            "Protection":0.9166667,
            "Defense":0.41666666
         }
      },
      {
         "name":"EETHKOTH",
         "count":47,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "49":0.029411765,
               "5":1.0,
               "54":0.1764706,
               "55":0.0882353,
               "56":0.05882353
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.25,
               "16":1.0,
               "49":0.125,
               "53":0.625,
               "55":0.4375,
               "56":0.4375
            },
            "5":{
               "55":0.7037037,
               "56":1.0
            },
            "6":{
               "48":0.5,
               "17":0.6666667,
               "49":0.25,
               "18":1.0,
               "55":0.8333333,
               "56":0.6666667
            }
         },
         "primaryCounts":{
            "1":{
               "48":47
            },
            "2":{
               "49":1,
               "5":34,
               "54":6,
               "55":3,
               "56":2
            },
            "3":{
               "49":46
            },
            "4":{
               "48":4,
               "16":16,
               "49":2,
               "53":10,
               "55":7,
               "56":7
            },
            "5":{
               "55":19,
               "56":27
            },
            "6":{
               "48":6,
               "17":8,
               "49":3,
               "18":12,
               "55":10,
               "56":8
            }
         },
         "fourSetsMultiplier":0.56666666,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":40,
            "2":3,
            "3":5,
            "4":11,
            "5":9,
            "6":3,
            "7":12,
            "8":6
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.27272728,
            "3":0.125,
            "4":1.0,
            "5":0.225,
            "6":0.27272728,
            "7":0.3,
            "8":0.15
         },
         "twoSetMax":84.44444,
         "twoSetCounts":{
            "1":40,
            "3":5,
            "5":9,
            "7":12,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":40.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":26.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":17.777777
            },
            {
               "set":7,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":6,
            "1":1,
            "17":2,
            "49":5,
            "18":3,
            "53":4,
            "5":11,
            "55":3,
            "56":2,
            "41":8,
            "42":3,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.54545456,
            "1":0.09090909,
            "17":0.18181819,
            "49":0.45454547,
            "18":0.27272728,
            "53":0.36363637,
            "5":1.0,
            "55":0.27272728,
            "56":0.18181819,
            "41":0.72727275,
            "42":0.27272728,
            "28":0.09090909
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":4,
            "Health":4,
            "Potency":2,
            "Offense":14,
            "Tenacity":3,
            "Protection":3,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":0.78571427,
            "Critical Chance":0.2857143,
            "Health":0.2857143,
            "Potency":0.14285715,
            "Offense":1.0,
            "Tenacity":0.21428572,
            "Protection":0.21428572,
            "Defense":0.5714286
         }
      },
      {
         "name":"HOTHREBELSOLDIER",
         "count":47,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.060606062,
               "49":0.030303031,
               "5":1.0,
               "55":0.030303031,
               "56":0.3030303
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.8,
               "49":0.9,
               "53":0.5,
               "55":0.4,
               "56":1.0
            },
            "5":{
               "55":0.9166667,
               "56":1.0
            },
            "6":{
               "48":0.7,
               "17":0.6,
               "49":0.8,
               "18":1.0,
               "55":0.6,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":46
            },
            "2":{
               "48":2,
               "49":1,
               "5":33,
               "55":1,
               "56":10
            },
            "3":{
               "49":47
            },
            "4":{
               "16":10,
               "48":8,
               "49":9,
               "53":5,
               "55":4,
               "56":10
            },
            "5":{
               "55":22,
               "56":24
            },
            "6":{
               "48":7,
               "17":6,
               "49":8,
               "18":10,
               "55":6,
               "56":10
            }
         },
         "fourSetsMultiplier":0.88,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":47,
            "2":2,
            "3":10,
            "4":15,
            "5":7,
            "6":5,
            "7":5,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.13333334,
            "3":0.21276596,
            "4":1.0,
            "5":0.14893617,
            "6":0.33333334,
            "7":0.10638298,
            "8":0.04255319
         },
         "twoSetMax":99.22223,
         "twoSetCounts":{
            "1":47,
            "3":10,
            "5":7,
            "7":5,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":47.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":31.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":20.88889
            },
            {
               "set":3,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":3,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":1,
            "1":1,
            "49":2,
            "17":6,
            "18":6,
            "53":4,
            "5":9,
            "55":8,
            "56":3,
            "41":1,
            "42":2,
            "28":10
         },
         "secondaryMultipliers":{
            "48":0.1,
            "1":0.1,
            "49":0.2,
            "17":0.6,
            "18":0.6,
            "53":0.4,
            "5":0.9,
            "55":0.8,
            "56":0.3,
            "41":0.1,
            "42":0.2,
            "28":1.0
         },
         "secondaryTypeCounts":{
            "Speed":9,
            "Critical Chance":4,
            "Health":9,
            "Potency":6,
            "Offense":2,
            "Tenacity":6,
            "Protection":13,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":0.6923077,
            "Critical Chance":0.30769232,
            "Health":0.6923077,
            "Potency":0.46153846,
            "Offense":0.15384616,
            "Tenacity":0.46153846,
            "Protection":1.0,
            "Defense":0.30769232
         }
      },
      {
         "name":"ROSETICO",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.075,
               "5":1.0,
               "54":0.05,
               "56":0.025
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.33333334,
               "53":0.52380955,
               "55":0.14285715,
               "56":0.33333334
            },
            "5":{
               "55":0.7777778,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.6875,
               "49":0.25,
               "18":0.5625,
               "55":0.3125,
               "56":0.25
            }
         },
         "primaryCounts":{
            "1":{
               "48":45
            },
            "2":{
               "48":3,
               "5":40,
               "54":2,
               "56":1
            },
            "3":{
               "49":47
            },
            "4":{
               "16":21,
               "48":7,
               "53":11,
               "55":3,
               "56":7
            },
            "5":{
               "55":21,
               "56":27
            },
            "6":{
               "48":16,
               "17":11,
               "49":4,
               "18":9,
               "55":5,
               "56":4
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.75,
         "setCounts":{
            "1":21,
            "2":6,
            "3":5,
            "4":14,
            "5":17,
            "6":8,
            "7":9,
            "8":5
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.42857143,
            "3":0.23809524,
            "4":1.0,
            "5":0.8095238,
            "6":0.5714286,
            "7":0.42857143,
            "8":0.23809524
         },
         "twoSetMax":52.0,
         "twoSetCounts":{
            "1":21,
            "3":5,
            "5":17,
            "7":9,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":7,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":7,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "17":4,
            "49":1,
            "1":2,
            "18":5,
            "5":14,
            "53":6,
            "55":3,
            "56":1,
            "41":3,
            "42":5,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.071428575,
            "17":0.2857143,
            "49":0.071428575,
            "1":0.14285715,
            "18":0.35714287,
            "5":1.0,
            "53":0.42857143,
            "55":0.21428572,
            "56":0.071428575,
            "41":0.21428572,
            "42":0.35714287,
            "28":0.21428572
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":6,
            "Health":5,
            "Potency":4,
            "Offense":4,
            "Tenacity":5,
            "Protection":4,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.42857143,
            "Health":0.35714287,
            "Potency":0.2857143,
            "Offense":0.2857143,
            "Tenacity":0.35714287,
            "Protection":0.2857143,
            "Defense":0.42857143
         }
      },
      {
         "name":"DIRECTORKRENNIC",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.06666667,
               "5":1.0,
               "54":0.022222223,
               "56":0.022222223
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.5625,
               "49":0.25,
               "53":0.5625,
               "55":0.375,
               "56":0.375
            },
            "5":{
               "55":0.7241379,
               "56":1.0
            },
            "6":{
               "48":0.46153846,
               "17":0.61538464,
               "49":0.46153846,
               "18":0.7692308,
               "55":0.53846157,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":3,
               "5":45,
               "54":1,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":16,
               "48":9,
               "49":4,
               "53":9,
               "55":6,
               "56":6
            },
            "5":{
               "55":21,
               "56":29
            },
            "6":{
               "48":6,
               "17":8,
               "49":6,
               "18":10,
               "55":7,
               "56":13
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.42857143,
         "setCounts":{
            "1":21,
            "2":5,
            "3":0,
            "4":18,
            "5":16,
            "6":12,
            "7":16,
            "8":7
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.2777778,
            "3":0.0,
            "4":1.0,
            "5":0.7619048,
            "6":0.6666667,
            "7":0.7619048,
            "8":0.33333334
         },
         "twoSetMax":53.0,
         "twoSetCounts":{
            "1":21,
            "3":0,
            "5":16,
            "7":16,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":16.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":16.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":10.666667
            },
            {
               "set":7,
               "occurrence":2,
               "count":10.666667
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":5,
               "occurrence":3,
               "count":7.111111
            },
            {
               "set":7,
               "occurrence":3,
               "count":7.111111
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "1":5,
            "49":5,
            "17":5,
            "18":5,
            "5":11,
            "53":7,
            "55":1,
            "56":3,
            "41":1,
            "42":7,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.09090909,
            "1":0.45454547,
            "49":0.45454547,
            "17":0.45454547,
            "18":0.45454547,
            "5":1.0,
            "53":0.6363636,
            "55":0.09090909,
            "56":0.27272728,
            "41":0.09090909,
            "42":0.6363636,
            "28":0.27272728
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":7,
            "Health":6,
            "Potency":5,
            "Offense":2,
            "Tenacity":5,
            "Protection":6,
            "Defense":12
         },
         "secondaryTypeMultipliers":{
            "Speed":0.9166667,
            "Critical Chance":0.5833333,
            "Health":0.5,
            "Potency":0.41666666,
            "Offense":0.16666667,
            "Tenacity":0.41666666,
            "Protection":0.5,
            "Defense":1.0
         }
      },
      {
         "name":"FIRSTORDEREXECUTIONER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.1,
               "5":1.0,
               "54":0.025,
               "55":0.125
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.33333334,
               "53":0.13333334,
               "55":0.13333334,
               "56":0.06666667
            },
            "5":{
               "55":1.0,
               "56":0.42857143
            },
            "6":{
               "48":1.0,
               "49":0.071428575,
               "17":0.035714287,
               "18":0.071428575,
               "55":0.39285713,
               "56":0.21428572
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":4,
               "5":40,
               "54":1,
               "55":5
            },
            "3":{
               "49":50
            },
            "4":{
               "16":30,
               "48":10,
               "53":4,
               "55":4,
               "56":2
            },
            "5":{
               "55":35,
               "56":15
            },
            "6":{
               "48":28,
               "49":2,
               "17":1,
               "18":2,
               "55":11,
               "56":6
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.61290324,
         "setCounts":{
            "1":52,
            "2":6,
            "3":1,
            "4":1,
            "5":24,
            "6":24,
            "7":3,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.25,
            "3":0.01923077,
            "4":0.041666668,
            "5":0.46153846,
            "6":1.0,
            "7":0.057692308,
            "8":0.03846154
         },
         "twoSetMax":110.66667,
         "twoSetCounts":{
            "1":52,
            "3":1,
            "5":24,
            "7":3,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":52.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":34.666668
            },
            {
               "set":5,
               "occurrence":1,
               "count":24.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":23.111113
            },
            {
               "set":5,
               "occurrence":2,
               "count":16.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":10.666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":6,
            "17":3,
            "49":2,
            "18":3,
            "53":3,
            "5":15,
            "55":1,
            "56":1,
            "41":6,
            "42":1,
            "28":7
         },
         "secondaryMultipliers":{
            "48":0.13333334,
            "1":0.4,
            "17":0.2,
            "49":0.13333334,
            "18":0.2,
            "53":0.2,
            "5":1.0,
            "55":0.06666667,
            "56":0.06666667,
            "41":0.4,
            "42":0.06666667,
            "28":0.46666667
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":3,
            "Health":7,
            "Potency":3,
            "Offense":8,
            "Tenacity":3,
            "Protection":8,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.2,
            "Health":0.46666667,
            "Potency":0.2,
            "Offense":0.53333336,
            "Tenacity":0.2,
            "Protection":0.53333336,
            "Defense":0.2
         }
      },
      {
         "name":"VADER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.06382979,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.8148148,
               "48":0.037037037,
               "53":1.0
            },
            "5":{
               "55":1.0,
               "56":1.0
            },
            "6":{
               "48":0.020833334,
               "17":1.0,
               "55":0.020833334
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":3,
               "5":47
            },
            "3":{
               "49":50
            },
            "4":{
               "16":22,
               "48":1,
               "53":27
            },
            "5":{
               "55":25,
               "56":25
            },
            "6":{
               "48":1,
               "17":48,
               "55":1
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.25,
         "setCounts":{
            "1":4,
            "2":8,
            "4":11,
            "5":20,
            "6":21,
            "7":46
         },
         "fourSetMultipliers":{
            "1":0.08695652,
            "2":0.3809524,
            "4":0.52380955,
            "5":0.4347826,
            "6":1.0,
            "7":1.0
         },
         "twoSetMax":96.666664,
         "twoSetCounts":{
            "1":4,
            "5":20,
            "7":46
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":46.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":30.666666
            },
            {
               "set":7,
               "occurrence":3,
               "count":20.444445
            },
            {
               "set":5,
               "occurrence":1,
               "count":20.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":13.333333
            },
            {
               "set":5,
               "occurrence":3,
               "count":8.888888
            },
            {
               "set":1,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":1,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":6,
            "1":4,
            "17":8,
            "49":3,
            "18":3,
            "5":21,
            "53":10,
            "55":2,
            "56":1,
            "41":3,
            "42":2,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.2857143,
            "1":0.1904762,
            "17":0.3809524,
            "49":0.14285715,
            "18":0.14285715,
            "5":1.0,
            "53":0.47619048,
            "55":0.0952381,
            "56":0.04761905,
            "41":0.14285715,
            "42":0.0952381,
            "28":0.1904762
         },
         "secondaryTypeCounts":{
            "Speed":21,
            "Critical Chance":10,
            "Health":6,
            "Potency":8,
            "Offense":9,
            "Tenacity":3,
            "Protection":5,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.47619048,
            "Health":0.2857143,
            "Potency":0.3809524,
            "Offense":0.42857143,
            "Tenacity":0.14285715,
            "Protection":0.23809524,
            "Defense":0.23809524
         }
      },
      {
         "name":"COUNTDOOKU",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.041666668
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.61904764,
               "49":0.14285715,
               "53":0.1904762,
               "55":0.1904762,
               "56":0.23809524
            },
            "5":{
               "55":0.47058824,
               "56":1.0
            },
            "6":{
               "48":0.34615386,
               "17":1.0,
               "49":0.03846154,
               "18":0.07692308,
               "55":0.15384616,
               "56":0.26923078
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":48,
               "54":2
            },
            "3":{
               "49":49
            },
            "4":{
               "16":21,
               "48":13,
               "49":3,
               "53":4,
               "55":4,
               "56":5
            },
            "5":{
               "55":16,
               "56":34
            },
            "6":{
               "48":9,
               "17":26,
               "49":1,
               "18":2,
               "55":4,
               "56":7
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.13636364,
         "setCounts":{
            "1":13,
            "2":0,
            "3":1,
            "4":43,
            "5":4,
            "6":1,
            "7":31,
            "8":2
         },
         "fourSetMultipliers":{
            "1":0.41935483,
            "2":0.0,
            "3":0.032258064,
            "4":1.0,
            "5":0.12903225,
            "6":0.023255814,
            "7":1.0,
            "8":0.06451613
         },
         "twoSetMax":64.666664,
         "twoSetCounts":{
            "1":13,
            "3":1,
            "5":4,
            "7":31,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":31.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":20.666666
            },
            {
               "set":7,
               "occurrence":3,
               "count":13.777778
            },
            {
               "set":1,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":1,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":5,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "17":5,
            "1":4,
            "49":1,
            "18":4,
            "5":19,
            "53":7,
            "55":1,
            "56":2,
            "41":7,
            "42":7,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.10526316,
            "17":0.2631579,
            "1":0.21052632,
            "49":0.05263158,
            "18":0.21052632,
            "5":1.0,
            "53":0.36842105,
            "55":0.05263158,
            "56":0.10526316,
            "41":0.36842105,
            "42":0.36842105,
            "28":0.15789473
         },
         "secondaryTypeCounts":{
            "Speed":19,
            "Critical Chance":7,
            "Health":5,
            "Potency":5,
            "Offense":9,
            "Tenacity":4,
            "Protection":5,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.36842105,
            "Health":0.2631579,
            "Potency":0.2631579,
            "Offense":0.47368422,
            "Tenacity":0.21052632,
            "Protection":0.2631579,
            "Defense":0.42105263
         }
      },
      {
         "name":"MISSIONVAO",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.06818182,
               "5":1.0,
               "54":0.022727273,
               "56":0.022727273
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.24242425,
               "53":0.030303031,
               "55":0.121212125,
               "56":0.060606062
            },
            "5":{
               "55":0.6,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.18518518,
               "18":0.14814815,
               "55":0.33333334,
               "56":0.14814815
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "48":3,
               "5":44,
               "54":1,
               "56":1
            },
            "3":{
               "49":49
            },
            "4":{
               "16":33,
               "48":8,
               "53":1,
               "55":4,
               "56":2
            },
            "5":{
               "55":18,
               "56":30
            },
            "6":{
               "48":27,
               "17":5,
               "18":4,
               "55":9,
               "56":4
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.11363637,
         "setCounts":{
            "1":7,
            "2":16,
            "3":0,
            "4":8,
            "5":30,
            "6":20,
            "7":3,
            "8":3
         },
         "fourSetMultipliers":{
            "1":0.23333333,
            "2":0.8,
            "3":0.0,
            "4":0.4,
            "5":1.0,
            "6":1.0,
            "7":0.1,
            "8":0.1
         },
         "twoSetMax":63.333332,
         "twoSetCounts":{
            "1":7,
            "3":0,
            "5":30,
            "7":3,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":30.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":20.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":13.333333
            },
            {
               "set":1,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":1,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "49":1,
            "1":6,
            "17":1,
            "18":2,
            "5":15,
            "53":2,
            "55":4,
            "56":2,
            "41":1,
            "42":4,
            "28":13
         },
         "secondaryMultipliers":{
            "48":0.2,
            "49":0.06666667,
            "1":0.4,
            "17":0.06666667,
            "18":0.13333334,
            "5":1.0,
            "53":0.13333334,
            "55":0.26666668,
            "56":0.13333334,
            "41":0.06666667,
            "42":0.26666668,
            "28":0.8666667
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":2,
            "Health":10,
            "Potency":1,
            "Offense":4,
            "Tenacity":2,
            "Protection":15,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.13333334,
            "Health":0.6666667,
            "Potency":0.06666667,
            "Offense":0.26666668,
            "Tenacity":0.13333334,
            "Protection":1.0,
            "Defense":0.33333334
         }
      },
      {
         "name":"CHIEFNEBIT",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "49":0.11764706,
               "52":0.05882353,
               "5":1.0,
               "54":0.7058824,
               "55":0.3529412,
               "56":0.64705884
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.20689656,
               "48":0.03448276,
               "49":0.10344828,
               "53":0.10344828,
               "55":0.27586207,
               "56":1.0
            },
            "5":{
               "55":0.4,
               "56":1.0
            },
            "6":{
               "17":0.08,
               "49":0.32,
               "18":0.08,
               "55":0.52,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "49":2,
               "52":1,
               "5":17,
               "54":12,
               "55":6,
               "56":11
            },
            "3":{
               "49":49
            },
            "4":{
               "16":6,
               "48":1,
               "49":3,
               "53":3,
               "55":8,
               "56":29
            },
            "5":{
               "55":14,
               "56":35
            },
            "6":{
               "17":2,
               "49":8,
               "18":2,
               "55":13,
               "56":25
            }
         },
         "fourSetsMultiplier":0.1904762,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":80,
            "2":3,
            "3":19,
            "4":5,
            "5":1,
            "6":0,
            "7":2,
            "8":13
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.6,
            "3":0.2375,
            "4":1.0,
            "5":0.0125,
            "6":0.0,
            "7":0.025,
            "8":0.1625
         },
         "twoSetMax":168.88889,
         "twoSetCounts":{
            "1":80,
            "3":19,
            "5":1,
            "7":2,
            "8":13
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":80.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":53.333332
            },
            {
               "set":1,
               "occurrence":3,
               "count":35.555553
            },
            {
               "set":3,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":8,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":1,
            "17":4,
            "49":3,
            "1":6,
            "18":1,
            "5":7,
            "53":4,
            "55":4,
            "56":6,
            "41":2,
            "42":8,
            "28":10
         },
         "secondaryMultipliers":{
            "48":0.1,
            "17":0.4,
            "49":0.3,
            "1":0.6,
            "18":0.1,
            "5":0.7,
            "53":0.4,
            "55":0.4,
            "56":0.6,
            "41":0.2,
            "42":0.8,
            "28":1.0
         },
         "secondaryTypeCounts":{
            "Speed":7,
            "Critical Chance":4,
            "Health":10,
            "Potency":4,
            "Offense":3,
            "Tenacity":1,
            "Protection":16,
            "Defense":11
         },
         "secondaryTypeMultipliers":{
            "Speed":0.4375,
            "Critical Chance":0.25,
            "Health":0.625,
            "Potency":0.25,
            "Offense":0.1875,
            "Tenacity":0.0625,
            "Protection":1.0,
            "Defense":0.6875
         }
      },
      {
         "name":"TUSKENSHAMAN",
         "count":45,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.03125,
               "52":0.03125,
               "5":1.0,
               "54":0.0625,
               "55":0.125,
               "56":0.125
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.5555556,
               "49":0.7777778,
               "53":0.5555556,
               "55":1.0,
               "56":0.8888889
            },
            "5":{
               "55":0.75,
               "56":1.0
            },
            "6":{
               "48":0.3529412,
               "17":1.0,
               "49":0.1764706,
               "18":0.7647059,
               "55":0.11764706,
               "56":0.1764706
            }
         },
         "primaryCounts":{
            "1":{
               "48":44
            },
            "2":{
               "48":1,
               "52":1,
               "5":32,
               "54":2,
               "55":4,
               "56":4
            },
            "3":{
               "49":45
            },
            "4":{
               "16":9,
               "48":5,
               "49":7,
               "53":5,
               "55":9,
               "56":8
            },
            "5":{
               "55":18,
               "56":24
            },
            "6":{
               "48":6,
               "17":17,
               "49":3,
               "18":13,
               "55":2,
               "56":3
            }
         },
         "fourSetsMultiplier":0.7307692,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":25,
            "2":1,
            "3":5,
            "4":14,
            "5":2,
            "6":4,
            "7":24,
            "8":7
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.071428575,
            "3":0.2,
            "4":1.0,
            "5":0.08,
            "6":0.2857143,
            "7":0.96,
            "8":0.28
         },
         "twoSetMax":65.666664,
         "twoSetCounts":{
            "1":25,
            "3":5,
            "5":2,
            "7":24,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":25.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":24.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":16.666666
            },
            {
               "set":7,
               "occurrence":2,
               "count":16.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":11.111111
            },
            {
               "set":7,
               "occurrence":3,
               "count":10.666667
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":5,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"potency",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":6,
            "49":3,
            "1":4,
            "17":2,
            "18":3,
            "5":9,
            "53":3,
            "55":2,
            "56":4,
            "41":9,
            "42":2,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.6666667,
            "49":0.33333334,
            "1":0.44444445,
            "17":0.22222222,
            "18":0.33333334,
            "5":1.0,
            "53":0.33333334,
            "55":0.22222222,
            "56":0.44444445,
            "41":1.0,
            "42":0.22222222,
            "28":0.6666667
         },
         "secondaryTypeCounts":{
            "Speed":9,
            "Critical Chance":3,
            "Health":6,
            "Potency":2,
            "Offense":15,
            "Tenacity":3,
            "Protection":10,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":0.6,
            "Critical Chance":0.2,
            "Health":0.4,
            "Potency":0.13333334,
            "Offense":1.0,
            "Tenacity":0.2,
            "Protection":0.6666667,
            "Defense":0.33333334
         }
      },
      {
         "name":"FIRSTORDERTIEPILOT",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.07692308,
               "5":1.0,
               "54":0.12820514,
               "55":0.051282052,
               "56":0.025641026
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.32,
               "49":0.04,
               "53":0.48,
               "55":0.04,
               "56":0.12
            },
            "5":{
               "55":0.8518519,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.5294118,
               "49":0.11764706,
               "18":0.29411766,
               "55":0.4117647,
               "56":0.5882353
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":3,
               "5":39,
               "54":5,
               "55":2,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":25,
               "48":8,
               "49":1,
               "53":12,
               "55":1,
               "56":3
            },
            "5":{
               "55":23,
               "56":27
            },
            "6":{
               "48":17,
               "17":9,
               "49":2,
               "18":5,
               "55":7,
               "56":10
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.47058824,
         "setCounts":{
            "1":15,
            "2":6,
            "3":1,
            "4":12,
            "5":23,
            "6":16,
            "7":9,
            "8":2
         },
         "fourSetMultipliers":{
            "1":0.65217394,
            "2":0.375,
            "3":0.04347826,
            "4":0.75,
            "5":1.0,
            "6":1.0,
            "7":0.39130434,
            "8":0.08695652
         },
         "twoSetMax":53.333332,
         "twoSetCounts":{
            "1":15,
            "3":1,
            "5":23,
            "7":9,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":23.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":15.333333
            },
            {
               "set":1,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":10.222222
            },
            {
               "set":1,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":7,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "17":3,
            "1":6,
            "49":1,
            "18":2,
            "5":10,
            "53":11,
            "55":6,
            "56":4,
            "41":2,
            "42":1,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.18181819,
            "17":0.27272728,
            "1":0.54545456,
            "49":0.09090909,
            "18":0.18181819,
            "5":0.90909094,
            "53":1.0,
            "55":0.54545456,
            "56":0.36363637,
            "41":0.18181819,
            "42":0.09090909,
            "28":0.09090909
         },
         "secondaryTypeCounts":{
            "Speed":10,
            "Critical Chance":11,
            "Health":12,
            "Potency":3,
            "Offense":4,
            "Tenacity":2,
            "Protection":5,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":0.8333333,
            "Critical Chance":0.9166667,
            "Health":1.0,
            "Potency":0.25,
            "Offense":0.33333334,
            "Tenacity":0.16666667,
            "Protection":0.41666666,
            "Defense":0.16666667
         }
      },
      {
         "name":"FIRSTORDERTROOPER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "49":0.04347826,
               "5":0.9130435,
               "54":0.13043478,
               "55":0.08695652,
               "56":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.05263158,
               "49":0.10526316,
               "53":0.02631579,
               "55":0.13157895,
               "56":1.0
            },
            "5":{
               "55":0.08695652,
               "56":1.0
            },
            "6":{
               "49":0.08108108,
               "17":0.027027028,
               "18":0.08108108,
               "55":0.16216215,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "49":1,
               "5":21,
               "54":3,
               "55":2,
               "56":23
            },
            "3":{
               "49":50
            },
            "4":{
               "48":2,
               "49":4,
               "53":1,
               "55":5,
               "56":38
            },
            "5":{
               "55":4,
               "56":46
            },
            "6":{
               "49":3,
               "17":1,
               "18":3,
               "55":6,
               "56":37
            }
         },
         "fourSetsMultiplier":0.11111111,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":74,
            "2":0,
            "3":51,
            "4":5,
            "5":0,
            "6":0,
            "7":0,
            "8":8
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.6891892,
            "4":1.0,
            "5":0.0,
            "6":0.0,
            "7":0.0,
            "8":0.10810811
         },
         "twoSetMax":174.33333,
         "twoSetCounts":{
            "1":74,
            "3":51,
            "5":0,
            "7":0,
            "8":8
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":74.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":51.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":49.333332
            },
            {
               "set":3,
               "occurrence":2,
               "count":34.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":32.88889
            },
            {
               "set":3,
               "occurrence":3,
               "count":22.666666
            },
            {
               "set":8,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"defense",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":1,
            "17":5,
            "1":4,
            "49":2,
            "18":4,
            "53":3,
            "5":6,
            "55":4,
            "56":4,
            "41":3,
            "42":6,
            "28":12
         },
         "secondaryMultipliers":{
            "48":0.083333336,
            "17":0.41666666,
            "1":0.33333334,
            "49":0.16666667,
            "18":0.33333334,
            "53":0.25,
            "5":0.5,
            "55":0.33333334,
            "56":0.33333334,
            "41":0.25,
            "42":0.5,
            "28":1.0
         },
         "secondaryTypeCounts":{
            "Speed":6,
            "Critical Chance":3,
            "Health":8,
            "Potency":5,
            "Offense":4,
            "Tenacity":4,
            "Protection":16,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":0.375,
            "Critical Chance":0.1875,
            "Health":0.5,
            "Potency":0.3125,
            "Offense":0.25,
            "Tenacity":0.25,
            "Protection":1.0,
            "Defense":0.5
         }
      },
      {
         "name":"GREEDO",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.044444446,
               "5":1.0,
               "54":0.022222223
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.1904762,
               "49":0.0952381,
               "53":0.8095238,
               "55":0.14285715,
               "56":0.04761905
            },
            "5":{
               "55":0.7407407,
               "56":1.0
            },
            "6":{
               "48":0.8,
               "17":1.0,
               "49":0.13333334,
               "18":0.26666668,
               "55":0.2,
               "56":0.8
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "48":2,
               "5":45,
               "54":1
            },
            "3":{
               "49":48
            },
            "4":{
               "16":21,
               "48":4,
               "49":2,
               "53":17,
               "55":3,
               "56":1
            },
            "5":{
               "55":20,
               "56":27
            },
            "6":{
               "48":12,
               "17":15,
               "49":2,
               "18":4,
               "55":3,
               "56":12
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.6333333,
         "setCounts":{
            "1":15,
            "2":3,
            "3":0,
            "4":6,
            "5":49,
            "6":21,
            "7":4,
            "8":2
         },
         "fourSetMultipliers":{
            "1":0.30612245,
            "2":0.14285715,
            "3":0.0,
            "4":0.2857143,
            "5":1.0,
            "6":1.0,
            "7":0.08163265,
            "8":0.040816326
         },
         "twoSetMax":103.44445,
         "twoSetCounts":{
            "1":15,
            "3":0,
            "5":49,
            "7":4,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":49.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":32.666668
            },
            {
               "set":5,
               "occurrence":3,
               "count":21.777779
            },
            {
               "set":1,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":7,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "1":4,
            "17":2,
            "49":1,
            "18":5,
            "5":17,
            "53":5,
            "55":4,
            "56":1,
            "41":3,
            "42":5,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.05882353,
            "1":0.23529412,
            "17":0.11764706,
            "49":0.05882353,
            "18":0.29411766,
            "5":1.0,
            "53":0.29411766,
            "55":0.23529412,
            "56":0.05882353,
            "41":0.1764706,
            "42":0.29411766,
            "28":0.3529412
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":5,
            "Health":8,
            "Potency":2,
            "Offense":4,
            "Tenacity":5,
            "Protection":7,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.29411766,
            "Health":0.47058824,
            "Potency":0.11764706,
            "Offense":0.23529412,
            "Tenacity":0.29411766,
            "Protection":0.4117647,
            "Defense":0.3529412
         }
      },
      {
         "name":"HOTHLEIA",
         "count":48,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.04761905,
               "49":0.023809524,
               "5":1.0,
               "54":0.023809524,
               "55":0.023809524
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.4347826,
               "53":0.3043478,
               "55":0.13043478,
               "56":0.2173913
            },
            "5":{
               "55":0.5862069,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "49":0.23076923,
               "17":0.9230769,
               "18":0.23076923,
               "55":0.3846154,
               "56":0.84615386
            }
         },
         "primaryCounts":{
            "1":{
               "48":47
            },
            "2":{
               "48":2,
               "49":1,
               "5":42,
               "54":1,
               "55":1
            },
            "3":{
               "49":47
            },
            "4":{
               "16":23,
               "48":10,
               "53":7,
               "55":3,
               "56":5
            },
            "5":{
               "55":17,
               "56":29
            },
            "6":{
               "48":13,
               "49":3,
               "17":12,
               "18":3,
               "55":5,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.45454547,
         "setCounts":{
            "1":16,
            "2":7,
            "3":1,
            "4":12,
            "5":26,
            "6":14,
            "7":10,
            "8":0
         },
         "fourSetMultipliers":{
            "1":0.61538464,
            "2":0.5,
            "3":0.03846154,
            "4":0.85714287,
            "5":1.0,
            "6":1.0,
            "7":0.3846154,
            "8":0.0
         },
         "twoSetMax":59.333336,
         "twoSetCounts":{
            "1":16,
            "3":1,
            "5":26,
            "7":10,
            "8":0
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":26.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":17.333334
            },
            {
               "set":1,
               "occurrence":1,
               "count":16.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":11.555556
            },
            {
               "set":1,
               "occurrence":2,
               "count":10.666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":7.111111
            },
            {
               "set":7,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "49":4,
            "1":2,
            "17":5,
            "18":7,
            "5":11,
            "53":9,
            "55":1,
            "56":5,
            "41":5,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.45454547,
            "49":0.36363637,
            "1":0.18181819,
            "17":0.45454547,
            "18":0.6363636,
            "5":1.0,
            "53":0.8181818,
            "55":0.09090909,
            "56":0.45454547,
            "41":0.45454547,
            "42":0.18181819,
            "28":0.18181819
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":9,
            "Health":3,
            "Potency":5,
            "Offense":10,
            "Tenacity":7,
            "Protection":7,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.8181818,
            "Health":0.27272728,
            "Potency":0.45454547,
            "Offense":0.90909094,
            "Tenacity":0.6363636,
            "Protection":0.6363636,
            "Defense":0.54545456
         }
      },
      {
         "name":"BODHIROOK",
         "count":45,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.10810811,
               "52":0.027027028,
               "5":1.0,
               "54":0.027027028,
               "55":0.054054055
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.54545456,
               "16":1.0,
               "49":0.45454547,
               "53":0.72727275,
               "55":0.6363636,
               "56":0.72727275
            },
            "5":{
               "55":0.875,
               "56":1.0
            },
            "6":{
               "48":0.1764706,
               "17":1.0,
               "49":0.29411766,
               "18":0.3529412,
               "55":0.47058824,
               "56":0.3529412
            }
         },
         "primaryCounts":{
            "1":{
               "48":45
            },
            "2":{
               "48":4,
               "52":1,
               "5":37,
               "54":1,
               "55":2
            },
            "3":{
               "49":45
            },
            "4":{
               "48":6,
               "16":11,
               "49":5,
               "53":8,
               "55":7,
               "56":8
            },
            "5":{
               "55":21,
               "56":24
            },
            "6":{
               "48":3,
               "17":17,
               "49":5,
               "18":6,
               "55":8,
               "56":6
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.40625,
         "setCounts":{
            "1":17,
            "2":2,
            "3":3,
            "4":30,
            "5":5,
            "6":0,
            "7":20,
            "8":3
         },
         "fourSetMultipliers":{
            "1":0.85,
            "2":0.06666667,
            "3":0.15,
            "4":1.0,
            "5":0.25,
            "6":0.0,
            "7":1.0,
            "8":0.15
         },
         "twoSetMax":50.333332,
         "twoSetCounts":{
            "1":17,
            "3":3,
            "5":5,
            "7":20,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":20.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":13.333333
            },
            {
               "set":1,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":7,
               "occurrence":3,
               "count":8.888888
            },
            {
               "set":1,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":5,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":6,
            "49":2,
            "1":2,
            "17":14,
            "18":2,
            "53":3,
            "5":14,
            "55":5,
            "56":1,
            "41":1,
            "42":1,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.42857143,
            "49":0.14285715,
            "1":0.14285715,
            "17":1.0,
            "18":0.14285715,
            "53":0.21428572,
            "5":1.0,
            "55":0.35714287,
            "56":0.071428575,
            "41":0.071428575,
            "42":0.071428575,
            "28":0.35714287
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":3,
            "Health":7,
            "Potency":14,
            "Offense":7,
            "Tenacity":2,
            "Protection":6,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.21428572,
            "Health":0.5,
            "Potency":1.0,
            "Offense":0.5,
            "Tenacity":0.14285715,
            "Protection":0.42857143,
            "Defense":0.21428572
         }
      },
      {
         "name":"BOBAFETT",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.31578946,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.19512194,
               "53":0.024390243
            },
            "5":{
               "55":0.61290324,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.22222222,
               "49":0.027777778,
               "18":0.027777778,
               "55":0.055555556,
               "56":0.055555556
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":12,
               "5":38
            },
            "3":{
               "49":50
            },
            "4":{
               "16":41,
               "48":8,
               "53":1
            },
            "5":{
               "55":19,
               "56":31
            },
            "6":{
               "48":36,
               "17":8,
               "49":1,
               "18":1,
               "55":2,
               "56":2
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":3,
            "2":12,
            "4":7,
            "5":30,
            "6":31,
            "7":13,
            "8":2
         },
         "fourSetMultipliers":{
            "1":0.1,
            "2":0.38709676,
            "4":0.22580644,
            "5":1.0,
            "6":1.0,
            "7":0.43333334,
            "8":0.06666667
         },
         "twoSetMax":63.333332,
         "twoSetCounts":{
            "1":3,
            "5":30,
            "7":13,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":30.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":20.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":13.333333
            },
            {
               "set":7,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":1,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "17":2,
            "49":3,
            "1":5,
            "18":3,
            "5":16,
            "53":4,
            "55":2,
            "56":3,
            "41":4,
            "42":3,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.125,
            "17":0.125,
            "49":0.1875,
            "1":0.3125,
            "18":0.1875,
            "5":1.0,
            "53":0.25,
            "55":0.125,
            "56":0.1875,
            "41":0.25,
            "42":0.1875,
            "28":0.0625
         },
         "secondaryTypeCounts":{
            "Speed":16,
            "Critical Chance":4,
            "Health":7,
            "Potency":2,
            "Offense":6,
            "Tenacity":3,
            "Protection":4,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.25,
            "Health":0.4375,
            "Potency":0.125,
            "Offense":0.375,
            "Tenacity":0.1875,
            "Protection":0.25,
            "Defense":0.375
         }
      },
      {
         "name":"WEDGEANTILLES",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.048780486,
               "49":0.024390243,
               "5":1.0,
               "54":0.07317073,
               "55":0.07317073
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.10344828,
               "49":0.06896552,
               "53":0.41379312,
               "55":0.03448276,
               "56":0.10344828
            },
            "5":{
               "55":1.0,
               "56":0.8518519
            },
            "6":{
               "48":1.0,
               "49":0.4117647,
               "17":0.11764706,
               "18":0.4117647,
               "55":0.47058824,
               "56":0.5294118
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":2,
               "49":1,
               "5":41,
               "54":3,
               "55":3
            },
            "3":{
               "49":50
            },
            "4":{
               "16":29,
               "48":3,
               "49":2,
               "53":12,
               "55":1,
               "56":3
            },
            "5":{
               "55":27,
               "56":23
            },
            "6":{
               "48":17,
               "49":7,
               "17":2,
               "18":7,
               "55":8,
               "56":9
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.31578946,
         "setCounts":{
            "1":17,
            "2":4,
            "3":2,
            "4":13,
            "5":25,
            "6":21,
            "7":2,
            "8":2
         },
         "fourSetMultipliers":{
            "1":0.68,
            "2":0.1904762,
            "3":0.08,
            "4":0.61904764,
            "5":1.0,
            "6":1.0,
            "7":0.08,
            "8":0.08
         },
         "twoSetMax":58.666664,
         "twoSetCounts":{
            "1":17,
            "3":2,
            "5":25,
            "7":2,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":25.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":16.666666
            },
            {
               "set":1,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":5,
               "occurrence":3,
               "count":11.111111
            },
            {
               "set":1,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "17":4,
            "49":3,
            "1":1,
            "18":7,
            "5":14,
            "53":6,
            "55":7,
            "56":2,
            "41":3,
            "42":2,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.071428575,
            "17":0.2857143,
            "49":0.21428572,
            "1":0.071428575,
            "18":0.5,
            "5":1.0,
            "53":0.42857143,
            "55":0.5,
            "56":0.14285715,
            "41":0.21428572,
            "42":0.14285715,
            "28":0.21428572
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":6,
            "Health":8,
            "Potency":4,
            "Offense":4,
            "Tenacity":7,
            "Protection":5,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.42857143,
            "Health":0.5714286,
            "Potency":0.2857143,
            "Offense":0.2857143,
            "Tenacity":0.5,
            "Protection":0.35714287,
            "Defense":0.35714287
         }
      },
      {
         "name":"DEATHTROOPER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.06666667,
               "5":1.0,
               "54":0.022222223,
               "56":0.022222223
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.72,
               "48":0.2,
               "53":0.08,
               "56":1.0
            },
            "5":{
               "55":0.06382979,
               "56":1.0
            },
            "6":{
               "48":0.20588236,
               "17":0.1764706,
               "55":0.0882353,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":3,
               "5":45,
               "54":1,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":18,
               "48":5,
               "53":2,
               "56":25
            },
            "5":{
               "55":3,
               "56":47
            },
            "6":{
               "48":7,
               "17":6,
               "55":3,
               "56":34
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.08695652,
         "setCounts":{
            "1":18,
            "2":8,
            "3":2,
            "4":11,
            "5":8,
            "6":27,
            "7":23,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.7826087,
            "2":0.2962963,
            "3":0.08695652,
            "4":0.4074074,
            "5":0.3478261,
            "6":1.0,
            "7":1.0,
            "8":0.04347826
         },
         "twoSetMax":56.333332,
         "twoSetCounts":{
            "1":18,
            "3":2,
            "5":8,
            "7":23,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":23.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":15.333333
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":10.222222
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "17":6,
            "49":1,
            "1":1,
            "18":1,
            "5":18,
            "53":3,
            "55":6,
            "56":4,
            "41":4,
            "42":1,
            "28":7
         },
         "secondaryMultipliers":{
            "48":0.11111111,
            "17":0.33333334,
            "49":0.055555556,
            "1":0.055555556,
            "18":0.055555556,
            "5":1.0,
            "53":0.16666667,
            "55":0.33333334,
            "56":0.22222222,
            "41":0.22222222,
            "42":0.055555556,
            "28":0.3888889
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":3,
            "Health":7,
            "Potency":6,
            "Offense":6,
            "Tenacity":1,
            "Protection":11,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.16666667,
            "Health":0.3888889,
            "Potency":0.33333334,
            "Offense":0.33333334,
            "Tenacity":0.055555556,
            "Protection":0.6111111,
            "Defense":0.11111111
         }
      },
      {
         "name":"CARTHONASI",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.06818182,
               "5":1.0,
               "54":0.06818182
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.2631579,
               "49":0.21052632,
               "53":0.84210527,
               "56":0.31578946
            },
            "5":{
               "55":0.6666667,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.5882353,
               "49":0.3529412,
               "18":0.23529412,
               "55":0.23529412,
               "56":0.5294118
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":3,
               "5":44,
               "54":3
            },
            "3":{
               "49":50
            },
            "4":{
               "16":19,
               "48":5,
               "49":4,
               "53":16,
               "56":6
            },
            "5":{
               "55":20,
               "56":30
            },
            "6":{
               "48":17,
               "17":10,
               "49":6,
               "18":4,
               "55":4,
               "56":9
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.2195122,
         "setCounts":{
            "1":19,
            "2":7,
            "3":4,
            "4":21,
            "5":21,
            "6":13,
            "7":4,
            "8":6
         },
         "fourSetMultipliers":{
            "1":0.9047619,
            "2":0.33333334,
            "3":0.1904762,
            "4":1.0,
            "5":1.0,
            "6":0.61904764,
            "7":0.1904762,
            "8":0.2857143
         },
         "twoSetMax":54.0,
         "twoSetCounts":{
            "1":19,
            "3":4,
            "5":21,
            "7":4,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":7,
            "17":3,
            "49":4,
            "18":3,
            "5":14,
            "53":3,
            "55":1,
            "56":3,
            "41":9,
            "42":1,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.2857143,
            "1":0.5,
            "17":0.21428572,
            "49":0.2857143,
            "18":0.21428572,
            "5":1.0,
            "53":0.21428572,
            "55":0.071428575,
            "56":0.21428572,
            "41":0.64285713,
            "42":0.071428575,
            "28":0.21428572
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":3,
            "Health":8,
            "Potency":3,
            "Offense":13,
            "Tenacity":3,
            "Protection":6,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.21428572,
            "Health":0.5714286,
            "Potency":0.21428572,
            "Offense":0.9285714,
            "Tenacity":0.21428572,
            "Protection":0.42857143,
            "Defense":0.35714287
         }
      },
      {
         "name":"WICKET",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.06521739,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.3043478,
               "53":0.5652174,
               "55":0.04347826,
               "56":0.17391305
            },
            "5":{
               "55":0.53125,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.36842105,
               "49":0.15789473,
               "18":0.21052632,
               "55":0.36842105,
               "56":0.42105263
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":3,
               "5":46
            },
            "3":{
               "49":49
            },
            "4":{
               "16":23,
               "48":7,
               "53":13,
               "55":1,
               "56":4
            },
            "5":{
               "55":17,
               "56":32
            },
            "6":{
               "48":19,
               "17":7,
               "49":3,
               "18":4,
               "55":7,
               "56":8
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.19512194,
         "setCounts":{
            "1":10,
            "2":3,
            "3":1,
            "4":6,
            "5":43,
            "6":32,
            "7":3,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.23255815,
            "2":0.09375,
            "3":0.023255814,
            "4":0.1875,
            "5":1.0,
            "6":1.0,
            "7":0.069767445,
            "8":0.023255814
         },
         "twoSetMax":90.77777,
         "twoSetCounts":{
            "1":10,
            "3":1,
            "5":43,
            "7":3,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":43.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":28.666666
            },
            {
               "set":5,
               "occurrence":3,
               "count":19.11111
            },
            {
               "set":1,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "1":3,
            "17":5,
            "49":2,
            "18":5,
            "5":11,
            "53":6,
            "55":4,
            "56":2,
            "41":3,
            "42":4,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.09090909,
            "1":0.27272728,
            "17":0.45454547,
            "49":0.18181819,
            "18":0.45454547,
            "5":1.0,
            "53":0.54545456,
            "55":0.36363637,
            "56":0.18181819,
            "41":0.27272728,
            "42":0.36363637,
            "28":0.27272728
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":6,
            "Health":7,
            "Potency":5,
            "Offense":4,
            "Tenacity":5,
            "Protection":5,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.54545456,
            "Health":0.6363636,
            "Potency":0.45454547,
            "Offense":0.36363637,
            "Tenacity":0.45454547,
            "Protection":0.45454547,
            "Defense":0.54545456
         }
      },
      {
         "name":"MACEWINDU",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.14285715,
               "49":0.028571429,
               "52":0.028571429,
               "5":1.0,
               "54":0.14285715,
               "56":0.028571429
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.47619048,
               "16":1.0,
               "49":0.1904762,
               "53":0.33333334,
               "55":0.0952381,
               "56":0.23809524
            },
            "5":{
               "55":0.88,
               "56":1.0
            },
            "6":{
               "48":0.90909094,
               "17":0.8181818,
               "49":0.8181818,
               "18":0.45454547,
               "55":0.45454547,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":5,
               "49":1,
               "52":1,
               "5":35,
               "54":5,
               "56":1
            },
            "3":{
               "49":49
            },
            "4":{
               "48":10,
               "16":21,
               "49":4,
               "53":7,
               "55":2,
               "56":5
            },
            "5":{
               "55":22,
               "56":25
            },
            "6":{
               "48":10,
               "17":9,
               "49":9,
               "18":5,
               "55":5,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.96,
         "setCounts":{
            "1":47,
            "2":7,
            "3":7,
            "4":11,
            "5":7,
            "6":7,
            "7":6,
            "8":3
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.6363636,
            "3":0.14893617,
            "4":1.0,
            "5":0.14893617,
            "6":0.6363636,
            "7":0.12765957,
            "8":0.06382979
         },
         "twoSetMax":99.22223,
         "twoSetCounts":{
            "1":47,
            "3":7,
            "5":7,
            "7":6,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":47.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":31.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":20.88889
            },
            {
               "set":3,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":5,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":2,
            "49":4,
            "17":2,
            "18":7,
            "5":8,
            "53":16,
            "55":1,
            "56":4,
            "41":10,
            "42":2,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.25,
            "1":0.125,
            "49":0.25,
            "17":0.125,
            "18":0.4375,
            "5":0.5,
            "53":1.0,
            "55":0.0625,
            "56":0.25,
            "41":0.625,
            "42":0.125,
            "28":0.0625
         },
         "secondaryTypeCounts":{
            "Speed":8,
            "Critical Chance":16,
            "Health":3,
            "Potency":2,
            "Offense":14,
            "Tenacity":7,
            "Protection":5,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":0.5,
            "Critical Chance":1.0,
            "Health":0.1875,
            "Potency":0.125,
            "Offense":0.875,
            "Tenacity":0.4375,
            "Protection":0.3125,
            "Defense":0.375
         }
      },
      {
         "name":"DATHCHA",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.10344828,
               "49":0.06896552,
               "5":1.0,
               "54":0.1724138,
               "55":0.13793103,
               "56":0.20689656
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.6923077,
               "48":0.53846157,
               "49":0.30769232,
               "53":0.61538464,
               "55":0.61538464,
               "56":1.0
            },
            "5":{
               "55":0.58064514,
               "56":1.0
            },
            "6":{
               "48":0.1904762,
               "17":0.42857143,
               "49":0.14285715,
               "18":0.2857143,
               "55":0.2857143,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":3,
               "49":2,
               "5":29,
               "54":5,
               "55":4,
               "56":6
            },
            "3":{
               "49":49
            },
            "4":{
               "16":9,
               "48":7,
               "49":4,
               "53":8,
               "55":8,
               "56":13
            },
            "5":{
               "55":18,
               "56":31
            },
            "6":{
               "48":4,
               "17":9,
               "49":3,
               "18":6,
               "55":6,
               "56":21
            }
         },
         "fourSetsMultiplier":0.75,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":56,
            "2":2,
            "3":3,
            "4":11,
            "5":15,
            "6":8,
            "7":5,
            "8":8
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.18181819,
            "3":0.05357143,
            "4":1.0,
            "5":0.26785713,
            "6":0.72727275,
            "7":0.08928572,
            "8":0.14285715
         },
         "twoSetMax":118.22221,
         "twoSetCounts":{
            "1":56,
            "3":3,
            "5":15,
            "7":5,
            "8":8
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":56.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":37.333332
            },
            {
               "set":1,
               "occurrence":3,
               "count":24.888887
            },
            {
               "set":5,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":8,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":7,
            "49":2,
            "17":3,
            "1":2,
            "18":2,
            "5":14,
            "53":5,
            "55":1,
            "56":2,
            "41":6,
            "42":6,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.5,
            "49":0.14285715,
            "17":0.21428572,
            "1":0.14285715,
            "18":0.14285715,
            "5":1.0,
            "53":0.35714287,
            "55":0.071428575,
            "56":0.14285715,
            "41":0.42857143,
            "42":0.42857143,
            "28":0.071428575
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":5,
            "Health":3,
            "Potency":3,
            "Offense":13,
            "Tenacity":2,
            "Protection":3,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.35714287,
            "Health":0.21428572,
            "Potency":0.21428572,
            "Offense":0.9285714,
            "Tenacity":0.14285715,
            "Protection":0.21428572,
            "Defense":0.5714286
         }
      },
      {
         "name":"TEEBO",
         "count":47,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.027027028,
               "49":0.027027028,
               "52":0.027027028,
               "5":1.0,
               "54":0.054054055,
               "55":0.08108108,
               "56":0.054054055
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.9166667,
               "16":0.75,
               "49":0.5,
               "53":0.5,
               "55":0.25,
               "56":1.0
            },
            "5":{
               "55":0.7037037,
               "56":1.0
            },
            "6":{
               "48":0.3529412,
               "17":1.0,
               "49":0.23529412,
               "18":0.23529412,
               "55":0.29411766,
               "56":0.64705884
            }
         },
         "primaryCounts":{
            "1":{
               "48":47
            },
            "2":{
               "48":1,
               "49":1,
               "52":1,
               "5":37,
               "54":2,
               "55":3,
               "56":2
            },
            "3":{
               "49":47
            },
            "4":{
               "48":11,
               "16":9,
               "49":6,
               "53":6,
               "55":3,
               "56":12
            },
            "5":{
               "55":19,
               "56":27
            },
            "6":{
               "48":6,
               "17":17,
               "49":4,
               "18":4,
               "55":5,
               "56":11
            }
         },
         "fourSetsMultiplier":0.8076923,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":30,
            "2":4,
            "3":5,
            "4":17,
            "5":1,
            "6":0,
            "7":39,
            "8":7
         },
         "fourSetMultipliers":{
            "1":0.7692308,
            "2":0.23529412,
            "3":0.12820514,
            "4":1.0,
            "5":0.025641026,
            "6":0.0,
            "7":1.0,
            "8":0.17948718
         },
         "twoSetMax":95.0,
         "twoSetCounts":{
            "1":30,
            "3":5,
            "5":1,
            "7":39,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":39.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":30.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":26.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":20.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":17.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":13.333333
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"potency",
         "commonSet2Name":"health",
         "commonSet3Name":"potency",
         "secondaryCounts":{
            "48":1,
            "1":3,
            "17":5,
            "49":3,
            "18":8,
            "5":12,
            "53":8,
            "55":2,
            "56":4,
            "41":4,
            "42":2,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.083333336,
            "1":0.25,
            "17":0.41666666,
            "49":0.25,
            "18":0.6666667,
            "5":1.0,
            "53":0.6666667,
            "55":0.16666667,
            "56":0.33333334,
            "41":0.33333334,
            "42":0.16666667,
            "28":0.33333334
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":8,
            "Health":5,
            "Potency":5,
            "Offense":5,
            "Tenacity":8,
            "Protection":8,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.6666667,
            "Health":0.41666666,
            "Potency":0.41666666,
            "Offense":0.41666666,
            "Tenacity":0.6666667,
            "Protection":0.6666667,
            "Defense":0.41666666
         }
      },
      {
         "name":"SNOWTROOPER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.8148148,
               "5":1.0,
               "54":0.037037037
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.17948718,
               "53":0.07692308,
               "55":0.025641026
            },
            "5":{
               "55":0.9230769,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.023255814,
               "49":0.023255814,
               "18":0.023255814,
               "56":0.093023255
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":22,
               "5":27,
               "54":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":39,
               "48":7,
               "53":3,
               "55":1
            },
            "5":{
               "55":24,
               "56":26
            },
            "6":{
               "48":43,
               "17":1,
               "49":1,
               "18":1,
               "56":4
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.08695652,
         "setCounts":{
            "1":5,
            "2":6,
            "3":1,
            "4":0,
            "5":41,
            "6":40,
            "7":0,
            "8":2
         },
         "fourSetMultipliers":{
            "1":0.12195122,
            "2":0.15,
            "3":0.024390243,
            "4":0.0,
            "5":1.0,
            "6":1.0,
            "7":0.0,
            "8":0.048780486
         },
         "twoSetMax":86.55556,
         "twoSetCounts":{
            "1":5,
            "3":1,
            "5":41,
            "7":0,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":41.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":27.333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":18.222223
            },
            {
               "set":1,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":7,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "49":1,
            "17":4,
            "1":1,
            "18":1,
            "5":14,
            "53":13,
            "55":5,
            "56":1,
            "41":3,
            "42":8,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.21428572,
            "49":0.071428575,
            "17":0.2857143,
            "1":0.071428575,
            "18":0.071428575,
            "5":1.0,
            "53":0.9285714,
            "55":0.35714287,
            "56":0.071428575,
            "41":0.21428572,
            "42":0.5714286,
            "28":0.21428572
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":13,
            "Health":6,
            "Potency":4,
            "Offense":6,
            "Tenacity":1,
            "Protection":4,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.9285714,
            "Health":0.42857143,
            "Potency":0.2857143,
            "Offense":0.42857143,
            "Tenacity":0.071428575,
            "Protection":0.2857143,
            "Defense":0.64285713
         }
      },
      {
         "name":"SHORETROOPER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":0.75,
               "54":0.4,
               "55":0.35,
               "56":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.02631579,
               "49":0.10526316,
               "55":0.18421052,
               "56":1.0
            },
            "5":{
               "55":0.25,
               "56":1.0
            },
            "6":{
               "49":0.057142857,
               "17":0.028571429,
               "18":0.114285715,
               "55":0.22857143,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":15,
               "54":8,
               "55":7,
               "56":20
            },
            "3":{
               "49":50
            },
            "4":{
               "48":1,
               "49":4,
               "55":7,
               "56":38
            },
            "5":{
               "55":10,
               "56":40
            },
            "6":{
               "49":2,
               "17":1,
               "18":4,
               "55":8,
               "56":35
            }
         },
         "fourSetsMultiplier":0.041666668,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":116,
            "3":26,
            "4":2,
            "8":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.22413793,
            "4":1.0,
            "8":0.00862069
         },
         "twoSetMax":244.8889,
         "twoSetCounts":{
            "1":116,
            "3":26,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":116.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":77.333336
            },
            {
               "set":1,
               "occurrence":3,
               "count":51.555557
            },
            {
               "set":3,
               "occurrence":1,
               "count":26.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":17.333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":11.555556
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":1,
            "49":3,
            "17":4,
            "1":7,
            "18":1,
            "5":2,
            "53":6,
            "55":7,
            "56":1,
            "41":6,
            "42":1,
            "28":15
         },
         "secondaryMultipliers":{
            "48":0.06666667,
            "49":0.2,
            "17":0.26666668,
            "1":0.46666667,
            "18":0.06666667,
            "5":0.13333334,
            "53":0.4,
            "55":0.46666667,
            "56":0.06666667,
            "41":0.4,
            "42":0.06666667,
            "28":1.0
         },
         "secondaryTypeCounts":{
            "Speed":2,
            "Critical Chance":6,
            "Health":14,
            "Potency":4,
            "Offense":7,
            "Tenacity":1,
            "Protection":16,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":0.125,
            "Critical Chance":0.375,
            "Health":0.875,
            "Potency":0.25,
            "Offense":0.4375,
            "Tenacity":0.0625,
            "Protection":1.0,
            "Defense":0.25
         }
      },
      {
         "name":"PRINCESSLEIA",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.021276595,
               "5":1.0,
               "54":0.021276595
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.30769232,
               "53":0.3846154,
               "55":0.115384616,
               "56":0.03846154
            },
            "5":{
               "55":0.84615386,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "49":0.0952381,
               "17":0.04761905,
               "18":0.0952381,
               "55":0.52380955,
               "56":0.52380955
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":1,
               "5":47,
               "54":1
            },
            "3":{
               "49":49
            },
            "4":{
               "16":26,
               "48":8,
               "53":10,
               "55":3,
               "56":1
            },
            "5":{
               "55":22,
               "56":26
            },
            "6":{
               "48":21,
               "49":2,
               "17":1,
               "18":2,
               "55":11,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.25641027,
         "setCounts":{
            "1":14,
            "2":2,
            "3":2,
            "4":13,
            "5":28,
            "6":24,
            "7":0,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.5,
            "2":0.083333336,
            "3":0.071428575,
            "4":0.5416667,
            "5":1.0,
            "6":1.0,
            "7":0.0,
            "8":0.035714287
         },
         "twoSetMax":60.666664,
         "twoSetCounts":{
            "1":14,
            "3":2,
            "5":28,
            "7":0,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":28.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":18.666666
            },
            {
               "set":1,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":12.444444
            },
            {
               "set":1,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":7,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":3,
            "1":2,
            "17":2,
            "18":7,
            "53":2,
            "5":15,
            "55":2,
            "56":1,
            "41":7,
            "42":5,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.13333334,
            "49":0.2,
            "1":0.13333334,
            "17":0.13333334,
            "18":0.46666667,
            "53":0.13333334,
            "5":1.0,
            "55":0.13333334,
            "56":0.06666667,
            "41":0.46666667,
            "42":0.33333334,
            "28":0.26666668
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":2,
            "Health":4,
            "Potency":2,
            "Offense":9,
            "Tenacity":7,
            "Protection":5,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.13333334,
            "Health":0.26666668,
            "Potency":0.13333334,
            "Offense":0.6,
            "Tenacity":0.46666667,
            "Protection":0.33333334,
            "Defense":0.53333336
         }
      },
      {
         "name":"SAVAGEOPRESS",
         "count":48,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.023809524,
               "49":0.023809524,
               "5":1.0,
               "54":0.04761905,
               "56":0.023809524
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.31578946,
               "49":0.21052632,
               "53":0.21052632,
               "55":0.21052632,
               "56":0.57894737
            },
            "5":{
               "55":1.0,
               "56":0.92
            },
            "6":{
               "48":0.64285713,
               "49":0.42857143,
               "17":0.2857143,
               "18":1.0,
               "55":0.5,
               "56":0.5
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "48":1,
               "49":1,
               "5":42,
               "54":2,
               "56":1
            },
            "3":{
               "49":48
            },
            "4":{
               "16":19,
               "48":6,
               "49":4,
               "53":4,
               "55":4,
               "56":11
            },
            "5":{
               "55":25,
               "56":23
            },
            "6":{
               "48":9,
               "49":6,
               "17":4,
               "18":14,
               "55":7,
               "56":7
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.84615386,
         "setCounts":{
            "1":52,
            "2":7,
            "3":5,
            "4":8,
            "5":11,
            "6":11,
            "7":2,
            "8":10
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.6363636,
            "3":0.09615385,
            "4":0.72727275,
            "5":0.21153846,
            "6":1.0,
            "7":0.03846154,
            "8":0.1923077
         },
         "twoSetMax":109.77779,
         "twoSetCounts":{
            "1":52,
            "3":5,
            "5":11,
            "7":2,
            "8":10
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":52.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":34.666668
            },
            {
               "set":1,
               "occurrence":3,
               "count":23.111113
            },
            {
               "set":5,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":8,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":8,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "49":2,
            "1":6,
            "17":1,
            "18":5,
            "5":14,
            "53":7,
            "55":1,
            "56":2,
            "41":3,
            "42":3,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.21428572,
            "49":0.14285715,
            "1":0.42857143,
            "17":0.071428575,
            "18":0.35714287,
            "5":1.0,
            "53":0.5,
            "55":0.071428575,
            "56":0.14285715,
            "41":0.21428572,
            "42":0.21428572,
            "28":0.21428572
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":7,
            "Health":7,
            "Potency":1,
            "Offense":6,
            "Tenacity":5,
            "Protection":5,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.5,
            "Health":0.5,
            "Potency":0.071428575,
            "Offense":0.42857143,
            "Tenacity":0.35714287,
            "Protection":0.35714287,
            "Defense":0.35714287
         }
      },
      {
         "name":"FINN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.13513513,
               "49":0.027027028,
               "5":1.0,
               "54":0.08108108,
               "56":0.10810811
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.11111111,
               "49":0.037037037,
               "53":0.11111111,
               "55":0.18518518,
               "56":0.4074074
            },
            "5":{
               "55":0.47058824,
               "56":1.0
            },
            "6":{
               "48":0.5294118,
               "17":1.0,
               "49":0.1764706,
               "18":0.1764706,
               "55":0.3529412,
               "56":0.7058824
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":5,
               "49":1,
               "5":37,
               "54":3,
               "56":4
            },
            "3":{
               "49":50
            },
            "4":{
               "16":27,
               "48":3,
               "49":1,
               "53":3,
               "55":5,
               "56":11
            },
            "5":{
               "55":16,
               "56":34
            },
            "6":{
               "48":9,
               "17":17,
               "49":3,
               "18":3,
               "55":6,
               "56":12
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.2820513,
         "setCounts":{
            "1":15,
            "2":6,
            "3":3,
            "4":8,
            "5":19,
            "6":25,
            "7":21,
            "8":5
         },
         "fourSetMultipliers":{
            "1":0.71428573,
            "2":0.24,
            "3":0.14285715,
            "4":0.32,
            "5":0.9047619,
            "6":1.0,
            "7":1.0,
            "8":0.23809524
         },
         "twoSetMax":55.0,
         "twoSetCounts":{
            "1":15,
            "3":3,
            "5":19,
            "7":21,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":1,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":5,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":1,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":6,
            "49":1,
            "17":2,
            "1":1,
            "18":5,
            "5":11,
            "53":3,
            "55":1,
            "56":6,
            "41":8,
            "42":8,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.54545456,
            "49":0.09090909,
            "17":0.18181819,
            "1":0.09090909,
            "18":0.45454547,
            "5":1.0,
            "53":0.27272728,
            "55":0.09090909,
            "56":0.54545456,
            "41":0.72727275,
            "42":0.72727275,
            "28":0.27272728
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":3,
            "Health":2,
            "Potency":2,
            "Offense":14,
            "Tenacity":5,
            "Protection":9,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":0.78571427,
            "Critical Chance":0.21428572,
            "Health":0.14285715,
            "Potency":0.14285715,
            "Offense":1.0,
            "Tenacity":0.35714287,
            "Protection":0.64285713,
            "Defense":0.64285713
         }
      },
      {
         "name":"EZRABRIDGERS3",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.06521739,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.21621622,
               "53":0.054054055,
               "55":0.054054055
            },
            "5":{
               "55":0.58064514,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.17857143,
               "49":0.035714287,
               "18":0.17857143,
               "55":0.14285715,
               "56":0.21428572
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":3,
               "5":46
            },
            "3":{
               "49":49
            },
            "4":{
               "16":37,
               "48":8,
               "53":2,
               "55":2
            },
            "5":{
               "55":18,
               "56":31
            },
            "6":{
               "48":28,
               "17":5,
               "49":1,
               "18":5,
               "55":4,
               "56":6
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.020833334,
         "setCounts":{
            "1":12,
            "2":18,
            "4":8,
            "5":30,
            "6":22,
            "7":0,
            "8":2
         },
         "fourSetMultipliers":{
            "1":0.4,
            "2":0.8181818,
            "4":0.36363637,
            "5":1.0,
            "6":1.0,
            "7":0.0,
            "8":0.06666667
         },
         "twoSetMax":63.333332,
         "twoSetCounts":{
            "1":12,
            "5":30,
            "7":0,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":30.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":20.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":13.333333
            },
            {
               "set":1,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":7,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "49":4,
            "1":1,
            "17":2,
            "18":4,
            "5":13,
            "53":3,
            "55":6,
            "56":3,
            "41":3,
            "42":1,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.30769232,
            "49":0.30769232,
            "1":0.07692308,
            "17":0.15384616,
            "18":0.30769232,
            "5":1.0,
            "53":0.23076923,
            "55":0.46153846,
            "56":0.23076923,
            "41":0.23076923,
            "42":0.07692308,
            "28":0.3846154
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":3,
            "Health":7,
            "Potency":2,
            "Offense":7,
            "Tenacity":4,
            "Protection":8,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.23076923,
            "Health":0.53846157,
            "Potency":0.15384616,
            "Offense":0.53846157,
            "Tenacity":0.30769232,
            "Protection":0.61538464,
            "Defense":0.3846154
         }
      },
      {
         "name":"AAYLASECURA",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.06666667,
               "5":1.0,
               "56":0.022222223
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.6818182,
               "48":0.18181819,
               "53":1.0,
               "55":0.18181819,
               "56":0.18181819
            },
            "5":{
               "55":0.6896552,
               "56":1.0
            },
            "6":{
               "48":0.5714286,
               "17":1.0,
               "49":0.04761905,
               "55":0.2857143,
               "56":0.42857143
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":3,
               "5":45,
               "56":1
            },
            "3":{
               "49":49
            },
            "4":{
               "16":15,
               "48":4,
               "53":22,
               "55":4,
               "56":4
            },
            "5":{
               "55":20,
               "56":29
            },
            "6":{
               "48":12,
               "17":21,
               "49":1,
               "55":6,
               "56":9
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.8148148,
         "setCounts":{
            "1":19,
            "2":6,
            "3":0,
            "4":10,
            "5":43,
            "6":11,
            "7":20,
            "8":3
         },
         "fourSetMultipliers":{
            "1":0.44186047,
            "2":0.54545456,
            "3":0.0,
            "4":0.90909094,
            "5":1.0,
            "6":1.0,
            "7":0.4651163,
            "8":0.069767445
         },
         "twoSetMax":91.666664,
         "twoSetCounts":{
            "1":19,
            "3":0,
            "5":43,
            "7":20,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":43.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":28.666666
            },
            {
               "set":7,
               "occurrence":1,
               "count":20.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":19.11111
            },
            {
               "set":1,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":13.333333
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":8.888888
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "49":1,
            "17":3,
            "1":7,
            "18":5,
            "5":13,
            "53":4,
            "55":4,
            "56":2,
            "41":3,
            "42":3,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.3846154,
            "49":0.07692308,
            "17":0.23076923,
            "1":0.53846157,
            "18":0.3846154,
            "5":1.0,
            "53":0.30769232,
            "55":0.30769232,
            "56":0.15384616,
            "41":0.23076923,
            "42":0.23076923,
            "28":0.07692308
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":4,
            "Health":11,
            "Potency":3,
            "Offense":8,
            "Tenacity":5,
            "Protection":3,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.30769232,
            "Health":0.84615386,
            "Potency":0.23076923,
            "Offense":0.61538464,
            "Tenacity":0.3846154,
            "Protection":0.23076923,
            "Defense":0.30769232
         }
      },
      {
         "name":"RESISTANCEPILOT",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.44827586,
               "52":0.03448276,
               "5":1.0,
               "54":0.03448276,
               "55":0.10344828,
               "56":0.10344828
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.26923078,
               "49":0.115384616,
               "53":0.26923078,
               "55":0.07692308,
               "56":0.1923077
            },
            "5":{
               "55":1.0,
               "56":1.0
            },
            "6":{
               "48":0.73333335,
               "17":1.0,
               "49":0.26666668,
               "18":0.6666667,
               "55":0.33333334,
               "56":0.33333334
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":13,
               "52":1,
               "5":29,
               "54":1,
               "55":3,
               "56":3
            },
            "3":{
               "49":50
            },
            "4":{
               "16":26,
               "48":7,
               "49":3,
               "53":7,
               "55":2,
               "56":5
            },
            "5":{
               "55":25,
               "56":25
            },
            "6":{
               "48":11,
               "17":15,
               "49":4,
               "18":10,
               "55":5,
               "56":5
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.7241379,
         "setCounts":{
            "1":12,
            "2":5,
            "3":1,
            "4":14,
            "5":17,
            "6":10,
            "7":18,
            "8":3
         },
         "fourSetMultipliers":{
            "1":0.6666667,
            "2":0.35714287,
            "3":0.055555556,
            "4":1.0,
            "5":0.9444444,
            "6":0.71428573,
            "7":1.0,
            "8":0.16666667
         },
         "twoSetMax":47.0,
         "twoSetCounts":{
            "1":12,
            "3":1,
            "5":17,
            "7":18,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":1,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":1,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "1":2,
            "49":4,
            "17":9,
            "18":2,
            "5":15,
            "53":5,
            "55":2,
            "56":2,
            "41":3,
            "42":2,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.06666667,
            "1":0.13333334,
            "49":0.26666668,
            "17":0.6,
            "18":0.13333334,
            "5":1.0,
            "53":0.33333334,
            "55":0.13333334,
            "56":0.13333334,
            "41":0.2,
            "42":0.13333334,
            "28":0.33333334
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":5,
            "Health":4,
            "Potency":9,
            "Offense":4,
            "Tenacity":2,
            "Protection":7,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.33333334,
            "Health":0.26666668,
            "Potency":0.6,
            "Offense":0.26666668,
            "Tenacity":0.13333334,
            "Protection":0.46666667,
            "Defense":0.4
         }
      },
      {
         "name":"AURRA_SING",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.08888889,
               "5":1.0,
               "56":0.022222223
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.16,
               "49":0.08,
               "53":0.48,
               "55":0.12,
               "56":0.16
            },
            "5":{
               "55":0.61290324,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.6666667,
               "49":0.16666667,
               "18":0.22222222,
               "55":0.2777778,
               "56":0.44444445
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":4,
               "5":45,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":25,
               "48":4,
               "49":2,
               "53":12,
               "55":3,
               "56":4
            },
            "5":{
               "55":19,
               "56":31
            },
            "6":{
               "48":18,
               "17":12,
               "49":3,
               "18":4,
               "55":5,
               "56":8
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.1627907,
         "setCounts":{
            "1":18,
            "2":7,
            "3":1,
            "4":11,
            "5":26,
            "6":25,
            "7":2,
            "8":4
         },
         "fourSetMultipliers":{
            "1":0.6923077,
            "2":0.28,
            "3":0.03846154,
            "4":0.44,
            "5":1.0,
            "6":1.0,
            "7":0.07692308,
            "8":0.15384616
         },
         "twoSetMax":61.333336,
         "twoSetCounts":{
            "1":18,
            "3":1,
            "5":26,
            "7":2,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":26.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":17.333334
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":11.555556
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "17":2,
            "1":3,
            "49":3,
            "18":3,
            "5":13,
            "53":5,
            "55":1,
            "56":3,
            "41":8,
            "42":3,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.30769232,
            "17":0.15384616,
            "1":0.23076923,
            "49":0.23076923,
            "18":0.23076923,
            "5":1.0,
            "53":0.3846154,
            "55":0.07692308,
            "56":0.23076923,
            "41":0.61538464,
            "42":0.23076923,
            "28":0.23076923
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":5,
            "Health":4,
            "Potency":2,
            "Offense":12,
            "Tenacity":3,
            "Protection":6,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.3846154,
            "Health":0.30769232,
            "Potency":0.15384616,
            "Offense":0.9230769,
            "Tenacity":0.23076923,
            "Protection":0.46153846,
            "Defense":0.46153846
         }
      },
      {
         "name":"CADBANE",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.07692308,
               "49":0.025641026,
               "5":1.0,
               "54":0.15384616,
               "56":0.025641026
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.2857143,
               "53":0.5714286,
               "55":0.1904762,
               "56":0.33333334
            },
            "5":{
               "55":0.61290324,
               "56":1.0
            },
            "6":{
               "48":0.64705884,
               "17":1.0,
               "49":0.11764706,
               "18":0.11764706,
               "55":0.7058824,
               "56":0.3529412
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":3,
               "49":1,
               "5":39,
               "54":6,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":21,
               "48":6,
               "53":12,
               "55":4,
               "56":7
            },
            "5":{
               "55":19,
               "56":31
            },
            "6":{
               "48":11,
               "17":17,
               "49":2,
               "18":2,
               "55":12,
               "56":6
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.2820513,
         "setCounts":{
            "1":9,
            "2":8,
            "3":2,
            "4":11,
            "5":23,
            "6":20,
            "7":17,
            "8":4
         },
         "fourSetMultipliers":{
            "1":0.39130434,
            "2":0.4,
            "3":0.08695652,
            "4":0.55,
            "5":1.0,
            "6":1.0,
            "7":0.73913044,
            "8":0.17391305
         },
         "twoSetMax":55.333332,
         "twoSetCounts":{
            "1":9,
            "3":2,
            "5":23,
            "7":17,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":23.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":15.333333
            },
            {
               "set":7,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":5,
               "occurrence":3,
               "count":10.222222
            },
            {
               "set":1,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":1,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "49":6,
            "17":6,
            "1":7,
            "18":6,
            "5":12,
            "53":4,
            "55":5,
            "56":1,
            "41":1,
            "42":1,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.33333334,
            "49":0.5,
            "17":0.5,
            "1":0.5833333,
            "18":0.5,
            "5":1.0,
            "53":0.33333334,
            "55":0.41666666,
            "56":0.083333336,
            "41":0.083333336,
            "42":0.083333336,
            "28":0.083333336
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":4,
            "Health":12,
            "Potency":6,
            "Offense":5,
            "Tenacity":6,
            "Protection":2,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.33333334,
            "Health":1.0,
            "Potency":0.5,
            "Offense":0.41666666,
            "Tenacity":0.5,
            "Protection":0.16666667,
            "Defense":0.5833333
         }
      },
      {
         "name":"TIEFIGHTERPILOT",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.05,
               "49":0.025,
               "52":0.025,
               "5":1.0,
               "54":0.1,
               "55":0.025,
               "56":0.025
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.30769232,
               "49":0.03846154,
               "53":0.1923077,
               "55":0.26923078,
               "56":0.115384616
            },
            "5":{
               "55":0.5625,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.9230769,
               "49":0.3846154,
               "18":0.61538464,
               "55":0.46153846,
               "56":0.46153846
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":2,
               "49":1,
               "52":1,
               "5":40,
               "54":4,
               "55":1,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":26,
               "48":8,
               "49":1,
               "53":5,
               "55":7,
               "56":3
            },
            "5":{
               "55":18,
               "56":32
            },
            "6":{
               "48":13,
               "17":12,
               "49":5,
               "18":8,
               "55":6,
               "56":6
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.6666667,
         "setCounts":{
            "1":11,
            "2":1,
            "3":4,
            "4":22,
            "5":24,
            "6":7,
            "7":11,
            "8":7
         },
         "fourSetMultipliers":{
            "1":0.45833334,
            "2":0.045454547,
            "3":0.16666667,
            "4":1.0,
            "5":1.0,
            "6":0.3181818,
            "7":0.45833334,
            "8":0.29166666
         },
         "twoSetMax":51.0,
         "twoSetCounts":{
            "1":11,
            "3":4,
            "5":24,
            "7":11,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":24.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":16.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":10.666667
            },
            {
               "set":1,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":7,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "17":4,
            "49":1,
            "1":5,
            "18":5,
            "5":15,
            "53":9,
            "55":1,
            "56":1,
            "41":3,
            "42":2,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.06666667,
            "17":0.26666668,
            "49":0.06666667,
            "1":0.33333334,
            "18":0.33333334,
            "5":1.0,
            "53":0.6,
            "55":0.06666667,
            "56":0.06666667,
            "41":0.2,
            "42":0.13333334,
            "28":0.06666667
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":9,
            "Health":6,
            "Potency":4,
            "Offense":4,
            "Tenacity":5,
            "Protection":2,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.6,
            "Health":0.4,
            "Potency":0.26666668,
            "Offense":0.26666668,
            "Tenacity":0.33333334,
            "Protection":0.13333334,
            "Defense":0.2
         }
      },
      {
         "name":"BB8",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.04255319
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.5294118,
               "48":0.3529412,
               "49":0.4117647,
               "53":1.0,
               "55":0.23529412,
               "56":0.3529412
            },
            "5":{
               "55":0.58064514,
               "56":1.0
            },
            "6":{
               "48":0.22727273,
               "49":0.22727273,
               "17":0.13636364,
               "18":0.22727273,
               "55":0.4090909,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "5":47,
               "54":2
            },
            "3":{
               "49":49
            },
            "4":{
               "16":9,
               "48":6,
               "49":7,
               "53":17,
               "55":4,
               "56":6
            },
            "5":{
               "55":18,
               "56":31
            },
            "6":{
               "48":5,
               "49":5,
               "17":3,
               "18":5,
               "55":9,
               "56":22
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.020833334,
         "setCounts":{
            "1":15,
            "3":2,
            "4":48,
            "5":16,
            "6":0,
            "7":2,
            "8":4
         },
         "fourSetMultipliers":{
            "1":0.9375,
            "3":0.125,
            "4":1.0,
            "5":1.0,
            "6":0.0,
            "7":0.125,
            "8":0.25
         },
         "twoSetMax":41.0,
         "twoSetCounts":{
            "1":15,
            "3":2,
            "5":16,
            "7":2,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":16.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":10.666667
            },
            {
               "set":1,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":7.111111
            },
            {
               "set":1,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":4,
            "49":2,
            "17":2,
            "18":5,
            "5":18,
            "53":3,
            "55":4,
            "56":2,
            "41":2,
            "42":6,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.22222222,
            "1":0.22222222,
            "49":0.11111111,
            "17":0.11111111,
            "18":0.2777778,
            "5":1.0,
            "53":0.16666667,
            "55":0.22222222,
            "56":0.11111111,
            "41":0.11111111,
            "42":0.33333334,
            "28":0.22222222
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":3,
            "Health":8,
            "Potency":2,
            "Offense":6,
            "Tenacity":5,
            "Protection":6,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.16666667,
            "Health":0.44444445,
            "Potency":0.11111111,
            "Offense":0.33333334,
            "Tenacity":0.2777778,
            "Protection":0.33333334,
            "Defense":0.44444445
         }
      },
      {
         "name":"L3_37",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":0.09375,
               "54":0.25,
               "55":0.21875,
               "56":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "49":0.022222223,
               "55":0.08888889,
               "56":1.0
            },
            "5":{
               "55":0.1627907,
               "56":1.0
            },
            "6":{
               "49":0.025641026,
               "18":0.15384616,
               "55":0.102564104,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":3,
               "54":8,
               "55":7,
               "56":32
            },
            "3":{
               "49":50
            },
            "4":{
               "49":1,
               "55":4,
               "56":45
            },
            "5":{
               "55":7,
               "56":43
            },
            "6":{
               "49":1,
               "18":6,
               "55":4,
               "56":39
            }
         },
         "fourSetsMultiplier":0.0,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":100,
            "3":15,
            "6":0,
            "8":34
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.15,
            "6":"NaN",
            "8":0.34
         },
         "twoSetMax":211.1111,
         "twoSetCounts":{
            "1":100,
            "3":15,
            "8":34
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":100.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":66.666664
            },
            {
               "set":1,
               "occurrence":3,
               "count":44.444443
            },
            {
               "set":8,
               "occurrence":1,
               "count":34.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":22.666666
            },
            {
               "set":8,
               "occurrence":3,
               "count":15.111111
            },
            {
               "set":3,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":6.6666665
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":1,
            "1":2,
            "49":1,
            "17":2,
            "18":5,
            "5":16,
            "53":3,
            "55":5,
            "56":1,
            "41":2,
            "42":5,
            "28":7
         },
         "secondaryMultipliers":{
            "48":0.0625,
            "1":0.125,
            "49":0.0625,
            "17":0.125,
            "18":0.3125,
            "5":1.0,
            "53":0.1875,
            "55":0.3125,
            "56":0.0625,
            "41":0.125,
            "42":0.3125,
            "28":0.4375
         },
         "secondaryTypeCounts":{
            "Speed":16,
            "Critical Chance":3,
            "Health":7,
            "Potency":2,
            "Offense":3,
            "Tenacity":5,
            "Protection":8,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.1875,
            "Health":0.4375,
            "Potency":0.125,
            "Offense":0.1875,
            "Tenacity":0.3125,
            "Protection":0.5,
            "Defense":0.375
         }
      },
      {
         "name":"SITHTROOPER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "49":0.04347826,
               "5":0.17391305,
               "54":0.73913044,
               "55":0.2173913,
               "56":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "49":0.11627907,
               "55":0.046511628,
               "56":1.0
            },
            "5":{
               "55":0.06382979,
               "56":1.0
            },
            "6":{
               "49":0.06666667,
               "55":0.044444446,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "49":1,
               "5":4,
               "54":17,
               "55":5,
               "56":23
            },
            "3":{
               "49":50
            },
            "4":{
               "49":5,
               "55":2,
               "56":43
            },
            "5":{
               "55":3,
               "56":47
            },
            "6":{
               "49":3,
               "55":2,
               "56":45
            }
         },
         "fourSetsMultiplier":0.041666668,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":89,
            "3":47,
            "4":2,
            "8":9
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.5280899,
            "4":1.0,
            "8":0.101123594
         },
         "twoSetMax":195.33333,
         "twoSetCounts":{
            "1":89,
            "3":47,
            "8":9
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":89.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":59.333332
            },
            {
               "set":3,
               "occurrence":1,
               "count":47.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":39.555553
            },
            {
               "set":3,
               "occurrence":2,
               "count":31.333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":20.88889
            },
            {
               "set":8,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":4.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"defense",
         "secondaryCounts":{
            "48":4,
            "1":3,
            "17":3,
            "49":6,
            "18":2,
            "5":3,
            "53":1,
            "55":9,
            "56":3,
            "41":3,
            "42":5,
            "28":9
         },
         "secondaryMultipliers":{
            "48":0.44444445,
            "1":0.33333334,
            "17":0.33333334,
            "49":0.6666667,
            "18":0.22222222,
            "5":0.33333334,
            "53":0.11111111,
            "55":1.0,
            "56":0.33333334,
            "41":0.33333334,
            "42":0.5555556,
            "28":1.0
         },
         "secondaryTypeCounts":{
            "Speed":3,
            "Critical Chance":1,
            "Health":12,
            "Potency":3,
            "Offense":7,
            "Tenacity":2,
            "Protection":12,
            "Defense":11
         },
         "secondaryTypeMultipliers":{
            "Speed":0.25,
            "Critical Chance":0.083333336,
            "Health":1.0,
            "Potency":0.25,
            "Offense":0.5833333,
            "Tenacity":0.16666667,
            "Protection":1.0,
            "Defense":0.9166667
         }
      },
      {
         "name":"IG86SENTINELDROID",
         "count":46,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.05263158,
               "49":0.02631579,
               "5":1.0,
               "54":0.02631579,
               "55":0.02631579,
               "56":0.05263158
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.44444445,
               "49":0.11111111,
               "53":0.5,
               "55":0.055555556,
               "56":0.33333334
            },
            "5":{
               "55":0.36363637,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.42857143,
               "49":0.2857143,
               "18":0.5714286,
               "55":0.35714287,
               "56":0.5
            }
         },
         "primaryCounts":{
            "1":{
               "48":43
            },
            "2":{
               "48":2,
               "49":1,
               "5":38,
               "54":1,
               "55":1,
               "56":2
            },
            "3":{
               "49":45
            },
            "4":{
               "16":18,
               "48":8,
               "49":2,
               "53":9,
               "55":1,
               "56":6
            },
            "5":{
               "55":12,
               "56":33
            },
            "6":{
               "48":14,
               "17":6,
               "49":4,
               "18":8,
               "55":5,
               "56":7
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.64285713,
         "setCounts":{
            "1":15,
            "2":5,
            "3":2,
            "4":3,
            "5":21,
            "6":20,
            "7":2,
            "8":5
         },
         "fourSetMultipliers":{
            "1":0.71428573,
            "2":0.25,
            "3":0.0952381,
            "4":0.15,
            "5":1.0,
            "6":1.0,
            "7":0.0952381,
            "8":0.23809524
         },
         "twoSetMax":50.0,
         "twoSetCounts":{
            "1":15,
            "3":2,
            "5":21,
            "7":2,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":9,
            "1":8,
            "17":3,
            "18":4,
            "5":14,
            "53":2,
            "55":2,
            "56":2,
            "41":3,
            "42":1,
            "28":10
         },
         "secondaryMultipliers":{
            "48":0.14285715,
            "49":0.64285713,
            "1":0.5714286,
            "17":0.21428572,
            "18":0.2857143,
            "5":1.0,
            "53":0.14285715,
            "55":0.14285715,
            "56":0.14285715,
            "41":0.21428572,
            "42":0.071428575,
            "28":0.71428573
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":2,
            "Health":10,
            "Potency":3,
            "Offense":5,
            "Tenacity":4,
            "Protection":12,
            "Defense":10
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.14285715,
            "Health":0.71428573,
            "Potency":0.21428572,
            "Offense":0.35714287,
            "Tenacity":0.2857143,
            "Protection":0.85714287,
            "Defense":0.71428573
         }
      },
      {
         "name":"KYLOREN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "52":0.02173913,
               "5":1.0,
               "56":0.06521739
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.5,
               "16":1.0,
               "49":0.09090909,
               "53":0.22727273,
               "56":0.45454547
            },
            "5":{
               "55":0.9230769,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.3125,
               "18":0.4375,
               "55":0.5,
               "56":0.875
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "52":1,
               "5":46,
               "56":3
            },
            "3":{
               "49":49
            },
            "4":{
               "48":11,
               "16":22,
               "49":2,
               "53":5,
               "56":10
            },
            "5":{
               "55":24,
               "56":26
            },
            "6":{
               "48":16,
               "17":5,
               "18":7,
               "55":8,
               "56":14
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.2195122,
         "setCounts":{
            "1":19,
            "2":7,
            "3":5,
            "4":30,
            "5":11,
            "6":4,
            "7":7,
            "8":10
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.23333333,
            "3":0.2631579,
            "4":1.0,
            "5":0.57894737,
            "6":0.13333334,
            "7":0.36842105,
            "8":0.5263158
         },
         "twoSetMax":42.666668,
         "twoSetCounts":{
            "1":19,
            "3":5,
            "5":11,
            "7":7,
            "8":10
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":5,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":5,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":7,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":8,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "17":4,
            "49":3,
            "1":4,
            "18":3,
            "53":4,
            "5":18,
            "55":3,
            "56":3,
            "41":5,
            "42":3,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.16666667,
            "17":0.22222222,
            "49":0.16666667,
            "1":0.22222222,
            "18":0.16666667,
            "53":0.22222222,
            "5":1.0,
            "55":0.16666667,
            "56":0.16666667,
            "41":0.2777778,
            "42":0.16666667,
            "28":0.11111111
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":4,
            "Health":7,
            "Potency":4,
            "Offense":8,
            "Tenacity":3,
            "Protection":5,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.22222222,
            "Health":0.3888889,
            "Potency":0.22222222,
            "Offense":0.44444445,
            "Tenacity":0.16666667,
            "Protection":0.2777778,
            "Defense":0.33333334
         }
      },
      {
         "name":"DENGAR",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.61538464,
               "48":0.115384616,
               "49":0.07692308,
               "53":1.0,
               "56":0.07692308
            },
            "5":{
               "55":0.2195122,
               "56":1.0
            },
            "6":{
               "48":0.4347826,
               "17":1.0,
               "49":0.04347826,
               "18":0.04347826,
               "55":0.13043478,
               "56":0.47826087
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":49
            },
            "3":{
               "49":49
            },
            "4":{
               "16":16,
               "48":3,
               "49":2,
               "53":26,
               "56":2
            },
            "5":{
               "55":9,
               "56":41
            },
            "6":{
               "48":10,
               "17":23,
               "49":1,
               "18":1,
               "55":3,
               "56":11
            }
         },
         "fourSetsMultiplier":0.8518519,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":19,
            "2":1,
            "3":1,
            "4":17,
            "5":51,
            "6":5,
            "7":20,
            "8":4
         },
         "fourSetMultipliers":{
            "1":0.37254903,
            "2":0.05882353,
            "3":0.019607844,
            "4":1.0,
            "5":1.0,
            "6":0.29411766,
            "7":0.39215687,
            "8":0.078431375
         },
         "twoSetMax":107.666664,
         "twoSetCounts":{
            "1":19,
            "3":1,
            "5":51,
            "7":20,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":51.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":34.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":22.666666
            },
            {
               "set":7,
               "occurrence":1,
               "count":20.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":13.333333
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":8.888888
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critChance",
         "commonSet2Name":"critChance",
         "commonSet3Name":"critChance",
         "secondaryCounts":{
            "48":5,
            "1":2,
            "17":5,
            "49":1,
            "18":1,
            "5":17,
            "53":3,
            "55":1,
            "56":3,
            "41":6,
            "42":4,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.29411766,
            "1":0.11764706,
            "17":0.29411766,
            "49":0.05882353,
            "18":0.05882353,
            "5":1.0,
            "53":0.1764706,
            "55":0.05882353,
            "56":0.1764706,
            "41":0.3529412,
            "42":0.23529412,
            "28":0.1764706
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":3,
            "Health":3,
            "Potency":5,
            "Offense":11,
            "Tenacity":1,
            "Protection":6,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.1764706,
            "Health":0.1764706,
            "Potency":0.29411766,
            "Offense":0.64705884,
            "Tenacity":0.05882353,
            "Protection":0.3529412,
            "Defense":0.29411766
         }
      },
      {
         "name":"PAPLOO",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "52":0.022727273,
               "5":1.0,
               "54":0.045454547,
               "55":0.022727273,
               "56":0.022727273
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.5833333,
               "48":0.33333334,
               "49":1.0,
               "53":0.6666667,
               "55":0.5833333,
               "56":0.8333333
            },
            "5":{
               "55":0.4,
               "56":1.0
            },
            "6":{
               "48":0.23529412,
               "17":0.23529412,
               "49":0.4117647,
               "18":0.47058824,
               "55":0.5294118,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "52":1,
               "5":44,
               "54":2,
               "55":1,
               "56":1
            },
            "3":{
               "49":49
            },
            "4":{
               "16":7,
               "48":4,
               "49":12,
               "53":8,
               "55":7,
               "56":10
            },
            "5":{
               "55":14,
               "56":35
            },
            "6":{
               "48":4,
               "17":4,
               "49":7,
               "18":8,
               "55":9,
               "56":17
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.8148148,
         "setCounts":{
            "1":37,
            "2":0,
            "3":23,
            "4":27,
            "5":1,
            "6":0,
            "7":6,
            "8":9
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.6216216,
            "4":1.0,
            "5":0.027027028,
            "6":0.0,
            "7":0.16216215,
            "8":0.24324325
         },
         "twoSetMax":84.666664,
         "twoSetCounts":{
            "1":37,
            "3":23,
            "5":1,
            "7":6,
            "8":9
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":37.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":24.666666
            },
            {
               "set":3,
               "occurrence":1,
               "count":23.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":16.444445
            },
            {
               "set":3,
               "occurrence":2,
               "count":15.333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":10.222222
            },
            {
               "set":8,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "49":6,
            "1":2,
            "17":2,
            "18":4,
            "53":1,
            "5":15,
            "55":5,
            "56":3,
            "41":1,
            "42":2,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.26666668,
            "49":0.4,
            "1":0.13333334,
            "17":0.13333334,
            "18":0.26666668,
            "53":0.06666667,
            "5":1.0,
            "55":0.33333334,
            "56":0.2,
            "41":0.06666667,
            "42":0.13333334,
            "28":0.2
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":1,
            "Health":7,
            "Potency":2,
            "Offense":5,
            "Tenacity":4,
            "Protection":6,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.06666667,
            "Health":0.46666667,
            "Potency":0.13333334,
            "Offense":0.33333334,
            "Tenacity":0.26666668,
            "Protection":0.4,
            "Defense":0.53333336
         }
      },
      {
         "name":"YOUNGHAN",
         "count":48,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.05,
               "5":1.0,
               "54":0.05,
               "55":0.025,
               "56":0.075
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.47368422,
               "49":0.15789473,
               "53":0.42105263,
               "55":0.15789473,
               "56":0.2631579
            },
            "5":{
               "55":0.6,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "49":0.3846154,
               "17":0.53846157,
               "18":0.46153846,
               "55":0.23076923,
               "56":0.9230769
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "48":2,
               "5":40,
               "54":2,
               "55":1,
               "56":3
            },
            "3":{
               "49":48
            },
            "4":{
               "16":19,
               "48":9,
               "49":3,
               "53":8,
               "55":3,
               "56":5
            },
            "5":{
               "55":18,
               "56":30
            },
            "6":{
               "48":13,
               "49":5,
               "17":7,
               "18":6,
               "55":3,
               "56":12
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.2972973,
         "setCounts":{
            "1":13,
            "2":10,
            "3":2,
            "4":10,
            "5":21,
            "6":17,
            "7":3,
            "8":5
         },
         "fourSetMultipliers":{
            "1":0.61904764,
            "2":0.5882353,
            "3":0.0952381,
            "4":0.5882353,
            "5":1.0,
            "6":1.0,
            "7":0.14285715,
            "8":0.23809524
         },
         "twoSetMax":48.0,
         "twoSetCounts":{
            "1":13,
            "3":2,
            "5":21,
            "7":3,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":1,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":1,
            "17":1,
            "49":1,
            "18":4,
            "5":11,
            "53":1,
            "55":3,
            "56":6,
            "41":8,
            "42":7,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.18181819,
            "1":0.09090909,
            "17":0.09090909,
            "49":0.09090909,
            "18":0.36363637,
            "5":1.0,
            "53":0.09090909,
            "55":0.27272728,
            "56":0.54545456,
            "41":0.72727275,
            "42":0.6363636,
            "28":0.36363637
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":1,
            "Health":4,
            "Potency":1,
            "Offense":10,
            "Tenacity":4,
            "Protection":10,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.09090909,
            "Health":0.36363637,
            "Potency":0.09090909,
            "Offense":0.90909094,
            "Tenacity":0.36363637,
            "Protection":0.90909094,
            "Defense":0.72727275
         }
      },
      {
         "name":"EWOKELDER",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.022222223,
               "5":1.0,
               "54":0.044444446,
               "55":0.022222223
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.33333334,
               "48":0.25,
               "49":0.8333333,
               "53":0.9166667,
               "55":1.0,
               "56":0.75
            },
            "5":{
               "55":1.0,
               "56":0.53125
            },
            "6":{
               "48":0.13333334,
               "49":0.4,
               "17":0.2,
               "18":1.0,
               "55":0.8666667,
               "56":0.6666667
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":1,
               "5":45,
               "54":2,
               "55":1
            },
            "3":{
               "49":49
            },
            "4":{
               "16":4,
               "48":3,
               "49":10,
               "53":11,
               "55":12,
               "56":9
            },
            "5":{
               "55":32,
               "56":17
            },
            "6":{
               "48":2,
               "49":6,
               "17":3,
               "18":15,
               "55":13,
               "56":10
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.6896552,
         "setCounts":{
            "1":67,
            "2":0,
            "3":3,
            "4":29,
            "5":3,
            "6":0,
            "7":2,
            "8":5
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.04477612,
            "4":1.0,
            "5":0.04477612,
            "6":0.0,
            "7":0.029850746,
            "8":0.07462686
         },
         "twoSetMax":141.44446,
         "twoSetCounts":{
            "1":67,
            "3":3,
            "5":3,
            "7":2,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":67.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":44.666668
            },
            {
               "set":1,
               "occurrence":3,
               "count":29.777779
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "17":2,
            "1":7,
            "49":2,
            "18":3,
            "5":12,
            "53":4,
            "55":5,
            "56":9,
            "41":1,
            "42":4,
            "28":10
         },
         "secondaryMultipliers":{
            "48":0.16666667,
            "17":0.16666667,
            "1":0.5833333,
            "49":0.16666667,
            "18":0.25,
            "5":1.0,
            "53":0.33333334,
            "55":0.41666666,
            "56":0.75,
            "41":0.083333336,
            "42":0.33333334,
            "28":0.8333333
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":4,
            "Health":12,
            "Potency":2,
            "Offense":3,
            "Tenacity":3,
            "Protection":19,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":0.6315789,
            "Critical Chance":0.21052632,
            "Health":0.6315789,
            "Potency":0.10526316,
            "Offense":0.15789473,
            "Tenacity":0.15789473,
            "Protection":1.0,
            "Defense":0.31578946
         }
      },
      {
         "name":"ADMIRALACKBAR",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.029411765,
               "49":0.029411765,
               "5":1.0,
               "54":0.20588236,
               "55":0.14705883,
               "56":0.05882353
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.3125,
               "16":1.0,
               "49":0.125,
               "53":0.625,
               "55":0.625,
               "56":0.4375
            },
            "5":{
               "55":1.0,
               "56":1.0
            },
            "6":{
               "48":0.7692308,
               "17":0.61538464,
               "49":0.46153846,
               "18":1.0,
               "55":0.53846157,
               "56":0.46153846
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "49":1,
               "5":34,
               "54":7,
               "55":5,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "48":5,
               "16":16,
               "49":2,
               "53":10,
               "55":10,
               "56":7
            },
            "5":{
               "55":25,
               "56":25
            },
            "6":{
               "48":10,
               "17":8,
               "49":6,
               "18":13,
               "55":7,
               "56":6
            }
         },
         "fourSetsMultiplier":0.5625,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":33,
            "2":1,
            "3":5,
            "4":17,
            "5":9,
            "6":0,
            "7":3,
            "8":12
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.05882353,
            "3":0.15151516,
            "4":1.0,
            "5":0.27272728,
            "6":0.0,
            "7":0.09090909,
            "8":0.36363637
         },
         "twoSetMax":69.666664,
         "twoSetCounts":{
            "1":33,
            "3":5,
            "5":9,
            "7":3,
            "8":12
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":33.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":22.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":14.666667
            },
            {
               "set":8,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":3,
            "1":4,
            "17":1,
            "49":3,
            "18":2,
            "5":16,
            "53":2,
            "55":3,
            "56":3,
            "41":4,
            "42":4,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.1875,
            "1":0.25,
            "17":0.0625,
            "49":0.1875,
            "18":0.125,
            "5":1.0,
            "53":0.125,
            "55":0.1875,
            "56":0.1875,
            "41":0.25,
            "42":0.25,
            "28":0.1875
         },
         "secondaryTypeCounts":{
            "Speed":16,
            "Critical Chance":2,
            "Health":7,
            "Potency":1,
            "Offense":7,
            "Tenacity":2,
            "Protection":6,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.125,
            "Health":0.4375,
            "Potency":0.0625,
            "Offense":0.4375,
            "Tenacity":0.125,
            "Protection":0.375,
            "Defense":0.4375
         }
      },
      {
         "name":"STORMTROOPER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "49":0.35,
               "5":1.0,
               "54":0.35,
               "55":0.05,
               "56":0.75
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.037037037,
               "48":0.037037037,
               "49":0.44444445,
               "53":0.14814815,
               "55":0.18518518,
               "56":1.0
            },
            "5":{
               "55":0.31578946,
               "56":1.0
            },
            "6":{
               "49":0.68,
               "18":0.12,
               "55":0.2,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "49":7,
               "5":20,
               "54":7,
               "55":1,
               "56":15
            },
            "3":{
               "49":50
            },
            "4":{
               "16":1,
               "48":1,
               "49":12,
               "53":4,
               "55":5,
               "56":27
            },
            "5":{
               "55":12,
               "56":38
            },
            "6":{
               "49":17,
               "18":3,
               "55":5,
               "56":25
            }
         },
         "fourSetsMultiplier":0.13636364,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":31,
            "2":1,
            "3":88,
            "4":5,
            "5":4,
            "6":0,
            "7":2,
            "8":4
         },
         "fourSetMultipliers":{
            "1":0.35227272,
            "2":0.2,
            "3":1.0,
            "4":1.0,
            "5":0.045454547,
            "6":0.0,
            "7":0.022727273,
            "8":0.045454547
         },
         "twoSetMax":185.77779,
         "twoSetCounts":{
            "1":31,
            "3":88,
            "5":4,
            "7":2,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":3,
               "occurrence":1,
               "count":88.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":58.666668
            },
            {
               "set":3,
               "occurrence":3,
               "count":39.11111
            },
            {
               "set":1,
               "occurrence":1,
               "count":31.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":20.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":13.777778
            },
            {
               "set":5,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"defense",
         "commonSet2Name":"defense",
         "commonSet3Name":"defense",
         "secondaryCounts":{
            "48":3,
            "1":7,
            "17":1,
            "49":2,
            "18":4,
            "53":5,
            "5":17,
            "55":4,
            "56":1,
            "41":1,
            "42":3,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.1764706,
            "1":0.4117647,
            "17":0.05882353,
            "49":0.11764706,
            "18":0.23529412,
            "53":0.29411766,
            "5":1.0,
            "55":0.23529412,
            "56":0.05882353,
            "41":0.05882353,
            "42":0.1764706,
            "28":0.05882353
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":5,
            "Health":11,
            "Potency":1,
            "Offense":4,
            "Tenacity":4,
            "Protection":2,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.29411766,
            "Health":0.64705884,
            "Potency":0.05882353,
            "Offense":0.23529412,
            "Tenacity":0.23529412,
            "Protection":0.11764706,
            "Defense":0.29411766
         }
      },
      {
         "name":"LUMINARAUNDULI",
         "count":48,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.030303031,
               "49":0.030303031,
               "5":1.0,
               "54":0.09090909,
               "55":0.24242425,
               "56":0.060606062
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.2857143,
               "16":0.5,
               "49":0.35714287,
               "53":1.0,
               "55":1.0,
               "56":0.2857143
            },
            "5":{
               "55":1.0,
               "56":0.33333334
            },
            "6":{
               "48":0.53846157,
               "49":0.6923077,
               "17":0.3846154,
               "18":0.61538464,
               "55":1.0,
               "56":0.46153846
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "48":1,
               "49":1,
               "5":33,
               "54":3,
               "55":8,
               "56":2
            },
            "3":{
               "49":48
            },
            "4":{
               "48":4,
               "16":7,
               "49":5,
               "53":14,
               "55":14,
               "56":4
            },
            "5":{
               "55":36,
               "56":12
            },
            "6":{
               "48":7,
               "49":9,
               "17":5,
               "18":8,
               "55":13,
               "56":6
            }
         },
         "fourSetsMultiplier":0.37142858,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":87,
            "2":1,
            "3":4,
            "4":11,
            "5":3,
            "6":1,
            "7":3,
            "8":6
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.09090909,
            "3":0.04597701,
            "4":1.0,
            "5":0.03448276,
            "6":0.09090909,
            "7":0.03448276,
            "8":0.06896552
         },
         "twoSetMax":183.66667,
         "twoSetCounts":{
            "1":87,
            "3":4,
            "5":3,
            "7":3,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":87.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":58.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":38.666668
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":2,
            "17":3,
            "1":4,
            "49":5,
            "18":3,
            "5":17,
            "53":2,
            "55":2,
            "56":1,
            "41":2,
            "42":5,
            "28":8
         },
         "secondaryMultipliers":{
            "48":0.11764706,
            "17":0.1764706,
            "1":0.23529412,
            "49":0.29411766,
            "18":0.1764706,
            "5":1.0,
            "53":0.11764706,
            "55":0.11764706,
            "56":0.05882353,
            "41":0.11764706,
            "42":0.29411766,
            "28":0.47058824
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":2,
            "Health":6,
            "Potency":3,
            "Offense":4,
            "Tenacity":3,
            "Protection":9,
            "Defense":10
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.11764706,
            "Health":0.3529412,
            "Potency":0.1764706,
            "Offense":0.23529412,
            "Tenacity":0.1764706,
            "Protection":0.5294118,
            "Defense":0.5882353
         }
      },
      {
         "name":"NUTEGUNRAY",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.25,
               "16":0.1,
               "49":0.45,
               "53":0.3,
               "55":0.4,
               "56":1.0
            },
            "5":{
               "55":0.8518519,
               "56":1.0
            },
            "6":{
               "17":0.13513513,
               "49":0.054054055,
               "18":1.0,
               "55":0.08108108,
               "56":0.08108108
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "48":5,
               "16":2,
               "49":9,
               "53":6,
               "55":8,
               "56":20
            },
            "5":{
               "55":23,
               "56":27
            },
            "6":{
               "17":5,
               "49":2,
               "18":37,
               "55":3,
               "56":3
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":11,
            "2":0,
            "3":3,
            "4":50,
            "5":0,
            "7":18,
            "8":9
         },
         "fourSetMultipliers":{
            "1":0.6111111,
            "2":0.0,
            "3":0.16666667,
            "4":1.0,
            "5":0.0,
            "7":1.0,
            "8":0.5
         },
         "twoSetMax":41.0,
         "twoSetCounts":{
            "1":11,
            "3":3,
            "5":0,
            "7":18,
            "8":9
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":8,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":8,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":3,
            "49":1,
            "17":8,
            "18":3,
            "5":17,
            "53":4,
            "55":3,
            "56":2,
            "41":6,
            "42":3,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.23529412,
            "1":0.1764706,
            "49":0.05882353,
            "17":0.47058824,
            "18":0.1764706,
            "5":1.0,
            "53":0.23529412,
            "55":0.1764706,
            "56":0.11764706,
            "41":0.3529412,
            "42":0.1764706,
            "28":0.11764706
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":4,
            "Health":6,
            "Potency":8,
            "Offense":10,
            "Tenacity":3,
            "Protection":4,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.23529412,
            "Health":0.3529412,
            "Potency":0.47058824,
            "Offense":0.5882353,
            "Tenacity":0.1764706,
            "Protection":0.23529412,
            "Defense":0.23529412
         }
      },
      {
         "name":"TUSKENRAIDER",
         "count":45,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.15625,
               "49":0.03125,
               "5":1.0,
               "54":0.125,
               "55":0.0625,
               "56":0.03125
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.4,
               "49":0.13333334,
               "53":1.0,
               "55":0.2,
               "56":0.2
            },
            "5":{
               "55":0.72,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.53333336,
               "49":0.26666668,
               "18":0.6,
               "55":0.4,
               "56":0.2
            }
         },
         "primaryCounts":{
            "1":{
               "48":43
            },
            "2":{
               "48":5,
               "49":1,
               "5":32,
               "54":4,
               "55":2,
               "56":1
            },
            "3":{
               "49":42
            },
            "4":{
               "16":15,
               "48":6,
               "49":2,
               "53":15,
               "55":3,
               "56":3
            },
            "5":{
               "55":18,
               "56":25
            },
            "6":{
               "48":15,
               "17":8,
               "49":4,
               "18":9,
               "55":6,
               "56":3
            }
         },
         "fourSetsMultiplier":0.8,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":19,
            "2":10,
            "3":4,
            "4":7,
            "5":11,
            "6":3,
            "7":9,
            "8":12
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0,
            "3":0.21052632,
            "4":0.7,
            "5":0.57894737,
            "6":0.3,
            "7":0.47368422,
            "8":0.6315789
         },
         "twoSetMax":43.666668,
         "twoSetCounts":{
            "1":19,
            "3":4,
            "5":11,
            "7":9,
            "8":12
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":8,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":8,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":7,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"tenacity",
         "secondaryCounts":{
            "48":6,
            "17":3,
            "1":4,
            "49":2,
            "18":4,
            "5":12,
            "53":2,
            "55":4,
            "56":4,
            "41":7,
            "42":2,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.5,
            "17":0.25,
            "1":0.33333334,
            "49":0.16666667,
            "18":0.33333334,
            "5":1.0,
            "53":0.16666667,
            "55":0.33333334,
            "56":0.33333334,
            "41":0.5833333,
            "42":0.16666667,
            "28":0.33333334
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":2,
            "Health":8,
            "Potency":3,
            "Offense":13,
            "Tenacity":4,
            "Protection":8,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":0.9230769,
            "Critical Chance":0.15384616,
            "Health":0.61538464,
            "Potency":0.23076923,
            "Offense":1.0,
            "Tenacity":0.30769232,
            "Protection":0.61538464,
            "Defense":0.30769232
         }
      },
      {
         "name":"ZAALBAR",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":0.21052632,
               "54":1.0,
               "55":0.05263158,
               "56":0.05263158
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.11764706,
               "49":0.05882353,
               "53":0.029411765,
               "55":0.23529412,
               "56":1.0
            },
            "5":{
               "55":0.14285715,
               "56":1.0
            },
            "6":{
               "17":0.083333336,
               "49":0.041666668,
               "18":0.875,
               "55":0.083333336,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "5":8,
               "54":38,
               "55":2,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":4,
               "49":2,
               "53":1,
               "55":8,
               "56":34
            },
            "5":{
               "55":6,
               "56":42
            },
            "6":{
               "17":2,
               "49":1,
               "18":21,
               "55":2,
               "56":24
            }
         },
         "fourSetsMultiplier":0.041666668,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":62,
            "3":13,
            "4":2,
            "5":1,
            "6":0,
            "8":66
         },
         "fourSetMultipliers":{
            "1":0.93939394,
            "3":0.1969697,
            "4":1.0,
            "5":0.015151516,
            "6":0.0,
            "8":1.0
         },
         "twoSetMax":172.0,
         "twoSetCounts":{
            "1":62,
            "3":13,
            "5":1,
            "8":66
         },
         "twoSetOccurrenceCounts":[
            {
               "set":8,
               "occurrence":1,
               "count":66.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":62.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":44.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":41.333332
            },
            {
               "set":8,
               "occurrence":3,
               "count":29.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":27.555555
            },
            {
               "set":3,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"tenacity",
         "commonSet2Name":"health",
         "commonSet3Name":"tenacity",
         "secondaryCounts":{
            "48":3,
            "17":2,
            "49":8,
            "1":5,
            "18":10,
            "5":7,
            "53":2,
            "55":2,
            "56":10,
            "41":2,
            "42":7,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.3,
            "17":0.2,
            "49":0.8,
            "1":0.5,
            "18":1.0,
            "5":0.7,
            "53":0.2,
            "55":0.2,
            "56":1.0,
            "41":0.2,
            "42":0.7,
            "28":0.3
         },
         "secondaryTypeCounts":{
            "Speed":7,
            "Critical Chance":2,
            "Health":7,
            "Potency":2,
            "Offense":5,
            "Tenacity":10,
            "Protection":13,
            "Defense":15
         },
         "secondaryTypeMultipliers":{
            "Speed":0.46666667,
            "Critical Chance":0.13333334,
            "Health":0.46666667,
            "Potency":0.13333334,
            "Offense":0.33333334,
            "Tenacity":0.6666667,
            "Protection":0.8666667,
            "Defense":1.0
         }
      },
      {
         "name":"YOUNGCHEWBACCA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.26086956,
               "55":0.82608694,
               "56":0.08695652
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.0625,
               "48":0.0625,
               "49":0.09375,
               "53":0.09375,
               "55":1.0,
               "56":0.25
            },
            "5":{
               "55":1.0,
               "56":0.19512194
            },
            "6":{
               "48":0.03125,
               "49":0.125,
               "17":0.03125,
               "18":0.15625,
               "55":1.0,
               "56":0.1875
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":23,
               "54":6,
               "55":19,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":2,
               "48":2,
               "49":3,
               "53":3,
               "55":32,
               "56":8
            },
            "5":{
               "55":41,
               "56":8
            },
            "6":{
               "48":1,
               "49":4,
               "17":1,
               "18":5,
               "55":32,
               "56":6
            }
         },
         "fourSetsMultiplier":0.020408163,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":130,
            "3":7,
            "4":1,
            "5":2,
            "6":0,
            "7":4,
            "8":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.053846154,
            "4":1.0,
            "5":0.015384615,
            "6":0.0,
            "7":0.03076923,
            "8":0.0076923077
         },
         "twoSetMax":274.44443,
         "twoSetCounts":{
            "1":130,
            "3":7,
            "5":2,
            "7":4,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":130.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":86.666664
            },
            {
               "set":1,
               "occurrence":3,
               "count":57.777775
            },
            {
               "set":3,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":7,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":5,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":5,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":1,
            "17":3,
            "1":8,
            "49":2,
            "18":2,
            "5":7,
            "53":3,
            "55":3,
            "56":1,
            "41":5,
            "42":2,
            "28":12
         },
         "secondaryMultipliers":{
            "48":0.083333336,
            "17":0.25,
            "1":0.6666667,
            "49":0.16666667,
            "18":0.16666667,
            "5":0.5833333,
            "53":0.25,
            "55":0.25,
            "56":0.083333336,
            "41":0.41666666,
            "42":0.16666667,
            "28":1.0
         },
         "secondaryTypeCounts":{
            "Speed":7,
            "Critical Chance":3,
            "Health":11,
            "Potency":3,
            "Offense":6,
            "Tenacity":2,
            "Protection":13,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":0.53846157,
            "Critical Chance":0.23076923,
            "Health":0.84615386,
            "Potency":0.23076923,
            "Offense":0.46153846,
            "Tenacity":0.15384616,
            "Protection":1.0,
            "Defense":0.30769232
         }
      },
      {
         "name":"WAMPA",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.175,
               "5":1.0,
               "56":0.05
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.65217394,
               "53":0.17391305,
               "55":0.13043478,
               "56":0.13043478
            },
            "5":{
               "55":0.75,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.55,
               "49":0.1,
               "18":0.4,
               "55":0.15,
               "56":0.25
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":7,
               "5":40,
               "56":2
            },
            "3":{
               "49":49
            },
            "4":{
               "16":23,
               "48":15,
               "53":4,
               "55":3,
               "56":3
            },
            "5":{
               "55":21,
               "56":28
            },
            "6":{
               "48":20,
               "17":11,
               "49":2,
               "18":8,
               "55":3,
               "56":5
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.13953489,
         "setCounts":{
            "1":7,
            "2":16,
            "3":2,
            "4":4,
            "5":22,
            "6":23,
            "7":11,
            "8":8
         },
         "fourSetMultipliers":{
            "1":0.3181818,
            "2":0.6956522,
            "3":0.09090909,
            "4":0.17391305,
            "5":1.0,
            "6":1.0,
            "7":0.5,
            "8":0.36363637
         },
         "twoSetMax":47.666668,
         "twoSetCounts":{
            "1":7,
            "3":2,
            "5":22,
            "7":11,
            "8":8
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":22.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.777778
            },
            {
               "set":8,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":1,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":1,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":1,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "49":3,
            "1":3,
            "17":3,
            "18":6,
            "53":2,
            "5":13,
            "55":3,
            "56":3,
            "41":8,
            "42":6,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.30769232,
            "49":0.23076923,
            "1":0.23076923,
            "17":0.23076923,
            "18":0.46153846,
            "53":0.15384616,
            "5":1.0,
            "55":0.23076923,
            "56":0.23076923,
            "41":0.61538464,
            "42":0.46153846,
            "28":0.07692308
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":2,
            "Health":6,
            "Potency":3,
            "Offense":12,
            "Tenacity":6,
            "Protection":4,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.15384616,
            "Health":0.46153846,
            "Potency":0.23076923,
            "Offense":0.9230769,
            "Tenacity":0.46153846,
            "Protection":0.30769232,
            "Defense":0.6923077
         }
      },
      {
         "name":"GARSAXON",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.032258064,
               "52":0.06451613,
               "5":1.0,
               "54":0.2580645,
               "55":0.16129032,
               "56":0.09677419
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":1.0,
               "49":0.13333334,
               "53":0.33333334,
               "55":0.06666667,
               "56":0.8
            },
            "5":{
               "55":0.5151515,
               "56":1.0
            },
            "6":{
               "48":0.72727275,
               "17":0.36363637,
               "49":1.0,
               "18":0.72727275,
               "55":0.72727275,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "52":2,
               "5":31,
               "54":8,
               "55":5,
               "56":3
            },
            "3":{
               "49":50
            },
            "4":{
               "16":15,
               "48":15,
               "49":2,
               "53":5,
               "55":1,
               "56":12
            },
            "5":{
               "55":17,
               "56":33
            },
            "6":{
               "48":8,
               "17":4,
               "49":11,
               "18":8,
               "55":8,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.7241379,
         "setCounts":{
            "1":21,
            "2":8,
            "3":9,
            "4":11,
            "5":12,
            "6":10,
            "7":19,
            "8":8
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.72727275,
            "3":0.42857143,
            "4":1.0,
            "5":0.5714286,
            "6":0.90909094,
            "7":0.9047619,
            "8":0.3809524
         },
         "twoSetMax":54.0,
         "twoSetCounts":{
            "1":21,
            "3":9,
            "5":12,
            "7":19,
            "8":8
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":5,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":3,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":5,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.5555556
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":2,
            "17":5,
            "1":3,
            "18":6,
            "5":11,
            "53":7,
            "55":1,
            "56":5,
            "41":3,
            "42":6,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.18181819,
            "49":0.18181819,
            "17":0.45454547,
            "1":0.27272728,
            "18":0.54545456,
            "5":1.0,
            "53":0.6363636,
            "55":0.09090909,
            "56":0.45454547,
            "41":0.27272728,
            "42":0.54545456,
            "28":0.09090909
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":7,
            "Health":4,
            "Potency":5,
            "Offense":5,
            "Tenacity":6,
            "Protection":6,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.6363636,
            "Health":0.36363637,
            "Potency":0.45454547,
            "Offense":0.45454547,
            "Tenacity":0.54545456,
            "Protection":0.54545456,
            "Defense":0.72727275
         }
      },
      {
         "name":"T3_M4",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.023809524,
               "5":1.0,
               "54":0.0952381,
               "55":0.023809524,
               "56":0.04761905
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.46666667,
               "48":0.26666668,
               "49":0.2,
               "53":1.0,
               "55":0.8,
               "56":0.6
            },
            "5":{
               "55":0.58064514,
               "56":1.0
            },
            "6":{
               "48":0.16666667,
               "17":1.0,
               "49":0.16666667,
               "18":0.33333334,
               "55":0.2777778,
               "56":0.7777778
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "5":42,
               "54":4,
               "55":1,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":7,
               "48":4,
               "49":3,
               "53":15,
               "55":12,
               "56":9
            },
            "5":{
               "55":18,
               "56":31
            },
            "6":{
               "48":3,
               "17":18,
               "49":3,
               "18":6,
               "55":5,
               "56":14
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.31578946,
         "setCounts":{
            "1":22,
            "2":4,
            "3":4,
            "4":33,
            "5":14,
            "6":1,
            "7":15,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.121212125,
            "3":0.18181819,
            "4":1.0,
            "5":0.6363636,
            "6":0.030303031,
            "7":0.6818182,
            "8":0.09090909
         },
         "twoSetMax":51.666668,
         "twoSetCounts":{
            "1":22,
            "3":4,
            "5":14,
            "7":15,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":22.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.666667
            },
            {
               "set":5,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.777778
            },
            {
               "set":5,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":7,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":5,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "17":3,
            "1":2,
            "49":2,
            "18":6,
            "5":11,
            "53":8,
            "55":4,
            "56":5,
            "41":3,
            "42":5,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.18181819,
            "17":0.27272728,
            "1":0.18181819,
            "49":0.18181819,
            "18":0.54545456,
            "5":1.0,
            "53":0.72727275,
            "55":0.36363637,
            "56":0.45454547,
            "41":0.27272728,
            "42":0.45454547,
            "28":0.18181819
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":8,
            "Health":6,
            "Potency":3,
            "Offense":5,
            "Tenacity":6,
            "Protection":7,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.72727275,
            "Health":0.54545456,
            "Potency":0.27272728,
            "Offense":0.45454547,
            "Tenacity":0.54545456,
            "Protection":0.6363636,
            "Defense":0.6363636
         }
      },
      {
         "name":"ASAJVENTRESS",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.0952381,
               "49":0.023809524,
               "53":0.023809524,
               "55":0.04761905
            },
            "5":{
               "55":1.0,
               "56":0.5151515
            },
            "6":{
               "48":1.0,
               "17":0.53846157,
               "49":0.115384616,
               "18":0.07692308,
               "55":0.1923077
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "16":42,
               "48":4,
               "49":1,
               "53":1,
               "55":2
            },
            "5":{
               "55":33,
               "56":17
            },
            "6":{
               "48":26,
               "17":14,
               "49":3,
               "18":2,
               "55":5
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.06382979,
         "setCounts":{
            "1":18,
            "2":5,
            "4":2,
            "5":28,
            "6":40,
            "7":5,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.64285713,
            "2":0.125,
            "4":0.05,
            "5":1.0,
            "6":1.0,
            "7":0.17857143,
            "8":0.035714287
         },
         "twoSetMax":64.666664,
         "twoSetCounts":{
            "1":18,
            "5":28,
            "7":5,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":28.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":18.666666
            },
            {
               "set":1,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":12.444444
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "49":1,
            "17":3,
            "1":3,
            "18":1,
            "5":19,
            "53":3,
            "55":3,
            "56":4,
            "41":2,
            "42":3,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.2631579,
            "49":0.05263158,
            "17":0.15789473,
            "1":0.15789473,
            "18":0.05263158,
            "5":1.0,
            "53":0.15789473,
            "55":0.15789473,
            "56":0.21052632,
            "41":0.10526316,
            "42":0.15789473,
            "28":0.21052632
         },
         "secondaryTypeCounts":{
            "Speed":19,
            "Critical Chance":3,
            "Health":6,
            "Potency":3,
            "Offense":7,
            "Tenacity":1,
            "Protection":8,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.15789473,
            "Health":0.31578946,
            "Potency":0.15789473,
            "Offense":0.36842105,
            "Tenacity":0.05263158,
            "Protection":0.42105263,
            "Defense":0.21052632
         }
      },
      {
         "name":"JUHANI",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.05263158,
               "5":1.0,
               "54":0.13157895,
               "55":0.05263158,
               "56":0.078947365
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.23809524,
               "48":0.1904762,
               "49":0.23809524,
               "53":0.42857143,
               "55":0.1904762,
               "56":1.0
            },
            "5":{
               "55":0.2631579,
               "56":1.0
            },
            "6":{
               "48":0.2,
               "49":0.53333336,
               "17":0.06666667,
               "18":0.93333334,
               "55":0.46666667,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":2,
               "5":38,
               "54":5,
               "55":2,
               "56":3
            },
            "3":{
               "49":49
            },
            "4":{
               "16":5,
               "48":4,
               "49":5,
               "53":9,
               "55":4,
               "56":21
            },
            "5":{
               "55":10,
               "56":38
            },
            "6":{
               "48":3,
               "49":8,
               "17":1,
               "18":14,
               "55":7,
               "56":15
            }
         },
         "fourSetsMultiplier":0.42857143,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":61,
            "2":2,
            "3":12,
            "4":13,
            "5":9,
            "6":0,
            "7":2,
            "8":18
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.15384616,
            "3":0.19672132,
            "4":1.0,
            "5":0.14754099,
            "6":0.0,
            "7":0.032786883,
            "8":0.29508197
         },
         "twoSetMax":128.77779,
         "twoSetCounts":{
            "1":61,
            "3":12,
            "5":9,
            "7":2,
            "8":18
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":61.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":40.666668
            },
            {
               "set":1,
               "occurrence":3,
               "count":27.111113
            },
            {
               "set":8,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":7,
            "49":3,
            "17":1,
            "1":4,
            "18":8,
            "5":13,
            "53":1,
            "55":4,
            "56":4,
            "41":2,
            "42":2,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.53846157,
            "49":0.23076923,
            "17":0.07692308,
            "1":0.30769232,
            "18":0.61538464,
            "5":1.0,
            "53":0.07692308,
            "55":0.30769232,
            "56":0.30769232,
            "41":0.15384616,
            "42":0.15384616,
            "28":0.30769232
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":1,
            "Health":8,
            "Potency":1,
            "Offense":9,
            "Tenacity":8,
            "Protection":8,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.07692308,
            "Health":0.61538464,
            "Potency":0.07692308,
            "Offense":0.6923077,
            "Tenacity":0.61538464,
            "Protection":0.61538464,
            "Defense":0.3846154
         }
      },
      {
         "name":"JEDIKNIGHTCONSULAR",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.12903225,
               "49":0.032258064,
               "52":0.06451613,
               "5":1.0,
               "54":0.19354838,
               "55":0.16129032,
               "56":0.032258064
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.75,
               "48":0.5833333,
               "49":0.5,
               "53":0.5833333,
               "55":1.0,
               "56":0.75
            },
            "5":{
               "55":1.0,
               "56":0.75
            },
            "6":{
               "48":0.375,
               "49":0.3125,
               "17":0.1875,
               "18":0.75,
               "55":1.0,
               "56":0.5
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":4,
               "49":1,
               "52":2,
               "5":31,
               "54":6,
               "55":5,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":9,
               "48":7,
               "49":6,
               "53":7,
               "55":12,
               "56":9
            },
            "5":{
               "55":28,
               "56":21
            },
            "6":{
               "48":6,
               "49":5,
               "17":3,
               "18":12,
               "55":16,
               "56":8
            }
         },
         "fourSetsMultiplier":0.47058824,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":54,
            "2":0,
            "3":5,
            "4":15,
            "5":8,
            "6":1,
            "7":4,
            "8":9
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.09259259,
            "4":1.0,
            "5":0.14814815,
            "6":0.06666667,
            "7":0.074074075,
            "8":0.16666667
         },
         "twoSetMax":114.0,
         "twoSetCounts":{
            "1":54,
            "3":5,
            "5":8,
            "7":4,
            "8":9
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":54.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":36.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":24.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":3,
            "1":8,
            "49":3,
            "17":1,
            "18":8,
            "5":13,
            "53":7,
            "55":5,
            "56":4,
            "41":3,
            "42":1,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.23076923,
            "1":0.61538464,
            "49":0.23076923,
            "17":0.07692308,
            "18":0.61538464,
            "5":1.0,
            "53":0.53846157,
            "55":0.3846154,
            "56":0.30769232,
            "41":0.23076923,
            "42":0.07692308,
            "28":0.30769232
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":7,
            "Health":13,
            "Potency":1,
            "Offense":6,
            "Tenacity":8,
            "Protection":8,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.53846157,
            "Health":1.0,
            "Potency":0.07692308,
            "Offense":0.46153846,
            "Tenacity":0.61538464,
            "Protection":0.61538464,
            "Defense":0.30769232
         }
      },
      {
         "name":"STORMTROOPERHAN",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "49":0.08,
               "5":1.0,
               "54":0.36,
               "55":0.08,
               "56":0.44
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.074074075,
               "16":0.11111111,
               "49":0.2962963,
               "53":0.074074075,
               "55":0.22222222,
               "56":1.0
            },
            "5":{
               "55":0.2631579,
               "56":1.0
            },
            "6":{
               "48":0.074074075,
               "17":0.037037037,
               "49":0.18518518,
               "18":0.2962963,
               "55":0.22222222,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "49":2,
               "5":25,
               "54":9,
               "55":2,
               "56":11
            },
            "3":{
               "49":48
            },
            "4":{
               "48":2,
               "16":3,
               "49":8,
               "53":2,
               "55":6,
               "56":27
            },
            "5":{
               "55":10,
               "56":38
            },
            "6":{
               "48":2,
               "17":1,
               "49":5,
               "18":8,
               "55":6,
               "56":27
            }
         },
         "fourSetsMultiplier":0.53125,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":42,
            "2":0,
            "3":44,
            "4":17,
            "5":1,
            "6":0,
            "7":3,
            "8":8
         },
         "fourSetMultipliers":{
            "1":0.95454544,
            "2":0.0,
            "3":1.0,
            "4":1.0,
            "5":0.022727273,
            "6":0.0,
            "7":0.06818182,
            "8":0.18181819
         },
         "twoSetMax":115.333336,
         "twoSetCounts":{
            "1":42,
            "3":44,
            "5":1,
            "7":3,
            "8":8
         },
         "twoSetOccurrenceCounts":[
            {
               "set":3,
               "occurrence":1,
               "count":44.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":42.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":29.333334
            },
            {
               "set":1,
               "occurrence":2,
               "count":28.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":19.555555
            },
            {
               "set":1,
               "occurrence":3,
               "count":18.666666
            },
            {
               "set":8,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"defense",
         "commonSet2Name":"health",
         "commonSet3Name":"defense",
         "secondaryCounts":{
            "48":3,
            "17":1,
            "1":8,
            "49":4,
            "18":5,
            "5":11,
            "53":1,
            "55":6,
            "56":4,
            "41":1,
            "42":4,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.27272728,
            "17":0.09090909,
            "1":0.72727275,
            "49":0.36363637,
            "18":0.45454547,
            "5":1.0,
            "53":0.09090909,
            "55":0.54545456,
            "56":0.36363637,
            "41":0.09090909,
            "42":0.36363637,
            "28":0.36363637
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":1,
            "Health":14,
            "Potency":1,
            "Offense":4,
            "Tenacity":5,
            "Protection":8,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":0.78571427,
            "Critical Chance":0.071428575,
            "Health":1.0,
            "Potency":0.071428575,
            "Offense":0.2857143,
            "Tenacity":0.35714287,
            "Protection":0.5714286,
            "Defense":0.5714286
         }
      },
      {
         "name":"HERASYNDULLAS3",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.048780486,
               "52":0.024390243,
               "5":1.0,
               "54":0.09756097,
               "55":0.024390243,
               "56":0.024390243
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.64285713,
               "16":0.64285713,
               "49":0.5,
               "53":0.5,
               "55":0.2857143,
               "56":1.0
            },
            "5":{
               "55":0.42857143,
               "56":1.0
            },
            "6":{
               "48":0.53333336,
               "49":0.53333336,
               "17":0.4,
               "18":0.4,
               "55":0.46666667,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":2,
               "52":1,
               "5":41,
               "54":4,
               "55":1,
               "56":1
            },
            "3":{
               "49":49
            },
            "4":{
               "48":9,
               "16":9,
               "49":7,
               "53":7,
               "55":4,
               "56":14
            },
            "5":{
               "55":15,
               "56":35
            },
            "6":{
               "48":8,
               "49":8,
               "17":6,
               "18":6,
               "55":7,
               "56":15
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.47058824,
         "setCounts":{
            "1":24,
            "2":0,
            "3":4,
            "4":32,
            "5":12,
            "6":2,
            "7":7,
            "8":8
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.16666667,
            "4":1.0,
            "5":0.5,
            "6":0.0625,
            "7":0.29166666,
            "8":0.33333334
         },
         "twoSetMax":52.0,
         "twoSetCounts":{
            "1":24,
            "3":4,
            "5":12,
            "7":7,
            "8":8
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":24.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":16.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":10.666667
            },
            {
               "set":5,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "17":3,
            "1":2,
            "49":3,
            "18":2,
            "5":14,
            "53":5,
            "55":3,
            "56":2,
            "41":4,
            "42":2,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.2857143,
            "17":0.21428572,
            "1":0.14285715,
            "49":0.21428572,
            "18":0.14285715,
            "5":1.0,
            "53":0.35714287,
            "55":0.21428572,
            "56":0.14285715,
            "41":0.2857143,
            "42":0.14285715,
            "28":0.42857143
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":5,
            "Health":5,
            "Potency":3,
            "Offense":8,
            "Tenacity":2,
            "Protection":8,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.35714287,
            "Health":0.35714287,
            "Potency":0.21428572,
            "Offense":0.5714286,
            "Tenacity":0.14285715,
            "Protection":0.5714286,
            "Defense":0.35714287
         }
      },
      {
         "name":"BOSSK",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.020408163
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.037037037,
               "48":0.074074075,
               "49":0.2962963,
               "53":0.074074075,
               "55":0.37037036,
               "56":1.0
            },
            "5":{
               "55":0.2195122,
               "56":1.0
            },
            "6":{
               "48":0.074074075,
               "49":0.11111111,
               "17":0.037037037,
               "18":0.5185185,
               "55":0.11111111,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":49,
               "54":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":1,
               "48":2,
               "49":8,
               "53":2,
               "55":10,
               "56":27
            },
            "5":{
               "55":9,
               "56":41
            },
            "6":{
               "48":2,
               "49":3,
               "17":1,
               "18":14,
               "55":3,
               "56":27
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.020408163,
         "setCounts":{
            "1":26,
            "3":2,
            "4":49,
            "5":1,
            "6":0,
            "7":1,
            "8":12
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.07692308,
            "4":1.0,
            "5":0.03846154,
            "6":0.0,
            "7":0.03846154,
            "8":0.46153846
         },
         "twoSetMax":55.333336,
         "twoSetCounts":{
            "1":26,
            "3":2,
            "5":1,
            "7":1,
            "8":12
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":26.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":17.333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":11.555556
            },
            {
               "set":8,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":5,
            "17":2,
            "49":1,
            "18":5,
            "5":21,
            "53":4,
            "55":1,
            "56":3,
            "41":3,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.0952381,
            "1":0.23809524,
            "17":0.0952381,
            "49":0.04761905,
            "18":0.23809524,
            "5":1.0,
            "53":0.1904762,
            "55":0.04761905,
            "56":0.14285715,
            "41":0.14285715,
            "42":0.0952381,
            "28":0.0952381
         },
         "secondaryTypeCounts":{
            "Speed":21,
            "Critical Chance":4,
            "Health":6,
            "Potency":2,
            "Offense":5,
            "Tenacity":5,
            "Protection":5,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.1904762,
            "Health":0.2857143,
            "Potency":0.0952381,
            "Offense":0.23809524,
            "Tenacity":0.23809524,
            "Protection":0.23809524,
            "Defense":0.14285715
         }
      },
      {
         "name":"MAUL",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.071428575,
               "49":0.04761905,
               "5":1.0,
               "54":0.04761905,
               "56":0.023809524
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.55,
               "49":0.05,
               "53":0.4,
               "55":0.15,
               "56":0.35
            },
            "5":{
               "55":0.6666667,
               "56":1.0
            },
            "6":{
               "48":0.25,
               "17":1.0,
               "49":0.16666667,
               "18":0.25,
               "55":0.20833333,
               "56":0.20833333
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":3,
               "49":2,
               "5":42,
               "54":2,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":20,
               "48":11,
               "49":1,
               "53":8,
               "55":3,
               "56":7
            },
            "5":{
               "55":20,
               "56":30
            },
            "6":{
               "48":6,
               "17":24,
               "49":4,
               "18":6,
               "55":5,
               "56":5
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.2820513,
         "setCounts":{
            "1":23,
            "2":6,
            "3":3,
            "4":24,
            "5":13,
            "6":9,
            "7":17,
            "8":4
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.25,
            "3":0.13043478,
            "4":1.0,
            "5":0.5652174,
            "6":0.375,
            "7":0.73913044,
            "8":0.17391305
         },
         "twoSetMax":55.333332,
         "twoSetCounts":{
            "1":23,
            "3":3,
            "5":13,
            "7":17,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":23.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":15.333333
            },
            {
               "set":5,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":10.222222
            },
            {
               "set":5,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":5,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "17":2,
            "49":2,
            "1":2,
            "18":4,
            "5":17,
            "53":1,
            "55":2,
            "56":8,
            "41":5,
            "42":2,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.1764706,
            "17":0.11764706,
            "49":0.11764706,
            "1":0.11764706,
            "18":0.23529412,
            "5":1.0,
            "53":0.05882353,
            "55":0.11764706,
            "56":0.47058824,
            "41":0.29411766,
            "42":0.11764706,
            "28":0.29411766
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":1,
            "Health":4,
            "Potency":2,
            "Offense":8,
            "Tenacity":4,
            "Protection":13,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.05882353,
            "Health":0.23529412,
            "Potency":0.11764706,
            "Offense":0.47058824,
            "Tenacity":0.23529412,
            "Protection":0.7647059,
            "Defense":0.23529412
         }
      },
      {
         "name":"IMPERIALSUPERCOMMANDO",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.13513513,
               "49":0.027027028,
               "52":0.027027028,
               "5":1.0,
               "54":0.08108108,
               "55":0.027027028,
               "56":0.054054055
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.6,
               "49":0.25,
               "53":0.25,
               "55":0.15,
               "56":0.2
            },
            "5":{
               "55":0.61290324,
               "56":1.0
            },
            "6":{
               "48":0.8333333,
               "17":0.6666667,
               "49":0.083333336,
               "18":0.6666667,
               "55":1.0,
               "56":0.9166667
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":5,
               "49":1,
               "52":1,
               "5":37,
               "54":3,
               "55":1,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":20,
               "48":12,
               "49":5,
               "53":5,
               "55":3,
               "56":4
            },
            "5":{
               "55":19,
               "56":31
            },
            "6":{
               "48":10,
               "17":8,
               "49":1,
               "18":8,
               "55":12,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.6666667,
         "setCounts":{
            "1":11,
            "2":12,
            "3":2,
            "4":8,
            "5":11,
            "6":10,
            "7":15,
            "8":7
         },
         "fourSetMultipliers":{
            "1":0.73333335,
            "2":1.0,
            "3":0.13333334,
            "4":0.6666667,
            "5":0.73333335,
            "6":0.8333333,
            "7":1.0,
            "8":0.46666667
         },
         "twoSetMax":37.0,
         "twoSetCounts":{
            "1":11,
            "3":2,
            "5":11,
            "7":15,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":5,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":5,
            "1":3,
            "17":5,
            "18":6,
            "5":14,
            "53":4,
            "55":4,
            "56":3,
            "41":3,
            "42":3,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.14285715,
            "49":0.35714287,
            "1":0.21428572,
            "17":0.35714287,
            "18":0.42857143,
            "5":1.0,
            "53":0.2857143,
            "55":0.2857143,
            "56":0.21428572,
            "41":0.21428572,
            "42":0.21428572,
            "28":0.35714287
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":4,
            "Health":7,
            "Potency":5,
            "Offense":5,
            "Tenacity":6,
            "Protection":8,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.2857143,
            "Health":0.5,
            "Potency":0.35714287,
            "Offense":0.35714287,
            "Tenacity":0.42857143,
            "Protection":0.5714286,
            "Defense":0.5714286
         }
      },
      {
         "name":"CHOPPERS3",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.2647059,
               "55":0.05882353,
               "56":0.11764706
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.33333334,
               "48":0.2777778,
               "49":0.22222222,
               "53":0.3888889,
               "55":0.5,
               "56":1.0
            },
            "5":{
               "55":0.58064514,
               "56":1.0
            },
            "6":{
               "48":0.2631579,
               "49":0.36842105,
               "17":0.05263158,
               "18":0.42105263,
               "55":0.47368422,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "5":34,
               "54":9,
               "55":2,
               "56":4
            },
            "3":{
               "49":49
            },
            "4":{
               "16":6,
               "48":5,
               "49":4,
               "53":7,
               "55":9,
               "56":18
            },
            "5":{
               "55":18,
               "56":31
            },
            "6":{
               "48":5,
               "49":7,
               "17":1,
               "18":8,
               "55":9,
               "56":19
            }
         },
         "fourSetsMultiplier":0.88461536,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":48,
            "2":0,
            "3":9,
            "4":23,
            "5":4,
            "6":0,
            "7":2,
            "8":12
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.1875,
            "4":1.0,
            "5":0.083333336,
            "6":0.0,
            "7":0.041666668,
            "8":0.25
         },
         "twoSetMax":101.333336,
         "twoSetCounts":{
            "1":48,
            "3":9,
            "5":4,
            "7":2,
            "8":12
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":48.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":32.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":21.333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":1,
            "17":8,
            "49":4,
            "1":5,
            "18":2,
            "5":10,
            "53":7,
            "55":3,
            "56":3,
            "41":1,
            "42":5,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.1,
            "17":0.8,
            "49":0.4,
            "1":0.5,
            "18":0.2,
            "5":1.0,
            "53":0.7,
            "55":0.3,
            "56":0.3,
            "41":0.1,
            "42":0.5,
            "28":0.3
         },
         "secondaryTypeCounts":{
            "Speed":10,
            "Critical Chance":7,
            "Health":8,
            "Potency":8,
            "Offense":2,
            "Tenacity":2,
            "Protection":6,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.7,
            "Health":0.8,
            "Potency":0.8,
            "Offense":0.2,
            "Tenacity":0.2,
            "Protection":0.6,
            "Defense":0.9
         }
      },
      {
         "name":"JEDIKNIGHTGUARDIAN",
         "count":47,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "49":0.055555556,
               "5":1.0,
               "54":0.083333336,
               "55":0.027777778,
               "56":0.11111111
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.7777778,
               "48":0.6666667,
               "49":0.8888889,
               "53":1.0,
               "55":0.6666667,
               "56":0.6666667
            },
            "5":{
               "55":1.0,
               "56":0.88
            },
            "6":{
               "48":0.18181819,
               "17":0.36363637,
               "49":1.0,
               "18":1.0,
               "55":0.6363636,
               "56":0.8181818
            }
         },
         "primaryCounts":{
            "1":{
               "48":44
            },
            "2":{
               "49":2,
               "5":36,
               "54":3,
               "55":1,
               "56":4
            },
            "3":{
               "49":44
            },
            "4":{
               "16":7,
               "48":6,
               "49":8,
               "53":9,
               "55":6,
               "56":6
            },
            "5":{
               "55":25,
               "56":22
            },
            "6":{
               "48":2,
               "17":4,
               "49":11,
               "18":11,
               "55":7,
               "56":9
            }
         },
         "fourSetsMultiplier":0.23684211,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":45,
            "2":1,
            "3":5,
            "4":6,
            "5":10,
            "6":2,
            "7":10,
            "8":9
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.16666667,
            "3":0.11111111,
            "4":1.0,
            "5":0.22222222,
            "6":0.33333334,
            "7":0.22222222,
            "8":0.2
         },
         "twoSetMax":95.0,
         "twoSetCounts":{
            "1":45,
            "3":5,
            "5":10,
            "7":10,
            "8":9
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":45.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":30.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":20.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":7,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":8,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":8,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":3,
            "17":2,
            "49":1,
            "1":2,
            "18":1,
            "53":9,
            "5":11,
            "55":9,
            "56":3,
            "41":1,
            "42":3,
            "28":8
         },
         "secondaryMultipliers":{
            "48":0.27272728,
            "17":0.18181819,
            "49":0.09090909,
            "1":0.18181819,
            "18":0.09090909,
            "53":0.8181818,
            "5":1.0,
            "55":0.8181818,
            "56":0.27272728,
            "41":0.09090909,
            "42":0.27272728,
            "28":0.72727275
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":9,
            "Health":11,
            "Potency":2,
            "Offense":4,
            "Tenacity":1,
            "Protection":11,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.8181818,
            "Health":1.0,
            "Potency":0.18181819,
            "Offense":0.36363637,
            "Tenacity":0.09090909,
            "Protection":1.0,
            "Defense":0.36363637
         }
      },
      {
         "name":"CANDEROUSORDO",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.2777778,
               "5":1.0,
               "54":0.027777778,
               "56":0.055555556
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.68,
               "49":0.04,
               "56":0.24
            },
            "5":{
               "55":0.4,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.25,
               "49":0.14285715,
               "18":0.14285715,
               "55":0.071428575,
               "56":0.14285715
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":10,
               "5":36,
               "54":1,
               "56":2
            },
            "3":{
               "49":49
            },
            "4":{
               "16":25,
               "48":17,
               "49":1,
               "56":6
            },
            "5":{
               "55":14,
               "56":35
            },
            "6":{
               "48":28,
               "17":7,
               "49":4,
               "18":4,
               "55":2,
               "56":4
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.19512194,
         "setCounts":{
            "1":17,
            "2":27,
            "4":3,
            "5":18,
            "6":11,
            "7":8,
            "8":15
         },
         "fourSetMultipliers":{
            "1":0.9444444,
            "2":1.0,
            "4":0.11111111,
            "5":1.0,
            "6":0.4074074,
            "7":0.44444445,
            "8":0.8333333
         },
         "twoSetMax":50.0,
         "twoSetCounts":{
            "1":17,
            "5":18,
            "7":8,
            "8":15
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":8,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":8,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":7,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.5555556
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "1":4,
            "17":3,
            "49":1,
            "18":1,
            "5":13,
            "53":2,
            "55":7,
            "56":3,
            "41":5,
            "42":3,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.3846154,
            "1":0.30769232,
            "17":0.23076923,
            "49":0.07692308,
            "18":0.07692308,
            "5":1.0,
            "53":0.15384616,
            "55":0.53846157,
            "56":0.23076923,
            "41":0.3846154,
            "42":0.23076923,
            "28":0.30769232
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":2,
            "Health":11,
            "Potency":3,
            "Offense":10,
            "Tenacity":1,
            "Protection":7,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.15384616,
            "Health":0.84615386,
            "Potency":0.23076923,
            "Offense":0.7692308,
            "Tenacity":0.07692308,
            "Protection":0.53846157,
            "Defense":0.30769232
         }
      },
      {
         "name":"HERMITYODA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.26666668,
               "48":0.13333334,
               "49":0.8666667,
               "53":0.33333334,
               "55":1.0,
               "56":0.73333335
            },
            "5":{
               "55":0.9230769,
               "56":1.0
            },
            "6":{
               "48":0.14285715,
               "49":1.0,
               "17":0.2857143,
               "18":0.71428573,
               "55":0.85714287,
               "56":0.5714286
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "16":4,
               "48":2,
               "49":13,
               "53":5,
               "55":15,
               "56":11
            },
            "5":{
               "55":24,
               "56":26
            },
            "6":{
               "48":2,
               "49":14,
               "17":4,
               "18":10,
               "55":12,
               "56":8
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":21,
            "2":0,
            "3":3,
            "4":50,
            "5":0,
            "6":0,
            "7":3,
            "8":4
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.14285715,
            "4":1.0,
            "5":0.0,
            "6":0.0,
            "7":0.14285715,
            "8":0.1904762
         },
         "twoSetMax":44.333332,
         "twoSetCounts":{
            "1":21,
            "3":3,
            "5":0,
            "7":3,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "1":3,
            "17":4,
            "49":3,
            "18":4,
            "5":21,
            "53":3,
            "55":2,
            "56":5,
            "41":4,
            "42":2,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.04761905,
            "1":0.14285715,
            "17":0.1904762,
            "49":0.14285715,
            "18":0.1904762,
            "5":1.0,
            "53":0.14285715,
            "55":0.0952381,
            "56":0.23809524,
            "41":0.1904762,
            "42":0.0952381,
            "28":0.2857143
         },
         "secondaryTypeCounts":{
            "Speed":21,
            "Critical Chance":3,
            "Health":5,
            "Potency":4,
            "Offense":5,
            "Tenacity":4,
            "Protection":11,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.14285715,
            "Health":0.23809524,
            "Potency":0.1904762,
            "Offense":0.23809524,
            "Tenacity":0.1904762,
            "Protection":0.52380955,
            "Defense":0.23809524
         }
      },
      {
         "name":"POGGLETHELESSER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.021276595,
               "56":0.04255319
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.23076923,
               "16":0.07692308,
               "49":0.03846154,
               "53":0.30769232,
               "55":0.26923078,
               "56":1.0
            },
            "5":{
               "55":0.61290324,
               "56":1.0
            },
            "6":{
               "48":0.041666668,
               "17":1.0,
               "49":0.041666668,
               "18":0.5833333,
               "55":0.25,
               "56":0.16666667
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":47,
               "54":1,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "48":6,
               "16":2,
               "49":1,
               "53":8,
               "55":7,
               "56":26
            },
            "5":{
               "55":19,
               "56":31
            },
            "6":{
               "48":1,
               "17":24,
               "49":1,
               "18":14,
               "55":6,
               "56":4
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.1904762,
         "setCounts":{
            "1":11,
            "3":2,
            "4":42,
            "5":2,
            "6":0,
            "7":30,
            "8":18
         },
         "fourSetMultipliers":{
            "1":0.36666667,
            "3":0.06666667,
            "4":1.0,
            "5":0.06666667,
            "6":0.0,
            "7":1.0,
            "8":0.6
         },
         "twoSetMax":68.0,
         "twoSetCounts":{
            "1":11,
            "3":2,
            "5":2,
            "7":30,
            "8":18
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":30.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":20.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":13.333333
            },
            {
               "set":8,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "17":2,
            "1":9,
            "49":1,
            "18":4,
            "5":16,
            "53":2,
            "55":3,
            "56":1,
            "41":1,
            "42":3,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.1875,
            "17":0.125,
            "1":0.5625,
            "49":0.0625,
            "18":0.25,
            "5":1.0,
            "53":0.125,
            "55":0.1875,
            "56":0.0625,
            "41":0.0625,
            "42":0.1875,
            "28":0.25
         },
         "secondaryTypeCounts":{
            "Speed":16,
            "Critical Chance":2,
            "Health":12,
            "Potency":2,
            "Offense":4,
            "Tenacity":4,
            "Protection":5,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.125,
            "Health":0.75,
            "Potency":0.125,
            "Offense":0.25,
            "Tenacity":0.25,
            "Protection":0.3125,
            "Defense":0.25
         }
      },
      {
         "name":"QUIGONJINN",
         "count":48,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "56":0.044444446
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.42105263,
               "53":0.5263158,
               "55":0.2631579,
               "56":0.2631579
            },
            "5":{
               "55":1.0,
               "56":0.88
            },
            "6":{
               "48":1.0,
               "17":0.4,
               "49":0.33333334,
               "18":0.4,
               "55":0.33333334,
               "56":0.73333335
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "5":45,
               "56":2
            },
            "3":{
               "49":47
            },
            "4":{
               "16":19,
               "48":8,
               "53":10,
               "55":5,
               "56":5
            },
            "5":{
               "55":25,
               "56":22
            },
            "6":{
               "48":15,
               "17":6,
               "49":5,
               "18":6,
               "55":5,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.2,
         "setCounts":{
            "1":26,
            "2":6,
            "3":0,
            "4":27,
            "5":13,
            "6":7,
            "7":6,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.22222222,
            "3":0.0,
            "4":1.0,
            "5":0.5,
            "6":0.25925925,
            "7":0.23076923,
            "8":0.07692308
         },
         "twoSetMax":56.333336,
         "twoSetCounts":{
            "1":26,
            "3":0,
            "5":13,
            "7":6,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":26.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":17.333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":11.555556
            },
            {
               "set":5,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "1":7,
            "17":4,
            "49":7,
            "18":6,
            "5":11,
            "53":4,
            "55":3,
            "56":2,
            "41":2,
            "42":4,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.45454547,
            "1":0.6363636,
            "17":0.36363637,
            "49":0.6363636,
            "18":0.54545456,
            "5":1.0,
            "53":0.36363637,
            "55":0.27272728,
            "56":0.18181819,
            "41":0.18181819,
            "42":0.36363637,
            "28":0.45454547
         },
         "secondaryTypeCounts":{
            "Speed":11,
            "Critical Chance":4,
            "Health":10,
            "Potency":4,
            "Offense":7,
            "Tenacity":6,
            "Protection":7,
            "Defense":11
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.36363637,
            "Health":0.90909094,
            "Potency":0.36363637,
            "Offense":0.6363636,
            "Tenacity":0.54545456,
            "Protection":0.6363636,
            "Defense":1.0
         }
      },
      {
         "name":"GEONOSIANSPY",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.42857143,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.021276595,
               "49":0.021276595,
               "55":0.021276595
            },
            "5":{
               "55":0.5151515,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "49":0.022727273,
               "18":0.022727273,
               "55":0.045454547,
               "56":0.045454547
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":15,
               "5":35
            },
            "3":{
               "49":50
            },
            "4":{
               "16":47,
               "48":1,
               "49":1,
               "55":1
            },
            "5":{
               "55":17,
               "56":33
            },
            "6":{
               "48":44,
               "49":1,
               "18":1,
               "55":2,
               "56":2
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":11,
            "2":7,
            "4":5,
            "5":35,
            "6":38,
            "7":1,
            "8":0
         },
         "fourSetMultipliers":{
            "1":0.31428573,
            "2":0.18421052,
            "4":0.13157895,
            "5":1.0,
            "6":1.0,
            "7":0.028571429,
            "8":0.0
         },
         "twoSetMax":73.88889,
         "twoSetCounts":{
            "1":11,
            "5":35,
            "7":1,
            "8":0
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":35.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":23.333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":15.555556
            },
            {
               "set":1,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "17":8,
            "49":4,
            "1":1,
            "18":4,
            "5":13,
            "53":11,
            "55":1,
            "56":3,
            "41":5,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.30769232,
            "17":0.61538464,
            "49":0.30769232,
            "1":0.07692308,
            "18":0.30769232,
            "5":1.0,
            "53":0.84615386,
            "55":0.07692308,
            "56":0.23076923,
            "41":0.3846154,
            "42":0.15384616,
            "28":0.15384616
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":11,
            "Health":2,
            "Potency":8,
            "Offense":9,
            "Tenacity":4,
            "Protection":5,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.84615386,
            "Health":0.15384616,
            "Potency":0.61538464,
            "Offense":0.6923077,
            "Tenacity":0.30769232,
            "Protection":0.3846154,
            "Defense":0.46153846
         }
      },
      {
         "name":"GRANDMOFFTARKIN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.04347826,
               "5":1.0,
               "54":0.02173913,
               "56":0.02173913
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.6315789,
               "49":0.05263158,
               "53":0.36842105,
               "55":0.21052632,
               "56":0.36842105
            },
            "5":{
               "55":0.61290324,
               "56":1.0
            },
            "6":{
               "48":0.114285715,
               "17":1.0,
               "49":0.028571429,
               "18":0.114285715,
               "55":0.057142857,
               "56":0.114285715
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":2,
               "5":46,
               "54":1,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":19,
               "48":12,
               "49":1,
               "53":7,
               "55":4,
               "56":7
            },
            "5":{
               "55":19,
               "56":31
            },
            "6":{
               "48":4,
               "17":35,
               "49":1,
               "18":4,
               "55":2,
               "56":4
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.3888889,
         "setCounts":{
            "1":9,
            "2":5,
            "3":2,
            "4":21,
            "5":7,
            "6":10,
            "7":46,
            "8":6
         },
         "fourSetMultipliers":{
            "1":0.19565217,
            "2":0.23809524,
            "3":0.04347826,
            "4":1.0,
            "5":0.1521739,
            "6":0.47619048,
            "7":1.0,
            "8":0.13043478
         },
         "twoSetMax":97.11111,
         "twoSetCounts":{
            "1":9,
            "3":2,
            "5":7,
            "7":46,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":46.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":30.666666
            },
            {
               "set":7,
               "occurrence":3,
               "count":20.444445
            },
            {
               "set":1,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "49":2,
            "17":6,
            "1":1,
            "18":3,
            "53":6,
            "5":15,
            "55":1,
            "56":3,
            "41":3,
            "42":3,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.33333334,
            "49":0.13333334,
            "17":0.4,
            "1":0.06666667,
            "18":0.2,
            "53":0.4,
            "5":1.0,
            "55":0.06666667,
            "56":0.2,
            "41":0.2,
            "42":0.2,
            "28":0.2
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":6,
            "Health":2,
            "Potency":6,
            "Offense":8,
            "Tenacity":3,
            "Protection":6,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.4,
            "Health":0.13333334,
            "Potency":0.4,
            "Offense":0.53333336,
            "Tenacity":0.2,
            "Protection":0.4,
            "Defense":0.33333334
         }
      },
      {
         "name":"DARTHREVAN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.5862069,
               "5":1.0,
               "54":0.03448276,
               "55":0.03448276,
               "56":0.06896552
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.3448276,
               "53":0.20689656,
               "55":0.1724138
            },
            "5":{
               "55":0.78571427,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.3,
               "49":0.033333335,
               "18":0.033333335,
               "55":0.16666667,
               "56":0.13333334
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":17,
               "5":29,
               "54":1,
               "55":1,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":29,
               "48":10,
               "53":6,
               "55":5
            },
            "5":{
               "55":22,
               "56":28
            },
            "6":{
               "48":30,
               "17":9,
               "49":1,
               "18":1,
               "55":5,
               "56":4
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.020408163,
         "setCounts":{
            "1":23,
            "2":2,
            "3":2,
            "4":47,
            "5":5,
            "6":0,
            "7":8,
            "8":3
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.04255319,
            "3":0.08695652,
            "4":1.0,
            "5":0.2173913,
            "6":0.0,
            "7":0.3478261,
            "8":0.13043478
         },
         "twoSetMax":48.555553,
         "twoSetCounts":{
            "1":23,
            "3":2,
            "5":5,
            "7":8,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":23.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":15.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":10.222222
            },
            {
               "set":7,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":5,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":5,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":9,
            "49":3,
            "17":2,
            "18":3,
            "53":5,
            "5":21,
            "55":4,
            "56":1,
            "41":5,
            "42":5,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.0952381,
            "1":0.42857143,
            "49":0.14285715,
            "17":0.0952381,
            "18":0.14285715,
            "53":0.23809524,
            "5":1.0,
            "55":0.1904762,
            "56":0.04761905,
            "41":0.23809524,
            "42":0.23809524,
            "28":0.1904762
         },
         "secondaryTypeCounts":{
            "Speed":21,
            "Critical Chance":5,
            "Health":13,
            "Potency":2,
            "Offense":7,
            "Tenacity":3,
            "Protection":5,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.23809524,
            "Health":0.61904764,
            "Potency":0.0952381,
            "Offense":0.33333334,
            "Tenacity":0.14285715,
            "Protection":0.23809524,
            "Defense":0.3809524
         }
      },
      {
         "name":"NIGHTSISTERINITIATE",
         "count":47,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.07692308,
               "52":0.025641026,
               "5":1.0,
               "55":0.025641026,
               "56":0.025641026
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.5833333,
               "49":0.33333334,
               "53":0.9166667,
               "55":0.5833333,
               "56":0.33333334
            },
            "5":{
               "55":1.0,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.5,
               "49":0.25,
               "18":0.8333333,
               "55":0.33333334,
               "56":0.9166667
            }
         },
         "primaryCounts":{
            "1":{
               "48":46
            },
            "2":{
               "48":3,
               "52":1,
               "5":39,
               "55":1,
               "56":1
            },
            "3":{
               "49":46
            },
            "4":{
               "16":12,
               "48":7,
               "49":4,
               "53":11,
               "55":7,
               "56":4
            },
            "5":{
               "55":23,
               "56":23
            },
            "6":{
               "48":12,
               "17":6,
               "49":3,
               "18":10,
               "55":4,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.6785714,
         "setCounts":{
            "1":18,
            "2":13,
            "3":2,
            "4":8,
            "5":22,
            "6":7,
            "7":5,
            "8":5
         },
         "fourSetMultipliers":{
            "1":0.8181818,
            "2":1.0,
            "3":0.09090909,
            "4":0.61538464,
            "5":1.0,
            "6":0.53846157,
            "7":0.22727273,
            "8":0.22727273
         },
         "twoSetMax":54.666668,
         "twoSetCounts":{
            "1":18,
            "3":2,
            "5":22,
            "7":5,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":22.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.666667
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.777778
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":6,
            "49":4,
            "1":1,
            "17":2,
            "18":4,
            "5":16,
            "53":2,
            "55":2,
            "56":6,
            "41":5,
            "42":3,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.375,
            "49":0.25,
            "1":0.0625,
            "17":0.125,
            "18":0.25,
            "5":1.0,
            "53":0.125,
            "55":0.125,
            "56":0.375,
            "41":0.3125,
            "42":0.1875,
            "28":0.125
         },
         "secondaryTypeCounts":{
            "Speed":16,
            "Critical Chance":2,
            "Health":3,
            "Potency":2,
            "Offense":11,
            "Tenacity":4,
            "Protection":8,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.125,
            "Health":0.1875,
            "Potency":0.125,
            "Offense":0.6875,
            "Tenacity":0.25,
            "Protection":0.5,
            "Defense":0.4375
         }
      },
      {
         "name":"SUNFAC",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":0.4375,
               "54":0.125,
               "56":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "49":0.022222223,
               "55":0.08888889,
               "56":1.0
            },
            "5":{
               "55":0.041666668,
               "56":1.0
            },
            "6":{
               "18":0.27027026,
               "55":0.08108108,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":14,
               "54":4,
               "56":32
            },
            "3":{
               "49":50
            },
            "4":{
               "49":1,
               "55":4,
               "56":45
            },
            "5":{
               "55":2,
               "56":48
            },
            "6":{
               "18":10,
               "55":3,
               "56":37
            }
         },
         "fourSetsMultiplier":0.08695652,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":95,
            "3":18,
            "4":4,
            "6":0,
            "7":1,
            "8":25
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.18947369,
            "4":1.0,
            "6":0.0,
            "7":0.010526316,
            "8":0.2631579
         },
         "twoSetMax":200.55554,
         "twoSetCounts":{
            "1":95,
            "3":18,
            "7":1,
            "8":25
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":95.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":63.333332
            },
            {
               "set":1,
               "occurrence":3,
               "count":42.22222
            },
            {
               "set":8,
               "occurrence":1,
               "count":25.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":16.666666
            },
            {
               "set":3,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":11.111111
            },
            {
               "set":3,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":5,
            "1":12,
            "49":5,
            "17":8,
            "18":2,
            "5":19,
            "53":1,
            "55":15,
            "56":2,
            "41":5,
            "42":2,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.2631579,
            "1":0.6315789,
            "49":0.2631579,
            "17":0.42105263,
            "18":0.10526316,
            "5":1.0,
            "53":0.05263158,
            "55":0.7894737,
            "56":0.10526316,
            "41":0.2631579,
            "42":0.10526316,
            "28":0.31578946
         },
         "secondaryTypeCounts":{
            "Speed":19,
            "Critical Chance":1,
            "Health":27,
            "Potency":8,
            "Offense":10,
            "Tenacity":2,
            "Protection":8,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":0.7037037,
            "Critical Chance":0.037037037,
            "Health":1.0,
            "Potency":0.2962963,
            "Offense":0.37037036,
            "Tenacity":0.074074075,
            "Protection":0.2962963,
            "Defense":0.25925925
         }
      },
      {
         "name":"AMILYNHOLDO",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":0.64705884,
               "54":1.0,
               "55":0.47058824,
               "56":0.8235294
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.032258064,
               "55":0.58064514,
               "56":1.0
            },
            "5":{
               "55":0.47058824,
               "56":1.0
            },
            "6":{
               "17":0.027777778,
               "18":1.0,
               "55":0.19444445,
               "56":0.16666667
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":11,
               "54":17,
               "55":8,
               "56":14
            },
            "3":{
               "49":50
            },
            "4":{
               "48":1,
               "55":18,
               "56":31
            },
            "5":{
               "55":16,
               "56":34
            },
            "6":{
               "17":1,
               "18":36,
               "55":7,
               "56":6
            }
         },
         "fourSetsMultiplier":0.041666668,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":41,
            "2":0,
            "3":4,
            "4":2,
            "5":0,
            "6":0,
            "7":1,
            "8":95
         },
         "fourSetMultipliers":{
            "1":0.43157893,
            "2":0.0,
            "3":0.042105265,
            "4":1.0,
            "5":0.0,
            "6":0.0,
            "7":0.010526316,
            "8":1.0
         },
         "twoSetMax":200.55554,
         "twoSetCounts":{
            "1":41,
            "3":4,
            "5":0,
            "7":1,
            "8":95
         },
         "twoSetOccurrenceCounts":[
            {
               "set":8,
               "occurrence":1,
               "count":95.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":63.333332
            },
            {
               "set":8,
               "occurrence":3,
               "count":42.22222
            },
            {
               "set":1,
               "occurrence":1,
               "count":41.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":27.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":18.222223
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"tenacity",
         "commonSet2Name":"tenacity",
         "commonSet3Name":"tenacity",
         "secondaryCounts":{
            "48":5,
            "1":15,
            "17":2,
            "49":2,
            "18":3,
            "5":12,
            "53":4,
            "55":9,
            "56":4,
            "41":7,
            "42":2,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.33333334,
            "1":1.0,
            "17":0.13333334,
            "49":0.13333334,
            "18":0.2,
            "5":0.8,
            "53":0.26666668,
            "55":0.6,
            "56":0.26666668,
            "41":0.46666667,
            "42":0.13333334,
            "28":0.4
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":4,
            "Health":24,
            "Potency":2,
            "Offense":12,
            "Tenacity":3,
            "Protection":10,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":0.5,
            "Critical Chance":0.16666667,
            "Health":1.0,
            "Potency":0.083333336,
            "Offense":0.5,
            "Tenacity":0.125,
            "Protection":0.41666666,
            "Defense":0.16666667
         }
      },
      {
         "name":"URORRURRR",
         "count":42,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.17857143,
               "52":0.071428575,
               "5":1.0,
               "54":0.035714287,
               "55":0.071428575,
               "56":0.10714286
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.35714287,
               "16":1.0,
               "49":0.35714287,
               "53":0.35714287,
               "55":0.42857143,
               "56":0.5
            },
            "5":{
               "55":0.61538464,
               "56":1.0
            },
            "6":{
               "48":0.54545456,
               "17":0.6363636,
               "49":0.45454547,
               "18":1.0,
               "55":0.36363637,
               "56":0.8181818
            }
         },
         "primaryCounts":{
            "1":{
               "48":41
            },
            "2":{
               "48":5,
               "52":2,
               "5":28,
               "54":1,
               "55":2,
               "56":3
            },
            "3":{
               "49":42
            },
            "4":{
               "48":5,
               "16":14,
               "49":5,
               "53":5,
               "55":6,
               "56":7
            },
            "5":{
               "55":16,
               "56":26
            },
            "6":{
               "48":6,
               "17":7,
               "49":5,
               "18":11,
               "55":4,
               "56":9
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.61538464,
         "setCounts":{
            "1":25,
            "2":2,
            "3":2,
            "4":15,
            "5":10,
            "6":9,
            "7":3,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.13333334,
            "3":0.08,
            "4":1.0,
            "5":0.4,
            "6":0.6,
            "7":0.12,
            "8":0.08
         },
         "twoSetMax":52.777775,
         "twoSetCounts":{
            "1":25,
            "3":2,
            "5":10,
            "7":3,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":25.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":16.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":11.111111
            },
            {
               "set":5,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "17":5,
            "1":5,
            "49":2,
            "18":5,
            "5":14,
            "53":3,
            "55":2,
            "56":2,
            "41":8,
            "42":3,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.2857143,
            "17":0.35714287,
            "1":0.35714287,
            "49":0.14285715,
            "18":0.35714287,
            "5":1.0,
            "53":0.21428572,
            "55":0.14285715,
            "56":0.14285715,
            "41":0.5714286,
            "42":0.21428572,
            "28":0.2857143
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":3,
            "Health":7,
            "Potency":5,
            "Offense":12,
            "Tenacity":5,
            "Protection":6,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.21428572,
            "Health":0.5,
            "Potency":0.35714287,
            "Offense":0.85714287,
            "Tenacity":0.35714287,
            "Protection":0.42857143,
            "Defense":0.35714287
         }
      },
      {
         "name":"PHASMA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "55":0.020408163
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.25,
               "49":0.125,
               "53":1.0,
               "55":0.5,
               "56":0.25
            },
            "5":{
               "55":0.5151515,
               "56":1.0
            },
            "6":{
               "48":0.64285713,
               "49":0.42857143,
               "17":0.71428573,
               "18":0.5,
               "55":0.2857143,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":49,
               "55":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":16,
               "48":4,
               "49":2,
               "53":16,
               "55":8,
               "56":4
            },
            "5":{
               "55":17,
               "56":33
            },
            "6":{
               "48":9,
               "49":6,
               "17":10,
               "18":7,
               "55":4,
               "56":14
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.1627907,
         "setCounts":{
            "1":14,
            "2":0,
            "3":5,
            "4":43,
            "5":15,
            "6":0,
            "7":9,
            "8":2
         },
         "fourSetMultipliers":{
            "1":0.93333334,
            "2":0.0,
            "3":0.33333334,
            "4":1.0,
            "5":1.0,
            "6":0.0,
            "7":0.6,
            "8":0.13333334
         },
         "twoSetMax":39.0,
         "twoSetCounts":{
            "1":14,
            "3":5,
            "5":15,
            "7":9,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":7,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":1,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":7,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":4,
            "17":2,
            "49":4,
            "18":2,
            "5":17,
            "53":4,
            "55":1,
            "56":2,
            "41":3,
            "42":6,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.11764706,
            "1":0.23529412,
            "17":0.11764706,
            "49":0.23529412,
            "18":0.11764706,
            "5":1.0,
            "53":0.23529412,
            "55":0.05882353,
            "56":0.11764706,
            "41":0.1764706,
            "42":0.3529412,
            "28":0.05882353
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":4,
            "Health":5,
            "Potency":2,
            "Offense":5,
            "Tenacity":2,
            "Protection":3,
            "Defense":10
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.23529412,
            "Health":0.29411766,
            "Potency":0.11764706,
            "Offense":0.29411766,
            "Tenacity":0.11764706,
            "Protection":0.1764706,
            "Defense":0.5882353
         }
      },
      {
         "name":"SHAAKTI",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.06382979
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.25,
               "48":0.125,
               "49":0.20833333,
               "53":0.16666667,
               "55":1.0,
               "56":0.33333334
            },
            "5":{
               "55":1.0,
               "56":0.5625
            },
            "6":{
               "48":0.10344828,
               "49":0.06896552,
               "17":0.10344828,
               "18":0.1724138,
               "55":1.0,
               "56":0.27586207
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":47,
               "54":3
            },
            "3":{
               "49":50
            },
            "4":{
               "16":6,
               "48":3,
               "49":5,
               "53":4,
               "55":24,
               "56":8
            },
            "5":{
               "55":32,
               "56":18
            },
            "6":{
               "48":3,
               "49":2,
               "17":3,
               "18":5,
               "55":29,
               "56":8
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.11111111,
         "setCounts":{
            "1":47,
            "3":3,
            "4":45,
            "5":1,
            "7":0,
            "8":4
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.06382979,
            "4":1.0,
            "5":0.021276595,
            "7":0.0,
            "8":0.08510638
         },
         "twoSetMax":99.22223,
         "twoSetCounts":{
            "1":47,
            "3":3,
            "5":1,
            "7":0,
            "8":4
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":47.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":31.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":20.88889
            },
            {
               "set":8,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":7,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":3,
            "17":2,
            "1":6,
            "18":2,
            "5":17,
            "53":4,
            "55":1,
            "56":3,
            "41":4,
            "42":5,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.11764706,
            "49":0.1764706,
            "17":0.11764706,
            "1":0.3529412,
            "18":0.11764706,
            "5":1.0,
            "53":0.23529412,
            "55":0.05882353,
            "56":0.1764706,
            "41":0.23529412,
            "42":0.29411766,
            "28":0.11764706
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":4,
            "Health":7,
            "Potency":2,
            "Offense":6,
            "Tenacity":2,
            "Protection":5,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.23529412,
            "Health":0.4117647,
            "Potency":0.11764706,
            "Offense":0.3529412,
            "Tenacity":0.11764706,
            "Protection":0.29411766,
            "Defense":0.47058824
         }
      },
      {
         "name":"SITHMARAUDER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.25641027,
               "5":1.0,
               "56":0.025641026
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.2631579,
               "56":0.05263158
            },
            "5":{
               "55":0.7241379,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "56":0.06382979
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":10,
               "5":39,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":38,
               "48":10,
               "56":2
            },
            "5":{
               "55":21,
               "56":29
            },
            "6":{
               "48":47,
               "56":3
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":10,
            "2":10,
            "3":1,
            "4":2,
            "5":31,
            "6":38,
            "7":3,
            "8":0
         },
         "fourSetMultipliers":{
            "1":0.32258064,
            "2":0.2631579,
            "3":0.032258064,
            "4":0.05263158,
            "5":1.0,
            "6":1.0,
            "7":0.09677419,
            "8":0.0
         },
         "twoSetMax":65.44444,
         "twoSetCounts":{
            "1":10,
            "3":1,
            "5":31,
            "7":3,
            "8":0
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":31.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":20.666666
            },
            {
               "set":5,
               "occurrence":3,
               "count":13.777778
            },
            {
               "set":1,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":4,
            "17":7,
            "49":3,
            "18":1,
            "5":15,
            "53":1,
            "55":3,
            "56":2,
            "41":8,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.26666668,
            "1":0.26666668,
            "17":0.46666667,
            "49":0.2,
            "18":0.06666667,
            "5":1.0,
            "53":0.06666667,
            "55":0.2,
            "56":0.13333334,
            "41":0.53333336,
            "42":0.13333334,
            "28":0.13333334
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":1,
            "Health":7,
            "Potency":7,
            "Offense":12,
            "Tenacity":1,
            "Protection":4,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.06666667,
            "Health":0.46666667,
            "Potency":0.46666667,
            "Offense":0.8,
            "Tenacity":0.06666667,
            "Protection":0.26666668,
            "Defense":0.33333334
         }
      },
      {
         "name":"RANGETROOPER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.06382979,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.56666666,
               "55":0.1
            },
            "5":{
               "55":0.78571427,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "49":0.030303031,
               "17":0.030303031,
               "18":0.121212125,
               "55":0.09090909,
               "56":0.21212122
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":3,
               "5":47
            },
            "3":{
               "49":50
            },
            "4":{
               "16":30,
               "48":17,
               "55":3
            },
            "5":{
               "55":22,
               "56":28
            },
            "6":{
               "48":33,
               "49":1,
               "17":1,
               "18":4,
               "55":3,
               "56":7
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.020408163,
         "setCounts":{
            "1":29,
            "2":18,
            "3":3,
            "4":8,
            "5":4,
            "6":23,
            "7":1,
            "8":6
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.7826087,
            "3":0.10344828,
            "4":0.3478261,
            "5":0.13793103,
            "6":1.0,
            "7":0.03448276,
            "8":0.20689656
         },
         "twoSetMax":61.222225,
         "twoSetCounts":{
            "1":29,
            "3":3,
            "5":4,
            "7":1,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":29.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":19.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":12.888889
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":6,
            "17":4,
            "49":1,
            "1":8,
            "18":3,
            "5":14,
            "53":1,
            "55":1,
            "56":5,
            "41":5,
            "42":1,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.42857143,
            "17":0.2857143,
            "49":0.071428575,
            "1":0.5714286,
            "18":0.21428572,
            "5":1.0,
            "53":0.071428575,
            "55":0.071428575,
            "56":0.35714287,
            "41":0.35714287,
            "42":0.071428575,
            "28":0.21428572
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":1,
            "Health":9,
            "Potency":4,
            "Offense":11,
            "Tenacity":3,
            "Protection":8,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.071428575,
            "Health":0.64285713,
            "Potency":0.2857143,
            "Offense":0.78571427,
            "Tenacity":0.21428572,
            "Protection":0.5714286,
            "Defense":0.14285715
         }
      },
      {
         "name":"NIGHTSISTERZOMBIE",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.06666667,
               "55":0.044444446
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.13793103,
               "16":0.20689656,
               "49":0.1724138,
               "53":0.03448276,
               "55":1.0,
               "56":0.13793103
            },
            "5":{
               "55":1.0,
               "56":0.25
            },
            "6":{
               "49":0.14285715,
               "17":0.071428575,
               "18":0.4642857,
               "55":1.0,
               "56":0.10714286
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":45,
               "54":3,
               "55":2
            },
            "3":{
               "49":50
            },
            "4":{
               "48":4,
               "16":6,
               "49":5,
               "53":1,
               "55":29,
               "56":4
            },
            "5":{
               "55":40,
               "56":10
            },
            "6":{
               "49":4,
               "17":2,
               "18":13,
               "55":28,
               "56":3
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.5151515,
         "setCounts":{
            "1":50,
            "2":1,
            "3":8,
            "4":32,
            "5":2,
            "6":0,
            "7":2,
            "8":15
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.03125,
            "3":0.16,
            "4":1.0,
            "5":0.04,
            "6":0.0,
            "7":0.04,
            "8":0.3
         },
         "twoSetMax":105.55555,
         "twoSetCounts":{
            "1":50,
            "3":8,
            "5":2,
            "7":2,
            "8":15
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":50.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":33.333332
            },
            {
               "set":1,
               "occurrence":3,
               "count":22.222221
            },
            {
               "set":8,
               "occurrence":1,
               "count":15.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":10.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":6.6666665
            },
            {
               "set":3,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":5,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":9,
            "17":2,
            "49":3,
            "18":2,
            "53":6,
            "5":15,
            "55":3,
            "56":3,
            "41":8,
            "42":3,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.13333334,
            "1":0.6,
            "17":0.13333334,
            "49":0.2,
            "18":0.13333334,
            "53":0.4,
            "5":1.0,
            "55":0.2,
            "56":0.2,
            "41":0.53333336,
            "42":0.2,
            "28":0.13333334
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":6,
            "Health":12,
            "Potency":2,
            "Offense":10,
            "Tenacity":2,
            "Protection":5,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.4,
            "Health":0.8,
            "Potency":0.13333334,
            "Offense":0.6666667,
            "Tenacity":0.13333334,
            "Protection":0.33333334,
            "Defense":0.4
         }
      },
      {
         "name":"SABINEWRENS3",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.02173913,
               "49":0.02173913,
               "5":1.0,
               "54":0.02173913,
               "56":0.02173913
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.2962963,
               "49":0.18518518,
               "53":0.22222222,
               "56":0.14814815
            },
            "5":{
               "55":0.31578946,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.2777778,
               "49":0.22222222,
               "18":0.2777778,
               "55":0.2777778,
               "56":0.7222222
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "49":1,
               "5":46,
               "54":1,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":27,
               "48":8,
               "49":5,
               "53":6,
               "56":4
            },
            "5":{
               "55":12,
               "56":38
            },
            "6":{
               "48":18,
               "17":5,
               "49":4,
               "18":5,
               "55":5,
               "56":13
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.11111111,
         "setCounts":{
            "1":9,
            "2":3,
            "3":3,
            "4":21,
            "5":26,
            "6":21,
            "7":6,
            "8":3
         },
         "fourSetMultipliers":{
            "1":0.34615386,
            "2":0.14285715,
            "3":0.115384616,
            "4":1.0,
            "5":1.0,
            "6":1.0,
            "7":0.23076923,
            "8":0.115384616
         },
         "twoSetMax":54.888893,
         "twoSetCounts":{
            "1":9,
            "3":3,
            "5":26,
            "7":6,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":26.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":17.333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":11.555556
            },
            {
               "set":1,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "17":4,
            "1":2,
            "49":4,
            "18":4,
            "5":12,
            "53":9,
            "55":2,
            "56":2,
            "41":8,
            "42":6,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.25,
            "17":0.33333334,
            "1":0.16666667,
            "49":0.33333334,
            "18":0.33333334,
            "5":1.0,
            "53":0.75,
            "55":0.16666667,
            "56":0.16666667,
            "41":0.6666667,
            "42":0.5,
            "28":0.41666666
         },
         "secondaryTypeCounts":{
            "Speed":12,
            "Critical Chance":9,
            "Health":4,
            "Potency":4,
            "Offense":11,
            "Tenacity":4,
            "Protection":7,
            "Defense":10
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.75,
            "Health":0.33333334,
            "Potency":0.33333334,
            "Offense":0.9166667,
            "Tenacity":0.33333334,
            "Protection":0.5833333,
            "Defense":0.8333333
         }
      },
      {
         "name":"EPIXFINN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.020408163,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.21621622,
               "53":0.08108108,
               "56":0.054054055
            },
            "5":{
               "55":1.0,
               "56":0.31578946
            },
            "6":{
               "48":1.0,
               "17":0.05,
               "49":0.025,
               "18":0.1,
               "55":0.05,
               "56":0.025
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "5":49
            },
            "3":{
               "49":50
            },
            "4":{
               "16":37,
               "48":8,
               "53":3,
               "56":2
            },
            "5":{
               "55":38,
               "56":12
            },
            "6":{
               "48":40,
               "17":2,
               "49":1,
               "18":4,
               "55":2,
               "56":1
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":21,
            "2":2,
            "3":1,
            "4":48,
            "5":13,
            "6":0,
            "7":4,
            "8":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.041666668,
            "3":0.04761905,
            "4":1.0,
            "5":0.61904764,
            "6":0.0,
            "7":0.1904762,
            "8":0.04761905
         },
         "twoSetMax":48.0,
         "twoSetCounts":{
            "1":21,
            "3":1,
            "5":13,
            "7":4,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":5,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":7,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":8,
            "49":4,
            "17":2,
            "1":2,
            "18":1,
            "5":18,
            "53":3,
            "55":1,
            "56":3,
            "41":4,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.44444445,
            "49":0.22222222,
            "17":0.11111111,
            "1":0.11111111,
            "18":0.055555556,
            "5":1.0,
            "53":0.16666667,
            "55":0.055555556,
            "56":0.16666667,
            "41":0.22222222,
            "42":0.11111111,
            "28":0.11111111
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":3,
            "Health":3,
            "Potency":2,
            "Offense":12,
            "Tenacity":1,
            "Protection":5,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.16666667,
            "Health":0.16666667,
            "Potency":0.11111111,
            "Offense":0.6666667,
            "Tenacity":0.055555556,
            "Protection":0.2777778,
            "Defense":0.33333334
         }
      },
      {
         "name":"B1BATTLEDROIDV2",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":1.0,
               "16":0.04255319,
               "53":0.021276595
            },
            "5":{
               "55":1.0,
               "56":1.0
            },
            "6":{
               "48":0.2820513,
               "17":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "48":47,
               "16":2,
               "53":1
            },
            "5":{
               "55":25,
               "56":25
            },
            "6":{
               "48":11,
               "17":39
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":4,
            "2":12,
            "3":2,
            "4":38,
            "5":0,
            "7":40
         },
         "fourSetMultipliers":{
            "1":0.1,
            "2":0.31578946,
            "3":0.05,
            "4":1.0,
            "5":0.0,
            "7":1.0
         },
         "twoSetMax":84.44444,
         "twoSetCounts":{
            "1":4,
            "3":2,
            "5":0,
            "7":40
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":40.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":26.666666
            },
            {
               "set":7,
               "occurrence":3,
               "count":17.777777
            },
            {
               "set":1,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "17":1,
            "1":3,
            "49":1,
            "18":5,
            "5":18,
            "53":1,
            "55":3,
            "56":1,
            "41":5,
            "42":6,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.16666667,
            "17":0.055555556,
            "1":0.16666667,
            "49":0.055555556,
            "18":0.2777778,
            "5":1.0,
            "53":0.055555556,
            "55":0.16666667,
            "56":0.055555556,
            "41":0.2777778,
            "42":0.33333334,
            "28":0.055555556
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":1,
            "Health":6,
            "Potency":1,
            "Offense":8,
            "Tenacity":5,
            "Protection":2,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.055555556,
            "Health":0.33333334,
            "Potency":0.055555556,
            "Offense":0.44444445,
            "Tenacity":0.2777778,
            "Protection":0.11111111,
            "Defense":0.3888889
         }
      },
      {
         "name":"BASTILASHAN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.8181818,
               "48":0.45454547,
               "49":0.54545456,
               "53":0.8181818,
               "55":0.90909094,
               "56":1.0
            },
            "5":{
               "55":1.0,
               "56":0.9230769
            },
            "6":{
               "48":0.1904762,
               "17":1.0,
               "49":0.1904762,
               "18":0.2857143,
               "55":0.23809524,
               "56":0.47619048
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "16":9,
               "48":5,
               "49":6,
               "53":9,
               "55":10,
               "56":11
            },
            "5":{
               "55":26,
               "56":24
            },
            "6":{
               "48":4,
               "17":21,
               "49":4,
               "18":6,
               "55":5,
               "56":10
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":21,
            "2":0,
            "3":1,
            "4":50,
            "5":7,
            "6":0,
            "7":8,
            "8":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.04761905,
            "4":1.0,
            "5":0.33333334,
            "6":0.0,
            "7":0.3809524,
            "8":0.04761905
         },
         "twoSetMax":44.333332,
         "twoSetCounts":{
            "1":21,
            "3":1,
            "5":7,
            "7":8,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":7,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":5,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":5,
            "17":3,
            "49":3,
            "18":3,
            "5":19,
            "53":5,
            "55":5,
            "56":3,
            "41":1,
            "42":2,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.21052632,
            "1":0.2631579,
            "17":0.15789473,
            "49":0.15789473,
            "18":0.15789473,
            "5":1.0,
            "53":0.2631579,
            "55":0.2631579,
            "56":0.15789473,
            "41":0.05263158,
            "42":0.10526316,
            "28":0.21052632
         },
         "secondaryTypeCounts":{
            "Speed":19,
            "Critical Chance":5,
            "Health":10,
            "Potency":3,
            "Offense":5,
            "Tenacity":3,
            "Protection":7,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.2631579,
            "Health":0.5263158,
            "Potency":0.15789473,
            "Offense":0.2631579,
            "Tenacity":0.15789473,
            "Protection":0.36842105,
            "Defense":0.2631579
         }
      },
      {
         "name":"TALIA",
         "count":48,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.19444445,
               "52":0.027777778,
               "5":1.0,
               "54":0.055555556,
               "56":0.027777778
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.9285714,
               "16":0.5,
               "49":0.071428575,
               "53":0.35714287,
               "55":1.0,
               "56":0.5714286
            },
            "5":{
               "55":1.0,
               "56":0.56666666
            },
            "6":{
               "48":1.0,
               "49":0.1875,
               "17":0.375,
               "18":0.1875,
               "55":0.9375,
               "56":0.3125
            }
         },
         "primaryCounts":{
            "1":{
               "48":48
            },
            "2":{
               "48":7,
               "52":1,
               "5":36,
               "54":2,
               "56":1
            },
            "3":{
               "49":47
            },
            "4":{
               "48":13,
               "16":7,
               "49":1,
               "53":5,
               "55":14,
               "56":8
            },
            "5":{
               "55":30,
               "56":17
            },
            "6":{
               "48":16,
               "49":3,
               "17":6,
               "18":3,
               "55":15,
               "56":5
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.4117647,
         "setCounts":{
            "1":42,
            "2":15,
            "3":0,
            "4":12,
            "5":12,
            "6":7,
            "7":1,
            "8":6
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0,
            "3":0.0,
            "4":0.8,
            "5":0.2857143,
            "6":0.46666667,
            "7":0.023809524,
            "8":0.14285715
         },
         "twoSetMax":88.666664,
         "twoSetCounts":{
            "1":42,
            "3":0,
            "5":12,
            "7":1,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":42.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":28.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":18.666666
            },
            {
               "set":5,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "1":2,
            "49":1,
            "17":4,
            "18":1,
            "5":16,
            "53":2,
            "55":4,
            "56":2,
            "41":14,
            "42":2,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.1875,
            "1":0.125,
            "49":0.0625,
            "17":0.25,
            "18":0.0625,
            "5":1.0,
            "53":0.125,
            "55":0.25,
            "56":0.125,
            "41":0.875,
            "42":0.125,
            "28":0.1875
         },
         "secondaryTypeCounts":{
            "Speed":16,
            "Critical Chance":2,
            "Health":6,
            "Potency":4,
            "Offense":17,
            "Tenacity":1,
            "Protection":5,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":0.9411765,
            "Critical Chance":0.11764706,
            "Health":0.3529412,
            "Potency":0.23529412,
            "Offense":1.0,
            "Tenacity":0.05882353,
            "Protection":0.29411766,
            "Defense":0.1764706
         }
      },
      {
         "name":"GENERALHUX",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.041666668
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.14285715,
               "16":0.21428572,
               "49":0.071428575,
               "53":0.071428575,
               "55":0.2857143,
               "56":1.0
            },
            "5":{
               "55":0.6666667,
               "56":1.0
            },
            "6":{
               "18":1.0,
               "55":0.020833334,
               "56":0.020833334
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":48,
               "54":2
            },
            "3":{
               "49":50
            },
            "4":{
               "48":4,
               "16":6,
               "49":2,
               "53":2,
               "55":8,
               "56":28
            },
            "5":{
               "55":20,
               "56":30
            },
            "6":{
               "18":48,
               "55":1,
               "56":1
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.35135135,
         "setCounts":{
            "1":6,
            "3":1,
            "4":37,
            "5":0,
            "7":2,
            "8":66
         },
         "fourSetMultipliers":{
            "1":0.09090909,
            "3":0.015151516,
            "4":1.0,
            "5":0.0,
            "7":0.030303031,
            "8":1.0
         },
         "twoSetMax":139.33333,
         "twoSetCounts":{
            "1":6,
            "3":1,
            "5":0,
            "7":2,
            "8":66
         },
         "twoSetOccurrenceCounts":[
            {
               "set":8,
               "occurrence":1,
               "count":66.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":44.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":29.333334
            },
            {
               "set":1,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"tenacity",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "17":1,
            "1":2,
            "49":4,
            "18":13,
            "5":14,
            "53":4,
            "55":3,
            "56":2,
            "41":4,
            "42":2,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.14285715,
            "17":0.071428575,
            "1":0.14285715,
            "49":0.2857143,
            "18":0.9285714,
            "5":1.0,
            "53":0.2857143,
            "55":0.21428572,
            "56":0.14285715,
            "41":0.2857143,
            "42":0.14285715,
            "28":0.35714287
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":4,
            "Health":5,
            "Potency":1,
            "Offense":6,
            "Tenacity":13,
            "Protection":7,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.2857143,
            "Health":0.35714287,
            "Potency":0.071428575,
            "Offense":0.42857143,
            "Tenacity":0.9285714,
            "Protection":0.5,
            "Defense":0.42857143
         }
      },
      {
         "name":"CT5555",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "54":0.020408163,
               "56":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "56":1.0
            },
            "5":{
               "56":1.0
            },
            "6":{
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "54":1,
               "56":49
            },
            "3":{
               "49":50
            },
            "4":{
               "56":50
            },
            "5":{
               "56":50
            },
            "6":{
               "56":50
            }
         },
         "fourSetsMultiplier":0.020408163,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":49,
            "2":1,
            "3":97,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.5051546,
            "2":1.0,
            "3":1.0,
            "8":0.010309278
         },
         "twoSetMax":210.66666,
         "twoSetCounts":{
            "1":49,
            "3":97,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":3,
               "occurrence":1,
               "count":97.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":64.666664
            },
            {
               "set":1,
               "occurrence":1,
               "count":49.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":43.11111
            },
            {
               "set":1,
               "occurrence":2,
               "count":32.666668
            },
            {
               "set":1,
               "occurrence":3,
               "count":21.777779
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"defense",
         "commonSet2Name":"defense",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":3,
            "1":1,
            "49":5,
            "17":3,
            "18":8,
            "5":6,
            "53":4,
            "55":1,
            "56":3,
            "41":2,
            "42":2,
            "28":13
         },
         "secondaryMultipliers":{
            "48":0.23076923,
            "1":0.07692308,
            "49":0.3846154,
            "17":0.23076923,
            "18":0.61538464,
            "5":0.46153846,
            "53":0.30769232,
            "55":0.07692308,
            "56":0.23076923,
            "41":0.15384616,
            "42":0.15384616,
            "28":1.0
         },
         "secondaryTypeCounts":{
            "Speed":6,
            "Critical Chance":4,
            "Health":2,
            "Potency":3,
            "Offense":5,
            "Tenacity":8,
            "Protection":16,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":0.375,
            "Critical Chance":0.25,
            "Health":0.125,
            "Potency":0.1875,
            "Offense":0.3125,
            "Tenacity":0.5,
            "Protection":1.0,
            "Defense":0.4375
         }
      },
      {
         "name":"GRANDMASTERLUKE",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "56":0.2820513
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.041666668,
               "56":1.0
            },
            "5":{
               "55":0.020408163,
               "56":1.0
            },
            "6":{
               "17":0.11363637,
               "55":0.022727273,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":39,
               "56":11
            },
            "3":{
               "49":50
            },
            "4":{
               "48":2,
               "56":48
            },
            "5":{
               "55":1,
               "56":49
            },
            "6":{
               "17":5,
               "55":1,
               "56":44
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.041666668,
         "setCounts":{
            "1":42,
            "3":3,
            "4":48,
            "5":1,
            "6":0,
            "7":5,
            "8":0
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.071428575,
            "4":1.0,
            "5":0.023809524,
            "6":0.0,
            "7":0.11904762,
            "8":0.0
         },
         "twoSetMax":88.666664,
         "twoSetCounts":{
            "1":42,
            "3":3,
            "5":1,
            "7":5,
            "8":0
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":42.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":28.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":18.666666
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":6,
            "17":3,
            "1":2,
            "49":3,
            "18":3,
            "5":25,
            "53":3,
            "55":9,
            "56":1,
            "41":5,
            "42":1,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.24,
            "17":0.12,
            "1":0.08,
            "49":0.12,
            "18":0.12,
            "5":1.0,
            "53":0.12,
            "55":0.36,
            "56":0.04,
            "41":0.2,
            "42":0.04,
            "28":0.04
         },
         "secondaryTypeCounts":{
            "Speed":25,
            "Critical Chance":3,
            "Health":11,
            "Potency":3,
            "Offense":11,
            "Tenacity":3,
            "Protection":2,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.12,
            "Health":0.44,
            "Potency":0.12,
            "Offense":0.44,
            "Tenacity":0.12,
            "Protection":0.08,
            "Defense":0.16
         }
      },
      {
         "name":"CARADUNE",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.021276595,
               "55":0.021276595,
               "56":0.021276595
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.42105263,
               "48":0.05263158,
               "49":0.31578946,
               "53":0.36842105,
               "55":0.47368422,
               "56":1.0
            },
            "5":{
               "55":0.7241379,
               "56":1.0
            },
            "6":{
               "48":0.26666668,
               "17":0.73333335,
               "49":0.33333334,
               "18":0.53333336,
               "55":0.46666667,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":47,
               "54":1,
               "55":1,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":8,
               "48":1,
               "49":6,
               "53":7,
               "55":9,
               "56":19
            },
            "5":{
               "55":21,
               "56":29
            },
            "6":{
               "48":4,
               "17":11,
               "49":5,
               "18":8,
               "55":7,
               "56":15
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.31578946,
         "setCounts":{
            "1":42,
            "2":1,
            "3":8,
            "4":34,
            "5":1,
            "6":3,
            "7":14,
            "8":3
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.029411765,
            "3":0.1904762,
            "4":1.0,
            "5":0.023809524,
            "6":0.0882353,
            "7":0.33333334,
            "8":0.071428575
         },
         "twoSetMax":88.666664,
         "twoSetCounts":{
            "1":42,
            "3":8,
            "5":1,
            "7":14,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":42.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":28.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":18.666666
            },
            {
               "set":7,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":3,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":3,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":3,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":4,
            "17":4,
            "49":2,
            "18":4,
            "5":16,
            "53":1,
            "55":9,
            "56":6,
            "41":1,
            "42":2,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.125,
            "1":0.25,
            "17":0.25,
            "49":0.125,
            "18":0.25,
            "5":1.0,
            "53":0.0625,
            "55":0.5625,
            "56":0.375,
            "41":0.0625,
            "42":0.125,
            "28":0.25
         },
         "secondaryTypeCounts":{
            "Speed":16,
            "Critical Chance":1,
            "Health":13,
            "Potency":4,
            "Offense":3,
            "Tenacity":4,
            "Protection":10,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.0625,
            "Health":0.8125,
            "Potency":0.25,
            "Offense":0.1875,
            "Tenacity":0.25,
            "Protection":0.625,
            "Defense":0.25
         }
      },
      {
         "name":"RESISTANCETROOPER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.56666666,
               "5":1.0,
               "54":0.033333335,
               "55":0.033333335,
               "56":0.033333335
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.16216215,
               "49":0.027027028,
               "53":0.10810811,
               "56":0.054054055
            },
            "5":{
               "55":0.5151515,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.7619048,
               "49":0.04761905,
               "18":0.14285715,
               "55":0.0952381,
               "56":0.33333334
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":17,
               "5":30,
               "54":1,
               "55":1,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":37,
               "48":6,
               "49":1,
               "53":4,
               "56":2
            },
            "5":{
               "55":17,
               "56":33
            },
            "6":{
               "48":21,
               "17":16,
               "49":1,
               "18":3,
               "55":2,
               "56":7
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.25,
         "setCounts":{
            "1":9,
            "2":16,
            "3":1,
            "4":1,
            "5":21,
            "6":23,
            "7":26,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.34615386,
            "2":0.6956522,
            "3":0.03846154,
            "4":0.04347826,
            "5":0.8076923,
            "6":1.0,
            "7":1.0,
            "8":0.03846154
         },
         "twoSetMax":64.333336,
         "twoSetCounts":{
            "1":9,
            "3":1,
            "5":21,
            "7":26,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":26.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":17.333334
            },
            {
               "set":5,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":11.555556
            },
            {
               "set":5,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":2,
            "17":2,
            "1":1,
            "18":2,
            "5":14,
            "53":2,
            "55":6,
            "56":5,
            "41":4,
            "42":3,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.14285715,
            "49":0.14285715,
            "17":0.14285715,
            "1":0.071428575,
            "18":0.14285715,
            "5":1.0,
            "53":0.14285715,
            "55":0.42857143,
            "56":0.35714287,
            "41":0.2857143,
            "42":0.21428572,
            "28":0.35714287
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":2,
            "Health":7,
            "Potency":2,
            "Offense":6,
            "Tenacity":2,
            "Protection":10,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.14285715,
            "Health":0.5,
            "Potency":0.14285715,
            "Offense":0.42857143,
            "Tenacity":0.14285715,
            "Protection":0.71428573,
            "Defense":0.35714287
         }
      },
      {
         "name":"CASSIANANDOR",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.04347826,
               "52":0.02173913,
               "5":1.0,
               "54":0.02173913
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.73333335,
               "49":0.26666668,
               "53":0.53333336,
               "55":0.13333334,
               "56":0.6666667
            },
            "5":{
               "55":0.61290324,
               "56":1.0
            },
            "6":{
               "48":0.23333333,
               "17":1.0,
               "49":0.033333335,
               "18":0.1,
               "55":0.2,
               "56":0.1
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":2,
               "52":1,
               "5":46,
               "54":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":15,
               "48":11,
               "49":4,
               "53":8,
               "55":2,
               "56":10
            },
            "5":{
               "55":19,
               "56":31
            },
            "6":{
               "48":7,
               "17":30,
               "49":1,
               "18":3,
               "55":6,
               "56":3
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.42857143,
         "setCounts":{
            "1":14,
            "2":8,
            "3":4,
            "4":22,
            "5":8,
            "6":5,
            "7":36,
            "8":5
         },
         "fourSetMultipliers":{
            "1":0.3888889,
            "2":0.36363637,
            "3":0.11111111,
            "4":1.0,
            "5":0.22222222,
            "6":0.22727273,
            "7":1.0,
            "8":0.1388889
         },
         "twoSetMax":76.0,
         "twoSetCounts":{
            "1":14,
            "3":4,
            "5":8,
            "7":36,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":36.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":24.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":16.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":5,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":5,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":7,
            "1":3,
            "17":5,
            "49":3,
            "18":1,
            "5":15,
            "53":4,
            "55":3,
            "56":2,
            "41":9,
            "42":1,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.46666667,
            "1":0.2,
            "17":0.33333334,
            "49":0.2,
            "18":0.06666667,
            "5":1.0,
            "53":0.26666668,
            "55":0.2,
            "56":0.13333334,
            "41":0.6,
            "42":0.06666667,
            "28":0.06666667
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":4,
            "Health":6,
            "Potency":5,
            "Offense":16,
            "Tenacity":1,
            "Protection":3,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":0.9375,
            "Critical Chance":0.25,
            "Health":0.375,
            "Potency":0.3125,
            "Offense":1.0,
            "Tenacity":0.0625,
            "Protection":0.1875,
            "Defense":0.25
         }
      },
      {
         "name":"OLDBENKENOBI",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.032258064,
               "48":0.032258064,
               "49":0.29032257,
               "53":0.032258064,
               "55":0.22580644,
               "56":1.0
            },
            "5":{
               "55":0.2820513,
               "56":1.0
            },
            "6":{
               "17":1.0,
               "49":0.024390243,
               "18":0.07317073,
               "55":0.024390243,
               "56":0.09756097
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "16":1,
               "48":1,
               "49":9,
               "53":1,
               "55":7,
               "56":31
            },
            "5":{
               "55":11,
               "56":39
            },
            "6":{
               "17":41,
               "49":1,
               "18":3,
               "55":1,
               "56":4
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.31578946,
         "setCounts":{
            "1":16,
            "2":0,
            "3":6,
            "4":38,
            "5":1,
            "7":46,
            "8":2
         },
         "fourSetMultipliers":{
            "1":0.3478261,
            "2":0.0,
            "3":0.13043478,
            "4":1.0,
            "5":0.02173913,
            "7":1.0,
            "8":0.04347826
         },
         "twoSetMax":97.11111,
         "twoSetCounts":{
            "1":16,
            "3":6,
            "5":1,
            "7":46,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":46.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":30.666666
            },
            {
               "set":7,
               "occurrence":3,
               "count":20.444445
            },
            {
               "set":1,
               "occurrence":1,
               "count":16.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":10.666667
            },
            {
               "set":1,
               "occurrence":3,
               "count":7.111111
            },
            {
               "set":3,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "17":2,
            "49":2,
            "1":3,
            "18":2,
            "5":17,
            "53":6,
            "55":1,
            "56":5,
            "41":3,
            "42":1,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.1764706,
            "17":0.11764706,
            "49":0.11764706,
            "1":0.1764706,
            "18":0.11764706,
            "5":1.0,
            "53":0.3529412,
            "55":0.05882353,
            "56":0.29411766,
            "41":0.1764706,
            "42":0.05882353,
            "28":0.1764706
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":6,
            "Health":4,
            "Potency":2,
            "Offense":6,
            "Tenacity":2,
            "Protection":8,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.3529412,
            "Health":0.23529412,
            "Potency":0.11764706,
            "Offense":0.3529412,
            "Tenacity":0.11764706,
            "Protection":0.47058824,
            "Defense":0.1764706
         }
      },
      {
         "name":"ENFYSNEST",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.02173913,
               "5":0.04347826,
               "54":0.02173913,
               "55":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.022727273,
               "16":0.045454547,
               "49":0.022727273,
               "53":0.045454547,
               "55":1.0
            },
            "5":{
               "55":1.0,
               "56":0.020408163
            },
            "6":{
               "18":1.0,
               "55":0.06521739,
               "56":0.02173913
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "5":2,
               "54":1,
               "55":46
            },
            "3":{
               "49":50
            },
            "4":{
               "48":1,
               "16":2,
               "49":1,
               "53":2,
               "55":44
            },
            "5":{
               "55":49,
               "56":1
            },
            "6":{
               "18":46,
               "55":3,
               "56":1
            }
         },
         "fourSetsMultiplier":0.020408163,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":28,
            "3":0,
            "4":1,
            "5":0,
            "8":119
         },
         "fourSetMultipliers":{
            "1":0.23529412,
            "3":0.0,
            "4":1.0,
            "5":0.0,
            "8":1.0
         },
         "twoSetMax":251.22223,
         "twoSetCounts":{
            "1":28,
            "3":0,
            "5":0,
            "8":119
         },
         "twoSetOccurrenceCounts":[
            {
               "set":8,
               "occurrence":1,
               "count":119.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":79.333336
            },
            {
               "set":8,
               "occurrence":3,
               "count":52.88889
            },
            {
               "set":1,
               "occurrence":1,
               "count":28.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":18.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":12.444444
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"tenacity",
         "commonSet2Name":"tenacity",
         "commonSet3Name":"tenacity",
         "secondaryCounts":{
            "48":3,
            "1":11,
            "49":3,
            "17":4,
            "18":12,
            "53":1,
            "5":14,
            "55":2,
            "56":3,
            "41":4,
            "42":6,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.21428572,
            "1":0.78571427,
            "49":0.21428572,
            "17":0.2857143,
            "18":0.85714287,
            "53":0.071428575,
            "5":1.0,
            "55":0.14285715,
            "56":0.21428572,
            "41":0.2857143,
            "42":0.42857143,
            "28":0.2857143
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":1,
            "Health":13,
            "Potency":4,
            "Offense":7,
            "Tenacity":12,
            "Protection":7,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.071428575,
            "Health":0.9285714,
            "Potency":0.2857143,
            "Offense":0.5,
            "Tenacity":0.85714287,
            "Protection":0.5,
            "Defense":0.64285713
         }
      },
      {
         "name":"JEDIKNIGHTREVAN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.5,
               "16":1.0,
               "49":0.375,
               "53":0.5,
               "55":0.25,
               "56":0.5
            },
            "5":{
               "55":0.7241379,
               "56":1.0
            },
            "6":{
               "48":0.5,
               "49":0.3125,
               "17":0.125,
               "18":0.4375,
               "55":0.75,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "48":8,
               "16":16,
               "49":6,
               "53":8,
               "55":4,
               "56":8
            },
            "5":{
               "55":21,
               "56":29
            },
            "6":{
               "48":8,
               "49":5,
               "17":2,
               "18":7,
               "55":12,
               "56":16
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":27,
            "3":0,
            "4":50,
            "5":8,
            "6":0,
            "7":1,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.0,
            "4":1.0,
            "5":0.2962963,
            "6":0.0,
            "7":0.037037037,
            "8":0.074074075
         },
         "twoSetMax":57.0,
         "twoSetCounts":{
            "1":27,
            "3":0,
            "5":8,
            "7":1,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":27.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":18.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "49":3,
            "1":9,
            "17":1,
            "18":8,
            "5":23,
            "53":2,
            "55":2,
            "56":1,
            "41":2,
            "42":3,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.13043478,
            "49":0.13043478,
            "1":0.39130434,
            "17":0.04347826,
            "18":0.3478261,
            "5":1.0,
            "53":0.08695652,
            "55":0.08695652,
            "56":0.04347826,
            "41":0.08695652,
            "42":0.13043478,
            "28":0.08695652
         },
         "secondaryTypeCounts":{
            "Speed":23,
            "Critical Chance":2,
            "Health":11,
            "Potency":1,
            "Offense":5,
            "Tenacity":8,
            "Protection":3,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.08695652,
            "Health":0.47826087,
            "Potency":0.04347826,
            "Offense":0.2173913,
            "Tenacity":0.3478261,
            "Protection":0.13043478,
            "Defense":0.26086956
         }
      },
      {
         "name":"PADMEAMIDALA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.020833334,
               "55":1.0,
               "56":0.020833334
            },
            "5":{
               "55":1.0,
               "56":0.020408163
            },
            "6":{
               "48":0.02173913,
               "17":0.04347826,
               "55":1.0,
               "56":0.02173913
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "16":1,
               "55":48,
               "56":1
            },
            "5":{
               "55":49,
               "56":1
            },
            "6":{
               "48":1,
               "17":2,
               "55":46,
               "56":1
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.78571427,
         "setCounts":{
            "1":92,
            "3":1,
            "4":28,
            "7":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.010869565,
            "4":1.0,
            "7":0.010869565
         },
         "twoSetMax":194.22221,
         "twoSetCounts":{
            "1":92,
            "3":1,
            "7":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":92.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":61.333332
            },
            {
               "set":1,
               "occurrence":3,
               "count":40.88889
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "49":1,
            "17":1,
            "1":6,
            "18":6,
            "5":18,
            "53":4,
            "55":6,
            "56":2,
            "41":4,
            "42":3,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.055555556,
            "49":0.055555556,
            "17":0.055555556,
            "1":0.33333334,
            "18":0.33333334,
            "5":1.0,
            "53":0.22222222,
            "55":0.33333334,
            "56":0.11111111,
            "41":0.22222222,
            "42":0.16666667,
            "28":0.11111111
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":4,
            "Health":12,
            "Potency":1,
            "Offense":5,
            "Tenacity":6,
            "Protection":4,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.22222222,
            "Health":0.6666667,
            "Potency":0.055555556,
            "Offense":0.2777778,
            "Tenacity":0.33333334,
            "Protection":0.22222222,
            "Defense":0.22222222
         }
      },
      {
         "name":"JEDIKNIGHTLUKE",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.35135135,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.13636364
            },
            "5":{
               "55":0.5151515,
               "56":1.0
            },
            "6":{
               "48":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":13,
               "5":37
            },
            "3":{
               "49":50
            },
            "4":{
               "16":44,
               "48":6
            },
            "5":{
               "55":17,
               "56":33
            },
            "6":{
               "48":50
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":13,
            "2":44,
            "5":31,
            "6":6,
            "7":1,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.41935483,
            "2":1.0,
            "5":1.0,
            "6":0.13636364,
            "7":0.032258064,
            "8":0.032258064
         },
         "twoSetMax":64.666664,
         "twoSetCounts":{
            "1":13,
            "5":31,
            "7":1,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":31.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":20.666666
            },
            {
               "set":5,
               "occurrence":3,
               "count":13.777778
            },
            {
               "set":1,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":1,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":7,
            "17":2,
            "1":3,
            "49":3,
            "18":1,
            "5":19,
            "53":1,
            "55":3,
            "56":1,
            "41":5,
            "42":2,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.36842105,
            "17":0.10526316,
            "1":0.15789473,
            "49":0.15789473,
            "18":0.05263158,
            "5":1.0,
            "53":0.05263158,
            "55":0.15789473,
            "56":0.05263158,
            "41":0.2631579,
            "42":0.10526316,
            "28":0.05263158
         },
         "secondaryTypeCounts":{
            "Speed":19,
            "Critical Chance":1,
            "Health":6,
            "Potency":2,
            "Offense":12,
            "Tenacity":1,
            "Protection":2,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.05263158,
            "Health":0.31578946,
            "Potency":0.10526316,
            "Offense":0.6315789,
            "Tenacity":0.05263158,
            "Protection":0.10526316,
            "Defense":0.2631579
         }
      },
      {
         "name":"BASTILASHANDARK",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.020408163
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.375,
               "16":0.29166666,
               "49":0.041666668,
               "53":0.16666667,
               "55":1.0,
               "56":0.20833333
            },
            "5":{
               "55":1.0,
               "56":0.1627907
            },
            "6":{
               "48":0.06451613,
               "17":0.29032257,
               "49":0.09677419,
               "18":0.032258064,
               "55":1.0,
               "56":0.12903225
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":49,
               "54":1
            },
            "3":{
               "49":50
            },
            "4":{
               "48":9,
               "16":7,
               "49":1,
               "53":4,
               "55":24,
               "56":5
            },
            "5":{
               "55":43,
               "56":7
            },
            "6":{
               "48":2,
               "17":9,
               "49":3,
               "18":1,
               "55":31,
               "56":4
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.041666668,
         "setCounts":{
            "1":37,
            "2":0,
            "3":1,
            "4":48,
            "5":2,
            "6":0,
            "7":3,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.027027028,
            "4":1.0,
            "5":0.054054055,
            "6":0.0,
            "7":0.08108108,
            "8":0.054054055
         },
         "twoSetMax":78.11111,
         "twoSetCounts":{
            "1":37,
            "3":1,
            "5":2,
            "7":3,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":37.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":24.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":16.444445
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "49":3,
            "1":2,
            "17":2,
            "18":5,
            "5":24,
            "53":5,
            "55":10,
            "56":2,
            "41":3,
            "42":5,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.16666667,
            "49":0.125,
            "1":0.083333336,
            "17":0.083333336,
            "18":0.20833333,
            "5":1.0,
            "53":0.20833333,
            "55":0.41666666,
            "56":0.083333336,
            "41":0.125,
            "42":0.20833333,
            "28":0.083333336
         },
         "secondaryTypeCounts":{
            "Speed":24,
            "Critical Chance":5,
            "Health":12,
            "Potency":2,
            "Offense":7,
            "Tenacity":5,
            "Protection":4,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.20833333,
            "Health":0.5,
            "Potency":0.083333336,
            "Offense":0.29166666,
            "Tenacity":0.20833333,
            "Protection":0.16666667,
            "Defense":0.33333334
         }
      },
      {
         "name":"CHEWBACCALEGENDARY",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.06382979,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.5185185,
               "53":0.037037037,
               "55":0.074074075,
               "56":0.22222222
            },
            "5":{
               "55":0.78571427,
               "56":1.0
            },
            "6":{
               "48":0.020833334,
               "17":0.020833334,
               "18":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":3,
               "5":47
            },
            "3":{
               "49":50
            },
            "4":{
               "16":27,
               "48":14,
               "53":1,
               "55":2,
               "56":6
            },
            "5":{
               "55":22,
               "56":28
            },
            "6":{
               "48":1,
               "17":1,
               "18":48
            }
         },
         "fourSetsMultiplier":0.08695652,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":2,
            "2":4,
            "4":0,
            "5":3,
            "8":136
         },
         "fourSetMultipliers":{
            "1":0.014705882,
            "2":1.0,
            "4":0.0,
            "5":0.022058824,
            "8":1.0
         },
         "twoSetMax":287.11108,
         "twoSetCounts":{
            "1":2,
            "5":3,
            "8":136
         },
         "twoSetOccurrenceCounts":[
            {
               "set":8,
               "occurrence":1,
               "count":136.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":90.666664
            },
            {
               "set":8,
               "occurrence":3,
               "count":60.444443
            },
            {
               "set":5,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"tenacity",
         "commonSet2Name":"tenacity",
         "commonSet3Name":"tenacity",
         "secondaryCounts":{
            "48":4,
            "17":2,
            "49":4,
            "1":1,
            "18":6,
            "5":16,
            "53":4,
            "55":2,
            "56":4,
            "41":3,
            "42":6,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.25,
            "17":0.125,
            "49":0.25,
            "1":0.0625,
            "18":0.375,
            "5":1.0,
            "53":0.25,
            "55":0.125,
            "56":0.25,
            "41":0.1875,
            "42":0.375,
            "28":0.1875
         },
         "secondaryTypeCounts":{
            "Speed":16,
            "Critical Chance":4,
            "Health":3,
            "Potency":2,
            "Offense":7,
            "Tenacity":6,
            "Protection":7,
            "Defense":10
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.25,
            "Health":0.1875,
            "Potency":0.125,
            "Offense":0.4375,
            "Tenacity":0.375,
            "Protection":0.4375,
            "Defense":0.625
         }
      },
      {
         "name":"SITHASSASSIN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.020408163
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.71428573,
               "49":0.21428572,
               "53":0.9285714,
               "55":0.2857143,
               "56":0.42857143
            },
            "5":{
               "55":0.7241379,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.42105263,
               "49":0.47368422,
               "18":0.2631579,
               "55":0.21052632,
               "56":0.2631579
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":49,
               "54":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":14,
               "48":10,
               "49":3,
               "53":13,
               "55":4,
               "56":6
            },
            "5":{
               "55":21,
               "56":29
            },
            "6":{
               "48":19,
               "17":8,
               "49":9,
               "18":5,
               "55":4,
               "56":5
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.020408163,
         "setCounts":{
            "1":12,
            "2":0,
            "3":3,
            "4":47,
            "5":9,
            "6":2,
            "7":4,
            "8":6
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.25,
            "4":1.0,
            "5":0.75,
            "6":0.04255319,
            "7":0.33333334,
            "8":0.5
         },
         "twoSetMax":29.0,
         "twoSetCounts":{
            "1":12,
            "3":3,
            "5":9,
            "7":4,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":1,
            "49":1,
            "17":2,
            "18":3,
            "5":18,
            "53":8,
            "55":2,
            "56":2,
            "41":7,
            "42":1,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.11111111,
            "1":0.055555556,
            "49":0.055555556,
            "17":0.11111111,
            "18":0.16666667,
            "5":1.0,
            "53":0.44444445,
            "55":0.11111111,
            "56":0.11111111,
            "41":0.3888889,
            "42":0.055555556,
            "28":0.055555556
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":8,
            "Health":3,
            "Potency":2,
            "Offense":9,
            "Tenacity":3,
            "Protection":3,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.44444445,
            "Health":0.16666667,
            "Potency":0.11111111,
            "Offense":0.5,
            "Tenacity":0.16666667,
            "Protection":0.16666667,
            "Defense":0.11111111
         }
      },
      {
         "name":"DARTHMALAK",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":0.55172414,
               "54":0.1724138,
               "56":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "55":0.020408163,
               "56":1.0
            },
            "5":{
               "56":1.0
            },
            "6":{
               "17":0.02173913,
               "18":1.0,
               "56":0.06521739
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":16,
               "54":5,
               "56":29
            },
            "3":{
               "49":50
            },
            "4":{
               "55":1,
               "56":49
            },
            "5":{
               "56":50
            },
            "6":{
               "17":1,
               "18":46,
               "56":3
            }
         },
         "fourSetsMultiplier":0.08695652,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":5,
            "3":2,
            "4":4,
            "5":1,
            "8":134
         },
         "fourSetMultipliers":{
            "1":0.03731343,
            "3":0.014925373,
            "4":1.0,
            "5":0.0074626864,
            "8":1.0
         },
         "twoSetMax":282.88892,
         "twoSetCounts":{
            "1":5,
            "3":2,
            "5":1,
            "8":134
         },
         "twoSetOccurrenceCounts":[
            {
               "set":8,
               "occurrence":1,
               "count":134.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":89.333336
            },
            {
               "set":8,
               "occurrence":3,
               "count":59.555557
            },
            {
               "set":1,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"tenacity",
         "commonSet2Name":"tenacity",
         "commonSet3Name":"tenacity",
         "secondaryCounts":{
            "48":3,
            "49":3,
            "1":1,
            "17":3,
            "18":12,
            "5":13,
            "53":1,
            "55":3,
            "56":4,
            "41":4,
            "42":5,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.23076923,
            "49":0.23076923,
            "1":0.07692308,
            "17":0.23076923,
            "18":0.9230769,
            "5":1.0,
            "53":0.07692308,
            "55":0.23076923,
            "56":0.30769232,
            "41":0.30769232,
            "42":0.3846154,
            "28":0.07692308
         },
         "secondaryTypeCounts":{
            "Speed":13,
            "Critical Chance":1,
            "Health":4,
            "Potency":3,
            "Offense":7,
            "Tenacity":12,
            "Protection":5,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.07692308,
            "Health":0.30769232,
            "Potency":0.23076923,
            "Offense":0.53846157,
            "Tenacity":0.9230769,
            "Protection":0.3846154,
            "Defense":0.61538464
         }
      },
      {
         "name":"FIRSTORDEROFFICERMALE",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.45454547,
               "49":0.90909094,
               "53":0.54545456,
               "55":0.6363636,
               "56":1.0
            },
            "5":{
               "55":0.3888889,
               "56":1.0
            },
            "6":{
               "48":0.23076923,
               "17":1.0,
               "49":0.6923077,
               "18":0.46153846,
               "55":0.61538464,
               "56":0.84615386
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "16":11,
               "48":5,
               "49":10,
               "53":6,
               "55":7,
               "56":11
            },
            "5":{
               "55":14,
               "56":36
            },
            "6":{
               "48":3,
               "17":13,
               "49":9,
               "18":6,
               "55":8,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.041666668,
         "setCounts":{
            "1":18,
            "2":0,
            "3":1,
            "4":47,
            "5":5,
            "6":1,
            "7":14,
            "8":7
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.055555556,
            "4":1.0,
            "5":0.2777778,
            "6":0.021276595,
            "7":0.7777778,
            "8":0.3888889
         },
         "twoSetMax":44.0,
         "twoSetCounts":{
            "1":18,
            "3":1,
            "5":5,
            "7":14,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":18.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":9.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":6.222222
            },
            {
               "set":5,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":5,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":5,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":7,
            "1":3,
            "17":5,
            "49":4,
            "18":1,
            "5":15,
            "53":6,
            "55":2,
            "56":4,
            "41":1,
            "42":4,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.46666667,
            "1":0.2,
            "17":0.33333334,
            "49":0.26666668,
            "18":0.06666667,
            "5":1.0,
            "53":0.4,
            "55":0.13333334,
            "56":0.26666668,
            "41":0.06666667,
            "42":0.26666668,
            "28":0.4
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":6,
            "Health":5,
            "Potency":5,
            "Offense":8,
            "Tenacity":1,
            "Protection":10,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.4,
            "Health":0.33333334,
            "Potency":0.33333334,
            "Offense":0.53333336,
            "Tenacity":0.06666667,
            "Protection":0.6666667,
            "Defense":0.53333336
         }
      },
      {
         "name":"BARRISSOFFEE",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":0.11111111,
               "55":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "49":0.020408163,
               "55":1.0
            },
            "5":{
               "55":1.0
            },
            "6":{
               "18":0.020408163,
               "55":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":5,
               "55":45
            },
            "3":{
               "49":50
            },
            "4":{
               "49":1,
               "55":49
            },
            "5":{
               "55":50
            },
            "6":{
               "18":1,
               "55":49
            }
         },
         "fourSetsMultiplier":0.0,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":149,
            "5":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "5":0.0067114094
         },
         "twoSetMax":314.55557,
         "twoSetCounts":{
            "1":149,
            "5":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":149.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":99.333336
            },
            {
               "set":1,
               "occurrence":3,
               "count":66.22222
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":2,
            "1":17,
            "17":1,
            "49":5,
            "18":1,
            "5":3,
            "53":3,
            "55":5,
            "56":5,
            "41":4,
            "42":2,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.11764706,
            "1":1.0,
            "17":0.05882353,
            "49":0.29411766,
            "18":0.05882353,
            "5":0.1764706,
            "53":0.1764706,
            "55":0.29411766,
            "56":0.29411766,
            "41":0.23529412,
            "42":0.11764706,
            "28":0.05882353
         },
         "secondaryTypeCounts":{
            "Speed":3,
            "Critical Chance":3,
            "Health":22,
            "Potency":1,
            "Offense":6,
            "Tenacity":1,
            "Protection":6,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":0.13636364,
            "Critical Chance":0.13636364,
            "Health":1.0,
            "Potency":0.045454547,
            "Offense":0.27272728,
            "Tenacity":0.045454547,
            "Protection":0.27272728,
            "Defense":0.3181818
         }
      },
      {
         "name":"ANAKINKNIGHT",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.11363637,
               "5":1.0,
               "56":0.022727273
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.041666668
            },
            "5":{
               "55":1.0,
               "56":0.78571427
            },
            "6":{
               "48":1.0,
               "17":0.044444446,
               "55":0.044444446
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":5,
               "5":44,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":48,
               "48":2
            },
            "5":{
               "55":28,
               "56":22
            },
            "6":{
               "48":45,
               "17":2,
               "55":2
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.041666668,
         "setCounts":{
            "1":17,
            "2":5,
            "3":1,
            "5":30,
            "6":43,
            "7":3
         },
         "fourSetMultipliers":{
            "1":0.56666666,
            "2":0.11627907,
            "3":0.033333335,
            "5":1.0,
            "6":1.0,
            "7":0.1
         },
         "twoSetMax":67.0,
         "twoSetCounts":{
            "1":17,
            "3":1,
            "5":30,
            "7":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":30.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":20.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":13.333333
            },
            {
               "set":1,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":1,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":1,
            "17":4,
            "49":4,
            "18":5,
            "5":16,
            "53":8,
            "55":1,
            "56":2,
            "41":3,
            "42":2,
            "28":9
         },
         "secondaryMultipliers":{
            "48":0.125,
            "1":0.0625,
            "17":0.25,
            "49":0.25,
            "18":0.3125,
            "5":1.0,
            "53":0.5,
            "55":0.0625,
            "56":0.125,
            "41":0.1875,
            "42":0.125,
            "28":0.5625
         },
         "secondaryTypeCounts":{
            "Speed":16,
            "Critical Chance":8,
            "Health":2,
            "Potency":4,
            "Offense":5,
            "Tenacity":5,
            "Protection":11,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.5,
            "Health":0.125,
            "Potency":0.25,
            "Offense":0.3125,
            "Tenacity":0.3125,
            "Protection":0.6875,
            "Defense":0.375
         }
      },
      {
         "name":"HOTHREBELSCOUT",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.09090909,
               "5":1.0,
               "54":0.022727273
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.5,
               "16":1.0,
               "49":0.055555556,
               "53":0.8333333,
               "55":0.16666667,
               "56":0.16666667
            },
            "5":{
               "55":0.6333333,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.5,
               "49":0.09090909,
               "18":0.09090909,
               "55":0.3181818,
               "56":0.22727273
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":4,
               "5":44,
               "54":1
            },
            "3":{
               "49":49
            },
            "4":{
               "48":9,
               "16":18,
               "49":1,
               "53":15,
               "55":3,
               "56":3
            },
            "5":{
               "55":19,
               "56":30
            },
            "6":{
               "48":22,
               "17":11,
               "49":2,
               "18":2,
               "55":7,
               "56":5
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.11363637,
         "setCounts":{
            "1":11,
            "2":11,
            "3":1,
            "4":14,
            "5":31,
            "6":19,
            "7":8,
            "8":0
         },
         "fourSetMultipliers":{
            "1":0.3548387,
            "2":0.57894737,
            "3":0.032258064,
            "4":0.7368421,
            "5":1.0,
            "6":1.0,
            "7":0.2580645,
            "8":0.0
         },
         "twoSetMax":65.44444,
         "twoSetCounts":{
            "1":11,
            "3":1,
            "5":31,
            "7":8,
            "8":0
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":31.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":20.666666
            },
            {
               "set":5,
               "occurrence":3,
               "count":13.777778
            },
            {
               "set":1,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":7,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "17":2,
            "1":6,
            "49":5,
            "18":3,
            "5":15,
            "53":4,
            "55":4,
            "56":3,
            "41":2,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.2,
            "17":0.13333334,
            "1":0.4,
            "49":0.33333334,
            "18":0.2,
            "5":1.0,
            "53":0.26666668,
            "55":0.26666668,
            "56":0.2,
            "41":0.13333334,
            "42":0.13333334,
            "28":0.13333334
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":4,
            "Health":10,
            "Potency":2,
            "Offense":5,
            "Tenacity":3,
            "Protection":5,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.26666668,
            "Health":0.6666667,
            "Potency":0.13333334,
            "Offense":0.33333334,
            "Tenacity":0.2,
            "Protection":0.33333334,
            "Defense":0.46666667
         }
      },
      {
         "name":"MONMOTHMA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "54":0.020833334,
               "56":0.020833334
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.3181818,
               "16":0.13636364,
               "49":0.22727273,
               "53":0.22727273,
               "55":0.36363637,
               "56":1.0
            },
            "5":{
               "55":1.0,
               "56":0.5625
            },
            "6":{
               "48":0.14285715,
               "49":0.2857143,
               "17":0.2857143,
               "18":1.0,
               "55":0.9285714,
               "56":0.9285714
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":48,
               "54":1,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "48":7,
               "16":3,
               "49":5,
               "53":5,
               "55":8,
               "56":22
            },
            "5":{
               "55":32,
               "56":18
            },
            "6":{
               "48":2,
               "49":4,
               "17":4,
               "18":14,
               "55":13,
               "56":13
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.1904762,
         "setCounts":{
            "1":32,
            "2":3,
            "3":5,
            "4":39,
            "5":1,
            "6":0,
            "7":0,
            "8":16
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.07692308,
            "3":0.15625,
            "4":1.0,
            "5":0.03125,
            "6":0.0,
            "7":0.0,
            "8":0.5
         },
         "twoSetMax":69.333336,
         "twoSetCounts":{
            "1":32,
            "3":5,
            "5":1,
            "7":0,
            "8":16
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":32.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":21.333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":16.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":14.222222
            },
            {
               "set":8,
               "occurrence":2,
               "count":10.666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":7.111111
            },
            {
               "set":3,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":7,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":1,
            "17":3,
            "1":4,
            "18":7,
            "53":3,
            "5":15,
            "55":1,
            "56":3,
            "41":6,
            "42":1,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.13333334,
            "49":0.06666667,
            "17":0.2,
            "1":0.26666668,
            "18":0.46666667,
            "53":0.2,
            "5":1.0,
            "55":0.06666667,
            "56":0.2,
            "41":0.4,
            "42":0.06666667,
            "28":0.13333334
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":3,
            "Health":5,
            "Potency":3,
            "Offense":8,
            "Tenacity":7,
            "Protection":5,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.2,
            "Health":0.33333334,
            "Potency":0.2,
            "Offense":0.53333336,
            "Tenacity":0.46666667,
            "Protection":0.33333334,
            "Defense":0.13333334
         }
      },
      {
         "name":"CHIRRUTIMWE",
         "count":49,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.022727273,
               "5":1.0,
               "54":0.022727273,
               "55":0.045454547
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.17391305,
               "49":0.08695652,
               "55":0.4347826,
               "56":0.39130434
            },
            "5":{
               "55":1.0,
               "56":0.84615386
            },
            "6":{
               "48":0.8,
               "17":0.33333334,
               "49":0.13333334,
               "18":0.26666668,
               "55":1.0,
               "56":0.73333335
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":1,
               "5":44,
               "54":1,
               "55":2
            },
            "3":{
               "49":48
            },
            "4":{
               "16":23,
               "48":4,
               "49":2,
               "55":10,
               "56":9
            },
            "5":{
               "55":26,
               "56":22
            },
            "6":{
               "48":12,
               "17":5,
               "49":2,
               "18":4,
               "55":15,
               "56":11
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.25641027,
         "setCounts":{
            "1":35,
            "2":6,
            "3":2,
            "4":25,
            "5":13,
            "6":8,
            "7":1,
            "8":9
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.24,
            "3":0.057142857,
            "4":1.0,
            "5":0.37142858,
            "6":0.32,
            "7":0.028571429,
            "8":0.25714287
         },
         "twoSetMax":73.88889,
         "twoSetCounts":{
            "1":35,
            "3":2,
            "5":13,
            "7":1,
            "8":9
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":35.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":23.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":15.555556
            },
            {
               "set":5,
               "occurrence":1,
               "count":13.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":8.666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":5.777778
            },
            {
               "set":8,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "49":2,
            "1":3,
            "17":2,
            "18":1,
            "53":2,
            "5":15,
            "55":6,
            "56":9,
            "41":7,
            "42":3,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.26666668,
            "49":0.13333334,
            "1":0.2,
            "17":0.13333334,
            "18":0.06666667,
            "53":0.13333334,
            "5":1.0,
            "55":0.4,
            "56":0.6,
            "41":0.46666667,
            "42":0.2,
            "28":0.2
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":2,
            "Health":9,
            "Potency":2,
            "Offense":11,
            "Tenacity":1,
            "Protection":12,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.13333334,
            "Health":0.6,
            "Potency":0.13333334,
            "Offense":0.73333335,
            "Tenacity":0.06666667,
            "Protection":0.8,
            "Defense":0.33333334
         }
      },
      {
         "name":"AHSOKATANO",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.093023255,
               "52":0.023255814,
               "5":1.0,
               "55":0.046511628
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.7037037,
               "48":0.074074075,
               "53":0.074074075,
               "55":1.0
            },
            "5":{
               "55":1.0,
               "56":0.06382979
            },
            "6":{
               "48":0.3529412,
               "49":0.05882353,
               "17":0.029411765,
               "55":1.0,
               "56":0.029411765
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":4,
               "52":1,
               "5":43,
               "55":2
            },
            "3":{
               "49":50
            },
            "4":{
               "16":19,
               "48":2,
               "53":2,
               "55":27
            },
            "5":{
               "55":47,
               "56":3
            },
            "6":{
               "48":12,
               "49":2,
               "17":1,
               "55":34,
               "56":1
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.7241379,
         "setCounts":{
            "1":78,
            "2":6,
            "4":2,
            "5":10,
            "6":21,
            "7":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.2857143,
            "4":0.0952381,
            "5":0.12820514,
            "6":1.0,
            "7":0.012820513
         },
         "twoSetMax":164.66667,
         "twoSetCounts":{
            "1":78,
            "5":10,
            "7":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":78.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":52.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":34.666668
            },
            {
               "set":5,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"critDmg",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":8,
            "17":1,
            "49":1,
            "18":2,
            "5":16,
            "53":2,
            "55":2,
            "56":6,
            "41":6,
            "42":3,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.125,
            "1":0.5,
            "17":0.0625,
            "49":0.0625,
            "18":0.125,
            "5":1.0,
            "53":0.125,
            "55":0.125,
            "56":0.375,
            "41":0.375,
            "42":0.1875,
            "28":0.25
         },
         "secondaryTypeCounts":{
            "Speed":16,
            "Critical Chance":2,
            "Health":10,
            "Potency":1,
            "Offense":8,
            "Tenacity":2,
            "Protection":10,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.125,
            "Health":0.625,
            "Potency":0.0625,
            "Offense":0.5,
            "Tenacity":0.125,
            "Protection":0.625,
            "Defense":0.25
         }
      },
      {
         "name":"WATTAMBOR",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "55":0.020408163
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.2,
               "16":0.08,
               "49":0.32,
               "53":0.04,
               "55":1.0,
               "56":0.36
            },
            "5":{
               "55":1.0,
               "56":0.61290324
            },
            "6":{
               "17":0.61904764,
               "49":0.33333334,
               "18":0.1904762,
               "55":1.0,
               "56":0.23809524
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":49,
               "55":1
            },
            "3":{
               "49":50
            },
            "4":{
               "48":5,
               "16":2,
               "49":8,
               "53":1,
               "55":25,
               "56":9
            },
            "5":{
               "55":31,
               "56":19
            },
            "6":{
               "17":13,
               "49":7,
               "18":4,
               "55":21,
               "56":5
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.041666668,
         "setCounts":{
            "1":35,
            "2":0,
            "3":2,
            "4":48,
            "5":0,
            "6":0,
            "7":6,
            "8":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.057142857,
            "4":1.0,
            "5":0.0,
            "6":0.0,
            "7":0.17142858,
            "8":0.028571429
         },
         "twoSetMax":73.88889,
         "twoSetCounts":{
            "1":35,
            "3":2,
            "5":0,
            "7":6,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":35.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":23.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":15.555556
            },
            {
               "set":7,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "1":14,
            "17":1,
            "49":1,
            "18":1,
            "5":9,
            "53":2,
            "55":4,
            "56":1,
            "41":8,
            "42":4,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.21428572,
            "1":1.0,
            "17":0.071428575,
            "49":0.071428575,
            "18":0.071428575,
            "5":0.64285713,
            "53":0.14285715,
            "55":0.2857143,
            "56":0.071428575,
            "41":0.5714286,
            "42":0.2857143,
            "28":0.21428572
         },
         "secondaryTypeCounts":{
            "Speed":9,
            "Critical Chance":2,
            "Health":18,
            "Potency":1,
            "Offense":11,
            "Tenacity":1,
            "Protection":4,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":0.5,
            "Critical Chance":0.11111111,
            "Health":1.0,
            "Potency":0.055555556,
            "Offense":0.6111111,
            "Tenacity":0.055555556,
            "Protection":0.22222222,
            "Defense":0.2777778
         }
      },
      {
         "name":"BISTAN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.125,
               "5":1.0,
               "54":0.3125,
               "55":0.03125,
               "56":0.0625
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.375,
               "49":0.083333336,
               "53":0.29166666,
               "55":0.125,
               "56":0.20833333
            },
            "5":{
               "55":0.9230769,
               "56":1.0
            },
            "6":{
               "48":0.4117647,
               "17":1.0,
               "49":0.29411766,
               "18":0.4117647,
               "55":0.5294118,
               "56":0.23529412
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":4,
               "5":32,
               "54":10,
               "55":1,
               "56":2
            },
            "3":{
               "49":49
            },
            "4":{
               "16":24,
               "48":9,
               "49":2,
               "53":7,
               "55":3,
               "56":5
            },
            "5":{
               "55":24,
               "56":26
            },
            "6":{
               "48":7,
               "17":17,
               "49":5,
               "18":7,
               "55":9,
               "56":4
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.8518519,
         "setCounts":{
            "1":11,
            "2":5,
            "3":4,
            "4":18,
            "5":16,
            "6":4,
            "7":23,
            "8":5
         },
         "fourSetMultipliers":{
            "1":0.47826087,
            "2":0.2777778,
            "3":0.17391305,
            "4":1.0,
            "5":0.6956522,
            "6":0.22222222,
            "7":1.0,
            "8":0.2173913
         },
         "twoSetMax":54.333332,
         "twoSetCounts":{
            "1":11,
            "3":4,
            "5":16,
            "7":23,
            "8":5
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":23.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":16.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":15.333333
            },
            {
               "set":1,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":10.666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":10.222222
            },
            {
               "set":1,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":5,
               "occurrence":3,
               "count":7.111111
            },
            {
               "set":8,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "49":2,
            "1":1,
            "17":6,
            "18":6,
            "5":14,
            "53":1,
            "55":5,
            "56":3,
            "41":7,
            "42":4,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.2857143,
            "49":0.14285715,
            "1":0.071428575,
            "17":0.42857143,
            "18":0.42857143,
            "5":1.0,
            "53":0.071428575,
            "55":0.35714287,
            "56":0.21428572,
            "41":0.5,
            "42":0.2857143,
            "28":0.35714287
         },
         "secondaryTypeCounts":{
            "Speed":14,
            "Critical Chance":1,
            "Health":6,
            "Potency":6,
            "Offense":11,
            "Tenacity":6,
            "Protection":8,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.071428575,
            "Health":0.42857143,
            "Potency":0.42857143,
            "Offense":0.78571427,
            "Tenacity":0.42857143,
            "Protection":0.5714286,
            "Defense":0.42857143
         }
      },
      {
         "name":"SCARIFREBEL",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.09090909,
               "49":0.045454547,
               "5":1.0,
               "54":0.8636364,
               "55":0.13636364,
               "56":0.13636364
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.44444445,
               "48":0.22222222,
               "49":0.22222222,
               "53":0.16666667,
               "55":1.0,
               "56":0.7222222
            },
            "5":{
               "55":1.0,
               "56":0.9230769
            },
            "6":{
               "48":0.0625,
               "17":0.1875,
               "49":0.25,
               "18":1.0,
               "55":0.875,
               "56":0.75
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":2,
               "49":1,
               "5":22,
               "54":19,
               "55":3,
               "56":3
            },
            "3":{
               "49":50
            },
            "4":{
               "16":8,
               "48":4,
               "49":4,
               "53":3,
               "55":18,
               "56":13
            },
            "5":{
               "55":26,
               "56":24
            },
            "6":{
               "48":1,
               "17":3,
               "49":4,
               "18":16,
               "55":14,
               "56":12
            }
         },
         "fourSetsMultiplier":0.2195122,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":50,
            "2":1,
            "3":17,
            "4":7,
            "5":6,
            "6":1,
            "7":6,
            "8":8
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.14285715,
            "3":0.34,
            "4":1.0,
            "5":0.12,
            "6":0.14285715,
            "7":0.12,
            "8":0.16
         },
         "twoSetMax":105.55555,
         "twoSetCounts":{
            "1":50,
            "3":17,
            "5":6,
            "7":6,
            "8":8
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":50.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":33.333332
            },
            {
               "set":1,
               "occurrence":3,
               "count":22.222221
            },
            {
               "set":3,
               "occurrence":1,
               "count":17.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":11.333333
            },
            {
               "set":8,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":7.5555553
            },
            {
               "set":5,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":5,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":5,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.6666667
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":2,
            "17":1,
            "49":5,
            "1":1,
            "18":2,
            "5":15,
            "53":4,
            "55":2,
            "56":2,
            "41":2,
            "42":6,
            "28":8
         },
         "secondaryMultipliers":{
            "48":0.13333334,
            "17":0.06666667,
            "49":0.33333334,
            "1":0.06666667,
            "18":0.13333334,
            "5":1.0,
            "53":0.26666668,
            "55":0.13333334,
            "56":0.13333334,
            "41":0.13333334,
            "42":0.4,
            "28":0.53333336
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":4,
            "Health":3,
            "Potency":1,
            "Offense":4,
            "Tenacity":2,
            "Protection":10,
            "Defense":11
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.26666668,
            "Health":0.2,
            "Potency":0.06666667,
            "Offense":0.26666668,
            "Tenacity":0.13333334,
            "Protection":0.6666667,
            "Defense":0.73333335
         }
      },
      {
         "name":"GRANDMASTERYODA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.020408163,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.47619048,
               "53":0.14285715,
               "55":0.7619048
            },
            "5":{
               "55":1.0,
               "56":0.1627907
            },
            "6":{
               "48":0.84,
               "49":0.04,
               "18":0.04,
               "55":1.0,
               "56":0.08
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "5":49
            },
            "3":{
               "49":50
            },
            "4":{
               "16":21,
               "48":10,
               "53":3,
               "55":16
            },
            "5":{
               "55":43,
               "56":7
            },
            "6":{
               "48":21,
               "49":1,
               "18":1,
               "55":25,
               "56":2
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":37,
            "2":7,
            "3":1,
            "4":42,
            "5":5,
            "6":1,
            "7":1,
            "8":0
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.16666667,
            "3":0.027027028,
            "4":1.0,
            "5":0.13513513,
            "6":0.023809524,
            "7":0.027027028,
            "8":0.0
         },
         "twoSetMax":78.11111,
         "twoSetCounts":{
            "1":37,
            "3":1,
            "5":5,
            "7":1,
            "8":0
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":37.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":24.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":16.444445
            },
            {
               "set":5,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":5,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":4,
            "17":4,
            "49":4,
            "18":5,
            "5":20,
            "53":4,
            "55":1,
            "56":4,
            "41":9,
            "42":2,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.2,
            "1":0.2,
            "17":0.2,
            "49":0.2,
            "18":0.25,
            "5":1.0,
            "53":0.2,
            "55":0.05,
            "56":0.2,
            "41":0.45,
            "42":0.1,
            "28":0.05
         },
         "secondaryTypeCounts":{
            "Speed":20,
            "Critical Chance":4,
            "Health":5,
            "Potency":4,
            "Offense":13,
            "Tenacity":5,
            "Protection":5,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.2,
            "Health":0.25,
            "Potency":0.2,
            "Offense":0.65,
            "Tenacity":0.25,
            "Protection":0.25,
            "Defense":0.3
         }
      },
      {
         "name":"GREEFKARGA",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "56":0.020408163
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.625,
               "48":0.375,
               "49":0.125,
               "53":0.5625,
               "55":0.4375,
               "56":1.0
            },
            "5":{
               "55":0.9230769,
               "56":1.0
            },
            "6":{
               "48":0.41666666,
               "17":1.0,
               "49":0.5833333,
               "18":0.5833333,
               "55":0.75,
               "56":0.8333333
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":49,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":10,
               "48":6,
               "49":2,
               "53":9,
               "55":7,
               "56":16
            },
            "5":{
               "55":24,
               "56":26
            },
            "6":{
               "48":5,
               "17":12,
               "49":7,
               "18":7,
               "55":9,
               "56":10
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.041666668,
         "setCounts":{
            "1":26,
            "2":0,
            "3":1,
            "4":47,
            "5":3,
            "6":1,
            "7":9,
            "8":3
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.03846154,
            "4":1.0,
            "5":0.115384616,
            "6":0.021276595,
            "7":0.34615386,
            "8":0.115384616
         },
         "twoSetMax":54.888893,
         "twoSetCounts":{
            "1":26,
            "3":1,
            "5":3,
            "7":9,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":26.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":17.333334
            },
            {
               "set":1,
               "occurrence":3,
               "count":11.555556
            },
            {
               "set":7,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":4,
            "17":1,
            "1":1,
            "18":4,
            "5":18,
            "53":8,
            "55":1,
            "56":4,
            "41":1,
            "42":2,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.11111111,
            "49":0.22222222,
            "17":0.055555556,
            "1":0.055555556,
            "18":0.22222222,
            "5":1.0,
            "53":0.44444445,
            "55":0.055555556,
            "56":0.22222222,
            "41":0.055555556,
            "42":0.11111111,
            "28":0.33333334
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":8,
            "Health":2,
            "Potency":1,
            "Offense":3,
            "Tenacity":4,
            "Protection":10,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.44444445,
            "Health":0.11111111,
            "Potency":0.055555556,
            "Offense":0.16666667,
            "Tenacity":0.22222222,
            "Protection":0.5555556,
            "Defense":0.33333334
         }
      },
      {
         "name":"SUPREMELEADERKYLOREN",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.19512194,
               "16":1.0,
               "49":0.024390243
            },
            "5":{
               "55":1.0,
               "56":0.13636364
            },
            "6":{
               "48":1.0,
               "17":0.02173913,
               "49":0.02173913,
               "18":0.02173913,
               "55":0.02173913
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "48":8,
               "16":41,
               "49":1
            },
            "5":{
               "55":44,
               "56":6
            },
            "6":{
               "48":46,
               "17":1,
               "49":1,
               "18":1,
               "55":1
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":39,
            "2":1,
            "3":0,
            "4":48,
            "5":2,
            "6":1,
            "7":1,
            "8":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.020833334,
            "3":0.0,
            "4":1.0,
            "5":0.051282052,
            "6":0.020833334,
            "7":0.025641026,
            "8":0.025641026
         },
         "twoSetMax":82.333336,
         "twoSetCounts":{
            "1":39,
            "3":0,
            "5":2,
            "7":1,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":39.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":26.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":17.333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "49":5,
            "17":1,
            "1":3,
            "18":7,
            "53":2,
            "5":23,
            "55":5,
            "56":5,
            "41":5,
            "42":10,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.2173913,
            "49":0.2173913,
            "17":0.04347826,
            "1":0.13043478,
            "18":0.3043478,
            "53":0.08695652,
            "5":1.0,
            "55":0.2173913,
            "56":0.2173913,
            "41":0.2173913,
            "42":0.4347826,
            "28":0.08695652
         },
         "secondaryTypeCounts":{
            "Speed":23,
            "Critical Chance":2,
            "Health":8,
            "Potency":1,
            "Offense":10,
            "Tenacity":7,
            "Protection":7,
            "Defense":15
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.08695652,
            "Health":0.3478261,
            "Potency":0.04347826,
            "Offense":0.4347826,
            "Tenacity":0.3043478,
            "Protection":0.3043478,
            "Defense":0.65217394
         }
      },
      {
         "name":"VEERS",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.39285713,
               "5":0.39285713,
               "55":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.114285715,
               "16":0.22857143,
               "53":0.057142857,
               "55":1.0,
               "56":0.028571429
            },
            "5":{
               "55":1.0,
               "56":0.25
            },
            "6":{
               "48":0.375,
               "17":0.09375,
               "55":1.0,
               "56":0.09375
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":11,
               "5":11,
               "55":28
            },
            "3":{
               "49":50
            },
            "4":{
               "48":4,
               "16":8,
               "53":2,
               "55":35,
               "56":1
            },
            "5":{
               "55":40,
               "56":10
            },
            "6":{
               "48":12,
               "17":3,
               "55":32,
               "56":3
            }
         },
         "fourSetsMultiplier":0.61290324,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":97,
            "2":6,
            "4":1,
            "5":8,
            "6":12,
            "7":5,
            "8":0
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.5,
            "4":0.083333336,
            "5":0.082474224,
            "6":1.0,
            "7":0.05154639,
            "8":0.0
         },
         "twoSetMax":204.77777,
         "twoSetCounts":{
            "1":97,
            "5":8,
            "7":5,
            "8":0
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":97.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":64.666664
            },
            {
               "set":1,
               "occurrence":3,
               "count":43.11111
            },
            {
               "set":5,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":1,
               "count":5.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":7,
               "occurrence":2,
               "count":3.3333333
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.222222
            },
            {
               "set":8,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":2,
            "17":2,
            "49":5,
            "1":3,
            "18":7,
            "5":10,
            "53":1,
            "55":4,
            "56":2,
            "41":7,
            "42":4,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.2,
            "17":0.2,
            "49":0.5,
            "1":0.3,
            "18":0.7,
            "5":1.0,
            "53":0.1,
            "55":0.4,
            "56":0.2,
            "41":0.7,
            "42":0.4,
            "28":0.1
         },
         "secondaryTypeCounts":{
            "Speed":10,
            "Critical Chance":1,
            "Health":7,
            "Potency":2,
            "Offense":9,
            "Tenacity":7,
            "Protection":3,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.1,
            "Health":0.7,
            "Potency":0.2,
            "Offense":0.9,
            "Tenacity":0.7,
            "Protection":0.3,
            "Defense":0.9
         }
      },
      {
         "name":"COMMANDERLUKESKYWALKER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.029411765,
               "5":1.0,
               "55":0.05882353,
               "56":0.38235295
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.2647059,
               "48":0.05882353,
               "55":0.14705883,
               "56":1.0
            },
            "5":{
               "55":0.2195122,
               "56":1.0
            },
            "6":{
               "48":0.075,
               "17":0.025,
               "55":0.15,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "5":34,
               "55":2,
               "56":13
            },
            "3":{
               "49":50
            },
            "4":{
               "16":9,
               "48":2,
               "55":5,
               "56":34
            },
            "5":{
               "55":9,
               "56":41
            },
            "6":{
               "48":3,
               "17":1,
               "55":6,
               "56":40
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.08695652,
         "setCounts":{
            "1":42,
            "2":25,
            "3":0,
            "4":6,
            "5":7,
            "6":15,
            "7":4,
            "8":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0,
            "3":0.0,
            "4":0.24,
            "5":0.16666667,
            "6":0.6,
            "7":0.0952381,
            "8":0.023809524
         },
         "twoSetMax":88.666664,
         "twoSetCounts":{
            "1":42,
            "3":0,
            "5":7,
            "7":4,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":42.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":28.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":18.666666
            },
            {
               "set":5,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":7,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":5,
            "17":4,
            "49":4,
            "18":1,
            "5":18,
            "53":1,
            "55":4,
            "56":2,
            "41":4,
            "42":1,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.11111111,
            "1":0.2777778,
            "17":0.22222222,
            "49":0.22222222,
            "18":0.055555556,
            "5":1.0,
            "53":0.055555556,
            "55":0.22222222,
            "56":0.11111111,
            "41":0.22222222,
            "42":0.055555556,
            "28":0.33333334
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":1,
            "Health":9,
            "Potency":4,
            "Offense":6,
            "Tenacity":1,
            "Protection":8,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.055555556,
            "Health":0.5,
            "Potency":0.22222222,
            "Offense":0.33333334,
            "Tenacity":0.055555556,
            "Protection":0.44444445,
            "Defense":0.2777778
         }
      },
      {
         "name":"SITHPALPATINE",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "55":0.19512194,
               "56":0.024390243
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.11627907,
               "48":0.023255814,
               "53":0.023255814,
               "55":1.0
            },
            "5":{
               "55":1.0,
               "56":0.1627907
            },
            "6":{
               "48":0.093023255,
               "17":0.023255814,
               "55":1.0,
               "56":0.046511628
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":41,
               "55":8,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "16":5,
               "48":1,
               "53":1,
               "55":43
            },
            "5":{
               "55":43,
               "56":7
            },
            "6":{
               "48":4,
               "17":1,
               "55":43,
               "56":2
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.08695652,
         "setCounts":{
            "1":57,
            "2":6,
            "4":40,
            "5":0
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.15,
            "4":1.0,
            "5":0.0
         },
         "twoSetMax":120.333336,
         "twoSetCounts":{
            "1":57,
            "5":0
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":57.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":38.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":25.333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":6,
            "17":2,
            "49":3,
            "18":1,
            "5":17,
            "53":4,
            "55":5,
            "56":5,
            "41":4,
            "42":3,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.23529412,
            "1":0.3529412,
            "17":0.11764706,
            "49":0.1764706,
            "18":0.05882353,
            "5":1.0,
            "53":0.23529412,
            "55":0.29411766,
            "56":0.29411766,
            "41":0.23529412,
            "42":0.1764706,
            "28":0.23529412
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":4,
            "Health":11,
            "Potency":2,
            "Offense":8,
            "Tenacity":1,
            "Protection":9,
            "Defense":6
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.23529412,
            "Health":0.64705884,
            "Potency":0.11764706,
            "Offense":0.47058824,
            "Tenacity":0.05882353,
            "Protection":0.5294118,
            "Defense":0.3529412
         }
      },
      {
         "name":"GLREY",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.5,
               "5":0.7,
               "54":0.3,
               "55":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.19512194,
               "16":0.024390243,
               "55":1.0
            },
            "5":{
               "55":1.0,
               "56":0.020408163
            },
            "6":{
               "48":0.31578946,
               "55":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":10,
               "5":14,
               "54":6,
               "55":20
            },
            "3":{
               "49":50
            },
            "4":{
               "48":8,
               "16":1,
               "55":41
            },
            "5":{
               "55":49,
               "56":1
            },
            "6":{
               "48":12,
               "55":38
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.2820513,
         "setCounts":{
            "1":72,
            "2":39
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0
         },
         "twoSetMax":152.0,
         "twoSetCounts":{
            "1":72
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":72.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":48.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":32.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "17":1,
            "49":1,
            "1":6,
            "18":3,
            "5":22,
            "53":2,
            "55":7,
            "56":5,
            "41":11,
            "42":2,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.045454547,
            "17":0.045454547,
            "49":0.045454547,
            "1":0.27272728,
            "18":0.13636364,
            "5":1.0,
            "53":0.09090909,
            "55":0.3181818,
            "56":0.22727273,
            "41":0.5,
            "42":0.09090909,
            "28":0.18181819
         },
         "secondaryTypeCounts":{
            "Speed":22,
            "Critical Chance":2,
            "Health":13,
            "Potency":1,
            "Offense":12,
            "Tenacity":3,
            "Protection":9,
            "Defense":3
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.09090909,
            "Health":0.59090906,
            "Potency":0.045454547,
            "Offense":0.54545456,
            "Tenacity":0.13636364,
            "Protection":0.4090909,
            "Defense":0.13636364
         }
      },
      {
         "name":"EPIXPOE",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.60714287,
               "49":0.035714287,
               "53":0.10714286,
               "55":0.035714287
            },
            "5":{
               "55":1.0,
               "56":0.7241379
            },
            "6":{
               "48":1.0,
               "17":0.046511628,
               "18":0.069767445,
               "56":0.046511628
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "16":28,
               "48":17,
               "49":1,
               "53":3,
               "55":1
            },
            "5":{
               "55":29,
               "56":21
            },
            "6":{
               "48":43,
               "17":2,
               "18":3,
               "56":2
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":16,
            "2":4,
            "3":0,
            "4":46,
            "5":10,
            "6":0,
            "7":12,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.08695652,
            "3":0.0,
            "4":1.0,
            "5":0.625,
            "6":0.0,
            "7":0.75,
            "8":0.125
         },
         "twoSetMax":38.666668,
         "twoSetCounts":{
            "1":16,
            "3":0,
            "5":10,
            "7":12,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":16.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":10.666667
            },
            {
               "set":5,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":7.111111
            },
            {
               "set":5,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":7,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":3,
            "17":2,
            "49":5,
            "1":2,
            "18":3,
            "53":1,
            "5":18,
            "55":1,
            "56":2,
            "41":6,
            "42":5,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.16666667,
            "17":0.11111111,
            "49":0.2777778,
            "1":0.11111111,
            "18":0.16666667,
            "53":0.055555556,
            "5":1.0,
            "55":0.055555556,
            "56":0.11111111,
            "41":0.33333334,
            "42":0.2777778,
            "28":0.055555556
         },
         "secondaryTypeCounts":{
            "Speed":18,
            "Critical Chance":1,
            "Health":3,
            "Potency":2,
            "Offense":9,
            "Tenacity":3,
            "Protection":3,
            "Defense":10
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.055555556,
            "Health":0.16666667,
            "Potency":0.11111111,
            "Offense":0.5,
            "Tenacity":0.16666667,
            "Protection":0.16666667,
            "Defense":0.5555556
         }
      },
      {
         "name":"DARTHSIDIOUS",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.45833334,
               "49":0.125,
               "53":0.25,
               "55":0.125,
               "56":0.125
            },
            "5":{
               "55":1.0,
               "56":0.8518519
            },
            "6":{
               "48":0.023809524,
               "17":1.0,
               "49":0.071428575,
               "18":0.04761905,
               "56":0.04761905
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "16":24,
               "48":11,
               "49":3,
               "53":6,
               "55":3,
               "56":3
            },
            "5":{
               "55":27,
               "56":23
            },
            "6":{
               "48":1,
               "17":42,
               "49":3,
               "18":2,
               "56":2
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.06382979,
         "setCounts":{
            "1":10,
            "2":1,
            "4":44,
            "5":0,
            "6":2,
            "7":41,
            "8":1
         },
         "fourSetMultipliers":{
            "1":0.24390244,
            "2":0.022727273,
            "4":1.0,
            "5":0.0,
            "6":0.045454547,
            "7":1.0,
            "8":0.024390243
         },
         "twoSetMax":86.55556,
         "twoSetCounts":{
            "1":10,
            "5":0,
            "7":41,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":41.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":27.333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":18.222223
            },
            {
               "set":1,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":1,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "49":3,
            "17":3,
            "1":1,
            "18":3,
            "5":15,
            "53":1,
            "55":4,
            "56":1,
            "41":3,
            "42":4,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.33333334,
            "49":0.2,
            "17":0.2,
            "1":0.06666667,
            "18":0.2,
            "5":1.0,
            "53":0.06666667,
            "55":0.26666668,
            "56":0.06666667,
            "41":0.2,
            "42":0.26666668,
            "28":0.33333334
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":1,
            "Health":5,
            "Potency":3,
            "Offense":8,
            "Tenacity":3,
            "Protection":6,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.06666667,
            "Health":0.33333334,
            "Potency":0.2,
            "Offense":0.53333336,
            "Tenacity":0.2,
            "Protection":0.4,
            "Defense":0.46666667
         }
      },
      {
         "name":"DARTHSION",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0,
               "56":0.041666668
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.028571429,
               "16":0.114285715,
               "49":0.14285715,
               "53":0.057142857,
               "55":0.08571429,
               "56":1.0
            },
            "5":{
               "55":0.020408163,
               "56":1.0
            },
            "6":{
               "48":0.16666667,
               "17":0.13333334,
               "49":0.13333334,
               "18":0.13333334,
               "55":0.1,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":48,
               "56":2
            },
            "3":{
               "49":50
            },
            "4":{
               "48":1,
               "16":4,
               "49":5,
               "53":2,
               "55":3,
               "56":35
            },
            "5":{
               "55":1,
               "56":49
            },
            "6":{
               "48":5,
               "17":4,
               "49":4,
               "18":4,
               "55":3,
               "56":30
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.35135135,
         "setCounts":{
            "1":45,
            "2":1,
            "3":12,
            "4":36,
            "5":3,
            "7":8,
            "8":3
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.027777778,
            "3":0.26666668,
            "4":1.0,
            "5":0.06666667,
            "7":0.17777778,
            "8":0.06666667
         },
         "twoSetMax":95.0,
         "twoSetCounts":{
            "1":45,
            "3":12,
            "5":3,
            "7":8,
            "8":3
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":45.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":30.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":20.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":12.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":8.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":7,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":5,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":3,
               "count":1.3333334
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":1,
            "17":9,
            "49":7,
            "1":3,
            "18":6,
            "5":17,
            "53":2,
            "55":2,
            "56":2,
            "41":3,
            "42":1,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.05882353,
            "17":0.5294118,
            "49":0.4117647,
            "1":0.1764706,
            "18":0.3529412,
            "5":1.0,
            "53":0.11764706,
            "55":0.11764706,
            "56":0.11764706,
            "41":0.1764706,
            "42":0.05882353,
            "28":0.23529412
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":2,
            "Health":5,
            "Potency":9,
            "Offense":4,
            "Tenacity":6,
            "Protection":6,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.11764706,
            "Health":0.29411766,
            "Potency":0.5294118,
            "Offense":0.23529412,
            "Tenacity":0.3529412,
            "Protection":0.3529412,
            "Defense":0.47058824
         }
      },
      {
         "name":"MAGMATROOPER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.020408163,
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":1.0,
               "16":0.93333334,
               "49":0.06666667,
               "53":0.4,
               "55":0.6,
               "56":0.33333334
            },
            "5":{
               "55":0.7241379,
               "56":1.0
            },
            "6":{
               "17":1.0,
               "18":0.5625
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "5":49
            },
            "3":{
               "49":50
            },
            "4":{
               "48":15,
               "16":14,
               "49":1,
               "53":6,
               "55":9,
               "56":5
            },
            "5":{
               "55":21,
               "56":29
            },
            "6":{
               "17":32,
               "18":18
            }
         },
         "fourSetsMultiplier":0.0,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":2,
            "5":1,
            "7":47,
            "8":99
         },
         "fourSetMultipliers":{
            "1":0.02020202,
            "5":0.01010101,
            "7":0.47474748,
            "8":1.0
         },
         "twoSetMax":212.0,
         "twoSetCounts":{
            "1":2,
            "5":1,
            "7":47,
            "8":99
         },
         "twoSetOccurrenceCounts":[
            {
               "set":8,
               "occurrence":1,
               "count":99.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":66.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":47.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":44.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":31.333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":20.88889
            },
            {
               "set":1,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":5,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"tenacity",
         "commonSet2Name":"tenacity",
         "commonSet3Name":"potency",
         "secondaryCounts":{
            "48":5,
            "1":1,
            "49":7,
            "17":3,
            "18":5,
            "5":15,
            "53":2,
            "55":2,
            "56":3,
            "41":2,
            "42":2,
            "28":3
         },
         "secondaryMultipliers":{
            "48":0.33333334,
            "1":0.06666667,
            "49":0.46666667,
            "17":0.2,
            "18":0.33333334,
            "5":1.0,
            "53":0.13333334,
            "55":0.13333334,
            "56":0.2,
            "41":0.13333334,
            "42":0.13333334,
            "28":0.2
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":2,
            "Health":3,
            "Potency":3,
            "Offense":7,
            "Tenacity":5,
            "Protection":6,
            "Defense":9
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.13333334,
            "Health":0.2,
            "Potency":0.2,
            "Offense":0.46666667,
            "Tenacity":0.33333334,
            "Protection":0.4,
            "Defense":0.6
         }
      },
      {
         "name":"KIADIMUNDI",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":1.0,
               "5":0.5151515
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.88461536,
               "56":0.03846154
            },
            "5":{
               "55":1.0,
               "56":0.78571427
            },
            "6":{
               "48":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":33,
               "5":17
            },
            "3":{
               "49":50
            },
            "4":{
               "16":26,
               "48":23,
               "56":1
            },
            "5":{
               "55":28,
               "56":22
            },
            "6":{
               "48":50
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":23,
            "2":41,
            "3":1,
            "5":25,
            "6":9,
            "7":0
         },
         "fourSetMultipliers":{
            "1":0.92,
            "2":1.0,
            "3":0.04,
            "5":1.0,
            "6":0.2195122,
            "7":0.0
         },
         "twoSetMax":64.666664,
         "twoSetCounts":{
            "1":23,
            "3":1,
            "5":25,
            "7":0
         },
         "twoSetOccurrenceCounts":[
            {
               "set":5,
               "occurrence":1,
               "count":25.0
            },
            {
               "set":1,
               "occurrence":1,
               "count":23.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":16.666666
            },
            {
               "set":1,
               "occurrence":2,
               "count":15.333333
            },
            {
               "set":5,
               "occurrence":3,
               "count":11.111111
            },
            {
               "set":1,
               "occurrence":3,
               "count":10.222222
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":7,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"critChance",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":5,
            "1":1,
            "17":3,
            "49":3,
            "18":1,
            "5":15,
            "53":2,
            "55":4,
            "56":1,
            "41":7,
            "42":4,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.33333334,
            "1":0.06666667,
            "17":0.2,
            "49":0.2,
            "18":0.06666667,
            "5":1.0,
            "53":0.13333334,
            "55":0.26666668,
            "56":0.06666667,
            "41":0.46666667,
            "42":0.26666668,
            "28":0.13333334
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":2,
            "Health":5,
            "Potency":3,
            "Offense":12,
            "Tenacity":1,
            "Protection":3,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.13333334,
            "Health":0.33333334,
            "Potency":0.2,
            "Offense":0.8,
            "Tenacity":0.06666667,
            "Protection":0.2,
            "Defense":0.46666667
         }
      },
      {
         "name":"BOKATAN",
         "count":46,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.046511628,
               "5":1.0,
               "54":0.023255814
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":1.0,
               "48":0.26086956,
               "49":0.13043478,
               "53":0.08695652,
               "55":0.3478261,
               "56":0.17391305
            },
            "5":{
               "55":1.0,
               "56":0.64285713
            },
            "6":{
               "48":1.0,
               "17":0.5882353,
               "18":0.1764706,
               "55":0.3529412,
               "56":0.5882353
            }
         },
         "primaryCounts":{
            "1":{
               "48":46
            },
            "2":{
               "48":2,
               "5":43,
               "54":1
            },
            "3":{
               "49":46
            },
            "4":{
               "16":23,
               "48":6,
               "49":3,
               "53":2,
               "55":8,
               "56":4
            },
            "5":{
               "55":28,
               "56":18
            },
            "6":{
               "48":17,
               "17":10,
               "18":3,
               "55":6,
               "56":10
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0952381,
         "setCounts":{
            "1":31,
            "2":17,
            "3":1,
            "4":12,
            "5":10,
            "6":13,
            "7":6,
            "8":0
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0,
            "3":0.032258064,
            "4":0.7058824,
            "5":0.32258064,
            "6":0.7647059,
            "7":0.19354838,
            "8":0.0
         },
         "twoSetMax":65.44444,
         "twoSetCounts":{
            "1":31,
            "3":1,
            "5":10,
            "7":6,
            "8":0
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":31.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":20.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":13.777778
            },
            {
               "set":5,
               "occurrence":1,
               "count":10.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":6.6666665
            },
            {
               "set":7,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":4.444444
            },
            {
               "set":7,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":6,
            "1":4,
            "17":2,
            "49":3,
            "18":4,
            "5":17,
            "53":1,
            "55":4,
            "56":1,
            "41":6,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.3529412,
            "1":0.23529412,
            "17":0.11764706,
            "49":0.1764706,
            "18":0.23529412,
            "5":1.0,
            "53":0.05882353,
            "55":0.23529412,
            "56":0.05882353,
            "41":0.3529412,
            "42":0.11764706,
            "28":0.11764706
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":1,
            "Health":8,
            "Potency":2,
            "Offense":12,
            "Tenacity":4,
            "Protection":3,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.05882353,
            "Health":0.47058824,
            "Potency":0.11764706,
            "Offense":0.7058824,
            "Tenacity":0.23529412,
            "Protection":0.1764706,
            "Defense":0.29411766
         }
      },
      {
         "name":"GENERALSKYWALKER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.7037037,
               "5":0.14814815,
               "56":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.60714287,
               "16":0.17857143,
               "56":1.0
            },
            "5":{
               "56":1.0
            },
            "6":{
               "48":0.35135135,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":19,
               "5":4,
               "56":27
            },
            "3":{
               "49":50
            },
            "4":{
               "48":17,
               "16":5,
               "56":28
            },
            "5":{
               "56":50
            },
            "6":{
               "48":13,
               "56":37
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":19,
            "2":50,
            "3":4,
            "5":6,
            "7":19,
            "8":1
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0,
            "3":0.21052632,
            "5":0.31578946,
            "7":1.0,
            "8":0.05263158
         },
         "twoSetMax":50.666668,
         "twoSetCounts":{
            "1":19,
            "3":4,
            "5":6,
            "7":19,
            "8":1
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":7,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":7,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":5,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":8,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":4,
            "1":4,
            "49":5,
            "17":1,
            "18":4,
            "5":10,
            "53":1,
            "55":1,
            "56":4,
            "41":10,
            "42":3,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.4,
            "1":0.4,
            "49":0.5,
            "17":0.1,
            "18":0.4,
            "5":1.0,
            "53":0.1,
            "55":0.1,
            "56":0.4,
            "41":1.0,
            "42":0.3,
            "28":0.2
         },
         "secondaryTypeCounts":{
            "Speed":10,
            "Critical Chance":1,
            "Health":5,
            "Potency":1,
            "Offense":14,
            "Tenacity":4,
            "Protection":6,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":0.71428573,
            "Critical Chance":0.071428575,
            "Health":0.35714287,
            "Potency":0.071428575,
            "Offense":1.0,
            "Tenacity":0.2857143,
            "Protection":0.42857143,
            "Defense":0.5714286
         }
      },
      {
         "name":"B2SUPERBATTLEDROID",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.0625,
               "52":0.03125,
               "5":0.1875,
               "54":1.0,
               "56":0.28125
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.024390243,
               "48":0.024390243,
               "55":0.17073171,
               "56":1.0
            },
            "5":{
               "55":0.2820513,
               "56":1.0
            },
            "6":{
               "17":1.0,
               "18":0.041666668
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":2,
               "52":1,
               "5":6,
               "54":32,
               "56":9
            },
            "3":{
               "49":50
            },
            "4":{
               "16":1,
               "48":1,
               "55":7,
               "56":41
            },
            "5":{
               "55":11,
               "56":39
            },
            "6":{
               "17":48,
               "18":2
            }
         },
         "fourSetsMultiplier":0.0,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":2,
            "3":1,
            "7":136,
            "8":11
         },
         "fourSetMultipliers":{
            "1":0.014705882,
            "3":0.007352941,
            "7":1.0,
            "8":0.080882356
         },
         "twoSetMax":287.11108,
         "twoSetCounts":{
            "1":2,
            "3":1,
            "7":136,
            "8":11
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":136.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":90.666664
            },
            {
               "set":7,
               "occurrence":3,
               "count":60.444443
            },
            {
               "set":8,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":8,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":1,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"potency",
         "commonSet2Name":"potency",
         "commonSet3Name":"potency",
         "secondaryCounts":{
            "48":1,
            "1":3,
            "17":11,
            "49":3,
            "18":4,
            "5":10,
            "53":2,
            "55":4,
            "56":1,
            "41":4,
            "42":2,
            "28":6
         },
         "secondaryMultipliers":{
            "48":0.09090909,
            "1":0.27272728,
            "17":1.0,
            "49":0.27272728,
            "18":0.36363637,
            "5":0.90909094,
            "53":0.18181819,
            "55":0.36363637,
            "56":0.09090909,
            "41":0.36363637,
            "42":0.18181819,
            "28":0.54545456
         },
         "secondaryTypeCounts":{
            "Speed":10,
            "Critical Chance":2,
            "Health":7,
            "Potency":11,
            "Offense":5,
            "Tenacity":4,
            "Protection":7,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":0.90909094,
            "Critical Chance":0.18181819,
            "Health":0.6363636,
            "Potency":1.0,
            "Offense":0.45454547,
            "Tenacity":0.36363637,
            "Protection":0.6363636,
            "Defense":0.45454547
         }
      },
      {
         "name":"ARMORER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.22222222,
               "16":0.16666667,
               "49":0.5,
               "53":0.33333334,
               "55":0.5555556,
               "56":1.0
            },
            "5":{
               "55":0.5151515,
               "56":1.0
            },
            "6":{
               "48":0.33333334,
               "17":0.4,
               "49":0.26666668,
               "18":0.93333334,
               "55":0.4,
               "56":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "48":4,
               "16":3,
               "49":9,
               "53":6,
               "55":10,
               "56":18
            },
            "5":{
               "55":17,
               "56":33
            },
            "6":{
               "48":5,
               "17":6,
               "49":4,
               "18":14,
               "55":6,
               "56":15
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.041666668,
         "setCounts":{
            "1":31,
            "2":2,
            "3":4,
            "4":46,
            "5":0,
            "6":0,
            "7":0,
            "8":9
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.04347826,
            "3":0.12903225,
            "4":1.0,
            "5":0.0,
            "6":0.0,
            "7":0.0,
            "8":0.29032257
         },
         "twoSetMax":65.44444,
         "twoSetCounts":{
            "1":31,
            "3":4,
            "5":0,
            "7":0,
            "8":9
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":31.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":20.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":13.777778
            },
            {
               "set":8,
               "occurrence":1,
               "count":9.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":6.0
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":4,
            "1":8,
            "17":4,
            "18":4,
            "53":3,
            "5":17,
            "55":3,
            "56":2,
            "41":1,
            "42":1,
            "28":7
         },
         "secondaryMultipliers":{
            "48":0.11764706,
            "49":0.23529412,
            "1":0.47058824,
            "17":0.23529412,
            "18":0.23529412,
            "53":0.1764706,
            "5":1.0,
            "55":0.1764706,
            "56":0.11764706,
            "41":0.05882353,
            "42":0.05882353,
            "28":0.4117647
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":3,
            "Health":11,
            "Potency":4,
            "Offense":3,
            "Tenacity":4,
            "Protection":9,
            "Defense":5
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.1764706,
            "Health":0.64705884,
            "Potency":0.23529412,
            "Offense":0.1764706,
            "Tenacity":0.23529412,
            "Protection":0.5294118,
            "Defense":0.29411766
         }
      },
      {
         "name":"FOSITHTROOPER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.03846154,
               "5":0.1923077,
               "55":1.0,
               "56":0.6923077
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.1923077,
               "48":0.03846154,
               "53":0.03846154,
               "55":1.0,
               "56":0.65384614
            },
            "5":{
               "55":1.0,
               "56":0.6666667
            },
            "6":{
               "48":0.20833333,
               "49":0.041666668,
               "55":1.0,
               "56":0.8333333
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":1,
               "5":5,
               "55":26,
               "56":18
            },
            "3":{
               "49":50
            },
            "4":{
               "16":5,
               "48":1,
               "53":1,
               "55":26,
               "56":17
            },
            "5":{
               "55":30,
               "56":20
            },
            "6":{
               "48":5,
               "49":1,
               "55":24,
               "56":20
            }
         },
         "fourSetsMultiplier":0.13636364,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":132,
            "5":6,
            "6":6
         },
         "fourSetMultipliers":{
            "1":1.0,
            "5":0.045454547,
            "6":1.0
         },
         "twoSetMax":278.66666,
         "twoSetCounts":{
            "1":132,
            "5":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":132.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":88.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":58.666668
            },
            {
               "set":5,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":2.6666667
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":2,
            "17":2,
            "49":1,
            "1":11,
            "18":1,
            "5":10,
            "53":2,
            "55":6,
            "56":6,
            "41":5,
            "42":1,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.18181819,
            "17":0.18181819,
            "49":0.09090909,
            "1":1.0,
            "18":0.09090909,
            "5":0.90909094,
            "53":0.18181819,
            "55":0.54545456,
            "56":0.54545456,
            "41":0.45454547,
            "42":0.09090909,
            "28":0.18181819
         },
         "secondaryTypeCounts":{
            "Speed":10,
            "Critical Chance":2,
            "Health":17,
            "Potency":2,
            "Offense":7,
            "Tenacity":1,
            "Protection":8,
            "Defense":2
         },
         "secondaryTypeMultipliers":{
            "Speed":0.5882353,
            "Critical Chance":0.11764706,
            "Health":1.0,
            "Potency":0.11764706,
            "Offense":0.4117647,
            "Tenacity":0.05882353,
            "Protection":0.47058824,
            "Defense":0.11764706
         }
      },
      {
         "name":"DARKTROOPER",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":1.0,
               "5":0.1627907
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":1.0,
               "16":0.020833334,
               "55":0.020833334
            },
            "5":{
               "55":1.0,
               "56":1.0
            },
            "6":{
               "48":1.0
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":43,
               "5":7
            },
            "3":{
               "49":50
            },
            "4":{
               "48":48,
               "16":1,
               "55":1
            },
            "5":{
               "55":25,
               "56":25
            },
            "6":{
               "48":50
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":37,
            "2":48,
            "3":1,
            "4":2,
            "5":4,
            "8":6
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0,
            "3":0.027027028,
            "4":0.041666668,
            "5":0.10810811,
            "8":0.16216215
         },
         "twoSetMax":78.11111,
         "twoSetCounts":{
            "1":37,
            "3":1,
            "5":4,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":37.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":24.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":16.444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":5,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":11,
            "17":1,
            "1":2,
            "49":3,
            "18":3,
            "5":7,
            "53":3,
            "55":1,
            "56":6,
            "41":16,
            "42":5,
            "28":5
         },
         "secondaryMultipliers":{
            "48":0.6875,
            "17":0.0625,
            "1":0.125,
            "49":0.1875,
            "18":0.1875,
            "5":0.4375,
            "53":0.1875,
            "55":0.0625,
            "56":0.375,
            "41":1.0,
            "42":0.3125,
            "28":0.3125
         },
         "secondaryTypeCounts":{
            "Speed":7,
            "Critical Chance":3,
            "Health":3,
            "Potency":1,
            "Offense":27,
            "Tenacity":3,
            "Protection":11,
            "Defense":8
         },
         "secondaryTypeMultipliers":{
            "Speed":0.25925925,
            "Critical Chance":0.11111111,
            "Health":0.11111111,
            "Potency":0.037037037,
            "Offense":1.0,
            "Tenacity":0.11111111,
            "Protection":0.4074074,
            "Defense":0.2962963
         }
      },
      {
         "name":"THEMANDALORIANBESKARARMOR",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.25641027,
               "5":1.0,
               "56":0.025641026
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":1.0,
               "16":0.18421052,
               "53":0.078947365,
               "56":0.05263158
            },
            "5":{
               "55":0.5625,
               "56":1.0
            },
            "6":{
               "48":1.0,
               "17":0.023255814,
               "55":0.023255814,
               "56":0.11627907
            }
         },
         "primaryCounts":{
            "1":{
               "48":49
            },
            "2":{
               "48":10,
               "5":39,
               "56":1
            },
            "3":{
               "49":50
            },
            "4":{
               "48":38,
               "16":7,
               "53":3,
               "56":2
            },
            "5":{
               "55":18,
               "56":32
            },
            "6":{
               "48":43,
               "17":1,
               "55":1,
               "56":5
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.08695652,
         "setCounts":{
            "1":28,
            "2":36,
            "3":3,
            "4":4,
            "5":8,
            "6":6,
            "7":2,
            "8":6
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":1.0,
            "3":0.10714286,
            "4":0.11111111,
            "5":0.2857143,
            "6":0.16666667,
            "7":0.071428575,
            "8":0.21428572
         },
         "twoSetMax":59.111107,
         "twoSetCounts":{
            "1":28,
            "3":3,
            "5":8,
            "7":2,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":28.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":18.666666
            },
            {
               "set":1,
               "occurrence":3,
               "count":12.444444
            },
            {
               "set":5,
               "occurrence":1,
               "count":8.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":5.3333335
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":3.5555556
            },
            {
               "set":3,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"offense",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":6,
            "1":1,
            "17":2,
            "49":7,
            "18":3,
            "5":6,
            "53":3,
            "55":6,
            "56":4,
            "41":1,
            "42":9,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.6666667,
            "1":0.11111111,
            "17":0.22222222,
            "49":0.7777778,
            "18":0.33333334,
            "5":0.6666667,
            "53":0.33333334,
            "55":0.6666667,
            "56":0.44444445,
            "41":0.11111111,
            "42":1.0,
            "28":0.22222222
         },
         "secondaryTypeCounts":{
            "Speed":6,
            "Critical Chance":3,
            "Health":7,
            "Potency":2,
            "Offense":7,
            "Tenacity":3,
            "Protection":6,
            "Defense":16
         },
         "secondaryTypeMultipliers":{
            "Speed":0.375,
            "Critical Chance":0.1875,
            "Health":0.4375,
            "Potency":0.125,
            "Offense":0.4375,
            "Tenacity":0.1875,
            "Protection":0.375,
            "Defense":1.0
         }
      },
      {
         "name":"C3POLEGENDARY",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "48":0.1,
               "49":0.05,
               "5":1.0,
               "54":0.4,
               "55":0.5,
               "56":0.45
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":0.05,
               "16":0.1,
               "49":0.3,
               "53":0.2,
               "55":0.85,
               "56":1.0
            },
            "5":{
               "55":1.0,
               "56":1.0
            },
            "6":{
               "48":0.027777778,
               "17":1.0,
               "18":0.055555556,
               "55":0.11111111,
               "56":0.19444445
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "48":2,
               "49":1,
               "5":20,
               "54":8,
               "55":10,
               "56":9
            },
            "3":{
               "49":50
            },
            "4":{
               "48":1,
               "16":2,
               "49":6,
               "53":4,
               "55":17,
               "56":20
            },
            "5":{
               "55":25,
               "56":25
            },
            "6":{
               "48":1,
               "17":36,
               "18":2,
               "55":4,
               "56":7
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.61290324,
         "setCounts":{
            "1":19,
            "3":2,
            "4":31,
            "5":0,
            "7":60,
            "8":6
         },
         "fourSetMultipliers":{
            "1":0.31666666,
            "3":0.033333335,
            "4":1.0,
            "5":0.0,
            "7":1.0,
            "8":0.1
         },
         "twoSetMax":126.666664,
         "twoSetCounts":{
            "1":19,
            "3":2,
            "5":0,
            "7":60,
            "8":6
         },
         "twoSetOccurrenceCounts":[
            {
               "set":7,
               "occurrence":1,
               "count":60.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":40.0
            },
            {
               "set":7,
               "occurrence":3,
               "count":26.666666
            },
            {
               "set":1,
               "occurrence":1,
               "count":19.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":12.666667
            },
            {
               "set":1,
               "occurrence":3,
               "count":8.444445
            },
            {
               "set":8,
               "occurrence":1,
               "count":6.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":2.6666667
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"potency",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":1,
            "49":1,
            "17":11,
            "18":4,
            "53":2,
            "5":15,
            "55":1,
            "56":4,
            "41":3,
            "42":3,
            "28":4
         },
         "secondaryMultipliers":{
            "48":0.13333334,
            "1":0.06666667,
            "49":0.06666667,
            "17":0.73333335,
            "18":0.26666668,
            "53":0.13333334,
            "5":1.0,
            "55":0.06666667,
            "56":0.26666668,
            "41":0.2,
            "42":0.2,
            "28":0.26666668
         },
         "secondaryTypeCounts":{
            "Speed":15,
            "Critical Chance":2,
            "Health":2,
            "Potency":11,
            "Offense":5,
            "Tenacity":4,
            "Protection":8,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.13333334,
            "Health":0.13333334,
            "Potency":0.73333335,
            "Offense":0.33333334,
            "Tenacity":0.26666668,
            "Protection":0.53333336,
            "Defense":0.26666668
         }
      },
      {
         "name":"MOFFGIDEONS1",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "16":0.8181818,
               "48":1.0,
               "49":0.54545456,
               "53":1.0,
               "55":0.72727275,
               "56":0.45454547
            },
            "5":{
               "55":1.0,
               "56":0.61290324
            },
            "6":{
               "48":0.5,
               "49":0.5,
               "17":0.5714286,
               "18":1.0,
               "55":0.5,
               "56":0.5
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "16":9,
               "48":11,
               "49":6,
               "53":11,
               "55":8,
               "56":5
            },
            "5":{
               "55":31,
               "56":19
            },
            "6":{
               "48":7,
               "49":7,
               "17":8,
               "18":14,
               "55":7,
               "56":7
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.0,
         "setCounts":{
            "1":21,
            "2":0,
            "3":1,
            "4":50,
            "5":4,
            "6":0,
            "7":3,
            "8":7
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.0,
            "3":0.04761905,
            "4":1.0,
            "5":0.1904762,
            "6":0.0,
            "7":0.14285715,
            "8":0.33333334
         },
         "twoSetMax":44.333332,
         "twoSetCounts":{
            "1":21,
            "3":1,
            "5":4,
            "7":3,
            "8":7
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":21.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":14.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":9.333333
            },
            {
               "set":8,
               "occurrence":1,
               "count":7.0
            },
            {
               "set":8,
               "occurrence":2,
               "count":4.6666665
            },
            {
               "set":5,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":8,
               "occurrence":3,
               "count":3.111111
            },
            {
               "set":7,
               "occurrence":1,
               "count":3.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":2,
               "count":2.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":7,
               "occurrence":3,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":1,
               "count":1.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":0.6666667
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.44444445
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "1":10,
            "17":2,
            "49":1,
            "18":6,
            "5":22,
            "53":2,
            "55":5,
            "56":1,
            "41":7,
            "42":3,
            "28":1
         },
         "secondaryMultipliers":{
            "48":0.09090909,
            "1":0.45454547,
            "17":0.09090909,
            "49":0.045454547,
            "18":0.27272728,
            "5":1.0,
            "53":0.09090909,
            "55":0.22727273,
            "56":0.045454547,
            "41":0.3181818,
            "42":0.13636364,
            "28":0.045454547
         },
         "secondaryTypeCounts":{
            "Speed":22,
            "Critical Chance":2,
            "Health":15,
            "Potency":2,
            "Offense":9,
            "Tenacity":6,
            "Protection":2,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.09090909,
            "Health":0.6818182,
            "Potency":0.09090909,
            "Offense":0.4090909,
            "Tenacity":0.27272728,
            "Protection":0.09090909,
            "Defense":0.18181819
         }
      },
      {
         "name":"KUIIL",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "5":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "48":1.0,
               "16":0.1875,
               "49":0.1875,
               "53":0.1875,
               "55":0.75,
               "56":0.8125
            },
            "5":{
               "55":1.0,
               "56":0.9230769
            },
            "6":{
               "48":0.7692308,
               "17":0.46153846,
               "49":0.23076923,
               "18":0.46153846,
               "55":1.0,
               "56":0.9230769
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "5":50
            },
            "3":{
               "49":50
            },
            "4":{
               "48":16,
               "16":3,
               "49":3,
               "53":3,
               "55":12,
               "56":13
            },
            "5":{
               "55":26,
               "56":24
            },
            "6":{
               "48":10,
               "17":6,
               "49":3,
               "18":6,
               "55":13,
               "56":12
            }
         },
         "fourSetsMultiplier":1.0,
         "twoSetsMultiplier":0.020408163,
         "setCounts":{
            "1":30,
            "2":1,
            "3":2,
            "4":48,
            "5":0,
            "6":0,
            "7":11,
            "8":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "2":0.020833334,
            "3":0.06666667,
            "4":1.0,
            "5":0.0,
            "6":0.0,
            "7":0.36666667,
            "8":0.06666667
         },
         "twoSetMax":63.333332,
         "twoSetCounts":{
            "1":30,
            "3":2,
            "5":0,
            "7":11,
            "8":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":30.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":20.0
            },
            {
               "set":1,
               "occurrence":3,
               "count":13.333333
            },
            {
               "set":7,
               "occurrence":1,
               "count":11.0
            },
            {
               "set":7,
               "occurrence":2,
               "count":7.3333335
            },
            {
               "set":7,
               "occurrence":3,
               "count":4.888889
            },
            {
               "set":3,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":8,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":8,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":3,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":8,
               "occurrence":3,
               "count":0.8888889
            },
            {
               "set":5,
               "occurrence":1,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":2,
               "count":0.0
            },
            {
               "set":5,
               "occurrence":3,
               "count":0.0
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"speed",
         "commonSet2Name":"health",
         "commonSet3Name":null,
         "secondaryCounts":{
            "48":2,
            "49":1,
            "1":1,
            "17":3,
            "18":2,
            "5":17,
            "53":7,
            "55":4,
            "56":1,
            "41":6,
            "42":3,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.11764706,
            "49":0.05882353,
            "1":0.05882353,
            "17":0.1764706,
            "18":0.11764706,
            "5":1.0,
            "53":0.4117647,
            "55":0.23529412,
            "56":0.05882353,
            "41":0.3529412,
            "42":0.1764706,
            "28":0.11764706
         },
         "secondaryTypeCounts":{
            "Speed":17,
            "Critical Chance":7,
            "Health":5,
            "Potency":3,
            "Offense":8,
            "Tenacity":2,
            "Protection":3,
            "Defense":4
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.4117647,
            "Health":0.29411766,
            "Potency":0.1764706,
            "Offense":0.47058824,
            "Tenacity":0.11764706,
            "Protection":0.1764706,
            "Defense":0.23529412
         }
      },
      {
         "name":"IG11",
         "count":50,
         "primaryMultipliers":{
            "1":{
               "48":1.0
            },
            "2":{
               "49":0.023255814,
               "5":0.11627907,
               "54":0.023255814,
               "55":1.0
            },
            "3":{
               "49":1.0
            },
            "4":{
               "55":1.0,
               "56":0.020408163
            },
            "5":{
               "55":1.0,
               "56":0.020408163
            },
            "6":{
               "17":0.021276595,
               "18":0.021276595,
               "55":1.0,
               "56":0.021276595
            }
         },
         "primaryCounts":{
            "1":{
               "48":50
            },
            "2":{
               "49":1,
               "5":5,
               "54":1,
               "55":43
            },
            "3":{
               "49":50
            },
            "4":{
               "55":49,
               "56":1
            },
            "5":{
               "55":49,
               "56":1
            },
            "6":{
               "17":1,
               "18":1,
               "55":47,
               "56":1
            }
         },
         "fourSetsMultiplier":0.0,
         "twoSetsMultiplier":1.0,
         "setCounts":{
            "1":143,
            "3":4,
            "7":2
         },
         "fourSetMultipliers":{
            "1":1.0,
            "3":0.027972028,
            "7":0.013986014
         },
         "twoSetMax":301.88892,
         "twoSetCounts":{
            "1":143,
            "3":4,
            "7":2
         },
         "twoSetOccurrenceCounts":[
            {
               "set":1,
               "occurrence":1,
               "count":143.0
            },
            {
               "set":1,
               "occurrence":2,
               "count":95.333336
            },
            {
               "set":1,
               "occurrence":3,
               "count":63.555557
            },
            {
               "set":3,
               "occurrence":1,
               "count":4.0
            },
            {
               "set":3,
               "occurrence":2,
               "count":2.6666667
            },
            {
               "set":7,
               "occurrence":1,
               "count":2.0
            },
            {
               "set":3,
               "occurrence":3,
               "count":1.7777778
            },
            {
               "set":7,
               "occurrence":2,
               "count":1.3333334
            },
            {
               "set":7,
               "occurrence":3,
               "count":0.8888889
            }
         ],
         "modsBySetCount":{
            
         },
         "commonSet1Name":"health",
         "commonSet2Name":"health",
         "commonSet3Name":"health",
         "secondaryCounts":{
            "48":7,
            "1":6,
            "17":6,
            "49":5,
            "18":1,
            "5":16,
            "53":2,
            "55":1,
            "56":2,
            "41":3,
            "42":2,
            "28":2
         },
         "secondaryMultipliers":{
            "48":0.4375,
            "1":0.375,
            "17":0.375,
            "49":0.3125,
            "18":0.0625,
            "5":1.0,
            "53":0.125,
            "55":0.0625,
            "56":0.125,
            "41":0.1875,
            "42":0.125,
            "28":0.125
         },
         "secondaryTypeCounts":{
            "Speed":16,
            "Critical Chance":2,
            "Health":7,
            "Potency":6,
            "Offense":10,
            "Tenacity":1,
            "Protection":4,
            "Defense":7
         },
         "secondaryTypeMultipliers":{
            "Speed":1.0,
            "Critical Chance":0.125,
            "Health":0.4375,
            "Potency":0.375,
            "Offense":0.625,
            "Tenacity":0.0625,
            "Protection":0.25,
            "Defense":0.4375
         }
      }
   ]
}
`;