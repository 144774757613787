

import React from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { Tooltip } from 'antd';
import { Modal } from 'antd';
import styled from 'styled-components';
import { Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TerritoryBattlePlayerCalc } from "../tb-player-calcs";

const ToolbarButton = styled(Button)` 
    margin: 5px;
`;

interface IProps
{
    possibleColumns: ColumnsType<TerritoryBattlePlayerCalc>;
    selectedDeploymentColumns: Map<string, boolean>;
    onConfigureColumns: (selectedDeploymentColumns: Map<string, boolean>) => void;
}

@observer
class TbPlayersConfigColumns extends React.Component<IProps>
{

    @observable showConfigureColumns: boolean = false;
    @observable selectedDeploymentColumns: Map<string, boolean> = new Map();

    @action
    componentDidMount()
    {
        this.selectedDeploymentColumns = new Map(this.props.selectedDeploymentColumns);
    }

    @action
    setDisplayColumn(columnName: string, value: boolean)
    {
        this.selectedDeploymentColumns.set(columnName, value);
    }

    @action
    clickShowConfigureColumns()
    {
        this.showConfigureColumns = true;
    }

    @action
    clickOk()
    {
        this.showConfigureColumns = false;
        this.props.onConfigureColumns(this.selectedDeploymentColumns);
    }

    render()
    {
        let possibleColumnList = this.props.possibleColumns.map(pc =>
        {

            return <div key={pc.key!.toString()}>
                <Checkbox checked={this.selectedDeploymentColumns.get(pc.key!.toString()) === true}
                    onChange={action((e) => this.selectedDeploymentColumns.set(pc.key!.toString(), e.target.checked))}>{pc.title}</Checkbox>
            </div>
        });
        return <React.Fragment>
            <Modal
                title="Configure Columns"
                visible={this.showConfigureColumns}
                onCancel={action((e) => this.showConfigureColumns = false)}
                onOk={() => this.clickOk()}>

                {possibleColumnList}
            </Modal >

            <Tooltip placement="bottomLeft" title="Configure Columns">
                <ToolbarButton onClick={() => this.clickShowConfigureColumns()}><SettingOutlined /></ToolbarButton>
            </Tooltip>

        </React.Fragment>;
    }
}

export default TbPlayersConfigColumns;
