import ModData, { ModStat } from "../model/ModData";
import ModSingleFilterData, { ExtraSecondaryStats, FilterOperation } from "../model/ModSingleFilterData";
import StatIds, { statIdIsFlat } from "../model/StatIds";
import ModFilterGroup from "../model/ModFilterGroup";
import PlayerData from "../model/PlayerData";

function filterSecondary(mod: ModData, statId: StatIds | ExtraSecondaryStats, operation: FilterOperation, value: number): boolean
{
    if (statId === "Reroll Count" || statId === "Level")
    {
        let compareVal = statId === "Reroll Count" ? mod.rerolledCount : mod.level;
        switch (operation) {
            case "Any":
                return true;
            case "Equal":
                return compareVal === value;
            case "Not Equal":
                return compareVal !== value;
            case "Greater Than":
                return compareVal > value;
            case "Less Than":
                return compareVal < value;
            case "Rolls Greater Than":
                return compareVal > value;
            case "Rolls Less Than":
                return compareVal < value;
            default:
                return false;
        }
    }
    let stat: ModStat | null = null;
    if (statId === "Any Non Speed" || statId === "Flat Non Speed" || statId === "Non Flat")
    {
        let matching: ModStat[];
        if (statId === "Any Non Speed")
            matching = mod.secondaries.filter(s => s.statId !== StatIds.Speed);
        else if (statId === "Flat Non Speed")
            matching = mod.secondaries.filter(s => s.statId !== StatIds.Speed && statIdIsFlat(s.statId));
        else
            matching = mod.secondaries.filter(s => !statIdIsFlat(s.statId));
        switch (operation) {
            case "Any":
                return true;
            case "Equal":
                return matching.find(s => Math.abs(value - s.rawValue) < 0.01) !== undefined;
            case "Not Equal":
                return matching.find(s => Math.abs(value - s.rawValue) < 0.01) === undefined;
            case "Greater Than":
                return matching.find(s => s.rawValue > value) !== undefined;
            case "Less Than":
                return matching.find(s => s.rawValue < value) !== undefined;
            case "Rolls Greater Than":
                return matching.find(s => s.statRolls > value) !== undefined;
            case "Rolls Less Than":
                return matching.find(s => s.statRolls < value) !== undefined;
            case "Pct of Max":
                return matching.find(s => s.percentOfMax(mod.rarity) * 100 >= value) !== undefined;
            case "Pct of Max by Tier":
                return matching.find(s => s.percentOfMaxByTier(mod.rarity, mod.tier) * 100 >= value) !== undefined;
            case "Less Than Pct of Max":
                return matching.find(s => s.percentOfMax(mod.rarity) * 100 < value) !== undefined;
            case "Less Than Pct of Max by Tier":
                return matching.find(s => s.percentOfMaxByTier(mod.rarity, mod.tier) * 100 < value) !== undefined;
            case "Average Per Roll Greater Than":
                return matching.find(s => s.rawValue / s.statRolls > value) !== undefined;
            case "Average Per Roll Less Than":
                return matching.find(s => s.rawValue / s.statRolls < value) !== undefined;
        }
    }
    if (statId === "Speed Remainder")
    {
        stat = mod.secondaryOfStat(StatIds.Speed);
        if (!stat)
            return false;
        var remainder = mod.speedRemainder;

        switch (operation) {
            case "Any":
                return true;
            case "Equal":
                return Math.abs(value - remainder) < 0.01;
            case "Not Equal":
                return Math.abs(value - remainder) < 0.01;
            case "Greater Than":
                return remainder > value;
            case "Less Than":
                return remainder < value;
            case "Rolls Greater Than":
                return false;
            case "Rolls Less Than":
                return false;
            case "Pct of Max":
                return false;
            case "Pct of Max by Tier":
                return false;
            case "Less Than Pct of Max":
                return false;
            case "Less Than Pct of Max by Tier":
                return false;
            case "Average Per Roll Greater Than":
                return false;
            case "Average Per Roll Less Than":
                return false;
        }
    }
    if (statId === "Non Flat Count")
    {
        let count = mod.secondaries.filter(s => !statIdIsFlat(s.statId)).length;
        switch (operation) {
            case "Any":
                return true;
            case "Equal":
                return Math.abs(value - count) < 0.01;
            case "Not Equal":
                return Math.abs(value - count) < 0.01;
            case "Greater Than":
                return count > value;
            case "Less Than":
                return count < value;
            default:
                return false;
        }
    }
    stat = mod.secondaryOfStat(statId);
    if (!stat)
        return false;

    switch (operation) {
        case "Any":
            break;
        case "Equal":
            if (Math.abs(value - stat.rawValue) > 0.01)
                return false;
            break;
        case "Not Equal":
            if (Math.abs(value - stat.rawValue) < 0.01)
                return false;
            break;
        case "Greater Than":
            if (stat.rawValue <= value)
                return false;
            break;
        case "Less Than":
            if (stat.rawValue >= value)
                return false;
            break;
        case "Rolls Greater Than":
            if (stat.statRolls <= value)
                return false;
            break;
        case "Rolls Less Than":
            if (stat.statRolls >= value)
                return false;
            break;
        case "Pct of Max":
            if (stat.percentOfMax(mod.rarity) * 100 < value)
                return false;
            break;
        case "Pct of Max by Tier":
            if (stat.percentOfMaxByTier(mod.rarity, mod.tier) * 100 < value)
                return false;
            break;
        case "Less Than Pct of Max":
            if (stat.percentOfMax(mod.rarity) * 100 >= value)
                return false;
            break;
        case "Less Than Pct of Max by Tier":
            if (stat.percentOfMaxByTier(mod.rarity, mod.tier) * 100 >= value)
                return false;
            break;
        case "Average Per Roll Greater Than":
            if (stat.rawValue / stat.statRolls <= value)
                return false;
            break;
        case "Average Per Roll Less Than":
            if (stat.rawValue / stat.statRolls >= value)
                return false;
            break;
    }
    return true;
}

export function modMatchesFilter(mod: ModData, filter: ModSingleFilterData, player: PlayerData): boolean {
    if (filter.slots.length > 0 && !filter.slots.includes(mod.slot))
        return false;

    if (filter.sets.length > 0 && !filter.sets.includes(mod.set))
        return false;

    if (filter.primaries.length > 0 && !filter.primaries.includes(mod.primary.statId))
        return false;

    if (filter.secondaries.length > 0 && filter.secondaries.filter((s) => mod.allSecondaryStats.includes(s)).length !== filter.secondaries.length)
        return false;

    if (filter.secondariesNotPresent.length > 0 && filter.secondariesNotPresent.filter((s) => mod.allSecondaryStats.includes(s)).length !== 0)
        return false;

    if (filter.tiers.length > 0 && !filter.tiers.includes(mod.tier))
        return false;

    if (filter.levels.length > 0 && !filter.levels.includes(mod.level))
        return false;

    if (filter.rarities.length > 0 && !filter.rarities.includes(mod.rarity))
        return false;
    
    if (filter.inLoadout != null && filter.inLoadout !== player.modInLoadout(mod))
        return false;
    
    if (filter.onlySlicable && !mod.okToSlice)
        return false;

    if (filter.equipped === "Only" && mod.equippedCharacter == null)
        return false;
    if (filter.equipped === "None" && mod.equippedCharacter != null)
        return false;

    if (filter.exposed === "Full" && mod.isIncomplete)
        return false;
    if (filter.exposed === "Not" && !mod.isIncomplete)
        return false;

    if (filter.secondary1Stat != null && !filterSecondary(mod, filter.secondary1Stat, filter.secondary1Operation, filter.secondary1Value))
        return false;
    if (filter.secondary2Stat != null && !filterSecondary(mod, filter.secondary2Stat, filter.secondary2Operation, filter.secondary2Value))
        return false;
    if (filter.secondary3Stat != null && !filterSecondary(mod, filter.secondary3Stat, filter.secondary3Operation, filter.secondary3Value))
        return false;
    if (filter.secondary4Stat != null && !filterSecondary(mod, filter.secondary4Stat, filter.secondary4Operation, filter.secondary4Value))
        return false;

    return true;
}

export function modMatchesGroup(mod: ModData, group: ModFilterGroup, player: PlayerData): boolean
{
    return group.filters.reduce((f: boolean, filter) => f || modMatchesFilter(mod, filter, player), false);
}

export class ModFilterResults {
    public incompleteMods: ModData[] = [];
    public garbageMods: ModData[] = [];
    public desiredMods: ModData[] = [];
    public toSliceMods: ModData[] = [];
    public unmatchedMods: ModData[] = [];
    public toCalibrateMods: ModData[] = [];
}

export function filterMods(mods: ModData[], filterGroups: ModFilterGroup[], player: PlayerData): ModFilterResults {
    let ret = new ModFilterResults();

    for (let mod of mods) {
        if (mod.rarity < 5)
        {
            ret.garbageMods.push(mod);
        }
        else if (filterGroups.filter((g) => g.type === "To Slice").reduce((matched: boolean, group) => matched || modMatchesGroup(mod, group, player), false))
        {
            ret.toSliceMods.push(mod);
        }
        else if (filterGroups.filter((g) => g.type === "To Calibrate").reduce((matched: boolean, group) => matched || modMatchesGroup(mod, group, player), false))
        {
            ret.toCalibrateMods.push(mod);
        }
        else if (filterGroups.filter((g) => g.type === "Desired").reduce((matched: boolean, group) => matched || modMatchesGroup(mod, group, player), false))
        {
            ret.desiredMods.push(mod);
        }
        else if (filterGroups.filter((g) => g.type === "Garbage").reduce((matched: boolean, group) => matched || modMatchesGroup(mod, group, player), false))
        {
            ret.garbageMods.push(mod);
        }
        else if (mod.isIncomplete || filterGroups.filter((g) => g.type === "Incomplete").reduce((matched: boolean, group) => matched || modMatchesGroup(mod, group, player), false)) 
        {
            ret.incompleteMods.push(mod);
        }
        else
        {
            ret.unmatchedMods.push(mod);
        }
    }

    return ret;
}
