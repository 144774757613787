import { action, observable } from "mobx";
import UnitData from './UnitData';
import { TerritoryBattleGameData } from './TerritoryBattleGameData';
import { TemplateTestFilter, TemplateTestFilterDefinition } from "./TemplateTestDefinition";
import pilot_data from './gen_pilot_data.json';

function isPilot(baseId: string): baseId is keyof typeof pilot_data
{
    return baseId in pilot_data;
}

class GameData
{
    @observable units: UnitData[] | null = null;
    @observable tbData: TerritoryBattleGameData = new TerritoryBattleGameData();
    @observable templateTestFilters: TemplateTestFilter[] | null = null;
    @observable templateTestFilterDefinition: TemplateTestFilterDefinition[] | null = null;
    @observable glBaseIds: string[] = [];

    @action
    public fromJSON(json: any)
    {

        if (json.units != null)
        {
            this.units = json.units.map((unit: any) =>
            {
                let u = new UnitData(unit);
                if (u.galacticLegend)
                    this.glBaseIds.push(u.baseId);
                if (isPilot(u.baseId))
                {
                    u.shipBaseId = pilot_data[u.baseId];
                }
                return u;
            });

        }
    }

    public tbDataFromJson(json: any)
    {
        this.tbData.fromJson(json, this.units ? this.units : []);
    }

    public templateFromJson(json: any)
    {
        this.templateTestFilterDefinition = [];
        this.templateTestFilters = [];

        json.data.filterDefinition.forEach((fd: any) =>
        {
            this.templateTestFilterDefinition!.push(new TemplateTestFilterDefinition(fd));
        });
        json.data.filter.forEach((f: any) =>
        {
            this.templateTestFilters!.push(new TemplateTestFilter(f, this.templateTestFilterDefinition!));
        });
    }

    public getUnitImageURL(baseId: string): string | null
    {
        if (!this.units)
            return null;

        for (let u of this.units)
        {
            if (u.baseId === baseId)
                return "https://api.hotutils.com/images" + u.baseImage;
        }
        return null;
    }

    public getNameFromBaseId(baseId: string): string | null
    {
        if (!this.units)
            return null;

        for (let u of this.units)
        {
            if (u.baseId === baseId)
                return u.name;
        }
        return null;
    }

    public getUnit(baseId: string): UnitData | null
    {
        if (!this.units)
            return null;

        for (let u of this.units)
        {
            if (u.baseId === baseId)
                return u;
        }
        return null;
    }

    public getCapitalShipBaseIds(): string[]
    {
        return this.units?.filter((u) => u.combatType === 2 && u.role?.find((r) => r.key === "role_capital")).map((u) => u.baseId) || [];
    }
}

export default GameData;
