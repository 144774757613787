import React from "react";
import IPageProps from "../../IPageProps";
import {observer} from "mobx-react";
import {Button, Alert, Popconfirm, Modal, Spin} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationCrosshairs, faLocationCrosshairsSlash, faRotate, faSyncAlt, faUserCog} from "@fortawesome/pro-duotone-svg-icons";
import {faArrowAltLeft} from "@fortawesome/pro-solid-svg-icons";
import header from "../../../components/Player/styles/Header.module.scss";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import {action, observable, runInAction, toJS} from "mobx";
import {
    ITerritoryWarsArray,
    ITerritoryWarsData,
    ITWFilledSquadsInGame,
    ITWUnitsInGame,
    ITWZonesInGame,
    squadsTW,
    zonesTW
} from "../../../model/TWPlanning";
import {IGuildPlayer} from "../../../model/GuildData";
import styles from "./styles/Templates.module.scss";
import twMapDef from "../img/tw-map-def_guetzli.jpg";
import twMapOff from "../img/tw-map-off_guetzli.jpg";
import { TWZoneModal } from "./Modal/TWZoneModal";
import TWTemplateZones from "./TWTemplateZones";
import TWAssignSquads from "./TWAssignSquads";
import BaseAPI from "../../../service/BaseAPI";
import {IGuildSquadTemplate} from "../../../model/Squads";
import TWSendingDMModal from "./Modal/TWSendingDM";
import i18next from "i18next";
import TWAPI, { ITwPlacement } from "../../../service/TWAPI";
import confirmationDialog from "../../../ui/confirmationDialog";
import moment from "moment";
import {TWWarData} from "../../../model/TWData";

interface ITWTemplateProps extends IPageProps {
    template: ITerritoryWarsData;
    templateId: string;
    category: typeof ITerritoryWarsArray;
    isCompactMode: boolean;
    compactMode: (isCompactMode: boolean) => void;
    members: IGuildPlayer[];
    guildId: string;
    guildName: string;
    isAdmin: boolean;
    onClose: () => void;
    onEditSettings: (templateId: string) => void;
    onDelete: (zoneId: number, squadId: string) => void;
    onSaveZone: (zoneId: string | undefined, index: number, id: number, newZoneId: number, zoneName: string, combatType: string) => void;
    onSaveSquads: (squadsTW: squadsTW[]) => void;
    updateZoneId: (zonesTW: zonesTW[]) => void;
    activatePlan: (planId: string) => void;
    guildTWSwttings: string;
    instanceId: string | null;
    planId: string | undefined | null;
    twData: TWWarData | null;
    refreshingTemplate: boolean;
    fetchTWTemplate: () => void;
}

@observer
export default class TWTemplate extends React.PureComponent<ITWTemplateProps> {
    @observable assignSquads: boolean = false;
    @observable showZoneModal: boolean = false;
    @observable showSendDMModal: boolean = false;
    @observable showSendReminderModal: boolean = false;
    @observable showSendReminderBtn: boolean = false;
    @observable hideMap: boolean = false;
    @observable hideHeader: boolean = false;
    @observable guildSquadTemplates: IGuildSquadTemplate[] = [];
    @observable errorPlayerSquadFetch?: string = undefined;
    @observable zoneTW: zonesTW = this.props.template.zones[0];
    @observable squadsTW: squadsTW[] = this.props.template.squads;
    @observable reloading: boolean = false;
    @observable refreshing: boolean = false;
    @observable.ref filledSquadsInTW: ITWZonesInGame[] = [];

    componentDidMount() {
        this.fetchSquads();
    }

    private renderZoneModal() {
        if (!this.showZoneModal) {
            return null;
        }

        return (
            <TWZoneModal
                {...this.props}
                showModal={this.showZoneModal}
                onClose={() => runInAction(() => this.showZoneModal = false)}
                zone={this.zoneTW}
                onSave={(zoneId, index, id, newZoneId, zoneName, combatType) => {
                    runInAction(() => this.showZoneModal = false);
                    this.props.onSaveZone(zoneId, index, id, newZoneId, zoneName, combatType);
                }}
            />
        );
    }

    private async fetchSquads(): Promise<void> {
        runInAction(() => {
            this.refreshing = true;
        });

        try {
            const guildSquadTemplate = await BaseAPI.getSquads(this.props.user);

            runInAction(() => {
                this.guildSquadTemplates = guildSquadTemplate.filter((s) => s.shareType === 2) || [];
                this.refreshing = false;
            })

        } catch (e: any) {
            runInAction(() => this.errorPlayerSquadFetch = e.errorMessage)
        }
    }

    @action
    private updateSquadTemplate(templateId: number, template: Partial<IGuildSquadTemplate>) {

        const currentViewedTemplate = this.guildSquadTemplates.find(x => x.id === templateId);

        if (!currentViewedTemplate) {
            return;
        }

        const viewedTemplate = {
            ...currentViewedTemplate,
            hasBeenShared: false,
            ...template,
        };
        const guildSquadTemplates = [...this.guildSquadTemplates];
        guildSquadTemplates[guildSquadTemplates.indexOf(currentViewedTemplate)] = viewedTemplate;

        this.guildSquadTemplates = guildSquadTemplates;

        BaseAPI.upsertSquad(this.props.user, viewedTemplate, null, null);
    }

    private getExcludedPlayers() {
        let excludedPlayers: string[] = [];

        for (let i = 0; i < this.props.template.excludedPlayers.length; i++) {
            const excludedPlayerAllyCode = this.props.template.excludedPlayers[i];

            const excludedPlayerName = this.props.members.find(x => x.allyCode === excludedPlayerAllyCode)?.name;
            if(excludedPlayerName !== undefined){
                excludedPlayers.push(excludedPlayerName);
            }
        }
        return <div>The following players are excluded: <strong>{excludedPlayers.join(' | ')}</strong></div>;
    }

    private getHighlightedPlayers() {
        let preferredPlayers: string[] = [];

        for (let i = 0; i < this.props.template.preferredPlayers.length; i++) {
            const preferredPlayerAllyCode = this.props.template.preferredPlayers[i];

            const preferredPlayerName = this.props.members.find(x => x.allyCode === preferredPlayerAllyCode)?.name;
            if(preferredPlayerName !== undefined){
                preferredPlayers.push(preferredPlayerName);
            }
        }
        return <div>The following players are preferred for setting their defenses: <strong>{preferredPlayers.join(' | ')}</strong></div>;
    }

    private async fetchTWData(refresh: boolean) {

        runInAction(() => {
            this.reloading = true;
        });

        if (refresh) {
            runInAction(() => this.showSendReminderBtn = true);
        }

        // const instanceId = "TERRITORY_WAR_EVENT_A:O1638727200000";
        const instanceId = undefined;

        try {
            let dataTW = await TWAPI.get(this.props.user, refresh, false, "Home", undefined, instanceId);
            // console.log('dataTW: ', dataTW);

            let dataZoneSquadsTW = [] as ITWZonesInGame[];

            for (let i = 0; i < dataTW.tw.home.zones.length; i++) {
                let filledSquadsInTW = [] as ITWFilledSquadsInGame[];
                const zoneTW = dataTW.tw.home.zones[i];

                zoneTW.squads.forEach((squad: ITWFilledSquadsInGame) => {
                    let unitsInTW = [] as ITWUnitsInGame[];

                    squad.units.forEach((unit) => {
                        unitsInTW.push({
                            id: unit.id,
                            baseId: unit.baseId,
                            name: this.props.gameData.getUnit(unit.baseId)?.name ?? unit.baseId,
                            omiCount: unit.omiCount,
                            relicLevel: unit.relicLevel,
                            gearLevel: unit.gearLevel,
                            ultimate: unit.ultimate,
                            zetaCount: unit.zetaCount,
                            zetaLead: unit.zetaLead
                        })
                    })

                    filledSquadsInTW.push({
                        squadId: squad.squadId,
                        playerName: squad.playerName,
                        playerAllyCode: squad.playerAllyCode ? squad.playerAllyCode : this.props.members.find(x => x.name === squad.playerName)?.allyCode,
                        units: Array.from(new Set(unitsInTW))
                    })
                })

                dataZoneSquadsTW.push({
                    zoneId: zoneTW.zoneId,
                    title: zoneTW.commandMessage,
                    index: zoneTW.index,
                    combatType: zoneTW.fleet === true ? 2 : 1,
                    squadCapacity: zoneTW.squadCapacity,
                    squadCount: zoneTW.squadCount,
                    squads: Array.from(new Set(filledSquadsInTW))
                })
            }

            if(this.props.template.zones.find(x => x.zoneId === undefined)){
                const currentViewedTemplate = toJS(this.props.template);
                let zonesTW: zonesTW[] = currentViewedTemplate.zones;

                for (let j = 0; j < zonesTW.length; j++) {
                    const zone = zonesTW[j];

                    if(zone.zoneId === undefined){
                        zone.zoneId = dataZoneSquadsTW.find(x => x.index === zone.index)?.zoneId ;
                    }
                }

                let viewedTemplate: ITerritoryWarsData = {
                    ...currentViewedTemplate,
                    zones: zonesTW
                };

                this.props.updateZoneId(zonesTW);
                await BaseAPI.setGuildSetting(this.props.user, this.props.guildTWSwttings, JSON.stringify(viewedTemplate.zones), true);
                await BaseAPI.setTWDataTemplates(this.props.user, this.props.category, viewedTemplate, this.props.templateId);
            }
            // console.table(this.props.template.zones);

            runInAction(() => {
                this.reloading = false;
                this.filledSquadsInTW = dataZoneSquadsTW;
                console.log(dataZoneSquadsTW)
            });
            // console.log(dataZoneSquadsTW);

        } catch (err: any) {
            Modal.error({
                title: i18next.t("common:ui.error_dlg_title"),
                content: <span>{err.errorMessage}</span>,
                maskClosable: false
            });
        }
    }

    private renderSendDMModal() {
        if (!this.showSendDMModal) {
            return null;
        }

        const unassignedSquads = (this.props.template.squadsPerZone * 10) - this.props.template.squads.length;

        return (
            <TWSendingDMModal
                {...this.props}
                showModal={this.showSendDMModal}
                onClose={() => runInAction(() => {
                    this.showSendDMModal = false;
                })}
                unassignedSquads={unassignedSquads}
                template={this.props.template}
                members={this.props.members}
                guildName={this.props.guildName}
            />
        );
    }

    private async onPlacingSquads(): Promise<void> {
        const playerSquads = this.props.template.squads.filter(x => x.allyCode === Number(this.props.user.currentPlayer?.allyCode));

        let confirm = await confirmationDialog("Are you sure?", `Are you sure you want to place your squads in the game? It cannot be undone! This will break the game connection`);
        if (!confirm) {
            return;
        }

        let plan: ITwPlacement[] = [];

        for (let i = 0; i < playerSquads.length; i++) {
            const squad = playerSquads[i];
            const zoneId = this.props.template.zones.find(z => z.id === squad.zoneId)?.zoneId;

            if(zoneId) {
                plan.push({
                    zoneId: zoneId,
                    unitBaseId: squad.units.map(u => u.preferredUnit ? u.preferredUnit : u.characterId)
                });
            } else {
                Modal.error({ maskClosable: false, title: i18next.t("common:ui.error_dlg_title"), content: <span>The zone id doesn't match!</span>});
                break;
            }
        }

        let waitDlg = Modal.info({ maskClosable: false, okButtonProps: { disabled: true, style: { display: 'none' } }, title: i18next.t("common:ui.please_wait_dlg_title"), content: <Spin size="large" /> });
        try
        {
            await TWAPI.placeTeams(this.props.user, plan);
            waitDlg.destroy();
            Modal.info({ maskClosable: false, title: "Your defense is done", content: "Your squads were placed successfully!"});
        }
        catch (err: any)
        {
            waitDlg.destroy();
            Modal.error({title: i18next.t("common:ui.error_dlg_title"), content: <span>{err.errorMessage}</span>, maskClosable: false});
        }
    }

    render() {
        if (!this.props.members) {
            return <LoadingSpinner size={"large"} spinning={true} text={'Loading the guild data...'}/>;
        }
        if (this.errorPlayerSquadFetch) {
            return <LoadingSpinner size={"large"} error={true} spinning={false} text={this.errorPlayerSquadFetch}/>;
        }
        let updateDate = moment.utc(this.props.twData?.updateTime);

        return (
            <div className={styles.container}>
                {!this.hideHeader && <div className={header.header}>
                    <div className={header.left}>
                        <h1 className={header.title}>
                            {this.props.template.name}
                        </h1>
                    </div>
                    <div className={header.right}>
                        <Button type={"default"} className={styles.btn} onClick={() => {
                            if (this.assignSquads) {
                                runInAction(() => this.assignSquads = false)
                            } else {
                                this.props.onClose();
                            }
                        }}
                                title={'Back to templates'}>
                            <FontAwesomeIcon icon={faArrowAltLeft} className={styles.icon}/>
                            Back to {this.assignSquads ? 'zones' : 'templates'}
                        </Button>
                        {this.props.isAdmin &&
						<Button type={"default"} className={styles.btn} onClick={() => this.props.onEditSettings(this.props.templateId)}
								title={'Template settings'}>
							<FontAwesomeIcon icon={faUserCog} className={styles.icon}/>
							Settings
						</Button>}
                        {this.props.isAdmin && this.assignSquads &&
						<Button type={"default"} className={styles.btn} onClick={() => this.fetchSquads()}
								title={this.refreshing ? 'Getting new data...' : 'Refresh the squads and template'}>
							<FontAwesomeIcon icon={faRotate} spin={this.refreshing} className={styles.icon}/>
                            {this.refreshing ? 'Getting new data...' : 'Refresh the data'}
						</Button>}
                        {this.props.isAdmin && !this.assignSquads &&
						<Button type={"default"} className={styles.btn} onClick={() => this.props.fetchTWTemplate()}
								title={this.props.refreshingTemplate ? 'Getting new data...' : 'Refresh the template'}>
							<FontAwesomeIcon icon={faRotate} spin={this.props.refreshingTemplate} className={styles.icon}/>
                            {this.props.refreshingTemplate ? 'Getting new data...' : 'Refresh the template'}
						</Button>}
                        {this.props.isAdmin && !this.assignSquads && <Button
                            type={"default"}
                            className={styles.btn}
                            disabled={this.props.instanceId === null || this.props.planId === this.props.templateId}
                            onClick={() => this.props.activatePlan(this.props.templateId)}
                            title={this.props.instanceId === this.props.templateId ? 'This plan is active for current TW' : 'Mark this plan as active for the current TW'}
                        >
							<FontAwesomeIcon icon={this.props.planId === this.props.templateId ? faLocationCrosshairsSlash : faLocationCrosshairs} className={styles.icon}/>
                            {this.props.planId === this.props.templateId ? 'Active Plan' : 'Set Active'}
						</Button>}
                        {this.props.isAdmin && <Popconfirm
							title={`Do you want to break the game connection and get fresh data?`}
							onConfirm={() => this.fetchTWData(true)}
							key={'popconfirmFetchTWData'}
							onCancel={() => this.fetchTWData(false)}
							okText="Yes"
							cancelText="No"
						>
                            <Button
                                type={"primary"}
                                className={styles.btn}
                                title={'Sync the assignments with the game'}
                            >
                                <FontAwesomeIcon icon={faSyncAlt} spin={this.reloading} className={styles.icon}/>
								Check the placements in game
                            </Button>
                        </Popconfirm>}
                    </div>
                </div>}
                {this.props.twData && this.props.twData.currentRound < 2 && updateDate.diff(moment(), "minutes") < -3 && <Alert message={"TW Data is " + updateDate.fromNow(true) + " old"} type="error"/>}
                {this.props.template.excludedPlayers?.length > 0 && <Alert
                    message={this.getExcludedPlayers()}
                    type="warning"
                    showIcon
                    closable
                />}
                {this.props.template.preferredPlayers?.length > 0 && <Alert
                    message={this.getHighlightedPlayers()}
                    type="info"
                    showIcon
                    closable
                />}
                {!this.assignSquads && <TWTemplateZones
                    {...this.props}
                    showSendReminderBtn={this.showSendReminderBtn}
                    assignSquads={zone => runInAction(() => {
                        this.assignSquads = true;
                        this.zoneTW = zone;
                    })}
					onPlacingSquads={() => this.onPlacingSquads()}
                    filledSquadsInTW={this.filledSquadsInTW}
                    onSaveAllSquads={squadsTW => {
                        runInAction(() => {
                            this.squadsTW = squadsTW;
                            this.props.onSaveSquads(this.squadsTW);
                        })
                    }}
                    onSaveSquad={(squadTW) => {
                        runInAction(() => {
                            this.squadsTW = this.squadsTW.filter(x => x.squadId !== squadTW.squadId);
                            this.squadsTW.push(squadTW);
                            this.props.onSaveSquads(this.squadsTW);
                        })
                    }}
                    onSaveSquads={(squadLeader, zoneId, newZoneId) => {
                        runInAction(() => {
                            let squadsTW = this.squadsTW.filter(x => x.zoneId === zoneId);
                            this.squadsTW = this.squadsTW.filter(x => x.zoneId !== zoneId);

                            for (let i = 0; i < squadsTW.length; i++) {
                                const squad = squadsTW[i];

                                if(squad.units[0].preferredUnit ? squad.units[0].preferredUnit : squad.units[0].characterId === squadLeader) {
                                    squad.zoneId = newZoneId;
                                }
                            }

                            this.squadsTW.push(...squadsTW);
                            this.props.onSaveSquads(this.squadsTW);
                        });
                    }}
                    onMoveSquads={(zoneId, newZoneId, switchUnits, moveUnits) => {
                        runInAction(() => {
                            let squadsTW = this.squadsTW.filter(x => x.zoneId === zoneId);
                            let squadsTWFromNewZone = this.squadsTW.filter(x => x.zoneId === newZoneId);

                            if(moveUnits) {
                                this.squadsTW = this.squadsTW.filter(x => x.zoneId !== zoneId);

                                for (let i = 0; i < squadsTW.length; i++) {
                                    const squad = squadsTW[i];
                                    squad.zoneId = newZoneId;
                                }

                                this.squadsTW.push(...squadsTW);
                                this.props.onSaveSquads(this.squadsTW);
                            } else {
                                this.squadsTW = this.squadsTW.filter(x => x.zoneId !== zoneId && x.zoneId !== newZoneId);

                                for (let i = 0; i < squadsTW.length; i++) {
                                    let squad = squadsTW[i];
                                    squad.zoneId = newZoneId;
                                }
                                for (let i = 0; i < squadsTWFromNewZone.length; i++) {
                                    const squadNewZone = squadsTWFromNewZone[i];
                                    squadNewZone.zoneId = zoneId;
                                }
                                this.squadsTW.push(...squadsTW, ...squadsTWFromNewZone);
                                this.props.onSaveSquads(this.squadsTW);
                            }
                        })
                    }}
                    onDelete={(zoneId, squadId) => {
                        runInAction(() => {
                            if(squadId === "all"){
                                this.squadsTW = this.squadsTW.filter(x => x.zoneId !== zoneId);
                            } else {
                                this.squadsTW = this.squadsTW.filter(x => x.squadId !== squadId);
                            }
                        })
                        this.props.onDelete(zoneId, squadId);
                    }}
                    showZoneModal={() => runInAction(() => this.showZoneModal = true)}
                    showSendDMModal={() => {
                        runInAction(() => {
                            this.showSendDMModal = true;
                        });
                    }}
                    setActiveTWTemplate={() => this.props.activatePlan(this.props.templateId)}
                    zoneTW={zone => runInAction(() => this.zoneTW = zone)}
                />}

                {this.assignSquads && <TWAssignSquads
                    {...this.props}
                    zoneTW={toJS(this.zoneTW)}
                    squadsTW={toJS(this.squadsTW)}
                    saveAssignedSquads={squadsTW => {
                        const newSquadsTw = this.squadsTW.filter(item => item.zoneId !== this.zoneTW.id).concat(squadsTW);

                        runInAction(() => {
                            this.assignSquads = false
                            this.squadsTW = newSquadsTw;
                        });

                        this.props.onSaveSquads(newSquadsTw);
                    }}
                    guildSquadTemplates={this.guildSquadTemplates.slice()}
                    updateSquadTemplate={(templateId, name, category, units, poolUnits, combatType) =>
                        this.updateSquadTemplate(templateId,{name, category, units, poolUnits, combatType})}
                    hideHeader={showHeader => runInAction(() => this.hideHeader = showHeader)}

                />}
                {this.filledSquadsInTW.length > 0 && <div className={styles.legend}>
                    <div className={styles.title}>Legend:</div>
                    <div className={`${styles.color}`}>
                        <div className={`${styles.box} ${styles.green}`} />
                        <div className={`${styles.txt}`}>The requested unit was placed in game!</div>
                    </div>
                    <div className={`${styles.color}`}>
                        <div className={`${styles.box} ${styles.yellow}`} />
                        <div className={`${styles.txt}`}>This unit was placed instead of the requested unit!</div>
                    </div>
                    <div className={`${styles.color}`}>
                        <div className={`${styles.box} ${styles.red}`} />
                        <div className={`${styles.txt}`}>This unit was requested, but wasn't placed in game!</div>
                    </div>
                </div>}
                {!this.assignSquads && <div className={`${styles.map} ${this.hideMap ? styles.small : styles.big}`}>
                    <img
                        className={`${styles.img} ${this.hideMap ? styles.small : styles.big}`}
                        src={this.props.category[0] === 'Defence' ? twMapDef : twMapOff}
                        onClick={() => runInAction(() => this.hideMap = !this.hideMap)}
                        alt={"Territory Wars Map"}
                        title={"Territory Wars Map"}
                    />
                    {this.props.template.zones.map(i =>
                        <div
                            key={i.index}
                            title={i.name}
                            className={`${styles.twArea} ${this.props.category[0] === 'Defence' ? styles.twDef : styles.twOff} ${styles['tw' + i.index]}`}
                        >
                            {i.name}
                        </div>
                    )}
                </div>}
                {this.renderZoneModal()}
                {this.renderSendDMModal()}
            </div>
        );
    }
}
