import React from "react";
import styles from "./styles/UnitAvatarGroup.module.scss";
import avatar from "./styles/UnitAvatar.module.scss";

interface IUnitAvatarUndefinedProps {
    name: string;
    baseImage: string;
    combatType: number;
    alignment: number;
    className?: string;
    stars: number;
    gear: number;
    relic: number;
    showName: boolean;
    onClick?: () => void;
}

export default function UnitAvatarUndefined(props: IUnitAvatarUndefinedProps) {

    const star = [];

    if (props.stars) {
        for (let i = 0; i < 7; i++) {

            star.push(
                <span key={i} className={` ${avatar.star} ${props.combatType === 2 ? (avatar.ship + ' ' + styles['ship-star']) : avatar.squad} ${avatar[`star-${i + 1}`]} ${props.stars <= i ? avatar[`star-inactive`] : ''}`} />
            );
        }
    }

    const forceAlignment =  props.alignment === 2 ? "light" : props.alignment === 3 ? "dark" : "neutral";
    const styleForceAlignment = "force-" + forceAlignment;

    return (
        <div className={`${props.className} ${styles['unit-avatar-group']} ${styles['size-small']}`} onClick={() => props.onClick !== undefined ? props.onClick() : undefined}>
            <div className={styles.wrapper}>
                <div className={`${avatar['unit-avatar']} ${styles['unit-avatar']}`} title={props.name}>

                    <div className={`${avatar[styleForceAlignment]} ${avatar.wrapper} ${props.combatType === 1 ? 'circle' : 'square'}`}>
                        <div className={`${avatar.avatar} ${styles.avatar}`} >
                            <img src={'https://api.hotutils.com/images/units/' + props.baseImage + '.png'} className={`${styles['size-medium']} ${styles['simple-avatar']}`} alt={props.name} title={props.name} />
                        </div>
                        {(props.combatType === 1  && props.gear) ? (
                            <span
                                className={avatar["gear" + (props.gear === 13 ? 13 : '')]}
                                style={{
                                    backgroundImage: props.gear.toString() === '13' ? `url(https://api.hotutils.com/images/gear/g13-atlas.png)` :
                                        `url(https://api.hotutils.com/images/gear/g${Number(props.gear)}.png)`
                                }}
                            />
                        ) : null}
                        {props.gear === 13 ? <span className={`${avatar.relic} ${styles.relic}`}>{Number(props.relic)}</span> : null}

                    </div>
                    {star}

                </div>
            </div>
            {props.showName && <div className={styles["main-name"]}>{props.name}</div>}
        </div>
    );
}