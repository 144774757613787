import {IGuildPlayer} from "../../../../model/GuildData";
import {ITerritoryWarsData, squadsTW} from "../../../../model/TWPlanning";

export function TotalSquadsAssigned(record: IGuildPlayer, isSquad: string, template?: ITerritoryWarsData, filledSquads?: squadsTW[]): number {
    let assignedToonsPerPlayers = 0;
    let fleetZones: number[] = [];

    if(!template){
        return 0;
    }

    for (let f of template.zones){
        if(f.combatType === 'Ship'){
            fleetZones.push(f.id);
        }
    }

    for (let s of template.squads) {
        if(filledSquads){
            if(isSquad === 'squad' && !fleetZones.includes(s.zoneId)){
                if(record.allyCode === s.allyCode && filledSquads.find(x => x.squadId === s.squadId)){
                    assignedToonsPerPlayers++;
                }
            } else if(isSquad === 'ship' && fleetZones.includes(s.zoneId)) {
                if(record.allyCode === s.allyCode && filledSquads.find(x => x.squadId === s.squadId)){
                    assignedToonsPerPlayers++;
                }
            } else if(isSquad === 'all') {
                if(record.allyCode === s.allyCode && filledSquads.find(x => x.squadId === s.squadId)){
                    assignedToonsPerPlayers++;
                }
            }
        } else {
            if(isSquad === 'squad' && !fleetZones.includes(s.zoneId)){
                if(record.allyCode === s.allyCode){
                    assignedToonsPerPlayers++;
                }
            } else if(isSquad === 'ship' && fleetZones.includes(s.zoneId)) {
                if(record.allyCode === s.allyCode){
                    assignedToonsPerPlayers++;
                }
            } else if(isSquad === 'all') {
                if(record.allyCode === s.allyCode){
                    assignedToonsPerPlayers++;
                }
            }
        }
    }

    return assignedToonsPerPlayers;
}

export function TotalZonesAssigned(record: IGuildPlayer, template: ITerritoryWarsData): string[] {
    let assignedZonesIdPerPlayers = new Set<number>();
    let assignedZonesPerPlayers: string[] = [];

    for (let s of template.squads) {
        if(record.allyCode === s.allyCode){
            assignedZonesIdPerPlayers.add(s.zoneId);
        }
    }
    const title = template.zones.filter(x => Array.from(new Set(assignedZonesIdPerPlayers)).includes(x.id));

    for (let z of title){
        assignedZonesPerPlayers.push(z.name);
    }

    return assignedZonesPerPlayers;
}
